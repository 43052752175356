import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../hooks/useWindowDimensions'
import SearchOne from '../components/searchBoxes/SearchOne'
import DropDownMenu from '../components/menus/DropDownMenu'

const MAX_WIDTH = 1000
const NOT_SUPPORTED_MSG =
  'Venuely is currently not supported on mobile. Please sign up using your desktop device.'

export default function PrivacyPolicy() {
  const navigate = useNavigate()
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const authUser = useSelector((state) => state.auth.user)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [newsletterName, setNewsletterName] = useState(null)
  const [newsletterEmail, setNewsletterEmail] = useState(null)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [allActivities, setallActivities] = useState([])
  const [event, setEvent] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const { width } = useWindowDimensions()
  const [navbarVisibile, setNavbarVisibile] = useState(true)

  const userSignUp = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(false)
    setShowRegistrationForm(!showRegistrationForm)
  }
  const userSignIn = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(!showLoginForm)
  }
  return (
    <>
      <div
        style={{
          boxShadow: 'rgba(128, 128, 128, 0.2) 0px 1px 10px',
          height: 80,
          transition: 'linear 0.2s all',
          backgroundColor: '#fff',
          top: 0,
          left: 0,
          position: 'sticky',
          zIndex: 10,
          opacity: navbarVisibile ? 1 : 0,
        }}
      >
        <div className="navbar__parent">
          <Link to={'/'}>
            <img
              src="/venuely.svg"
              alt=""
              style={{ width: 130, transition: 'ease-in-out 0.2s all' }}
              className="nav-logo"
            />
          </Link>
          <SearchOne />
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <h3 className="link_text" onClick={() => navigate('/become-host')}>
              Become a host
            </h3>
            <div className="right__side__links">
              {!isauthenticated ? (
                <>
                  <h3 className="link_text" onClick={userSignUp}>
                    Sign up
                  </h3>
                  <h3 className="link_text" onClick={userSignIn}>
                    Sign in
                  </h3>
                </>
              ) : (
                <DropDownMenu
                  showDropdownMenu={showDropdownMenu}
                  setShowDropdownMenu={setShowDropdownMenu}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // border: '1px solid blue',
          margin: 10,
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'justify',
            // border: '1px solid red',
          }}
        >
          <h1>Privacy Policy</h1>
          <p>
            Protecting your personal information is important to us. This
            Privacy Policy describes how Venuely Inc. (“our,” “us,” or “we”)
            collects, uses, or discloses personal information we receive through
            our websites (including www.venuely.org, venuely.org), and any
            mobile apps or other related online or offline services we may offer
            (collectively, the “Services”). This policy also describes your
            choices about the collection and use of your information.
          </p>
          <p>
            <br />
          </p>
          <p>
            Please read the Privacy Policy carefully before you start to use our
            Services. By using the Services, you agree to be bound and abide by
            our posted Terms of Use (the “Terms”) and this Privacy Policy. If
            you do not agree to both the Terms and our Privacy Policy, or if you
            violate them in any way, your right to access or use the Services is
            terminated.&nbsp;Please see Sections 12-14 of our Terms of Use
            regarding your legal rights in any dispute involving our Services.
          </p>

          <h1>1. How We Collect Information</h1>
          <p>
            We may collect information about you by various means, including:
          </p>
          <ul>
            <li>
              <p>
                Directly from you, both online (e.g., website registration,
                postings, rentals) and offline (e.g., phone);
              </p>
            </li>
            <li>
              <p>
                Through your use of our websites or related online services; and
              </p>
            </li>
            <li>
              <p>
                From other third party sources and social media platforms that
                you may use to engage with us, as well as from our affiliates or
                third parties for the purposes provided below, and/or to
                supplement information we already possess.
              </p>
            </li>
          </ul>

          <h1>2. Types of Information We Collect</h1>
          <p>
            In order to better provide you with our Services, we may collect the
            following categories of information:&nbsp;
          </p>
          <ul>
            <li>
              <p>
                Profile and registration information, such as a username and
                password, name, mailing address, e-mail address, phone number,
                and usage and subscription preferences, which may include
                signing up to some of our Services or communications from us;
              </p>
            </li>
            <li>
              <p>
                Identity/Property verification, in the event we need to validate
                someone’s identity or Property rights, we may request a copy of
                government-issued identification, ownership rights, or other
                such evidence, but such information will be used strictly for
                such purposes;
              </p>
            </li>
            <li>
              <p>
                Transactional information&nbsp;including payment information and
                transactions (e.g., rentals, other purchases) made through the
                Services;
              </p>
            </li>
            <li>
              <p>
                User Content&nbsp;you may provide to publicly post via the
                Services if we permit (e.g., Property listings, photos,
                comments, reviews, ratings);
              </p>
            </li>
            <li>
              <p>
                Survey information&nbsp;in response to questions we may send you
                through the Services, including for feedback and research
                purposes;&nbsp;
              </p>
            </li>
            <li>
              <p>
                Communications&nbsp;between you and us, such as via e-mail,
                mail, phone or other channels;&nbsp;
              </p>
            </li>
            <li>
              <p>
                Offline activity&nbsp;you directly engage in with us, if any;
                and
              </p>
            </li>
            <li>
              <p>
                Online User Activity&nbsp;described in the next section.&nbsp;
              </p>
            </li>
          </ul>
          <p>
            If you provide us with information regarding another individual, you
            represent that you have that&nbsp;person’s consent to give us his or
            her information and to permit us to use the information in
            accordance with this policy.
          </p>
          <h1 style={{ textAlign: 'left' }}>
            3. Online User Activity, Cookies and Information Collected by Other
            Automated Means
          </h1>
          <p>
            Cookies are a commonly-used web technology that allow websites to
            store and retrieve certain information on a user’s system, and track
            users’ online activities. We, together with vendors we use, may
            collect information about your use of our Services by such automated
            means, including but not limited to cookies, pixels, SDKs and other
            similar technologies.&nbsp;
          </p>
          <p>
            Cookies and similar technologies can help us automatically identify
            you when you return to our website. Cookies help us review website
            traffic patterns, improve the website, and determine what Services
            are popular. We can also use such information to deliver customized
            content and advertising to users of the Services whose behavior
            indicates that they are interested in a particular subject
            area.&nbsp;
          </p>
          <p>
            When you use the Services, the information we may collect by
            automated means includes, for example:
          </p>
          <ul>
            <li>
              <p>
                Usage Details&nbsp;about your interaction with our Services
                (such as the date, time, and length of visits, and specific
                pages or content accessed during the visits, search terms,
                frequency of the visits, referring website addresses);
              </p>
            </li>
            <li>
              <p>
                Device Information&nbsp;including the IP address and other
                details of a device that you use to connect with our Services
                (such as device type and unique device identifier, operating
                system, browser type, mobile network information, and the
                device's telephone number); and
              </p>
            </li>
            <li>
              <p>
                Location information&nbsp;where you choose to provide the
                website with access to information about your device’s location.
              </p>
            </li>
          </ul>
          <p>
            We may also ask advertisers or other partners to serve ads or
            services to you, which may use cookies or similar technologies
            placed by us or the third party. If a user does not want information
            collected through the use of cookies, most browsers allow the
            visitor to reject cookies, but if you choose to decline cookies, you
            may not be able to fully experience the interactive features our
            Services provide. We may share non-personal information obtained via
            cookies with our advertisers and affiliates. Because there is not
            yet a consensus on how companies should respond to web browser-based
            do-not-track (“DNT”) mechanisms, we do not respond to web
            browser-based DNT signals at this time.&nbsp;
          </p>

          <h1>4. How We Use Information We Collect</h1>

          <p>
            We may use the information we obtain about you for purposes allowed
            by applicable laws, including:
          </p>
          <ul>
            <li>
              <p>
                Provide our Services to you, including to maintain your account,
                facilitate interactions between users (e.g., Hosts and Guests),
                manage any purchases or transactions, provide important notices,
                respond to your requests, questions and comments and provide
                customer support;
              </p>
            </li>
            <li>
              <p>
                Monitor the performance of our Services including metrics such
                as total number of visitors, traffic, and demographic patterns;
                and
              </p>
            </li>
            <li>
              <p>
                Tailor the content we display to you in our Services and
                communications, including advertising, and offer products and
                services that we believe may be of interest to you;
              </p>
            </li>
            <li>
              <p>
                Operate, evaluate and improve our programs, our websites and
                other products and services we offer (including to develop new
                services), and diagnose or fix technology problems;
              </p>
            </li>
            <li>
              <p>
                Analyze and enhance our communications and strategies (including
                by identifying when emails sent to you have been received and
                read);
              </p>
            </li>
            <li>
              <p>
                Inform you about changes to this Privacy Policy and our Terms of
                Use and other policies; and
              </p>
            </li>
            <li>
              <p>
                Comply with and enforce as needed applicable legal requirements,
                industry standards, our policies and our contractual rights, as
                needed for security purposes, and to investigate possible fraud
                or safety issues.
              </p>
            </li>
          </ul>
          <p>
            We may also use or share information in an anonymized or aggregate
            manner for many purposes such as research, analysis, modeling,
            marketing, and improvement of our Services.
          </p>

          <h1>5. Online Advertising</h1>
          <p>
            You may see us advertising on various websites, web pages, social
            media and other platforms. Please note that online advertising
            services do not always provide us with complete information about
            the platforms or websites on which our advertising may appear. If
            you believe that our advertising is being displayed through websites
            or channels that promote inappropriate or offensive content, please
            contact us.&nbsp;
          </p>

          <h1>6. How We Share Your Information</h1>
          <p>
            We will share your information as you direct, such as sending a
            Guest’s contact information to a Host or insurer in order to
            facilitate a rental or purchase.&nbsp;
          </p>
          <p>
            We will not disclose your personal information to third parties
            without your consent, except in the following circumstances:
          </p>
          <ul>
            <li>
              <p>
                We may share your information with&nbsp;service providers that
                we believe need the information to perform a technology,
                business, or other professional function for us (examples
                include IT services, maintenance and hosting of our Services,
                payment processors, shipping and returns partners, marketing
                partners, and other service providers). We only provide such
                vendors with information so they can perform their required
                functions on our behalf.
              </p>
            </li>
            <li>
              <p>
                If you voluntarily share or submit any User Content including
                contact information (e.g., name) or content (e.g., Property
                listing, picture), or commentary (e.g., product review) for
                public posting through our Services, or link it to any social
                media platforms, that post and any content or information
                associated with it may become available to the public. See our
                Terms of Use for more information about how we may use such User
                Content.
              </p>
            </li>
            <li>
              <p>
                We also may disclose information about you (i) if we are
                required to do so by law or legal process, (ii) when we believe
                disclosure is necessary to prevent harm or financial loss, (iii)
                in connection with an investigation of suspected or actual
                fraudulent or illegal activity; or (iv) under exigent
                circumstances to protect the personal safety of our staff, users
                or the public.
              </p>
            </li>
            <li>
              <p>
                We reserve the right to transfer the information we maintain in
                the event we sell or transfer all or a portion of our business
                or assets. If we engage in such a sale or transfer, we will make
                reasonable efforts to direct the recipient to use your personal
                information in a manner that is consistent with this Privacy
                Policy.&nbsp;
              </p>
            </li>
          </ul>
          <p>
            Where appropriate, we will limit sharing of your information in
            accordance with the choices you have provided to us and consistent
            with applicable law.
          </p>

          <h1>7. Your Privacy Choices</h1>
          <p>
            We offer you certain choices about what information we collect from
            you, how we use and disclose the information, and how we communicate
            with you.&nbsp;
          </p>
          <ul>
            <li>
              <p>
                Marketing Emails: From time to time, we may contact you via
                email for the purpose of providing announcements, promotional
                offers, alerts, confirmations, surveys, and/or other general
                communication. You may choose not to receive marketing emails
                from us by clicking on the unsubscribe link in the marketing
                emails you receive from us. Even if you opt out of receiving
                such communications, we will continue sending you non-marketing
                email communications, such as administrative or transaction
                alerts, or information about changes to our terms of service.
              </p>
            </li>
            <li>
              <p>
                Cookies: Web browsers may offer users the ability to disable
                receiving certain types of cookies; however, if cookies are
                disabled, some features or functionality of our Services may not
                function correctly.
              </p>
            </li>
            <li>
              <p>
                Targeted Advertising: We may potentially work with advertising
                partners who collect information about your online activities
                and provide you with choices regarding the use of your browsing
                behavior for purposes of targeted advertising. Popular
                advertising services you may opt out of targeted advertising
                from include Google, Facebook, the Network Advertising
                Initiative and Digital Advertising Alliance.&nbsp;
              </p>
            </li>
            <li>
              <p>
                User Account: You may terminate your user account (if any) by
                contacting us as provided below. We may retain any account
                information or User Content for internal purposes or as
                otherwise provided in this Privacy Policy and our Terms of Use.
              </p>
            </li>
          </ul>
          <p>
            Certain privacy laws may provide additional rights with respect to
            the collection and use of personal information collected by
            businesses. This may include the right to request that a business:
            (i) disclose personal information maintained about the individual;
            (ii) correct or delete personal information maintained about the
            individual (subject to certain exceptions); or (iii) not sell
            personal information about the individual to a third party
            (excluding qualified service providers). It can be unlawful to
            discriminate against an individual for exercising such rights. You
            can submit such requests to us via the contact information provided
            below and we will endeavor to fulfill any obligations that are
            legally required, otherwise we will respond to such requests in our
            discretion.
          </p>

          <h1>8. Links to Other Websites and Third Party Content</h1>
          <p>
            Our Services may contain links to other websites or online services,
            including social media. Please be aware that we are not responsible
            for the content or privacy practices of such other websites or
            online services, and we encourage you to be aware when you leave our
            Services and to read the privacy statements of any other website or
            online service that collects personal information.&nbsp;
          </p>
          <p>
            You may see us promoted by other businesses on various websites, web
            pages, social media and other platforms. Please note that we do not
            always have complete information about where our brand may be
            displayed or promoted, and if you believe that we are featured in
            venues that are inappropriate or offensive, please contact us.&nbsp;
          </p>

          <h1>9. How We Protect Information</h1>
          <p>
            We endeavor to maintain reasonable administrative, technical and
            physical safeguards designed to protect the personal information we
            maintain against accidental, unlawful or unauthorized destruction,
            loss, alteration, access, disclosure or use. However, we cannot
            ensure the security of any information you transmit to us, or
            guarantee that this information will not be accessed, disclosed,
            altered, or destroyed. We will make any legally required disclosures
            in the event of any compromise of personal information. To the
            extent the law allows us to provide such notification via e-mail or
            conspicuous posting on the Services, you agree to accept notice in
            that form.&nbsp;
          </p>

          <h1>10. Children&nbsp;</h1>
          <p>
            We do not knowingly collect personal information from children under
            the age of 13 on our Services. If you believe that a child under 13
            provided personal information to us through the Services, please
            notify us. If we learn that we have received personal information
            from a child under 13 without parental consent, we will endeavor to
            promptly delete that information.&nbsp;
          </p>
          <p>
            If you are a California resident under the age of 18, do not share
            any personal information on any public content channel we may offer
            (e.g., User Content). If you do post any such information, you can
            request removal by contacting us as directed below.
          </p>

          <h1>11. International Data Transfers and Use</h1>
          <p>
            Our Services are controlled and operated by us from the United
            States and are not intended to subject us to the laws or
            jurisdiction of any state, country or territory other than those of
            the United States. Any information you provide through use of the
            Services may be stored and processed, transferred between and
            accessed from the United States and other countries which may not
            guarantee the same level of protection of personal information as
            the one in which you reside. However, we will handle your personal
            information in accordance with this Privacy Policy regardless of
            where your personal information is stored or accessed.
          </p>

          <h1>12. Updates to Our Privacy Policy</h1>
          <p>
            We may revise or update this Privacy Policy at any time. We will
            post the updated version on our Services, and may notify you of any
            significant changes to our Privacy Policy. We will indicate on our
            Privacy Policy when it was most recently updated. Your continued use
            of our Services after such updates will constitute an acknowledgment
            of the change and agreement to abide and be bound by the updated
            Privacy Policy.&nbsp;
          </p>

          <h1>13. Contacting Us&nbsp;</h1>
          <p>
            If you have any questions, concerns or comments about this Privacy
            Policy, our privacy practices, or if you would like us to update
            information or preferences you provided to us, please contact us by
            mail to: Bricks and Mortals, 239 Thompson Street, New York, NY
            10012; via email to&nbsp;
            <a href="mailto:hello@venuely.org">hello@venuely.org</a> or by phone
            at (218) 389-1055.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>This Privacy Policy were last updated June&nbsp;__, 2022.&nbsp;</p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>{' '}
    </>
  )
}
