import React, { useEffect, useReducer, useState } from 'react'
import Button1 from '../components/buttons/Button1'
import HeaderBar from '../components/HeaderBar'
import '../style/paymentPage.css'
import AddCardDetails from '../components/popups/AddCardDetails'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { message, Popconfirm, Button, Spin } from 'antd'
import { TopMsg } from '../components/Topmsg'
import visaImg from '../img/visa.png'
import amexImg from '../img/amex.png'
import mastercardImg from '../img/mastercard.png'
import discoverImg from '../img/discover.png'
import unionImg from '../img/union.png'
import otherCardsImg from '../img/othercards.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout, refresh } from '../redux/actions/AuthAction'
import useInterval from '@use-it/interval'

const PaymentPage = () => {
  const [addCardModal, setaddCardModal] = useState(false)
  const [newCardAdded, setnewCardAdded] = useState(false)
  const [newDefaultCard, setnewDefaultCard] = useState(false)
  const [cardList, setCardList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [cardNumber, setcardNumber] = useState(null)
  const [expDate, setexpDate] = useState(null)
  const [cvc, setCvc] = useState(null)
  const [zipCode, setzipCode] = useState(null)
  const cookies = new Cookies()
  const authData = cookies.get('jwt')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const baseURL = process.env.REACT_APP_BASE_URL
  const PAGE_LIMIT = 10
  const getPaymentCardList = async () => {
    const cookies = new Cookies()
    const authData = await cookies.get('jwt')
    try {
      const res = await axios.get(
        `${baseURL}/guest/paymnet-method?limit=${PAGE_LIMIT}`,
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      setCardList(res.data.all_method)
      setLoading(false)
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        message.error(err.response.data.detail)
      }
    }
  }
  useEffect(() => {
    getPaymentCardList()
  }, [newCardAdded, newDefaultCard])

  const deleteCard = async (id) => {
    try {
      const cookies = new Cookies()
      const authData = await cookies.get('jwt')
      const res = await axios.delete(`${baseURL}/guest/paymnet-method`, {
        headers: {
          Authorization: authData
        },
        data: {
          method: id
        }
      })
      console.log("Payment", res.data)
      setnewCardAdded(!newCardAdded)
      message.success(TopMsg('Card deleted successfully'))
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        message.error(err.response.data.detail)
      }
    }
  }

  const makeDefault = async (id) => {
    try {
      const cookies = new Cookies()
      const authData = await cookies.get('jwt')
      const res = await axios({
        method: 'PATCH',
        url: `${baseURL}/guest/paymnet-method`,
        data: { method: id },
        headers: {
          Authorization: authData
        }
      })
      if (res.status === 200) {
        setnewDefaultCard(!newDefaultCard)
        message.success(TopMsg('Action successfully done'))
      }
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        message.error(err.response.data.detail)
      }
    }
  }
  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)

  const getCardSrc = (item) => {
    const brand = item.brand
    const brandMap = new Map()
    brandMap.set("visa", visaImg)
    brandMap.set("unionpay", unionImg)
    brandMap.set("mastercard", mastercardImg)
    brandMap.set("amex", amexImg)
    brandMap.set("discover", discoverImg)
    return <img src={brandMap.has(brand) ? brandMap.get(brand) : otherCardsImg}
      style={{ width: 42, height: 42, objectFit: 'contain' }} alt="" />
  }

  return (
    <>
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      <AddCardDetails
        title="Add card details"
        addCardModal={addCardModal}
        setaddCardModal={setaddCardModal}
        cardNumber={cardNumber}
        setcardNumber={setcardNumber}
        expDate={expDate}
        setexpDate={setexpDate}
        zipCode={zipCode}
        setzipCode={setzipCode}
        cvc={cvc}
        setCvc={setCvc}
        newCardAdded={newCardAdded}
        setnewCardAdded={setnewCardAdded}
      />
      {loading ? (
        <div style={{ position: 'absolute', top: '15%', left: '50%', transform: 'translate(-50%, 0)' }}>
          <Spin size="large" />
        </div>
      ) : (
        <div className="payments">
          <div className="payment__method">
            <h1>Payments</h1>
            <h3>Payment methods</h3>
            <p>
              Add and manage your payment methods using our secure payment system using Stripe: a certified PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.
            </p>
            {cardList.map((item, idx) => {
              return (
                <div className="card-list" key={idx}>
                  <div className="card__each">
                    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: '50%' }}>
                      {getCardSrc(item)}
                      <h1 style={{ textTransform: 'capitalize' }}>{item.brand}</h1>
                      <h1 className="card-item">
                        {`••••${item.last4}`}
                      </h1>
                    </div>
                    {idx === 0 && (
                      <Popconfirm
                        title=""
                        visible={false}
                        onVisibleChange={() => null}
                        onConfirm={() => null}
                        onCancel={() => null}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ width: 120 }}>Default</Button>
                      </Popconfirm>
                    )}
                    {idx !== 0 && (
                      <Popconfirm
                        placement="bottom"
                        title='Do you want to make this default?'
                        onConfirm={() => makeDefault(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ width: 120 }}>Make default</Button>
                      </Popconfirm>
                    )}
                    <Popconfirm
                      placement="bottom"
                      title='Are you sure to delete this task?'
                      onConfirm={() => deleteCard(item.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button>Delete</Button>
                    </Popconfirm>
                  </div>
                </div>
              )
            })}
            <div
              onClick={() => setaddCardModal(!addCardModal)}
              style={{ marginBottom: 30 }}
            >
              <Button1 title="Add payment methods" width={260} />
            </div>
          </div>
          <div className="payment__policy">
            <h4 style={{ fontSize: 18, fontWeight: '500' }}>Payments through Venuely</h4>
            <p>
              Always pay and communicate through Venuely to ensure you're protected under our Terms of Service, cancellation, and other safeguards.
            </p>
            <h5 className="blue--text">Learn more</h5>
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentPage
