import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

const InsurancePolicyModalAtBookingDetails = (props) => {
  const { isInsuranceModalVisible, setInsuranceModalVisible, item } = props

  return (
    <Modal
      title={'Insurance Policy'}
      destroyOnClose
      okText="Apply"
      open={isInsuranceModalVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setInsuranceModalVisible(false)}
      onCancel={() => setInsuranceModalVisible(false)}
    >
      <div style={{ maxHeight: 550, overflowX: 'hidden', overflowY: 'scroll' }}>
        <p style={{ fontSize: '1.3rem' }}>
          *The venue requires you to provide an insurance certificate in order
          to host this event
        </p>
        <p style={{ fontSize: '1.1rem' }}>
          Please purchase an insurnance certificate by clicking our partner{' '}
          <a
            href="https://www.eventsured.com/venuely/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            Eventsured link HERE.
          </a>
        </p>
        <p style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
          *IMPORTANT: You must purchase commercial liability insurance, and email this certificate to the host before your event.
        </p>
        <p>
          <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
            Host’s email address
          </span>
          : {props.item.host.email}
        </p>
      </div>
    </Modal>
  )
}

export default InsurancePolicyModalAtBookingDetails
