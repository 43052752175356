import 'animate.css'
import '../style/landingPage/landing_page.css'
import '../style/landingPage/landing_webflow.css'
import amChurchImg from './images/AmbientChurch_Venuely_Main_with_Credit-02.jpg'
import amChurchImg500 from './images/AmbientChurch_Venuely_Main_with_Credit-02-p-500.jpg'
import amChurchImg800 from './images/AmbientChurch_Venuely_Main_with_Credit-02-p-800.jpg'
import amChurchImg1080 from './images/AmbientChurch_Venuely_Main_with_Credit-02-p-1080.jpg'
import icon19 from './images/Veneuly-Main-Landing-Page-Icons-19.png'
import icon20 from './images/Veneuly-Main-Landing-Page-Icons-20.png'
import icon21 from './images/Veneuly-Main-Landing-Page-Icons-21.png'
import icon22 from './images/Veneuly-Main-Landing-Page-Icons-22.png'
import icon23 from './images/Veneuly-Main-Landing-Page-Icons-23.png'
import icon24 from './images/Veneuly-Main-Landing-Page-Icons-24.png'
import icon25 from './images/Veneuly-Main-Landing-Page-Icons-25.png'
import icon26 from './images/Veneuly-Main-Landing-Page-Icons-26.png'
import icon27 from './images/Veneuly-Main-Landing-Page-Icons-27.png'
import icon28 from './images/Veneuly-Main-Landing-Page-Icons-28.png'
import icon29 from './images/Veneuly-Main-Landing-Page-Icons-29.png'
import icon30 from './images/Veneuly-Main-Landing-Page-Icons-30.png'
import icon31 from './images/Veneuly-Main-Landing-Page-Icons-31.png'
import img500 from './images/Venuley-Renter-Feature-Images-04-p-500.png'
import img800 from './images/Venuley-Renter-Feature-Images-04-p-800.png'
import img1080 from './images/Venuley-Renter-Feature-Images-04-p-1080.png'
import img1600 from './images/Venuley-Renter-Feature-Images-04-p-1600.png'
import img2000 from './images/Venuley-Renter-Feature-Images-04-p-2000.png'
import img2667 from './images/Venuley-Renter-Feature-Images-04.png'
import img5 from './images/Venuley-Renter-Feature-Images-05.png'
import img5_500 from './images/Venuley-Renter-Feature-Images-05-p-500.png'
import img5_800 from './images/Venuley-Renter-Feature-Images-05-p-800.png'
import img5_1080 from './images/Venuley-Renter-Feature-Images-05-p-1080.png'
import img5_1600 from './images/Venuley-Renter-Feature-Images-05-p-1600.png'
import img5_2000 from './images/Venuley-Renter-Feature-Images-05-p-2000.png'
import img5_2600 from './images/Venuley-Renter-Feature-Images-05-p-2600.png'
import icon2 from './images/Veneuly-Main-Landing-Page-Icons-02.png'
import icon6 from './images/Veneuly-Main-Landing-Page-Icons-06.png'
import icon3 from './images/Veneuly-Main-Landing-Page-Icons-03.png'
import womenTypingImg from './images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand.jpg'
import womenTypingImg500 from './images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand-p-500.jpeg'
import womenTypingImg800 from './images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand-p-800.jpeg'
import eventImg from './images/event-veneuly.jpg'
import eventImg500 from './images/event-veneuly-p-500.jpg'
import eventImg800 from './images/event-veneuly-p-800.jpg'
import classesImg from './images/group-young-people-sitting-conference-together.jpg'
import classesImg500 from './images/group-young-people-sitting-conference-together-p-500.jpg'
import classesImg800 from './images/group-young-people-sitting-conference-together-p-800.jpg'
import meetingsImg from './images/high-angle-people-group-therapy.jpg'
import meetingsImg500 from './images/high-angle-people-group-therapy-p-500.jpg'
import meetingsImg800 from './images/high-angle-people-group-therapy-p-800.jpg'
import rehearsalsImg from './images/dynamic-shot-young-music-band-playing-instruments-together-while-practicing-cozy-studio.jpg'
import rehearsalsImg500 from './images/dynamic-shot-young-music-band-playing-instruments-together-while-practicing-cozy-studio-p-500.jpg'
import rehearsalsImg800 from './images/dynamic-shot-young-music-band-playing-instruments-together-while-practicing-cozy-studio-p-800.jpg'
import perfImg from './images/judson-church-dance.jpeg'
import perfImg500 from './images/judson-church-dance-p-500.jpeg'
import perfImg800 from './images/judson-church-dance-p-800.jpeg'
import mediaImg from './images/man-filming-with-professional-camera.jpg'
import mediaImg500 from './images/man-filming-with-professional-camera-p-500.jpg'
import mediaImg800 from './images/man-filming-with-professional-camera-p-800.jpg'
import SearchOne from '../components/searchBoxes/SearchOne'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { me, refresh } from '../redux/actions/AuthAction'
import useInterval from '@use-it/interval'
import DropDownMenu from '../components/menus/DropDownMenu'
import RegistrationForm from '../components/registration/RegistrationForm'
import SelectUser from '../components/login/SelectUser'
import useScript from '../hooks/useScript'
import ActivityModal from '../components/popups/ActivityModal'
import axios from 'axios'
import { baseURL } from '../redux/requests'
import Cookies from 'universal-cookie'
import { message } from 'antd'
import LandingFAQ from '../components/FAQ/LandingFAQ'

function RenterLanding() {
  const navigate = useNavigate()
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const authUser = useSelector((state) => state.auth.user)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [newsletterName, setNewsletterName] = useState(null)
  const [newsletterEmail, setNewsletterEmail] = useState(null)
  const [newsletterAddress, setNewsletterAddress] = useState(null)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [allActivities, setallActivities] = useState([])
  const [event, setEvent] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const { width } = useWindowDimensions()
  const [navbarVisibile, setNavbarVisibile] = useState(true)

  useEffect(() => {
    if (!authUser?.email) dispatch(me())
    if (isauthenticated) {
      if (authUser.type == 'Host') {
        navigate('/pending-request')
      }
      if (authUser.type == 'Guest') {
        navigate('/')
      }
    }
  }, [authUser])

  useInterval(() => {
    if (isauthenticated) {
      dispatch(
        refresh({
          callBack: (response) => {
            // console.log(response)
          },
        }),
      )
    }
  }, 360000)

  const dispatch = useDispatch()

  const getAllActivities = async () => {
    setActivityModal(!activityModal)
    const res = await axios.post(`${baseURL}/guest/activity`)
    setallActivities(res.data)
    setEvent(true)
  }

  const errorMsg = (err) => (
    <label style={{ fontSize: 12, textTransform: 'uppercase' }}>{err}</label>
  )
  const submitNewsLetter = (e) => {
    e.preventDefault()
    const cookies = new Cookies()
    const authData = cookies.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    const data = {
      name: newsletterName,
      email: newsletterEmail,
      address: newsletterAddress,
    }
    axios
      .post(`${baseURL}/user/subscribe`, data, config)
      .then((res) => {
        if (res.status === 200) {
          message.success(res.data)
        }
      })
      .catch((err) => {
        message.error(
          err ? errorMsg(err.response.data) : 'Something went wrong',
        )
      })
  }
  const MAX_WIDTH = 1000
  const NOT_SUPPORTED_MSG =
    'Venuely is currently not supported on mobile. Please sign up using your desktop device.'
  const userSignUp = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(false)
    setShowRegistrationForm(!showRegistrationForm)
  }
  const userSignIn = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(!showLoginForm)
  }
  const startSearch = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    navigate('/renter/search')
  }

  const landingPageRef = useRef()
  useEffect(() => {
    const scrollHandler = (ev) => {
      const landingPage = landingPageRef.current.getBoundingClientRect()
      let top = Math.abs(landingPage.top)
      if (top < 950) {
        setNavbarVisibile(true)
      } else if (ev.deltaY < 0) {
        setNavbarVisibile(true)
      } else {
        setNavbarVisibile(false)
      }
    }
    window.addEventListener('wheel', scrollHandler, true)
    return () => {
      window.removeEventListener('wheel', scrollHandler, true)
    }
  })

  return (
    <div ref={landingPageRef}>
      <div
        className={`singn-up-form ${
          showRegistrationForm ? '' : 'hide-elements'
        }`}
      >
        <RegistrationForm closeRegistrationFormCart={setShowRegistrationForm} />
      </div>
      <div className={`singn-in-form ${showLoginForm ? '' : 'hide-elements'}`}>
        <SelectUser closeRegistrationFormCart={setShowLoginForm} />
      </div>
      <div
        style={{
          boxShadow: 'rgba(128, 128, 128, 0.2) 0px 1px 10px',
          height: 80,
          transition: 'linear 0.2s all',
          backgroundColor: '#fff',
          top: 0,
          left: 0,
          position: 'sticky',
          zIndex: 10,
          opacity: navbarVisibile ? 1 : 0,
        }}
      >
        {width < 480 ? (
          <div
            style={{
              padding: '30px 20px',
            }}
          >
            <Link to={'/'}>
              <img
                src="/venuely.svg"
                alt=""
                style={{ width: 130 }}
                className="nav-logo"
              />
            </Link>
          </div>
        ) : (
          <div className="navbar__parent">
            <Link to={'/'}>
              <img
                src="/venuely.svg"
                alt=""
                style={{ width: 130, transition: 'ease-in-out 0.2s all' }}
                className="nav-logo"
              />
            </Link>
            <SearchOne />
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <h3
                className="link_text"
                onClick={() => navigate('/become-host')}
              >
                Become a host
              </h3>
              <div className="right__side__links">
                {!isauthenticated ? (
                  <>
                    <h3 className="link_text" onClick={userSignUp}>
                      Sign up
                    </h3>
                    <h3 className="link_text" onClick={userSignIn}>
                      Sign in
                    </h3>
                  </>
                ) : (
                  <DropDownMenu
                    showDropdownMenu={showDropdownMenu}
                    setShowDropdownMenu={setShowDropdownMenu}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        data-w-id="22949717-fa4a-923b-ab3c-68f3e1880797"
        className="header-section-7"
      >
        <div className="container-8">
          <div
            className="w-layout-grid header-grid-4"
            style={{ alignItems: 'center' }}
          >
            <div className="header-content">
              <div
                style={{
                  WebkitTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  transform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  opacity: 1,
                }}
                className="header-title-wrapper-2"
              >
                <div className="accent-wrapper-2">
                  <h2 className="heading-title" id="heading-title">
                    Discover
                  </h2>
                  {/* <h1 className="heading-title _01">congregations in a new light.</h1> */}
                  <img
                    src="https://uploads-ssl.webflow.com/609b3427f37846455f9de8db/60e55a65c96f3f169a45d662_Curve%20Wave.svg"
                    loading="lazy"
                    alt=""
                    className="line-accent animate__animated animate__fadeInUp"
                  />
                </div>
                <h1 className="heading-title" id="heading-title">
                  congregations in a new light.
                </h1>
                <img
                  style={{ margin: '30px 0' }}
                  src="https://uploads-ssl.webflow.com/609b3427f37846455f9de8db/60e558e72307039eeb35f2a4_Star%20Accent.svg"
                  loading="lazy"
                  alt=""
                  className="star animate__animated animate__rotateIn animate__faster"
                />
              </div>
              <p
                style={{
                  WebkitTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  msTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  transform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                }}
                className="header-paragraph-6 animate__animated animate__fadeInUp"
              >
                Host your next gathering in stunning, one-of-a-kind spaces.
              </p>
              {/* <div style={{
                                WebkitTransform: "translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                msTransform: "translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", transform: "translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                            }} className="number-large animate__animated animate__fadeInUp">Start Searching Today!</div> */}
              <div
                onClick={startSearch}
                style={{
                  WebkitTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  msTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  transform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                }}
                className="button-wrapper-5"
              >
                <a
                  href="#"
                  style={{ marginTop: -20 }}
                  className="_wf-normal-button w-button animate__animated animate__fadeInUp"
                >
                  START YOUR SEARCH!
                </a>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/609b3427f37846455f9de8db/60e55a1ea1127c367aaa06a9_Arrow%20Bottom.svg"
                loading="lazy"
                style={{
                  WebkitTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  msTransform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  transform:
                    'translate3d(0, 35px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                  opacity: 1,
                }}
                alt=""
                className="arrow-large"
              />
            </div>
            {/* <div className="header-image"><img src={amChurchImg} loading="lazy"
                            style={{ WebkitTransform: "translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", msTransform: "translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", transform: "translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" }}
                            srcSet={`${amChurchImg500} 500w, ${amChurchImg800} 800w, ${amChurchImg1080} 1080w, ${amChurchImg} 1200w`} sizes="(max-width: 991px) 76vw, 750px" alt="" className="header-scooter" />
                            <div className="header-block"></div>
                        </div> */}
            <div className="header-image">
              <img
                src={amChurchImg}
                alt=""
                srcSet={`${amChurchImg500} 500w, ${amChurchImg800} 800w, ${amChurchImg1080} 1080w, ${amChurchImg} 1200w`}
                sizes="(max-width: 1200px) 90vw, 900px"
                className="header-scooter animate__animated animate__fadeInLeft animate_faster"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-how">
        <div className="container-2">
          <div className="title-wrapper-3">
            <div className="title-large-2">3 Steps</div>
            <h3 className="h1-heading-3">
              How does Venuely work <br /> for Guests?
            </h3>
            <p className="paragraph-large-3">
              Venuely Hosts are members of the very communities in which you
              live and work. By sharing space with them, you are directly
              supporting those communities’ overall health and well-being.
            </p>
          </div>
          <div className="w-layout-grid grid-feature-cards">
            <div className="card-features">
              <div className="feature-icon-large-2">
                <img src={icon19} loading="lazy" alt="" />
              </div>
              <div className="heading-large-2">1. Search</div>
              <p className="paragraph-standard-2">
                Discover new spaces in your community for your special events
                and gatherings! Search by location and include additional
                details like event date and time, expected attendance, type of
                event, and price range for better results.
              </p>
            </div>
            <div className="card-features">
              <div className="feature-icon-large-2">
                <img src={icon20} loading="lazy" alt="" />
              </div>
              <div className="heading-large-2">2. Book</div>
              <p className="paragraph-standard-2">
                You can complete a reservation request using Venuely's online
                system. Engage with your Host directly and get your questions
                answered. Bookings aren't completed until the Host approves your
                reservation.
              </p>
            </div>
            <div className="card-features">
              <div className="feature-icon-large-2">
                <img src={icon21} loading="lazy" alt="" />
              </div>
              <div className="heading-large-2">3. Gather</div>
              <p className="paragraph-standard-2">
                Make sure you keep in touch with your Host if you have any
                follow-up questions after your booking has been secured. Then
                the rest is up to you to activate the space!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-why">
        <div className="container-features">
          <div className="w-layout-grid grid-feature-wrapper">
            <div>
              <div className="title-large-3">WHY we built venuely</div>
              <div className="heading-title _02">
                Houses of worship play important roles in our communities.
              </div>
              <div className="feature-details">
                By working with Venuely to partner with these mission-driven
                organizations and host your events, you are playing a role in
                their work to support communities and help people in need.
              </div>
            </div>
            <div>
              <div className="w-layout-grid grid-features">
                <div className="feature-card-01">
                  <div className="icon-square-01">
                    <img src={icon22} loading="lazy" alt="" />
                  </div>
                  <h3 className="feature-title">
                    Congregations provide physical and social infrastructure
                    <br></br>
                  </h3>
                  <div className="feature-details">
                    {' '}
                    and complex social networks that can be leveraged for a wide
                    range of issues.
                  </div>
                </div>
                <div className="feature-card-02">
                  <div className="icon-square-02">
                    <img src={icon23} loading="lazy" alt="" />
                  </div>
                  <h3 className="feature-title">
                    Congregations provide access<br></br>
                  </h3>
                  <div className="feature-details">
                    {' '}
                    to food, health care, educational and job opportunities, and
                    other forms of support through extended social networks and
                    connections with other community institutions.
                  </div>
                </div>
                <div className="feature-card-03">
                  <div className="icon-square-03">
                    <img src={icon24} loading="lazy" alt="" />
                  </div>
                  <h3 className="feature-title">
                    Help us to come together.<br></br>
                  </h3>
                  <div className="feature-details">
                    In addition to their role as places of worship, religious
                    buildings are places where members of local communities can
                    come together to celebrate life’s events, big and small –
                    from births, weddings, and funerals, to meetings,
                    performances, and more…
                  </div>
                </div>
                <div className="feature-card-04">
                  <div className="icon-square-04">
                    <img src={icon25} loading="lazy" alt="" />
                  </div>
                  <h3 className="feature-title">
                    They are the heart of communities<br></br>
                  </h3>
                  <div className="feature-details">
                    Religious spaces are often used and cherished by a broad
                    range of community members for a wide range of activities,
                    regardless of their religious affiliation.{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-large-2">
        <img
          src="https://uploads-ssl.webflow.com/61f89cf914a3727e4698c853/61f89cf914a372853698c86d_BG%20Vector.svg"
          loading="lazy"
          alt=""
          className="bg-vector"
        />
        <div className="container-large-3">
          <div className="heading-wrapper-2">
            <div className="h1-heading-3">Our Core Features</div>
            <p className="paragraph-3">
              We built Venuely with artists and creatives specifically in mind.
            </p>
          </div>
          <div className="w-layout-grid grid-content">
            <div>
              <div className="browser">
                <img
                  src="images/Venuley-Renter-Feature-Images-04.png"
                  loading="lazy"
                  srcSet={`${img500} 500w, 
              ${img800} 800w, ${img1080} 1080w, ${img1600} 1600w, ${img2000} 2000w, ${img2667} 2667w`}
                  sizes="(max-width: 1919px) 90vw, 1280px"
                  alt=""
                  className="brower-top-bar"
                />
              </div>
            </div>
            <div id="w-node-da873c82-97fc-eeb0-e88a-7b35f1ff114e-99b9931a">
              <div className="w-layout-grid grid-feature-2">
                <div className="feature-wrapper">
                  <div className="feature-icon-small">
                    <img src={icon26} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">01.</span> Access architecturally
                      unique spaces
                    </div>
                    <p className="paragraph-3">
                      Venuely hosts have spaces that you won't find in any
                      traditional office or hotel setting. These sites are
                      architecturally significant and magnificent locations for
                      your guests.
                    </p>
                  </div>
                </div>
                <div className="feature-wrapper">
                  <div className="feature-icon-small">
                    <img src={icon27} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">02.</span> Gather communities and
                      host wide range of activities{' '}
                    </div>
                    <p className="paragraph-3">
                      Not only are Venuely host spaces aesthetically pleasing to
                      the eye – they also have fantastic scale too! From grand
                      spaces seating more than 1000, to more intimate settings
                      for fewer than 10, our hosts have spaces that will fit
                      your diverse needs.
                    </p>
                  </div>
                </div>
                <div className="feature-wrapper">
                  <div className="feature-icon-small">
                    <img src={icon28} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">03.</span> Communicate directly
                      with the host
                    </div>
                    <p className="paragraph-3">
                      Our built in messenger service allows you to contact the
                      host directly and keep track of your communications. Never
                      worry about losing that email thread again.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-large-2 _02">
        <div className="container-large-3">
          <div className="w-layout-grid grid-content-bottom">
            <div id="w-node-_982c9895-d75d-30ce-1c6c-ea1b5e7e0a74-99b9931a">
              <div className="w-layout-grid grid-feature-2">
                <div className="feature-wrapper">
                  <div className="feature-icon-small _02">
                    <img src={icon29} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">04.</span> Book single or
                      multiple days for your event
                    </div>
                    <p className="paragraph-3">
                      Whether you need a space for one day or 10 days, Venuely
                      has you covered. See our{' '}
                      <a
                        href="https://venuely.org/faq"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQ section
                      </a>{' '}
                      for more details on how our booking calendar works.
                    </p>
                  </div>
                </div>
                <div className="feature-wrapper">
                  <div className="feature-icon-small _02">
                    <img src={icon30} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">05.</span> Pay with ease using
                      Stripe
                    </div>
                    <p className="paragraph-3">
                      Stripe is an easy and secure way to pay for your Venuely
                      booking. You can book through Venuely's Stripe system with
                      confidence.
                    </p>
                  </div>
                </div>
                <div className="feature-wrapper">
                  <div className="feature-icon-small _02">
                    <img src={icon31} loading="lazy" alt="" />
                  </div>
                  <div>
                    <div className="sub-heading-large-2">
                      <span className="number">06.</span> Flexible cancellation
                      policy
                    </div>
                    <p className="paragraph-3">
                      All of the Venuely Hosts are required to post their
                      cancellation policy as part of their profile. You can read
                      all about their expectations before submitting any payment
                      information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="browser">
                <img
                  src={img5}
                  loading="lazy"
                  srcSet={`${img5_500} 500w, 
            ${img5_800} 800w, ${img5_1080} 1080w, ${img5_1600} 1600w, ${img5_2000} 2000w, ${img5_2600} 2600w, ${img5} 2667w`}
                  sizes="(max-width: 1919px) 90vw, 1280px"
                  alt=""
                  className="brower-top-bar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-cta-sign-up-section main">
        <div className="_wf-content-section">
          <div className="heading-wrapper-4 margin-bottom-64">
            <div className="heading-detail-section-2">
              discover beautiful spaces
            </div>
            <h1 className="heading-content cta">
              Start planning your next event with Venuely!
            </h1>
            <div className="w-layout-grid grid-button margin-top-64 horizontal">
              <a
                href="#"
                className="button-primary w-inline-block"
                onClick={startSearch}
              >
                <div>Get started</div>
              </a>
              <a
                href="#"
                className="button-outline-2 w-inline-block"
                onClick={userSignUp}
              >
                <div className="text-block-2">Sign Up</div>
              </a>
            </div>
            <div className="cta-image-block"></div>
          </div>
          <div className="grid-wrapper">
            <div className="features-grid w-container">
              <div className="_wf-features-item-left-aligned">
                <div className="feature-icon-large-2">
                  <img src={icon2} loading="lazy" alt="" />
                </div>
                <div className="feature-header main">Access</div>
                <p className="features-text">
                  Venuely is user-friendly for both our Hosts and Guests. Hosts
                  can post their spaces with no obligation or costs, and our
                  Guests are free to browse listings without booking.
                </p>
              </div>
              <div className="_wf-features-item-left-aligned">
                <div className="feature-icon-large-2">
                  <img src={icon6} loading="lazy" alt="" />
                </div>
                <div className="feature-header main">Congregate</div>
                <p className="features-text">
                  Guests and Hosts alike come together through Venuely. Post
                  your space, search for a space, reserve a space, communicate
                  about your booking, and secure payment – all in one place!
                </p>
              </div>
              <div className="_wf-features-item-left-aligned">
                <div className="feature-icon-large-2">
                  <img src={icon3} loading="lazy" alt="" />
                </div>
                <div className="feature-header main">Create</div>
                <p className="features-text">
                  Guests can create their next event with Venuely’s wide range
                  of Host venues–from performances, to media production, to
                  celebrations, and more. Through Venuely, Hosts can create an
                  expanded community, income, and connection for your house of
                  worship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-regular">
        <div className="container-large-2">
          <div className="heading-wrapper-4 margin-bottom-64">
            <div className="heading-detail-section-2">Ideas</div>
            <h1 className="heading-content">List of Activities</h1>
            <div className="w-layout-grid grid-button margin-top-64">
              <a
                href="#"
                className="button-outline-2 w-inline-block"
                onClick={() => getAllActivities()}
              >
                <div className="text-block-2">View All Activities</div>
              </a>
              <ActivityModal
                allActivities={allActivities}
                setallActivities={setallActivities}
                activityModal={activityModal}
                setActivityModal={setActivityModal}
                event={event}
                setEvent={setEvent}
              />
            </div>
          </div>
          <div
            className="w-layout-grid grid-three-column"
            style={{ display: 'grid', width: '100%' }}
          >
            <div
              id="w-node-_9312d99f-58d1-3562-14a2-2e11aca342a9-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Events</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Wedding, Funerals, Memorials Etc</div>
                </div>
              </div>
              <div className="cta-card-01">
                <img
                  src={eventImg}
                  loading="lazy"
                  srcSet={`${eventImg500} 500w, ${eventImg800} 800w,  ${eventImg} 1000w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, 
              (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
            <div
              id="w-node-_94e45dc9-da3d-9b7e-83f6-cc0af25309ef-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Classes</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Dance, Fitness, Coaching, etc</div>
                </div>
              </div>
              <div className="cta-card-02">
                <img
                  src="images/group-young-people-sitting-conference-together.jpg"
                  loading="lazy"
                  srcSet={`${classesImg500} 500w, ${classesImg800} 800w, ${classesImg} 1000w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
            <div
              id="w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43da5-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Meetings</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Creative, Sports, Focus Group, etc</div>
                </div>
              </div>
              <div className="cta-card-03">
                <img
                  src={meetingsImg}
                  loading="lazy"
                  srcSet={`${meetingsImg500} 500w, ${meetingsImg800} 800w, ${meetingsImg} 1000w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
            <div
              id="w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d96-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Rehearsals</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Dance &amp; Music, Speaking, etc</div>
                </div>
              </div>
              <div className="cta-card-02">
                <img
                  src="images/dynamic-shot-young-music-band-playing-instruments-together-while-practicing-cozy-studio.jpg"
                  loading="lazy"
                  srcSet={`${rehearsalsImg500} 500w, ${rehearsalsImg800} 800w, ${rehearsalsImg} 1000w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
            <div
              id="w-node-_96fb01a7-d620-3a46-f219-393390db0bf6-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Performances</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Theater, Art, Dance, etc</div>
                </div>
              </div>
              <div className="cta-card-03">
                <img
                  src="images/judson-church-dance.jpeg"
                  loading="lazy"
                  srcSet={`${perfImg500} 500w, ${perfImg800} 800w, ${perfImg} 1080w, ${perfImg} 1280w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 
              1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
            <div
              id="w-node-_2b2b7e10-9d4a-28e5-becf-cfd473b43d87-99b9931a"
              className="cta-card"
            >
              <div className="cta-title-wrapper">
                <div className="heading-detail-section-2">FOR</div>
                <h6 className="h6-heading-2">Media Productions</h6>
                <div className="integration-detail-wrapper">
                  <div className="icon-small-2 w-embed">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66198 1.85938H5.16932C3.11932 1.85938 1.83398 3.31071 1.83398 5.36538V10.908C1.83398 12.9627 3.11332 14.414 5.16932 14.414H11.052C13.1087 14.414 14.388 12.9627 14.388 10.908V8.22271"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.88541 7.28062L10.8674 2.29862C11.4881 1.67862 12.4941 1.67862 13.1147 2.29862L13.9261 3.10995C14.5467 3.73062 14.5467 4.73728 13.9261 5.35728L8.92008 10.3633C8.64875 10.6346 8.28075 10.7873 7.89675 10.7873H5.39941L5.46208 8.26728C5.47141 7.89662 5.62275 7.54328 5.88541 7.28062Z"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.1104 3.06836L13.1544 6.11236"
                        stroke="#1A1A1A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>Film, Documentary, etc</div>
                </div>
              </div>
              <div className="cta-card-01">
                <img
                  src={mediaImg}
                  loading="lazy"
                  srcSet={`${mediaImg500} 500w, ${mediaImg800} 800w, ${mediaImg} 1000w`}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 350px, (max-width: 991px) 85vw, (max-width: 1919px) 86vw, 1230px"
                  alt=""
                  className="image-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="become-a-host-section main">
        <div className="container-regular-2">
          <div className="w-layout-grid grid-two-column">
            <div className="placeholder-image-tall">
              <img
                src={womenTypingImg}
                loading="lazy"
                sizes="(max-width: 991px) 90vw, 500px"
                srcSet={`${womenTypingImg500} 500w, ${womenTypingImg800} 800w, ${womenTypingImg} 1000w`}
                alt=""
                className="image-cover"
              />
            </div>
            <div id="w-node-_141591e0-dc24-6b1f-748a-9bbb9e2894da-99b9931a">
              <div className="margin-bottom-12 text-weight-bold">
                <div className="heading-detail-small">
                  fill in your downtime
                </div>
              </div>
              <div className="margin-bottom-32">
                <h1 className="heading-content">
                  Did you know you can become a host?
                </h1>
              </div>
              <p className="paragraph-large">
                Go ahead, you scrolled all the way down to the bottom of the
                homepage, join us as a Venuely Host!
              </p>
              <p className="paragraph-large">
                Need more convincing? Still have questions and concerns? Check
                out our{' '}
                <a
                  href="https://venuely.org/faq"
                  target="_blank"
                  style={{ fontSize: 16 }}
                >
                  FAQ page
                </a>{' '}
                then let us know what other info you need.
              </p>
              <div className="button-wrapper-5">
                <Link
                  to={'/become-host'}
                  className="_wf-normal-button w-button"
                >
                  become a host
                </Link>
                <a
                  onClick={() => {
                    navigate('/become-host', { state: { FAQ: true } })
                  }}
                  className="_wf-normal-button2 w-button"
                >
                  Review FAQ!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFAQ />
      <div className="consultation-section wf-section">
        <div className="_wf-hero-container-2-blue w-container">
          <div
            data-w-id="0b3ea087-b89c-daf5-77d7-7097d2a7d4cf"
            className="consultation-contain"
          >
            <img
              src="images/7055170_discussion_consultation_communication_meeting_conversation_icon.png"
              loading="lazy"
              width="100"
              alt=""
              className="consultation-icon"
            />
            <h3 className="header-2 consultation">Need a consultation?</h3>
            <h6 className="subheader">
              Our team is here to help! Reach out to us anytime with any
              additional questions, ideas, and feedback at
              <br />
              <br />
              <span className="text-span-2" style={{ fontSize: 18 }}>
                <a
                  href="mailto:hello@venuely.org"
                  target="_blank"
                  style={{ fontSize: 18 }}
                >
                  hello@venuely.org
                </a>
              </span>
              {/* <p className="text-span-landing-contact">
                <a href="tel:(973) 544-8297">
                  <span
                    className="text-span-landing-contact"
                    style={{ fontSize: 18 }}
                  >
                    (973) 544-8297
                  </span>
                </a>
              </p> */}
            </h6>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <div className="footer-container w-container">
          <div className="footer-column">
            {/* <div className="footer-column-name">CATEGORIES</div> */}
            <a onClick={startSearch} className="footer-link">
              Home
            </a>
            <a onClick={userSignUp} className="footer-link">
              Sign up
            </a>
            <a onClick={userSignIn} className="footer-link">
              Log in
            </a>
            <a
              href="mailto:hello@venuely.org"
              target="_blank"
              style={{ fontSize: 18, marginTop: 12 }}
            >
              hello@venuely.org
            </a>
            <a href="#" className="footer-link"></a>
          </div>
          <div className="footer-column">
            <a href="/faq" className="footer-link">
              FAQ
            </a>
            <a href="/privacy-policy" className="footer-link">
              Privacy Policy
            </a>
            <a href="/terms-of-use" className="footer-link">
              Terms of service
            </a>
          </div>
          <div className="footer-column email-culumn">
            <div className="footer-column-name">PRODUCT UPDATE NEWSLETTER</div>
            <div id="Product-Update-Newsletter" className="w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                className="_wf-form"
              >
                <input
                  type="text"
                  className="text-field name w-input"
                  maxLength="256"
                  name="Name"
                  data-name="Name"
                  placeholder="Enter your name"
                  value={newsletterName}
                  onChange={(e) => setNewsletterName(e.target.value)}
                  id="Name-5"
                  required
                />
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="Enter your email"
                  value={newsletterEmail}
                  onChange={(e) => setNewsletterEmail(e.target.value)}
                  id="Email"
                  required
                />
                <input
                  type="address"
                  className="text-field w-input"
                  maxLength="256"
                  name="Address"
                  data-name="Address"
                  placeholder="Enter your city"
                  value={newsletterAddress}
                  onChange={(e) => setNewsletterAddress(e.target.value)}
                  id="Address"
                  required
                />
                <input
                  type="submit"
                  onClick={submitNewsLetter}
                  value="Submit"
                  data-wait="Please wait..."
                  className="_wf-outline-button white-outline 
            submit w-button"
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            {/* <div className="date">We value your privacy.</div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RenterLanding
