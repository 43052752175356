import React, { useState, useEffect, createRef } from 'react'
import { userType } from '../redux/contants/user-type'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../redux/actions/AuthAction'

export default function Login(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)
  const auth = useSelector((state) => state.auth)

  // useEffect(() => {
  //   if (auth.authenticated) {
  //     navigate('/')
  //   }
  // }, [auth.authenticated])

  // const onFinish = async (values) => {
  //   setloading(true)
  //   await dispatch(login({
  //     ...values,
  //     callBack:(response)=>{
  //       setloading(false)
  //       let is_ok = response.status == 200
  //       if(!is_ok) {
  //         console.log(response.data.detail)
  //         notification['error']({
  //           message: 'Oops.',
  //           description: response.data.detail,
  //         })
  //       }
  //     }
  //   }))
  // }

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo)
  // }

  return <>this is login page</>
}
