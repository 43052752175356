import React, { useEffect, useState } from 'react'
// import './css/normalize.css'
// import './css/venuely-landing-page-ideations.webflow.css'
// import './css/webflow.css'
import HeaderBar from '../../components/HeaderBar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { baseURL } from '../../redux/requests'
import DropDownMenu from '../../components/menus/DropDownMenu'
import SearchOne from '../../components/searchBoxes/SearchOne'
import useWindowDimensions from '../../hooks/useWindowDimensions'
const MAX_WIDTH = 1000
const NOT_SUPPORTED_MSG =
  'Venuely is currently not supported on mobile. Please sign up using your desktop device.'

function Faq() {
  const navigate = useNavigate()
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const authUser = useSelector((state) => state.auth.user)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [newsletterName, setNewsletterName] = useState(null)
  const [newsletterEmail, setNewsletterEmail] = useState(null)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [allActivities, setallActivities] = useState([])
  const [event, setEvent] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const { width } = useWindowDimensions()
  const [navbarVisibile, setNavbarVisibile] = useState(true)

  const userSignUp = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(false)
    setShowRegistrationForm(!showRegistrationForm)
  }
  const userSignIn = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(!showLoginForm)
  }
  const errorMsg = (err) => (
    <label style={{ fontSize: 12, textTransform: 'uppercase' }}>{err}</label>
  )
  const startSearch = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    navigate('/renter/search')
  }
  const submitNewsLetter = (e) => {
    e.preventDefault()
    const cookies = new Cookies()
    const authData = cookies.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    const data = {
      name: newsletterName,
      email: newsletterEmail,
    }
    axios
      .post(`${baseURL}/user/subscribe`, data, config)
      .then((res) => {
        if (res.status === 200) {
          message.success(res.data)
        }
      })
      .catch((err) => {
        message.error(
          err ? errorMsg(err.response.data) : 'Something went wrong',
        )
      })
  }
  // useEffect(() => {
  //   const script = document.createElement('script')
  //   script.src = `js/webflow.js`
  //   script.async = true
  //   document.body.appendChild(script)
  // }, [])
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <div className="hide-scrollbar">
      {/* <p className="text-lime-400">Hello</p> */}
      {/* <p>hello</p> */}
      <div
        style={{
          boxShadow: 'rgba(128, 128, 128, 0.2) 0px 1px 10px',
          height: 80,
          transition: 'linear 0.2s all',
          backgroundColor: '#fff',
          top: 0,
          left: 0,
          position: 'sticky',
          zIndex: 10,
          opacity: navbarVisibile ? 1 : 0,
        }}
      >
        <div className="navbar__parent">
          <Link to={'/'}>
            <img
              src="/venuely.svg"
              alt=""
              style={{ width: 130, transition: 'ease-in-out 0.2s all' }}
              className="nav-logo"
            />
          </Link>
          <SearchOne />
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <h3 className="link_text" onClick={() => navigate('/become-host')}>
              Become a host
            </h3>
            <div className="right__side__links">
              {!isauthenticated ? (
                <>
                  <h3 className="link_text" onClick={userSignUp}>
                    Sign up
                  </h3>
                  <h3 className="link_text" onClick={userSignIn}>
                    Sign in
                  </h3>
                </>
              ) : (
                <DropDownMenu
                  showDropdownMenu={showDropdownMenu}
                  setShowDropdownMenu={setShowDropdownMenu}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <iframe
        // src="http://localhost:5500/faq-page.html"
        // src="/faq/faq-page.html" // working on localhost and build version of react app
        src="https://curious-faun-8292ac.netlify.app/"
        frameborder="0"
        width={'100%'}
        style={{ border: '1px solid white', height: `100vh` }}
      ></iframe>
      {/* <div className="footer-section">
        <div className="footer-container w-container">
          <div className="footer-column">
            <a onClick={startSearch} className="footer-link">
              Home1
            </a>
            <a onClick={userSignUp} className="footer-link">
              Sign up
            </a>
            <a onClick={userSignIn} className="footer-link">
              Log in
            </a>
            <Link to={'/become-host'} className="footer-link">
              Become a host
            </Link>
            <a href="#" className="footer-link"></a>
          </div>
          <div className="footer-column">
            <a href="/faq" className="footer-link">
              FAQ
            </a>
            <a href="/privacy-policy" className="footer-link">
              Privacy
            </a>
            <a href="/terms-of-use" className="footer-link">
              Terms of service
            </a>
          </div>
          <div className="footer-column email-culumn">
            <div className="footer-column-name">PRODUCT UPDATE NEWSLETTER</div>
            <div id="Product-Update-Newsletter" className="w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                className="_wf-form"
              >
                <input
                  type="text"
                  className="text-field name w-input"
                  maxLength="256"
                  name="Name"
                  data-name="Name"
                  placeholder="Enter your name"
                  value={newsletterName}
                  onChange={(e) => setNewsletterName(e.target.value)}
                  id="Name-5"
                  required
                />
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength="256"
                  name="Email"
                  data-name="Email"
                  placeholder="Enter your email"
                  value={newsletterEmail}
                  onChange={(e) => setNewsletterEmail(e.target.value)}
                  id="Email"
                  required
                />
                <input
                  type="submit"
                  onClick={submitNewsLetter}
                  value="Submit"
                  data-wait="Please wait..."
                  className="_wf-outline-button white-outline 
            submit w-button"
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="date">We value your privacy.</div>
          </div>
        </div>
      </div> */}

      {/* <div className="page-wrapper">
        <div className="section-2 hero wf-section">
          <div
            data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a103"
            style={{
              WebkitTransform:
                'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              MozTransform:
                'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              msTransform:
                'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              transform:
                'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
              opacity: 0,
            }}
            className="container-default w-container"
          >
            <div className="container-700px-center">
              <img
                src="images/Veneuly-FAQ.jpg"
                loading="lazy"
                srcSet="images/Veneuly-FAQ-p-500.jpg 500w, images/Veneuly-FAQ-p-800.jpg 800w, images/Veneuly-FAQ-p-1080.jpg 1080w, images/Veneuly-FAQ.jpg 1200w"
                sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, 700px"
                alt=""
                className="faq-image"
              />
              <div className="text-center">
                <h1>Frequently Asked Questions</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section-2 cloneables-listing wf-section">
          <div className="container-default w-container">
            <div
              data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a17a"
              style={{ opacity: 0 }}
              className="cloneable-container-default"
            >
              <div
                data-duration-in={300}
                data-duration-out={100}
                data-current="Host"
                data-easing="ease"
                className="accordion-tabs---brix preview-page w-tabs"
              >
                <div
                  data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a17c"
                  style={{ opacity: 0 }}
                  className="accordion-tabs-menu---brix w-tab-menu"
                >
                  <a
                    data-w-tab="Host"
                    className="tab-button---host w-inline-block w-tab-link w--current"
                  >
                    <div>Host</div>
                  </a>
                  <a
                    data-w-tab="Guest"
                    className="tab-button---guest w-inline-block w-tab-link"
                  >
                    <div>Guest</div>
                  </a>
                  <a
                    data-w-tab="Tab 3"
                    className="tab-button---brix w-inline-block w-tab-link"
                  >
                    <div>Section 3</div>
                  </a>
                </div>
                <div className="tabs-container---brix w-tab-content">
                  <div
                    data-w-tab="Host"
                    className="tab---brix w-tab-pane w--tab-active"
                  >
                    <div className="accordion-item-wrapper---brix tabs-accordion---brix">
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a193"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>Who can be a Venuely Host?</strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Any house of worship, of any faith, can become a
                            Venuely host. It is not required that there be an
                            active congregation. We are just looking for amazing
                            structures to share their space.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a189"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong className="bold-text">
                              I'm a host in NYC, how can Venuely support me?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            We are thrilled that you are going to join Venuely!
                            Luckily, since you are in NYC, our development
                            laboratory, we have lots of ways to support you. Our
                            system is currently outfitted so that any renter in
                            NYC looking to share space can search on our site,
                            see if there are any spaces available that fit their
                            needs, and book it - all in one place! Just like
                            AirBnB, we will list any spaces in NYC that fit the
                            criteria they list. Then they will be able to
                            preview all those spaces before making a selection.
                            <br />
                            <br />
                            Venuely also gives its hosts the ability to not only
                            chat with potential renters in advance but also to
                            approve those bookings too. All renters will have to
                            agree to your space rules and policies before
                            completing their booking. Plus, once the booking is
                            approved and confirmed, the payment goes directly
                            through our system and will routinely make direct
                            deposits into your linked bank account.
                            <br />
                            <br />
                            In addition, as a NYC host, you can take advantage
                            of our Venuely Verified status. Venuely Verified is
                            a status checkmark (Image above) letting potential
                            renters know that the space photos, measurements,
                            and amenities have all been Verified by Venuely's
                            partners. This gives your space an extra boost in
                            the eyes of the renter who knows that they can use
                            your space confident that it has exactly what was
                            described.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a19d"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              How do I earn money as a house of faith?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Once you have uploaded all of your rooms to
                            Venuely's system, prospective guests can browse your
                            listing and see if it fits their needs. You will
                            also connect your bank account to Venuely's secure
                            payment processing program. Venuely processes the
                            funds and will automatically send those funds to
                            your bank account. So, so simple!
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="7d26ebb1-fde6-2ccc-2ca2-da9db4e3fa45"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              Does Venuely take a transaction fee?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Yes, Venuely takes a 5% fee per transaction;
                            however, that cost is paid by the Guests not our
                            Hosts. While we wish we could provide these services
                            100% for free, there is a cost to maintain and host
                            and update the website as well as keep good customer
                            service standards. This fee helps us pay for those
                            services to keep Venuely working for you!
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a1b1"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>How do I set my pricing?</strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            There are many ways to answer this question. You can
                            set your pricing based on: anticipated wear and tear
                            on your space, the costs to run and maintain your
                            space for any given time, the mission and vision
                            alignment of the prospective Guest to your
                            institution, and the local going commercial rate.
                            You also have an opportunity to create diffeshare of
                            prices for Events, Meetings-Classes-Rehearsals, and
                            Media/Production.
                            <br />
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a1bb"
                        className="accordion-item---brix tabs-accordion---brix last-accordion-item---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              Will sharing my space impact my 501c3 status?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            The Internal revenue code requires that 501c3
                            organizations be organized and operated exclusively
                            for exempt purposes{' '}
                            <a href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations">
                              Exemption Requirements - 501(c)(3) Organizations |
                              Internal Revenue Service (irs.gov)
                            </a>
                            . While, as a rule, all income unrelated to a
                            501c3’s organization IRS approved purposes are
                            subject to unrelated business income tax (UBIT),
                            most shareal income derived from the shareing of
                            real property is specifically exempt from UBIT,
                            provided that not more than 50% of the share is for
                            the use of personal property, the property is not
                            debt-financed income or leased to a controlled
                            entity, and the organization is not exempt under
                            Sections
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Guest" className="tab---brix w-tab-pane">
                    <div className="accordion-item-wrapper---brix tabs-accordion---brix">
                      <div
                        data-w-id="64dfd70a-182f-689a-a4df-f4ec23e50917"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              Does Venuely take a transaction fee?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Yes, Venuely charges a 5% fee per transaction paid
                            for by our Guests. While we wish we could provide
                            these services 100% for free, this helps us maintain
                            and host and update the website as well as keep good
                            customer service standards. This fee helps us pay
                            for those services to keep Venuely working for you!
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a1c7"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              I'm a guest in NYC, how can Venuely support me?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Venuely allows potential guests to chat with
                            potential hosts in advance of following through with
                            any booking. In addition, in the Venuely approval
                            system, the renter must read and agree to space use
                            policies in advance meaning that you as the renter
                            are fully aware of what is or what is not allowed
                            within the space and what are the expectations of
                            using that space for the host. In additional good
                            news for our renters, no credit cards are charged
                            until the host follows through and approves the
                            booking.
                            <br />
                            <br />
                            Lastly, look for the Venuely Verified checkmark(Icon
                            above) when selecting your venue. The Venuely
                            Verified is a status checkmark letting potential
                            renters know that the space photos, measurements,
                            and amenities have all been Verified by Venuely's
                            partners. This gives the renters confidence in
                            knowing that your space is exactly as it was
                            described.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a1d1"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              Can I book the same day of the week for the same
                              time of day, for example, three Tuesdays in a row
                              from 4pm-8pm?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Currently, Venuely doesn't have the capacity to do
                            repeat bookings in this manner. In the above
                            example, the Guest would have to book each Tuesday
                            from 4 pm-8 pm individually, so they would have
                            three bookings all for Tuesdays at 4 pm for four
                            hours. Our system currently works best when used on
                            consecutive days for the same time each day.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a1db"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            <strong>
                              I'm a guest outside of NYC, how can Venuely
                              support me?
                            </strong>
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            As a Guest outside of the NYC area, Venuely may not
                            have spaces for you to browse in your area. However,
                            as the number of Venuely hosts grows, so does the
                            possibility that one of our hosts is in your area!
                            We are also open to starting conversations with
                            hosts or Guests outside of NYC about how to get more
                            Venuely in your area. Venuely is free for our hosts
                            and Guests to join at any time. The purpose of our
                            site is two fold. <br />
                            <br />
                            To help our hosts by providing a platform where they
                            can post the spaces they have to share with outside
                            users and help potential Guests in any given region
                            with a variety of spaces to choose from for their
                            upcoming event needs. Venuely keeps your financial
                            information safe via our secure Stripe payment
                            system and gives Guests confidence that they are
                            supporting hosts in their area who have missions
                            they wish to support.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 3" className="tab---brix w-tab-pane">
                    <div className="accordion-item-wrapper---brix tabs-accordion---brix">
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a205"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            What is Webflow and why is it the best website
                            builder?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a20f"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            What is your favorite template from BRIX Templates?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a219"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            How do you clone a template from the Showcase?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a223"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            Why is BRIX Templates the best Webflow agency?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a22d"
                        className="accordion-item---brix tabs-accordion---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            When was Webflow officially launched?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a237"
                        className="accordion-item---brix tabs-accordion---brix last-accordion-item---brix"
                      >
                        <div className="accordion-trigger---brix">
                          <div
                            style={{ color: 'rgb(23,15,73)' }}
                            className="accordion-item-title---brix"
                          >
                            How do you integrate Jetboost with Webflow?
                          </div>
                          <div className="open-close-icon-wrapper---brix">
                            <div
                              style={{ backgroundColor: 'rgb(23,15,73)' }}
                              className="open-close-line---brix"
                            />
                            <div
                              style={{
                                WebkitTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                MozTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                msTransform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                                transform:
                                  'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              }}
                              className="open-close-line---brix second-line---brix"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            height: '0px',
                            WebkitTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            MozTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            msTransform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            transform:
                              'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                            opacity: 0,
                          }}
                          className="accordion-content---brix"
                        >
                          <p className="accordion-paragraph---brix">
                            Vitae congue eu consequat ac felis placerat
                            vestibulum lectus mauris ultrices. Cursus sit amet
                            dictum sit amet justo donec enim diam porttitor
                            lacus luctus accumsan tortor posuere.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="heading-5">Help Center - Creating a Listing</h2>
        <div className="section-2 cloneables-listing wf-section">
          <div className="container-default w-container">
            <div
              data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a136"
              style={{ opacity: 0 }}
              className="cloneable-container-default"
            >
              <div className="accordion-item-wrapper---brix preview-page">
                <div
                  data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a138"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>How do I&nbsp;create a listing?</strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      To create a listing:
                      <br />‍<br />
                      1. Visit https://venuely.org.
                      <br />‍<br />
                      2. Click "Sign in" in the upper right corner, then "I'm a
                      Host."
                      <br />‍<br />
                      3. Enter the email address and password you used to create
                      your account, or click
                      <br />
                      "Continue with Google" if you signed up with Google.
                      <br />‍<br />
                      4. Click "Your Space" near the top right hand corner to
                      access your listings.
                      <br />‍<br />
                      5. Click "Add a Space" and be redirected to "Your Space:
                      Listing Setup"
                      <br />‍<br />
                      6. Add in all the necessary information to make your venue
                      perfect for future reservations.
                      <br />‍<br />
                      7. Link to see visual step by step guide
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a143"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>Can I upload photos to my listing?</strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      Yes! You can show off your venue by uploading at least
                      four photos! We recommend up to 8 photos to highlight your
                      best looks. The first photo will be the default photo for
                      your listing and will be the first thing people see, so
                      choose a strong one that will catch the guests' attention.
                      You can change the default photo by rearranging the order
                      the photos appear.
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a14e"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>What can I post on Venuely?</strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      Venuely was created for houses of worship and sacred sites
                      to share their spaces. If you have a house of worship and
                      sacred site with space available, you can post one or more
                      listings on Venuely.
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a159"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>How much does it cost to post a listing?</strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      We never charge to post a listing on Venuely.&nbsp;Venuely
                      is operated by a non-profit organization (Bricks and
                      Mortals) founded to assist houses of faith and their
                      communities. We do charge a 5% fee per transaction in
                      order to keep the website running and maintain high
                      customer service standards.
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="063d2f5a-bb79-6dbd-2187-1e36cb28bf56"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>Do you edit my listing?</strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      You have full control over what your listing says about
                      your available spaces. Once you have finished setting up
                      your space, you submit your listing for approval by our
                      moderators. If we have any further questions, we will
                      contact you via email to confirm the information. Once
                      your listing is approved, you will be able to accept
                      reservations right away. You can always update your
                      listings!
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="3b504b45-76a9-099e-6226-f8436100baa5"
                  style={{ borderColor: 'rgba(0,0,0,0)' }}
                  className="accordion-item---brix accordion-1---brix"
                >
                  <div className="accordion-trigger---brix">
                    <div className="accordion-item-title---brix">
                      <strong>
                        Are there any types of listings you don’t allow?
                      </strong>
                    </div>
                    <div
                      style={{ backgroundColor: 'rgb(255,255,255)' }}
                      className="accordion-arrow-wrap---brix"
                    >
                      <div
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                        }}
                        className="accordion-arrow-wrapper---brix"
                      >
                        <div className="arrow-line---brix _2---brix" />
                        <div className="arrow-line---brix _1---brix" />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '0px',
                      WebkitTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      MozTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      msTransform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      transform:
                        'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                      opacity: 0,
                    }}
                    className="accordion-content---brix"
                  >
                    <p className="accordion-paragraph---brix">
                      Venuely does not permit the posting of spaces/listings
                      that include the following: non-houses of faith or sacred
                      sites, third-parties posting a property that they do not
                      own or do not have permission to rent out the following
                      space, and spaces that are not permitted to be rented out.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="heading-5">Help Center - Managing Your Listing</h2>
        <div className="section-2 cloneables-listing wf-section">
          <div className="container-default w-container">
            <div
              data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a257"
              style={{ opacity: 0 }}
              className="cloneable-container-default"
            >
              <div className="accordion-columns-wrapper---brix preview-page">
                <div className="column---brix left---brix">
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a25a"
                    className="accordion-item---brix accordion-3---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>
                          Can I edit my listing after submitting/approved?
                        </strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        You certainly can! You can edit your listing after it
                        has been approved by going to the "Your Space" tab in
                        your account.
                        <br />
                        <br />
                        To make changes to your listing:
                        <br />
                        <br />
                        1. Visit https://venuely.org.
                        <br />
                        <br />
                        2. Click "Sign in" in the upper right corner, then "I'm
                        a Host."
                        <br />
                        <br />
                        3. Enter the email address and password you used to
                        create your account, or click
                        <br />
                        "Continue with Google" if you signed up with Google.
                        <br />
                        <br />
                        4. Click "Your Space" near the top right hand corner to
                        access your listings.
                        <br />
                        <br />
                        5. Scroll down the page until you find the listing you
                        want to change, then click the "Edit"
                        <br />
                        button.
                      </p>
                    </div>
                  </div>
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a265"
                    className="accordion-item---brix accordion-3---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>How do I share a link to my listing?</strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        To share your listing:
                        <br />
                        <br />
                        1. Log in to your host account
                        <br />
                        <br />
                        2. Click User Avatar Icon on the top right hand corner
                        <br />
                        <br />
                        3. Click "Profile" to access your host profile
                        <br />
                        <br />
                        4. Find the listing you want to repost.
                        <br />
                        <br />
                        5. Click "Share" Icon by on the top right hand corner of
                        the listing <br />
                        photo slides. And click "copy link button". Or simply
                        copy the url address of your listing from the browser
                        and
                        <br />
                        share them by pasting the url.
                      </p>
                    </div>
                  </div>
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a270"
                    className="accordion-item---brix accordion-3---brix last-accordion-item---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>
                          What if a broker contacts me about my listing?
                        </strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        Please send an email to hello@venuely.org with their
                        name, email address, and workplace. We will actively
                        remove them from Venuely.org because our platform is not
                        designed for such transactions.
                      </p>
                    </div>
                  </div>
                  <div
                    data-w-id="0be1315b-0ece-ffc0-9b0f-24466aedb5f2"
                    className="accordion-item---brix accordion-4--brix-copy"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>
                          Will sharing my space impact my 501c3 status?
                        </strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        The Internal revenue code requires that 501c3
                        organizations be organized and operated exclusively for
                        exempt purposes{' '}
                        <a href="https://www.irs.gov/charities-non-profits/charitable-organizations/exemption-requirements-501c3-organizations">
                          Exemption Requirements - 501(c)(3) Organizations |
                          Internal Revenue Service (irs.gov)
                        </a>
                        .&nbsp; While, as a rule, all income unrelated to a
                        501c3’s organization IRS approved purposes are subject
                        to unrelated business income tax (UBIT), most shareal
                        income derived from the sharing of real property is
                        specifically exempt from UBIT, provided that not more
                        than 50% of the share is for the use of personal
                        property, the property is not debt-financed income or
                        leased to a controlled entity, and the organization is
                        not exempt under Sections 501(c)(7), 501(c)(9) or IRC
                        501(c)(17),{' '}
                        <a href="https://www.irs.gov/charities-non-profits/exclusion-of-rent-from-real-property-from-unrelated-business-taxable-income">
                          Exclusion of share from Real Property from Unrelated
                          Business Taxable Income | Internal Revenue Service
                          (irs.gov)
                        </a>
                        &nbsp;
                        <br />
                        <br />
                        Nevertheless faith based organizations should evaluate
                        the tax implications of any space use agreement with
                        their attorney.&nbsp; Such agreements may have federal,
                        state and local tax implications on income, real
                        property and sales tax.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column---brix right---brix">
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a27c"
                    className="accordion-item---brix accordion-3---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>
                          &nbsp;What if I need to remove my listing?
                        </strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        After you've submitted your listing, you can remove it
                        at any time.
                        <br />
                        Sign in to your account to remove your listing:
                        <br />‍<br />
                        1. Visit https://venuely.org.
                        <br />
                        <br />
                        2. Click "Sign in" in the upper right corner, then "I'm
                        a Host."
                        <br />
                        <br />
                        3. Enter the email address and password you used to
                        create your account, or click "Continue with Google" if
                        you signed up with Google.
                        <br />
                        <br />
                        4. Click "Your Space" near the top right hand corner to
                        access your listings.
                        <br />
                        <br />
                        5. Scroll down the page until you find the listing you
                        want to remove.
                        <br />
                        <br />
                        6. Choose "Remove" and confirm your decision.
                      </p>
                    </div>
                  </div>
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a287"
                    className="accordion-item---brix accordion-3---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>Does my listing expire?</strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        No, your listing does not expire. Your listing(s) should
                        be kept up-to-date with any changes, and we ask that
                        listings be updated or maintained on an annual basis to
                        keep the information accurate and up to date.
                      </p>
                    </div>
                  </div>
                  <div
                    data-w-id="83d2bbeb-dd93-8b67-3cce-acf1c909a292"
                    className="accordion-item---brix accordion-3---brix last-accordion-item---brix"
                  >
                    <div className="accordion-trigger---brix">
                      <div className="accordion-item-title---brix accordion-3---brix">
                        <strong>
                          Why can’t my listing go live as soon as I submit it?
                        </strong>
                      </div>
                      <div
                        style={{ backgroundColor: 'rgb(247,247,251)' }}
                        className="accordion-3-open-close-icon-wrapper---brix"
                      >
                        <div className="open-close-icon-wrapper---brix large-icon---brix">
                          <div
                            style={{ backgroundColor: 'rgb(81,79,110)' }}
                            className="large-open-close-line---brix"
                          />
                          <div
                            style={{
                              WebkitTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              MozTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              msTransform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                              transform:
                                'translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)',
                            }}
                            className="large-open-close-line---brix second-line---brix"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ height: '0px' }}
                      className="accordion-content---brix"
                    >
                      <p
                        style={{
                          WebkitTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          MozTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          msTransform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          transform:
                            'translate3d(0, 0, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
                          opacity: 0,
                        }}
                        className="accordion-paragraph---brix"
                      >
                        We must ensure that everything is correct and
                        appropriate for our website. Our moderators will work as
                        quickly as possible to ensure that your listing is ready
                        for guests to check out and reserve their reservation.
                        If you have any questions or if you are not sure you
                        submitted your listing correctly please contact us at
                        hello@venuely.org.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/blob-faq-accordions-webflow-cloneable-template-brix-templates.png"
          srcSet="images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-500.png 500w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-800.png 800w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-1080.png 1080w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates.png 1843w"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 60vw, 50vw"
          alt=""
          className="blob _1"
        />
        <img
          src="images/blob-faq-accordions-webflow-cloneable-template-brix-templates.png"
          srcSet="images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-500.png 500w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-800.png 800w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates-p-1080.png 1080w, images/blob-faq-accordions-webflow-cloneable-template-brix-templates.png 1843w"
          sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 60vw, 50vw"
          alt=""
          className="blob _2"
        />
      </div> */}
    </div>
  )
}

export default Faq
