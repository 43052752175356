import React, { useState, useEffect } from 'react'
import arrowDown from '../../img/arrow_down_1.png'

function getAllDateBetweenTwoDate(start, end) {
  let dates = []
  let numberOfDays = end.getDate() - start.getDate()
  for (var i = 0; i <= numberOfDays; i++) {
    let DateObject = new Date(
      `${start.getMonth() + 1} ${start.getDate()},${start.getFullYear()}`,
    )
    // console.log(start.getMonth());
    DateObject.setDate(DateObject.getDate() + i)
    dates.push(DateObject)
  }

  return dates
}

export default function GridCalenderInformation({
  dateRande,
  selectedMonth,
  SelectedYear,
}) {
  // let DateObject = new Date()
  let numberOfDays = 0
  let numberOfSpaces = [
    'Example space 1',
    'Example space 2',
    'Example space 3',
    'Example space 4',
    'Example space 5',
    'Example space 6',
    'Example space 7',
    'Example space 8',
    'Example space 9',
    'Example space 10',
    'Example space 11',
    'Example space 12',
  ]
  let spaceNameList = []
  let DayNameList = []
  let list = []
  let days = []
  let resarvations = [
    { title: '10 am resarvation 3', isConfirmed: false },
    { title: '5 am resarvation 1', isConfirmed: true },
  ]
  let resarvationList = []
  const [shiftTheCalender, setShiftTheCalender] = useState(0)
  const [countForCalenderShif, setCountForCalenderShif] = useState({
    left: 0,
    right: 0,
  })
  useEffect(() => {
    setCountForCalenderShif({
      left: numberOfSpaces.length / 2 + 1,
      right: 0,
    })
  }, [1])

  let { start, end } = dateRande
  resarvations.map((res) => {
    resarvationList.push(
      <div key={res.title} className="per-day-resarvation">
        {' '}
        <div
          className="is-active-radius"
          style={{ background: res.isConfirmed ? '#4BBE88' : 'red' }}
        ></div>{' '}
        <p>{res.title}</p>{' '}
      </div>,
    )
  })

  if (start != null && end != null) {
    list = getAllDateBetweenTwoDate(start, end)
    numberOfDays = end.getDate() - start.getDate() + 1
  } else {
    let startDate = new Date(`${selectedMonth.monthNumber} 1,${SelectedYear}`)
    let endDate = new Date(`${selectedMonth.monthNumber} 1,${SelectedYear}`)
    endDate.setDate(endDate.getDate() + 6)
    numberOfDays = 7
    list = getAllDateBetweenTwoDate(startDate, endDate)
  }

  numberOfSpaces.map((spaice, index) => {
    days.push(
      <div className="space-name top-part-ot-table" key={index + 'spce-name'}>
        {' '}
        {spaice}{' '}
      </div>,
    )
  })

  list.map((date) => {
    DayNameList.push(
      <div className="day-name-list">
        <p>{date.getDate()}</p>
        <p>{date.toLocaleDateString('locale', { weekday: 'short' })} </p>
      </div>,
    )
  })

  for (let i = 1; i <= numberOfDays * numberOfSpaces.length; i++) {
    days.push(
      <div
        key={i + 'resarvation-list'}
        className="resarvation-list-div-wrapper"
      >
        <div className="resarvation-list-div">
          {' '}
          {i % 5 == 1 ? resarvationList.map((r) => r) : ''}{' '}
        </div>
      </div>,
    )
  }
  let shift = (left = true) => {
    if (left) {
      if (countForCalenderShif.left > 0) {
        setCountForCalenderShif({
          left: countForCalenderShif.left - 1,
          right: countForCalenderShif.right + 1,
        })
        setShiftTheCalender(shiftTheCalender - 312)
      }
      return null
    }
    if (countForCalenderShif.right > 0) {
      setCountForCalenderShif({
        left: countForCalenderShif.left + 1,
        right: countForCalenderShif.right - 1,
      })
      setShiftTheCalender(shiftTheCalender + 312)
    }
    return null
  }

  return (
    <div style={{ position: 'relative' }}>
      <img
        onClick={() => {
          shift(true)
        }}
        className="arrow shift-calender-left leftArrow"
        src={arrowDown}
        alt=""
        srcSet=""
      />
      <div className="day-name-list-wrapper">
        <div className="day-name-list top-part-ot-table"></div>
        {DayNameList.map((d) => d)}
      </div>
      <div className="grid-show-calander-wrapper">
        <div
          className="grid-show-calander"
          style={{
            gridTemplateColumns: `repeat(${numberOfSpaces.length},7fr)`,
            marginLeft: shiftTheCalender,
          }}
        >
          {days.map((d) => d)}
        </div>
      </div>
      <img
        onClick={() => {
          shift(false)
        }}
        className="arrow shift-calender-right rightArrow"
        src={arrowDown}
        alt=""
        srcSet=""
      />
    </div>
  )
}
