import React from 'react'
import { Navigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

export default function Auth({ children }) {
  const auth = useSelector((state) => state.auth.authenticated)
  const location = useLocation()

  if (location.pathname.includes('/profile/')) {
    // Then don't check the 'auth' instead allow to proceed
    return children
  }

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} />
  }
  return children
}
