import React, { useEffect, useState } from 'react'
import {
  acceptSelectedReservation,
  cancelSelectedReservation,
  setReservation,
} from '../../redux/actions/PropertyAction'
import { useDispatch } from 'react-redux'
import { baseURL, tConvert } from '../../redux/requests'
import greenTik from './../../img/green_done_mark.png'
import unsmilingFace from './../../img/unsmiling_face.png'
import dummy_renter_image from './images/dummy_user.png'
import emailPhoneMasking, {
  maskingEmail,
  maskingPhone,
} from 'word-masking-helper'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { getICSFileData } from '../../redux/actions/ComponentAction'
export default function ResarvationDetailsCardV2({
  resarvation,
  setSelectedReservation,
  pageName,
  resetData,
}) {
  const [selectedStepName, setSelectedStepName] = useState('details')
  const [hideDenyButton, setHideDenyButton] = useState(false)
  const [hideAcceptButton, setHideAcceptButton] = useState(false)
  const [cancelationReason, setCancelationReason] = useState('')
  const [message, setmessage] = useState('')

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log(resarvation);
    return () => {
      setSelectedStepName('details')
      setCancelationReason('')
      setmessage('')
    }
  }, [resarvation])
  let handleConfirm = () => {
    setHideAcceptButton(true)
    dispatch(
      acceptSelectedReservation({
        id: resarvation.id,
        callBack: (response) => {
          dispatch(
            setReservation({
              callBack: () => {
                setHideAcceptButton(false)
                window.scrollTo(0, 0)
                setSelectedStepName('confirm')
                resetData()
              },
            }),
          )
        },
      }),
    )
  }

  let dateConvertForReservation = (date) => {
    var dateObj = new Date(date)
    return `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`
  }

  let handleCancel = (e) => {
    e.preventDefault()
    if (cancelationReason != null && message != null) {
      setHideDenyButton(true)
      dispatch(
        cancelSelectedReservation({
          id: resarvation.id,
          message_to_renter: e.target.elements.message_to_renter.value,
          cancelation_reason: e.target.elements.cancelation_reason.value,
          callBack: (response) => {
            dispatch(
              setReservation({
                callBack: (response) => {
                  setHideDenyButton(false)
                  window.scrollTo(0, 0)
                  setSelectedStepName('cancel')
                  resetData()
                },
              }),
            )
          },
        }),
      )
    }
  }

  let handleChange = (e) => {
    if (e.target.name == 'cancelation_reason') {
      setCancelationReason(e.target.value)
      // setCancelationReason()
    }

    if (e.target.name == 'message_to_renter') {
      setmessage(e.target.value)
      // setmessage
    }
    console.log(e.target.name)
  }

  let download = (filename, text) => {
    var element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
    )
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()
    document.body.removeChild(element)
  }

  // Start file download.
  let handle_download = (reservation) => {
    console.log(reservation)
    let activity = reservation.activity.title,
      alcohol_consumption = reservation.alcohol_consumption ? 'Yes' : 'No',
      guest_name = reservation.guest.name,
      guest_email = reservation.guest.email,
      guest_Job_title = reservation.guest.Job_title ?? '',
      guest_Organization = reservation.guest.Organization ?? '',
      expected_guest_amount = reservation.expected_guest_amount,
      space_name = reservation.property.name,
      total_amount = String(reservation.total_amount),
      total_day = String(reservation.total_day),
      total_hour_per_day = String(reservation.total_hour_per_day),
      description = `\n Space Name: ${space_name} \n Guest Name: ${guest_name} \n Guest Email: ${guest_email} \n Guest Job Title: ${guest_Job_title} \n Guest Organization: ${guest_Organization} \n Activity: ${activity} \n Expected guest Amount: ${expected_guest_amount} \n Alcohol Consumption: ${alcohol_consumption} \n Total Amount: ${total_amount} \n Total Day: ${total_day} \n`
    // console.log(description);
    dispatch(
      getICSFileData({
        start_date: reservation.start_date,
        end_date: reservation.end_date,
        start_time: reservation.start_time,
        end_time: reservation.end_time,
        summary: `Reservation for ${space_name}`,
        description: description,
        address: reservation.property.address,
        attendee: [guest_email],
        callBack: (response) => {
          download(response.data.name, response.data.data)
        },
      }),
    )
  }

  let confirm_cancel_step = (
    <div className="confirm-cancel-reservation-box">
      <img src={unsmilingFace} alt="" />
      <form
        onSubmit={handleCancel}
        onChange={handleChange}
        className="confirm-cancel-reservation-form"
      >
        <h4>What’s your reason for denying the request?</h4>
        <p>Select all that may apply:</p>
        <div className="cancel-confirm-form-reason-select-box">
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="scheduling_conflicts"
              name="cancelation_reason"
              value="Scheduling conflicts"
            />
            <label for="scheduling_conflicts">Scheduling conflicts.</label>
          </div>
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="guests_didnt_read_the_listing_information_or_house_rules"
              name="cancelation_reason"
              value="Guests didn’t read the listing information or house rules"
            />
            <label for="guests_didnt_read_the_listing_information_or_house_rules">
              Guests didn’t read the listing information or house rules
            </label>
          </div>
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="extra_guests_over_the_occupancy_limits"
              name="cancelation_reason"
              value="Extra guests over the occupancy limits"
            />
            <label for="extra_guests_over_the_occupancy_limits">
              Extra guests over the occupancy limits
            </label>
          </div>
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="planned_activity_does_not_work_well_in_this_space"
              name="cancelation_reason"
              value="Planned activity does not work well in this space"
            />
            <label for="planned_activity_does_not_work_well_in_this_space">
              Planned activity does not work well in this space
            </label>
          </div>
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="guest_did_not_provide_commercial_liability_insurance_in_time"
              name="cancelation_reason"
              value="Guest didn’t provide commercial liability insurance in time"
            />
            <label for="guest_did_not_provide_commercial_liability_insurance_in_time">Guest didn’t provide commercial liability insurance in time.</label>
          </div>
          <div className="confirm-cancel-reservation-reason">
            <input
              type="radio"
              id="other"
              name="cancelation_reason"
              value="Other"
            />
            <label for="other">Other</label>
          </div>
        </div>
        <div className="confirm-cancel-message-reason">
          <h4>*Message to the Guest: </h4>
          <p>This field is required</p>
          <textarea
            required
            name="message_to_renter"
            id="message_to_renter"
            cols="30"
            rows="10"
          ></textarea>
        </div>
        <div className="confirm-cancel-reservation-reason-button-group">
          <button
            type="button"
            onClick={() => {
              window.scrollTo(0, 0)
              setSelectedStepName('details')
            }}
            className="reservation-cancel-notconfirm"
          >
            <div>Cancel</div>
          </button>
          <button
            style={{
              backgroundColor: `${
                cancelationReason.length > 0 && message.length > 0
                  ? '#6568E6'
                  : ''
              }`,
            }}
            disabled={hideDenyButton}
            type="submit"
            className="reservation-cancel-confirm"
          >
            <div
              className={`reservation-loader ${
                hideDenyButton ? '' : 'hide-elements'
              }`}
            ></div>
            <div className={`${hideDenyButton ? 'hide-elements' : ''}`}>
              Next
            </div>
          </button>
        </div>
      </form>
    </div>
  )

  let confirm_step = (
    <div className="confirm-reservation-box">
      <img src={greenTik} alt="" />
      The request has been successfully accepted!
      <button
        type="button"
        onClick={() => {
          setSelectedReservation(null)
        }}
        className="confirm-reservation-box-button"
      >
        {' '}
        Back to the Dashboard{' '}
      </button>
    </div>
  )
  let cancel_step = (
    <div className="confirm-reservation-box">
      <img src={greenTik} alt="" />
      The request has been successfully denied!
      <button
        type="button"
        onClick={() => {
          setSelectedReservation(null)
        }}
        className="confirm-reservation-box-button"
      >
        {' '}
        Back to the Dashboard{' '}
      </button>
    </div>
  )
  let show_details_info = (
    <>
      <div className="resarvation-space-details">
        <div className="resarvation-space-image">
          {resarvation.property.images.length > 0 ? (
            <img
              src={`${baseURL.replace('/api', '')}${
                resarvation.property.images[0].image
              }`}
              alt=""
              className="space-thumbnail"
            />
          ) : (
            <img
              src={`${baseURL.replace('/api', '')}`}
              alt=""
              className="space-thumbnail"
            />
          )}
        </div>
        <div className="resarvation-space-information">
          <h2>Requested Space</h2>
          {resarvation.property.name}
        </div>
      </div>
      <hr />
      <div className="resarvation-information">
        <h2>Reservation Details:</h2>
        <div className="resarvation-information-group">
          <h4>Requested Date:</h4>{' '}
          <p>
            {dateConvertForReservation(resarvation.start_date)}{' '}
            {resarvation.start_date != resarvation.end_date
              ? `to ${dateConvertForReservation(resarvation.end_date)}`
              : ''}
          </p>
          <h4>Even Duration:</h4>{' '}
          <p>
            {tConvert(resarvation.start_time)} to{' '}
            {tConvert(resarvation.end_time)}
          </p>
          <h4>Planned Activity:</h4> <p>{resarvation.activity.title}</p>
          <h4>Number of guests:</h4> <p>{resarvation.expected_guest_amount} </p>
          <h4>Alcohol:</h4>{' '}
          <p>{resarvation.alcohol_consumption ? 'Yes' : 'No'} </p>
        </div>
      </div>
      <hr />
      <div className="rester-information">
        <h2>Guest Information:</h2>
        <div className="renter-information-group">
          <div className="renter-image">
            {/* <img src={`${baseURL}${resarvation.images[0].image}`} alt="" /> */}
            <img
              alt=""
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '100%',
                objectFit: 'cover',
              }}
              src={
                resarvation.guest.image
                  ? `${baseURL.replace('/api', '')}${resarvation.guest.image}`
                  : dummy_renter_image
              }
            />
          </div>
          <div className="renter-information-data-group">
            <h4>Guest Name:</h4> <p>{resarvation.guest.name}</p>
            <h4>Organization:</h4> <p>{resarvation.guest.Organization}</p>
            <h4>Job Title:</h4> <p>{resarvation.guest.Job_title}</p>
            {/* <h4>Organization:</h4>  <p>Marse Enterprise</p>
                        <h4>Job Title:</h4>  <p>Dance Instructor</p> */}
          </div>
        </div>
      </div>
      <hr />
      <div className="resarvation-information-text-area">
        <h2>Message from the Guest:</h2>
        <textarea
          value={emailPhoneMasking(resarvation.message_to_host)}
          name="message_for_renter"
          id=""
          cols="30"
          rows="10"
        ></textarea>
        <br />
        <button
          style={{
            color: '#6568E6',
            border: '1px solid #6568E6',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <NavLink to={`/messages/history/${resarvation.id}`}>
            Reply to Guest prior to making your decision
          </NavLink>
        </button>
        {pageName == 'acceptedBooking' ? (
          <button
            onClick={() => {
              handle_download(resarvation)
            }}
            style={{
              color: '#6568E6',
              border: '1px solid #6568E6',
              padding: '10px',
              marginTop: '10px',
              marginLeft: '5px',
            }}
          >
            Download ICS file
          </button>
        ) : (
          ''
        )}
      </div>
      <hr />
      <div className="reservation-price-information">
        <h2>Price detail:</h2>
        <div className="reservation-price-information-data-group">
          {/* { 
                    resarvation.price.pricing_condition.id == 1 
                    ? (<><h4>${resarvation.price.price} x {getTimeDifferenceInHour(resarvation.start,resarvation.end)} hours:   </h4>   <p>${resarvation.price.price*getTimeDifferenceInHour(resarvation.start,resarvation.end)}</p></>) 
                    : (<><h4>Fixed Price </h4>   <p>${resarvation.price.price}</p></>) 
                    } */}
          <h4>
            $
            {resarvation.is_fixed
              ? (resarvation.amount / resarvation.total_day).toFixed(2)
              : (
                  resarvation.amount /
                  resarvation.total_day /
                  resarvation.total_hour_per_day
                ).toFixed(2)}{' '}
            {!resarvation.is_fixed
              ? `x ${resarvation.total_hour_per_day} hour`
              : ''}{' '}
            x {resarvation.total_day} day:
          </h4>{' '}
          <p>${resarvation.amount.toFixed(2)}</p>
          <h4>Cleaning fee: </h4> <p>${resarvation.cleaning_fee.toFixed(2)}</p>
          <h4>Service fee: </h4>{' '}
          <p>
            $
            {(resarvation.service_fee + resarvation.transaction_fee).toFixed(2)}
          </p>
          {/* <h4>8+ hours discount:</h4>   <p> {resarvation.price.minimum_hour_for_discount == getTimeDifferenceInHour(resarvation.start,resarvation.end) ? `-$${(resarvation.price.discount/100)*resarvation.price.price}`:`$0` }</p> */}
          <h4>Coupon:</h4>{' '}
          <p>
            {' '}
            {resarvation.coupon_discount == 0 ? '' : '-'}$
            {resarvation.coupon_discount.toFixed(2)}
          </p>
          <h4>8+ hours discount:</h4>{' '}
          <p>
            {resarvation.discount == 0 ? '' : '-'}$
            {resarvation.discount.toFixed(2)}
          </p>
        </div>
        <hr style={{ width: '510px' }} />
        <div className="total-calculation">
          <h4>Total:</h4> <p>${resarvation.total_amount.toFixed(2)}</p>
        </div>
        <hr />
      </div>

      {pageName == 'pandingRequest' ? (
        <div className="resarvation-confirm-cancel-button-group">
          <button
            type="button"
            onClick={() => {
              window.scrollTo(0, 0)
              setSelectedStepName('confirm_cancel')
            }}
            className="reservation-cancel"
          >
            {/* <div className={`reservation-loader ${hideDenyButton ? '' : 'hide-elements'}`}></div> */}
            <div>Deny Request</div>
          </button>
          <button
            type="button"
            disabled={hideAcceptButton}
            onClick={handleConfirm}
            className="reservation-confirm"
          >
            <div
              className={`reservation-loader accept-loader ${
                hideAcceptButton ? '' : 'hide-elements'
              }`}
            ></div>
            <div className={`${hideAcceptButton ? 'hide-elements' : ''}`}>
              Accept Request
            </div>
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  )

  const stepList = {
    details: show_details_info,
    confirm: confirm_step,
    cancel: cancel_step,
    confirm_cancel: confirm_cancel_step,
  }
  return (
    <div>
      <button
        type="button"
        className="close-button"
        onClick={() => {
          setSelectedReservation(null)
        }}
      >
        X
      </button>
      {stepList[selectedStepName]}
    </div>
  )
}

function getTimeDifferenceInHour(start, end) {
  const d1 = new Date('1970-01-01T' + `${end}` + 'Z')
  const d2 = new Date('1970-01-01T' + `${start}` + 'Z')
  const diff = d1 - d2
  const hours = Math.floor(diff / (1000 * 60 * 60))
  return hours
}
