import { Button, Modal, Spin } from 'antd'
import axios from 'axios'
import React, { Children, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { TopMsg } from '../Topmsg'
import { message as Message } from 'antd'

const stripeApiKey = process.env.REACT_APP_STRIPE_API
const stripePromise = loadStripe(stripeApiKey)
const baseURL = process.env.REACT_APP_BASE_URL

const StripeModal = ({
  isStripeModal,
  setIsStripeModal,
  dateVal,
  dateVal2,
  startTime,
  endTime,
  price,
  space,
  alcoholConsumption,
  msgToHost,
  guestAmount,
  activity,
  setPaymentIntentId,
  setSavedCreditCard,
}) => {
  const [clientSecret, setClientSecret] = useState(null)
  const [loading, setLoading] = useState(true)
  // const [errMsg, setErrMsg] = useState(null)
  const [message, setMessage] = useState(null)

  useEffect(() => {
    const cookie = new Cookies()
    const authData = cookie.get('jwt')
    const data = {
      date: dateVal === dateVal2 ? [dateVal] : [dateVal, dateVal2],
      start: startTime,
      end: endTime,
      price: price,
      space: space,
      alcohol_consumption: alcoholConsumption,
      message_to_host: msgToHost,
      expected_guest_amount: +guestAmount,
      activity: activity,
      is_inquiry: false,
    }
    axios
      .put(`${baseURL}/guest/space-reservation`, data, {
        headers: {
          Authorization: authData,
        },
      })
      .then((res) => {
        setClientSecret(res.data.client_secret)
        setLoading(false)
      })
      .catch((err) => {
        setIsStripeModal(false)
        Message.error(err.response.data.detail)
      })
  }, [isStripeModal, setIsStripeModal])

  const appearance = { theme: 'stripe' }
  const options = { clientSecret, appearance }
  const showModal = () => setIsStripeModal(true)
  const handleCancel = () => setIsStripeModal(false)

  return (
    <>
      <Modal
        destroyOnClose
        title="Authorize with stripe"
        visible={isStripeModal}
        okText="Pay now"
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        {!loading ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              setPaymentIntentId={setPaymentIntentId}
              setSavedCreditCard={setSavedCreditCard}
              dateVal={dateVal}
              dateVal2={dateVal2}
              startTime={startTime}
              endTime={endTime}
              price={price}
              space={space}
              alcoholConsumption={alcoholConsumption}
              guestAmount={guestAmount}
              activity={activity}
              msgToHost={msgToHost}
              setIsStripeModal={setIsStripeModal}
              message={message}
              setMessage={setMessage}
            />
          </Elements>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </>
  )
}

export default StripeModal
