const states = {
  nyStates: [
    {
      name: 'Adams',
    },
    {
      name: 'Adams Center',
    },
    {
      name: 'Addison',
    },
    {
      name: 'Airmont',
    },
    {
      name: 'Akron',
    },
    {
      name: 'Alabama',
    },
    {
      name: 'Albany',
    },
    {
      name: 'Albany County',
    },
    {
      name: 'Albertson',
    },
    {
      name: 'Albion',
    },
    {
      name: 'Alden',
    },
    {
      name: 'Alexandria Bay',
    },
    {
      name: 'Alfred',
    },
    {
      name: 'Allegany',
    },
    {
      name: 'Allegany County',
    },
    {
      name: 'Altamont',
    },
    {
      name: 'Amagansett',
    },
    {
      name: 'Amherst',
    },
    {
      name: 'Amityville',
    },
    {
      name: 'Amsterdam',
    },
    {
      name: 'Andover',
    },
    {
      name: 'Angola',
    },
    {
      name: 'Angola on the Lake',
    },
    {
      name: 'Apalachin',
    },
    {
      name: 'Aquebogue',
    },
    {
      name: 'Arcade',
    },
    {
      name: 'Ardsley',
    },
    {
      name: 'Arlington',
    },
    {
      name: 'Armonk',
    },
    {
      name: 'Arrochar',
    },
    {
      name: 'Arverne',
    },
    {
      name: 'Astoria',
    },
    {
      name: 'Athens',
    },
    {
      name: 'Atlantic Beach',
    },
    {
      name: 'Attica',
    },
    {
      name: 'Auburn',
    },
    {
      name: 'Augusta',
    },
    {
      name: 'Averill Park',
    },
    {
      name: 'Avon',
    },
    {
      name: 'Babylon',
    },
    {
      name: 'Bainbridge',
    },
    {
      name: 'Baiting Hollow',
    },
    {
      name: 'Baldwin',
    },
    {
      name: 'Baldwin Harbor',
    },
    {
      name: 'Baldwinsville',
    },
    {
      name: 'Ballston Spa',
    },
    {
      name: 'Balmville',
    },
    {
      name: 'Bardonia',
    },
    {
      name: 'Barnum Island',
    },
    {
      name: 'Batavia',
    },
    {
      name: 'Bath',
    },
    {
      name: 'Bath Beach',
    },
    {
      name: 'Baxter Estates',
    },
    {
      name: 'Bay Park',
    },
    {
      name: 'Bay Shore',
    },
    {
      name: 'Bay Wood',
    },
    {
      name: 'Baychester',
    },
    {
      name: 'Bayport',
    },
    {
      name: 'Bayside',
    },
    {
      name: 'Bayville',
    },
    {
      name: 'Beacon',
    },
    {
      name: 'Beaver Dam Lake',
    },
    {
      name: 'Beaverdam Lake-Salisbury Mills',
    },
    {
      name: 'Bedford',
    },
    {
      name: 'Bedford Hills',
    },
    {
      name: 'Bellaire',
    },
    {
      name: 'Belle Harbor',
    },
    {
      name: 'Bellerose',
    },
    {
      name: 'Bellerose Terrace',
    },
    {
      name: 'Bellmore',
    },
    {
      name: 'Bellport',
    },
    {
      name: 'Belmont',
    },
    {
      name: 'Bensonhurst',
    },
    {
      name: 'Bergen',
    },
    {
      name: 'Bergen Beach',
    },
    {
      name: 'Bethpage',
    },
    {
      name: 'Big Flats',
    },
    {
      name: 'Billington Heights',
    },
    {
      name: 'Binghamton',
    },
    {
      name: 'Black River',
    },
    {
      name: 'Blasdell',
    },
    {
      name: 'Blauvelt',
    },
    {
      name: 'Bloomfield',
    },
    {
      name: 'Blue Point',
    },
    {
      name: 'Bohemia',
    },
    {
      name: 'Bolivar',
    },
    {
      name: 'Boonville',
    },
    {
      name: 'Borough Park',
    },
    {
      name: 'Boston',
    },
    {
      name: 'Brentwood',
    },
    {
      name: 'Brewerton',
    },
    {
      name: 'Brewster',
    },
    {
      name: 'Brewster Hill',
    },
    {
      name: 'Briarcliff Manor',
    },
    {
      name: 'Briarwood',
    },
    {
      name: 'Bridgehampton',
    },
    {
      name: 'Bridgeport',
    },
    {
      name: 'Brighton',
    },
    {
      name: 'Brighton Beach',
    },
    {
      name: 'Brightwaters',
    },
    {
      name: 'Brinckerhoff',
    },
    {
      name: 'Broad Channel',
    },
    {
      name: 'Broadalbin',
    },
    {
      name: 'Brockport',
    },
    {
      name: 'Brocton',
    },
    {
      name: 'Bronx',
    },
    {
      name: 'Bronxville',
    },
    {
      name: 'Brookhaven',
    },
    {
      name: 'Brooklyn',
    },
    {
      name: 'Brooklyn Heights',
    },
    {
      name: 'Brookville',
    },
    {
      name: 'Broome County',
    },
    {
      name: 'Brownsville',
    },
    {
      name: 'Brownville',
    },
    {
      name: 'Buchanan',
    },
    {
      name: 'Buffalo',
    },
    {
      name: 'Bushwick',
    },
    {
      name: 'Cairo',
    },
    {
      name: 'Calcium',
    },
    {
      name: 'Caledonia',
    },
    {
      name: 'Calverton',
    },
    {
      name: 'Cambria Heights',
    },
    {
      name: 'Cambridge',
    },
    {
      name: 'Camden',
    },
    {
      name: 'Camillus',
    },
    {
      name: 'Canajoharie',
    },
    {
      name: 'Canandaigua',
    },
    {
      name: 'Canarsie',
    },
    {
      name: 'Canastota',
    },
    {
      name: 'Canisteo',
    },
    {
      name: 'Canton',
    },
    {
      name: 'Carle Place',
    },
    {
      name: 'Carmel',
    },
    {
      name: 'Carmel Hamlet',
    },
    {
      name: 'Carthage',
    },
    {
      name: 'Castleton-on-Hudson',
    },
    {
      name: 'Catskill',
    },
    {
      name: 'Cattaraugus County',
    },
    {
      name: 'Cayuga County',
    },
    {
      name: 'Cayuga Heights',
    },
    {
      name: 'Cazenovia',
    },
    {
      name: 'Cedarhurst',
    },
    {
      name: 'Celoron',
    },
    {
      name: 'Center Moriches',
    },
    {
      name: 'Centereach',
    },
    {
      name: 'Centerport',
    },
    {
      name: 'Central Islip',
    },
    {
      name: 'Central Square',
    },
    {
      name: 'Central Valley',
    },
    {
      name: 'Chadwicks',
    },
    {
      name: 'Champlain',
    },
    {
      name: 'Chappaqua',
    },
    {
      name: 'Charleston',
    },
    {
      name: 'Chatham',
    },
    {
      name: 'Chautauqua County',
    },
    {
      name: 'Cheektowaga',
    },
    {
      name: 'Chelsea',
    },
    {
      name: 'Chemung County',
    },
    {
      name: 'Chenango Bridge',
    },
    {
      name: 'Chenango County',
    },
    {
      name: 'Chester',
    },
    {
      name: 'Chestnut Ridge',
    },
    {
      name: 'Chittenango',
    },
    {
      name: 'Churchville',
    },
    {
      name: 'City Island',
    },
    {
      name: 'Clarence',
    },
    {
      name: 'Clarence Center',
    },
    {
      name: 'Clark Mills',
    },
    {
      name: 'Clarkson',
    },
    {
      name: 'Clayton',
    },
    {
      name: 'Clifton',
    },
    {
      name: 'Clifton Springs',
    },
    {
      name: 'Clinton',
    },
    {
      name: 'Clinton County',
    },
    {
      name: 'Clintondale',
    },
    {
      name: 'Clyde',
    },
    {
      name: 'Clymer',
    },
    {
      name: 'Cobleskill',
    },
    {
      name: 'Cohoes',
    },
    {
      name: 'Cold Spring',
    },
    {
      name: 'Cold Spring Harbor',
    },
    {
      name: 'College Point',
    },
    {
      name: 'Colonie',
    },
    {
      name: 'Columbia County',
    },
    {
      name: 'Commack',
    },
    {
      name: 'Concord',
    },
    {
      name: 'Coney Island',
    },
    {
      name: 'Congers',
    },
    {
      name: 'Constantia',
    },
    {
      name: 'Cooperstown',
    },
    {
      name: 'Copiague',
    },
    {
      name: 'Coram',
    },
    {
      name: 'Corinth',
    },
    {
      name: 'Corning',
    },
    {
      name: 'Cornwall',
    },
    {
      name: 'Corona',
    },
    {
      name: 'Cortland',
    },
    {
      name: 'Cortland County',
    },
    {
      name: 'Cortland West',
    },
    {
      name: 'Country Knolls',
    },
    {
      name: 'Coxsackie',
    },
    {
      name: 'Crompond',
    },
    {
      name: 'Croton-on-Hudson',
    },
    {
      name: 'Crown Heights',
    },
    {
      name: 'Crugers',
    },
    {
      name: 'Cuba',
    },
    {
      name: 'Cumberland Head',
    },
    {
      name: 'Cutchogue',
    },
    {
      name: 'Cypress Hills',
    },
    {
      name: 'Dannemora',
    },
    {
      name: 'Dansville',
    },
    {
      name: 'Deer Park',
    },
    {
      name: 'Delaware County',
    },
    {
      name: 'Delevan',
    },
    {
      name: 'Delhi',
    },
    {
      name: 'Delmar',
    },
    {
      name: 'Depew',
    },
    {
      name: 'Deposit',
    },
    {
      name: 'Dexter',
    },
    {
      name: 'Dix Hills',
    },
    {
      name: 'Dobbs Ferry',
    },
    {
      name: 'Dolgeville',
    },
    {
      name: 'Dongan Hills',
    },
    {
      name: 'Douglaston',
    },
    {
      name: 'Dover Plains',
    },
    {
      name: 'Dryden',
    },
    {
      name: 'Dundee',
    },
    {
      name: 'Dunkirk',
    },
    {
      name: 'Durham',
    },
    {
      name: 'Dutchess County',
    },
    {
      name: 'Dyker Heights',
    },
    {
      name: 'East Atlantic Beach',
    },
    {
      name: 'East Aurora',
    },
    {
      name: 'East Elmhurst',
    },
    {
      name: 'East Farmingdale',
    },
    {
      name: 'East Flatbush',
    },
    {
      name: 'East Garden City',
    },
    {
      name: 'East Glenville',
    },
    {
      name: 'East Greenbush',
    },
    {
      name: 'East Hampton',
    },
    {
      name: 'East Hampton North',
    },
    {
      name: 'East Harlem',
    },
    {
      name: 'East Hills',
    },
    {
      name: 'East Islip',
    },
    {
      name: 'East Ithaca',
    },
    {
      name: 'East Massapequa',
    },
    {
      name: 'East Meadow',
    },
    {
      name: 'East Moriches',
    },
    {
      name: 'East New York',
    },
    {
      name: 'East Northport',
    },
    {
      name: 'East Norwich',
    },
    {
      name: 'East Patchogue',
    },
    {
      name: 'East Quogue',
    },
    {
      name: 'East Rochester',
    },
    {
      name: 'East Rockaway',
    },
    {
      name: 'East Setauket',
    },
    {
      name: 'East Shoreham',
    },
    {
      name: 'East Syracuse',
    },
    {
      name: 'East Tremont',
    },
    {
      name: 'East Village',
    },
    {
      name: 'East Williston',
    },
    {
      name: 'Eastchester',
    },
    {
      name: 'Eastport',
    },
    {
      name: 'Eatons Neck',
    },
    {
      name: 'Eden',
    },
    {
      name: 'Edgemere',
    },
    {
      name: 'Edinburg',
    },
    {
      name: 'Eggertsville',
    },
    {
      name: 'Elbridge',
    },
    {
      name: 'Elizabethtown',
    },
    {
      name: 'Ellenville',
    },
    {
      name: 'Elma Center',
    },
    {
      name: 'Elmhurst',
    },
    {
      name: 'Elmira',
    },
    {
      name: 'Elmira Heights',
    },
    {
      name: 'Elmont',
    },
    {
      name: 'Elmsford',
    },
    {
      name: 'Eltingville',
    },
    {
      name: 'Elwood',
    },
    {
      name: 'Emerson Hill',
    },
    {
      name: 'Endicott',
    },
    {
      name: 'Endwell',
    },
    {
      name: 'Erie County',
    },
    {
      name: 'Essex County',
    },
    {
      name: 'Fairmount',
    },
    {
      name: 'Fairport',
    },
    {
      name: 'Fairview',
    },
    {
      name: 'Falconer',
    },
    {
      name: 'Fallsburg',
    },
    {
      name: 'Far Rockaway',
    },
    {
      name: 'Farmingdale',
    },
    {
      name: 'Farmingville',
    },
    {
      name: 'Fayetteville',
    },
    {
      name: 'Financial District',
    },
    {
      name: 'Firthcliffe',
    },
    {
      name: 'Fishkill',
    },
    {
      name: 'Flanders',
    },
    {
      name: 'Flatbush',
    },
    {
      name: 'Flatlands',
    },
    {
      name: 'Floral Park',
    },
    {
      name: 'Florida',
    },
    {
      name: 'Flower Hill',
    },
    {
      name: 'Fonda',
    },
    {
      name: 'Fordham',
    },
    {
      name: 'Forest Hills',
    },
    {
      name: 'Fort Covington Hamlet',
    },
    {
      name: 'Fort Drum',
    },
    {
      name: 'Fort Edward',
    },
    {
      name: 'Fort Hamilton',
    },
    {
      name: 'Fort Montgomery',
    },
    {
      name: 'Fort Plain',
    },
    {
      name: 'Fort Salonga',
    },
    {
      name: 'Fort Wadsworth',
    },
    {
      name: 'Frankfort',
    },
    {
      name: 'Franklin County',
    },
    {
      name: 'Franklin Square',
    },
    {
      name: 'Franklinville',
    },
    {
      name: 'Fredonia',
    },
    {
      name: 'Freeport',
    },
    {
      name: 'Fresh Meadows',
    },
    {
      name: 'Frewsburg',
    },
    {
      name: 'Friendship',
    },
    {
      name: 'Fulton',
    },
    {
      name: 'Fulton County',
    },
    {
      name: 'Galeville',
    },
    {
      name: 'Gang Mills',
    },
    {
      name: 'Garden City',
    },
    {
      name: 'Garden City Park',
    },
    {
      name: 'Garden City South',
    },
    {
      name: 'Gardnertown',
    },
    {
      name: 'Gasport',
    },
    {
      name: 'Gates-North Gates',
    },
    {
      name: 'Genesee County',
    },
    {
      name: 'Geneseo',
    },
    {
      name: 'Geneva',
    },
    {
      name: 'Glasco',
    },
    {
      name: 'Glen Cove',
    },
    {
      name: 'Glen Head',
    },
    {
      name: 'Glen Oaks',
    },
    {
      name: 'Glendale',
    },
    {
      name: 'Glens Falls',
    },
    {
      name: 'Glens Falls North',
    },
    {
      name: 'Glenwood Landing',
    },
    {
      name: 'Gloversville',
    },
    {
      name: 'Goldens Bridge',
    },
    {
      name: 'Gordon Heights',
    },
    {
      name: 'Goshen',
    },
    {
      name: 'Gouverneur',
    },
    {
      name: 'Gowanda',
    },
    {
      name: 'Gramercy Park',
    },
    {
      name: 'Grand Island',
    },
    {
      name: 'Grandyle Village',
    },
    {
      name: 'Graniteville',
    },
    {
      name: 'Grant City',
    },
    {
      name: 'Granville',
    },
    {
      name: 'Gravesend',
    },
    {
      name: 'Great Kills',
    },
    {
      name: 'Great Neck',
    },
    {
      name: 'Great Neck Estates',
    },
    {
      name: 'Great Neck Gardens',
    },
    {
      name: 'Great Neck Plaza',
    },
    {
      name: 'Great River',
    },
    {
      name: 'Greece',
    },
    {
      name: 'Green Island',
    },
    {
      name: 'Greenburgh',
    },
    {
      name: 'Greene',
    },
    {
      name: 'Greene County',
    },
    {
      name: 'Greenlawn',
    },
    {
      name: 'Greenpoint',
    },
    {
      name: 'Greenport',
    },
    {
      name: 'Greenport West',
    },
    {
      name: 'Greenvale',
    },
    {
      name: 'Greenville',
    },
    {
      name: 'Greenwich',
    },
    {
      name: 'Greenwood Lake',
    },
    {
      name: 'Groton',
    },
    {
      name: 'Grymes Hill',
    },
    {
      name: 'Hadley',
    },
    {
      name: 'Hagaman',
    },
    {
      name: 'Halesite',
    },
    {
      name: 'Hamburg',
    },
    {
      name: 'Hamilton',
    },
    {
      name: 'Hamilton County',
    },
    {
      name: 'Hamlin',
    },
    {
      name: 'Hampton Bays',
    },
    {
      name: 'Hampton Manor',
    },
    {
      name: 'Hannawa Falls',
    },
    {
      name: 'Harbor Isle',
    },
    {
      name: 'Harlem',
    },
    {
      name: 'Harriman',
    },
    {
      name: 'Harris Hill',
    },
    {
      name: 'Harrison',
    },
    {
      name: 'Hartford',
    },
    {
      name: 'Hartsdale',
    },
    {
      name: 'Hastings-on-Hudson',
    },
    {
      name: 'Hauppauge',
    },
    {
      name: 'Haverstraw',
    },
    {
      name: 'Haviland',
    },
    {
      name: 'Hawthorne',
    },
    {
      name: 'Head of the Harbor',
    },
    {
      name: "Hell's Kitchen",
    },
    {
      name: 'Hempstead',
    },
    {
      name: 'Heritage Hills',
    },
    {
      name: 'Herkimer',
    },
    {
      name: 'Herkimer County',
    },
    {
      name: 'Herricks',
    },
    {
      name: 'Hewlett',
    },
    {
      name: 'Hewlett Harbor',
    },
    {
      name: 'Hicksville',
    },
    {
      name: 'Highland',
    },
    {
      name: 'Highland Falls',
    },
    {
      name: 'Highland Mills',
    },
    {
      name: 'Hillcrest',
    },
    {
      name: 'Hillside',
    },
    {
      name: 'Hillside Lake',
    },
    {
      name: 'Hilton',
    },
    {
      name: 'Holbrook',
    },
    {
      name: 'Holcomb',
    },
    {
      name: 'Holland',
    },
    {
      name: 'Holley',
    },
    {
      name: 'Hollis',
    },
    {
      name: 'Holtsville',
    },
    {
      name: 'Homer',
    },
    {
      name: 'Honeoye Falls',
    },
    {
      name: 'Hoosick Falls',
    },
    {
      name: 'Hornell',
    },
    {
      name: 'Horseheads',
    },
    {
      name: 'Horseheads North',
    },
    {
      name: 'Houghton',
    },
    {
      name: 'Howard Beach',
    },
    {
      name: 'Hudson',
    },
    {
      name: 'Hudson Falls',
    },
    {
      name: 'Huguenot',
    },
    {
      name: 'Huntington',
    },
    {
      name: 'Huntington Bay',
    },
    {
      name: 'Huntington Station',
    },
    {
      name: 'Hunts Point',
    },
    {
      name: 'Hurley',
    },
    {
      name: 'Hyde Park',
    },
    {
      name: 'Ilion',
    },
    {
      name: 'Inwood',
    },
    {
      name: 'Irondequoit',
    },
    {
      name: 'Irvington',
    },
    {
      name: 'Island Park',
    },
    {
      name: 'Islandia',
    },
    {
      name: 'Islip',
    },
    {
      name: 'Islip Terrace',
    },
    {
      name: 'Ithaca',
    },
    {
      name: 'Jackson Heights',
    },
    {
      name: 'Jamaica',
    },
    {
      name: 'Jamesport',
    },
    {
      name: 'Jamestown',
    },
    {
      name: 'Jamestown West',
    },
    {
      name: 'Jefferson County',
    },
    {
      name: 'Jefferson Heights',
    },
    {
      name: 'Jefferson Valley-Yorktown',
    },
    {
      name: 'Jericho',
    },
    {
      name: 'Johnson City',
    },
    {
      name: 'Johnstown',
    },
    {
      name: 'Jordan',
    },
    {
      name: 'Kaser',
    },
    {
      name: 'Katonah',
    },
    {
      name: 'Keeseville',
    },
    {
      name: 'Kenmore',
    },
    {
      name: 'Kensington',
    },
    {
      name: 'Kerhonkson',
    },
    {
      name: 'Keuka Park',
    },
    {
      name: 'Kew Gardens',
    },
    {
      name: 'Kew Gardens Hills',
    },
    {
      name: 'Kiantone',
    },
    {
      name: 'Kinderhook',
    },
    {
      name: 'Kings Bridge',
    },
    {
      name: 'Kings County',
    },
    {
      name: 'Kings Park',
    },
    {
      name: 'Kings Point',
    },
    {
      name: 'Kingston',
    },
    {
      name: 'Kiryas Joel',
    },
    {
      name: 'Lackawanna',
    },
    {
      name: 'Lake Carmel',
    },
    {
      name: 'Lake Erie Beach',
    },
    {
      name: 'Lake Grove',
    },
    {
      name: 'Lake Katrine',
    },
    {
      name: 'Lake Luzerne',
    },
    {
      name: 'Lake Mohegan',
    },
    {
      name: 'Lake Placid',
    },
    {
      name: 'Lake Pleasant',
    },
    {
      name: 'Lake Ronkonkoma',
    },
    {
      name: 'Lake Success',
    },
    {
      name: 'Lakeland',
    },
    {
      name: 'Lakeview',
    },
    {
      name: 'Lakewood',
    },
    {
      name: 'Lancaster',
    },
    {
      name: 'Lansing',
    },
    {
      name: 'Larchmont',
    },
    {
      name: 'Latham',
    },
    {
      name: 'Lattingtown',
    },
    {
      name: 'Laurel',
    },
    {
      name: 'Laurel Hollow',
    },
    {
      name: 'Laurelton',
    },
    {
      name: 'Lawrence',
    },
    {
      name: 'Le Roy',
    },
    {
      name: 'Levittown',
    },
    {
      name: 'Lewis County',
    },
    {
      name: 'Lewiston',
    },
    {
      name: 'Liberty',
    },
    {
      name: 'Lido Beach',
    },
    {
      name: 'Lima',
    },
    {
      name: 'Lincoln Park',
    },
    {
      name: 'Lincolndale',
    },
    {
      name: 'Lindenhurst',
    },
    {
      name: 'Lindley',
    },
    {
      name: 'Little Falls',
    },
    {
      name: 'Little Neck',
    },
    {
      name: 'Little Valley',
    },
    {
      name: 'Liverpool',
    },
    {
      name: 'Livingston County',
    },
    {
      name: 'Livingston Manor',
    },
    {
      name: 'Livonia',
    },
    {
      name: 'Lloyd Harbor',
    },
    {
      name: 'Lockport',
    },
    {
      name: 'Locust Valley',
    },
    {
      name: 'Long Beach',
    },
    {
      name: 'Long Island City',
    },
    {
      name: 'Lorenz Park',
    },
    {
      name: 'Lowville',
    },
    {
      name: 'Lynbrook',
    },
    {
      name: 'Lyncourt',
    },
    {
      name: 'Lyons',
    },
    {
      name: 'Macedon',
    },
    {
      name: 'Madison County',
    },
    {
      name: 'Mahopac',
    },
    {
      name: 'Malone',
    },
    {
      name: 'Malverne',
    },
    {
      name: 'Mamaroneck',
    },
    {
      name: 'Manchester',
    },
    {
      name: 'Manhasset',
    },
    {
      name: 'Manhasset Hills',
    },
    {
      name: 'Manhattan',
    },
    {
      name: 'Manlius',
    },
    {
      name: 'Manorhaven',
    },
    {
      name: 'Manorville',
    },
    {
      name: 'Marbletown',
    },
    {
      name: 'Marcellus',
    },
    {
      name: 'Mariners Harbor',
    },
    {
      name: 'Marion',
    },
    {
      name: 'Marlboro',
    },
    {
      name: 'Maspeth',
    },
    {
      name: 'Massapequa',
    },
    {
      name: 'Massapequa Park',
    },
    {
      name: 'Massena',
    },
    {
      name: 'Mastic',
    },
    {
      name: 'Mastic Beach',
    },
    {
      name: 'Mattituck',
    },
    {
      name: 'Mattydale',
    },
    {
      name: 'Maybrook',
    },
    {
      name: 'Mayville',
    },
    {
      name: 'McGraw',
    },
    {
      name: 'McKownville',
    },
    {
      name: 'Mechanicstown',
    },
    {
      name: 'Mechanicville',
    },
    {
      name: 'Medford',
    },
    {
      name: 'Medina',
    },
    {
      name: 'Melrose',
    },
    {
      name: 'Melrose Park',
    },
    {
      name: 'Melville',
    },
    {
      name: 'Menands',
    },
    {
      name: 'Merrick',
    },
    {
      name: 'Merritt Park',
    },
    {
      name: 'Mexico',
    },
    {
      name: 'Middle Island',
    },
    {
      name: 'Middle Village',
    },
    {
      name: 'Middleburgh',
    },
    {
      name: 'Middleport',
    },
    {
      name: 'Middletown',
    },
    {
      name: 'Midland Beach',
    },
    {
      name: 'Mill Neck',
    },
    {
      name: 'Millbrook',
    },
    {
      name: 'Miller Place',
    },
    {
      name: 'Milton',
    },
    {
      name: 'Mineola',
    },
    {
      name: 'Minetto',
    },
    {
      name: 'Mineville',
    },
    {
      name: 'Minoa',
    },
    {
      name: 'Mohawk',
    },
    {
      name: 'Monroe',
    },
    {
      name: 'Monroe County',
    },
    {
      name: 'Monsey',
    },
    {
      name: 'Montauk',
    },
    {
      name: 'Montebello',
    },
    {
      name: 'Montgomery',
    },
    {
      name: 'Montgomery County',
    },
    {
      name: 'Monticello',
    },
    {
      name: 'Montour Falls',
    },
    {
      name: 'Montrose',
    },
    {
      name: 'Moravia',
    },
    {
      name: 'Moriches',
    },
    {
      name: 'Morningside Heights',
    },
    {
      name: 'Morris Heights',
    },
    {
      name: 'Morris Park',
    },
    {
      name: 'Morrisania',
    },
    {
      name: 'Morrisonville',
    },
    {
      name: 'Morrisville',
    },
    {
      name: 'Mott Haven',
    },
    {
      name: 'Mount Ivy',
    },
    {
      name: 'Mount Kisco',
    },
    {
      name: 'Mount Morris',
    },
    {
      name: 'Mount Sinai',
    },
    {
      name: 'Mount Vernon',
    },
    {
      name: 'Mountain Lodge Park',
    },
    {
      name: 'Munsey Park',
    },
    {
      name: 'Munsons Corners',
    },
    {
      name: 'Muttontown',
    },
    {
      name: 'Myers Corner',
    },
    {
      name: 'Nanuet',
    },
    {
      name: 'Napanoch',
    },
    {
      name: 'Naples',
    },
    {
      name: 'Nassau',
    },
    {
      name: 'Nassau County',
    },
    {
      name: 'Nedrow',
    },
    {
      name: 'Neponsit',
    },
    {
      name: 'Nesconset',
    },
    {
      name: 'New Brighton',
    },
    {
      name: 'New Cassel',
    },
    {
      name: 'New City',
    },
    {
      name: 'New Dorp',
    },
    {
      name: 'New Dorp Beach',
    },
    {
      name: 'New Hartford',
    },
    {
      name: 'New Hempstead',
    },
    {
      name: 'New Hyde Park',
    },
    {
      name: 'New Paltz',
    },
    {
      name: 'New Rochelle',
    },
    {
      name: 'New Springville',
    },
    {
      name: 'New Square',
    },
    {
      name: 'New Windsor',
    },
    {
      name: 'New York City',
    },
    {
      name: 'New York County',
    },
    {
      name: 'New York Mills',
    },
    {
      name: 'Newark',
    },
    {
      name: 'Newburgh',
    },
    {
      name: 'Newfane',
    },
    {
      name: 'Niagara County',
    },
    {
      name: 'Niagara Falls',
    },
    {
      name: 'Niskayuna',
    },
    {
      name: 'Nissequogue',
    },
    {
      name: 'Niverville',
    },
    {
      name: 'Norfolk',
    },
    {
      name: 'North Amityville',
    },
    {
      name: 'North Babylon',
    },
    {
      name: 'North Ballston Spa',
    },
    {
      name: 'North Bay Shore',
    },
    {
      name: 'North Bellmore',
    },
    {
      name: 'North Bellport',
    },
    {
      name: 'North Boston',
    },
    {
      name: 'North Castle',
    },
    {
      name: 'North Collins',
    },
    {
      name: 'North Elba',
    },
    {
      name: 'North Gates',
    },
    {
      name: 'North Great River',
    },
    {
      name: 'North Hills',
    },
    {
      name: 'North Lindenhurst',
    },
    {
      name: 'North Massapequa',
    },
    {
      name: 'North Merrick',
    },
    {
      name: 'North New Hyde Park',
    },
    {
      name: 'North Patchogue',
    },
    {
      name: 'North Sea',
    },
    {
      name: 'North Syracuse',
    },
    {
      name: 'North Tonawanda',
    },
    {
      name: 'North Valley Stream',
    },
    {
      name: 'North Wantagh',
    },
    {
      name: 'Northeast Ithaca',
    },
    {
      name: 'Northport',
    },
    {
      name: 'Northumberland',
    },
    {
      name: 'Northville',
    },
    {
      name: 'Northwest Harbor',
    },
    {
      name: 'Northwest Ithaca',
    },
    {
      name: 'Norwich',
    },
    {
      name: 'Norwood',
    },
    {
      name: 'Noyack',
    },
    {
      name: 'Nunda',
    },
    {
      name: 'Nyack',
    },
    {
      name: 'Oakdale',
    },
    {
      name: 'Oakfield',
    },
    {
      name: 'Oakwood',
    },
    {
      name: 'Oceanside',
    },
    {
      name: 'Ogdensburg',
    },
    {
      name: 'Olcott',
    },
    {
      name: 'Old Bethpage',
    },
    {
      name: 'Old Brookville',
    },
    {
      name: 'Old Westbury',
    },
    {
      name: 'Olean',
    },
    {
      name: 'Oneida',
    },
    {
      name: 'Oneida County',
    },
    {
      name: 'Oneonta',
    },
    {
      name: 'Onondaga County',
    },
    {
      name: 'Ontario',
    },
    {
      name: 'Ontario County',
    },
    {
      name: 'Orange County',
    },
    {
      name: 'Orange Lake',
    },
    {
      name: 'Orangeburg',
    },
    {
      name: 'Orchard Park',
    },
    {
      name: 'Oriskany',
    },
    {
      name: 'Orleans County',
    },
    {
      name: 'Ossining',
    },
    {
      name: 'Oswego',
    },
    {
      name: 'Oswego County',
    },
    {
      name: 'Otisville',
    },
    {
      name: 'Otsego County',
    },
    {
      name: 'Owego',
    },
    {
      name: 'Oxford',
    },
    {
      name: 'Oyster Bay',
    },
    {
      name: 'Oyster Bay Cove',
    },
    {
      name: 'Ozone Park',
    },
    {
      name: 'Painted Post',
    },
    {
      name: 'Palenville',
    },
    {
      name: 'Palmyra',
    },
    {
      name: 'Park Slope',
    },
    {
      name: 'Parkchester',
    },
    {
      name: 'Patchogue',
    },
    {
      name: 'Pawling',
    },
    {
      name: 'Peach Lake',
    },
    {
      name: 'Pearl River',
    },
    {
      name: 'Peekskill',
    },
    {
      name: 'Pelham',
    },
    {
      name: 'Pelham Manor',
    },
    {
      name: 'Penn Yan',
    },
    {
      name: 'Perry',
    },
    {
      name: 'Perth',
    },
    {
      name: 'Peru',
    },
    {
      name: 'Phelps',
    },
    {
      name: 'Philadelphia',
    },
    {
      name: 'Philmont',
    },
    {
      name: 'Phoenix',
    },
    {
      name: 'Piermont',
    },
    {
      name: 'Pine Bush',
    },
    {
      name: 'Pine Plains',
    },
    {
      name: 'Pittsford',
    },
    {
      name: 'Plainedge',
    },
    {
      name: 'Plainview',
    },
    {
      name: 'Plandome',
    },
    {
      name: 'Plandome Heights',
    },
    {
      name: 'Plattekill',
    },
    {
      name: 'Plattsburgh',
    },
    {
      name: 'Plattsburgh West',
    },
    {
      name: 'Pleasant Valley',
    },
    {
      name: 'Pleasantville',
    },
    {
      name: 'Poestenkill',
    },
    {
      name: 'Point Lookout',
    },
    {
      name: 'Pomona',
    },
    {
      name: 'Port Byron',
    },
    {
      name: 'Port Chester',
    },
    {
      name: 'Port Dickinson',
    },
    {
      name: 'Port Ewen',
    },
    {
      name: 'Port Henry',
    },
    {
      name: 'Port Jefferson',
    },
    {
      name: 'Port Jefferson Station',
    },
    {
      name: 'Port Jervis',
    },
    {
      name: 'Port Morris',
    },
    {
      name: 'Port Richmond',
    },
    {
      name: 'Port Washington',
    },
    {
      name: 'Port Washington North',
    },
    {
      name: 'Portland',
    },
    {
      name: 'Potsdam',
    },
    {
      name: 'Poughkeepsie',
    },
    {
      name: 'Pound Ridge',
    },
    {
      name: 'Pulaski',
    },
    {
      name: 'Purchase',
    },
    {
      name: 'Putnam County',
    },
    {
      name: 'Putnam Lake',
    },
    {
      name: 'Queens',
    },
    {
      name: 'Queens County',
    },
    {
      name: 'Queens Village',
    },
    {
      name: 'Queensbury',
    },
    {
      name: 'Randolph',
    },
    {
      name: 'Ransomville',
    },
    {
      name: 'Rapids',
    },
    {
      name: 'Ravena',
    },
    {
      name: 'Red Hook',
    },
    {
      name: 'Red Oaks Mill',
    },
    {
      name: 'Rego Park',
    },
    {
      name: 'Remsenburg-Speonk',
    },
    {
      name: 'Rensselaer',
    },
    {
      name: 'Rensselaer County',
    },
    {
      name: 'Rhinebeck',
    },
    {
      name: 'Richfield Springs',
    },
    {
      name: 'Richland',
    },
    {
      name: 'Richmond County',
    },
    {
      name: 'Richmond Hill',
    },
    {
      name: 'Ridge',
    },
    {
      name: 'Ridgewood',
    },
    {
      name: 'Riverdale',
    },
    {
      name: 'Riverhead',
    },
    {
      name: 'Riverside',
    },
    {
      name: 'Rochester',
    },
    {
      name: 'Rock Hill',
    },
    {
      name: 'Rockaway Point',
    },
    {
      name: 'Rockland County',
    },
    {
      name: 'Rockville Centre',
    },
    {
      name: 'Rocky Point',
    },
    {
      name: 'Roessleville',
    },
    {
      name: 'Rome',
    },
    {
      name: 'Ronkonkoma',
    },
    {
      name: 'Roosevelt',
    },
    {
      name: 'Rosebank',
    },
    {
      name: 'Rosedale',
    },
    {
      name: 'Rosendale Village',
    },
    {
      name: 'Roslyn',
    },
    {
      name: 'Roslyn Estates',
    },
    {
      name: 'Roslyn Harbor',
    },
    {
      name: 'Roslyn Heights',
    },
    {
      name: 'Rossville',
    },
    {
      name: 'Rotterdam',
    },
    {
      name: 'Rouses Point',
    },
    {
      name: 'Rye',
    },
    {
      name: 'Rye Brook',
    },
    {
      name: 'Sackets Harbor',
    },
    {
      name: 'Sag Harbor',
    },
    {
      name: 'Saint Bonaventure',
    },
    {
      name: 'Saint James',
    },
    {
      name: 'Saint Johnsville',
    },
    {
      name: 'Salamanca',
    },
    {
      name: 'Salisbury',
    },
    {
      name: 'Sanborn',
    },
    {
      name: 'Sands Point',
    },
    {
      name: 'Saranac Lake',
    },
    {
      name: 'Saratoga County',
    },
    {
      name: 'Saratoga Springs',
    },
    {
      name: 'Saugerties',
    },
    {
      name: 'Saugerties South',
    },
    {
      name: 'Sayville',
    },
    {
      name: 'Scarsdale',
    },
    {
      name: 'Schenectady',
    },
    {
      name: 'Schenectady County',
    },
    {
      name: 'Schoharie',
    },
    {
      name: 'Schoharie County',
    },
    {
      name: 'Schuyler County',
    },
    {
      name: 'Schuylerville',
    },
    {
      name: 'Scotchtown',
    },
    {
      name: 'Scotia',
    },
    {
      name: 'Scottsville',
    },
    {
      name: 'Sea Cliff',
    },
    {
      name: 'Seaford',
    },
    {
      name: 'Searingtown',
    },
    {
      name: 'Seaside',
    },
    {
      name: 'Selden',
    },
    {
      name: 'Seneca County',
    },
    {
      name: 'Seneca Falls',
    },
    {
      name: 'Seneca Knolls',
    },
    {
      name: 'Setauket-East Setauket',
    },
    {
      name: 'Sheepshead Bay',
    },
    {
      name: 'Shelter Island',
    },
    {
      name: 'Shelter Island Heights',
    },
    {
      name: 'Shenorock',
    },
    {
      name: 'Sherburne',
    },
    {
      name: 'Sherrill',
    },
    {
      name: 'Shinnecock Hills',
    },
    {
      name: 'Shirley',
    },
    {
      name: 'Shokan',
    },
    {
      name: 'Shortsville',
    },
    {
      name: 'Shrub Oak',
    },
    {
      name: 'Sidney',
    },
    {
      name: 'Silver Creek',
    },
    {
      name: 'Skaneateles',
    },
    {
      name: 'Sleepy Hollow',
    },
    {
      name: 'Sloan',
    },
    {
      name: 'Sloatsburg',
    },
    {
      name: 'Smithtown',
    },
    {
      name: 'Sodus',
    },
    {
      name: 'Solvay',
    },
    {
      name: 'Sound Beach',
    },
    {
      name: 'South Beach',
    },
    {
      name: 'South Blooming Grove',
    },
    {
      name: 'South Corning',
    },
    {
      name: 'South Fallsburg',
    },
    {
      name: 'South Farmingdale',
    },
    {
      name: 'South Floral Park',
    },
    {
      name: 'South Glens Falls',
    },
    {
      name: 'South Hempstead',
    },
    {
      name: 'South Hill',
    },
    {
      name: 'South Huntington',
    },
    {
      name: 'South Lockport',
    },
    {
      name: 'South Nyack',
    },
    {
      name: 'South Valley Stream',
    },
    {
      name: 'Southampton',
    },
    {
      name: 'Southold',
    },
    {
      name: 'Southport',
    },
    {
      name: 'Spackenkill',
    },
    {
      name: 'Sparkill',
    },
    {
      name: 'Spencerport',
    },
    {
      name: 'Spring Valley',
    },
    {
      name: 'Springfield',
    },
    {
      name: 'Springfield Gardens',
    },
    {
      name: 'Springs',
    },
    {
      name: 'Springville',
    },
    {
      name: 'Spuyten Duyvil',
    },
    {
      name: 'St. Lawrence County',
    },
    {
      name: 'Stamford',
    },
    {
      name: 'Stapleton',
    },
    {
      name: 'Staten Island',
    },
    {
      name: 'Steinway',
    },
    {
      name: 'Steuben County',
    },
    {
      name: 'Stewart Manor',
    },
    {
      name: 'Stillwater',
    },
    {
      name: 'Stone Ridge',
    },
    {
      name: 'Stony Brook',
    },
    {
      name: 'Stony Point',
    },
    {
      name: 'Stottville',
    },
    {
      name: 'Suffern',
    },
    {
      name: 'Suffolk County',
    },
    {
      name: 'Sullivan County',
    },
    {
      name: 'Sunnyside',
    },
    {
      name: 'Sunset Park',
    },
    {
      name: 'Sylvan Beach',
    },
    {
      name: 'Syosset',
    },
    {
      name: 'Syracuse',
    },
    {
      name: 'Tappan',
    },
    {
      name: 'Tarrytown',
    },
    {
      name: 'Terrace Heights',
    },
    {
      name: 'Terryville',
    },
    {
      name: 'The Bronx',
    },
    {
      name: 'Thiells',
    },
    {
      name: 'Thomaston',
    },
    {
      name: 'Thornwood',
    },
    {
      name: 'Throgs Neck',
    },
    {
      name: 'Ticonderoga',
    },
    {
      name: 'Tillson',
    },
    {
      name: 'Tioga County',
    },
    {
      name: 'Tivoli',
    },
    {
      name: 'Tompkins County',
    },
    {
      name: 'Tompkinsville',
    },
    {
      name: 'Tonawanda',
    },
    {
      name: 'Town Line',
    },
    {
      name: 'Tremont',
    },
    {
      name: 'Tribes Hill',
    },
    {
      name: 'Troy',
    },
    {
      name: 'Trumansburg',
    },
    {
      name: 'Tuckahoe',
    },
    {
      name: 'Tupper Lake',
    },
    {
      name: 'Ulster County',
    },
    {
      name: 'Unadilla',
    },
    {
      name: 'Union Springs',
    },
    {
      name: 'Uniondale',
    },
    {
      name: 'Unionport',
    },
    {
      name: 'University Gardens',
    },
    {
      name: 'University Heights',
    },
    {
      name: 'Upper Brookville',
    },
    {
      name: 'Upper Nyack',
    },
    {
      name: 'Utica',
    },
    {
      name: 'Vails Gate',
    },
    {
      name: 'Valatie',
    },
    {
      name: 'Valhalla',
    },
    {
      name: 'Valley Cottage',
    },
    {
      name: 'Valley Stream',
    },
    {
      name: 'Van Nest',
    },
    {
      name: 'Vernon',
    },
    {
      name: 'Verplanck',
    },
    {
      name: 'Victor',
    },
    {
      name: 'Village Green',
    },
    {
      name: 'Village of the Branch',
    },
    {
      name: 'Viola',
    },
    {
      name: 'Volney',
    },
    {
      name: 'Voorheesville',
    },
    {
      name: 'Wading River',
    },
    {
      name: 'Wakefield',
    },
    {
      name: 'Walden',
    },
    {
      name: 'Wallkill',
    },
    {
      name: 'Walton',
    },
    {
      name: 'Walton Park',
    },
    {
      name: 'Wampsville',
    },
    {
      name: 'Wanakah',
    },
    {
      name: 'Wantagh',
    },
    {
      name: 'Wappingers Falls',
    },
    {
      name: 'Warren County',
    },
    {
      name: 'Warrensburg',
    },
    {
      name: 'Warsaw',
    },
    {
      name: 'Warwick',
    },
    {
      name: 'Washington County',
    },
    {
      name: 'Washington Heights',
    },
    {
      name: 'Washington Mills',
    },
    {
      name: 'Washingtonville',
    },
    {
      name: 'Watchtower',
    },
    {
      name: 'Water Mill',
    },
    {
      name: 'Waterford',
    },
    {
      name: 'Waterloo',
    },
    {
      name: 'Watertown',
    },
    {
      name: 'Waterville',
    },
    {
      name: 'Watervliet',
    },
    {
      name: 'Watkins Glen',
    },
    {
      name: 'Waverly',
    },
    {
      name: 'Wawarsing',
    },
    {
      name: 'Wayland',
    },
    {
      name: 'Wayne County',
    },
    {
      name: 'Webster',
    },
    {
      name: 'Weedsport',
    },
    {
      name: 'Wellsville',
    },
    {
      name: 'Wesley Hills',
    },
    {
      name: 'West Albany',
    },
    {
      name: 'West Babylon',
    },
    {
      name: 'West Bay Shore',
    },
    {
      name: 'West Carthage',
    },
    {
      name: 'West Elmira',
    },
    {
      name: 'West End',
    },
    {
      name: 'West Glens Falls',
    },
    {
      name: 'West Haverstraw',
    },
    {
      name: 'West Hempstead',
    },
    {
      name: 'West Hills',
    },
    {
      name: 'West Hurley',
    },
    {
      name: 'West Islip',
    },
    {
      name: 'West Nyack',
    },
    {
      name: 'West Point',
    },
    {
      name: 'West Sand Lake',
    },
    {
      name: 'West Sayville',
    },
    {
      name: 'West Seneca',
    },
    {
      name: 'Westbury',
    },
    {
      name: 'Westchester County',
    },
    {
      name: 'Westerleigh',
    },
    {
      name: 'Westfield',
    },
    {
      name: 'Westhampton',
    },
    {
      name: 'Westhampton Beach',
    },
    {
      name: 'Westmere',
    },
    {
      name: 'Weston Mills',
    },
    {
      name: 'Westvale',
    },
    {
      name: 'Wheatley Heights',
    },
    {
      name: 'White Plains',
    },
    {
      name: 'Whitehall',
    },
    {
      name: 'Whitesboro',
    },
    {
      name: 'Whitestone',
    },
    {
      name: 'Williamsburg',
    },
    {
      name: 'Williamson',
    },
    {
      name: 'Williamsville',
    },
    {
      name: 'Williston Park',
    },
    {
      name: 'Willowbrook',
    },
    {
      name: 'Wilson',
    },
    {
      name: 'Wolcott',
    },
    {
      name: 'Woodbury',
    },
    {
      name: 'Woodhaven',
    },
    {
      name: 'Woodlawn',
    },
    {
      name: 'Woodmere',
    },
    {
      name: 'Woodrow',
    },
    {
      name: 'Woodside',
    },
    {
      name: 'Woodstock',
    },
    {
      name: 'Worcester',
    },
    {
      name: 'Wurtsboro',
    },
    {
      name: 'Wyandanch',
    },
    {
      name: 'Wykagyl',
    },
    {
      name: 'Wynantskill',
    },
    {
      name: 'Wyoming County',
    },
    {
      name: 'Yaphank',
    },
    {
      name: 'Yates County',
    },
    {
      name: 'Yonkers',
    },
    {
      name: 'Yorkshire',
    },
    {
      name: 'Yorktown Heights',
    },
    {
      name: 'Yorkville',
    },
    {
      name: 'Youngstown',
    },
    {
      name: 'Zena',
    },
  ],
  njStates: [
    {
      name: 'Absecon',
    },
    {
      name: 'Allendale',
    },
    {
      name: 'Asbury Park',
    },
    {
      name: 'Ashland',
    },
    {
      name: 'Atlantic City',
    },
    {
      name: 'Audubon',
    },
    {
      name: 'Avenel',
    },
    {
      name: 'Barclay-Kingston',
    },
    {
      name: 'Barrington',
    },
    {
      name: 'Bayonne',
    },
    {
      name: 'Beachwood',
    },
    {
      name: 'Belleville',
    },
    {
      name: 'Bellmawr',
    },
    {
      name: 'Belmar',
    },
    {
      name: 'Bergenfield',
    },
    {
      name: 'Berkeley Heights',
    },
    {
      name: 'Berlin',
    },
    {
      name: 'Bernardsville',
    },
    {
      name: 'Bloomfield',
    },
    {
      name: 'Bloomingdale',
    },
    {
      name: 'Bogota',
    },
    {
      name: 'Boonton',
    },
    {
      name: 'Bound Brook',
    },
    {
      name: 'Bridgeton',
    },
    {
      name: 'Brigantine',
    },
    {
      name: 'Browns Mills',
    },
    {
      name: 'Budd Lake',
    },
    {
      name: 'Burlington',
    },
    {
      name: 'Butler',
    },
    {
      name: 'Caldwell',
    },
    {
      name: 'Camden',
    },
    {
      name: 'Carneys Point',
    },
    {
      name: 'Carteret',
    },
    {
      name: 'Cedar Grove',
    },
    {
      name: 'Chatham',
    },
    {
      name: 'Cherry Hill Mall',
    },
    {
      name: 'Clark',
    },
    {
      name: 'Clayton',
    },
    {
      name: 'Cliffside Park',
    },
    {
      name: 'Clifton',
    },
    {
      name: 'Closter',
    },
    {
      name: 'Collingswood',
    },
    {
      name: 'Colonia',
    },
    {
      name: 'Cranford',
    },
    {
      name: 'Cresskill',
    },
    {
      name: 'Crestwood Village',
    },
    {
      name: 'Dayton',
    },
    {
      name: 'Dover',
    },
    {
      name: 'Dumont',
    },
    {
      name: 'Dunellen',
    },
    {
      name: 'East Brunswick',
    },
    {
      name: 'East Orange',
    },
    {
      name: 'East Rutherford',
    },
    {
      name: 'Eatontown',
    },
    {
      name: 'Echelon',
    },
    {
      name: 'Edgewater',
    },
    {
      name: 'Edison',
    },
    {
      name: 'Elizabeth',
    },
    {
      name: 'Elmwood Park',
    },
    {
      name: 'Emerson',
    },
    {
      name: 'Englewood',
    },
    {
      name: 'Erlton-Ellisburg',
    },
    {
      name: 'Ewing',
    },
    {
      name: 'Fair Lawn',
    },
    {
      name: 'Fairfield',
    },
    {
      name: 'Fairview',
    },
    {
      name: 'Fanwood',
    },
    {
      name: 'Florence-Roebling',
    },
    {
      name: 'Florham Park',
    },
    {
      name: 'Fords',
    },
    {
      name: 'Fort Dix',
    },
    {
      name: 'Fort Lee',
    },
    {
      name: 'Franklin Lakes',
    },
    {
      name: 'Freehold',
    },
    {
      name: 'Garfield',
    },
    {
      name: 'Glassboro',
    },
    {
      name: 'Glen Ridge',
    },
    {
      name: 'Glen Rock',
    },
    {
      name: 'Gloucester City',
    },
    {
      name: 'Greentree',
    },
    {
      name: 'Guttenberg',
    },
    {
      name: 'Hackensack',
    },
    {
      name: 'Hackettstown',
    },
    {
      name: 'Haddon Heights',
    },
    {
      name: 'Haddonfield',
    },
    {
      name: 'Haledon',
    },
    {
      name: 'Hammonton',
    },
    {
      name: 'Harrison',
    },
    {
      name: 'Hasbrouck Heights',
    },
    {
      name: 'Hawthorne',
    },
    {
      name: 'Highland Park',
    },
    {
      name: 'Hillsdale',
    },
    {
      name: 'Hillside',
    },
    {
      name: 'Hoboken',
    },
    {
      name: 'Holiday City-Berkeley',
    },
    {
      name: 'Hopatcong',
    },
    {
      name: 'Irvington',
    },
    {
      name: 'Iselin',
    },
    {
      name: 'Jamesburg',
    },
    {
      name: 'Jersey City',
    },
    {
      name: 'Keansburg',
    },
    {
      name: 'Kearny',
    },
    {
      name: 'Kendall Park',
    },
    {
      name: 'Kenilworth',
    },
    {
      name: 'Keyport',
    },
    {
      name: 'Kinnelon',
    },
    {
      name: 'Lake Mohawk',
    },
    {
      name: 'Lakewood',
    },
    {
      name: 'Laurence Harbor',
    },
    {
      name: 'Leisure Village West-Pine Lake Park',
    },
    {
      name: 'Leonia',
    },
    {
      name: 'Lincoln Park',
    },
    {
      name: 'Lincroft',
    },
    {
      name: 'Linden',
    },
    {
      name: 'Lindenwold',
    },
    {
      name: 'Linwood',
    },
    {
      name: 'Little Falls',
    },
    {
      name: 'Little Ferry',
    },
    {
      name: 'Little Silver',
    },
    {
      name: 'Livingston',
    },
    {
      name: 'Lodi',
    },
    {
      name: 'Long Branch',
    },
    {
      name: 'Lyndhurst',
    },
    {
      name: 'Madison',
    },
    {
      name: 'Madison Park',
    },
    {
      name: 'Manasquan',
    },
    {
      name: 'Manville',
    },
    {
      name: 'Maplewood',
    },
    {
      name: 'Margate City',
    },
    {
      name: 'Marlton',
    },
    {
      name: 'Matawan',
    },
    {
      name: 'Maywood',
    },
    {
      name: 'McGuire AFB',
    },
    {
      name: 'Mercerville-Hamilton Square',
    },
    {
      name: 'Metuchen',
    },
    {
      name: 'Middlesex',
    },
    {
      name: 'Midland Park',
    },
    {
      name: 'Millburn',
    },
    {
      name: 'Milltown',
    },
    {
      name: 'Millville',
    },
    {
      name: 'Montclair',
    },
    {
      name: 'Montvale',
    },
    {
      name: 'Moorestown-Lenola',
    },
    {
      name: 'Morganville',
    },
    {
      name: 'Morristown',
    },
    {
      name: 'Mountainside',
    },
    {
      name: 'Mystic Island',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'New Milford',
    },
    {
      name: 'New Providence',
    },
    {
      name: 'Newark',
    },
    {
      name: 'Newton',
    },
    {
      name: 'North Arlington',
    },
    {
      name: 'North Brunswick Township',
    },
    {
      name: 'North Caldwell',
    },
    {
      name: 'North Haledon',
    },
    {
      name: 'North Plainfield',
    },
    {
      name: 'Northfield',
    },
    {
      name: 'Nutley',
    },
    {
      name: 'Oakland',
    },
    {
      name: 'Ocean Acres',
    },
    {
      name: 'Ocean City',
    },
    {
      name: 'Old Bridge',
    },
    {
      name: 'Oradell',
    },
    {
      name: 'Orange',
    },
    {
      name: 'Palisades Park',
    },
    {
      name: 'Palmyra',
    },
    {
      name: 'Paramus',
    },
    {
      name: 'Park Ridge',
    },
    {
      name: 'Passaic',
    },
    {
      name: 'Paterson',
    },
    {
      name: 'Paulsboro',
    },
    {
      name: 'Pennsauken',
    },
    {
      name: 'Pennsville',
    },
    {
      name: 'Perth Amboy',
    },
    {
      name: 'Phillipsburg',
    },
    {
      name: 'Pine Hill',
    },
    {
      name: 'Pitman',
    },
    {
      name: 'Plainfield',
    },
    {
      name: 'Pleasantville',
    },
    {
      name: 'Point Pleasant',
    },
    {
      name: 'Pompton Lakes',
    },
    {
      name: 'Princeton',
    },
    {
      name: 'Princeton Meadows',
    },
    {
      name: 'Rahway',
    },
    {
      name: 'Ramblewood',
    },
    {
      name: 'Ramsey',
    },
    {
      name: 'Raritan',
    },
    {
      name: 'Red Bank',
    },
    {
      name: 'Ridgefield',
    },
    {
      name: 'Ridgefield Park',
    },
    {
      name: 'Ridgewood',
    },
    {
      name: 'Ringwood',
    },
    {
      name: 'River Edge',
    },
    {
      name: 'River Vale',
    },
    {
      name: 'Rockaway',
    },
    {
      name: 'Roselle',
    },
    {
      name: 'Roselle Park',
    },
    {
      name: 'Rumson',
    },
    {
      name: 'Runnemede',
    },
    {
      name: 'Rutherford',
    },
    {
      name: 'Saddle Brook',
    },
    {
      name: 'Sayreville',
    },
    {
      name: 'Scotch Plains',
    },
    {
      name: 'Secaucus',
    },
    {
      name: 'Somers Point',
    },
    {
      name: 'Somerset',
    },
    {
      name: 'Somerville',
    },
    {
      name: 'South Amboy',
    },
    {
      name: 'South Orange',
    },
    {
      name: 'South Plainfield',
    },
    {
      name: 'South River',
    },
    {
      name: 'Spotswood',
    },
    {
      name: 'Springdale',
    },
    {
      name: 'Springfield',
    },
    {
      name: 'Stratford',
    },
    {
      name: 'Strathmore',
    },
    {
      name: 'Succasunna-Kenvil',
    },
    {
      name: 'Summit',
    },
    {
      name: 'Teaneck',
    },
    {
      name: 'Tenafly',
    },
    {
      name: 'Tinton Falls',
    },
    {
      name: 'Toms River',
    },
    {
      name: 'Totowa',
    },
    {
      name: 'Trenton',
    },
    {
      name: 'Twin Rivers',
    },
    {
      name: 'Union',
    },
    {
      name: 'Union Beach',
    },
    {
      name: 'Union City',
    },
    {
      name: 'Upper Saddle River',
    },
    {
      name: 'Ventnor City',
    },
    {
      name: 'Verona',
    },
    {
      name: 'Villas',
    },
    {
      name: 'Vineland',
    },
    {
      name: 'Waldwick',
    },
    {
      name: 'Wallington',
    },
    {
      name: 'Wanaque',
    },
    {
      name: 'Washington',
    },
    {
      name: 'Washington Township',
    },
    {
      name: 'Wayne',
    },
    {
      name: 'West Caldwell',
    },
    {
      name: 'West Freehold',
    },
    {
      name: 'West Long Branch',
    },
    {
      name: 'West Milford',
    },
    {
      name: 'West New York',
    },
    {
      name: 'West Orange',
    },
    {
      name: 'West Paterson',
    },
    {
      name: 'Westfield',
    },
    {
      name: 'Westwood',
    },
    {
      name: 'Wharton',
    },
    {
      name: 'White Horse',
    },
    {
      name: 'White Meadow Lake',
    },
    {
      name: 'Williamstown',
    },
    {
      name: 'Wood-Ridge',
    },
    {
      name: 'Woodbridge',
    },
    {
      name: 'Woodbury',
    },
    {
      name: 'Wyckoff',
    },
    {
      name: 'Yardville-Groveville',
    },
    {
      name: 'Yorketown',
    },
  ],
  indianaStates: [
    {
      name: 'Alexandria',
    },
    {
      name: 'Anderson',
    },
    {
      name: 'Angola',
    },
    {
      name: 'Auburn',
    },
    {
      name: 'Avon',
    },
    {
      name: 'Batesville',
    },
    {
      name: 'Bedford',
    },
    {
      name: 'Beech Grove',
    },
    {
      name: 'Bloomington',
    },
    {
      name: 'Bluffton',
    },
    {
      name: 'Boonville',
    },
    {
      name: 'Brazil',
    },
    {
      name: 'Brownsburg',
    },
    {
      name: 'Carmel',
    },
    {
      name: 'Cedar Lake',
    },
    {
      name: 'Chesterton',
    },
    {
      name: 'Clarksville',
    },
    {
      name: 'Columbia City',
    },
    {
      name: 'Columbus',
    },
    {
      name: 'Connersville',
    },
    {
      name: 'Crawfordsville',
    },
    {
      name: 'Crown Point',
    },
    {
      name: 'Danville',
    },
    {
      name: 'Decatur',
    },
    {
      name: 'Dyer',
    },
    {
      name: 'East Chicago',
    },
    {
      name: 'Elkhart',
    },
    {
      name: 'Elwood',
    },
    {
      name: 'Evansville',
    },
    {
      name: 'Fishers',
    },
    {
      name: 'Fort Wayne',
    },
    {
      name: 'Frankfort',
    },
    {
      name: 'Franklin',
    },
    {
      name: 'Gary',
    },
    {
      name: 'Goshen',
    },
    {
      name: 'Granger',
    },
    {
      name: 'Greencastle',
    },
    {
      name: 'Greenfield',
    },
    {
      name: 'Greensburg',
    },
    {
      name: 'Greenwood',
    },
    {
      name: 'Griffith',
    },
    {
      name: 'Hammond',
    },
    {
      name: 'Hartford City',
    },
    {
      name: 'Highland',
    },
    {
      name: 'Hobart',
    },
    {
      name: 'Huntington',
    },
    {
      name: 'Indianapolis',
    },
    {
      name: 'Jasper',
    },
    {
      name: 'Jeffersonville',
    },
    {
      name: 'Kendallville',
    },
    {
      name: 'Kokomo',
    },
    {
      name: 'La Porte',
    },
    {
      name: 'Lafayette',
    },
    {
      name: 'Lake Station',
    },
    {
      name: 'Lakes of the Four Seasons',
    },
    {
      name: 'Lawrence',
    },
    {
      name: 'Lebanon',
    },
    {
      name: 'Logansport',
    },
    {
      name: 'Lowell',
    },
    {
      name: 'Madison',
    },
    {
      name: 'Marion',
    },
    {
      name: 'Martinsville',
    },
    {
      name: 'Merrillville',
    },
    {
      name: 'Michigan City',
    },
    {
      name: 'Mishawaka',
    },
    {
      name: 'Mooresville',
    },
    {
      name: 'Mount Vernon',
    },
    {
      name: 'Muncie',
    },
    {
      name: 'Munster',
    },
    {
      name: 'Nappanee',
    },
    {
      name: 'New Albany',
    },
    {
      name: 'New Castle',
    },
    {
      name: 'New Haven',
    },
    {
      name: 'Noblesville',
    },
    {
      name: 'North Manchester',
    },
    {
      name: 'North Vernon',
    },
    {
      name: 'Peru',
    },
    {
      name: 'Plainfield',
    },
    {
      name: 'Plymouth',
    },
    {
      name: 'Portage',
    },
    {
      name: 'Portland',
    },
    {
      name: 'Princeton',
    },
    {
      name: 'Richmond',
    },
    {
      name: 'Rochester',
    },
    {
      name: 'Salem',
    },
    {
      name: 'Schererville',
    },
    {
      name: 'Scottsburg',
    },
    {
      name: 'Sellersburg',
    },
    {
      name: 'Seymour',
    },
    {
      name: 'Shelbyville',
    },
    {
      name: 'South Bend',
    },
    {
      name: 'Speedway',
    },
    {
      name: 'St. John',
    },
    {
      name: 'Tell City',
    },
    {
      name: 'Terre Haute',
    },
    {
      name: 'Valparaiso',
    },
    {
      name: 'Vincennes',
    },
    {
      name: 'Wabash',
    },
    {
      name: 'Warsaw',
    },
    {
      name: 'Washington',
    },
    {
      name: 'West Lafayette',
    },
    {
      name: 'Westfield',
    },
    {
      name: 'Zionsville',
    },
  ],
}

export default states
