import { useRef, useState } from 'react'
import { Radio } from 'antd'
import styles from './styles/OccupencyMenu.module.css'

const OccupencyMenu = (props) => {
  const {
    occupencyVal,
    setOccupencyVal,
    setOccupencyPopup,
    exactValue,
    setExactValue,
  } = props
  const exactInputRef = useRef(null)

  const selectedItem = () => {
    document.getElementById('search__btn').click()
    setOccupencyPopup(false)
  }
  const [items] = useState([
    '1-10',
    '11-25',
    '26-50',
    '100-10000',
    'Exact Count',
  ])

  return (
    <>
      <div className={styles.occupency__parent} style={{
        visibility: props.occupencyPopup ? 'visible' : "hidden",
        opacity: props.occupencyPopup ? 1 : 0
      }}>
        <div
          className="menu"
          style={{
            padding: 20,
            width: 250,
            position: 'absolute',
            top: '-2rem',
            left: '0.5rem',
          }}
        >
          <div className={styles.occupency__parent}>
            <h5 className={styles.title__text}>Occupancy</h5>
            <h5 className={styles.clear_text} onClick={() => setOccupencyVal('1-10000')}>Clear</h5>
          </div>
          {items.map((item, idx) => {
            return (
              <div key={idx} style={{ margin: '10px 0px' }}>
                <Radio.Group onChange={(e) => {
                  if (e.target.value === 'Exact Count') {
                    exactInputRef.current.focus()
                  }
                  setOccupencyVal(e.target.value)
                }}
                  value={occupencyVal}>
                  <Radio value={item}>{item}</Radio>
                </Radio.Group>
              </div>
            )
          })}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <input
              id="exact-count"
              ref={exactInputRef}
              type="text"
              value={exactValue}
              onClick={(e) => setOccupencyVal('Exact Count')}
              onChange={(e) => setExactValue(e.target.value)}
              placeholder="e.g. 77"
              style={{ borderRadius: 4, padding: 5, border: '#aaa 1px solid' }}
            />
          </div>
          <button className={styles.done_btn} onClick={selectedItem}>
            <span>
              Done
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default OccupencyMenu
