import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { searchForSuggation } from '../../redux/actions/BasicPolicyAction';

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const dispatch = useDispatch()
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        dispatch( searchForSuggation({
            searched_key:value,
            callBack:(response)=>{
                let options = []
                response.data.map((suggestion)=>{ 
                    options.push({
                        label: `${suggestion.key}`,
                        value: suggestion.id
                    })
                }
                )
                if (fetchId !== fetchRef.current) {
                  // for fetch callback order
                  return;
                }
                setOptions(options);
                setFetching(false);

            }
        }))

        // fetchOptions(value).then((newOptions) => {
        // });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
}
  
  // Usage of DebounceSelect
  
async function fetchUserList(username) {
    
    console.log('fetching user', username);
    
    // return fetch('http://127.0.0.1:8000/api/property/address',{
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({searched_key:username})
        
    // }).then( (response)=>{ console.log(response); } )
    // .then((response) => {response.json()})
    //     .then( (body) => {
    //             console.log(body);
    //             return body.results.map((user) => ({
    //                 label: `${user.key}`,
    //                 value: user.id,
    //             }))
    //         },
    //     );
}

export default function SuggestionsBoxV2({handleChange}) {
    const [value, setValue] = useState([]);
    const dispatch = useDispatch()
    function setMapData(value) {
        // console.log(value);
        dispatch( searchForSuggation({
            find_coordinates:value.value,
            callBack:(response)=>{
                handleChange(response.data.lat,response.data.lng)
            }
        }))
    }
    return (
      <DebounceSelect
        value={value}
        showSearch
        allowClear
        placeholder="Type your address here"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
            setMapData(newValue)
            // console.log(newValue)
            setValue(newValue);
        }}
        style={{
          width: '50%',
          top:"159px",
          left:"25%",
          zIndex:"1000"
        }}
      />
    );
}