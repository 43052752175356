import { AvailabilityActionType } from '../contants/actions-type'
const initialState = {
  availability: null,
  availabilities: null,
}

export default function AvailabilityReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case AvailabilityActionType.SELECT_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }
    case AvailabilityActionType.SET_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }

    case AvailabilityActionType.SELECT_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }

    case AvailabilityActionType.CREATE_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }

    case AvailabilityActionType.UPDATE_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }

    case AvailabilityActionType.REMOVE_AVAILABILITY:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
