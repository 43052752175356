import React, { useState, useEffect, createRef } from 'react'
import { userType } from '../redux/contants/user-type'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { login, resetPasswordEmail } from '../redux/actions/AuthAction'
import { useParams } from 'react-router'
import { Button, Checkbox, Form, Input,notification } from 'antd';

export default function ResetPassword(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)
  const params = useParams()
  const auth = useSelector((state) => state.auth)
  const formRef = React.createRef();
  // useEffect(() => {
  //   if (auth.authenticated) {
  //     navigate('/')
  //   }
  // }, [auth.authenticated])

  const onFinish = (values) => {
    let data = {
      "email":params.email,
      "type":params.type,
      "token":params.token,
      "password":values.password,
      "confirm_password":values.confirm_password
    }

    dispatch( resetPasswordEmail({
      ...data,
      callBack: (response)=>{
        if(response.status == 406){
          openNotificationWithIcon('error','Oops !',response.data[0])
          navigate('/')
        }

        if(response.status == 200){
          openNotificationWithIcon('success','success',response.data[0])
          navigate('/')
        }

      }
    }))
  }

  function validatePassword(_,newPassword) {
    var minNumberofChars = 6
    var maxNumberofChars = 16
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    var passowrd_validet_regEx = /[!@#$%^&*()]/g
    
    if (
      newPassword.length < minNumberofChars ||
      newPassword.length > maxNumberofChars
    ) {
      return Promise.reject(new Error('password need to be at least 6 charecter and maximum 16 charecter'));
    }

    if (passowrd_validet_regEx.test(newPassword)) {
      return Promise.reject(new Error('password cannot have special character'));
    }

    return Promise.resolve();
  }

  const openNotificationWithIcon = (type,title,message) => {
    notification[type]({
      message: title,
      description:message
    });
  };

  const matchPassword = (_, values) => {
    let value = formRef.current.getFieldValue();
    if (value.password == value.confirm_password) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('password not matched'));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  return <>
    <div style={{
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      height: "900px",
    }} 
    className="container">
      <Form
        name="basic"
        style={{width:"50%"}}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        ref={formRef}
      >

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { validator: validatePassword}
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          rules={[
            { required: true, message: 'Please input your confirm password!' },
            { validator: matchPassword}
          ]}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>

        </Form.Item>
      </Form>
    </div>
  </>
}
