import React from 'react'
import bussinessTravel from './../../img/BusinessTravel.png'
import finances from './../../img/Finances.png'

export default function TakeUserRole({ data, setData, setStepper }) {
  let handleRoleSelection = (role) => {
    setData({
      ...data,
      role: role,
    })
    setStepper('form')
  }
  return (
    <div className="take-user-role">
      <div className="guest-option-section">
        <img src={bussinessTravel} alt="" srcSet="" />
        <p style={{ fontSize: 14, height: 200, fontWeight: '500', textAlign:'left' }}>
          Looking for a unique space to suit your unique needs? Interested in renting a space that links
          to its community in positive way? Venuely guests get access to beautiful, one of a kind, spaces
          that are mission oriented so you know your dollars stay in the neighborhood and go towards good causes.
        </p>
        <div
          onClick={() => {
            handleRoleSelection('Renter')
          }}
          className="select-guest-option-button role-option-select-button"
          style={{ fontSize: 14 }}
        >
          I'm a Guest
        </div>
      </div>
      <div className="host-option-section">
        <img src={finances} alt="" srcSet="" />
        <p style={{ fontSize: 14, height: 200, fontWeight: '500', textAlign:'left' }}>
          Looking for ways to share your unique spaces that go unused most of the week? Interested in establishing new links to your local community? Venuely hosts get access to mission focused renters and space sharers
          interested in keeping their dollars in the neighborhood and establishing healthy supportive communities.
        </p>
        <div
          onClick={() => {
            handleRoleSelection('Host')
          }}
          className="select-host-option-button role-option-select-button"
          style={{ fontSize: 14 }}
        >
          I'm a Host
        </div>
      </div>
    </div>
  )
}
