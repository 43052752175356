import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import BufferTimeRuls from '../../components/BufferTimeRuls'
import BookingWindowRules from '../../components/BookingWindowRules'
import { setSpace } from '../../redux/actions/SpaceAction'
import {
  setPolicy,
  selectPolicy,
  createPolicy,
  updatePolicy,
} from '../../redux/actions/BasicPolicyAction'
import { getAllInputValue } from '../../redux/requests'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'
import {
  createBufferTimeRole,
  deleteBufferTimeRole,
  setBufferTimeRole,
  updateBufferTimeRole,
} from '../../redux/actions/BufferTimeRoleAction'
import {
  createWindowBookingRole,
  deleteWindowBookingRole,
  setWindowBookingRole,
  updateWindowBookingRole,
} from '../../redux/actions/WindowBookingRoleAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import BlockedDateList from '../../components/BlockedDateList'

const formRef = createRef()
export default function PolicyAndRulsOfSpaceInsert() {
  const property = useSelector((state) => state.information.property)
  const formData = useSelector((state) => state.information.form_data)
  const [cancellation_policy_id, setCancellation_policy_id] = useState(
    property && property.policies_and_rules.basic_policy.cancellation_policy.id,
  )

  const [allSpaces, setAllSpaces] = useState([])
  const [canAddBufferRole, setcanAddBufferRole] = useState(true)
  const [canAddWindowRole, setCanAddWindowRole] = useState(true)
  const [insurance_required, setInsurance_required] = useState(false)
  // "property && property.policies_and_rules.basic_policy.insurance_need", // not working properly above, need to do it at useEffect below

  const [buffet_time_rules, setBuffet_time_rules] = useState([])
  const [booking_window_rules, setBooking_window_rules] = useState([])
  const [buffet_time_rules_options_data, setBuffet_time_rules_options_data] =
    useState(null)
  const [
    booking_window_time_rules_options_data,
    setBooking_window_time_rules_options_data,
  ] = useState(null)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setInsurance_required(
      property && property.policies_and_rules.basic_policy.insurance_need,
    )
  }, [property])

  useEffect(() => {
    if (loading) {
      dispatch(
        setInformation({
          callBack: (response) => {
            dispatch(
              setBanner({
                header: 'Your Space: Policies & Rules',
                discription: 'Set the policies for all of your spaces',
                button: {
                  visible: false,
                  link: null,
                  name: null,
                },
                progress_bar: {
                  visible: true,
                  progress: 25 * 1,
                },
                pages: {
                  visible: true,
                  total_page: 1,
                  page_number: 4,
                },
                callBack: () => {
                  let cancellation_policy_id =
                    response.data.property.policies_and_rules.basic_policy
                      .cancellation_policy.id
                  setCancellation_policy_id(cancellation_policy_id)
                  changeBufferTimeRoleState()
                  changeBookingWindowRoleState()
                  // setFormData()
                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])

  function checkIfDuplicateExists(arr) {
    let space = arr.map((list) => list.space),
      space_object_list = [],
      space_id_list = []

    space.map((spaceList) => {
      spaceList.map((singleSpace) => {
        space_object_list.push(singleSpace)
        space_id_list.push(singleSpace.id)
      })
    })
    return new Set(space_id_list).size != space_object_list.length
  }

  let validateData = async () => {
    let is_validete = []
    await dispatch(
      setInformation({
        callBack: (response) => {
          let basic_policy =
              response.data.property.policies_and_rules.basic_policy,
            previous_booking_window_rules_space =
              basic_policy.booking_window_rules.map((data) => data.space),
            previous_buffer_time_rules_space =
              basic_policy.buffer_time_rules.map((data) => data.space),
            previous_buffer_time_rules_space_id = [],
            previous_booking_window_rules_space_id = []

          previous_buffer_time_rules_space.map((data) => {
            data.map((space) => {
              previous_buffer_time_rules_space_id.push(space.id)
            })
          })

          previous_booking_window_rules_space.map((data) => {
            data.map((space) => {
              previous_booking_window_rules_space_id.push(space.id)
            })
          })
          if (checkIfDuplicateExists(buffet_time_rules)) {
            is_validete.push(!checkIfDuplicateExists(buffet_time_rules))
            NotificationManager.warning(
              `you cannot have same space to multiple buffet time rules`,
              'Buffet Time Rules Space',
              3000,
            )
          }

          buffet_time_rules.map((data) => {
            if (data.id == null) {
              data.space.map((space) => {
                console.log(space)
                if (previous_buffer_time_rules_space_id.includes(space.id)) {
                  NotificationManager.warning(
                    `${space.name} has already a buffet time rules space`,
                    'Buffet Time Rules Space',
                    3000,
                  )
                  is_validete.push(false)
                } else {
                  is_validete.push(true)
                }
              })
            }
          })
          if (checkIfDuplicateExists(booking_window_rules)) {
            is_validete.push(!checkIfDuplicateExists(booking_window_rules))
            NotificationManager.warning(
              `you cannot have same space to multiple booking window rules`,
              'Buffet Time Rules Space',
              3000,
            )
          }
          booking_window_rules.map((data) => {
            if (data.id == null) {
              data.space.map((space) => {
                console.log(space)
                if (previous_booking_window_rules_space_id.includes(space.id)) {
                  NotificationManager.warning(
                    `${space.name} hase already a booking window rules`,
                    'Booking Window Rules',
                    3000,
                  )
                  is_validete.push(false)
                } else {
                  is_validete.push(true)
                }
              })
            }
          })

          // console.log(previous_buffer_time_rules_space_id,previous_booking_window_rules_space_id);
          // console.log(buffet_time_rules)
          // booking_window_rules
        },
      }),
    )

    return is_validete
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let form = e.target.elements
    let requiredData = {
      insurance_need: insurance_required,
      cancellation_policy: formRef.current.cancellation_policy.value,
    }

    validateData().then((data) => {
      if (!data.includes(false)) {
        dispatch(
          updateInformation({
            basic_policies: { ...requiredData },
            callBack: (response) => {
              navigate(`/space/policy-and-ruls/house-rules`)
            },
          }),
        )

        create_or_update_buffer_time()
        create_or_update_booking_window()
      }
    })
  }

  let create_or_update_buffer_time = async () => {
    await buffet_time_rules.map((buffet_time_rule) => {
      let is_update = buffet_time_rule.id != null
      if (!is_update) {
        let space_list = buffet_time_rule.space.map((space) => {
          return space.id
        })

        if (buffet_time_rule.time.id != undefined && space_list.length > 0) {
          dispatch(
            createBufferTimeRole({
              time: buffet_time_rule.time.id,
              space: space_list,
              callBack: (response) => {
                if (response.status == 400) {
                  Object.keys(response.data).map((key) => {
                    NotificationManager.warning(response.data[key], key, 3000)
                    navigate(-1)
                  })
                }
              },
            }),
          )
        }
      }

      // if(is_update){
      //     let space_list = buffet_time_rule.space.map( space => {return space.id} )
      //     dispatch( updateBufferTimeRole( {
      //         id:buffet_time_rule.id,
      //         time:buffet_time_rule.time.id,
      //         space:space_list,
      //         callBack:(response)=>{}
      //     }))
      // }
    })
  }

  let create_or_update_booking_window = async () => {
    await booking_window_rules.map((booking_window_time_rule) => {
      let is_update = booking_window_time_rule.id != null
      if (!is_update) {
        let space_list = booking_window_time_rule.space.map((space) => {
          return space.id
        })
        if (
          booking_window_time_rule.time.id != undefined &&
          space_list.length > 0
        ) {
          dispatch(
            createWindowBookingRole({
              time: booking_window_time_rule.time.id,
              space: space_list,
              callBack: (response) => {
                console.log(response.status)
                if (response.status == 400) {
                  Object.keys(response.data).map((key) => {
                    NotificationManager.warning(response.data[key], key, 3000)
                    navigate(-1)
                  })
                }
              },
            }),
          )
        }
      }

      // if(is_update){
      //     let space_list = booking_window_time_rule.space.map( space => {return space.id} )

      //     dispatch( updateWindowBookingRole( {
      //         id:booking_window_time_rule.id,
      //         time:booking_window_time_rule.time.id,
      //         space:space_list,
      //         callBack:(response)=>{}
      //     }))
      // }
    })
  }

  let addBufferTimeRuls = (e) => {
    e.preventDefault()
    dispatch(
      createBufferTimeRole({
        callBack: (response) => {
          changeBufferTimeRoleState()
        },
      }),
    )
  }

  let changeBufferTimeRoleState = () => {
    dispatch(
      setBufferTimeRole({
        callBack: (response) => {
          setBuffet_time_rules(response.data.ruls)
          setBuffet_time_rules_options_data(response.data.options)
          let showAddButton =
            response.data.options.space.length == 0 ? false : true
          setcanAddBufferRole(showAddButton)
          if (!showAddButton) {
            response.data.ruls.map((rule) => {
              if (rule.space.length == 0) {
                dispatch(
                  deleteBufferTimeRole({
                    id: rule.id,
                    callBack: (response) => {
                      changeBufferTimeRoleState()
                      setBuffet_time_rules(response.data.ruls)
                    },
                  }),
                )
              }
            })
          }
        },
      }),
    )
  }

  let changeBookingWindowRoleState = () => {
    dispatch(
      setWindowBookingRole({
        callBack: (response) => {
          setBooking_window_rules(response.data.ruls)
          setBooking_window_time_rules_options_data(response.data.options)
          let showAddButton =
            response.data.options.space.length == 0 ? false : true
          setCanAddWindowRole(showAddButton)
          if (!showAddButton) {
            response.data.ruls.map((rule) => {
              if (rule.space.length == 0) {
                dispatch(
                  deleteWindowBookingRole({
                    id: rule.id,
                    callBack: (response) => {
                      changeBookingWindowRoleState()
                      setBooking_window_rules(response.data.ruls)
                    },
                  }),
                )
              }
            })
          }
        },
      }),
    )
  }

  let addBookingWindowRules = (e) => {
    e.preventDefault()
    dispatch(
      createWindowBookingRole({
        callBack: (response) => {
          changeBookingWindowRoleState()
        },
      }),
    )
  }

  let handleDeleteBufferTimeRole = (index) => {
    if (buffet_time_rules[index].id != null) {
      dispatch(
        deleteBufferTimeRole({
          id: buffet_time_rules[index].id,
          callBack: (response) => {
            changeBufferTimeRoleState()
            setBuffet_time_rules(response.data.ruls)
          },
        }),
      )
    }
  }

  let handleDeleteBookingWindowRules = (index) => {
    if (booking_window_rules[index].id != null) {
      dispatch(
        deleteWindowBookingRole({
          id: booking_window_rules[index].id,
          callBack: (response) => {
            changeBookingWindowRoleState()
            setWindowBookingRole(response.data.ruls)
          },
        }),
      )
    }
  }

  let handleBufferTimeRoleData = (formData) => {
    dispatch(
      updateBufferTimeRole({
        id: formData.id,
        ...(Object.keys(formData.time).length > 0
          ? { time: formData.time.id }
          : {}),
        space: formData.space.map((s) => {
          return s.id
        }),
        callBack: (response) => {
          changeBufferTimeRoleState()
        },
      }),
    )
  }

  let handleBookingWindowRulesData = (formData) => {
    dispatch(
      updateWindowBookingRole({
        id: formData.id,
        ...(Object.keys(formData.time).length > 0
          ? { time: formData.time.id }
          : {}),
        space: formData.space.map((s) => {
          return s.id
        }),
        callBack: (response) => {
          changeBookingWindowRoleState()
        },
      }),
    )
  }

  let desabledButtonStyle = {
    backgroundColor: 'lightGray',
    border: 'none',
  }

  return (
    <div
      className="space-setup-inserte-section hide-scrollbar"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form
        ref={formRef}
        className="policy-and-ruls-of-space-insert-space-form"
      >
        <h1 className="radio-button-group-input-lable">Insurance Policy</h1>
        <p className="radio-button-group-input-lable-description">
          This applies to all spaces you have published.
        </p>
        <p
          className="radio-button-group-input-lable-description"
          style={{ fontSize: 18 }}
        >
          Do you require that guests provide proof of commercial liability
          insurance?
        </p>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="checkbox"
              id="example_space_1"
              name="example_space_1"
              // value="Yes"
              style={{ marginTop: 3 }}
              checked={insurance_required ? true : false}
              onClick={(v) => setInsurance_required(true)}
            />
            <span style={{ marginLeft: 4 }}>Yes</span>
          </div>
          <div style={{ width: 20 }}></div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input
              type="checkbox"
              id="example_space_1"
              name="example_space_1"
              // value="No"
              style={{ marginTop: 3 }}
              checked={!insurance_required ? true : false}
              onClick={(v) => setInsurance_required(false)}
            />
            <span style={{ marginLeft: 4 }}>No</span>
          </div>
        </div>

        <hr
          style={{ marginTop: '59px', marginBottom: 59 }}
          className="policy-and-ruls-of-space-insert-lasr-hr"
        />

        <div className="radio-button-group">
          <h1 className="radio-button-group-input-lable">
            Cancellation Policy
          </h1>
          <p className="radio-button-group-input-lable-description">
            How flexible is your cancellation policy ?
          </p>
        </div>

        {formData &&
          Object.keys(formData.basic_policy.cancellation_policy_options).map(
            (key) => {
              let cancellation_policy_options =
                formData.basic_policy.cancellation_policy_options
              let is_checked =
                cancellation_policy_id == cancellation_policy_options[key].id
              return (
                <div className="radio-button" style={{ width: '800px' }}>
                  <input
                    onChange={() =>
                      setCancellation_policy_id(
                        cancellation_policy_options[key].id,
                      )
                    }
                    checked={is_checked}
                    type="radio"
                    style={{ top: '0' }}
                    name={'cancellation_policy'}
                    id={cancellation_policy_options[key].title.replaceAll(
                      ' ',
                      '_',
                    )}
                    value={cancellation_policy_options[key].id}
                  />
                  <label
                    style={{ marginLeft: '25px' }}
                    htmlFor={cancellation_policy_options[key].title.replaceAll(
                      ' ',
                      '_',
                    )}
                  >
                    {cancellation_policy_options[key].title.replaceAll(
                      '_',
                      ' ',
                    )}
                    <p style={{ marginTop: '5px' }}>
                      {cancellation_policy_options[key].description}
                    </p>
                  </label>
                </div>
              )
            },
          )}

        <hr
          style={{ marginTop: '59px' }}
          className="policy-and-ruls-of-space-insert-lasr-hr"
        />
        <div className="buffer-time-ruls">
          <h1>Buffer Time Rules</h1>
          <p>
            Buffer time enable you to enforce any necessary time gaps between
            bookings (e.g. to engable cleaning and setup for <br />
            subsequent use.) The Guests are prevented from creating bookings
            that don’t respect these rules.
          </p>
          {buffet_time_rules_options_data != null
            ? buffet_time_rules &&
              buffet_time_rules.map((rules, index) => {
                let space_option = [
                  ...rules.space,
                  ...buffet_time_rules_options_data.space,
                ]
                let time_option = [...buffet_time_rules_options_data.time]
                return (
                  <BufferTimeRuls
                    form_data={rules}
                    index={index}
                    key={index}
                    handleDelete={handleDeleteBufferTimeRole}
                    handleData={handleBufferTimeRoleData}
                    render={true}
                    space_option={space_option.sort((a, b) => {
                      return a.name.toUpperCase()[0] > b.name.toUpperCase()[0]
                        ? 1
                        : -1
                    })}
                    time_option={time_option}
                  />
                )
              })
            : ''}
          <button
            disabled={!canAddBufferRole}
            style={!canAddBufferRole ? desabledButtonStyle : {}}
            onClick={addBufferTimeRuls}
            title={
              !canAddBufferRole
                ? 'you do not have any space to add ruls'
                : 'add ruls'
            }
          >
            + Add a buffer rule
          </button>
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="booking-window-rules">
          <h1>Booking Window Rules</h1>
          <p>
            These rules contain how far in advance a booking can be made. If no
            rules are set, the booking can be created arbitrarily far in
            advance.
          </p>
          {booking_window_time_rules_options_data != null
            ? booking_window_rules.map((rule, index) => {
                let space_option = [
                  ...rule.space,
                  ...booking_window_time_rules_options_data.space,
                ]
                let time_option = [
                  ...booking_window_time_rules_options_data.time,
                ]

                return (
                  <BookingWindowRules
                    form_data={rule}
                    index={index}
                    key={index}
                    handleDelete={handleDeleteBookingWindowRules}
                    handleData={handleBookingWindowRulesData}
                    render={true}
                    space_option={space_option.sort((a, b) => {
                      return a.name.toUpperCase()[0] > b.name.toUpperCase()[0]
                        ? 1
                        : -1
                    })}
                    time_option={time_option}
                  />
                )
              })
            : ''}
          <button
            disabled={!canAddWindowRole}
            style={!canAddWindowRole ? desabledButtonStyle : {}}
            onClick={addBookingWindowRules}
            title={
              !canAddWindowRole
                ? 'you do not have any space to add ruls'
                : 'add ruls'
            }
          >
            + Add a window rule
          </button>
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <BlockedDateList />
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="policy-and-ruls-of-space-insert-space-button-group">
          <button onClick={handleSubmit} type="submit">
            {' '}
            Next{' '}
          </button>
        </div>
      </form>
    </div>
  )
}

let compareAndSort = (a, b) => {
  return a.title[0] - b.title[0]
}
