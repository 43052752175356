import { PricingActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const createPricing = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space/pricing/', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: PricingActionType.CREATE_PRICING,
    payload: {},
  })
}

export const updatePricing = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/pricing/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: PricingActionType.UPDATE_PRICING,
    payload: {},
  })
}
