import React, { useEffect, useState } from 'react'
import placeHolderImage from './../img/Placeholder.png'
import { useSelector, useDispatch } from 'react-redux'
import { selectReservation } from '../redux/actions/PropertyAction'
import { baseURL } from '../redux/requests'
// import { dateConvertForReservation } from '../redux/requests';
export default function ResarvationCardV2({
  resarvation,
  setSelectedReservation,
  pageName,
  setPageName,
}) {
  const [reservationDetails, setReservationDetails] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      selectReservation({
        id: resarvation.id,
        callBack: (response) => {
          setReservationDetails(response.data)
          // console.log(response.data.images.length);
          // console.log(response.data.images);
        },
      }),
    )
  }, [resarvation])

  let convertTime = (time) => {
    let newtime = new Date(`1/1/2020 ${time}`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    return newtime
  }

  let dateConvertForReservation = (date) => {
    var dateObj = new Date(date)
    return `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`
  }

  return (
    <div className="resarvation-card">
      <div className="resarvation-card-thumnail">
        <img
          width={132}
          height={97}
          src={
            reservationDetails && reservationDetails.property.images.length > 0
              ? `${baseURL.replace('/api', '')}${
                  reservationDetails.property.images[0].image
                }`
              : placeHolderImage
          }
          alt=""
          srcSet=""
        />
      </div>
      <div className="resarvation-card-body-thumnail">
        <h1 className="resarvation-card-header">
          <span className="resarvation-card-header-title">Space Title:</span>
          <span className="resarvation-card-header-header">
            {reservationDetails && reservationDetails.property.name}
          </span>
        </h1>
        <p className="resarvation-card-content">
          <span className="resarvation-card-content-requested-date">
            <span className="resarvation-card-content-title">
              Requested Date:
            </span>
            <span className="resarvation-card-content-content">
              {reservationDetails &&
                dateConvertForReservation(reservationDetails.start_date)}
              {reservationDetails &&
              reservationDetails.end_date != reservationDetails.start_date
                ? ` to ${dateConvertForReservation(
                    reservationDetails.end_date,
                  )}`
                : ''}
            </span>
          </span>
          <span className="resarvation-card-content-event-duration">
            <span className="resarvation-card-content-title">
              Event Duration:
            </span>
            <span className="resarvation-card-content-content">
              {convertTime(reservationDetails && reservationDetails.start_time)}{' '}
              to{' '}
              {convertTime(reservationDetails && reservationDetails.end_time)}{' '}
            </span>
          </span>
          <span className="resarvation-card-content-planned-activity">
            <span className="resarvation-card-content-title">
              Planned Activity:
            </span>
            <span className="resarvation-card-content-content">
              {reservationDetails && reservationDetails.activity.title}
            </span>
          </span>
          <span className="resarvation-card-content-number-of-guests">
            <span className="resarvation-card-content-title">
              Number of Guests:{' '}
            </span>
            <span className="resarvation-card-content-content">
              {reservationDetails && reservationDetails.expected_guest_amount}
            </span>
          </span>
        </p>
      </div>
      <div className="resarvation-card-button">
        <button
          type="button"
          onClick={() => {
            setPageName(pageName)
            setSelectedReservation(reservationDetails)
          }}
        >
          {`${
            pageName == 'pandingRequest' ? 'Review Request' : 'View Details'
          }`}
        </button>
      </div>
    </div>
  )
}
