import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { AuthActionType } from '../../redux/contants/actions-type'

import { Link } from 'react-router-dom'
import { refresh } from '../../redux/actions/AuthAction'
import StatusBox from '../../components/StatusBox'
import { setBanner } from '../../redux/actions/ComponentAction'
import { setSpace } from '../../redux/actions/SpaceAction'
import { setReservation } from '../../redux/actions/PropertyAction'
import ResarvationDetailsCard from './ResarvationDetailsCard'
import ResarvationCard from '../../components/ResarvationCard'

export default function AcceptedBookings() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  // const reservations = useSelector(state => state.property.reservations.previous_reservation)
  const [resarvations, setResarvations] = useState([])
  const [selectedReservation, setSelectedReservation] = useState(null)

  const [reservations, setReservations] = useState(null)
  const [limit, setLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [has_next, set_has_next] = useState(false)
  const [has_previous, set_has_previous] = useState(false)
  const [showPageLoader, setShowPageLoader] = useState(false)
  useEffect(() => {
    if (loading) {
      dispatch(
        setSpace({
          callBack: (response) => {
            let has_space = Object.keys(response.data).length > 0
            dispatch(
              setBanner({
                header: 'Today',
                discription: has_space
                  ? 'Review all of your reservations in this page.'
                  : 'Complete your first listing and you’ll be a host soon!',
                button: {
                  visible: !has_space,
                  link: '/space/add/new',
                  name: 'Create Space',
                },
                progress_bar: {
                  visible: false,
                  progress: 0,
                },
                pages: {
                  visible: false,
                  total_page: 0,
                  page_number: 0,
                },
                callBack: () => {
                  setData(1, limit)
                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])
  let setData = (pageNumber, limit) => {
    setShowPageLoader(true)
    dispatch(
      setReservation({
        type: 'PAST',
        page_number: pageNumber,
        limit: limit,
        callBack: (response) => {
          setLimit(response.data.limit)
          setPageNumber(response.data.current_page_number)
          set_has_next(response.data.has_next)
          set_has_previous(response.data.has_previous)
          setReservations(response.data)
          setShowPageLoader(false)
        },
      }),
    )
  }

  let desableStyle = {
    borderColor: 'lightgray',
    color: 'lightgray',
  }

  return (
    <>
      {showPageLoader ? (
        <div className="loader" style={{ top: '69%', left: '45%' }}></div>
      ) : reservations && reservations.data.length > 0 ? (
        reservations.data.map((resarvation, index) => {
          return (
            <ResarvationCard
              key={index}
              pageName={'pastBooking'}
              resarvation={resarvation}
              setSelectedReservation={setSelectedReservation}
            />
          )
        })
      ) : (
        <div className="data-showing-box">
          <p>You don’t have any guest renting your space today</p>
        </div>
      )}
      <div
        className="reservation-pagination-button-group"
        style={{ marginTop: 50 }}
      >
        <button
          style={!has_previous ? desableStyle : {}}
          onClick={() => {
            if (has_previous) {
              setData(pageNumber - 1, limit)
            }
          }}
        >
          {' '}
          previous{' '}
        </button>
        <button
          style={!has_next ? desableStyle : {}}
          onClick={() => {
            if (has_next) {
              setData(parseInt(pageNumber) + parseInt(1), limit)
            }
          }}
        >
          {' '}
          next{' '}
        </button>
      </div>
      {selectedReservation != null ? (
        <div className="reservation-details">
          <ResarvationDetailsCard
            pageName={'pastBooking'}
            resarvation={selectedReservation}
            setSelectedReservation={setSelectedReservation}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
