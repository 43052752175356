import React, { useState, useEffect, createRef } from 'react'
import './../../style/RegistrationForm/registrationForm.css'
import ConfirmEmail from './ConfirmEmail'
import TakeEmailAddress from './TakeEmailAddress'
import TakePhoneNumber from './TakePhoneNumber'
import TakeProfilePicture from './TakeProfilePicture'
import TakeUserDetails from './TakeUserDetails'
import TakeUserRole from './TakeUserRole'
import { useSelector, useDispatch } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import closeIcon from '../icons/close.svg'

export default function RegistrationForm({ closeRegistrationFormCart }) {
  const [stepperPossition, setStepperPossition] = useState('role')
  const [RegistrationData, setRegistrationData] = useState({
    email: null,
    phone: null,
    picture: null,
    role: 'host',
    type: '',
    firstName: null,
    lastName: null,
    passowrd: null,
    check_email: false,
  })
  let createNotification = (type) => {
    return () => {
      switch (type) {
        case 'info':
          NotificationManager.info('Info message')
          break
        case 'success':
          NotificationManager.success('Success message', 'Title here')
          break
        case 'warning':
          NotificationManager.warning(
            'Warning message',
            'Close after 3000ms',
            3000,
          )
          break
        case 'error':
          NotificationManager.error('Error message', 'Click me!', 400, () => {
            alert('callback')
          })
          break
      }
    }
  }
  let stepperComponent = {
    role: (
      <TakeUserRole
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
      />
    ),
    email: (
      <TakeEmailAddress
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
        closeModel={closeRegistrationFormCart}
      />
    ),
    details: (
      <TakeUserDetails
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
      />
    ),
    phone: (
      <TakePhoneNumber
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
      />
    ),
    confitmEmail: (
      <ConfirmEmail
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
      />
    ),
    profilePicture: (
      <TakeProfilePicture
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
        closeModel={closeRegistrationFormCart}
      />
    ),
  }

  let handleCloseAction = () => {
    setStepperPossition('role')
    closeRegistrationFormCart(false)
  }

  return (
    <>
      <div
        className="registration-form-wrapper"
        style={{
          alignItems: stepperPossition === 'details' ? 'stretch' : 'center',
          paddingTop: stepperPossition === 'details' ? '20px' : '0px',
        }}
      >
        <div
          className="ragistration-form-cart"
          style={{
            maxHeight: '90vh',
          }}
        >
          <div className="ragistration-form-cart-header">
            <div
              onClick={handleCloseAction}
              className="close-registration-form-cart-button"
            >
              <img src={closeIcon} alt="" />
            </div>
            <div
              className="registration-form-cart-title"
              style={{ fontSize: 16, fontWeight: '500' }}
            >
              Sign up
            </div>
            <div></div>
          </div>
          <div className="ragistration-form-stepper">
            {stepperComponent[stepperPossition]}
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  )
}
