import React, { useState } from 'react'
import './../../style/RegistrationForm/registrationForm.css'
import LoginForm from './LoginForm'
import TakeUserRole from './TakeUserRole'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import closeIcon from '../icons/close.svg'

export default function SelectUser({ closeRegistrationFormCart }) {
  const [stepperPossition, setStepperPossition] = useState('role')
  const [RegistrationData, setRegistrationData] = useState({
    email: null,
    password: null,
    role: '',
  })

  let stepperComponent = {
    role: (
      <TakeUserRole
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
      />
    ),
    form: (
      <LoginForm
        data={RegistrationData}
        setData={setRegistrationData}
        setStepper={setStepperPossition}
        closeModal={closeRegistrationFormCart}
      />
    ),
  }

  let handleCloseAction = () => {
    setStepperPossition('role')
    closeRegistrationFormCart(false)
  }

  return (
    <div>
      <NotificationContainer />
      <div
        className="registration-form-wrapper"
        style={{
          alignItems: 'center', // Inline css here to overrdie the default css ( because this same class is used at RegistrationForm.js )
        }}
      >
        <div className="ragistration-form-cart">
          <div className="ragistration-form-cart-header">
            <div
              onClick={handleCloseAction}
              className="close-registration-form-cart-button"
            >
              <img src={closeIcon} alt="" />
            </div>
            <div
              className="registration-form-cart-title"
              style={{ fontSize: 16, fontWeight: '500' }}
            >
              Sign in
            </div>
            <div></div>
          </div>
          <div className="ragistration-form-stepper">
            {stepperComponent[stepperPossition]}
          </div>
        </div>
      </div>
    </div>
  )
}
