import React from 'react'

export default function ConfirmEmail({ data, setData, setStepper }) {
  let goToNextStep = () => {
    setStepper('phone')
  }
  return (
    <div className="confirm-email">
      <p className="step-count">Step 1 of 3</p>
      <h1>Confirm your email address</h1>
      <p>Please check your email inbox, and click the following link.</p>
      <p onClick={goToNextStep} className="confirm-email-later">
        I’ll do this later
      </p>
    </div>
  )
}
