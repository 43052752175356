import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Spin, message } from 'antd'
import { TagFilled, CloseOutlined } from '@ant-design/icons'

import HeaderBar from '../../components/HeaderBar'
import uparrow from '../../pages/icons/uparrow.svg'
import slider from '../../pages/icons/slider.svg'
import sliderFull from '../../pages/icons/slider_full.svg'
import './mybookingsreview.css'
import rectangle from '../../pages/icons/rectangle.svg'
import ellipse from '../../pages/icons/ellipse.svg'
import calendar from '../../pages/icons/calendar.svg'
import clock from '../../pages/icons/clock.svg'
import help from '../../pages/icons/help.svg'
import line from '../../pages/icons/line1.png'
import { useLocation, useNavigate } from 'react-router-dom'
import CancelBookingModal from '../../components/popups/CancelBookingModal'
import moment from 'moment'
import ReviewModal from '../../components/popups/ReviewModal'
import ContactInfoOfVenuelyAtRenter from '../../components/popups/ContactInfoOfVenuelyAtRenter'
import InsurancePolicyModalAtBookingDetails from '../../components/popups/InsurancePolicyModalAtBookingDetails'
import PaymentInformationModal from './PaymentInformationModal'

const MybookingsReview = () => {
  const [modal, setModal] = useState(false)
  const [cancelBooking, setcancelBooking] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  // const item = location.state.item
  const [item, setItem] = useState(null)
  const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL.split('/api')[0]
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [payInfoModalVis, setPayInfoModalVis] = useState(false)
  const [contactModalOn, setContactModalOn] = useState(false)
  const [isInsuranceModalVisible, setInsuranceModalVisible] = useState(false)
  const [stop_insurance_reminder_email, setStop_insurance_reminder_email] =
    useState(false)
  const [modalText, setModalText] = useState('')
  const [covidText, setCovidText] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [reservationStatus, setReservationStatus] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // console.log(location.state.item.unique_id)
  const getUserBookings = async () => {
    try {
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const res = await axios.get(
        `${baseURL}/guest/space-reservation/${location.state.item.unique_id}`,
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      // console.log(res.data)
      setItem(res.data)
      setReservationStatus(res.data.reservation_status)
      setStop_insurance_reminder_email(res.data?.stop_insurance_reminder_email)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getUserBookings()
  }, [])

  const handleStopInsuranceToggle = async () => {
    // return

    const cookie = new Cookies()
    const authData = cookie.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    const res = await axios.patch(
      `${baseURL}/guest/space-reservation/${item.id}`,
      {
        stop_insurance_reminder_email: !stop_insurance_reminder_email,
      },
      config,
    )
    // console.log(res.data)
    if (res.data) {
      setStop_insurance_reminder_email(!stop_insurance_reminder_email)
      message.success('Success')
    }
  }

  if (!item) {
    return (
      <>
        <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
        <div>
          <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
        </div>
      </>
    )
  }

  return (
    <>
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      <ReviewModal
        modalTitle={modalTitle}
        modalText={modalText}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        covidText={covidText}
      />
      <PaymentInformationModal
        payInfoModalVis={payInfoModalVis}
        setPayInfoModalVis={setPayInfoModalVis}
        data={item}
      />
      <ContactInfoOfVenuelyAtRenter
        item={item}
        contactModalOn={contactModalOn}
        setContactModalOn={setContactModalOn}
      />
      <InsurancePolicyModalAtBookingDetails
        item={item}
        isInsuranceModalVisible={isInsuranceModalVisible}
        setInsuranceModalVisible={setInsuranceModalVisible}
      />
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '80vw',
              marginTop: '5vh',
              marginBottom: '5vh',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/renter/mybookings')}
          >
            <img
              src={uparrow}
              alt=""
              style={{
                transform: 'rotate(-90deg)',
                marginTop: -8,
                marginRight: 10,
              }}
            />
            <h5 style={{ color: '#6568E6', fontSize: 16 }}>My Bookings</h5>
          </div>
          <div className="booking-process">
            <h4 className="booking-process-text">Request to book</h4>
            <h4 className="booking-process-text">Review by host</h4>
            <h4
              className="booking-process-text"
              style={{ opacity: reservationStatus === 'PENDING' ? 0.6 : 1.0 }}
            >
              Booking complete
            </h4>
          </div>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}
        >
          <img
            src={reservationStatus === 'PENDING' ? slider : sliderFull}
            alt=""
            style={{ width: 1100 }}
          />
        </div>
        <div className="booking-process" style={{ marginTop: 20 }}>
          <p
            style={{
              color: '#1B7E69',
              width: 200,
              textAlign: 'center',
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            This process would take about 24-48 hours
          </p>
        </div>
        <div className="two-part">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {reservationStatus === 'PENDING' ? (
              <>
                <div style={{ marginLeft: 30 }}>
                  <h2 style={{ fontSize: 28, fontWeight: 500 }}>
                    You requested about {item?.host?.name}'s space
                  </h2>
                  <div>
                    <p style={{ fontSize: 16 }}>
                      Please wait for the host to review your request. You will
                      get a notification email. Thanks for your patience.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ marginLeft: 30 }}>
                <h2 style={{ fontSize: 28, fontWeight: 500 }}>
                  Your event at {item?.host?.name}'s space
                </h2>
              </div>
            )}

            {item.house_rules?.insurance_need && (
              <div style={{ padding: 20 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    id="example_space_1"
                    name="example_space_1"
                    // value="Yes"
                    style={{ marginTop: 0, marginRight: 6 }}
                    checked={stop_insurance_reminder_email ? true : false}
                    onClick={(v) => handleStopInsuranceToggle()}
                  />
                  {/* <span style={{ marginLeft: 4 }}>Yes</span> */}{' '}
                  <p
                    style={{
                      marginBottom: 0,
                      fontWeight: '500',
                      fontSize: '1.3rem',
                    }}
                  >
                    Please check this box once you have purchased and have
                    submitted the commercial liability insurance to the host via
                    email.
                  </p>
                </div>
              </div>
            )}

            <img
              src={line}
              alt=""
              style={{
                filter: 'grayscale(100%)',
                marginTop: 50,
                marginBottom: 25,
                opacity: 0.7,
                width: 650,
              }}
            />

            <div className="boxes">
              <div
                className="box"
                onClick={() => {
                  setCovidText('')
                  setModalText(item.house_rules.rules)
                  setModalTitle('House Rules')
                  setIsModalVisible(true)
                }}
              >
                <h4>House rules</h4>
                <p>{item.house_rules.rules.slice(0, 150) + '...'}</p>
                <h6 className="detail">Show detail</h6>
              </div>
              <div
                className="box"
                onClick={() => {
                  setCovidText('')
                  setModalText(item.house_rules.restriction)
                  setModalTitle('Food and beverage')
                  setIsModalVisible(true)
                }}
              >
                <h4>Food and beverage rules</h4>
                <p>{item.house_rules.restriction.slice(0, 150) + '...'}</p>
                <h6 className="detail">Show detail</h6>
              </div>
              <div
                className="box"
                onClick={() => {
                  setModalText(item.house_rules.cleaning_process)
                  setModalTitle('COVID-19 policy')
                  setCovidText(item.house_rules)
                  setIsModalVisible(true)
                }}
              >
                <h4>COVID-19 policy</h4>
                <p>{item.house_rules.cleaning_process.slice(0, 150) + '...'}</p>
                <h6 className="detail">Show detail</h6>
              </div>
              {item?.message_to_host !== '' && (
                <div className="box">
                  <h4>Message with host</h4>
                  <p>{item.message_to_host}</p>
                  <h6
                    className="detail"
                    onClick={() =>
                      navigate(`/renter/messages/${item.unique_id}`)
                    }
                  >
                    View conversation
                  </h6>
                </div>
              )}
              <div
                className="box"
                onClick={() => {
                  // setCovidText('')
                  // setModalText(item.cancellation_policy.description)
                  // setModalTitle('Cancellation policy')
                  // setIsModalVisible(true)
                  setcancelBooking(true)
                  setModalText(item.cancellation_policy.description)
                }}
              >
                <h4>Cancellation policy</h4>
                <p>
                  {item.cancellation_policy.description.slice(0, 150) + '...'}
                </p>
                <h6 className="detail">Show detail</h6>
              </div>
              {/* <div>{JSON.stringify(item?.house_rules.insurance_need)}</div> */}
              {item?.house_rules?.insurance_need && (
                <div
                  className="box"
                  onClick={() => {
                    // setCovidText('')
                    // setModalText(item.house_rules.restriction)
                    // setModalTitle('Insurance Policy')
                    // setIsModalVisible(true)
                    setInsuranceModalVisible(true)
                    console.log('first')
                  }}
                >
                  <h4>Insurance Policy</h4>
                  <p>
                    The host requires the space be insured for this activity.
                    Make sure to purchase the insurance certificate using our
                    partnership link here.
                  </p>
                  <h6 className="detail">Show detail</h6>
                </div>
              )}
            </div>
            <CancelBookingModal
              propertyId={item.id}
              modalText={modalText}
              cancelBooking={cancelBooking}
              setcancelBooking={setcancelBooking}
            />
            {/* <button
              style={{
                width: 180,
                padding: 10,
                border: '#6568E6 1px solid',
                color: '#6568E6',
                fontWeight: 600,
                margin: 18,
                marginTop: 20,
                fontSize: 16,
              }}
              onClick={() => {
                setcancelBooking(true)
                setModalText(item.cancellation_policy.description)
              }}
            >
              Cancel booking
            </button> */}

            <div
              className="main-modal"
              style={{ display: modal ? 'flex' : 'none' }}
            >
              <div
                style={{
                  backgroundColor: '#000',
                  opacity: 0.5,
                  width: '100%',
                  position: 'fixed',
                  height: '100%',
                  top: 0,
                  left: 0,
                  zIndex: -1,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#fff',
                  width: 550,
                  height: 610,
                  borderRadius: 10,
                }}
              >
                <div style={{ marginLeft: 20 }}>
                  <div
                    className="header"
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginLeft: -160,
                      paddingTop: 20,
                    }}
                  >
                    <img
                      className="close-icon"
                      onClick={() => setModal(false)}
                      src="close-icon.svg"
                      alt=""
                      style={{ width: 20 }}
                    />
                    <h5
                      style={{
                        marginLeft: -200,
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        color: '#000',
                      }}
                    >
                      Arrival instruction
                    </h5>
                  </div>
                  <img
                    src="/horline.svg"
                    alt=""
                    style={{ width: '100%', opacity: 0.8, marginTop: -20 }}
                  />
                  <div style={{ overflowY: 'scroll', height: 500 }}>
                    <h4>Lorem ipsum</h4>
                    <p style={{ width: 500, marginBottom: 10 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Purus mi, aliquam pharetra, viverra risus non lorem
                      adipiscing. Sit duis senectus sed rutrum non in. Cursus
                      egestas diam tincidunt feugiat eget commodo nunc. Gravida
                      diam, pretium massa viverra sit enim. Laoreet vestibulum
                      quis ultrices non lobortis vel duis felis viverra.
                      Elementum urna, urna, sit sed sit sed. Diam et tincidunt
                      odio proin. Vitae tristique id vel morbi leo sit nulla ac
                      augue.
                    </p>
                    <h5 style={{ marginTop: 10 }}>dolor sit amet</h5>
                    <p style={{ width: 500 }}>
                      Enim consectetur pellentesque aliquet id ornare at felis
                      in. Eu, vitae amet nisl arcu pellentesque. Arcu interdum
                      porta at diam, ornare ultricies at gravida. Magna egestas
                      diam, orci sed pulvinar. Enim gravida pellentesque in
                      pellentesque morbi condimentum egestas et. Nunc turpis a
                      imperdiet sit.
                    </p>
                    <h5 style={{ marginTop: 10 }}>
                      consectetur adipiscing elit
                    </h5>
                    <p style={{ width: 500 }}>
                      Metus est lorem id malesuada ut mi ultricies eget mauris.
                      Augue mi aliquet mattis amet pharetra tortor. Maecenas
                      scelerisque tempus nulla odio. Consectetur neque arcu
                      massa tristique cras vulputate imperdiet blandit amet.
                      Aliquam lacinia habitant lacus vel eget ipsum elit. Sit ac
                      vestibulum, pellentesque eget aliquam amet vel. Sed mauris
                      magna sed proin dignissim bibendum arcu habitant.
                      Sollicitudin in volutpat, tincidunt ullamcorper. Arcu
                      enim, porta ac morbi pretium.
                    </p>
                    <h4 style={{ marginTop: 10 }}>Lorem ipsum</h4>
                    <p style={{ width: 500, marginBottom: 10 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Purus mi, aliquam pharetra, viverra risus non lorem
                      adipiscing. Sit duis senectus sed rutrum non in. Cursus
                      egestas diam tincidunt feugiat eget commodo nunc. Gravida
                      diam, pretium massa viverra sit enim. Laoreet vestibulum
                      quis ultrices non lobortis vel duis felis viverra.
                      Elementum urna, urna, sit sed sit sed. Diam et tincidunt
                      odio proin. Vitae tristique id vel morbi leo sit nulla ac
                      augue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #ccc',
                borderRadius: 8,
                // height: 970,
              }}
            >
              {item?.property.images && (
                <img
                  src={
                    `${imgURL}${item?.property?.images[0].image}` || rectangle
                  }
                  alt=""
                  style={{
                    width: 443,
                    objectFit: 'cover',
                    borderRadius: '8px 8px 0 0',
                  }}
                />
              )}
              <div
                style={{ display: 'flex', flexDirection: 'column', margin: 15 }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', margin: 10 }}
                >
                  <img
                    src={`${imgURL}${item?.host.image}` || ellipse}
                    alt=""
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: 'cover',
                      borderRadius: '100%',
                      marginRight: 12,
                    }}
                  />
                  <h5 className="info-data">{item?.host?.name}</h5>
                </div>
                <h5 className="info-titles">Address</h5>
                <h5 className="info-data">{item?.property.address}</h5>
                <h5 className="info-titles">Date & time</h5>
                <div className="info-titles-icon">
                  <img src={calendar} alt="" />
                  <h5 className="info-data">
                    {moment(item?.start_date).format('ddd, MMM, DD, YYYY')}{' '}
                    {item?.end_date != item?.start_date && (
                      <>
                        - {moment(item?.end_date).format('ddd, MMM, DD, YYYY')}
                      </>
                    )}
                  </h5>
                </div>
                <div className="info-titles-icon">
                  <img src={clock} alt="" />
                  <h5 className="info-data">
                    {moment(item?.start_time, 'HH:mm:ss a').format('hh:mm A')} -{' '}
                    {moment(item?.end_time, 'HH:mm:ss a').format('hh:mm A')}
                  </h5>
                </div>
                <h5 className="info-titles">Activity</h5>
                <h5 className="info-data">{item?.activity.title}</h5>
                <h5 className="info-titles">Attendees</h5>
                <h5 className="info-data">{item?.property.number_of_guest}</h5>
                <h5 className="info-titles">Alcohol</h5>
                <h5 className="info-data">
                  {item?.property.alcohol_consumption ? 'Yes' : 'No'}
                </h5>
                {/* <h5 className="info-titles">Price total</h5> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h5
                      style={{
                        marginRight: 5,
                        alignItems: 'center',
                        margin: '10px 0',
                        fontSize: 18,
                        fontWeight: 'bold',
                      }}
                    >
                      Subtotal
                    </h5>
                    <img
                      src={help}
                      alt=""
                      style={{
                        width: 16,
                        height: 16,
                        marginLeft: 5,
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <h5 style={{ fontSize: 18 }}>
                    ${1 * item?.total_amount.toFixed(2)}
                  </h5>
                </div>
                {item.coupon && (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p>Coupon Applied</p>
                    <p style={{}}>
                      <TagFilled style={{ color: 'black' }} />{' '}
                      {item.coupon?.code}
                    </p>
                  </div>
                )}
                <h5
                  style={{ color: '#6568E6', fontSize: 14, cursor: 'pointer' }}
                  onClick={() => setPayInfoModalVis(true)}
                >
                  Show payment information
                </h5>
              </div>
            </div>
            <div
              className="box"
              style={{
                width: '100%',
                margin: 0,
                marginTop: 20,
                marginBottom: 20,
              }}
              onClick={() => {
                setCovidText('')
                // setModalTitle('Venue Contact Information')
                // setIsModalVisible(true)
                setContactModalOn(true)
                // TODO: Add separte state for this modal
              }}
            >
              <h4 style={{ fontSize: '1.6rem' }}>Venue Contact Info</h4>
              <p>
                You can find the point of contact’s info, the location, and
                arrival instructions to the venue here!
              </p>
              <h6 className="detail">Show detail</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MybookingsReview
