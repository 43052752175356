import { InformationActionType } from '../contants/actions-type'
import { privetData,data, handleErrorAndDispatch } from '../requests'
import axios from 'axios'
import { baseURL } from '../requests'

export const setInformation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.get('/host/').catch((err) => {
    return err.response
  })

  console.log(InformationActionType.SET_INFORMATION)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.SET_INFORMATION,
    payload: response.data,
  })
}

export const setHostPublicInformation = (requiredData) => async (dispatch) => {
  let request = await data()
  const response = await request.get(`/host/${requiredData.id}`).catch((err) => {
    return err.response
  })

  console.log(InformationActionType.SET_INFORMATION)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.SET_INFORMATION,
    payload: response.data,
  })
}

export const setInformationPulic = (requiredData, id) => async (dispatch) => {
  // let request = await privetData()
  const response = await axios.get(`${baseURL}/host/${id}`).catch((err) => {
    return err.response
  })

  // console.log(InformationActionType.SET_INFORMATION)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.SET_INFORMATION,
    payload: response.data,
  })
}

export const updateInformation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.post('/host/', requiredData).catch((err) => {
    return err.response
  })

  console.log(InformationActionType.UPDATE_INFORMATION)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.UPDATE_INFORMATION,
    payload: response.data.data,
  })
}

export const updateOrCreatePaymentInformation =
  (requiredData) => async (dispatch) => {
    let request = await privetData()
    const response = await request
      .post('/host/payment-method', requiredData)
      .catch((err) => {
        return err.response
      })

    console.log(InformationActionType.UPDATE_OR_ADD_PAYMENT_INFORMATION)
    handleErrorAndDispatch(requiredData, response, dispatch, {
      type: InformationActionType.UPDATE_OR_ADD_PAYMENT_INFORMATION,
      // payload:response.data.data
    })
  }

export const setPaymentHistory = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/payout-history', requiredData)
    .catch((err) => {
      return err.response
    })

  console.log(InformationActionType.SET_PAYMENT_HISTORY)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.SET_PAYMENT_HISTORY,
    // payload:response.data.data
  })
}

export const setPaymentHistoryCSVdata = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/host/payout-history-csv', requiredData)
    .catch((err) => {
      return err.response
    })

  // console.log(InformationActionType.SET_PAYMENT_HISTORY);
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: InformationActionType.SET_PAYMENT_HISTORY,
    // payload:response.data.data
  })
}
