import { PricingActionType } from '../contants/actions-type'
const initialState = {
  pricing: {},
  pricings: [],
}

export default function PricingReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case PricingActionType.CREATE_PRICING:
      return {
        ...state,
        ...payload,
      }

    case PricingActionType.UPDATE_PRICING:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
