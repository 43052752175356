import { SpaceActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setSpace = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.get('/host/space').catch((err) => {
    return err.response
  })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: SpaceActionType.SET_SPACE,
    payload: {
      spaces: response.data,
    },
  })
}

export const selectSpace = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/${requiredData.id}/`)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: SpaceActionType.SELECT_SPACE,
    payload: {
      space: response.data,
    },
  })
}

export const createSpace = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: SpaceActionType.CREATE_SPACE,
    payload: {
      spaces: response.data.spaces,
      space: response.data.space,
    },
  })
}

export const updateSpace = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })
  // console.log(requiredData);
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: SpaceActionType.UPDATE_SPACE,
    payload: {
      spaces: response.data.spaces,
      space: response.data.space,
    },
  })
}

export const deleteSpace = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(`/host/space/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: SpaceActionType.REMOVE_SPACE,
    // payload:response.data
  })
}
