import axios from 'axios'
import { message } from 'antd'

// helper
const getAddressSuggestions = async (
  searched_key,
  setPlaceSuggestions,
  props,
) => {
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/property/address`,
    { searched_key },
  )
  console.log(searched_key)
  console.log(res.data)
  setPlaceSuggestions(res.data)
}

// helper
const getAllActivities = async (props, roomId) => {
  props.setActivityModal(!props.activityModal)
  const res = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/guest/activity`,
    {
      ...(roomId && { price_id: roomId }),
    },
  )
  props.setallActivities(res.data)
  props.setEvent(true)
}

// helper
const getActivity = async (
  val,
  props,
  selectedActivites,
  activityVal,
  optOutActivities,
) => {
  if (selectedActivites.length === 3) {
    message.error(
      'You have reached the limit of 3 activity per search. Please remove one to add another activity.',
    )
    return
  }
  if (val?.length === 0) {
    props.setactivityList(false)
  } else {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/guest/activity`,
      {
        name: activityVal,
      },
    )
    props.setactivityItemsArray(
      res.data
        .filter((ar) => !optOutActivities.find((rm) => rm.title === ar.title))
        .map((item) => item),
    )
    props.setCalendarPopup(false)
    props.setLocMenu(false)
    props.setactivityList(true)
  }
}

const getSpaceListWithoutDataInput = async (props) => {
  try {
    const fetchUrl = `${process.env.REACT_APP_BASE_URL}/guest/property`
    const reqData = {
      // distance: 200000, // * currently turned off
      activity: [],
      date: [],
      start: '',
      end: '',
      page_number: 1,
      limit: 20,
      state: 'New York',
    }
    // console.log(reqData)
    const res = await axios.post(fetchUrl, reqData)
    // console.log(res.data)
    const sortedSpaces = res?.data?.data?.sort((a, b) => {
      return a.price - b.price
    })
    props.setSpaceList({ ...res.data, data: sortedSpaces })
    props.setLocSelected(true)
  } catch (err) {
    // console.log(err)
  }
}

export {
  getAllActivities,
  getActivity,
  getAddressSuggestions,
  getSpaceListWithoutDataInput,
}
