import { PropertyActionType } from '../contants/actions-type'
import { privetData } from '../requests'

export const setProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/control/property/approval/')
    .catch((err) => {
      requiredData.callBack(err.response)
    })
  requiredData.setData(response)
  console.log(PropertyActionType.SET_PROPERTY)

  dispatch({
    type: PropertyActionType.SET_PROPERTY,
    payload: response.data,
  })
}

export const setReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/host/space/reservation', {
      params: {
        type: requiredData.type,
        page_number: requiredData.page_number,
        limit: requiredData.limit,
      },
    })
    .catch((err) => {
      requiredData.callBack(err.response)
    })
  requiredData.callBack(response)
  console.log(PropertyActionType.SET_RESERVATION)

  dispatch({
    type: PropertyActionType.SET_RESERVATION,
    payload: response.data,
  })
}

export const selectReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/reservation/${requiredData.id}`)
    .catch((err) => {
      requiredData.callBack(err.response)
    })
  requiredData.callBack(response)
  console.log(PropertyActionType.SELECT_RESERVATION)

  dispatch({
    type: PropertyActionType.SELECT_RESERVATION,
    payload: response.data,
  })
}

export const acceptSelectedReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/reservation/${requiredData.id}`, {
      cancel_reservation: false,
      ...requiredData,
    })
    .catch((err) => {
      requiredData.callBack(err.response)
    })
  requiredData.callBack(response)
  console.log(PropertyActionType.SELECT_RESERVATION)

  dispatch({
    type: PropertyActionType.SELECT_RESERVATION,
    payload: response.data,
  })
}

export const cancelSelectedReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/reservation/${requiredData.id}`, {
      cancel_reservation: true,
      ...requiredData,
    })
    .catch((err) => {
      requiredData.callBack(err.response)
    })
  requiredData.callBack(response)
  console.log(PropertyActionType.SELECT_RESERVATION)

  dispatch({
    type: PropertyActionType.SELECT_RESERVATION,
    payload: response.data,
  })
}

export const selecteProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/property/${requiredData.id}`)
    .catch((err) => {
      console.log(err)
    })
  // console.log(requiredData);
  // console.log(response);
  requiredData.callBack(response.data)
  // console.log(PropertyActionType.SELECT_PROPERTY);
  dispatch({
    type: PropertyActionType.SELECT_PROPERTY,
    payload: response.data,
  })
}

export const selectedPropertyApproval = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/control/property/approval/${requiredData.id}`)
    .catch((err) => {
      console.log(err)
    })
  if (requiredData.form) {
    requiredData.fillform({
      approval: response.data.approval,
      approval_status: response.data.approval_status.name,
      message: response.data.message,
      price_limit: response.data.price_limit,
    })
  }
  console.log(PropertyActionType.SELECTED_PROPERTY_APPROVAL, response)
  dispatch({
    type: PropertyActionType.SELECTED_PROPERTY_APPROVAL,
    payload: response.data,
  })
}

export const updateProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/property/${requiredData.id}/`, requiredData)
    .catch((err) => {
      console.log(err)
    })
  console.log(PropertyActionType.UPDATE_SELECTED_PROPERTY)
  dispatch({
    type: PropertyActionType.UPDATE_SELECTED_PROPERTY,
    // payload:response.data
  })
}

export const createProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post(`/property/`, requiredData)
    .catch((err) => {
      console.log(err)
    })
  console.log(PropertyActionType.CREATE_PROPERTY)
  dispatch({
    type: PropertyActionType.CREATE_PROPERTY,
    // payload:response.data
  })
}

export const deleteProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(`/property/${requiredData.id}`)
    .catch((err) => {
      console.log(err)
    })
  console.log(PropertyActionType.REMOVE_SELECTED_PROPERTY)
  dispatch({
    type: PropertyActionType.REMOVE_SELECTED_PROPERTY,
    // payload:response.data
  })
}

export const verifyProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/control/property/approval/${requiredData.id}/`, requiredData)
    .catch((err) => {
      console.log(err)
    })
  console.log(PropertyActionType.VERIFY_PROPERTY, response)
  dispatch({
    type: PropertyActionType.VERIFY_PROPERTY,
    // payload:response.data
  })
}

export const reserveProperty = (requiredData) => async (dispatch) => {
  let request = await privetData()
  console.log(requiredData)
  const response = await request
    .post(`/property/reserve/`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(PropertyActionType.RESERVE_PROPERTY)
  requiredData.callBack(response)
  dispatch({
    type: PropertyActionType.RESERVE_PROPERTY,
    // payload:response.data
  })
}

export const refundReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  console.log(requiredData)
  const response = await request
    .post(`/payment/refund/`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(PropertyActionType.REFUND_RESARVATION)
  requiredData.callBack(response)
  dispatch({
    type: PropertyActionType.REFUND_RESARVATION,
    // payload:response.data
  })
}

export const setRefundReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  console.log(requiredData)
  const response = await request
    .get(`/payment/refund/${requiredData.id}`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(PropertyActionType.SET_REFUND_RESARVATION)
  requiredData.callBack(response.data)
  dispatch({
    type: PropertyActionType.SET_REFUND_RESARVATION,
    // payload:response.data
  })
}

export const cancelReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  console.log(requiredData)
  const response = await request
    .patch(`/property/reserve/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(PropertyActionType.CENCEL_RESARVATION)
  requiredData.callBack(response.data)
  dispatch({
    type: PropertyActionType.CENCEL_RESARVATION,
    // payload:response.data
  })
}

export const confirmReservation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  console.log(requiredData)
  const response = await request
    .patch(`/property/reserve/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(PropertyActionType.CONFIRM_RESARVATION)
  requiredData.callBack(response.data)
  dispatch({
    type: PropertyActionType.CONFIRM_RESARVATION,
    // payload:response.data
  })
}

export const searchPropertyForSelectOption =
  (requiredData) => async (dispatch) => {
    let request = await privetData()
    const response = await request
      .post(`property/search/option/value/`, requiredData)
      .catch((err) => {
        return err.response
      })
    requiredData.callBack(response.data)
    dispatch({
      type: PropertyActionType.Search_Property_For_Select_Option,
      // payload:response.data
    })
  }
