import { useLocation } from 'react-router-dom'
import { RenterFAQ } from './RenterFAQ'
import { BecomeHostFAQ } from './BecomeHostFAQ'
import { useState } from 'react'
import 'animate.css'

const LandingFAQ = () => {
  const location = useLocation()
  const pathName = location.pathname
  const list = pathName === '/' ? RenterFAQ : BecomeHostFAQ

  const [currentIndex, setCurrentIndex] = useState(null)
  const [showAns, setShowAns] = useState(false)

  return (
    <div className="f-q-section wf-section">
      <div
        data-w-id="e61eb558-f77a-0610-69ef-e6cd6dec0e35"
        className="container faq-container"
      >
        <h2 className="pricing-h1 faq-h2">Frequently Asked Questions</h2>
        {list.map((el, idx) => {
          return (
            <div
              className="faq-wrap"
              style={{ borderBottom: '1px #e6e6e6 solid' }}
              onClick={() => {
                if (currentIndex === idx) {
                  setShowAns(false)
                  setCurrentIndex(null)
                } else {
                  setShowAns(true)
                  setCurrentIndex(idx)
                }
              }}
            >
              <div className="faq-question">
                <div className="faq-plus-wrap">
                  <div
                    className="faq-plus-l animate__animated animate__rotateIn animate__faster"
                    style={{
                      display: currentIndex !== idx ? 'block' : 'none',
                      transition: 'linear 0.1s all',
                    }}
                  ></div>
                  <div className="faq-plus"></div>
                </div>
                <div className="faq-q-text" style={{ margin: 20 }}>
                  <strong style={{ fontSize: 18 }}>{el.header}</strong>
                </div>
              </div>
              {showAns && (
                <div
                  className="faq-answer"
                  style={{
                    display: currentIndex === idx ? 'block' : 'none',
                  }}
                >
                  <p className="faq-answer-text">{el.body}</p>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LandingFAQ
