import React, { useState, useEffect } from 'react'
let weekEnd = (day) => {
  return day === 'Sun'
}
let getCount = (givenMonth, givenYear, index) => {
  var lastDate = new Date(givenYear, givenMonth, 0)
  let isNextMonth = false
  let monthNumber = givenMonth
  let count = index
  let yearNumber = givenYear
  if (index > lastDate.getDate()) {
    monthNumber = parseInt(givenMonth) + 1
    if (givenMonth == 12) {
      monthNumber = 1
      yearNumber = givenYear + 1
    }
    count = index - lastDate.getDate()
    isNextMonth = true
    // console.log(monthNumber,yearNumber,count,isNextMonth);
  }

  let date = new Date(`${monthNumber} ${count}, ${yearNumber}`)
  let dayNumber = date.getDate()
  let dayName = date.toLocaleString('en-us', { weekday: 'short' })
  let presentMonth = date.toLocaleString('en-us', { month: 'long' })
  return { isNextMonth, dayNumber, dayName, presentMonth }
}

export default function CalenderInformation({ selectedMonth, SelectedYear }) {
  const [SelectedResarvation, setSelectedResarvation] = useState(null)
  let givenMonth = selectedMonth.monthNumber
  let givenYear = SelectedYear
  let days = []
  let daySerial = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  let getResarvationOfTheDay = (day) => {
    let resarvation = [
      {
        space_name: 'space 1',
        renter_name: 'Mike Jones',
        organization: 'Mike Jones Art Studio',
        job_title: 'Studio Owner',
        requested_date_time: {
          date: 'Thu, Dec 16, 2021',
          time: '9:00 am - 8:00 pm',
        },
        planned_activity: ['Music', 'Video', 'Shoot'],
        expected_attendees: 15,
        alcohol: 'No',
        estimated_price: '$825 + Fees',
        confirmed: true,
      },
      {
        space_name: 'space 2',
        renter_name: 'Mike Jones',
        organization: 'Mike Jones Art Studio',
        job_title: 'Studio Owner',
        requested_date_time: {
          date: 'Thu, Dec 16, 2021',
          time: '9:00 am - 8:00 pm',
        },
        planned_activity: ['Music', 'Video', 'Shoot'],
        expected_attendees: 15,
        alcohol: 'No',
        estimated_price: '$825 + Fees',
        confirmed: true,
      },
      {
        space_name: 'space 3',
        renter_name: 'Mike Jones',
        organization: 'Mike Jones Art Studio',
        job_title: 'Studio Owner',
        requested_date_time: {
          date: 'Thu, Dec 16, 2021',
          time: '9:00 am - 8:00 pm',
        },
        planned_activity: ['Music', 'Video', 'Shoot'],
        expected_attendees: 15,
        alcohol: 'No',
        estimated_price: '$825 + Fees',
        confirmed: true,
      },
      {
        space_name: 'space 4',
        renter_name: 'Mike Jones',
        organization: 'Mike Jones Art Studio',
        job_title: 'Studio Owner',
        requested_date_time: {
          date: 'Thu, Dec 16, 2021',
          time: '9:00 am - 8:00 pm',
        },
        planned_activity: ['Music', 'Video', 'Shoot'],
        expected_attendees: 15,
        alcohol: 'No',
        estimated_price: '$825 + Fees',
        confirmed: false,
      },
      {
        space_name: 'space 5',
        renter_name: 'Mike Jones',
        organization: 'Mike Jones Art Studio',
        job_title: 'Studio Owner',
        requested_date_time: {
          date: 'Thu, Dec 16, 2021',
          time: '9:00 am - 8:00 pm',
        },
        planned_activity: ['Music', 'Video', 'Shoot'],
        expected_attendees: 15,
        alcohol: 'No',
        estimated_price: '$825 + Fees',
        confirmed: false,
      },
    ]
    if (day == 10 || day == 18 || day == 19) {
      return resarvation
    }
    return []
  }

  daySerial.forEach((se) => {
    days.push(
      <div key={se} className={`weekday`}>
        {se}
      </div>,
    )
  })
  let givenDate = new Date(`${givenMonth} 1, ${givenYear}`)
  let skip =
    daySerial.indexOf(givenDate.toLocaleString('en-us', { weekday: 'short' })) -
    1

  for (let index = skip; index >= 0; index--) {
    var d = new Date(givenYear, givenMonth - 1, 0)
    days.push(
      <div key={index + 'asdas'} className={`date next-month`}>
        {' '}
        {d.getDate() - index}{' '}
      </div>,
    )
  }

  for (let index = 1; index < 42 - skip; index++) {
    let { isNextMonth, dayNumber, dayName, presentMonth } = getCount(
      givenMonth,
      givenYear,
      index,
    )
    let resarvations = getResarvationOfTheDay(dayNumber)
    days.push(
      <div
        key={index}
        className={`date ${weekEnd(dayName) ? 'weekEnd' : ''} ${
          isNextMonth ? 'next-month' : ''
        }`}
      >
        {dayNumber}
        <div className="resarvations-name-list">
          {resarvations.map((resarvation, index) => {
            let resarvation_information_box_id = `${dayName}-${dayNumber}-${presentMonth}-resarvation-number-${index}-${isNextMonth}`
            if (!isNextMonth) {
              return (
                <div className="resarvation-name">
                  <div
                    className={`mark ${
                      resarvation.confirmed ? '' : 'mark-red'
                    }`}
                  ></div>
                  <p
                    onClick={(e) => {
                      setSelectedResarvation(resarvation_information_box_id)
                    }}
                  >
                    {' '}
                    {resarvation.space_name}{' '}
                  </p>
                  <div
                    className="information"
                    id={resarvation_information_box_id}
                    style={{
                      display:
                        SelectedResarvation == resarvation_information_box_id
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <div
                      className="resarvation-heading"
                      style={{
                        background: resarvation.confirmed
                          ? '#4BBE88'
                          : '#FA3E3E',
                      }}
                    >
                      <span className="resarvations-title">
                        {' '}
                        {resarvation.space_name}{' '}
                      </span>
                      <span
                        className="resarvations-close-button"
                        onClick={(e) => {
                          setSelectedResarvation(null)
                        }}
                      >
                        X
                      </span>
                    </div>
                    <div className="resarvation-info-box-content">
                      <div className="resarvation-info-box-heading">
                        <div className="renter-image">image</div>
                        <div className="renter-information">
                          <div className="renter-name">
                            <h6>Guest Name:</h6>
                            <p>Rok the great</p>
                          </div>
                          <div className="organization">
                            <h6>Organization:</h6>
                            <p>Rok Dance Studio</p>
                          </div>
                          <div className="job-title">
                            <h6> Job Title:</h6>
                            <p> Dance Instructor </p>
                          </div>
                        </div>
                      </div>
                      <div className="resarvation-info-box">
                        <div className="resarvation-date-time">
                          <h4>
                            {resarvation.confirmed ? '' : 'Requested'} Date &
                            Time
                          </h4>
                          <p className="resarvation-date">
                            {resarvation.requested_date_time.date}
                          </p>
                          <p className="resarvation-time">
                            {resarvation.requested_date_time.time}
                          </p>
                        </div>
                        <div className="activity">
                          <h4>
                            {resarvation.confirmed ? '' : 'Planned'} Activity
                          </h4>
                          {resarvation.planned_activity.map((a) => a)}
                        </div>
                        <div className="attendees">
                          <h4>
                            {resarvation.confirmed ? '' : 'Expected'} Attendees
                          </h4>
                          {resarvation.expected_attendees}
                        </div>
                        <div className="alcohol">
                          <h4>Alcohol</h4>
                          {resarvation.alcohol}
                        </div>
                        <div className="total-price">
                          <h4>
                            {resarvation.confirmed ? 'Total' : 'Estimated'}{' '}
                            Price
                          </h4>
                          {resarvation.estimated_price}
                        </div>
                      </div>
                    </div>
                    <div className="resarvation-info-box-footer">
                      <button>Booking Details</button>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>,
    )
  }

  return <div className="calender">{days.map((day) => day)}</div>
}
