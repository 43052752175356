import { ConversationActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setConversation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch('/property/reserve/conversation', { ...requiredData })
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ConversationActionType.SET_CONVERSATION,
    payload: {},
  })
}

export const selectConversation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/property/reserve/conversation/${requiredData.id}`)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ConversationActionType.SELECT_CONVERSATION,
    payload: {},
  })
}

export const sendMessage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post(`/property/reserve/conversation`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ConversationActionType.SEND_MESSAGE,
    payload: {},
  })
}
