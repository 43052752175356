import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'

export default function SpaceListItem({ id, space, index }) {
  const [showSpaceDetailsOption, setshowSpaceDetailsOption] = useState(false)
  useEffect(() => {
    console.log(space)
  }, [1])

  let desabledCss = {
    color: 'lightGray',
    cursor: 'not-allowed',
  }

  return (
    <>
      <NavLink
        to={`add/${id}`}
        className={({ isActive }) => {
          setshowSpaceDetailsOption(isActive)
          return isActive ? 'active' : ''
        }}
        style={styles.navLinkStyle}
      >
        {' '}
        {space.about.title == 'space-title'
          ? `space#${index + 1}`
          : space.about.title}{' '}
      </NavLink>
      <ul
        className={`space-list-child ${
          !showSpaceDetailsOption ? 'hide-elements' : ''
        }`}
      >
        <li className="space-list-child-item">
          <NavLink
            to={`add/${id}/setup-information`}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ color: '#000' }}
          >
            {' '}
            Setup{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            to={`add/${id}/about`}
            style={
              space.setup.email == null ||
              space.setup.phone == null ||
              space.setup.number_of_guest == null ||
              space.setup.place_category == null
                ? desabledCss
                : {}
            }
            onClick={(e) => {
              if (
                space.setup.email == null ||
                space.setup.phone == null ||
                space.setup.number_of_guest == null ||
                space.setup.place_category == null
              ) {
                e.preventDefault()
              }
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            About{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            style={space.about.title == 'space-title' ? desabledCss : {}}
            to={`add/${id}/photos`}
            onClick={(e) => {
              if (space.about.title == 'space-title') {
                e.preventDefault()
              }
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {' '}
            Photos{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            style={space.about.title == 'space-title' ? desabledCss : {}}
            to={`add/${id}/availability`}
            onClick={(e) => {
              if (space.about.title == 'space-title') {
                e.preventDefault()
              }
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {' '}
            Availability{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            style={space.about.title == 'space-title' ? desabledCss : {}}
            to={`add/${id}/activities`}
            onClick={(e) => {
              if (space.about.title == 'space-title') {
                e.preventDefault()
              }
            }}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Activities & Pricing{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            onClick={(e) => {
              if (space.about.title == 'space-title') {
                e.preventDefault()
              }
            }}
            style={space.about.title == 'space-title' ? desabledCss : {}}
            to={`add/${id}/amenities`}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {' '}
            Amenities{' '}
          </NavLink>
        </li>
        <li className="space-list-child-item">
          <NavLink
            onClick={(e) => {
              if (space.about.title == 'space-title') {
                e.preventDefault()
              }
            }}
            style={space.about.title == 'space-title' ? desabledCss : {}}
            to={`add/${id}/optout`}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {' '}
            Opt out Activities{' '}
          </NavLink>
        </li>
      </ul>
    </>
  )
}

const styles = {
  navLinkStyle: {
    color: '#000',
    // marginBottom: 5,
  },
}
