import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setInformation } from '../../redux/actions/InformationAction'
import greenTick from './../../img/green_tik.png'
import dummyUser from './../../img/dummy-user.png'
import redWorning from './../../img/red_worning.png'
import groupOfGuest from './../../img/group_of_guest.png'
import venuley_varified from './../../img/venuely_verified.png'
import left_arrow_blue from './../../img/left_arrow_blue.png'
import clock from './../../img/clock.png'
import calender from './../../img/calender.png'
import shield_icon from './../../img/shield_icon.png'
import { NavLink, useParams, useNavigate } from 'react-router-dom'

import {
  baseURL,
  getTimeDifferenceBetweenTwoTime,
  calculateDiscount,
  calculatePrice,
  timeConvart,
  dateConvart,
  calculatePriceByInquiry,
  sensore_string,
} from '../../redux/requests'

import emailPhoneMasking, {
  maskingEmail,
  maskingPhone,
} from 'word-masking-helper'
import {
  selectConversation,
  sendMessage,
} from '../../redux/actions/ConversationAction'
import './inquiry.css'

export default function InquiryConversation() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [hostInformation, setHostInformation] = useState(null)
  const [guestInformation, setGuestInformation] = useState(null)
  const [inquiryInformation, setInquiryInformation] = useState(null)
  const [priceInformation, setPriceInformation] = useState(null)
  const [propertyInformation, setPropertyInformation] = useState(null)
  const [reservationInformation, setReservationInformation] = useState(null)
  const [activities, setActivities] = useState(null)
  const [conversation, setConversation] = useState(null)
  const [pagination, setPagination] = useState({
    limit: 0,
    current_page_number: 0,
    has_next: false,
    has_other_pages: false,
    has_previous: false,
    total_number_of_items: 0,
    total_number_of_page: 0,
    total_unseen_messages: 0,
  })
  const [message, setMessage] = useState('')

  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    setConversationData()
  }, [id])
  let make_message_list_scroll_bottom = () => {
    let elements = document.getElementById('conversation-list')
    elements.scroll({ top: elements.scrollHeight, behavior: 'smooth' })
    console.log('hello')
  }
  let setConversationData = () => {
    dispatch(
      selectConversation({
        id: id,
        callBack: (response) => {
          setInquiryInformation(response.data.inquiry)
          setGuestInformation(response.data.inquiry.guest)
          setHostInformation(response.data.inquiry.host)
          setPriceInformation(response.data.inquiry.price)
          setPropertyInformation(response.data.inquiry.property)
          setReservationInformation(response.data.inquiry.reservation)
          setActivities(response.data.inquiry.activity)
          setConversation(response.data.conversation)
          console.log(response.data.inquiry.property)
          make_message_list_scroll_bottom()
        },
      }),
    )
  }
  const [showLoadingOnSubmitButton, setShowLoadingOnSubmitButton] =
    useState(false)
  let handleSubmit = (e) => {
    e.preventDefault()
    setShowLoadingOnSubmitButton(true)
    if (message.length > 0) {
      let text = sensore_string(message)
      dispatch(
        sendMessage({
          id: id,
          message: text,
          callBack: (response) => {
            setInquiryInformation(response.data.inquiry)
            setGuestInformation(response.data.inquiry.guest)
            setHostInformation(response.data.inquiry.host)
            setPriceInformation(response.data.inquiry.price)
            setPropertyInformation(response.data.inquiry.property)
            setReservationInformation(response.data.inquiry.reservation)
            setActivities(response.data.inquiry.activity)
            setConversation(response.data.conversation)
            // console.log(response.data.inquiry.property);
            make_message_list_scroll_bottom()
            setMessage('')
            setShowLoadingOnSubmitButton(false)
          },
        }),
      )
    }
  }

  return (
    <div className="conversation-history-wrapper" style={{ padding: '101px' }}>
      <div className="conversation-history">
        <div className="conversation-history-other-information">
          <div
            className="conversation-history-back-button"
            onClick={() => {
              navigate(-1)
            }}
          >
            <img src={left_arrow_blue} alt="" srcSet="" />
            message
          </div>
          <h1 style={{ marginTop: 20 }}>
            {propertyInformation && propertyInformation.name}
          </h1>
          <p>
            Let the guest know about how your space can meet the guest’s needs.
            Once the guest is comfortable moving forward, the guest needs to go
            through the check out process to send a proper reservation request.
            No space can be booked until you go to “Today” and accept individual
            reservation requests.
          </p>
        </div>
        <div className="conversation-list" id="conversation-list">
          {conversation &&
            conversation.map((message_data) => {
              let guest_image =
                guestInformation.image != null
                  ? `${baseURL.replace('/api', '')}${guestInformation.image}`
                  : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
              let host_image =
                hostInformation.image != null
                  ? `${baseURL.replace('/api', '')}${hostInformation.image}`
                  : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
              return (
                <div className="group-of-message-information">
                  <div className="message-user-image" style={{ width: '7%' }}>
                    <img
                      style={{ objectFit: 'cover' }}
                      src={`${
                        message_data.message_from == 'GUEST'
                          ? guest_image
                          : host_image
                      }`}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div className="message-data" style={{ width: '93%' }}>
                    <div className="message-name">
                      <div className="senders-name">
                        {message_data.message_from == 'GUEST'
                          ? guestInformation.name
                          : hostInformation.name}
                      </div>
                      <div className="message-date">
                        {`${
                          dateConvart(message_data.created.split('T')[0])
                            .dayName
                        } ${
                          dateConvart(message_data.created.split('T')[0])
                            .convertedDate
                        } ${timeConvart(message_data.created.split('T')[1])}`}
                      </div>
                    </div>
                    <p style={{ fontSize: 16, marginTop: 3 }}>
                      {message_data.message}
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
        <div className="send-message-form">
          <div className="send-message-host-image">
            <img
              src={`${baseURL.replace('/api', '')}${
                hostInformation && hostInformation.image
              }`}
              style={{ objectFit: 'cover' }}
              alt=""
              srcSet=""
            />
          </div>
          <form onSubmit={handleSubmit} className="send-message-input-group">
            <textarea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              name="message"
              id=""
              cols="30"
              rows="10"
            ></textarea>
            <button type="submit" style={{ position: 'relative' }}>
              {showLoadingOnSubmitButton ? (
                <div
                  className="loader"
                  style={{
                    top: '16%',
                    left: '40%',
                    height: '30px',
                    width: '30px',
                  }}
                ></div>
              ) : (
                'Send'
              )}
            </button>
          </form>
        </div>
      </div>
      <div className="conversation-history-inquiry-information-wrapper">
        <div className="conversation-history-inquiry-information">
          <div className="conversation-history-inquiry-property-image">
            <h4 style={{ fontSize: 24 }}>
              {propertyInformation && propertyInformation.name}
            </h4>
            <img
              style={{ width: '443px', objectFit: 'fill' }}
              src={`${baseURL.replace('/api', '')}${
                propertyInformation && propertyInformation.images.length > 0
                  ? propertyInformation.images[0].image
                  : ''
              }`}
              alt=""
              srcSet=""
            />
          </div>
          <div className="conversation-history-inquiry-host-information">
            <img
              src={`${baseURL.replace('/api', '')}${
                hostInformation && hostInformation.image
              }`}
              alt=""
              srcSet=""
              style={{ objectFit: 'cover' }}
            />
            <h4 style={{ fontSize: 18 }}>
              {hostInformation && hostInformation.name}
            </h4>
          </div>
          <div className="conversation-history-inquiry-property-address">
            <h4 style={{ fontSize: 18 }}>Address</h4>
            <div style={{ fontSize: 16 }}>
              {' '}
              {propertyInformation && propertyInformation.address}{' '}
            </div>
          </div>

          <div className="conversation-history-inquiry-reservation-date-time">
            <h4 style={{ fontSize: 18 }}>Inquired Date & time</h4>
            <div className="conversation-history-inquiry-reservation-date">
              <img src={calender} alt="" srcSet="" />
              <div style={{ fontSize: 16 }}>
                {' '}
                {inquiryInformation &&
                  `${dateConvart(inquiryInformation.start_date).dayName} ${
                    dateConvart(inquiryInformation.start_date).convertedDate
                  }`}{' '}
                {inquiryInformation &&
                calculatePriceByInquiry(inquiryInformation).total_day > 0
                  ? `to ${dateConvart(inquiryInformation.end_date).dayName} ${
                      dateConvart(inquiryInformation.end_date).convertedDate
                    }`
                  : ``}
              </div>
            </div>
            <div className="conversation-history-inquiry-reservation-time">
              <img src={clock} alt="" srcSet="" />
              <div style={{ fontSize: 16 }}>
                {' '}
                {inquiryInformation &&
                  timeConvart(inquiryInformation.start_time)}{' '}
                to{' '}
                {inquiryInformation && timeConvart(inquiryInformation.end_time)}
              </div>
            </div>
          </div>
          <div className="conversation-history-inquiry-reservation-activity">
            <h4 style={{ fontSize: 18 }}>Activity</h4>
            <p style={{ fontSize: 16 }}>{activities && activities.title}</p>
          </div>
          <div className="conversation-history-inquiry-expected-attendies">
            <h4 style={{ fontSize: 18 }}>Attendees</h4>
            <div style={{ fontSize: 16 }}>
              <img src={groupOfGuest} alt="" srcSet="" />
              <div>
                {inquiryInformation && inquiryInformation.expected_guest_amount}
              </div>
            </div>
          </div>
          <div className="conversation-history-inquiry-alcohol-consumption">
            <h4 style={{ fontSize: 18 }}>Alcohol</h4>
            <p style={{ fontSize: 16 }}>
              {inquiryInformation && inquiryInformation.alcohol_consumption
                ? 'Yes'
                : 'No'}
            </p>
          </div>
          <div className="conversation-history-inquiry-price-details">
            <h4 style={{ fontSize: 18 }}>Price Details</h4>
            <div className="conversation-history-inquiry-price-calculation">
              <div style={{ fontSize: 14 }}>
                $
                {inquiryInformation &&
                  (inquiryInformation.is_fixed
                    ? (
                        inquiryInformation.amount / inquiryInformation.total_day
                      ).toFixed(2)
                    : (
                        inquiryInformation.amount /
                        inquiryInformation.total_day /
                        inquiryInformation.total_hour_per_day
                      ).toFixed(2))}{' '}
                {inquiryInformation && !inquiryInformation.is_fixed
                  ? `x ${inquiryInformation.total_hour_per_day} hour`
                  : ''}{' '}
                x {inquiryInformation && inquiryInformation.total_day} day:
              </div>
              <div style={{ fontSize: 14 }}>
                ${inquiryInformation && inquiryInformation.amount.toFixed(2)}
              </div>
              <div style={{ fontSize: 14 }}>Cleaning fee</div>
              <div style={{ fontSize: 14 }}>
                $
                {inquiryInformation &&
                  inquiryInformation.cleaning_fee.toFixed(2)}
              </div>
              <div style={{ fontSize: 14 }}>Service fee</div>
              <div style={{ fontSize: 14 }}>
                $
                {inquiryInformation &&
                  (
                    inquiryInformation.service_fee +
                    inquiryInformation.transaction_fee
                  ).toFixed(2)}
              </div>
              <div style={{ fontSize: 14 }}>Coupon</div>
              <div style={{ fontSize: 14 }}>
                {inquiryInformation && inquiryInformation.coupon_discount == 0
                  ? ''
                  : '-'}
                $
                {inquiryInformation &&
                  inquiryInformation.coupon_discount.toFixed(2)}
              </div>
              <div style={{ fontSize: 14 }}>8+ hours discount</div>
              <div style={{ fontSize: 14 }}>
                {inquiryInformation && inquiryInformation.discount == 0
                  ? ''
                  : '-'}
                ${inquiryInformation && inquiryInformation.discount.toFixed(2)}
              </div>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>Subtotal </div>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                $
                {inquiryInformation &&
                  inquiryInformation.total_amount.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="conversation-history-inquiry-information-worning">
          <img src={shield_icon} alt="" srcSet="" />
          <h4 style={{ fontSize: 18 }}>Protect your payment</h4>
          <p style={{ fontSize: 16 }}>
            Only bookings completed through Venuely website will have access to
            secure payment, fraud, safeguards, and our cancellation protection.
            <span style={{ fontSize: 16, color: '#6568E6', cursor: 'pointer' }}>
              {' '}
              Learn More
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
