import StatusBox from '../../components/StatusBox'
import { NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin, message, Button } from 'antd'
import { setBanner } from '../../redux/actions/ComponentAction'
import greenTick from './../../img/green_tik.png'
import dummyUser from './../../img/dummy-user.png'
import redWorning from './../../img/red_worning.png'
import { baseURL2 } from '../../redux/requests'
import { TopMsg } from '../../components/Topmsg'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'
import { resendVerifyEmailAccountLink } from '../../redux/actions/AuthAction'
import HorizontalLoading from '../../components/HorizontalLoading'
import EditPhoneModal from './EditPhoneModal'
import DeleteProfile from '../../components/modal/host/DeleteProfile'

export default function EditHostProfile() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [imgUpLoading, setImgUpLoading] = useState(false)
  const [hostMission, setHostMission] = useState('')
  const [hostName, setHostName] = useState('')
  const [hostEmail, setHostEmail] = useState('')
  const [hostPresentEmail, setHostPresentEmail] = useState('')
  const [hostPresentDOB, setHostPresentDOB] = useState('')
  const [hostPresentPhone, setHostPresentPhone] = useState('')
  const [hostPhone, setHostPhone] = useState('')
  const [hostPresentPassword, setHostPresentPassword] = useState('')
  const [hostPassword, setHostPassword] = useState('')
  const [hostConfirmPassword, setHostConfirmPassword] = useState('')
  const [hostPresentImage, setHostPresentImage] = useState(null)
  const [hostImage, setHostImage] = useState('')
  const [hostDateOfBirth, setHostDateOfBirth] = useState('')
  const [hostFirstName, setHostFirstName] = useState('')
  const [hostLastName, setHostLastName] = useState('')
  const [hostInformation, setHostInformation] = useState(null)
  const [has_password, setHas_password] = useState(true)
  const [showHostProfilePageLoader, setShowHostProfilePageLoader] =
    useState(false)
  const [closeAccountModal, setCloseAccountModal] = useState(false)

  useEffect(() => {
    if (loading) {
      setShowHostProfilePageLoader(true)
      dispatch(
        setInformation({
          callBack: (response) => {
            setShowHostProfilePageLoader(false)
            setData(response.data)
          },
        }),
      )
    }
    return () => {
      setLoading(false)
    }
  }, [loading])
  let setData = (data) => {
    console.log(data)
    setHas_password(data.has_password)
    setHostInformation(data)
    setHostMission(data.mission_and_vision)
    setHostFirstName(data.first_name)
    setHostLastName(data.last_name)
    setHostPresentEmail(data.email)
    setHostName(data.name)
    setHostEmail(data.email)
    if (data.phone?.length == 11) {
      // Removing 1 from input box
      setHostPhone(data.phone.substring(1))
    }
    setHostPresentPhone(data.phone)
    setHostImage(data.image)
    setHostPresentImage(data.image)
    // console.log(hostPresentImage);
    setHostDateOfBirth(data.birth_date)
    setHostPresentDOB(data.birth_date)
  }
  let sideBarCss = {
    height: 'calc(100vh - 131px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }

  const [showProfileNameForm, setShowProfileNameForm] = useState(false)
  const [showbirthDateForm, setShowbirthDateForm] = useState(false)
  const [showPasswordForm, setShowPasswordForm] = useState(false)
  const [showMissionAndVissionForm, setShowMissionAndVissionForm] =
    useState(false)
  const [showImageUploadPopUpBox, setShowImageUploadPopUpBox] = useState(false)
  const [showEmailUploadPopUpBox, setShowEmailUploadPopUpBox] = useState(false)
  const [showPhoneUploadPopUpBox, setShowPhoneUploadPopUpBox] = useState(false)
  const [showSendEmailSendLoadingBar, setShowSendEmailSendLoadingBar] =
    useState(false)

  let loadingBar = (
    <div class="hr-loading-wrapper">
      <div style={{ width: '70px' }} class="loading-bar"></div>
    </div>
  )

  let loader = (
    <div className="loader" style={{ top: '60%', left: '50%' }}></div>
  )

  let reSendEmail = () => {
    setShowSendEmailSendLoadingBar(true)
    dispatch(
      resendVerifyEmailAccountLink({
        callBack: (response) => {
          setShowSendEmailSendLoadingBar(false)
        },
      }),
    )
  }

  let emailData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Email address</h3>
        <p>{hostPresentEmail}</p>
        <p>
          {hostInformation && hostInformation.verified ? (
            ''
          ) : (
            <>
              <button style={{ fontWeight: 'bold' }} onClick={reSendEmail}>
                {showSendEmailSendLoadingBar
                  ? loadingBar
                  : 'resend verification link'}
              </button>
            </>
          )}
        </p>
      </div>
      <div
        onClick={() => {
          handleShowPopUp('email-uploade')
        }}
        className="edit-button"
      >
        Edit
      </div>
    </div>
  )

  let passwordData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Password</h3>
        <p>**********</p>
      </div>
      <div
        onClick={() => {
          setShowPasswordForm(true)
        }}
        className="edit-button"
      >
        Edit
      </div>
    </div>
  )

  let passwordFormData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Password</h3>
        <p>Type your new password</p>
        <form style={{ gridTemplateColumns: '1fr', gap: '10px' }}>
          {has_password ? (
            <div>
              <input
                style={{ padding: '0px 23px', width: '155%' }}
                id="current_password"
                name="current_password"
                placeholder="Current password"
                type="password"
                onChange={(e) => {
                  setHostPresentPassword(e.target.value)
                }}
                value={hostPresentPassword}
                onFocus={(e) => {
                  e.target.type = 'text'
                }}
                onBlurCapture={(e) => {
                  e.target.type = 'password'
                }}
              />
              <p
                id="prev-password-message"
                className="hide-elements"
                style={{ color: 'red' }}
              >
                password not matched
              </p>
              {/* <p> Forgot password? </p> */}
            </div>
          ) : (
            ''
          )}
          <div>
            <input
              style={{ padding: '0px 23px', width: '155%' }}
              id="new_password"
              name="new_password"
              placeholder="New password"
              type="password"
              onChange={(e) => {
                setHostPassword(e.target.value)
              }}
              value={hostPassword}
              onFocus={(e) => {
                e.target.type = 'text'
              }}
              onBlurCapture={(e) => {
                e.target.type = 'password'
              }}
            />
            <p
              id="new-password-message"
              className="hide-elements"
              style={{ color: 'red' }}
            >
              password not matched
            </p>
          </div>
          <div>
            <input
              style={{ padding: '0px 23px', width: '155%' }}
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm password"
              onChange={(e) => {
                setHostConfirmPassword(e.target.value)
              }}
              type="password"
              value={hostConfirmPassword}
              onFocus={(e) => {
                e.target.type = 'text'
              }}
              onBlurCapture={(e) => {
                e.target.type = 'password'
              }}
            />
            <p
              id="confirm-password-message"
              className="hide-elements"
              style={{ color: 'red' }}
            >
              password not matched
            </p>
          </div>
          <button
            onClick={(e) => {
              let confirm_password_input =
                document.getElementById('confirm_password')
              let confirm_password_message = document.getElementById(
                'confirm-password-message',
              )
              let new_password_input = document.getElementById('new_password')
              let new_password_message = document.getElementById(
                'new-password-message',
              )

              let current_password_message = document.getElementById(
                'prev-password-message',
              )

              if (hostPassword.length > 0 && hostConfirmPassword.length > 0) {
                if (hostPassword == hostConfirmPassword) {
                  confirm_password_input.style.borderColor = 'black'
                  new_password_input.style.borderColor = 'black'
                  confirm_password_message.classList.add('hide-elements')
                  new_password_message.classList.add('hide-elements')
                  setShowHostProfilePageLoader(true)
                  dispatch(
                    updateInformation({
                      personal_information: {
                        password: hostPassword,
                        prev_password: hostPresentPassword,
                      },
                      callBack: (response) => {
                        setShowHostProfilePageLoader(false)
                        if (response.status == 500) {
                          message.error(response?.data[0])
                        }
                        if (response.status != 500) {
                          setData(response.data.data)
                          setLoading(true)
                          setShowPasswordForm(false)
                          // console.log(response.data.data)
                          current_password_message.classList.add(
                            'hide-elements',
                          )
                        }
                      },
                    }),
                  )
                } else {
                  confirm_password_input.style.borderColor = 'red'
                  new_password_input.style.borderColor = 'red'
                  confirm_password_message.classList.remove('hide-elements')
                  new_password_message.classList.remove('hide-elements')
                }
              }
            }}
            type="button"
          >
            Save
          </button>
        </form>
      </div>
      <div
        onClick={() => {
          setShowPasswordForm(false)
        }}
        className="edit-button"
      >
        cancel
      </div>
    </div>
  )

  let MissionStatementData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Mission Statement</h3>
        <p>Don’t include your organization’s name here</p>
      </div>
      <div
        onClick={() => {
          setShowMissionAndVissionForm(true)
        }}
        className="edit-button"
      >
        Edit
      </div>
    </div>
  )

  let MissionStatementFormData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Mission Statement</h3>
        <p>Don’t include your organization’s name here</p>
        <form
          style={{ gridTemplateColumns: '1fr' }}
          id="mission-statement-form-data-form"
        >
          <div>
            <textarea
              style={{ padding: '15px' }}
              value={hostMission}
              onChange={(e) => {
                setHostMission(e.target.value)
              }}
              name="mission_statement"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <button
            onClick={() => {
              if (hostMission.length > 0) {
                setShowHostProfilePageLoader(true)
                dispatch(
                  updateInformation({
                    personal_information: { mission_and_vision: hostMission },
                    callBack: (response) => {
                      setShowHostProfilePageLoader(false)
                      setData(response.data.data)
                      setShowMissionAndVissionForm(false)
                    },
                  }),
                )
              }
            }}
            type="button"
          >
            Save
          </button>
        </form>
      </div>
      <div
        onClick={() => {
          setShowMissionAndVissionForm(false)
        }}
        className="edit-button"
      >
        cancel
      </div>
    </div>
  )

  let closeAccountRow = (
    <div style={{ paddingTop: 50, paddingBottom: 50 }}>
      <Button
        onClick={() => {
          setCloseAccountModal(true)
        }}
        type="primary"
        ghost
        style={{ width: '60%', height: 50 }}
      >
        Close Account
      </Button>
    </div>
  )

  let PhoneNumberData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Phone number</h3>
        {hostPhone && hostPhone !== '0' ? (
          <p>+1{hostPhone}</p>
        ) : (
          <p>
            <p>
              Plase add the phone number must be verified to publish your
              listings
            </p>
          </p>
        )}
      </div>
      <div
        onClick={() => {
          handleShowPopUp('phone-uploade')
        }}
        className="edit-button"
      >
        Add
      </div>
    </div>
  )

  let profileNameData = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>Legal name</h3>
        <p>{hostName}</p>
      </div>
      <div
        onClick={() => {
          setShowProfileNameForm(true)
        }}
        className="edit-button"
      >
        Edit
      </div>
    </div>
  )

  let profileNameForm = (
    <div className="host-profile-data-group">
      <div className="host-profile-data">
        <h3>{hostName}</h3>
        <p>
          This is the name on your travel document, which could be a license or
          a passport.
        </p>
        <form>
          <div style={{ position: 'relative' }}>
            <label htmlFor="first_name">First name</label>
            <input
              id="first_name"
              onChange={(e) => {
                setHostFirstName(e.target.value)
              }}
              type="text"
              value={hostFirstName}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <label htmlFor="last_name">Last name</label>
            <input
              id="last_name"
              onChange={(e) => {
                setHostLastName(e.target.value)
              }}
              type="text"
              value={hostLastName}
            />
          </div>
          <button
            onClick={() => {
              let name = `${hostFirstName.replace(
                /^\s+|\s+$/gm,
                '',
              )} ${hostLastName.replace(/^\s+|\s+$/gm, '')}`
              if (hostFirstName.length > 0 && hostLastName.length > 0) {
                setShowHostProfilePageLoader(true)
                dispatch(
                  updateInformation({
                    personal_information: {
                      name: name,
                      first_name: hostFirstName,
                      last_name: hostLastName,
                    },
                    callBack: (response) => {
                      setShowHostProfilePageLoader(false)
                      setData(response.data.data)
                      setLoading(true)
                      setShowProfileNameForm(false)
                    },
                  }),
                )
              }
            }}
            type="button"
          >
            Save
          </button>
        </form>
      </div>
      <div
        onClick={() => {
          setShowProfileNameForm(false)
        }}
        className="edit-button"
      >
        cancel
      </div>
    </div>
  )
  let convirtData = (date) => {
    const dateObject = new Date(date) // 2009-11-10
    const month = dateObject.toLocaleString('default', { month: 'long' })
    return `${month} ${dateObject.getDate()} ${dateObject.getFullYear()}`
  }
  let profileBirthDate = (
    <div className="host-profile-data-group">
      <div className="host-profile-birth-date">
        <h3>Date of birth</h3>
        {/* <p>November 28, 1990</p> */}
        {hostPresentDOB && <p>{convirtData(hostPresentDOB)}</p>}
      </div>
      <div
        onClick={() => {
          setShowbirthDateForm(true)
        }}
        className="edit-button"
      >
        Edit
      </div>
    </div>
  )

  const storeImages = () => {
    setImgUpLoading(true)
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    // console.log(hostImage);
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      setShowHostProfilePageLoader(true)
      dispatch(
        updateInformation({
          personal_information: { image: dataURL },
          callBack: (response) => {
            setShowHostProfilePageLoader(false)
            setData(response.data.data)
            handleClosePopUp('image-uploade')
            document.getElementById('host-main-profile-picture').src = dataURL
            setLoading(true)
            message.success(TopMsg('Image uploaded successfully.'))
            setImgUpLoading(false)
          },
        }),
      )
    }
    img.src = hostImage
  }
  const [okayToUpload, setokayToUpload] = useState(false)
  let handleImageUpload = (e) => {
    e.preventDefault()
    setokayToUpload(false)
    if (e.target.files) {
      let preview = document.getElementById('host-prifile-image-preview')
      Array.from(e.target.files).map((file) => {
        let size = file.size / 1024
        if (size < 1025) {
          let url = URL.createObjectURL(file)
          preview.src = url
          document.getElementById('image-upload-message').style.color = 'black'
          setHostImage(url)
          setokayToUpload(true)
          // console.log(hostImage);
        } else {
          setokayToUpload(false)
          document.getElementById('image-upload-message').style.color = 'red'
        }
      })
      document.getElementById('host-profile-image').value = ''
    }
  }

  let profileBirthDateForm = (
    <div className="host-profile-data-group">
      <div className="host-profile-birth-date">
        <h3>Date of birth</h3>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        <form
          style={{ gridTemplateColumns: '1fr' }}
          id="host-profile-birth-date-form"
        >
          <div style={{ position: 'relative' }}>
            <label htmlFor="host_profile_birth_date">{`Birthdate(MM/DD/YYYY)`}</label>
            <input
              onChange={(e) => {
                console.log(e.target.value)
                setHostDateOfBirth(e.target.value)
              }}
              style={{ width: '255%' }}
              id="host_profile_birth_date"
              type="date"
              value={hostDateOfBirth}
            />
          </div>
          <button
            type="button"
            onClick={(e) => {
              setShowHostProfilePageLoader(true)
              if (hostDateOfBirth.length > 0) {
                dispatch(
                  updateInformation({
                    personal_information: { birth_date: hostDateOfBirth },
                    callBack: (response) => {
                      setShowHostProfilePageLoader(false)
                      setData(response.data.data)
                      setShowbirthDateForm(false)
                    },
                  }),
                )
              }
            }}
          >
            Save
          </button>
        </form>
      </div>
      <div
        onClick={() => {
          setShowbirthDateForm(false)
        }}
        className="edit-button"
      >
        cancel
      </div>
    </div>
  )

  let showUploadImagePopUp = (
    <div className="pop-up-wrapper">
      <div className="pop-up-card">
        <div className="profile-pop-up-card-header">
          <div
            onClick={() => {
              handleClosePopUp('image-uploade')
            }}
            className="profile-pop-up-close"
          >
            x
          </div>
          <h4 style={{ paddingRight: '70px' }}>Upload profile photo</h4>
        </div>
        <div
          style={{ position: 'relative' }}
          className="profile-pop-up-card-body"
        >
          <h4>Add your profile photo</h4>
          <p>
            Pick an image that shows your face. This photo will be displayed in
            public with your list of spaces. Please use a proper image.
          </p>
          <input
            style={{
              position: 'absolute',
              height: '147px',
              border: '1px solid',
              width: '147px',
              marginTop: '31px',
              borderRadius: '100%',
              opacity: '0',
              cursor: 'pointer',
            }}
            onChange={handleImageUpload}
            type="file"
            name="image"
            id="host-profile-image"
          />
          <img
            style={{
              height: '134px',
              width: '134px',
              marginTop: '56px',
              marginBottom: '24px',
              borderRadius: '100%',
            }}
            id={'host-prifile-image-preview'}
            src={
              hostPresentImage != null
                ? `${baseURL2}${hostPresentImage}`
                : dummyUser
            }
          />
          <p id="image-upload-message">Maximum upload size is 1mb</p>
          <div
            onClick={storeImages}
            className={`profile-pop-up-footer-button profile-pop-up-footer-button${
              okayToUpload ? '-enable' : '-disable'
            }-out-line`}
          >
            {imgUpLoading ? <Spin /> : `Upload a photo`}
          </div>
        </div>
      </div>
    </div>
  )

  const [showEmailChangeLoader, setShowEmailChangeLoader] = useState(false)
  const [emailChangePopUpSteper, setEmailChangePopUpSteper] = useState('form')
  var pattern = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const [updatingEmailShowLoading, setUpdatingEmailShowLoading] =
    useState(false)
  let emailChangeForm = (
    <div className="profile-pop-up-card-body">
      <h4>Change your email address</h4>
      <p>Enter your new email address.</p>
      <div className="host-profile-email-change-input-group">
        <h4>Current email address</h4>
        <p> {hostPresentEmail} </p>
        <input
          id="change_email_address"
          type="email"
          value={hostEmail}
          onChange={(e) => {
            setHostEmail(e.target.value)
            // console.log(hostEmail.length)
            // console.log(e.target.value + ',')
            // console.log(pattern.test(hostEmail))
          }}
          placeholder="New email address"
        />
        <div
          id="change_email_address_error_message"
          style={{ color: 'red' }}
        ></div>
      </div>
      <div
        onClick={() => {
          setUpdatingEmailShowLoading(true)
          if (hostEmail.length > 0) {
            if (pattern.test(hostEmail)) {
              dispatch(
                updateInformation({
                  personal_information: { email: hostEmail },
                  callBack: (response) => {
                    setUpdatingEmailShowLoading(false)
                    if (response.status != 200) {
                      document.getElementById(
                        'change_email_address_error_message',
                      ).innerHTML = response.data[0]
                      document.getElementById(
                        'change_email_address',
                      ).style.borderColor = 'red'
                    }
                    if (response.status == 200) {
                      document.getElementById(
                        'change_email_address_error_message',
                      ).innerHTML = ''
                      document.getElementById(
                        'change_email_address',
                      ).style.borderColor = 'black'
                      setData(response.data.data)
                      setEmailChangePopUpSteper('message')
                    }
                  },
                }),
              )
            }
          }
        }}
        style={{ width: '616px' }}
        className={`profile-pop-up-footer-button profile-pop-up-footer-button${
          hostEmail.length > 0 && pattern.test(hostEmail)
            ? '-enable'
            : '-disable'
        }-fill`}
        disabled={updatingEmailShowLoading}
      >
        {updatingEmailShowLoading ? (
          <div
            className="loader"
            style={{ top: '67%', left: '49.5%', width: '30px', height: '30px' }}
          ></div>
        ) : (
          'Continue'
        )}
      </div>
    </div>
  )

  let mailChangeMessage = (
    <div className="profile-pop-up-card-body">
      <h4>Verify your email address</h4>
      <p>Please check your email inbox, and click the following link.</p>

      <h4
        style={{
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '22px',
          textAlign: 'center',
        }}
      >
        Didn’t get a link?
      </h4>
      <p style={{ width: '272px' }}>
        If you don’t receive your link in 1 minutes,click{' '}
        <button onClick={reSendEmail}>
          {' '}
          {showSendEmailSendLoadingBar ? (
            loadingBar
          ) : (
            <div style={{ color: 'black', fontWeight: 'bolder' }}>
              {' '}
              resend a link{' '}
            </div>
          )}{' '}
        </button>{' '}
        .
      </p>
    </div>
  )
  let emailChangePopUpStepperElements = {
    form: emailChangeForm,
    message: mailChangeMessage,
  }
  let showChangeEmailPopUp = (
    <div className="pop-up-wrapper">
      <div className="pop-up-card">
        <div className="profile-pop-up-card-header">
          <div
            onClick={() => {
              handleClosePopUp('email-uploade')
            }}
            className="profile-pop-up-close"
          >
            x
          </div>
          <h4 style={{ paddingRight: '70px' }}>Change Email Address</h4>
        </div>
        {emailChangePopUpStepperElements[emailChangePopUpSteper]}
      </div>
    </div>
  )

  let handleShowPopUp = (type) => {
    window.scrollTo(0, 0)
    setokayToUpload(false)
    document.body.style.overflow = 'hidden'
    if (type == 'image-uploade') setShowImageUploadPopUpBox(true)
    if (type == 'email-uploade') setShowEmailUploadPopUpBox(true)
    if (type == 'phone-uploade') setShowPhoneUploadPopUpBox(true)
  }

  let handleClosePopUp = (type) => {
    setokayToUpload(false)
    document.body.style.overflow = 'visible'
    setEmailChangePopUpSteper('form')
    if (type == 'image-uploade') setShowImageUploadPopUpBox(false)
    if (type == 'email-uploade') setShowEmailUploadPopUpBox(false)
    if (type == 'phone-uploade') setShowPhoneUploadPopUpBox(false)
  }

  return (
    <main>
      <DeleteProfile
        visible={closeAccountModal}
        setVisible={setCloseAccountModal}
      />
      <aside style={sideBarCss}>
        <div className="space-side-nevbar" style={{}}>
          <ul>
            <li style={{ borderBottom: 0 }}>
              {' '}
              <NavLink
                to={'/profile'}
                className={({ isActive }) => (isActive ? 'active' : '')}
                style={{ color: '#000' }}
              >
                {' '}
                Profile Details{' '}
              </NavLink>{' '}
            </li>
          </ul>
        </div>
        <div className="profile-picture-upload-side-bar">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleShowPopUp('image-uploade')
            }}
            className="user-picture"
          >
            <img
              id="host-main-profile-picture"
              style={{ borderRadius: '100%' }}
              src={
                hostPresentImage != null
                  ? `${baseURL2}${hostPresentImage}`
                  : dummyUser
              }
            />
            <p>Upload a photo</p>
          </div>

          <div className="user-email-side-bar">
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleShowPopUp('email-uploade')
              }}
            >
              {' '}
              Email address
            </p>
            {hostInformation && hostInformation.verified ? (
              <p style={{ color: 'green' }}>
                {' '}
                <img
                  style={{ height: '13px', marginBottom: '5px' }}
                  src={greenTick}
                />{' '}
                Verified
              </p>
            ) : (
              <p style={{ color: 'red' }}>
                {' '}
                <img
                  style={{ height: '13px', marginBottom: '5px' }}
                  src={redWorning}
                />{' '}
                Not Verified
              </p>
            )}
          </div>

          <div className="phone-number-side-bar">
            <p>Phone number</p>
            {hostInformation && hostInformation?.phone?.length == 0 ? (
              <>
                <p style={{ color: 'red' }}>
                  {' '}
                  <img
                    style={{ height: '13px', marginBottom: '5px' }}
                    src={redWorning}
                  />{' '}
                  Not provided
                </p>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleShowPopUp('phone-uploade')
                  }}
                >
                  Add Now
                </p>
              </>
            ) : (
              <>
                {hostInformation?.phone_verified ? (
                  <p style={{ color: 'green' }}>
                    {' '}
                    <img
                      style={{ height: '13px', marginBottom: '5px' }}
                      src={greenTick}
                    />{' '}
                    Verified
                  </p>
                ) : (
                  <p style={{ color: 'red' }}>
                    {' '}
                    <img
                      style={{ height: '13px', marginBottom: '5px' }}
                      src={redWorning}
                    />{' '}
                    Not Verified
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </aside>
      <section
        style={{ height: 'calc(100vh - 131px)', overflowY: 'scroll' }}
        className="main-content"
      >
        <div className="banner">
          <div className="banner-content" style={{ padding: 50 }}>
            <h1>Profile Details</h1>
            <p>Edit and save your profile information</p>
          </div>
        </div>

        {showImageUploadPopUpBox ? showUploadImagePopUp : ''}
        {showEmailUploadPopUpBox ? showChangeEmailPopUp : ''}
        {showPhoneUploadPopUpBox ? (
          <EditPhoneModal
            props={{
              handleClosePopUp,
              setHostPhone,
              hostPhone,
              updateInformation,
              setData,
              hostEmail,
              setShowPhoneUploadPopUpBox,
              setInformation,
            }}
          />
        ) : (
          ''
        )}

        {!showHostProfilePageLoader ? (
          <div className="host-profile-data-group-list">
            {showProfileNameForm ? profileNameForm : profileNameData}
            {showbirthDateForm ? profileBirthDateForm : profileBirthDate}
            {emailData}
            {PhoneNumberData}
            {showPasswordForm ? passwordFormData : passwordData}
            {showMissionAndVissionForm
              ? MissionStatementFormData
              : MissionStatementData}
            {closeAccountRow}
          </div>
        ) : (
          <div>
            <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
          </div>
        )}
      </section>
    </main>
  )
}
