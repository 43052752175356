import React, { useEffect, useRef, useState } from 'react'
import { Space, DatePicker, message } from 'antd'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { Typography } from 'antd'

import '../../style/antd.css'
import './index.css'
import { months } from '../utils/months'
import get12HourFormat from '../utils/get12HourFormat'
import { timeListArr } from '../utils/timeListArr'

const CalendarMenuMain = ({
  dateVal,
  setDateVal,
  dateVal2,
  setDateVal2,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  startDatemenu,
  setstartDatemenu,
  setendDatemenu,
  endDatemenu,
  calendarPopup,
  setCalendarPopup,
  setcalendarVisible,
  setTimeVal,
  inAvailability,
  blockedDate,
  windowDate,
  previousBookingDate,
  setFlexibleTime,
  flexibleTime,
}) => {
  const { RangePicker } = DatePicker
  const location = useLocation()
  const pathName = location.pathname

  const style = {
    width: 280,
  }

  const [invalidFormat, setInvalidFormat] = useState(false)
  const [clear, setClear] = useState(false)
  const [clearHover, setClearHover] = useState(false)
  const date = new Date()

  useEffect(() => {
    if (startTime === '' || endTime === '') {
      setInvalidFormat(false)
    } else {
      let beginningTime = moment(startTime, 'h:mma')
      let end = moment(endTime, 'h:mma')
      if (beginningTime.isBefore(end)) {
        setInvalidFormat(false)
      } else {
        setInvalidFormat(true)
      }
    }
  }, [startTime, endTime])

  const ref = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setcalendarVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const inavailableDays = inAvailability?.map((a) => a.day.day_number)
  const prevBookingDate = previousBookingDate?.map((a) => a.date)
  const valueDate = moment().format('YYYY-MM-DD')

  const handleDisableDates = (current) => {
    const urlPaths =
      pathName.includes('/') ||
      pathName.includes('/renter/search') ||
      pathName.includes('/renter/place') ||
      pathName.includes('/renter/booking')
    if (urlPaths) {
      return moment().add(-1, 'days') > current
    } else {
      if (blockedDate !== undefined) {
        for (let block of blockedDate) {
          return (
            current?.isBetween(block.start_date, block.end_date, null, '[]') ||
            (windowDate && current?.isSameOrAfter(moment(windowDate))) ||
            inavailableDays?.includes(current.day()) ||
            prevBookingDate?.includes(current.format('YYYY-MM-DD')) ||
            moment().add(-1, 'days') >= current
          )
        }
      }
    }
  }

  const handleCalendarChange = (ev, dateString, info) => {
    const date1 = dateString[0]
    const date2 = dateString[1]
    if (info.range === 'start') {
      setDateVal(date1)
      setDateVal2(date1)
    } else if (info.range === 'end') {
      setDateVal(date1)
      setDateVal2(date2)
      if (location.pathname == '/renter/booking') {
        setcalendarVisible(false)
      }
    }
  }
  const TimesComponent = (mainItem, setItem, visibileItem) => {
    return (
      <ul
        className="date-menu-calendar-menu date-menu-common"
        style={{
          opacity: visibileItem ? 1 : 0,
          visibility: visibileItem ? 'visible' : 'hidden',
        }}
      >
        {timeListArr.map((item, idx) => {
          return (
            <label
              key={idx}
              className={item === mainItem ? 'time-text2-focus' : 'time-text2'}
              onClick={() => setItem(item)}
            >
              {get12HourFormat(item)}
            </label>
          )
        })}
      </ul>
    )
  }

  const timeBoxStyle = (string) => {
    return {
      borderRadius:
        string === 'endTime' ? '0px 7px 7px 0px' : '7px 0px 0px 7px',
      height: 60,
      padding: 5,
      width: 140,
      border: invalidFormat ? '1px #DF3416 solid' : '1px #ccc solid',
    }
  }

  const SelectedDateSection = () => {
    return (
      <div style={{ marginBottom: 30 }}>
        {dateVal === dateVal2 ? (
          <h5
            style={{
              textAlign: 'center',
              marginTop: 20,
              height: 20,
              fontSize: 15,
            }}
          >
            {months[+dateVal2.split('-')[1] - 1]} {dateVal2?.slice(8, 10)}
          </h5>
        ) : (
          <h5
            style={{
              textAlign: 'center',
              marginTop: 20,
              height: 20,
              fontSize: 15,
            }}
          >
            {months[+dateVal.split('-')[1] - 1]} {dateVal?.slice(8, 10)} {'-'}
            {months[+dateVal2.split('-')[1] - 1]} {dateVal2?.slice(8, 10)}
          </h5>
        )}
      </div>
    )
  }

  return (
    <>
      <div id="calendar-parent" style={parentDivStyle}>
        <div style={{ marginTop: -250 }}>
          <Space direction="vertical" size={12}>
            <RangePicker
              open={true}
              popupStyle={style}
              style={style}
              format={'YYYY-MM-DD'}
              clearIcon={false}
              defaultValue={moment(valueDate)}
              value={clear ? null : [moment(dateVal), moment(dateVal2)]}
              disabledDate={handleDisableDates}
              onCalendarChange={handleCalendarChange}
            />
          </Space>
        </div>
        {location.pathname === '/' ||
          (location.pathname === '/renter/search' && (
            <div>
              <SelectedDateSection />
              <div className="start-end-date">
                <div
                  className="start-end"
                  onClick={() => {
                    setstartDatemenu(!startDatemenu)
                    setendDatemenu(false)
                  }}
                  style={timeBoxStyle('startTime')}
                >
                  <div className="date-btn">
                    <h5
                      style={{
                        fontWeight: 'bold',
                        color: invalidFormat ? '#DF3416' : '#000',
                      }}
                    >
                      Start time
                    </h5>
                    <label className="label-start-end-date2">
                      {startTime === '' ? '' : get12HourFormat(startTime)}
                    </label>
                    {TimesComponent(startTime, setStartTime, startDatemenu)}
                  </div>
                  <img src="/dropdown.svg" alt="" />
                </div>
                <div
                  className="start-end"
                  onClick={() => {
                    setendDatemenu(!endDatemenu)
                    setstartDatemenu(false)
                  }}
                  style={timeBoxStyle('endTime')}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5
                      style={{
                        fontWeight: 'bold',
                        color: invalidFormat ? '#DF3416' : '#000',
                      }}
                    >
                      End time
                    </h5>
                    <label className="label-start-end-date2">
                      {endTime === '' ? '' : get12HourFormat(endTime)}
                    </label>
                    {TimesComponent(endTime, setEndTime, endDatemenu)}
                  </div>
                  <img src="/dropdown.svg" alt="" />
                </div>
              </div>
              <h6
                style={{
                  color: '#DF3416',
                  fontWeight: '600',
                  display: invalidFormat ? 'block' : 'none',
                  marginLeft: 22,
                  marginTop: -15,
                  position: 'absolute',
                }}
              >
                Invalid time slot
              </h6>
              {/* <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingTop: 14,
                marginLeft: 30,
              }}
            >
              <Checkbox
                id="checkbox"
                onClick={() => setFlexibleTime(!flexibleTime)}
                checked={flexibleTime}
                onChange={(e) => setFlexibleTime(e.target.checked)}
                style={{ fontSize: 14 }}
              >
                <label htmlFor="checkbox" style={{ fontSize: 14 }}>
                  I’m Flexible w/ Start & End time
                </label>
              </Checkbox>
            </div> */}
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: '#6568E6',
                  margin: 20,
                  borderRadius: 5,
                  padding: 8,
                  marginTop: 50,
                }}
                onClick={() => {
                  if (invalidFormat) {
                    console.log(invalidFormat)
                    return
                  }
                  if (startTime === '' || endTime === '' || dateVal === '') {
                    message.error('Please fill the Date and Time')
                  } else {
                    setCalendarPopup(!calendarPopup)
                    setTimeVal(true)
                  }
                }}
              >
                <label
                  className="apply-btn"
                  style={{ color: '#fff', fontSize: 14, cursor: 'pointer' }}
                >
                  Apply
                </label>
              </div>
              <div
                className="clear-btn"
                onClick={() => {
                  const valueDate = `${date.getFullYear()}-${
                    date.getMonth() + 1
                  }-${date.getDate()}`
                  setDateVal('')
                  setDateVal2('')
                  setTimeVal(false)
                  setStartTime('')
                  setEndTime('')
                  setFlexibleTime(false)
                  setInvalidFormat(false)
                  setClear(true)
                }}
                onMouseEnter={() => setClearHover(true)}
                onMouseLeave={() => setClearHover(false)}
                style={{
                  textAlign: 'center',
                  marginTop: -8,
                  border: '#6568E6 1px solid',
                  margin: '-10px 20px',
                  borderRadius: 5,
                  padding: 8,
                }}
              >
                <label
                  style={{
                    color: clearHover ? '#fff' : '#6568E6',
                    fontSize: 14,
                  }}
                >
                  Clear
                </label>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

const parentDivStyle = {
  marginTop: 410,
  paddingTop: 18,
  backgroundColor: '#fff',
  zIndex: 4,
  position: 'absolute',
  marginLeft: 90,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  border: '1px solid #D9D9D9',
  borderRadius: 6,
  padding: 10,
  boxShadow: 'rgb(0 0 0 / 7%) 2px 2px 10px',
  height: 330,
  paddingBottom: 40,
  zIndex: 6,
}
export default CalendarMenuMain
