import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Radio, Space, Button } from 'antd'
import jwt_decode from 'jwt-decode'

export default function UnsubscribeEmail() {
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState(false)
  const [decodedToken, setDecodedToken] = useState(null)
  const [reason, setReason] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const [reasonError, setReasonError] = useState('')
  const { token } = useParams()
  const navigate = useNavigate()

  const unsubscribe = async () => {
    setReasonError(false)
    setBtnLoading(true)
    if (!reason) {
      setReasonError(`You must select a reason to unsubscribe.`)
      setBtnLoading(false)
      return
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/unsubscribe`,
        {
          token: token,
          reason: reason,
        },
      )
      if (res.data === 'somthing went wrong, please email us for help') {
        setReasonError(`Failed to unsubscribe. Please try again later.`)
        setBtnLoading(false)
        return
      }
      setUnsubscribeSuccess(true)
      setBtnLoading(false)
    } catch (e) {
      console.log(e)
      setReasonError(`Failed to unsubscribe. Please try again later.`)
      setBtnLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      console.log(token)
      try {
        var decoded = jwt_decode(token)
        // console.log(decoded)
        setDecodedToken(decoded)
        if (!decoded.email) {
          navigate('/')
        }
      } catch (e) {
        navigate('/')
      }
    })()
  }, [])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: '#f0f1ff',
        }}
      >
        <div style={{ width: '30%' }}>
          {/* <h1 style={{ width: '100%' }}>Venuely</h1> */}
          <h1 style={{ width: '100%' }}></h1>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          {unsubscribeSuccess && (
            <>
              <h1>Unsubscribe Successful</h1>
              <p>
                Your unsubscription request was taken into account and will be
                effective within the next 24 hours.
              </p>
            </>
          )}
          {!unsubscribeSuccess && (
            <>
              <p>
                Your address: <b>{decodedToken?.email}</b>{' '}
              </p>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <p className="" style={{ marginBottom: 10 }}>
                  Reason:
                </p>
                <Radio.Group
                  dir
                  onChange={(e) => {
                    // set value to state
                    console.log(e.target.value)
                    setReason(e.target.value)
                    setReasonError('')
                  }}
                  style={{ marginLeft: 20 }}
                >
                  <Space direction="vertical">
                    <Radio value={`I receive too many emails from Venuely.`}>
                      I receive too many emails from Venuely.
                    </Radio>
                    <Radio value={`The emails appear in unreadable form.`}>
                      The emails appear in unreadable form.
                    </Radio>
                    <Radio value={`I never signed up for this mailing list.`}>
                      I never signed up for this mailing list.
                    </Radio>
                    <Radio value={`I no longer want to receive these emails.`}>
                      I no longer want to receive these emails.
                    </Radio>
                  </Space>
                </Radio.Group>
                <br />
              </div>
              {reasonError && <p style={{ color: 'red' }}>{reasonError}</p>}
              <Button
                size="large"
                type="primary"
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                }}
                loading={btnLoading}
                onClick={unsubscribe}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
