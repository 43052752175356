import { ComponentActionType } from '../contants/actions-type'
const initialState = {
  banner: {
    header: null,
    discription: null,
    button: {
      visible: false,
      link: null,
      name: null,
    },
    progress_bar: {
      visible: false,
      progress: 0,
    },
    pages: {
      visible: false,
      total_page: 0,
      page_number: 0,
    },
  },
}

export default function ComponentReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case ComponentActionType.SET_BANNER:
      return {
        ...state,
        banner: payload,
      }
    case ComponentActionType.FATCH_DATA:
      return {
        ...state,
      }
    default:
      return state
  }
}
