import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setBanner,
  setGuestRequirments,
} from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'

export default function GuestRequirementOfSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [guestRequirMentsData, setGuestRequirMentsData] = useState('')
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Guest Requirement',
          discription:
            'What is required of the every guests and guests to access and utilize your space',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: 25 * 4,
          },
          pages: {
            visible: true,
            total_page: 4,
            page_number: 4,
          },
          callBack: () => {
            dispatch(
              setGuestRequirments({
                callBack: (response) => {
                  setGuestRequirMentsData(response.data.standard_requirements)
                  // document.getElementById('req').innerHTML(response.data.standard_requirements)
                },
              }),
            )
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'scroll' }}
    >
      <div className="policy-and-ruls-of-space-insert-space-form">
        <div className="buffer-time-ruls">
          <h1>
            Your Guests will always meet these requirements before they book:
          </h1>
          <div
            className="req"
            id="req"
            dangerouslySetInnerHTML={{ __html: guestRequirMentsData }}
          ></div>
          {/* <ul>
                            <li>
                                <h5><b> ✔️ Venuely standard requirements</b></h5>
                                <div className="list-content">
                                    <p>Confirmed phone number, email address, payment information, and agreement to House Rules.  Learn More</p>
                                </div>
                            </li>
                            <li>
                                <h5> <b> ✔️ Vaccination Requirement: Indoor Public Activities (Key to NYC)</b></h5>
                                <div className="list-content">
                                    <b>Expanded Requirements (Jan 2022 update)</b>
                                    <p>COVID-19 vaccination proof requirements have expanded to include younger children and to require full vaccination:</p>
                                    <ul>
                                        <li> <b>Children:</b> Children ages 5 to 11 are now required to have proof of vaccination for the public indoor activities described further below. <br/>They must show they have received at least one dose of a vaccine </li>
                                        <li> <b>Full Vaccination:</b> People 12 and older participating in the below public indoor activities are now required to show proof they have <br /> received two vaccine doses, except for those who have received the one dose of the Johnson & Johnson vaccine. <br/> Starting January 29, 2022, children ages 5 to 11 must also show proof of full vaccination. </li>
                                    </ul>
                                    <p>People ages 5 to 12 are required to show proof they have received at least one dose of a COVID-19 vaccine, and people 12 and older <br/> must show proof of two doses (except for Johnson & Johnson recipients), for:</p>
                                    <ul>
                                        <li> <b> Indoor dining </b><br /> Includes restaurants, catering halls, hotel banquet rooms, bars, nightclubs, cafeterias, coffee shops, fast food restaurants, grocery stores <br /> with indoor dining and other indoor dining spaces </li>
                                        <li> <b> Indoor fitness </b> <br /> Includes gyms, fitness centers, fitness classes, pools, dance studios and other indoor fitness studios, such as yoga or Pilates </li>
                                        <li> <b> Indoor entertainment and certain meeting spaces </b> <br /> Includes movie theaters, music and concert venues, museums, aquariums and zoos, professional sports arenas, indoor stadiums, convention centers, <br /> exhibition halls, hotel meeting and event spaces, performing arts theaters, bowling alleys, arcades, pool and billiard halls, recreational game centers, <br /> adult entertainment and indoor play areas</li>
                                    </ul>
                                    <p>People 18 and older are also required to show identification along with their proof of vaccination. These requirements — called the Key to NYC — also means employees working at these locations must be vaccinated.</p>
                                    <p>To report a location that is not following these requirements, call 311.</p>
                                    <p> <b> Note: </b>People who have proof they received an active COVID-19 vaccine during a U.S.-based clinical trial are also permitted at the above activities.</p>
                                    <p> <b> Proof of Vaccination </b><br/> For proof of vaccination, you can use: </p>
                                    <ul>
                                        <li>CDC Vaccination Card. A digital photo or photocopy of this card is also acceptable. </li>
                                        <li>NYC Vaccination Record or other official immunization record, including from your health care provider. A digital photo or photocopy of this card is also acceptable. If you are unable to get this record, call 311.</li>
                                        <li>NYC COVID Safe App: Android | iOS. You can upload to this app a photo of your CDC vaccination card or other official record, along with your photo ID.</li>
                                        <li>CLEAR Health Pass: Android | iOS. You can use the digital vaccine card option in the CLEAR app if you are 18 and older and are fully vaccinated.</li>
                                        <li>Excelsior Pass (or Excelsior Pass Plus). To use this app, you will need the phone number or email address associated with your New York vaccination record.  If you are unable to use this app, try one of the other options listed above.</li>
                                    </ul>
                                </div>
                            </li>
                        </ul> */}
        </div>

        <hr
          style={{ width: '1200px', marginTop: '152px' }}
          className="policy-and-ruls-of-space-insert-lasr-hr"
        />
        <div className="policy-and-ruls-of-space-insert-space-button-group">
          <button
            type="button"
            style={{ marginRight: '10px' }}
            onClick={() => {
              navigate(`/space/policy-and-ruls/covid-19-policy`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button
            type="submit"
            onClick={() => {
              navigate(`/space`)
            }}
          >
            {' '}
            Next{' '}
          </button>
        </div>
      </div>
    </div>
  )
}
