import React from 'react'
import { Button, Modal, Typography, message } from 'antd'

function ShareModal({ shareModal, setShareModal }) {

    const handleOk = () => setShareModal(false)
    const handleCancel = () => setShareModal(false)
    const { Paragraph } = Typography

    return (
        <>
            <Modal
                title="Share"
                visible={shareModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <h2 style={{ fontWeight: 500, fontSize: 14 }}>Shareable Link</h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Paragraph
                        className="copy-link-host-profile"
                        style={{
                            border: '1px solid #c4c4c4',
                            color: '#000',
                            padding: '6px',
                            borderRadius: '5px',
                            display: 'flex',
                            overflow: 'hidden',
                            backgroundColor: '#e1e2f5',
                            margin: 0,
                        }}
                    >
                        {window.location.href.slice(0, 60)}...
                    </Paragraph>
                    <Button
                        type="primary"
                        style={{ borderRadius: 5, marginLeft: 4 }}
                        onClick={() => {
                            navigator.clipboard.writeText(window.location.href)
                            message.success('Link copied to clipboard')
                        }}
                    >
                        Copy Link
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default ShareModal
