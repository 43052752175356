import { PropertyActionType } from '../contants/actions-type'
const initialState = {
  properties: [],
  property: [],
  selectedPropertyApproval: {},
  reservations: {
    current_page_number: null,
    data: [],
    has_next: false,
    has_other_pages: false,
    has_previous: false,
    limit: 10,
    total_canceled: 0,
    total_confirmed: 0,
    total_number_of_items: 0,
    total_number_of_page: 0,
    total_past: 0,
    total_pending: 0,
    type: 'PENDING',
  },
  reservation: {},
}

export default function PropertyReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case PropertyActionType.SELECT_RESERVATION:
      return {
        ...state,
        reservation: payload,
      }
    case PropertyActionType.SET_RESERVATION:
      return {
        ...state,
        reservations: payload,
      }
    case PropertyActionType.SET_PROPERTY:
      return {
        ...state,
        properties: payload,
      }
    case PropertyActionType.SELECT_PROPERTY:
      return {
        ...state,
        property: payload,
      }
    case PropertyActionType.REMOVE_SELECTED_PROPERTY:
      return { ...state }

    case PropertyActionType.UPDATE_SELECTED_PROPERTY:
      return { ...state }

    case PropertyActionType.CREATE_PROPERTY:
      return { ...state }

    case PropertyActionType.RESERVE_PROPERTY:
      return { ...state }

    case PropertyActionType.SELECTED_PROPERTY_APPROVAL:
      return {
        ...state,
        selectedPropertyApproval: payload,
      }
    case PropertyActionType.VERIFY_PROPERTY:
      return { ...state }
    case PropertyActionType.REFUND_RESARVATION:
      return { ...state }
    case PropertyActionType.SET_REFUND_RESARVATION:
      return { ...state }
    case PropertyActionType.CENCEL_RESARVATION:
      return { ...state }
    case PropertyActionType.CONFIRM_RESARVATION:
      return { ...state }
    case PropertyActionType.DELETE_PAYMENT_METHOD:
      return { ...state }
    case PropertyActionType.CREATE_PAYMENT_METHOD:
      return { ...state }
    case PropertyActionType.Search_Property_For_Select_Option:
      return { ...state }

    default:
      return state
  }
}
