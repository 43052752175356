import { HouseRuleActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setHouseRule = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/property/house-restriction/')
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: HouseRuleActionType.SET_HOUSE_RULE,
    payload: response.data,
  })
}

export const createHouseRule = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/property/house-restriction/', requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: HouseRuleActionType.SET_HOUSE_RULE,
    // payload:response.data
  })
}
