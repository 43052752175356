import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { TagFilled, CloseOutlined } from '@ant-design/icons'

import HeaderBar from '../components/HeaderBar'
import defaultProfileSVG from '../pages/icons/default_profile.svg'
import Cookies from 'universal-cookie'
import useInterval from '@use-it/interval'
import { useDispatch } from 'react-redux'
import { message, Spin } from 'antd'
import { logout, refresh } from '../redux/actions/AuthAction'
import '../style/messages.css'
import get12HourFormat from '../components/utils/get12HourFormat'
import { months } from '../components/utils/months'
import { useNavigate, useParams } from 'react-router-dom'
import noDataSVG from '../img/no-data.svg'

const Messages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [inquiryData, setinquiryData] = useState('')
  const params = useParams()
  const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL.split('/api')[0]
  const cookies = new Cookies()
  const authData = cookies.get('jwt')
  useEffect(() => {
    const getMsgs = async () => {
      try {
        const config = {
          headers: {
            Authorization: authData,
          },
        }
        const data = {
          type: 'all',
          page_number: 1,
          limit: 10,
        }
        const reqAPI = await axios.patch(
          `${baseURL}/property/reserve/conversation`,
          data,
          config,
        )
        setinquiryData(reqAPI.data)
        console.log(reqAPI.data.inquiries)
        setLoading(false)
      } catch (err) {
        if (err.response.status === 406) {
          message.error('Please Login again')
          dispatch(logout())
          navigate('/')
        } else {
          message.error(err.response.data.detail)
        }
      }
    }
    getMsgs()
    return () => getMsgs()
  }, [authData, baseURL])

  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)

  const getStatus = (item) => {
    let message = ''
    let color = ''
    if (item.is_inquiry) {
      color = '#6568E6'
      message = 'Inquiry'
    } else if (item.reservation_status === 'PENDING') {
      color = '#DF3416'
      message = 'Booking Request'
    } else if (item.reservation_status === 'CONFIRMED') {
      color = '#1B7E69'
      message = 'Accepted'
    }
    return (
      <h1 className="title__1" style={{ color: color }}>
        {message}
      </h1>
    )
  }

  const [acceptedBookings, setAcceptedBookings] = useState(false)
  const listItems = acceptedBookings
    ? inquiryData.inquiries.filter((item) => {
        if (item.length === 0) {
          return
        } else {
          return item.reservation_status === 'CONFIRMED'
        }
      })
    : inquiryData.inquiries

  return (
    <div>
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      {!loading ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                margin: 40,
              }}
            >
              <div style={{ display: 'flex', margin: '20px 0' }}>
                <h3
                  style={{
                    color: '#0E1111',
                    fontSize: 14,
                    fontWeight: acceptedBookings ? 400 : 'bold',
                    marginInline: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => setAcceptedBookings(false)}
                >
                  All Messages
                </h3>
                <h3
                  style={{
                    color: '#0E1111',
                    fontSize: 14,
                    fontWeight: acceptedBookings ? 'bold' : 400,
                    marginInline: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => setAcceptedBookings(true)}
                >
                  Accepted Bookings
                </h3>
              </div>
              <div
                className="msg__frame"
                style={{
                  margin: 10,
                  height: 0,
                  padding: 0,
                  visibility: 'hidden',
                  marginBottom: -17,
                }}
              ></div>
              {listItems && listItems.length === 0 && (
                <div
                  style={{
                    border: '1px #ccc solid',
                    width: 1042,
                    borderRadius: 5,
                    textAlign: 'center',
                    height: 300,
                  }}
                >
                  <img src={noDataSVG} alt="" />
                  <h4 style={{ fontSize: 16, color: '#414141' }}>
                    You currently have no messages
                  </h4>
                </div>
              )}
              {listItems.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: 10,
                    }}
                  >
                    <div
                      className="msg__frame"
                      onClick={() =>
                        navigate(`/renter/messages/${item.unique_id}`)
                      }
                      // onClick={() => console.log("ID", item.unique_id)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: 20,
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={`${imgURL}${item.property.images[0].image}`}
                          alt=""
                          style={{
                            width: 186,
                            height: 125,
                            borderRadius: 8,
                            objectFit: 'fill',
                          }}
                        />
                        <div style={{ textAlign: 'center' }}>
                          <img
                            src={
                              item.host.image !== null
                                ? `${imgURL}${item.host.image}`
                                : defaultProfileSVG
                            }
                            alt=""
                            style={{
                              width: 51,
                              height: 51,
                              borderRadius: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <h1 className="user-title">{item.host.name}</h1>
                        </div>
                      </div>
                      <div className="title">
                        <h1 className="title__1">
                          {months[+item.created.slice(5, 7)]}{' '}
                          {item.created.slice(8, 10)},{' '}
                          {item.created.slice(0, 4)},{' '}
                          {get12HourFormat(item.start_time)} -{' '}
                          {get12HourFormat(item.end_time)}
                        </h1>
                        <h1 className="title__2">
                          {item.last_message.message}
                        </h1>
                        <h1 className="title__3">
                          {months[+item.last_message.created.slice(5, 7)]}{' '}
                          {item.last_message.created.slice(8, 10)},{' '}
                          {item.last_message.created.slice(0, 4)},{' '}
                          {get12HourFormat(
                            item.last_message.created.split('T')[1].slice(0, 8),
                          )}
                        </h1>
                      </div>
                      <div>
                        {getStatus(item)}
                        <h1 className="title__4">
                          ${item.total_amount.toFixed(2)}
                        </h1>
                        {item.coupon && (
                          <p style={{ textAlign: 'right' }}>
                            <TagFilled style={{ color: 'black' }} />{' '}
                            {item.coupon?.code}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  )
}

export default Messages
