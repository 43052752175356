import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setReservation } from '../../../redux/actions/PropertyAction'
import ResarvationDetailsCard from '../ResarvationDetailsCard'
import ResarvationCardV2 from '../../../components/ResarvationCardV2'

export default function AllReservationRequestComponent({
  title,
  type,
  limit,
  setSelectedReservation,
  setResarvationDetailsCardType,
  reRenderComponent,
}) {
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [datalist, setDataList] = useState([])
  const [has_next, set_has_next] = useState(false)
  const [has_previous, set_has_previous] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  // const [selectedReservation, setSelectedReservation] = useState(null)
  const [showLoader, setshowLoader] = useState(true)
  const [cardType, setCardType] = useState('acceptedBooking')
  const dispatch = useDispatch()
  useEffect(() => {
    // if(isDataLoading){
    // let card_type = 'AllReservation'
    if (type == 'PENDING') {
      setCardType('pandingRequest')
    }

    if (type == 'CANCELED') {
      setCardType('canceledRequest')
    }

    if (type == 'PAST') {
      setCardType('pastRequest')
    }

    // setResarvationDetailsCardType(card_type)
    setData(1)
    // }
    return () => {
      setIsDataLoading(false)
    }
  }, [isDataLoading, reRenderComponent])

  let setData = (page_number) => {
    setshowLoader(true)
    dispatch(
      setReservation({
        type: type,
        page_number: page_number,
        limit: limit,
        callBack: (response) => {
          // setLimit(response.data.limit)
          console.log(response.data.current_page_number)
          setPageNumber(response.data.current_page_number)
          set_has_next(response.data.has_next)
          set_has_previous(response.data.has_previous)
          setDataList(response.data.data)
          setshowLoader(false)
          // console.log(type);
          // console.log(response.data.data);
        },
      }),
    )
  }

  let change_page = (next = true) => {
    let page_number = 1
    if (next) {
      page_number = parseInt(pageNumber) + parseInt(1)
      if (has_next) {
        setData(page_number)
      }
    }

    if (!next) {
      if (has_previous) {
        page_number = parseInt(pageNumber) - parseInt(1)
        setData(page_number)
      }
    }
  }

  let desableStyle = {
    borderColor: 'lightgray',
    color: 'lightgray',
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="reservation-list-header">
        <h1 style={{ marginTop: '20px' }}>{title}</h1>
      </div>
      {showLoader ? (
        <div style={{ height: '300px' }}>
          <div>
            <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
          </div>
        </div>
      ) : datalist && datalist.length > 0 ? (
        datalist.map((resarvation, index) => {
          return (
            <ResarvationCardV2
              key={index}
              pageName={cardType}
              resarvation={resarvation}
              setSelectedReservation={setSelectedReservation}
              setPageName={setResarvationDetailsCardType}
            />
          )
        })
      ) : (
        <div className="data-showing-box">
          <p>You don’t have any guest renting your space today</p>
        </div>
      )}
      <div
        className="reservation-pagination-button-group"
        style={{ marginTop: 50 }}
      >
        <button
          style={!has_previous ? desableStyle : {}}
          onClick={() => {
            change_page(false)
          }}
        >
          {' '}
          previous{' '}
        </button>
        <button
          style={!has_next ? desableStyle : {}}
          onClick={() => {
            change_page()
          }}
        >
          {' '}
          next{' '}
        </button>
      </div>
    </div>
  )
}
