export const AuthActionType = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ME: 'ME',
  REFRESHED: 'REFRESHED',
  UPDATE: 'UPDATE',
  REGISTRATION: 'REGISTRATION',
}

export const ComponentActionType = {
  SET_BANNER: 'SET_BANNER',
  FATCH_DATA: 'FATCH_DATA',
}

export const PropertyActionType = {
  SELECT_RESERVATION: 'SELECT_RESERVATION',
  SET_RESERVATION: 'SET_RESERVATION',
  SET_PROPERTY: 'SET_PROPERTY',
  SELECT_PROPERTY: 'SELECTED_PROPERTY',
  REMOVE_SELECTED_PROPERTY: 'REMOVE_SELECTED_PROPERTY',
  UPDATE_SELECTED_PROPERTY: 'UPDATE_SELECTED_PROPERTY',
  CREATE_PROPERTY: 'CREATE_PROPERTY',
  RESERVE_PROPERTY: 'RESERVE_PROPERTY',
  VERIFY_PROPERTY: 'VERIFY_PROPERTY',
  SELECTED_PROPERTY_APPROVAL: 'SELECTED_PROPERTY_APPROVAL',
  REFUND_RESARVATION: 'REFUND_RESARVATION',
  SET_REFUND_RESARVATION: 'SET_REFUND_RESARVATION',
  CENCEL_RESARVATION: 'CENCEL_RESARVATION',
  CONFIRM_RESARVATION: 'CONFIRM_RESARVATION',
  Search_Property_For_Select_Option: 'Search_Property_For_Select_Option',
}

export const SpaceActionType = {
  SET_MAIN_SPACE: 'SET_MAIN_SPACE',
  SELECT_MAIN_SPACE: 'SELECT_MAIN_SPACE',
  CREATE_MAIN_SPACE: 'CREATE_MAIN_SPACE',
  UPDATE_MAIN_SPACE: 'UPDATE_MAIN_SPACE',

  SET_SPACE: 'SET_SPACE',
  SELECT_SPACE: 'SELECTED_SPACE',
  CREATE_SPACE: 'CREATE_SPACE',
  UPDATE_SPACE: 'UPDATE_SPACE',
  REMOVE_SPACE: 'REMOVE_SPACE',
}

export const BasicPolicyActionType = {
  SET_BASIC_POLICY: 'SET_BASIC_POLICY',
  SELECT_BASIC_POLICY: 'SELECTED_BASIC_POLICY',
  CREATE_BASIC_POLICY: 'CREATE_BASIC_POLICY',
  UPDATE_BASIC_POLICY: 'UPDATE_BASIC_POLICY',
  REMOVE_BASIC_POLICY: 'REMOVE_BASIC_POLICY',
}

export const HouseRuleActionType = {
  SET_HOUSE_RULE: 'SET_HOUSE_RULE',
  SELECT_HOUSE_RULE: 'SELECTED_HOUSE_RULE',
  CREATE_HOUSE_RULE: 'CREATE_HOUSE_RULE',
  UPDATE_HOUSE_RULE: 'UPDATE_HOUSE_RULE',
  REMOVE_HOUSE_RULE: 'REMOVE_HOUSE_RULE',
}

export const ConversationActionType = {
  SET_CONVERSATION: 'SET_CONVERSATION',
  SELECT_CONVERSATION: 'SELECTED_CONVERSATION',
  SEND_MESSAGE: 'SEND_MESSAGE',
}

export const InformationActionType = {
  SET_PAYMENT_HISTORY: 'SET_PAYMENT_HISTORY',
  SET_INFORMATION: 'SET_INFORMATION',
  UPDATE_INFORMATION: 'UPDATE_INFORMATION',
  UPDATE_OR_ADD_PAYMENT_INFORMATION: 'UPDATE_OR_ADD_PAYMENT_INFORMATION',
}

export const AvailabilityActionType = {
  SELECT_AVAILABILITY: 'SELECT_AVAILABILITY',
  SET_AVAILABILITY: 'SET_AVAILABILITY',
  UPDATE_AVAILABILITY: 'UPDATE_AVAILABILITY',
  CREATE_AVAILABILITY: 'CREATE_AVAILABILITY',
  REMOVE_AVAILABILITY: 'REMOVE_AVAILABILITY',
}

export const BlockedDateActionType = {
  SELECT_BLOCKED_DATE: 'SELECT_BLOCKED_DATE',
  SET_BLOCKED_DATE: 'SET_BLOCKED_DATE',
  UPDATE_BLOCKED_DATE: 'UPDATE_BLOCKED_DATE',
  CREATE_BLOCKED_DATE: 'CREATE_BLOCKED_DATE',
  REMOVE_BLOCKED_DATE: 'REMOVE_BLOCKED_DATE',
}

export const ImageActionType = {
  SET_IMAGE: 'SET_IMAGE',
  SELECT_IMAGE: 'SELECT_IMAGE',
  UPDATE_IMAGE: 'UPDATE_IMAGE',
  CREATE_IMAGE: 'CREATE_IMAGE',
  REMOVE_IMAGE: 'REMOVE_IMAGE',
}

export const BufferTimeRoleActionType = {
  UPDATE_BUFFER_TIME_ROLE: 'UPDATE_BUFFER_TIME_ROLE',
  CREATE_BUFFER_TIME_ROLE: 'CREATE_BUFFER_TIME_ROLE',
  SET_BUFFER_TIME_ROLE: 'SET_BUFFER_TIME_ROLE',
  SELECT_BUFFER_TIME_ROLE: 'SELECT_BUFFER_TIME_ROLE',
  REMOVE_BUFFER_TIME_ROLE: 'REMOVE_BUFFER_TIME_ROLE',
}

export const WindowBookingRoleActionType = {
  SET_WINDOW_BOOKING_ROLE: 'SET_WINDOW_BOOKING_ROLE',
  SELECT_WINDOW_BOOKING_ROLE: 'SELECT_WINDOW_BOOKING_ROLE',
  UPDATE_WINDOW_BOOKING_ROLE: 'UPDATE_WINDOW_BOOKING_ROLE',
  CREATE_WINDOW_BOOKING_ROLE: 'CREATE_WINDOW_BOOKING_ROLE',
  REMOVE_WINDOW_BOOKING_ROLE: 'REMOVE_WINDOW_BOOKING_ROLE',
}

export const PricingActionType = {
  CREATE_PRICING: 'CREATE_PRICING',
  UPDATE_PRICING: 'UPDATE_PRICING',
}
