import React, { useEffect, useState, useRef } from 'react'
import StatusBox from '../../components/StatusBox'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setBanner } from '../../redux/actions/ComponentAction'
import greenTick from './../../img/green_tik.png'
import dummyUser from './../../img/dummy-user.png'
import redWorning from './../../img/red_worning.png'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'
import { setConversation } from '../../redux/actions/ConversationAction'
import './inquiry.css'
import {
  baseURL,
  getTimeDifferenceBetweenTwoTime,
  calculateDiscount,
  calculatePrice,
  timeConvart,
  dateConvart,
  calculatePriceByInquiry,
} from '../../redux/requests'
import emailPhoneMasking, {
  maskingEmail,
  maskingPhone,
} from 'word-masking-helper'

export default function InquiryList() {
  const sectionElementRef = useRef(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [unSeenMessage, setUnSeenMessage] = useState(0)
  const [allInquiries, setAllInquiries] = useState(null)
  const { type } = useParams()
  const [paginationData, setPaginationData] = useState({
    limit: 0,
    current_page_number: 0,
    has_next: false,
    has_other_pages: false,
    has_previous: false,
    total_number_of_items: 0,
    total_number_of_page: 0,
    total_unseen_messages: 0,
  })
  useEffect(() => {
    console.log(type)
    setInquiryData(1, 10)
  }, [type])
  let setInquiryData = (page_number, limit) => {
    setLoading(true)
    dispatch(
      setConversation({
        type: type && type != null ? type : 'all',
        page_number: page_number,
        limit: limit,
        callBack: (response) => {
          console.log(response)
          setUnSeenMessage(response.data.total_unseen_messages)
          setAllInquiries(response.data.inquiries)
          setPaginationData({
            limit: response.data.limit,
            current_page_number: response.data.current_page_number,
            has_next: response.data.has_next,
            has_other_pages: response.data.has_other_pages,
            has_previous: response.data.has_previous,
            total_number_of_items: response.data.total_number_of_items,
            total_number_of_page: response.data.total_number_of_page,
            total_unseen_messages: response.data.total_unseen_messages,
          })
          console.log(response.data)
          setLoading(false)
        },
      }),
    )
  }
  let sideBarCss = {
    position: 'absolute',
    top: '132px',
    bottom: '0',
    // minHeight: '100vh',
    // height: '250rem',
    height:
      allInquiries && allInquiries.length > 0
        ? 170 * allInquiries.length +
          sectionElementRef.current?.clientHeight * 1.5
        : '100vh',
  }

  let get_reservation_status = (reservation) => {
    let status = 'Booking Request'
    if (!reservation.confirm_reservation && !reservation.cancel_reservation) {
      status = 'Booking Request'
    }

    if (reservation.confirm_reservation) {
      status = 'Accepted'
    }

    if (reservation.cancel_reservation) {
      status = 'canceled'
    }

    return status
  }

  let booking_request_color = {
    PENDING: 'red',
    CONFIRMED: 'green',
    CANCELED: 'lightgray',
    Inquiry: '#6568E6',
  }
  let desabledCss = {
    color: 'lightGray',
    borderColor: 'lightGray',
    cursor: 'not-allowed',
  }

  return (
    <main>
      <aside style={sideBarCss} className="main-content-aside">
        <div className="space-side-nevbar">
          <ul>
            <li>
              {' '}
              <NavLink
                to={'/messages/all'}
                className={({ isActive }) => (isActive ? 'active' : '')}
                style={styles.navLinkStyle}
              >
                {' '}
                All Messages ({unSeenMessage} Read){' '}
              </NavLink>{' '}
            </li>
            <li>
              {' '}
              <NavLink
                to={'/messages/accepted-bookings'}
                className={({ isActive }) => (isActive ? 'active' : '')}
                style={styles.navLinkStyle}
              >
                {' '}
                Accepted Bookings{' '}
              </NavLink>{' '}
            </li>
          </ul>
        </div>
      </aside>
      <section style={{}} className="main-content" ref={sectionElementRef}>
        <div className="banner">
          <div className="banner-content" style={{ padding: 50 }}>
            <h1>Messages</h1>
            <p>Space to communicate with the renters</p>
          </div>
        </div>
        <div className="inquiry-card-list-wrapper">
          {loading ? (
            <div>
              <Spin
                size="large"
                style={{ width: '100%', margin: '40px auto' }}
              />
            </div>
          ) : (
            allInquiries &&
            allInquiries.map((inquiry) => {
              return (
                <NavLink to={`/messages/history/${inquiry.unique_id}`}>
                  <div className="single-inquiry-card">
                    <div className="inquiry-card-property-image">
                      <img
                        style={{
                          width: '186px',
                          height: '125px',
                          objectFit: 'fill',
                          borderRadius: 5,
                        }}
                        src={`${baseURL.replace('/api', '')}${
                          inquiry.property.images.length > 0
                            ? inquiry.property.images[0].image
                            : ''
                        }`}
                        alt=""
                      />
                    </div>
                    <div className="inquiry-card-guest-image">
                      <img
                        style={{
                          width: '186px',
                          height: '125px',
                          objectFit: 'cover',
                        }}
                        src={
                          inquiry.guest.image
                            ? `${baseURL.replace('/api', '')}${
                                inquiry.guest.image
                              }`
                            : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
                        }
                        alt=""
                      />
                      <p style={{ color: '#000', textAlign: 'center' }}>
                        {inquiry.guest.name}
                      </p>
                    </div>
                    <div className="inquiry-card-inquiry-information">
                      <h4 className="inquiry-card-inquiry-information-date-time">
                        <div>
                          {' '}
                          {`${dateConvart(inquiry.start_date).dayName} ${
                            dateConvart(inquiry.start_date).convertedDate
                          }`}{' '}
                          {calculatePriceByInquiry(inquiry).total_day > 0
                            ? `to ${dateConvart(inquiry.end_date).dayName} ${
                                dateConvart(inquiry.end_date).convertedDate
                              }`
                            : ``}
                        </div>
                        {/* {inquiry.start_date} {inquiry.start_date != inquiry.end_date ? `to ${inquiry.end_date}` : ''} ,{inquiry.start_time}-{inquiry.end_time} */}
                      </h4>
                      <p className="inquiry-card-inquiry-information-last-message">
                        {emailPhoneMasking(inquiry.last_message.message)}
                      </p>
                      <div className="inquiry-card-inquiry-information-last-message-date">
                        {dateConvart(inquiry.last_message.created).dayName},{' '}
                        {
                          dateConvart(inquiry.last_message.created)
                            .convertedDate
                        }{' '}
                        {timeConvart(
                          inquiry.last_message.created.split('T')[1],
                        )}
                      </div>
                    </div>
                    <div className="inquiry-card-inquiry-status-amount">
                      <p
                        className="inquiry-card-inquiry-status"
                        style={{
                          color:
                            booking_request_color[
                              inquiry.is_inquiry
                                ? 'Inquiry'
                                : inquiry.reservation_status
                            ],
                        }}
                      >
                        {inquiry.is_inquiry
                          ? 'Inquiry'
                          : inquiry.reservation_status}
                      </p>
                      <p className="inquiry-card-inquiry-amount">
                        ${inquiry.amount.toFixed(2)}
                      </p>
                    </div>
                  </div>{' '}
                </NavLink>
              )
            })
          )}

          {allInquiries && allInquiries.length > 0 ? (
            <div
              className="inquiry-card-list-pagination-buttons"
              style={{ marginTop: 50 }}
            >
              <div
                onClick={() => {
                  if (paginationData.has_previous) {
                    let page_number = paginationData.current_page_number - 1
                    setInquiryData(page_number, paginationData.limit)
                  }
                }}
                style={paginationData.has_previous ? {} : desabledCss}
              >
                {' '}
                previous{' '}
              </div>{' '}
              <div
                style={paginationData.has_next ? {} : desabledCss}
                onClick={() => {
                  if (paginationData.has_next) {
                    let page_number = paginationData.current_page_number + 1
                    setInquiryData(page_number, paginationData.limit)
                  }
                }}
              >
                {' '}
                next{' '}
              </div>
            </div>
          ) : (
            <>
              {allInquiries?.length === 0 && (
                <div className="data-showing-box">
                  <p>You don’t have any conversation yet.</p>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </main>
  )
}

const styles = {
  navLinkStyle: {
    color: '#000',
    width: '100%',
    textAlign: 'center',
  },
}
