import { InformationActionType } from '../contants/actions-type'
const initialState = {}

export default function InformationReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case InformationActionType.SET_INFORMATION:
      return {
        ...state,
        ...payload,
      }

    case InformationActionType.UPDATE_INFORMATION:
      return {
        ...state,
        ...payload,
      }
    case InformationActionType.UPDATE_OR_ADD_PAYMENT_INFORMATION:
      return {
        ...state,
      }
    case InformationActionType.SET_PAYMENT_HISTORY:
      return {
        ...state,
      }
    default:
      return state
  }
}
