import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Radio } from 'antd'
import { getAllInputValue } from '../../redux/requests'
import {
  selectSpace,
  setSpace,
  updateSpace,
} from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

const formRef = createRef()
export default function AboutSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  // const about = useSelector(state => state.space.space.about)
  const [about, setAbout] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [alchoholConsumption, setAlchoholConsumption] = useState(false) // Property values
  const [smoking, setSmoking] = useState(false) // Property values
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: About',
          discription: 'Inform the renters more in depth about your space',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100 / 7) * 2,
          },
          pages: {
            visible: true,
            total_page: 2,
            page_number: 6,
          },
          callBack: () => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  setAlchoholConsumption(
                    response.data.about.alcohol_consumption,
                  )
                  setSmoking(response.data.about.smoking)

                  console.log(response)
                  if (response.data.about.title) {
                    if (response.data.about.title != 'space-title') {
                      formRef.current.elements.name.value =
                        response.data.about.title
                    }
                  }
                  if (response.data.about.description)
                    formRef.current.elements.description.value =
                      response.data.about.description
                  if (response.data.about.dimention)
                    formRef.current.elements.dimention.value =
                      response.data.about.dimention
                },
              }),
            )
          },
        }),
      )
    }
  }, [id])

  let formValidate = (form) => {
    if (form.name.length > 50) {
      NotificationManager.warning(
        `title length cannot be gatter then 50`,
        'About Space',
        3000,
      )
      return false
    }

    if (form.description.length > 2000) {
      NotificationManager.warning(
        `description length cannot be gatter then 2000`,
        'About Space',
        3000,
      )
      return false
    }

    if (form.dimention > 60000 || form.dimention < 0) {
      NotificationManager.warning(
        `dimention cannot be gatter then 60000 or a negative number`,
        'About Space',
        3000,
      )
      return false
    }

    return true
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let formDate = getAllInputValue(e)
    let is_validated = formValidate(formDate)
    
    if (is_validated) {
      dispatch(
        updateSpace({
          about: {
            ...formDate,
            alcohol_consumption: alchoholConsumption,
            smoking: smoking,
          },
          id: id,
          callBack: (response) => {
            if (response.status == 200) {
              NotificationManager.success(
                `Successfully updated`,
                'Space Information',
                700,
              )
              navigate(`/space/add/${id}/photos`)
            }
          },
        }),
      )
    }
  }

  let fatchSpace = () => {
    dispatch(
      selectSpace({
        id: id,
        callBack: (response) => {
          console.log(response.data)
        },
      }),
    )
  }
  const [titleCount, setTitleCount] = useState(0)
  const [discriptionCOunt, setDiscriptionCOunt] = useState(0)
  let handleChane = (e) => {
    let form = formRef.current
    setTitleCount(form.name.value.length)
    setDiscriptionCOunt(form.description.value.length)
  }
  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form
        ref={formRef}
        onChange={handleChane}
        onSubmit={handleSubmit}
        className="about-space-insert-form"
      >
        <div className="about-space-insert-textarea-group">
          <h1 className="">Let’s give this listing/space a name</h1>
          <p className="">Create your title</p>
          <textarea
            name="name"
            id="name"
            cols="30"
            rows="10"
            required
            placeholder="• Highlight your unique selling points&#10;• Use the full 50 character limit&#10;• Avoid generic adjectives like 'nice', 'beautiful,' 'convenient,' 'spacious'. Be more specific instead."
          ></textarea>
          <small>{titleCount}/50</small>
        </div>
        <hr />
        <div className="about-space-insert-textarea-group">
          <h1 className="">Let’s describe your space </h1>
          <p className="">Create your description</p>
          <textarea
            name="description"
            id="description"
            required
            cols="30"
            rows="10"
            placeholder="• Highlight your unique amenities, location or whatever else makes your property stand out."
          ></textarea>
          <small>{discriptionCOunt}/2000</small>
        </div>
        <hr />
        <div className="about-space-insert-input-number-group">
          <h1 className="">How big is the space guests can book?</h1>
          <p className="">
            Please only include the size of this specific space that guests can
            use during their booking.
          </p>
          <input
            style={{ paddingLeft: '10px' }}
            type="number"
            name="dimention"
            id="dimention"
          />
          <button disabled>Sq ft</button>
        </div>
        <hr />
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <h1 className="" style={{ marginBottom: 30 }}>
            Is drinking alcohol allowed?
          </h1>
          <Radio.Group
            onChange={(e) => {
              setAlchoholConsumption(e.target.value)
            }}
            value={alchoholConsumption}
          >
            <Radio value={true}>Allowed</Radio>
            <Radio value={false}>Not Allowed</Radio>
          </Radio.Group>
        </div>
        <div style={{ marginTop: 50, marginBottom: 80 }}>
          <h1 className="" style={{ marginBottom: 30 }}>
            Is smoking allowed?
          </h1>
          <Radio.Group
            onChange={(e) => {
              setSmoking(e.target.value)
            }}
            value={smoking}
          >
            <Radio value={true}>Allowed</Radio>
            <Radio value={false}>Not Allowed</Radio>
          </Radio.Group>
        </div>
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/setup-information`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="submit"> Save & Continue </button>
        </div>
      </form>
    </div>
  )
}
