import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setBanner } from '../../redux/actions/ComponentAction'
import pinLogo from './../../img/pin.png'
import instructionImage from './../../img/instruction.png'
import Map from '../../components/map/Map'
import { updateSpace } from '../../redux/actions/SpaceAction'
import { NotificationManager } from 'react-notifications'
import {
  updateInformation,
  setInformation,
} from '../../redux/actions/InformationAction'
import { useNavigate } from 'react-router-dom'
import { message, Spin } from 'antd'

const formRef = createRef()
export default function BasicLocationInsert() {
  const banner = useSelector((state) => state.component.banner)
  const mainSpace = useSelector((state) => state.space.main_space)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [nextBtnLoading, setNextBtnLoading] = useState(false)
  const [iframeLoaded, setiframeLoaded] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Basics',
          discription:
            'Provide the point of contact and the location of your space',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: 100,
          },
          pages: {
            visible: true,
            total_page: 2,
            page_number: 2,
          },
          callBack: () => {},
        }),
      )
      setFormData()
    }
  }, [loading])

  let setFormData = () => {
    let form = formRef.current.elements
    dispatch(
      setInformation({
        callBack: (response) => {
          if (response.data.property != undefined) {
            form.latitude.value =
              response.data.property.basic_information.latitude
            form.longitud.value =
              response.data.property.basic_information.longitud
          }
          setLoading(false)
        },
      }),
    )
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let locationData = {
      latitude: e.target.latitude.value,
      longitud: e.target.longitud.value,
    }
    console.log(locationData)
    setNextBtnLoading(true)
    dispatch(
      updateInformation({
        basic_information: locationData,
        callBack: (response) => {
          console.log(response.data)
          if (
            response.data.details == 'we are not available is this state'
          ) {
            NotificationManager.error(
              `${response.data.details}`,
              'Space Information',
              3000,
            )
            setNextBtnLoading(false)
            return // ? Exit from this function
          }

          NotificationManager.success(
            `${response.data.message}`,
            'Space Information',
            1000,
          )
          setNextBtnLoading(false)
          navigate('/space')
        },
      }),
    )
  }
  return (
    <div
      className="basic-information-inserte-section"
      style={{
        height: 'calc(100vh - 380px)',
        overflow: 'scroll',
        paddingBottom: 100,
      }}
    >
      {/* <div className="pageCount">
                2/2
            </div> */}
      <h1>Please type the address again below</h1>
      <p>
        Drag the map to reposition the pin to make sure your address is correct
      </p>

      <div className="location-insert-map">
        {/* { !iframeLoaded ? <div className='loader'></div> : ''} */}
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="location-insert-form"
        >
          <Map form={formRef} />
          <input type="text" hidden name="latitude" />
          <input type="text" hidden name="longitud" />
          <input type="text" hidden name="address" />
          <input type="text" hidden name="zip" />
          {/* <img src={instructionImage} className='instructionImage' alt="" srcSet="" /> */}
          <button type="submit" className="location-insert-submit-button">
            {nextBtnLoading ? (
              <div className="white-spin-container" style={{}}>
                <Spin className="white-spin-antd" size="medium" />
              </div>
            ) : (
              'Next'
            )}
          </button>
        </form>
      </div>
    </div>
  )
}
