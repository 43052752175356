import React from 'react'

export default function LoadingAnimation() {
  return (
    <div class="hr-loading-wrapper">
      <div class="loading-bar">Loading...</div>
    </div>
  )
}

{
  /* <div class="loading-container">
    <div class="loading">
        Loading...
    </div>
</div> */
}
