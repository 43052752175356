import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import leftArrowImg from '../../pages/icons/leftPurpleArrow.png'
import rightArrowImg from '../../pages/icons/rightPurpleArrow.png'
import shareIcon from '../icons/share.svg'

const baseURL = process.env.REACT_APP_BASE_URL
const imgURL = baseURL.split('/api')[0]

function PropertyCover(props) {
  const { photoModal, setShareModal, dateVal, dateVal2, loading, spaceList } =
    props

  const params = useParams()

  const [coverImgId, setCoverImgId] = useState(0)

  const leftArrowFunc = () => {
    if (
      coverImgId <=
      spaceList?.property.image.length - spaceList?.property.image.length
    ) {
      setCoverImgId(spaceList?.property.image.length - 1)
    } else {
      setCoverImgId(coverImgId - 1)
    }
  }
  const rightArrowFunc = () => {
    if (coverImgId >= spaceList?.property.image.length - 1) {
      setCoverImgId(0)
    } else {
      setCoverImgId(coverImgId + 1)
    }
  }

  if (loading) {
    return <></>
  }

  return (
    <div
      id="carousel-bg"
      className="carousel-bg"
      style={{
        backgroundImage: `url(${imgURL}${
          spaceList?.property.image[
            spaceList?.property.image.length -
              spaceList?.property.image.length +
              coverImgId
          ].image
        })`,
      }}
    >
      <div
        id="photo-modal-backdrop"
        className="photo__modal__backdrop"
        style={{
          visibility: photoModal ? 'visible' : 'hidden',
          opacity: photoModal ? 1 : 0,
          userSelect: 'none',
        }}
      >
        <img
          className="photo__modal__img"
          src={`${imgURL}${spaceList?.property.image[coverImgId].image}`}
          alt=""
        />
        <div style={styles.imageHoverNextPrevContainer}>
          <div style={styles.imageHoverNextPrevContainer2}>
            <img
              src={leftArrowImg}
              className="img__changer__arrow"
              alt=""
              onClick={() => leftArrowFunc()}
            />
            <img
              src={rightArrowImg}
              className="img__changer__arrow"
              alt=""
              onClick={() => rightArrowFunc()}
            />
          </div>
        </div>
        <div style={styles.overLayImagesOnBelow}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {spaceList.property.image.map((el, i) => {
              return (
                <div
                  style={{
                    overflow: 'hidden',
                    height: 70,
                    width: 70,
                    objectFit: 'cover',
                    borderRadius: 5,
                    margin: 5,
                    border:
                      coverImgId === i ? '2px #6568E6 solid' : '1px #555 solid',
                    boxShadow: coverImgId === i && '#6568E677 1px 1px 5px',
                  }}
                >
                  <img
                    className="cover__img__pane"
                    src={`${imgURL}${el.image}`}
                    onClick={() => setCoverImgId(i)}
                    style={{
                      objectFit: 'cover',
                      opacity: coverImgId === i ? 1 : 0.5,
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'right', marginRight: 30, marginTop: 20 }}>
        <button
          style={{
            padding: '8px 12px',
            borderRadius: 8,
            boxShadow: '0px 4.28571px 8.57143px rgba(0, 0, 0, 0.1)',
          }}
          onClick={() => setShareModal(true)}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <img src={shareIcon} alt="" style={{ marginTop: -3 }} />
            <span
              style={{
                fontSize: 14,
                color: '#6568E6',
                fontWeight: 600,
              }}
            >
              Share
            </span>
          </div>
        </button>
      </div>
      <div style={{ marginTop: -10 }}>
        <div className="arrow-btns">
          <img src={leftArrowImg} alt="btns" onClick={() => leftArrowFunc()} />
          <img
            src={rightArrowImg}
            alt="btns"
            onClick={() => rightArrowFunc()}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <div className="dots-container-40">
            <div className="dots">
              {Array(spaceList?.property.image.length)
                .fill(spaceList?.property.image.length - 1)
                .map((dot, idx) => {
                  return (
                    <>
                      <div
                        key={idx}
                        style={{
                          backgroundColor: coverImgId === idx ? '#fff' : '#999',
                          width: 8,
                          height: 8,
                          borderRadius: 10,
                          margin: '0vh 4px',
                        }}
                      ></div>
                    </>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  loading: {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  imageHoverNextPrevContainer: {
    position: 'absolute',
    top: '46.5%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  imageHoverNextPrevContainer2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    heigth: '100vh',
    width: '95vw',
  },
  overLayImagesOnBelow: {
    position: 'absolute',
    top: '88%',
    width: '100vw',
  },
}

export default PropertyCover
