import axios from 'axios'
import Cookies from 'universal-cookie'
import { logout } from '../actions/AuthAction'
import emailPhoneMasking, {
  maskingEmail,
  maskingPhone,
} from 'word-masking-helper'

export const baseURL = process.env.REACT_APP_BASE_URL
export const baseURL2 = process.env.REACT_APP_BASE_URL2

export const privetData = async () => {
  const cookies = new Cookies()
  return axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
      Authorization: await cookies.get('jwt'),
    },
  })
}

export const data = () =>
  axios.create({
    baseURL: baseURL,
    timeout: 30000,
  })

export const handleErrorAndDispatch = (
  requiredData,
  response,
  dispatch,
  dispatchData,
) => {
  let acceptable = response.status != 406,
      unathorised = response.status == 403

  if (!acceptable || unathorised ) dispatch(logout())
  if (requiredData.callBack) requiredData.callBack(response)
  if (acceptable && !unathorised ) dispatch({ ...dispatchData })
}

export const getAllInputValue = (form,keys = []) => {
  let values = {}
  let is_key_given = keys.length > 0
  if (!is_key_given){
    for (const element in form.target.elements) {
      if (
        form.target.elements[element].nodeName == 'INPUT' ||
        form.target.elements[element].nodeName == 'TEXTAREA' 
      ) {
          values[form.target.elements[element].name] = form.target.elements[element].value
        }
      }
    return values
  }

  if(is_key_given){
    for(const key in keys){
      values[keys[key]] = form.target.elements[keys[key]].value
    }
    return values
  }
}

export let setTimeList = () => {
  let time_list = []
  for (let i = 8; i <= 24; i++) {
    var time = new Date(`1/1/2020 ${i}:00:00`).toLocaleTimeString('en-US', {
      hour12: false,
    })
    var newTime = new Date(`1/1/2020 ${i}:00:00`)

    time_list.push(time)
    if (i != 24) {
      for (let j = 1; j < 4; j++) {
        let min = 15 * j
        let addedIntervel = newTime.getTime() + min * 60000
        var conv = new Date(addedIntervel).toLocaleTimeString('en-US', {
          hour12: false,
        })
        time_list.push(conv)
      }
    }
  }

  for (let i = 1; i <= 7; i++) {
    var time = new Date(`1/1/2020 ${i}:00:00`).toLocaleTimeString('en-US', {
      hour12: false,
    })
    var newTime = new Date(`1/1/2020 ${i}:00:00`)

    time_list.push(time)
    if (i != 24) {
      for (let j = 1; j < 4; j++) {
        let min = 15 * j
        let addedIntervel = newTime.getTime() + min * 60000
        var conv = new Date(addedIntervel).toLocaleTimeString('en-US', {
          hour12: false,
        })
        time_list.push(conv)
      }
    }
  }

  return time_list
}

export let time_list = setTimeList()

export let dateConvertForReservation = (date) => {
  let newDate = new Date(`${date}`).toLocaleDateString('en-US')
  return newDate
}
export let tConvert = (time) => {
  let newtime = new Date(`1/1/2020 ${time}`).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
  return newtime
  // time = time.substring(0, time.length-3);
  // time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  // if (time.length > 1) { // If time format correct
  //   time = time.slice (1);  // Remove full string match value
  //   time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
  //   time[0] = +time[0] % 12 || 12; // Adjust hours
  // }
  // return time.join (''); // return adjusted time or original string
}

// export let tConvert = (time) => {

//   time = time.substring(0, time.length-3);
//   time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

//   if (time.length > 1) { // If time format correct
//     time = time.slice (1);  // Remove full string match value
//     time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
//     time[0] = +time[0] % 12 || 12; // Adjust hours
//   }
//   return time.join (''); // return adjusted time or original string
// }

export function titleCase(str) {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export let getTimeDifferenceBetweenTwoTime = (start, end) => {
  let startTime = new Date(`1/1/2022 ${start}`)
  let endTime = new Date(`1/1/2022 ${end}`)
  let hours = (endTime - startTime) / 36e5
  return hours
}

export let calculateDiscount = (start, end, amount, pricing) => {
  let hours = getTimeDifferenceBetweenTwoTime(start, end)
  let actualPrice = pricing.price
  let cleaning_fee = pricing.additional_cleaning_fee
  if (pricing.pricing_condition.id == 1) {
    actualPrice = hours * pricing.price
  }
  if (pricing.cleaning_fee.id == 2) {
    actualPrice = cleaning_fee + actualPrice
  }
  let charge = (5 / 100) * actualPrice
  return amount - (actualPrice + charge)
}
export let calculateDiscountByInquiry = (start, end, amount, pricing) => {
  let hours = getTimeDifferenceBetweenTwoTime(start, end)
  let actualPrice = pricing.price
  let cleaning_fee = pricing.additional_cleaning_fee

  if (pricing.pricing_condition.id == 1) {
    actualPrice = hours * pricing.price
  }
  if (pricing.cleaning_fee.id == 2) {
    actualPrice = cleaning_fee + actualPrice
  }
  // let charge = (5 / 100) * actualPrice

  if (hours >= pricing.minimum_hour_for_discount) {
    return pricing.discount
  }

  return 0
}

export let calculatePrice = (reservation) => {
  let hours = getTimeDifferenceBetweenTwoTime(
    reservation.start,
    reservation.end,
  ),
    price = reservation.price.price * hours,
    discount = calculateDiscount(
      reservation.start,
      reservation.end,
      reservation.charge.amount,
      reservation.price,
    ),
    cleaning_fee =
      reservation.price.cleaning_fee.id == 1
        ? 0
        : reservation.price.additional_cleaning_fee,
    charge = (5 / 100) * price,
    total_price = price + cleaning_fee + charge - discount

  total_price = total_price.toFixed(2)
  charge = charge.toFixed(2)

  return {
    hours,
    price,
    discount,
    cleaning_fee,
    charge,
    total_price,
  }
}

export let getDifferenceDay = (start_date, end_date) => {
  const date1 = new Date(start_date)
  const date2 = new Date(end_date)
  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return {
    day: diffDays,
    minutes: diffTime,
  }
}

export let calculatePriceByInquiry = (inquiry) => {
  let hours = getTimeDifferenceBetweenTwoTime(
    inquiry.start_time,
    inquiry.end_time,
  ),
    price =
      inquiry.price.pricing_condition.id == 1
        ? inquiry.price.price * hours
        : inquiry.price.price,
    total_day = getDifferenceDay(inquiry.start_date, inquiry.end_date).day,
    amount = total_day != 0 ? inquiry.amount / (total_day + 1) : inquiry.amount,
    discount = calculateDiscountByInquiry(
      inquiry.start_time,
      inquiry.end_time,
      amount,
      inquiry.price,
    ),
    cleaning_fee =
      inquiry.price.cleaning_fee.id == 1
        ? 0
        : inquiry.price.additional_cleaning_fee,
    charge = (5 / 100) * price,
    total_price = price + cleaning_fee + charge - discount

  total_price = total_price.toFixed(2)
  charge = charge.toFixed(2)
  return {
    hours,
    price,
    discount,
    cleaning_fee,
    charge,
    total_price,
    total_day,
  }
}

export let timeConvart = (time) => {
  let startTime = new Date(`1/1/2022 ${time}`)
  let timeString = startTime.toLocaleTimeString('en-us', { hour12: true })
  return timeString
}

export let dateConvart = (date) => {
  let dateObject = new Date(`${date}`)
  let presentDay = new Date()
  let presentDayYear = presentDay.toLocaleString('en-us', { year: 'numeric' })
  let day = dateObject.toLocaleString('en-us', { day: 'numeric' })
  let year = dateObject.toLocaleString('en-us', { year: 'numeric' })
  let dayName = dateObject.toLocaleString('en-us', { weekday: 'short' })
  let monthName = dateObject.toLocaleString('en-us', { month: 'long' })
  let convertedDate = dateObject.toLocaleString('en-us', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour12: true,
  })
  return { day, year, dayName, monthName, presentDayYear, convertedDate }
}
let Filter = require('bad-words')
let filter = new Filter()

export let sensore_string = (string) => {
  let clean_string = string
  if (clean_string.length > 0) {
    clean_string = filter.clean(clean_string)
  }
  let hide_email_phone = emailPhoneMasking(clean_string)
  return hide_email_phone
}


export let getFinishedPercentageForSpace = (id, spaces) => {

  let space = spaces[id]
  let page_list = {
    setup: `/space/add/${id}`,
    about: `/space/add/${id}/about`,
    photos: `/space/add/${id}/photos`,
    activities_and_pricing: `/space/add/${id}/activities`,
    opt_out_activities: `/space/add/${id}/optout`,
    amenities: `/space/add/${id}/amenities`
  }
  let page_empty = []

  if (space == undefined) {
    return {
      link: '',
      done: `0%`,
    }
  }

  if (
    space.setup.place_category == null ||
    space.setup.email == null ||
    space.setup.phone == null ||
    space.setup.number_of_guest == null ||
    space.setup.age == null
  ) {
    page_empty.push('setup')
  }

  if (Object.keys(space.activities_and_pricing).length != 0) {
    for (let key in space.activities_and_pricing) {
      if (
        space.activities_and_pricing[key].opt_out_activities.length == 0
      ) {
        page_empty.push('opt_out_activities')
      }
    }
  }

  if (
    space.about.description == null ||
    space.about.dimention == null
  ) {
    page_empty.push('about')
  }

  for (let key in space) {
    let typeOfdata = typeof space[key]

    if (typeOfdata == 'object') {
      if (Object.keys(space[key]).length == 0) {

        if (key != 'price') page_empty.push(key)
      }
    } else {
      if (space[key].length == 0) {
        if (key != 'price' && key != 'name') {
          console.log(space);
          page_empty.push(key)
        }
      }
    }

  }

  let data = {
    link: page_list[page_empty[0]],
    done: `${Math.floor((6 - page_empty.length) * (100 / 6))}%`,
  }

  if (data.done == '99%') {
    data.done = '100%'
  }

  return data
}

export let getFinishedPercentageForBasicInformation = (property) => {

  let page_list = {
    basic: '/space/basic/information',
    location: '/space/basic/location'
  }
  let page_empty = []

  if (
    property.basic_information.latitude == null ||
    property.basic_information.longitud == null
  ) {
    page_empty.push('location')
  }

  if (
    property.basic_information.name == null ||
    property.basic_information.address == null
  ) {
    page_empty.push('basic')
  }

  let data = {
    link: page_list[page_empty[0]],
    done: `${Math.floor((2 - page_empty.length) * (100 / 2))}`,
  }

  if (data.done == '99') {
    data.done = '100'
  }

  return data
}

export let getFinishedPercentageForRules = (property) => {

  let page_list = {
    covid_19_policy: '/space/policy-and-ruls/covid-19-policy',
    policies_and_rules: '/space/policy-and-ruls',
    house_ruls: "/space/policy-and-ruls/house-rules"
  }

  let page_empty = []

  if (
    property.policies_and_rules.basic_policy.booking_window_rules.length < 0 ||
    property.policies_and_rules.basic_policy.buffer_time_rules.length < 0
  ) {
    page_empty.push('policies_and_rules')
  }

  if (property.policies_and_rules.house_rules == undefined) {
    page_empty.push('house_ruls')
  }

  // console.log(property.policies_and_rules.covid_19_policy.cleaning_process.length == 0 );
  if (property.policies_and_rules.covid_19_policy == undefined) {
    page_empty.push('covid_19_policy')
  }

  if (property.policies_and_rules.covid_19_policy) {
    if (property.policies_and_rules.covid_19_policy.cleaning_process.length == 0) {
      page_empty.push('covid_19_policy')
    }
  }

  let data = {
    link: page_list[page_empty[0]],
    done: `${Math.floor((3 - page_empty.length) * (100 / 3))}`,
  }

  if (data.done == '99') {
    data.done = '100'
  }

  return data
}