import React from 'react'
import verfiedIconImg from '../../pages/images/Venuely-Verified-Icon-02.png'

const RenterFAQ = [
  {
    header: `I'm a host, how can Venuely support me?`,
    body: (
      <>
        We are thrilled that you are going to join Venuely! Luckily, if you are
        in New York, New Jersey, or Indiana, our development laboratory, we have
        lots of ways to support you. Our system is currently outfitted so that
        any Guest in NY, NJ, and IN looking to share space can search on our
        site, see if there are any spaces available that fit their needs, and
        book it - all in one place! Just like AirBnB, we will list any spaces
        that fit the criteria they list. Then they will be able to preview all
        those spaces before making a selection.
        <br />
        <br />
        Venuely also gives its hosts the ability to not only chat with potential
        Guests in advance but also to approve those bookings too. All Guests
        will have to agree to your space rules and policies before completing
        their booking. Plus, once the booking is approved and confirmed, the
        payment goes directly through our system, and will routinely make direct
        deposits into your linked bank account.
        <br />
        <br />
        <div style={{ textAlign: 'center', marginTop: 12 }}>
          <img
            src={verfiedIconImg}
            alt=""
            style={{ width: 60, objectFit: 'contain' }}
          />
        </div>
        ‍<br></br>In addition, as a host, you can take advantage of our Venuely
        Verified status. Venuely Verified is a status checkmark letting
        potential Guests know that the space photos, measurements, and amenities
        have all been Verified by Venuely's partners. This gives your space an
        extra boost in the eyes of the Guest who knows that they can use your
        space confident that it has exactly what was described.
      </>
    ),
  },
  {
    header: `I'm a host outside of NY, NJ, and IN, how can Venuely support me?`,
    body: (
      <>
        Venuely is currently operating in New York, New Jersey, and Indiana
        State. We are in the process of expanding!
        <br />
        <br />
        We are eager to continue to expand into every state to meet your needs!
        If you are outside of NY, NJ, or IN and would like to partner with us to
        bring Venuely to your state, contact us at: hello@venuely.org
      </>
    ),
  },
  {
    header: `Can I be Venuely Verified if I am outside of NY, NJ, and IN?`,
    body: (
      <>
        The short answer is - not at this time - however one of the co-sponsors
        of Venuely, Partners for Sacred Places, works with many communities in
        the US. There is some very limited availability for verification if you
        live in New Jersey, Philadelphia, or Chicago. Additionally, if you are
        an Episcopal Church within the state of Indiana, this option may also be
        made available to you.<br></br>
        <br></br>As Venuely grows in popularity and attracts more users, we hope
        to make this option available to many more communities across the
        country.
      </>
    ),
  },
  {
    header: `I'm a guest, how can Venuely support me?`,
    body: (
      <>
        Venuely allows potential Guests to chat with potential hosts in advance
        of following through with any booking. In addition, in the Venuely
        approval system, the Guest must read and agree to space use policies in
        advance meaning that you as the Guest are fully aware of what is or what
        is not allowed within the space and what are the expectations of using
        that space for the host. In additional good news for our Guests, no
        credit cards are charged until the host follows through and approves the
        booking.
        <br></br>
        <div style={{ textAlign: 'center', marginTop: 12 }}>
          <img
            src={verfiedIconImg}
            alt=""
            style={{ width: 60, objectFit: 'contain' }}
          />
        </div>
        <br></br>
        Lastly, look for the Venuely Verified checkmark when selecting your
        venue. The Venuely Verified is a status checkmark letting potential
        Guests know that the space photos, measurements, and amenities have all
        been Verified by Venuely's partners. This gives the Guests confidence in
        knowing that your space is exactly as it was described.
      </>
    ),
  },
  {
    header: ` I'm a Guest outside of NY, NJ, or IN, how can Venuely support me?`,
    body: (
      <>
        As a Guest outside of the NY, NJ, or IN area, Venuely may not have
        spaces for you to browse in your area. However, as the number of Venuely
        hosts grows, so does the possibility that one of our hosts is in your
        area! We are also open to starting conversations with hosts or Guests
        outside of NY, NJ, and IN about how to get more Venuely in your area.
        Venuely is free for our hosts and Guests to join at any time. The
        purpose of our site is twofold. To help our hosts by providing a
        platform where they can post the spaces they have to share with outside
        users and help potential Guests in any given region with a variety of
        spaces to choose from for their upcoming event needs. Venuely keeps your
        financial information safe via our secure Stripe payment system and
        gives Guests confidence that they are supporting hosts in their area who
        have missions they wish to support.
        <strong>
          <br></br>
        </strong>
        <br></br>
      </>
    ),
  },
]

export { RenterFAQ }
