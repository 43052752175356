import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Button1 from '../buttons/Button1'
import checkIcon from '../../img/check.svg'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Spin, message as Message } from 'antd'

export default function CheckoutForm({
  setPaymentIntentId,
  setIsStripeModal,
  message,
  setSavedCreditCard,
}) {
  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)
  const baseURL = process.env.REACT_APP_BASE_URL
  const [authorized, setAuthorized] = useState(false)
  const [showBtn, setShowBtn] = useState(false)

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: 'http://localhost:3000/booking',
        return_url: `${baseURL}/booking`,
      },
      redirect: 'if_required',
    })
    if (result.error) {
      // console.log(result?.error)
      Message.error('Something went wrong')
    } else {
      setSavedCreditCard(false)
      console.log('PAYMENT_INTENT_ID', result?.paymentIntent.id)
      setPaymentIntentId(result?.paymentIntent.id)
      setAuthorized(true)
      setIsStripeModal(false)
      Message.success('Successfully authorized')
    }
    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {authorized ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={checkIcon} alt="" />
          <div id="payment-message" style={{ fontSize: 16, margin: 5 }}>
            {message}
          </div>
        </div>
      ) : (
        <>
          <PaymentElement
            id="payment-element"
            onReady={() => {
              setShowBtn(true)
            }}
          />
          {showBtn && (
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? (
                  <Spin style={{ marginTop: 20 }} />
                ) : (
                  <button
                    className="btn__1"
                    style={{
                      border: '1px #6567e6e6 solid',
                      color: '#fff',
                      fontSize: 16,
                      width: 260,
                      marginTop: 20,
                    }}
                  >
                    Authorize Payment
                  </button>
                )}
              </span>
            </button>
          )}
        </>
      )}
    </form>
  )
}
