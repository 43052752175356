import { ComponentActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setBanner = (requiredData) => async (dispatch) => {
  dispatch({
    type: ComponentActionType.SET_BANNER,
    payload: { ...requiredData },
  })
  requiredData.callBack()
}

export const setGuestRequirments = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.get('/host/global-variables').catch((err) => {
    return err.response
  })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ComponentActionType.FATCH_DATA,
  })
}

export const getICSFileData = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/ics', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ComponentActionType.FATCH_DATA,
  })
}
