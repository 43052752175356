import React, { useState, useEffect } from 'react'
import polygon_1 from './../img/polygon_1.png'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from 'antd'
import moment from 'moment'
import {
  createWindowBookingRole,
  deleteWindowBookingRole,
  updateWindowBookingRole,
} from '../redux/actions/WindowBookingRoleAction'
import { setInformation } from '../redux/actions/InformationAction'
import { setSpace } from '../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import {
  deleteBlockedDate,
  setBlockedDate,
  updateBlockedDate,
} from '../redux/actions/BlockedDateAction'
import { getAllInputValue } from '../redux/requests'
import calendarImage from './../img/calender.png'
export default function SingleBlockedDate({
  setData,
  properties,
  blocked_date,
}) {
  let dispatch = useDispatch()
  // const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState(blocked_date.start_date)
  const [endDate, setEndDate] = useState(blocked_date.end_date)
  const [selected_properties, set_selected_properties] = useState(
    blocked_date.properties,
  )
  const [showPropertyListForBlockeddate, setShowPropertyListForBlockeddate] =
    useState(false)
  const [deleteBlockedDateConfirmButton, setDeleteBlockedDateConfirmButton] =
    useState(false)
  let handleFormChange = (e,type = null,date = null) => {
    let form = document.getElementById(
      `${blocked_date.id}_blocked_date_select_form`,
    )
    let values = {
      id: blocked_date.id,
      ...(properties.length == 1 ? {properties: [properties[0].id]}: {}),
      ...(type == 'start' ? {start_date:date} : {start_date:startDate}),
      ...(type == 'end' ? {end_date:date} : {end_date:endDate}),
    }
    
    if(properties.length > 1){
      let selected_property = []
      for (let el in form.elements.properties) {
        if (form.elements.properties[el].checked != undefined) {
          if (form.elements.properties[el].checked) {
            selected_property.push(parseInt(form.elements.properties[el].value))
          }
        }
      }
      values.properties = selected_property
    }

    dispatch(
      updateBlockedDate({
        ...values,
        callBack: (response) => {
          setData()
        },
      }),
    )
  }

  // Whenever start or end date selected
  const updateDB = (dateType, dateString) => {
    let form = document.getElementById(
      `${blocked_date.id}_blocked_date_select_form`,
    )
    let values = {}
    let selected_property = []
    
    for (const element in form.elements) {
      if (isNaN(parseInt(element))) {
        if (form.elements[element].value != undefined) {
          values[element] = form.elements[element].value
        }
      }
    }

    for (let el in form.elements.properties) {
      if (form.elements.properties[el].checked != undefined) {
        if (form.elements.properties[el].checked) {
          selected_property.push(parseInt(form.elements.properties[el].value))
        }
      }
    }
     
    values.properties = selected_property

    dispatch(
      updateBlockedDate({
        id: blocked_date.id,
        ...values,
        ...(dateType === 'start'
          ? {
              start_date: dateString,
              ...(endDate !== null ? { end_date: endDate } : {}), // end date could exists or could not
            }
          : {
              start_date: startDate, // If end date is exists then start date will must exists
              end_date: dateString,
            }),
        callBack: (response) => {
          setData()
        },
      }),
    )
  }
  let handleDelete = () => {
    dispatch(
      deleteBlockedDate({
        id: blocked_date.id,
        callBack: (response) => {
          setData()
        },
      }),
    )
  }

  return (
    <div className="single-blocked-date-wrapper">
      <NotificationContainer />
      <button
        onFocusCapture={() => setDeleteBlockedDateConfirmButton(true)}
        onBlurCapture={() => setDeleteBlockedDateConfirmButton(false)}
        type="button"
        className="delete-blocked-date"
      >
        X
        <div
          onClick={handleDelete}
          className={`delete-blocked-date-confirm-button ${
            deleteBlockedDateConfirmButton ? '' : 'hide-elements'
          }`}
        >
          Delete
        </div>
      </button>
      <form
        onChange={handleFormChange}
        id={`${blocked_date.id}_blocked_date_select_form`}
        className="single-blocked-date"
      >
        {/* Old date input element below */}
        {/* <input
          name="start_date"
          onChange={(e) => {
            setStartDate(e.target.value)
          }}
          type="date"
          value={startDate}
        /> */}

        <DatePicker
          defaultValue={startDate ? moment(startDate, 'YYYY-MM-DD') : null}
          onChange={(date, dateString) => {
            setStartDate(dateString)
            handleFormChange('','start', dateString)
            
          }}
          style={styles.customDatePicker}
        />
        <span style={{ fontSize: 16 }}>to</span>
        {/* <img src={calendarImage} style = {{
                        position: "absolute",
                        left: "30.9rem",
                        top: "3.9rem"
                }} alt="" srcSet="" /> */}

        {/* Old date input element below */}
        {/* <input
          name="end_date"
          placeholder="dd-mm-yyyy"
          onChange={(e) => {
            setEndDate(e.target.value)
          }}
          type="date"
          value={endDate}
        /> */}
        <DatePicker
          defaultValue={endDate ? moment(endDate, 'YYYY-MM-DD') : null}
          onChange={(date, dateString) => {
            setEndDate(dateString)
            // Check if end date is less than start date
            if (dateString && startDate) {
              let start = new Date(startDate)
              let end = new Date(dateString)
              if (end < start) {
                NotificationManager.warning(
                  `end date must be greater then start date`,
                  'date',
                  3000,
                )
                // form.elements.end_date.value = endDate // no need here
                return null
              }
            }
            // updateDB('end', dateString)
            handleFormChange('','end', dateString)
          }}
          style={styles.customDatePicker}
        />
        <button
          onFocusCapture={() => setShowPropertyListForBlockeddate(true)}
          onBlurCapture={() => setShowPropertyListForBlockeddate(false)}
          type="button"
          className="property-list"
        >
          {selected_properties.length > 0
            ? 'selecte space'
            : 'no space selected'}
          <div
            className={`property-list-for-select ${
              showPropertyListForBlockeddate ? '' : 'hide-elements'
            }`}
          >
            <ul>
              {properties &&
                properties.map((property) => {
                  return (
                    <li>
                      <input
                        onChange={(e) => {
                          let data = properties.find(
                            (p) => p.id === parseInt(e.target.value),
                          )
                          let prev_data = selected_properties
                          let index = prev_data.findIndex(
                            (p) => p.id === parseInt(e.target.value),
                          )

                          if (index !== -1) {
                            prev_data.splice(index, 1)
                            set_selected_properties([...prev_data])
                          }

                          if (index == -1) {
                            let new_array = [...selected_properties, data]
                            set_selected_properties(new_array)
                          }
                        }}
                        type="checkbox"
                        name="properties"
                        className="hide-elements"
                        id={`${property.id}_property_blocked_date_${blocked_date.id}`}
                        value={property.id}
                        checked={
                          selected_properties.length > 0
                            ? selected_properties.find(
                                (p) => p.id === property.id,
                              ) != undefined
                            : false
                        }
                      />
                      <label
                        htmlFor={`${property.id}_property_blocked_date_${blocked_date.id}`}
                      >
                        <div className="fake-chake-box-blocked-date">
                          <div
                            className={
                              selected_properties.length > 0
                                ? selected_properties.find(
                                    (p) => p.id === property.id,
                                  ) != undefined
                                  ? ''
                                  : 'hide-elements'
                                : 'hide-elements'
                            }
                          >
                            ✔
                          </div>
                        </div>
                        {property.name}
                      </label>
                    </li>
                  )
                })}
            </ul>
          </div>
        </button>
      </form>
    </div>
  )
}

const styles = {
  customDatePicker: {
    margin: `0px 27px 0px 27px`,
    padding: '8px 12px',
    borderRadius: 3,
    border: '',
    paddingTop: 10,
    height: 42,
  },
}
