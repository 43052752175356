import { BlockedDateActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setBlockedDate = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/host/space/blocked-date')
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BlockedDateActionType.SET_BLOCKED_DATE,
    payload: {},
  })
}

export const selectBlockedDate = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/blocked-date/${requiredData.id}/`)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BlockedDateActionType.SELECT_BLOCKED_DATE,
    payload: {},
  })
}

export const createBlockedDate = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space/blocked-date/', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BlockedDateActionType.CREATE_BLOCKED_DATE,
    payload: {},
  })
}

export const updateBlockedDate = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/blocked-date/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })
  // console.log(requiredData);
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BlockedDateActionType.UPDATE_BLOCKED_DATE,
    payload: {},
  })
}

export const deleteBlockedDate = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(`/host/space/blocked-date/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BlockedDateActionType.REMOVE_BLOCKED_DATE,
    // payload:response.data
  })
}
