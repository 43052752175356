import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactCodeInput from 'react-verification-code-input'
import { message } from 'antd'
import { TopMsg } from '../../components/Topmsg'
import { data, privetData, handleErrorAndDispatch } from '../../redux/requests'
import { resendVerifyPhoneCode } from '../../redux/actions/AuthAction'

function EditPhoneModal({ props }) {
  const {
    handleClosePopUp,
    setHostPhone,
    hostPhone,
    updateInformation,
    setData,
    setInformation,
  } = props
  const dispatch = useDispatch()

  const [showPhoneNumberUpdateLoader, setShowPhoneNumberUpdateLoader] =
    useState(false)
  const [showVerifyInfo, setShowVerifyInfo] = useState(false) // to change the content of modal
  const [verificationCode, setVerificationCode] = useState(null)

  const updatePhoneNumber = async () => {
    if (hostPhone.length == 10) {
      setShowPhoneNumberUpdateLoader(true)
      dispatch(
        updateInformation({
          personal_information: { phone: '1' + hostPhone },
          callBack: (response) => {
            setShowPhoneNumberUpdateLoader(false)
            if (response.status != 200) {
              // document.getElementById(
              //   'change_phone_number_error_message',
              // ).innerHTML = response.data[0]
              // document.getElementById('change_phone_number').style.borderColor =
              //   'red'
              message.error(
                TopMsg(
                  response.data[0] ? response.data[0] : 'Something is wrong',
                ),
              )
            }
            if (response.status == 200) {
              try {
                // alert(response.status)
                // document.getElementById(
                //   'change_phone_number_error_message',
                // ).innerHTML = ''
                // document.getElementById(
                //   'change_phone_number',
                // ).style.borderColor = 'black'

                // When database updated, send the SMS this phone number
                const payload = {
                  phone: '1' + hostPhone,
                  email: props.hostEmail,
                  type: 'HOST',
                }
                console.log(payload)

                dispatch(
                  resendVerifyPhoneCode(
                    {
                      callBack: (response) => {
                        console.log(response)
                        message.success(
                          TopMsg('Verification code resend to your mobile.'),
                        )
                      },
                    },
                    payload,
                  ),
                )

                setData(response.data.data)
                // handleClosePopUp('phone-uploade')
                setShowVerifyInfo(true)
              } catch (err) {
                console.log(err)
              }
            }
          },
        }),
      )
    }
  }

  const verifyCode = async () => {
    if (hostPhone.includes('+') || !hostPhone) return
    try {
      const payload = {
        type: 'Host',
        email: props.hostEmail,
        phone: '1' + hostPhone,
        token: verificationCode,
      }
      console.log(payload)

      let request = await privetData()
      const response = await request
        .post(`/user/verify-phone`, payload)
        .catch((err) => {
          return err.response
        })
      console.log(response)

      if (response.status === 406) {
        message.error(
          TopMsg('Wrong code, pelase try again or resend the code.'),
        )
        return
      }

      message.success(TopMsg('Phone info verified.'))
      setShowVerifyInfo(false) // to show verify info

      // To update verifed info at left
      dispatch(
        setInformation({
          callBack: (response) => {
            setData(response.data)
          },
        }),
      )

      props.setShowPhoneUploadPopUpBox(false)
    } catch (err) {
      console.log(err)
      message.error(TopMsg('Wrong code, pelase try again or resend the code.'))
    }
  }

  return (
    <div className="pop-up-wrapper">
      <div className="pop-up-card">
        <div className="profile-pop-up-card-header">
          <div
            onClick={() => {
              handleClosePopUp('phone-uploade')
            }}
            className="profile-pop-up-close"
          >
            x
          </div>
          <h4 style={{ paddingRight: '70px', fontSize: 20, marginTop: 10 }}>
            Add Phone Number
          </h4>
        </div>
        <div className="profile-pop-up-card-body">
          <h4>
            {!showVerifyInfo
              ? `Add Phone Number`
              : `Please enter verification code`}
          </h4>
          <p>
            {!showVerifyInfo
              ? `This is so your Host, Guests, or Venuely can reach you.`
              : `Please enter 5 digit code sent to ${hostPhone}`}
          </p>

          {!showVerifyInfo ? (
            <form className="change-phone-number-input-group-list">
              <div className="change-phone-number-input-group">
                <label htmlFor="change_country">Country</label>
                <input
                  id="change_country"
                  type="text"
                  value={'United States (+1)'}
                />
              </div>
              <div className="change-phone-number-input-group">
                <label htmlFor="change_phone_number">Phone Number</label>
                <input
                  id="change_phone_number"
                  type="number"
                  onChange={(e) => {
                    setHostPhone(e.target.value)
                  }}
                  value={hostPhone}
                />
                {/* <p
                  style={{ color: 'red' }}
                  id="change_phone_number_error_message"
                ></p> */}
              </div>
              <p style={{ textAlign: 'left', marginTop: '8px' }}>
                We’ll call or text you to confirm your number. Standard message
                and data rates apply.
              </p>
              <div
                onClick={() => {
                  updatePhoneNumber()
                }}
                style={{ width: '616px', position: 'relative' }}
                className={`profile-pop-up-footer-button profile-pop-up-footer-button${
                  hostPhone.length == 10 ? '-enable' : '-disable'
                }-fill`}
              >
                {showPhoneNumberUpdateLoader ? (
                  <div
                    style={{
                      height: '30px',
                      width: '30px',
                      top: '7px',
                      left: '23rem',
                    }}
                    className="loader"
                  ></div>
                ) : (
                  'Continue'
                )}
              </div>
            </form>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ fontSize: 18, fontWeight: 600 }}>
                Enter verification code
              </p>
              <ReactCodeInput
                fields={5}
                onChange={(val) => {
                  console.log(val)
                  setVerificationCode(val)
                }}
              />
              <div
                style={{ width: '616px', position: 'relative', marginTop: 30 }}
                className={`profile-pop-up-footer-button profile-pop-up-footer-button${
                  hostPhone.length == 10 ? '-enable' : '-disable'
                }-fill`}
                onClick={() => verifyCode()}
              >
                <label style={{ fontSize: 16, marginTop: 12 }}>
                  Confirm Code
                </label>
              </div>

              <p
                style={{
                  marginTop: 30,
                  textAlign: 'center',
                  fontSize: 16,
                }}
              >
                Didn’t get a code?
              </p>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  color: '#656369',
                }}
              >
                If you don’t receive your link in 1 minutes, <br />
                click{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    fontSize: 14,
                    color: '#656369',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    props.reSendPhoneCode()
                  }}
                >
                  resend a code.
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditPhoneModal
