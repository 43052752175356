import React, { useEffect, useRef, useState } from 'react'
import '../style/landingPage/landing_normalize.css'
import '../style/landingPage/landing_webflow.css'
import '../style/landingPage/landing_page.css'
import { useLocation, useNavigate } from 'react-router-dom'
import RegistrationForm from '../components/registration/RegistrationForm'
import SelectUser from '../components/login/SelectUser'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { me, refresh } from '../redux/actions/AuthAction'
import useInterval from '@use-it/interval'
import useScript from '../hooks/useScript'
import SearchOne from '../components/searchBoxes/SearchOne'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { message, notification, Button } from 'antd'
import DropDownMenu from '../components/menus/DropDownMenu'
import useWindowDimensions from '../hooks/useWindowDimensions'
import mediaImg800 from './images/fill-your-congregation-down-time-p-500.jpeg'
import LandingFAQ from '../components/FAQ/LandingFAQ'
import { PhoneOutlined } from '@ant-design/icons'

const BecomeHost = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state
  const faqRef = useRef(null)
  useEffect(() => {
    if (locationState && locationState.FAQ) {
      const faqRefElem = faqRef.current
      faqRefElem.scrollIntoView()
    } else {
      document.body.scroll(0, 0)
    }
  }, [location.state])

  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const authUser = useSelector((state) => state.auth.user)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [newsletterName, setNewsletterName] = useState(null)
  const [newsletterEmail, setNewsletterEmail] = useState(null)
  const [newsletterAddress, setNewsletterAddress] = useState(null)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const { width } = useWindowDimensions()

  useInterval(() => {
    if (isauthenticated) {
      dispatch(
        refresh({
          callBack: (response) => {
            // console.log(response)
          },
        }),
      )
    }
  }, 360000)

  const dispatch = useDispatch()

  const baseURL = process.env.REACT_APP_BASE_URL
  const errorMsg = (err) => (
    <label style={{ fontSize: 12, textTransform: 'uppercase' }}>{err}</label>
  )

  const submitNewsLetter = (e) => {
    e.preventDefault()
    const cookies = new Cookies()
    const authData = cookies.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    const data = {
      name: newsletterName,
      email: newsletterEmail,
      address: newsletterAddress,
    }
    axios
      .post(`${baseURL}/user/subscribe`, data, config)
      .then((res) => {
        if (res.status === 200) {
          message.success(res.data)
        }
      })
      .catch((err) => {
        message.error(
          err ? errorMsg(err.response.data) : 'Something went wrong',
        )
      })
  }
  const MAX_WIDTH = 1000
  const NOT_SUPPORTED_MSG =
    'Venuely is currently not supported on mobile. Please sign up using your desktop device.'
  const userSignUp = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(false)
    setShowRegistrationForm(!showRegistrationForm)
  }
  const userSignIn = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(!showLoginForm)
  }
  const startSearch = () => {
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    navigate('/renter/search')
  }

  return (
    <>
      <div
        className={`singn-up-form ${
          showRegistrationForm ? '' : 'hide-elements'
        }`}
      >
        <RegistrationForm closeRegistrationFormCart={setShowRegistrationForm} />
      </div>
      <div className={`singn-in-form ${showLoginForm ? '' : 'hide-elements'}`}>
        <SelectUser closeRegistrationFormCart={setShowLoginForm} />
      </div>

      {width < 480 ? (
        <div style={styles.navContainerMobile}>
          <Link to={'/'}>
            <img
              src="/venuely.svg"
              alt=""
              className="nav-logo"
              // onClick={(event) => {
              //   if (event.ctrlKey || event.metaKey) {
              //     window.open(window.location.host, '_blank')
              //   }
              // }}
            />
          </Link>
        </div>
      ) : (
        <div style={styles.navContainer}>
          <Link to={'/'}>
            <img src="/venuely.svg" alt="" className="nav-logo" />
          </Link>
          <SearchOne />

          <div style={{ alignItems: 'center', display: 'flex' }}>
            <h3 className="link_text" onClick={() => navigate('/')}>
              Become a guest
            </h3>
            <div>
              {!isauthenticated ? (
                <>
                  <div className="right__side__links">
                    <h3 className="link_text" onClick={userSignUp}>
                      Sign up
                    </h3>
                    <h3 className="link_text" onClick={userSignIn}>
                      Sign in
                    </h3>
                  </div>
                </>
              ) : (
                <DropDownMenu
                  showDropdownMenu={showDropdownMenu}
                  setShowDropdownMenu={setShowDropdownMenu}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="hero-section-v2 wf-section">
        <div className="_wf-hero-section-1 hero-background-image">
          <div className="_wf-hero-container-2-white background-image-text-container w-container">
            <div
              data-w-id="6145e8ff-4b45-517f-f6ec-ee5d30e52dfb"
              className="hero-section-text-with-background-image"
            >
              <h1 className="header-2 hero _01">
                Monetize Your Congregation&#x27;s Space
              </h1>
              <h1 className="header-2 hero _02">
                Put Your Congregation&#x27;s Space to Work
              </h1>
              <h2 className="subheader white hero">
                Earn funds every month simply by sharing your
                congregation&#x27;s extra space during downtime. Hosting with
                Venuely is free. No Startup cost.
              </h2>
              <a
                href="#"
                data-w-id="6145e8ff-4b45-517f-f6ec-ee5d30e52e00"
                className="_wf-normal-button w-button"
                onClick={userSignUp}
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process">
        <div className="container-large">
          <div className="title-wrapper">
            <div
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f22d"
              className="title-large"
            >
              PROCESS
            </div>
            <h3
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f22f"
              className="h3-heading"
            >
              Our Simple 4 Step Process
            </h3>
          </div>
          <div className="w-layout-grid grid-process">
            <div
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f232"
              className="card-process"
            >
              <div className="_4-step-div-horizontal">
                <div className="process-icon">
                  <div style={{ fontSize: 24 }}>1</div>
                </div>
                <img
                  src="images/Venuely-Landing-Page-Icons-01.png"
                  loading="lazy"
                  width="98"
                  alt=""
                  className="process-icon-drawing"
                />
              </div>
              <div className="heading-large">Login or Sign Up As a Host</div>
              <p className="paragraph-standard">
                Sign up in no time! It takes less than a minute to sign up as
                Venuely host
              </p>
            </div>
            <div
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f23a"
              className="card-process"
            >
              <div className="_4-step-div-horizontal">
                <div className="process-icon">
                  <div style={{ fontSize: 24 }}>2</div>
                </div>
                <img
                  src="images/Venuely-Landing-Page-Icons-02.png"
                  loading="lazy"
                  alt=""
                  className="process-icon-drawing"
                />
              </div>
              <div className="heading-large">
                Add Your Space &amp; Verify Account
              </div>
              <p className="paragraph-standard">
                Add your first space listing, and verify your account to be able
                to publish
              </p>
            </div>
            <div
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f242"
              className="card-process"
            >
              <div className="_4-step-div-horizontal">
                <div className="process-icon">
                  <div style={{ fontSize: 24 }}>3</div>
                </div>
                <img
                  src="images/Venuely-Landing-Page-Icons-03.png"
                  loading="lazy"
                  alt=""
                  className="process-icon-drawing"
                />
              </div>
              <div className="heading-large">
                Host Events &amp; Welcome the Guests{' '}
              </div>
              <p className="paragraph-standard">
                Host only the type of events you would like in your space(s)
              </p>
            </div>
            <div
              data-w-id="9c2fc5bd-8854-f9c9-3361-79465a49f24a"
              className="card-process"
            >
              <div className="_4-step-div-horizontal">
                <div className="process-icon">
                  <div style={{ fontSize: 24 }}>4</div>
                </div>
                <img
                  src="images/Venuely-Landing-Page-Icons-04.png"
                  loading="lazy"
                  alt=""
                  className="process-icon-drawing"
                />
              </div>
              <div className="heading-large">Get Your Funds Every Time</div>
              <p className="paragraph-standard">
                The accumulated funds will be sent to your connected bank
                account every 14 days
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="benefit-section">
        <div className="_wf-content-section">
          <div className="_wf-content-video-container-2 w-container">
            <div
              data-w-id="8ed11157-ecc9-c3dd-0ae9-5d99e6730670"
              className="_wf-content-image"
            ></div>
            <div className="image-text image-text-on-the-right">
              <div
                data-w-id="8ed11157-ecc9-c3dd-0ae9-5d99e6730672"
                className="_wf-features-item-left-aligned image-content-text"
              >
                <div className="feature-header _2nd">
                  A platform for serving your community in a different way
                </div>
                <p className="features-text">
                  We understand that religious congregations play important
                  roles in our communities. Optimize your spaces to provide
                  physical infrastructure and complex social networks that can
                  be leveraged for a wide range of issues, host creative
                  activities, and, create extra funds to support your missions
                  further.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-cta-layout-v1">
        <div className="_wf-hero-section-1 white-hero-section">
          <div className="_wf-hero-container-2-blue w-container">
            <div data-w-id="539a184a-95c6-3ce6-71b3-8e17397ad003">
              <div className="fill__area">
                <img src={mediaImg800} alt="" />
                <div>
                  <h1 className="header-2">
                    Fill your congregation space&#x27;s downtime
                  </h1>
                  <h6 className="features-text">
                    And earn money every month. Utilize your space to create
                    savings that can be redirected to mission oriented projects.
                  </h6>
                </div>
              </div>
            </div>
            {/* <img
              src="images/fill-your-congregation-down-time.jpeg"
              data-w-id="539a184a-95c6-3ce6-71b3-8e17397ad00a"
              sizes="(max-width: 767px) 92vw, (max-width: 991px) 459.421875px, (max-width: 1279px) 479.296875px, 504.84375px"
              srcSet="images/fill-your-congregation-down-time-p-500.jpeg 500w, images/fill-your-congregation-down-time-p-800.jpeg 800w, images/fill-your-congregation-down-time-p-1080.jpeg 1080w, images/fill-your-congregation-down-time.jpeg 1200w"
              alt=""
              className="_wf-hero-image-2"
            /> */}
          </div>
        </div>
      </div>
      <div className="feature-section _01">
        <div className="_wf-content-section _02">
          <div className="_wf-hero-container-blue w-container">
            <div className="content-header-wrapper"></div>
          </div>
          <div className="_wf-checklist-container w-container">
            <div className="checklist-main-text">
              <div>
                <div
                  data-w-id="83419869-a247-6877-7907-5f642850db7b"
                  className="checklist-main-header"
                >
                  Fully control who, what, when & how's of hosting in your
                  space.
                </div>
                <p
                  data-w-id="83419869-a247-6877-7907-5f642850db7d"
                  className="features-subheader"
                >
                  Guests must agree with your house rules & policies If the
                  guests don't agree with your house rules, they simply won't be
                  able to move forward with the check out process and won't be
                  able to make booking reservations.
                </p>
              </div>
              <img
                src="images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand.jpg"
                loading="lazy"
                width="503"
                data-w-id="86db18a4-dcbd-d722-a4b3-814130694eb5"
                srcSet="images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand-p-500.jpeg 500w, images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand-p-800.jpeg 800w, images/brunette-woman-typing-email-laptop-computer-while-sitting-home-selective-focus-hand.jpg 1000w"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 79vw, (max-width: 991px) 362.25px, 485.203125px"
                alt=""
                className="feature-image-02"
              />
            </div>
            <div className="checklist-wrapper">
              <div
                data-w-id="83419869-a247-6877-7907-5f642850db80"
                className="_wf-checklist benefit"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--01.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">
                    List &amp; manage multiple spaces within your congregations
                  </div>
                  <p className="features-text">
                    You would like to list more than one space in your
                    congregation? No problem. You can add as many spaces within
                    your building and manage each space with just one account.
                  </p>
                </div>
              </div>
              <div
                data-w-id="83419869-a247-6877-7907-5f642850db87"
                className="_wf-checklist benefit"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--02.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">
                    Guests must agree with your house rules & policies
                  </div>
                  <p className="features-text">
                    If the Guests don't agree with your house rules, they simply
                    won't be able to move forward with the check out process and
                    won't be able to make booking reservations.
                  </p>
                </div>
              </div>
              <div
                data-w-id="83419869-a247-6877-7907-5f642850db8e"
                className="_wf-checklist benefit"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--03.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">
                    Only allow activities that work well in your space
                  </div>
                  <p className="features-text">
                    You can opt out on activities that you don&#x27;t think
                    it&#x27;s a good fit for your space(s) and those listings
                    won&#x27;t be visible on the search results. Additionally,
                    you can always deny any reservation requests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-section">
        <div className="_wf-content-section">
          <div className="w-container">
            <div className="hero-block">
              <div className="content-hero"></div>
            </div>
            <div className="title-wrapper">
              <div
                data-w-id="5eab5fdd-dd20-8d96-ab41-678e7753a570"
                className="title-large"
              >
                Features
              </div>
              <h3
                data-w-id="5eab5fdd-dd20-8d96-ab41-678e7753a572"
                className="h3-heading"
              >
                Our Core Features
              </h3>
            </div>
          </div>
          <div className="_wf-hero-container-blue w-container">
            <div className="content-header-wrapper"></div>
          </div>
          <div className="_wf-checklist-container w-container">
            <div className="double-checklist-2">
              <div
                data-w-id="ec52df3c-3e26-d590-cc92-c3942590a56b"
                className="_wf-checklist _01"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--04.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">Automation Rules</div>
                  <p className="features-text">
                    Your house of faith, your rules. Just set them once and our
                    system will apply the rules you have to your space(s).{' '}
                  </p>
                </div>
              </div>
              <div
                data-w-id="ec52df3c-3e26-d590-cc92-c3942590a572"
                className="_wf-checklist _03"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--07.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">Online Payments</div>
                  <p className="features-text">
                    Easily connect to your bank account and get paid out with
                    ease. We partner with Stripe and Stripe has been audited by
                    a PCI-certified auditor and is certified to PCI Service
                    Provider Level 1. This is the most stringent level of
                    certification available in the payments industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="double-checklist-1">
              <div
                data-w-id="ec52df3c-3e26-d590-cc92-c3942590a555"
                className="_wf-checklist _02"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--05.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">
                    Flexible Listing Customization
                  </div>
                  <p className="features-text">
                    Every space is unique. You can easily customize photos,
                    availability, activities and pricing.
                  </p>
                </div>
              </div>
              <div
                data-w-id="ec52df3c-3e26-d590-cc92-c3942590a55c"
                className="_wf-checklist _04"
              >
                <img
                  src="images/Veneuly-Website-Icons-features_bigger--06.png"
                  height="140"
                  alt=""
                  className="checlist-image"
                />
                <div className="_wf-checklist-text">
                  <div className="_wf-checklist-titile">In App Messaging</div>
                  <p className="features-text">
                    You will be able to communicate directly with the Guests
                    using Venuely messages. It will be an easy way to answer
                    inquiries, questions, and also communicate your
                    expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="final-cta-sign-up-section">
        <div className="_wf-content-section">
          <div className="_wf-hero-container-blue w-container">
            <div className="content-header-wrapper">
              <img
                src="images/judson-church-dance.jpeg"
                height="140"
                data-w-id="59b08a08-8dcf-ea31-dac3-f2ffc7deb9d3"
                sizes="(max-width: 479px) 100vw, (max-width: 991px) 213.328125px, 800px"
                srcSet="images/judson-church-dance-p-500.jpeg 500w, images/judson-church-dance-p-800.jpeg 800w, 
          images/judson-church-dance-p-1080.jpeg 1080w, images/judson-church-dance.jpeg 1280w"
                alt=""
                className="feature-image"
              />
              <h1
                data-w-id="59b08a08-8dcf-ea31-dac3-f2ffc7deb9d4"
                className="_wf-content-header cta"
                style={{ fontWeight: 'bold' }}
              >
                Create Your Free Venuely Account Today
              </h1>
            </div>
          </div>
          <div className="grid-wrapper">
            <div
              className="features-grid w-container"
              style={{ marginTop: 40 }}
            >
              <div
                data-w-id="59b08a08-8dcf-ea31-dac3-f2ffc7deb9d8"
                className="_wf-features-item-left-aligned"
              >
                <div className="feature-header">No startup cost</div>
                <p className="features-text">
                  We don't ask for any payment up-front for our users to start
                  using and earning from Venuely. Just sign up and start today.
                </p>
              </div>
              <div
                data-w-id="59b08a08-8dcf-ea31-dac3-f2ffc7deb9dd"
                className="_wf-features-item-left-aligned"
              >
                <div className="feature-header">One time setup</div>
                <p className="features-text">
                  You can add your space, get approved by our team and
                  immediately can start accepting reservations.{' '}
                </p>
              </div>
              <div
                data-w-id="59b08a08-8dcf-ea31-dac3-f2ffc7deb9e2"
                className="_wf-features-item-left-aligned"
              >
                <div className="feature-header">Funds every 2 weeks</div>
                <p className="features-text">
                  Get rewarded by filling your congregation&#x27;s downtime. And
                  support your mission further with the extra income.
                </p>
              </div>
            </div>
          </div>
          <div className="buttons-wrapper">
            <a
              href="#"
              data-w-id="3e4795bb-90a1-efd5-fa10-84c73e086460"
              className="_wf-normal-button white-button w-button"
              style={{ fontWeight: '400' }}
              onClick={userSignUp}
            >
              Get Started Today!
            </a>
          </div>
        </div>
      </div>
      <div ref={faqRef}>
        <LandingFAQ />
      </div>
      <div className="consultation-section wf-section">
        <div className="_wf-hero-container-2-blue w-container">
          <div
            data-w-id="0b3ea087-b89c-daf5-77d7-7097d2a7d4cf"
            className="consultation-contain"
          >
            <img
              src="images/7055170_discussion_consultation_communication_meeting_conversation_icon.png"
              loading="lazy"
              width="100"
              alt=""
              className="consultation-icon"
            />
            <h3 className="header-2 consultation">Need a consultation?</h3>
            <h6 className="subheader">
              Our team is here to help. Tell us about your congregation and your
              space, and our team will reach out with a helping hand.
              <br />
              <br />
              <span className="text-span-2" style={{ fontSize: 18 }}>
                hello@venuely.org
              </span>
              {/* <p className="text-span-landing-contact">
                <a href="tel:(973) 544-8297">
                  <span
                    className="text-span-landing-contact"
                    style={{ fontSize: 18 }}
                  >
                    (973) 544-8297
                  </span>
                </a>
              </p> */}
            </h6>
          </div>
        </div>
      </div>
      <div
        data-w-id="a7bb0a66-8c14-7999-3312-fe2191d6cc37"
        className="footer-section"
      >
        <div className="footer-section">
          <div className="footer-container w-container">
            <div className="footer-column">
              {/* <div className="footer-column-name">CATEGORIES</div> */}
              <a onClick={startSearch} className="footer-link">
                Home
              </a>
              <a className="footer-link" onClick={userSignUp}>
                Sign up
              </a>
              <a className="footer-link" onClick={userSignIn}>
                Log in
              </a>
              <a
                href="mailto:recipient@example.com"
                target="_blank"
                style={{ fontSize: 18, marginTop: 12 }}
              >
                hello@venuely.org
              </a>
              <a href="#" className="footer-link"></a>
            </div>
            <div className="footer-column">
              {/* <div className="footer-column-name">INFO</div> */}
              <Link to="/faq" target="_blank" className="footer-link">
                FAQ
              </Link>
              <Link
                to="/privacy-policy"
                target="_blank"
                className="footer-link"
              >
                Privacy Policy
              </Link>
              <Link to="/terms-of-use" target="_blank" className="footer-link">
                Terms of Use
              </Link>
            </div>
            <div className="footer-column email-culumn">
              <div className="footer-column-name">
                PRODUCT UPDATE NEWSLETTER
              </div>
              <div id="Product-Update-Newsletter" className="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  className="_wf-form"
                >
                  <input
                    type="text"
                    className="text-field name w-input"
                    maxLength="256"
                    name="Name"
                    data-name="Name"
                    placeholder="Enter your name"
                    value={newsletterName}
                    onChange={(e) => setNewsletterName(e.target.value)}
                    id="Name-5"
                    required
                  />
                  <input
                    type="email"
                    className="text-field w-input"
                    maxLength="256"
                    name="Email"
                    data-name="Email"
                    placeholder="Enter your email"
                    value={newsletterEmail}
                    onChange={(e) => setNewsletterEmail(e.target.value)}
                    id="Email"
                    required
                  />
                  <input
                    type="address"
                    className="text-field w-input"
                    maxLength="256"
                    name="Address"
                    data-name="Address"
                    placeholder="Enter your city"
                    value={newsletterAddress}
                    onChange={(e) => setNewsletterAddress(e.target.value)}
                    id="Address"
                    required
                  />
                  <input
                    type="submit"
                    onClick={submitNewsLetter}
                    value="Submit"
                    data-wait="Please wait..."
                    className="_wf-outline-button white-outline 
            submit w-button"
                  />
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              {/* <div className="date">We value your privacy.</div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BecomeHost

const styles = {
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 120px',
    alignItems: 'center',
  },
  navContainerMobile: {
    margin: '30px 20px',
  },
}
