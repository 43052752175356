import useInterval from '@use-it/interval'
import { useDispatch } from 'react-redux'
import HeaderBar from '../components/HeaderBar'
import Button1 from '../components/buttons/Button1'
import { logout, refresh } from '../redux/actions/AuthAction'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { message, Spin, Tag } from 'antd'
import { TagFilled, CloseOutlined } from '@ant-design/icons'
import '../style/inboxchat.css'
import defaultProfileSVG from '../pages/icons/default_profile.svg'
import rectangle_2 from '../img/rectangle_2.svg'
import calendarImg from '../img/calender.svg'
import clockImg from '../img/clock.svg'
import get12HourFormat from '../components/utils/get12HourFormat'
import { months } from '../components/utils/months'
import moment from 'moment'
import { baseURL } from '../redux/requests'
import { AnalyticsEventTracker } from '../components/utils/AnalyticsEventTracker'

const InboxChat = () => {
  const valueDate = moment().format('YYYY-MM-DD')
  const [dateVal] = useState(valueDate)
  const [dateVal2] = useState(valueDate)
  const params = useParams()
  const navigate = useNavigate()
  const [inboxMsgs, setinBoxMsgs] = useState([])
  const [doneloading, setdoneloading] = useState(false)
  const [msgSent, setmsgSent] = useState(false)
  const [msg, setMsg] = useState()
  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)
  // const baseURL = process.env.REACT_APP_BASE_URL
  const cookies = new Cookies()
  const authData = cookies.get('jwt')
  const dispatch = useDispatch()
  const imgURL = baseURL.split('/api')[0]

  useEffect(() => {
    const getInboxChat = async () => {
      try {
        const config = {
          headers: {
            Authorization: authData,
          },
        }
        const data = {
          type: 'all',
          page_number: 1,
          limit: 10,
        }
        const reqAPI = await axios.patch(
          `${baseURL}/property/reserve/conversation/${params?.id}`,
          data,
          config,
        )
        // console.log(reqAPI.data)
        setinBoxMsgs(reqAPI.data)
        setdoneloading(true)
      } catch (err) {
        if (err.response.status === 406) {
          message.error('Please Login again')
          dispatch(logout())
          navigate('/')
        } else {
          message.error(err.response.statusText)
        }
      }
    }
    getInboxChat()
  }, [msgSent, authData, baseURL, params.id])

  const sendMsg = async () => {
    try {
      const config = {
        headers: {
          Authorization: authData,
        },
      }
      const data = {
        id: inboxMsgs.inquiry.unique_id,
        message: msg,
      }
      // console.log(data)
      await axios.post(`${baseURL}/property/reserve/conversation`, data, config)
      setMsg('')
      setmsgSent(true)
      AnalyticsEventTracker('Message', 'Sent Msg to host', 'Sent Msg to host')
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        message.error(err.response.statusText)
      }
    }
  }

  // fixed rate id = 2
  // hourly id = 1
  const price =
    inboxMsgs?.inquiry?.price.pricing_condition.id === 2
      ? inboxMsgs?.inquiry?.amount / inboxMsgs?.inquiry?.total_day
      : inboxMsgs?.inquiry?.amount /
        (inboxMsgs?.inquiry?.total_hour_per_day * inboxMsgs?.inquiry?.total_day)

  const inquiryAmount = inboxMsgs?.inquiry?.amount

  const priceDetailInfo =
    inboxMsgs?.inquiry?.price?.pricing_condition.id === 2
      ? price + ' x ' + inboxMsgs?.inquiry?.total_day + ' day'
      : price + ' x ' + inboxMsgs?.inquiry?.total_hour_per_day + ' hours'

  const serviceFeePrice = inboxMsgs?.inquiry?.service_fee
  const transaction_fee = inboxMsgs?.inquiry?.transaction_fee
  const totalAmount = inboxMsgs?.inquiry?.total_amount.toFixed(2)

  const navigateToInsearch = () => {
    navigate(
      `/renter/place/${inboxMsgs.inquiry.price.unique_id}/${dateVal}/${dateVal2}`,
    )
  }

  return (
    <>
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      {doneloading ? (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div>
            <div className="space">
              <h1 className="space-title">
                You inquired about {inboxMsgs?.inquiry?.property?.name}
              </h1>
              <p className="static_text">
                Introduce yourself. Describe your planned event or activity and,
                how you plan to use the space. Once both you and the host are
                comfortable moving forward, go to the listing profile and go
                through the check out process and send a proper reservation
                request. Once the host accepts your request, your reservation
                will be confirmed.
              </p>
            </div>
            <div style={{ marginTop: 20 }}>
              <h1 className="chat-title">
                View All Messages ({inboxMsgs.conversation.length})
              </h1>
            </div>
            {inboxMsgs?.conversation.map((item, idx) => {
              return (
                <div key={idx}>
                  <div
                    className="single-msg"
                    onClick={() =>
                      navigate(
                        `/profile/${inboxMsgs?.inquiry?.host?.unique_id}`,
                      )
                    }
                  >
                    <img
                      src={
                        item.message_from === 'GUEST'
                          ? `${
                              inboxMsgs?.inquiry?.guest?.image
                                ? `${imgURL}${inboxMsgs?.inquiry?.guest?.image}`
                                : defaultProfileSVG
                            }`
                          : `${
                              inboxMsgs?.inquiry?.host?.image
                                ? `${imgURL}${inboxMsgs?.inquiry?.host?.image}`
                                : defaultProfileSVG
                            }`
                      }
                      alt=""
                      style={{
                        width: 51,
                        height: 51,
                        marginInline: 20,
                        borderRadius: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <div style={{ margin: '25px 0' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        <h4 style={{ fontSize: 16 }}>
                          {item.message_from === 'GUEST'
                            ? inboxMsgs?.inquiry?.guest.name
                            : inboxMsgs?.inquiry?.host.name}
                        </h4>
                        <h3
                          style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#B4B4B4',
                          }}
                        >
                          {moment(item.created).format(
                            'ddd, MMM, YYYY, h:mm a',
                          )}
                        </h3>
                      </div>
                      <h1 className="user-msg" style={{ marginTop: 1 }}>
                        {item.message}
                      </h1>
                    </div>
                  </div>
                </div>
              )
            })}
            <div style={{ margin: '25px 0' }}>
              <div className="single-msg">
                <img
                  src={
                    inboxMsgs?.inquiry?.guest?.image !== null
                      ? `${imgURL}${inboxMsgs?.inquiry?.guest?.image}`
                      : defaultProfileSVG
                  }
                  alt=""
                  style={{
                    width: 51,
                    height: 51,
                    marginInline: 20,
                    borderRadius: '100%',
                    objectFit: 'cover',
                    marginTop: -200,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <input
                    className="msgbox"
                    placeholder="Message to the host"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    style={{ border: 'none' }}
                  />
                  <div style={{ margin: '25px 0' }} onClick={() => sendMsg()}>
                    <Button1 title="Send" width={134} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: 20 }}>
            <div
              style={{
                height: 1250,
                borderRadius: 8,
                boxShadow: 'none',
                position: 'relative',
                border: '#ccc 1px solid',
                position: 'relative',
              }}
            >
              <h5
                style={{
                  fontSize: 18,
                  textDecoration: 'underline',
                  fontWeight: 700,
                  color: '#fff',
                  position: 'absolute',
                  margin: 30,
                  cursor: 'pointer',
                }}
                onClick={navigateToInsearch}
              >
                {inboxMsgs.inquiry.property.name}
              </h5>
              <img
                src={
                  inboxMsgs?.inquiry?.property.images[0].image !== null
                    ? `${imgURL}${inboxMsgs?.inquiry?.property.images[0].image}`
                    : rectangle_2
                }
                alt=""
                style={{
                  width: 443,
                  height: 276,
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0',
                }}
              />
              <div
                style={{
                  margin: 20,
                }}
              >
                <div className="prices" style={{ marginTop: 0 }}>
                  <Link to={`/profile/${inboxMsgs?.inquiry?.host?.unique_id}`}>
                    <div style={{ display: 'flex' }}>
                      <img
                        src={
                          inboxMsgs?.inquiry.host.image !== null
                            ? `${imgURL}${inboxMsgs?.inquiry.host.image}`
                            : defaultProfileSVG
                        }
                        style={{
                          width: 80,
                          height: 80,
                          objectFit: 'contain',
                          borderRadius: '100%',
                        }}
                        alt=""
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1
                          style={{
                            width: 240,
                            fontWeight: 500,
                            fontSize: 18,
                            marginLeft: 25,
                            textTransform: 'capitalize',
                          }}
                        >
                          {inboxMsgs?.inquiry?.host.name}
                        </h1>
                      </div>
                    </div>{' '}
                  </Link>

                  <img src="/horline.svg" alt="" style={{ margin: '15px 0' }} />
                  <div>
                    <h5
                      style={{
                        fontWeight: 'bold',
                        marginTop: 20,
                        fontSize: 16,
                      }}
                    >
                      Address
                    </h5>
                    <h5
                      style={{ fontWeight: '500', marginTop: 20, fontSize: 16 }}
                    >
                      {inboxMsgs.inquiry.property.address}
                    </h5>
                    <h5
                      style={{
                        fontWeight: 'bold',
                        marginTop: 20,
                        fontSize: 16,
                      }}
                    >
                      Inquired Date & time
                    </h5>
                    <div className="stuff-prices">
                      <div
                        style={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <img
                          src={calendarImg}
                          alt=""
                          style={{ marginRight: 10 }}
                        />
                        <h5 style={{ fontSize: 16 }}>
                          {months[+inboxMsgs.inquiry.start_date.slice(5, 7)]}{' '}
                          {inboxMsgs.inquiry.start_date.slice(8, 11)},{' '}
                          {inboxMsgs.inquiry.start_date.slice(0, 4)}
                        </h5>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <img src={clockImg} alt="" style={{ marginRight: 10 }} />
                      <h5 style={{ fontSize: 16 }}>
                        {get12HourFormat(inboxMsgs.inquiry.start_time)}
                      </h5>
                    </div>
                  </div>
                </div>
                <h5 style={{ fontWeight: 'bold', marginTop: 20, fontSize: 16 }}>
                  Activity
                </h5>
                <h5 style={{ fontWeight: '500', marginTop: 20, fontSize: 16 }}>
                  {inboxMsgs.inquiry.activity.title}
                </h5>
                <h5 style={{ fontWeight: 'bold', marginTop: 20, fontSize: 16 }}>
                  Attendees
                </h5>
                <h5 style={{ fontWeight: '500', marginTop: 20, fontSize: 16 }}>
                  {inboxMsgs.inquiry.expected_guest_amount}
                </h5>
                <h5 style={{ fontWeight: 'bold', marginTop: 20, fontSize: 16 }}>
                  Alcohol
                </h5>
                <h5 style={{ fontWeight: '500', marginTop: 20, fontSize: 16 }}>
                  {inboxMsgs.inquiry.alcohol_consumption ? 'Yes' : 'No'}
                </h5>
                <div className="prices">
                  <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>
                    Price detail
                  </h4>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>
                        ${parseFloat(priceDetailInfo).toFixed(2)}
                      </h5>
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </div>
                    <h5 style={{ fontSize: 16 }}>
                      ${inquiryAmount.toFixed(2)}
                    </h5>
                  </div>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>Cleaning fee</h5>
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </div>
                    <h5 style={{ fontSize: 16 }}>
                      ${inboxMsgs.inquiry.cleaning_fee.toFixed(2)}
                    </h5>
                  </div>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>Service fee</h5>
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </div>
                    <h5 style={{ fontSize: 16 }}>
                      ${(serviceFeePrice + transaction_fee).toFixed(2)}
                    </h5>
                  </div>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>
                        {inboxMsgs.inquiry.price.minimum_hour_for_discount}+
                        hours discount
                      </h5>
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </div>
                    <h5 style={{ color: '#808080', fontSize: 16 }}>
                      ${inboxMsgs.inquiry.discount.toFixed(2)}
                    </h5>
                  </div>
                  {inboxMsgs.inquiry.coupon && (
                    <div className="stuff-prices">
                      <div style={{ display: 'flex' }}>
                        <h5 style={{ fontSize: 16 }}>Coupon Discount</h5>
                        <Tag
                          color="white"
                          icon={<TagFilled style={{ color: 'black' }} />}
                        >
                          <span style={{ color: 'black' }}>
                            {inboxMsgs.inquiry.coupon?.code}
                          </span>
                        </Tag>
                      </div>
                      <h5 style={{ color: '#808080', fontSize: 16 }}>
                        - ${inboxMsgs.inquiry.coupon_discount}
                      </h5>
                    </div>
                  )}
                </div>
                <img src="/horline.svg" alt="" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px 0px',
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>Subtotal</h3>
                  <h3 style={{ fontWeight: 'bold' }}>
                    ${parseFloat(totalAmount).toFixed(2)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '15%',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  )
}

export default InboxChat
