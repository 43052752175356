import React, { useEffect, useState } from 'react'
import { Modal, Checkbox } from 'antd'
import axios from 'axios'

const Morefilters = (props) => {
  const {
    isModalVisible,
    setIsModalVisible,
    setAmenity,
    setTypeOfWorship,
    setPlaceCatagory,
    setHouseRules,
  } = props

  //List of amenities
  const [basics, setBasics] = useState([])
  const [presentation, setPresentation] = useState([])
  const [av, setav] = useState([])
  const [Physical, setPhysical] = useState([])
  const [Additional, setAdditioanl] = useState([])
  const [Security, setSecurity] = useState([])
  const [Transportation, setTransportation] = useState([])
  const [kitchen, setKitchen] = useState([])

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const baseURL = process.env.REACT_APP_BASE_URL
  useEffect(() => {
    ;(async () => {
      try {
        const res = await axios.get(`${baseURL}/guest/activity`)
        setData(res.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  useEffect(() => {
    setAmenity(
      basics.concat(
        presentation,
        av,
        Physical,
        Additional,
        Security,
        Transportation,
        kitchen,
      ),
    )
  }, [
    basics,
    presentation,
    av,
    Physical,
    Additional,
    Security,
    Transportation,
    kitchen,
  ])

  return (
    <>
      {!loading && (
        <>
          <Modal
            title="More Filters"
            okText="Apply"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            style={{ top: '8%' }}
          >
            <div style={{ maxHeight: 500, overflowY: 'scroll' }}>
              <div style={{ margin: 10 }}>
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Facility Type
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['type of worship'].map((item, idx) => ({
                    label: item.replace(/[_-]/g, ' '),
                    value: item,
                  }))}
                  onChange={(checkedValues) => {
                    setTypeOfWorship(checkedValues)
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Type of Space
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['type of space'].map((item, idx) => ({
                    label: item.replace(/[_-]/g, ' '),
                    value: item,
                  }))}
                  onChange={(checkedValues) => {
                    setPlaceCatagory(checkedValues)
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>House Rules</h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['house rules'].map((item, idx) => ({
                    label: item.replace(/[_-]/g, ' '),
                    value: item,
                  }))}
                  onChange={(checkedValues) => {
                    setHouseRules(checkedValues)
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>Basics</h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data.amenities['Basics'].map((item, idx) => ({
                    label: item.title,
                    value: item,
                  }))}
                  onChange={(vals) => {
                    setBasics(vals.map((item, idx) => item.id))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Presentation and Office Related
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data.amenities['Presentation & Office Related'].map(
                    (item, idx) => ({ label: item.title, value: item.id }),
                  )}
                  onChange={(vals) => {
                    setPresentation(vals.map((item, idx) => item))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  AV and Lighting
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data.amenities['AV & Lighting'].map((item, idx) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  onChange={(vals) => {
                    setav(vals.map((item, idx) => item))
                  }}
                />

                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Physical Activities and Sports
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['amenities'][
                    'Physical Activities & Sports'
                  ].map((item, idx) => ({ label: item.title, value: item.id }))}
                  onChange={(vals) => {
                    setPhysical(vals.map((item, idx) => item))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Kitchen and Dining Related
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['amenities']['Kitchen & Dining Related'].map(
                    (item, idx) => ({ label: item.title, value: item.id }),
                  )}
                  onChange={(vals) => {
                    setKitchen(vals.map((item, idx) => item))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Additional Spaces
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['amenities']['Additional Spaces'].map(
                    (item, idx) => ({ label: item.title, value: item.id }),
                  )}
                  onChange={(vals) => {
                    setAdditioanl(vals.map((item, idx) => item))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>Security</h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['amenities']['Security'].map((item, idx) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  onChange={(vals) => {
                    setSecurity(vals.map((item, idx) => item))
                  }}
                />
                <h5 style={{ fontWeight: 600, marginTop: 14 }}>
                  Transportation & Accessibility
                </h5>
                <Checkbox.Group
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2,1fr)',
                    paddingBottom: 14,
                    borderBottom: '1px #B4B4B4 solid',
                    marginTop: 15,
                    gap: 15,
                    textTransform: 'capitalize',
                  }}
                  options={data['amenities'][
                    'Transportation & Accessibility'
                  ].map((item, idx) => ({ label: item.title, value: item.id }))}
                  onChange={(vals) => {
                    setTransportation(vals.map((item, idx) => item))
                  }}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export default Morefilters
