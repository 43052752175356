import React, { useState, useEffect } from 'react'
import polygon_1 from './../img/polygon_1.png'
import { useSelector, useDispatch } from 'react-redux'
import {
  createWindowBookingRole,
  deleteWindowBookingRole,
  updateWindowBookingRole,
} from '../redux/actions/WindowBookingRoleAction'
import { setInformation } from '../redux/actions/InformationAction'
import { setSpace } from '../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function BookingWindowRules({
  handleDelete,
  form_data,
  index,
  handleData,
  render,
  space_option,
  time_option,
}) {
  const [showSpaceDropDownBox, setShowSpaceDropDownBox] = useState(false)
  const [showTimeDropDownBox, setShowTimeDropDownBox] = useState(false)
  const [selectedTimeName, setSelectedTimeName] = useState('All future dates')
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedSpaces, setSelectedSpaces] = useState(form_data.space)
  const [loading, setLoading] = useState(render)
  const dispatch = useDispatch()

  useEffect(() => {
    setFormData()
  }, [form_data])

  let setFormData = () => {
    let form = document.getElementById(
      `booking_window_time_ruls_form_id_${index}`,
    ).elements

    if (form.time != undefined) {
      if (form_data.time != null) {
        setSelectedTimeName(form_data.time.title)
        form.time.value = form_data.time.id
      }
    }
  }

  let handleChange = (e) => {
    let form = document.getElementById(
      `booking_window_time_ruls_form_id_${index}`,
    ).elements
    let data = {
      id: form_data.id,
      time: {},
      space: [],
    }

    for (let key in time_option) {
      if (time_option[key].id == form.time.value) {
        data.time = time_option[key]
        setSelectedTimeName(time_option[key].title)
        setSelectedTime(time_option[key])
      }
    }

    if (form.space[0] != null) {
      for (let key in form.space) {
        if (form.space[key] != null) {
          if (form.space[key].checked != undefined) {
            if (form.space[key].checked) {
              data['space'].push(findSpaceFromList(form.space[key].value))
            }
          }
        }
      }
    }

    if (form.space[0] == null) {
      if (form.space.checked) {
        data['space'].push(findSpaceFromList(form.space.value))
      }
    }
    // console.log(data);
    handleData(data)
  }

  let findSpaceFromList = (id) => {
    for (let key in space_option) {
      if (space_option[key].id == id) {
        return space_option[key]
      }
    }
  }

  const [showConfirmRoleBox, setShowConfirmRoleBox] = useState(false)
  return (
    <div className="buffer-time-ruls-input-box">
      <button
        onBlurCapture={() => {
          setShowConfirmRoleBox(false)
        }}
        onFocusCapture={() => {
          setShowConfirmRoleBox(true)
        }}
        type="button"
        className="delete-buffer-time-ruls-input-box-button"
        style={{ cursor: 'pointer' }}
      >
        X
        {showConfirmRoleBox ? (
          <div
            onClick={() => {
              handleDelete(index)
            }}
            className="role-delete-confirm-box"
          >
            Delete This Rule
          </div>
        ) : (
          ''
        )}
      </button>
      <form
        onChange={handleChange}
        id={`booking_window_time_ruls_form_id_${index}`}
        className="buffer-time-ruls-content-wrapper"
      >
        All renters can make a booking for
        <button
          onFocusCapture={() => {
            setShowSpaceDropDownBox(true)
          }}
          onBlurCapture={() => {
            setShowSpaceDropDownBox(false)
          }}
          type="button"
          className="drop-down-box space-drop-down-box"
        >
          {form_data.space.length > 0 ? 'space selected' : 'No Spaces'}
          <img className="polygon1" src={polygon_1} alt="" srcSet="" />
          <div
            style={{ display: showSpaceDropDownBox ? 'block' : 'none' }}
            className="drop-down space-drop-down"
          >
            <ul
              style={{
                marginLeft: '120px',
              }}
            >
              {space_option &&
                Object.keys(space_option).map((key) => {
                  let space_id = form_data.space.map((space) => {
                    return space.id
                  })
                  return (
                    <li>
                      <input
                        type="checkbox"
                        className="hide-elements"
                        value={space_option[key].id}
                        name={`space`}
                        id={`window_input_box_id_${index}_${space_option[key].id}`}
                        onChange={() => {}}
                        checked={space_id.includes(space_option[key].id)}
                      />
                      <label
                        style={{ marginLeft: '10px' }}
                        htmlFor={`window_input_box_id_${index}_${space_option[key].id}`}
                      >
                        <div
                          className={`custom-check-box ${
                            space_id.includes(space_option[key].id)
                              ? 'selected-check-box'
                              : ''
                          }`}
                          id={`window_custom_check_box_${index}_${space_option[key].id}`}
                        >
                          ✔
                        </div>
                        {space_option[key].name}
                      </label>
                    </li>
                  )
                })}
            </ul>
          </div>
        </button>
        <button
          type="button"
          className="drop-down-box time-drop-down-box"
          // onClick={()=>{ setShowTimeDropDownBox(!showTimeDropDownBox) }}
          onFocusCapture={() => {
            setShowTimeDropDownBox(true)
          }}
          onBlurCapture={() => {
            setShowTimeDropDownBox(false)
          }}
        >
          {selectedTimeName}{' '}
          <img className="polygon1" src={polygon_1} alt="" srcSet="" />
          <div
            style={{ display: showTimeDropDownBox ? 'block' : 'none' }}
            className="drop-down time-drop-down"
          >
            <ul
              style={{
                marginLeft: '75px',
                marginTop: '20px',
              }}
            >
              {time_option &&
                time_option.map((value, key) => {
                  return (
                    <li>
                      <input
                        type="radio"
                        className="hide-elements"
                        value={value.id}
                        name={`time`}
                        id={`window_input_time_id_${index}_${value.id}`}
                      />
                      <label
                        htmlFor={`window_input_time_id_${index}_${value.id}`}
                      >
                        <div
                          id={`window_time_radio_box_${index}_${value.id}`}
                          className="radio-box-dummy"
                          style={{ marginRight: '10px' }}
                        >
                          <div
                            className={
                              value.title == selectedTimeName
                                ? ''
                                : 'hide-elements'
                            }
                            id={`radio_inside_${index}_${value.id}`}
                          ></div>
                        </div>
                        {value.title}
                      </label>
                    </li>
                  )
                })}
            </ul>
          </div>
        </button>
      </form>
    </div>
  )
}
