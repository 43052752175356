import React, { PureComponent, useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

export default function BarCart({ chart_data }) {
  const [chartData, setChartData] = useState([])
  // useEffect(() => {
  //   // console.log(history);
  //   history && history.history.map( (h)=>{
  //     let date = timeConvart(h.reservation.date)
  //     console.log(date);
  //   })

  // }, [history])

  let timeConvart = (date) => {
    let dateObject = new Date(`${date}`)
    let presentDay = new Date()
    let presentDayYear = presentDay.toLocaleString('en-us', { year: 'numeric' })
    let day = dateObject.toLocaleString('en-us', { day: 'numeric' })
    let year = dateObject.toLocaleString('en-us', { year: 'numeric' })
    let dayName = dateObject.toLocaleString('en-us', { weekday: 'short' })
    let monthName = dateObject.toLocaleString('en-us', { month: 'long' })
    return { day, year, dayName, monthName, presentDayYear }
  }
  const data = [
    {
      name: 'Jan',
      'Paid Out': 200,
      Expected: 0,
      Refunds: 500,
      Chargebacks: 0,
    },
    {
      name: 'Feb',
      'Paid Out': 300,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Mar',
      'Paid Out': 100,
      Expected: 0,
      Refunds: 299,
      Chargebacks: 0,
    },
    {
      name: 'Apr',
      'Paid Out': 0,
      Expected: 400,
      Refunds: 0,
      Chargebacks: 499,
    },
    {
      name: 'May',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Jun',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Jul',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Aug',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Sep',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Oct',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Nov',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
    {
      name: 'Dec',
      'Paid Out': 0,
      Expected: 0,
      Refunds: 0,
      Chargebacks: 0,
    },
  ]

  return (
    <div style={{ width: '100%', marginBottom: 30 }}>
      <ResponsiveContainer width={'100%'} height={550}>
        <BarChart
          width={1000}
          // height={300}
          data={chart_data}
          // margin={{ top: 0, right: 100, left: 0, bottom: 0 }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 50, right: 50 }}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="Paid Out" stackId="a" fill="#348262" />
          <Bar dataKey="Expected" stackId="a" fill="#67CEA3" />
          <Bar dataKey="Refunds" stackId="a" fill="#CCCCCC" />
          <Bar dataKey="Chargebacks" stackId="a" fill="#F6922F" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
