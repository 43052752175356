import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import { Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons';

import { setHostPublicInformation, setInformation } from '../../redux/actions/InformationAction'
import greenTick from './../../img/green_tik.png'
import dummyUser from './../../img/dummy-user.png'
import redWorning from './../../img/red_worning.png'
import groupOfGuest from './../../img/group_of_guest.png'
import venuley_varified from './../../img/venuely_verified.png'
import ShareProfileModal from './ShareProfileModal'

import { baseURL } from '../../redux/requests'
import moment from 'moment'

export default function HostProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [hostInformation, setHostInformation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false) // modal for share profile

  const todaysDate = moment().format('YYYY-MM-DD')
  // const present_time = moment().format('hh:mm:ss')
  let present_time = moment()
  const remainder = 30 - (present_time.minute() % 30);
  present_time = moment().add(remainder, "minutes").format('hh:mm:ss')
  const present_time_plus_added = moment().add(remainder, "minutes").add(2,'hours').format('hh:mm:ss')

  useEffect(() => {
    if (loading) {
      if(id){
        dispatch(
          setHostPublicInformation({
              id:id,
              callBack: (response) => {
                console.log(response.data)
                console.log(id);
                setLoading(false)
                setHostInformation(response.data)
              },
            }
          )
        )
      }
      if(!id){
        dispatch(
          setInformation({
            callBack: (response) => {
              console.log(response.data)
              console.log(id);
              setLoading(false)
              setHostInformation(response.data)
            },
          }),
        )
      }

    }
    return () => {
      setLoading(false)
    }
  }, [loading])

  if (loading) {
    return (
      <div>
        <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
      </div>
    )
  }

  return (
    <div className="container">
      <ShareProfileModal setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible}  />
      <div className="host-profile-view-information-box-group">
        <div className="host-profile-view-varification-information-group">
          {/* Share profile clickable icon Row below */}
          <div style={{textAlign: 'end'}}><UploadOutlined className='upload_icon_antd' style={{cursor: 'pointer'}} onClick={()=>setIsModalVisible(true)} hostInformation={hostInformation}/></div>
          <div className="host-profile-view-host-image-box">
            <img
              style={{ borderRadius: '100%' }}
              src={
                hostInformation && hostInformation.image != null
                  ? `${baseURL.replace('/api', '')}${hostInformation.image}`
                  : dummyUser
              }
              alt=""
              srcSet=""
            />
          </div>
          <div className="host-profile-view-varification-information">
            <div className="host-profile-view-email-varification-information">
              <h4>Email address</h4>
              <p
                style={{
                  color:
                    hostInformation && hostInformation.verified
                      ? 'green'
                      : 'red',
                }}
              >
                {hostInformation && hostInformation.verified ? (
                  'Verified ✓'
                ) : (
                  <>
                    <img
                      style={{
                        height: '12px',
                        marginBottom: '2px',
                        marginRight: '6px',
                      }}
                      src={redWorning}
                      alt=""
                      srcSet=""
                    />
                    Not verified{' '}
                  </>
                )}
              </p>
            </div>

            <div className="host-profile-view-phone-varification-information">
              <h4>Phone number</h4>
              <p
                style={{
                  color:
                    hostInformation && hostInformation.phone_verified
                      ? 'green'
                      : 'red',
                }}
              >
                {hostInformation && hostInformation.phone_verified ? (
                  'Verified ✓'
                ) : (
                  <>
                    <img
                      style={{
                        height: '12px',
                        marginBottom: '2px',
                        marginRight: '6px',
                      }}
                      src={redWorning}
                      alt=""
                      srcSet=""
                    />
                    Not verified{' '}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="host-profile-view-basic-information">
          <h3>Host Profile</h3>
          <div className="host-profile-view-basic-information-name">
            <p>Name</p>
            <p>
              {hostInformation && hostInformation.name}
              {hostInformation &&
              hostInformation.control.is_venuley_varified ? (
                <img src={venuley_varified} alt="" srcSet="" />
              ) : (
                ''
              )}
            </p>
          </div>
          <div className="host-profile-view-basic-information-mission">
            <p>Venue’s Mission Statement</p>
            <p>{hostInformation && hostInformation.mission_and_vision}</p>
          </div>
        </div>
      </div>

      <h3 className="host-profile-view-event-list-heading">
        Spaces Listed By {hostInformation && hostInformation.first_name}
      </h3>

      <div
        className="host-profile-view-information-event-group"
        style={{ paddingBottom: '200px' }}
      >
        {hostInformation && Object.keys(hostInformation.events).length > 0
          ? Object.keys(hostInformation.events).map((key) => {
              return (
                <>
                  <hr className="host-profile-view-event-list-heading-hr" />
                  <h2>{key}</h2>
                  {hostInformation.events[key].map((space) => {
                    
                    return (
                      <div className="host-profile-view-information-event-box">
                        <div className="host-profile-view-information-event-box-image">
                          <img
                            src={
                              space.property.images.length > 0
                                ? `${baseURL.replace('/api', '')}${
                                    space.property.images[0].image
                                  }`
                                : 'https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
                            }
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <div className="host-profile-view-information-event-box-information">
                          <h3>{space.property.name}</h3>
                          <div className="host-profile-view-information-event-box-information-space">
                            <div>
                              <p>
                                from ${space.price}/
                                {space.pricing_condition.id === 1 ? 'hr' : 'day'}
                              </p>  
                              <p>
                                <img src={groupOfGuest} alt="" srcSet="" />{' '}
                                {space.property.number_of_guest}{' '}
                              </p>
                            </div>
                            <p>{space.property.address}</p>
                          </div>
                          <button
                            onClick={() => {
                              window.open(
                                `/renter/place/${space.unique_id}/${present_time}/${present_time_plus_added}/${todaysDate}/${todaysDate}`,
                                '_blank',
                              )
                              // navigate(`/space/add/${space.property.id}`)
                            }}
                          >
                            View
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </>
              )
            })
          : ''}
      </div>
    </div>
  )
}
