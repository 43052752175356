import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setBanner } from './../../../redux/actions/ComponentAction'

import CustomeButton from '../../../components/buttons/CustomeButton'
import SelectOption from '../../../components/SelectOption'
import arrowDown from './../../../img/arrow_down_1.png'
import calender from './../../../img/calender.png'
import clock from './../../../img/clock.png'
import {
  setPaymentHistory,
  setPaymentHistoryCSVdata,
} from '../../../redux/actions/InformationAction'
import { baseURL } from '../../../redux/requests'
import { CSVLink, CSVDownload } from 'react-csv'
import './revenue.css'
export default function TransactionHistory() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [paymentHistoryData, setPaymentHistoryData] = useState(null)
  const details = useSelector((state) => state.information)

  useEffect(() => {
    if (loading) {
      setShowTableLoader(true)
      dispatch(
        setBanner({
          header: 'Transaction History',
          discription: 'Browse record of past transactions and booking details',
          button: {
            visible: details.details_submitted
              ? !details.details_submitted
              : true,
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            dispatch(
              setPaymentHistory({
                page_number: 1,
                limit: 10,
                callBack: (response) => {
                  let history = response.data
                  setPaymentHistoryData(history)
                  setShowTableLoader(false)
                  // console.log(history);
                },
              }),
            )
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  const theadList = [
    'No',
    'Space',
    'Date',
    'Activity',
    'Discount',
    'Amount',
    'Type',
    'Details',
  ]

  let setSelectOptionValue = ({ name, value }, multiSelect) => {
    console.log(name, value, multiSelect)
  }
  let spacemultiSelectOptionsList = [
    { value: 'example_space_1', name: 'Example Space #1' },
    { value: 'example_space_2', name: 'Example Space #2' },
    { value: 'example_space_3', name: 'Example Space #3' },
    { value: 'example_space_4', name: 'Example Space #4' },
  ]
  let transactionMultiSelectOptionsList = [
    { value: 'expected', name: 'Expected' },
    { value: 'paid_out', name: 'Paid out' },
    { value: 'refund', name: 'Refund' },
    { value: 'chargeback', name: 'Chargeback' },
  ]
  let spaceOptionsList = [
    { value: 'apply_to', name: 'Apply to' },
    { value: 'all_spaces', name: 'All spaces' },
  ]
  let monthRangeOptionsListStart = [
    { name: 'From: January', value: 'january' },
    { name: 'From: February', value: 'february' },
    { name: 'From: March', value: 'march' },
    { name: 'From: April', value: 'april' },
    { name: 'From: May', value: 'may' },
    { name: 'From: Jun', value: 'jun' },
    { name: 'From: July', value: 'july' },
    { name: 'From: August', value: 'august' },
    { name: 'From: september', value: 'september' },
    { name: 'From: Octobar', value: 'octobar' },
    { name: 'From: November', value: 'november' },
    { name: 'From: December', value: 'december' },
  ]
  let monthRangeOptionsListEnd = [
    { name: 'To: January', value: 'january' },
    { name: 'To: February', value: 'february' },
    { name: 'To: March', value: 'march' },
    { name: 'To: April', value: 'april' },
    { name: 'To: May', value: 'may' },
    { name: 'To: Jun', value: 'jun' },
    { name: 'To: July', value: 'july' },
    { name: 'To: August', value: 'august' },
    { name: 'To: september', value: 'september' },
    { name: 'To: Octobar', value: 'octobar' },
    { name: 'To: November', value: 'november' },
    { name: 'To: December', value: 'december' },
  ]
  let yearRangeOptionsList = [
    { name: '2022', value: '2022' },
    { name: '2023', value: '2023' },
    { name: '2024', value: '2024' },
    { name: '2025', value: '2025' },
    { name: '2028', value: '2028' },
    { name: '2029', value: '2029' },
  ]

  let getTimeDifferenceBetweenTwoTime = (start, end) => {
    let startTime = new Date(`1/1/2022 ${start}`)
    let endTime = new Date(`1/1/2022 ${end}`)
    let hours = (endTime - startTime) / 36e5
    return hours
  }

  let calculateDiscount = (start, end, amount, pricing) => {
    let hours = getTimeDifferenceBetweenTwoTime(start, end)
    let actualPrice = pricing.price
    let cleaning_fee = pricing.additional_cleaning_fee
    if (pricing.pricing_condition.id == 1) {
      actualPrice = hours * pricing.price
    }
    if (pricing.cleaning_fee.id == 2) {
      actualPrice = cleaning_fee + actualPrice
    }
    let charge = (5 / 100) * actualPrice
    return amount - (actualPrice + charge)
  }

  let textColorListForPaymentType = {
    EXPECTED: '#67CEA3',
    PAID_OUT: '#348262',
    CHARGE_BACK: '#FF9D2F',
    REFUNDED: '#959595',
  }
  const [showPopUpBox, setShowPopUpBox] = useState(false)
  const [selectedHistoryData, setSelectedHistoryData] = useState(null)
  let handlePopUpBoxShow = (history) => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    setSelectedHistoryData(history)
    setShowPopUpBox(true)
    console.log(selectedHistoryData)
  }

  let handlePopUpBoxClose = () => {
    document.body.style.overflow = 'visible'
    setSelectedHistoryData(null)
    setShowPopUpBox(false)
    console.log()
  }

  let calculatePrice = (reservation) => {
    let hours = getTimeDifferenceBetweenTwoTime(
        reservation.start,
        reservation.end,
      ),
      price =
        reservation.price.pricing_condition.id == 1
          ? reservation.price.price * hours
          : reservation.price.price,
      discount = calculateDiscount(
        reservation.start,
        reservation.end,
        reservation.charge.amount,
        reservation.price,
      ),
      cleaning_fee =
        reservation.price.cleaning_fee.id == 1
          ? 0
          : reservation.price.additional_cleaning_fee,
      charge = (5 / 100) * price,
      total_price = price + cleaning_fee + charge - discount

    total_price = total_price.toFixed(2)
    charge = charge.toFixed(2)

    return {
      hours,
      price,
      discount,
      cleaning_fee,
      charge,
      total_price,
    }
  }
  let timeConvart = (time) => {
    let startTime = new Date(`1/1/2022 ${time}`)
    let timeString = startTime.toLocaleTimeString('en-us', { hour12: true })
    return timeString
  }
  let dateConvart = (date) => {
    let dateObject = new Date(`${date}`)
    let presentDay = new Date()
    let presentDayYear = presentDay.toLocaleString('en-us', { year: 'numeric' })
    let day = dateObject.toLocaleString('en-us', { day: 'numeric' })
    let year = dateObject.toLocaleString('en-us', { year: 'numeric' })
    let dayName = dateObject.toLocaleString('en-us', { weekday: 'short' })
    let monthName = dateObject.toLocaleString('en-us', { month: 'long' })
    let convertedDate = dateObject.toLocaleString('en-us', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour12: true,
    })
    return { day, year, dayName, monthName, presentDayYear, convertedDate }
  }

  let get_time_string = (start, end) => {
    let string = `${dateConvart(start).day}, ${dateConvart(start).monthName} ${
      dateConvart(start).year
    }`
    if (start != end) {
      string += ` to ${dateConvart(end).day}, ${dateConvart(end).monthName} ${
        dateConvart(end).year
      },`
    }
    return string
  }
  const [showTableLoader, setShowTableLoader] = useState(false)
  const [setPayoutCSVdata, setSetPayoutCSVdata] = useState([])
  const [csvDataLoading, setCsvDataLoading] = useState(false)
  const [showExportBurronDropDown, setShowExportBurronDropDown] =
    useState(false)

  if (showTableLoader) {
    return (
      <div>
        <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
      </div>
    )
  }

  return (
    <div>
      <div className="transaction-history-cart-filter-information">
        <div className="transaction-history-cart-filter">
          {/* <div className="cart-filter-space-group">
            <h1>Select a space</h1>
            <SelectOption multiSelect={spacemultiSelectOptionsList} preSetValue={null} optionsList={spaceOptionsList} optionName={'select_space'} className={'select_space'} sendValue={setSelectOptionValue} placeHolder={'Select a space'}/>
          </div>
          <div className="cart-filter-history-group">
            <h1>Select a month</h1>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={monthRangeOptionsListStart} optionName={'select_month_start'} className={'select_month_start'} sendValue={setSelectOptionValue} placeHolder={'From: January'}/>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={monthRangeOptionsListEnd} optionName={'select_month_end'} className={'select_month_end'} sendValue={setSelectOptionValue} placeHolder={'From: Decembar'}/>
          </div>
          <div className="cart-filter-history-second-group">
            <h1>Select a year</h1>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={yearRangeOptionsList} optionName={'select_year'} className={'select_year'} sendValue={setSelectOptionValue} placeHolder={'Year'}/>
          </div>
          <div className="cart-filter-history-second-group">
            <h1>Transaction Type</h1>
            <SelectOption multiSelect={transactionMultiSelectOptionsList} preSetValue={null} optionsList={spaceOptionsList} optionName={'transaction_type'} className={'transaction_type'} sendValue={setSelectOptionValue} placeHolder={'Transaction Type'}/>
          </div>
          <div className="export-button-transaction-history">
            <CustomeButton style={{margin: "-32px 0px 0px 0px"}} name={'export'} clickEventFunction={()=>{console.log('clicked')}}/>
          </div> */}
        </div>

        {/* <hr className='transaction-history-cart-filter-information-hr'/> */}
      </div>
      <div className="transaction-amount-history">
        <div>
          <h1>
            {paymentHistoryData &&
            paymentHistoryData.total_number_of_reservation != null
              ? paymentHistoryData.total_number_of_reservation
              : 0}
          </h1>
          <p>Reservations</p>
        </div>
        <div>
          <h1>
            $
            {paymentHistoryData && paymentHistoryData.paid_out != null
              ? paymentHistoryData.paid_out.toFixed(2)
              : 0}
          </h1>
          <p>Paid Out</p>
        </div>
        <div>
          <h1>
            $
            {paymentHistoryData && paymentHistoryData.expected != null
              ? paymentHistoryData.expected.toFixed(2)
              : 0}
          </h1>
          <p>Expected</p>
        </div>
        <div>
          <h1>
            $
            {paymentHistoryData && paymentHistoryData.refunded != null
              ? paymentHistoryData.refunded.toFixed(2)
              : 0}
          </h1>
          <p>Refunds</p>
        </div>
        <div>
          <h1>
            $
            {paymentHistoryData && paymentHistoryData.charge_back != null
              ? paymentHistoryData.charge_back.toFixed(2)
              : 0}
          </h1>
          <p>Chargebacks</p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ position: 'relative', marginBottom: 15 }}>
            <button
              onClick={() => {
                setShowExportBurronDropDown(!showExportBurronDropDown)
              }}
              className="export-button"
            >
              Export
            </button>
            <div
              className={`export-button-drop-down ${
                showExportBurronDropDown ? '' : 'hide-elements'
              }`}
            >
              <CSVLink
                asyncOnClick={true}
                data={setPayoutCSVdata}
                onClick={(event, done) => {
                  setCsvDataLoading(true)
                  let data_loaded = setPayoutCSVdata.length > 0
                  if (!data_loaded) {
                    event.preventDefault()
                  }
                  dispatch(
                    setPaymentHistoryCSVdata({
                      callBack: (response) => {
                        setCsvDataLoading(false)
                        setSetPayoutCSVdata(response.data.data_list)
                        done(true)
                      },
                    }),
                  )
                }}
              >
                {csvDataLoading
                  ? 'loading...'
                  : setPayoutCSVdata.length > 0
                  ? 'download csv'
                  : 'click to load csv'}
              </CSVLink>
            </div>
          </div>
          <div>
            <span>
              {' '}
              {paymentHistoryData && paymentHistoryData.current_page}-
              {paymentHistoryData && paymentHistoryData.total_number_of_page} of{' '}
              {paymentHistoryData && paymentHistoryData.total_number_of_page}{' '}
            </span>
            <img
              onClick={() => {
                if (paymentHistoryData.has_previous) {
                  setShowTableLoader(true)
                  dispatch(
                    setPaymentHistory({
                      page_number: paymentHistoryData.current_page - 1,
                      limit: paymentHistoryData.limit,
                      callBack: (response) => {
                        setShowTableLoader(false)
                        setPaymentHistoryData(response.data)
                        console.log(response.data)
                      },
                    }),
                  )
                }
              }}
              src={arrowDown}
              className="left-arrow-for-table"
              alt=""
              srcSet=""
            />
            <img
              onClick={() => {
                if (paymentHistoryData.has_next) {
                  setShowTableLoader(true)
                  dispatch(
                    setPaymentHistory({
                      page_number: paymentHistoryData.current_page + 1,
                      limit: paymentHistoryData.limit,
                      callBack: (response) => {
                        setShowTableLoader(false)
                        setPaymentHistoryData(response.data)
                        console.log(response.data)
                      },
                    }),
                  )
                }
              }}
              src={arrowDown}
              className="right-arrow-for-table"
              alt=""
              srcSet=""
            />
          </div>
        </div>
      </div>

      <table className="transaction-history-table">
        <thead>
          {theadList.map((head) => {
            return <th>{head}</th>
          })}
        </thead>
        <tbody>
          {!showTableLoader ? (
            paymentHistoryData &&
            paymentHistoryData.history.map((data, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td> {data.reservation.property.name} </td>
                  <td>
                    {` ${get_time_string(
                      data.reservation.start_date,
                      data.reservation.end_date,
                    )}
                        ${timeConvart(
                          data.reservation.start_time,
                        )} to ${timeConvart(data.reservation.end_time)}
                      `}
                  </td>
                  <td> {data.reservation.activity.title} </td>
                  <td>
                    {' '}
                    $
                    {data.reservation.discount +
                      data.reservation.coupon_discount}{' '}
                  </td>
                  <td> ${data.reservation.amount.toFixed(2)} </td>
                  <td>
                    {' '}
                    <p
                      style={{
                        textTransform: 'capitalize',
                        display: 'inline-block',
                        color: textColorListForPaymentType[data.payment_type],
                      }}
                    >
                      {data.payment_type.toLowerCase().replaceAll('_', ' ')}
                    </p>{' '}
                  </td>
                  <td>
                    {' '}
                    <p
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handlePopUpBoxShow(data)
                      }}
                    >
                      {' '}
                      View{' '}
                    </p>
                  </td>
                </tr>
              )
            })
          ) : (
            <div
              style={{
                top: '80%',
                left: '60%',
              }}
              className="loader"
            >
              wait
            </div>
          )}
        </tbody>
      </table>
      {showPopUpBox ? (
        <div className="single-payment-history-pop-show-wrapper">
          <div className="single-payment-history-pop-show-card">
            <p
              className="single-payment-history-pop-close-button"
              style={{
                cursor: 'pointer',
                // position: 'absolute',
                // top: '10rem',
                // left: '102rem',
                fontWeight: '900',
                textAlign: 'right',
                fontSize: 16,
              }}
              onClick={handlePopUpBoxClose}
            >
              x
            </p>
            <div className="single-payment-history-pop-card-heading">
              <img
                style={{
                  objectFit: 'contain',
                }}
                src={`${baseURL.replace('/api', '')}${
                  selectedHistoryData &&
                  selectedHistoryData.reservation.property.images.length > 0
                    ? selectedHistoryData.reservation.property.images[0].image
                    : ''
                }`}
                alt=""
              />
              <h4>
                {selectedHistoryData &&
                  selectedHistoryData.reservation.property.name}
              </h4>
            </div>
            <div className="single-payment-history-pop-card-guest-info">
              <img
                style={{
                  height: '100px',
                  width: '100px',
                  borderRadius: '100%',
                  objectFit: 'contain',
                }}
                src={`${baseURL.replace('/api', '')}${
                  selectedHistoryData &&
                  selectedHistoryData.reservation.guest.image
                }`}
                alt=""
              />
              <h4>
                {selectedHistoryData &&
                  selectedHistoryData.reservation.guest.name}
              </h4>
            </div>

            <div className="single-payment-history-pop-reservation-date-time">
              <h4>Date & time</h4>
              {/* {day,year,dayName,monthName,presentDayYear,convertedDate} */}
              <div className="single-payment-history-pop-reservation-date">
                <img src={calender} alt="" srcSet="" />
                <div>
                  {selectedHistoryData &&
                    `
                    ${get_time_string(
                      selectedHistoryData.reservation.start_date,
                      selectedHistoryData.reservation.end_date,
                    )}
                  `}
                </div>
              </div>
              <div className="single-payment-history-pop-reservation-time">
                <img
                  style={{ height: '18px', width: '15px' }}
                  src={clock}
                  alt=""
                  srcSet=""
                />
                <div className="single-payment-history-pop-start-time">
                  {selectedHistoryData &&
                    timeConvart(selectedHistoryData.reservation.start_time)}
                </div>
                <div>-</div>
                <div className="single-payment-history-pop-end-time">
                  {selectedHistoryData &&
                    timeConvart(selectedHistoryData.reservation.end_time)}
                </div>
              </div>
            </div>

            <div className="single-payment-history-pop-activity">
              <h4>Activity</h4>
              {selectedHistoryData &&
                selectedHistoryData.reservation.activity.title}
            </div>
            <div className="single-payment-history-pop-attedies">
              <h4>Attendees</h4>
              {selectedHistoryData &&
                selectedHistoryData.reservation.expected_guest_amount}
            </div>
            <div className="single-payment-history-pop-earning">
              <h4>Earning Breakdown</h4>
              <div className="single-payment-history-pop-earning-calculate">
                <div>
                  $
                  {selectedHistoryData &&
                    (selectedHistoryData.reservation.is_fixed
                      ? selectedHistoryData.reservation.amount /
                        selectedHistoryData.reservation.total_day
                      : selectedHistoryData.reservation.amount /
                        selectedHistoryData.reservation.total_day /
                        selectedHistoryData.reservation
                          .total_hour_per_day)}{' '}
                  {selectedHistoryData &&
                  !selectedHistoryData.reservation.is_fixed
                    ? `x ${selectedHistoryData.reservation.total_hour_per_day} hour`
                    : ''}{' '}
                  x{' '}
                  {selectedHistoryData &&
                    selectedHistoryData.reservation.total_day}{' '}
                  day
                </div>
                <div>${selectedHistoryData.reservation.amount.toFixed(2)}</div>
                <div>cleaning fee</div>
                <div>
                  ${selectedHistoryData.reservation.cleaning_fee.toFixed(2)}
                </div>
                <div>Service fee</div>
                <div>
                  $
                  {(
                    selectedHistoryData.reservation.service_fee +
                    selectedHistoryData.reservation.transaction_fee
                  ).toFixed(2)}
                </div>
                <div>discount</div>
                <div>
                  {selectedHistoryData.reservation.coupon_discount == 0
                    ? ''
                    : '-'}
                  ${selectedHistoryData.reservation.coupon_discount.toFixed(2)}
                </div>
                <div>8+ hour discount</div>
                <div>
                  {selectedHistoryData.reservation.discount == 0 ? '' : '-'}$
                  {selectedHistoryData.reservation.discount.toFixed(2)}
                </div>
                <div className="single-payment-history-pop-earning-total">
                  total
                </div>
                <div className="single-payment-history-pop-earning-total">
                  ${selectedHistoryData.reservation.total_amount.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
