import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { logout, me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useOnclickOutside from 'react-cool-onclickoutside'
import useInterval from '@use-it/interval'
import dummyLogo from './../img/veneuly_logo.png'
import dummyUser from './../img/group.png'
import People from '../components/icons/People'

function Master() {
  const [collapsed, setCollapsed] = useState(false)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [usrData, setUsrData] = useState({})
  let navigate = useNavigate()
  const [showFloatingBox, setshowFloatingBox] = useState(false)
  const { pathname } = useLocation()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response);
        },
      }),
    )
  }, 360000)

  // useEffect(() => {
  //   if(auth.user.name == null){
  //       dispatch(me())
  //   }
  // }, [])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);
  let handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  const ref = useOnclickOutside(() => {
    setShowDropdownMenu(false)
  })

  return (
    <>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <NavLink to={'/'} className=" ">
          <div className="logo" style={{ height: '100%', width: '100%' }}>
            <img
              src={dummyLogo}
              alt=""
              srcSet
              style={{ height: 'auto', width: 150 }}
            />
          </div>
        </NavLink>
        <nav style={{ marginRight: 20 }}>
          <NavLink
            to={'/messages'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={style.navLinkStyle}
          >
            Messages
          </NavLink>
          <NavLink
            to={'/pending-request'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={style.navLinkStyle}
          >
            Reservation
          </NavLink>
          <NavLink
            to={'/space'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={style.navLinkStyle}
          >
            Your Space
          </NavLink>
          {/* <NavLink to={'/calendar'} className={({ isActive }) => (isActive ? 'active' : '')}>Calendar</NavLink> */}
          {/* <NavLink to={'/payment/revenue/income'} className={({ isActive }) => (isActive ? 'active' : '')}>Payment</NavLink> */}
          <NavLink
            to={'/payment/banking/payout-details'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={style.navLinkStyle}
          >
            Payment
          </NavLink>
          {/* <a style={{cursor:"pointer"}} onClick={handleLogout} className={({ isActive }) => (isActive ? 'active' : '')}>Sign-out</a> */}
          {/* <a to={'/'} className=''> */}
          <button
            type="button"
            // onBlur={()=>{ setShowDropdownMenu(false) }}
            onClick={() => {
              setShowDropdownMenu(!showDropdownMenu)
            }}
            className="profile-drop-down"
            style={{ position: 'relative', textAlign: 'left' }}
          >
            {/* <img src={dummyUser} alt="" /> */}
            <People />
            <ul
              ref={ref} // for outside click event ( to close the box )
              className={`${showDropdownMenu ? '' : 'hide-elements'}`}
              style={{ top: 35, right: 5 }}
            >
              <li style={{ width: '100%' }}>
                {' '}
                <NavLink
                  to={'/profile'}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  style={{ ...style.navLinkStyle, fontSize: 15 }}
                >
                  {' '}
                  Profile{' '}
                </NavLink>{' '}
              </li>
              <li style={{ width: '100%' }}>
                {' '}
                <NavLink
                  to={'/profile-edit'}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  style={{ ...style.navLinkStyle, fontSize: 15 }}
                >
                  {' '}
                  Edit Profile{' '}
                </NavLink>
              </li>
              <li onClick={handleLogout} style={{ paddingLeft: '30px' }}>
                Log out
              </li>
            </ul>
          </button>
          {/* </a> */}
        </nav>
      </header>
      <Outlet />
    </>
  )
}

const style = {
  navLinkStyle: {
    color: '#000',
    fontSize: 14,
  },
}

export default Master
