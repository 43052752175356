import { BasicPolicyActionType } from '../contants/actions-type'
const initialState = {
  basic_policy: null,
}

export default function BasicPolicyReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case BasicPolicyActionType.SET_BASIC_POLICY:
      return {
        ...state,
        ...payload,
      }

    case BasicPolicyActionType.SELECT_BASIC_POLICY:
      return {
        ...state,
        ...payload,
      }

    case BasicPolicyActionType.CREATE_BASIC_POLICY:
      return {
        ...state,
        ...payload,
      }

    case BasicPolicyActionType.UPDATE_BASIC_POLICY:
      return {
        ...state,
        ...payload,
      }

    case BasicPolicyActionType.REMOVE_BASIC_POLICY:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
