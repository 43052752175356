import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate, useParams, Link } from 'react-router-dom'
import OptOutActivitieBox from '../../components/OptOutActivitieBox'
import { updatePricing } from '../../redux/actions/PricingAction'
import { selectSpace } from '../../redux/actions/SpaceAction'
import { NotificationManager } from 'react-notifications'

export default function OptOutOfSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  const pricing = useSelector(
    (state) => state.space.space.activities_and_pricing,
  )
  const pricing_form_data = useSelector(
    (state) => state.information.form_data.pricing,
  )
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Opt Out Activities',
          discription: 'Select all activities you would like to opt out on.',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100/7) * 7,
          },
          pages: {
            visible: true,
            total_page: 7,
            page_number: 7,
          },
          callBack: () => {
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])
  const [displayList, setDisplayList] = useState(false)
  const [optOutActivities, setOptOutActivities] = useState({})
  let handleOptOutData = (data) => {
    setOptOutActivities({
      ...optOutActivities,
      ...data,
    })
  }
  let handleStepperGoingNext = () => {
    for (let pricing_id in optOutActivities) {
      dispatch(
        updatePricing({
          id: pricing_id,
          opt_out_activities: optOutActivities[pricing_id],
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  NotificationManager.success(
                    `Successfully opt-out-activitie`,
                    'opt-out-activitie',
                    700,
                  )
                },
              }),
            )
          },
        }),
      )
    }
    navigate(`/space`)
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form className="space-setup-inserte-activities-form">
        <div className="radio-button-group">
          <h1 className="radio-button-group-input-lable">
            Which type of activities would you like to{' '}
            <b style={{ fontSize: '28px', color: '#6568E6' }}> NOT </b> host in
            this space?
          </h1>
          <p className="radio-button-group-input-lable-description">
            This process will help your listing to NOT show up on searches with
            activities that you don’t want to host in this space.
          </p>
          <div className="activities-box">
            {pricing &&
              Object.keys(pricing).map((key, index) => {
                return (
                  <OptOutActivitieBox
                    header={pricing[key].pricing_type.title}
                    listHeading={'List of Activities'}
                    listHeadingDescription={
                      pricing[key].pricing_type.opt_out_description
                    }
                    lists={pricing_form_data[key].activitie}
                    pricing_id={pricing[key].id}
                    pricing_type_id={key}
                    space_id={id}
                    setOptOutActivities={handleOptOutData}
                  />
                )
              })}
            {Object.keys(pricing).length === 0 && (
              <div className="data-showing-box">
                <p style={{ fontSize: 16 }}>
                  Please set prices for your property.{' '}
                  <Link
                    to={`/space/add/${id}/activities`}
                    style={{ fontSize: 16 }}
                  >
                    Click here{' '}
                  </Link>{' '}
                  to set your pricings.{' '}
                </p>
              </div>
            )}
          </div>
        </div>
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/amenities`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="button" onClick={handleStepperGoingNext}>
            {' '}
            Save & Continue{' '}
          </button>
        </div>
      </form>
    </div>
  )
}
