import React from 'react'

function People() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="13.5" r="5.5" stroke="black" strokeWidth="2" />
      <circle cx="16" cy="16" r="15" stroke="black" strokeWidth="2" />
      <mask id="path-3-inside-1_391_10720" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.51904 27.144C6.01758 22.4298 10.5908 19 16 19C21.4092 19 25.9824 22.4298 27.481 27.144C24.5733 30.139 20.504 32 16 32C11.496 32 7.42669 30.139 4.51904 27.144Z"
        />
      </mask>
      <path
        d="M4.51904 27.144L2.61303 26.5381L2.25051 27.6785L3.08405 28.5371L4.51904 27.144ZM27.481 27.144L28.916 28.5371L29.7495 27.6785L29.387 26.5381L27.481 27.144ZM6.42506 27.7498C7.65283 23.8875 11.4392 21 16 21V17C9.74252 17 4.38232 20.9722 2.61303 26.5381L6.42506 27.7498ZM16 21C20.5609 21 24.3472 23.8875 25.575 27.7498L29.387 26.5381C27.6177 20.9722 22.2575 17 16 17V21ZM26.046 25.7508C23.4994 28.3739 19.9414 30 16 30V34C21.0666 34 25.6472 31.904 28.916 28.5371L26.046 25.7508ZM16 30C12.0587 30 8.50062 28.3739 5.95403 25.7508L3.08405 28.5371C6.35277 31.904 10.9334 34 16 34V30Z"
        fill="black"
        mask="url(#path-3-inside-1_391_10720)"
      />
    </svg>
  )
}

export default People
