import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import uploadButtonImage from './../../img/upload.png'
import doneButtonImage from './../../img/done.png'
import { getBase64, toBase64 } from '../../helper/ImageHelper'
import placeHolderImage from './../../img/Placeholder.png'
import {
  createImage,
  setImage,
  updateImage,
  deleteImage,
} from '../../redux/actions/ImageAction'
import { selectSpace, setSpace } from '../../redux/actions/SpaceAction'
import { baseURL2 } from '../../redux/requests'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import trash from './../../img/trash_icon.png'
import { UploadOutlined } from '@ant-design/icons'
import image_placeholder from './../../img/image_placeholder.jpg'
export default function AboutSpaceInsert() {
  let formRef = createRef()
  const banner = useSelector((state) => state.component.banner)
  const images = useSelector((state) => state.space.space.photos)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const [selectedImage, setSelectedImage] = useState({
    id: null,
    description: null,
    image: placeHolderImage,
  })
  const [showPhotoUploadBox, setShowPhotoUploadBox] = useState(false)
  const [showPictureDetailsBox, setshowPictureDetailsBox] = useState(false)
  const [listOfPriviewImage, setlistOfPriviewImage] = useState([])
  let hideDescriptionBox = () => {
    setshowPictureDetailsBox(false)
  }
  const [dragedIndex, setDragedIndex] = useState(0)
  const [enteredIndex, setEnteredIndex] = useState(0)
  const [readyToUploadImage, setReadyToUploadImage] = useState([
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
    placeHolderImage,
  ])

  // const [listOfImage, setListOfImage] = useState([
  //     {id: 1, image: placeHolderImage, order: 1, description: ''},
  //     {id: 2, image: placeHolderImage, order: 2, description: ''},
  //     {id: 3, image: placeHolderImage, order: 3, description: ''},
  //     {id: 4, image: placeHolderImage, order: 4, description: ''},
  //     {id: 5, image: placeHolderImage, order: 5, description: ''},
  //     {id: 6, image: placeHolderImage, order: 6, description: ''},
  //     {id: 7, image: placeHolderImage, order: 7, description: ''},
  //     {id: 8, image: placeHolderImage, order: 8, description: ''},
  //     {id: 9, image: placeHolderImage, order: 9, description: ''}
  // ])
  const [listOfImage, setListOfImage] = useState([])

  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Photos',
          discription: 'Upload photos and select a cover image',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100/7) * 3,
          },
          pages: {
            visible: true,
            total_page: 3,
            page_number: 6,
          },
          callBack: () => {
            fatchSpaceData()
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let setImageList = (images) => {
    if (images.length > 0) {
      // let newImageList = images.sort((a, b) => (a.order > b.order) ? 1 : -1)
      let url = images.map((img) => baseURL2 + img.image)
      let placeHolderImages = []
      setListOfImage(images)

      if (url.length < 9) {
        let i = 9 - url.length
        for (let index = 0; index < i; index++) {
          placeHolderImages.push(placeHolderImage)
        }
      }

      setSelectedImage({
        id: null,
        description: null,
        image: placeHolderImage,
      })
      setReadyToUploadImage([...url, ...placeHolderImages])
    }
  }

  let handleDragOver = (e) => {
    e.preventDefault()
    e.target.style.cursor = 'grabbing'
  }

  let handleDragStart = (e) => {
    setDragedIndex(e.target.getAttribute('index'))
    e.target.style.cursor = 'grabbing'
    console.log(e.target.getAttribute('index'), 'start')
  }

  let handleDragLeave = (e) => {
    // console.log(dragedIndex,enteredIndex);
    e.target.style.cursor = 'grab'
    let placeIndex = enteredIndex != 0 ? enteredIndex - 1 : 0
    let dragedElement = listOfImage[dragedIndex]

    let firstPart = [...listOfImage]
    firstPart.splice(dragedIndex, 1)

    let secondPart = [
      ...firstPart.slice(0, placeIndex),
      dragedElement,
      ...firstPart.slice(placeIndex, listOfImage.length - 1),
    ]
    secondPart.map((image, index) => {
      dispatch(
        updateImage({
          id: image.id,
          order: index,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {},
              }),
            )
          },
        }),
      )
      // console.log(image.order,index);
    })

    setListOfImage(secondPart)
  }

  let handleDragEnter = (e) => {
    if (dragedIndex != e.target.getAttribute('index')) {
      setEnteredIndex(e.target.getAttribute('index'))
      console.log(e.target.getAttribute('index'), 'Enter')
    }
  }

  let handelShowPictureDetailsBox = (picture) => {
    // formRef.current.elements.short_description.value = picture.description;
    // console.log(formRef.current.elements.short_description.value);
    if (picture.image != placeHolderImage) {
      setSelectedImage(picture)
      setshowPictureDetailsBox(!showPictureDetailsBox)
    }
  }

  let handleUpdateImageDescription = (e) => {
    e.preventDefault()
    if (selectedImage.description.length <= 50) {
      dispatch(
        updateImage({
          id: selectedImage.id,
          description: selectedImage.description,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  setListOfImage(response.data.photos)
                  setshowPictureDetailsBox(!showPictureDetailsBox)
                  NotificationManager.success(
                    `Successfully updated`,
                    'Image',
                    700,
                  )
                },
              }),
            )
          },
        }),
      )
    } else {
      NotificationManager.warning(
        `description length must be 50 charecter or less`,
        'Image description',
        3000,
      )
    }
  }

  let handleImageUpload = async (e) => {
    e.preventDefault()
    if (e.target.files) {
      let image_list = [...listOfPriviewImage]
      for(let i=0;i<e.target.files.length;i++){
        let image_data = await getBase64(e.target.files[i])
        image_list.push(image_data)
      }
      
      setlistOfPriviewImage(image_list)
      document.getElementById('uploadCaptureInputFile').value = ''
    }
  }
  const [showPageLoader, setShowPageLoader] = useState(false)
  let fatchSpaceData = () => {
    setShowPageLoader(true)
    dispatch(
      selectSpace({
        id: id,
        callBack: (response) => {
          console.log(response.data.photos);
          setImageList(response.data.photos)
          setlistOfPriviewImage([])
          setShowPageLoader(false)
        },
      }),
    )
  }

  let savePhotos = async () => {
    setShowPhotoUploadBox(!showPhotoUploadBox)
    await storeImages(listOfPriviewImage)
    // for(let i = 0;i<listOfPriviewImage.length;i++){
    //   console.log(listOfPriviewImage);
    // }
    document.getElementById('uploadCaptureInputFile').value = ''
    
  }

  const getBase64 = (file) => {
    return new Promise( async (resolve,reject)=>{
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = async function (error) {
        reject(error)
      };
    })
  }

  const storeImages = (images) => {
    return new Promise( async (resolve,reject) => {
      setShowPageLoader(true)
      dispatch(
        createImage({
          property: id,
          images: images,
          callBack: (response) => {
            fatchSpaceData()
            resolve()
          },
        }),
      )
    })
  }

  let showImageEditBox = (id, visible) => {
    let box = document.getElementById(id)
    // box.classList.toggle('hide-elements')
    if (visible) {
      box.classList.remove('hide-elements')
    }

    if (!visible) {
      box.classList.add('hide-elements')
    }
  }

  let handelDeleteImage = (m) => {
    if (m.image != placeHolderImage) {
      dispatch(
        deleteImage({
          id: m.id,
          callBack: () => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  setListOfImage(response.data.photos)
                  NotificationManager.success(
                    `Successfully updated`,
                    'Image',
                    700,
                  )
                },
              }),
            )
          },
        }),
      )
    }
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <div className="space-photo-insert-form">
        <div className="file-upload-group">
          <h1>Let’s add photos of your space</h1>
          <p>
            Photos are the first thing your potential guests will see. We
            recommend adding 8 or more high quality photos.
          </p>
          <p>Photo size must be 5MB or less.</p>
          <div className="photo_upload_box">
            <p>Please add at least 4 photos of your space</p>
            <div
              className="file_upload_button"
              onClick={() => {
                setShowPhotoUploadBox(true)
              }}
            >
              {/* <input type="file" name="" id="" /> */}
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 0,
                  width: '100%',
                  color: '#000',
                }}
              >
                {' '}
                <UploadOutlined
                  style={{ marginRight: 10 }}
                  className="upload_icon_antd"
                />
                Upload Photo
              </p>
            </div>
          </div>
        </div>
        <div
          className={`photo-upload-place-holder-wrapper ${
            !showPhotoUploadBox ? 'hide-elements' : ''
          }`}
        >
          <div className="photo-upload-place-holder">
            <div
              className="close-button"
              onClick={() => {
                document.getElementById('uploadCaptureInputFile').value = ''
                setlistOfPriviewImage([])
                setShowPhotoUploadBox(false)
              }}
            >
              x
            </div>
            <div
              className="file-upload-group"
              // style={{ padding: '50px 0px 0px 94px' }}
              // style={{ border: '1px solid red' }}
            >
              <p>
                Photos are the first thing your potential guests will see. We
                recommend adding 8 or more high quality photos.
              </p>
              <p>Photo size must be 5MB or less.</p>
            </div>

            {listOfPriviewImage && listOfPriviewImage.length > 0 && (
              <div className="photo-upload-box">
                {listOfPriviewImage.map((img, index) => {
                  return (
                    <div
                      className="photo-upload-placeholder"
                      onClick={() => {
                        let new_array = listOfPriviewImage
                        new_array.splice(index, 1)
                        setlistOfPriviewImage([...new_array])
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundImage = `url(${trash})`
                        e.target.style.backgroundSize = '35px'
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundImage = `url(${img})`
                        e.target.style.backgroundSize = 'contain'
                      }}
                      style={{
                        backgroundImage: `url(${img})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                      }}
                    >
                      {/* <div className="delete-image-from-preview">X</div> */}
                      {/* <img src={img} alt="" srcSet="" /> */}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="photo-upload-box-button">
              <div className="photo-upload-box-button photo-upload-box-button-box">
                <img
                  className="upload-photo-button-side-image"
                  src={uploadButtonImage}
                  alt=""
                  srcSet=""
                />
                <input
                  id="uploadCaptureInputFile"
                  multiple
                  onChange={handleImageUpload}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                />
                Upload Photo
              </div>
              <div className="done-button" onClick={savePhotos}>
                <img
                  className="upload-done-button-side-image"
                  src={doneButtonImage}
                  alt=""
                  srcSet=""
                />
                Done
              </div>
            </div>
          </div>
        </div>
        <hr />
        <h1 className="uploaded-picture-visualisation-title">
          *Drag to reorder. Click “Edit” to write description for each photo
        </h1>
        <div
          style={{ position: 'relative' }}
          className="uploaded-picture-visualisation"
        >
          {showPageLoader ? (
            <div
              className="loader"
              style={{ height: '40px', width: '40px', left: '15pc' }}
            ></div>
          ) : listOfImage.length > 0 ? (
            listOfImage.map((m, index) => {
              let src =
                placeHolderImage == m.image
                  ? placeHolderImage
                  : baseURL2 + m.image
              return (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${src})`,
                      position: 'relative',
                      // cursor:"grab"
                      backgroundSize: `${
                        src == placeHolderImage ? '' : 'contain'
                      }`,
                    }}
                    draggable="true"
                    key={m.id + 'adsasd'}
                    index={index}
                    onDragOver={handleDragOver}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragLeave}
                    onDragEnter={handleDragEnter}
                    className="uploaded-picture draggable-cursore"
                  >
                    {index == 0 ? (
                      <p className="thumbnail-message">Cover Photo</p>
                    ) : (
                      ''
                    )}
                    <button
                      onFocusCapture={() => {
                        if (m.image != placeHolderImage)
                          showImageEditBox(`${m.id}-image-edit-box`, true)
                      }}
                      onBlurCapture={() => {
                        if (m.image != placeHolderImage)
                          showImageEditBox(`${m.id}-image-edit-box`, false)
                      }}
                      className="dotted-button"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <div
                        className="image-manu hide-elements"
                        id={`${m.id}-image-edit-box`}
                      >
                        <p
                          onClick={() => {
                            handelDeleteImage(m)
                          }}
                        >
                          {' '}
                          delete{' '}
                        </p>
                        <p
                          onClick={() => {
                            handelShowPictureDetailsBox(m)
                          }}
                        >
                          {' '}
                          edit{' '}
                        </p>
                      </div>
                    </button>
                  </div>
                </>
              )
            })
          ) : (
            <img src={image_placeholder} alt="" srcSet="" />
          )}
        </div>
        <hr />
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/about`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button
            type="submit"
            onClick={() => {
              navigate(`/space/add/${id}/availability`)
            }}
          >
            {' '}
            Save & Continue{' '}
          </button>
        </div>
        {/* { showPictureDetailsBox ? <PhotoDescription selectedImage={selectedImage} hideBox ={hideDescriptionBox} /> : '' } */}
        {/* ${!showPictureDetailsBox ? 'hide-elements': ''} */}
        {showPictureDetailsBox ? (
          <div className={`show-picture-information-wrapper `}>
            <form ref={formRef} className="show-picture-information">
              <div
                style={{
                  backgroundImage: `url(${baseURL2 + selectedImage.image})`,
                  backgroundSize: 'contain',
                }}
                className="picture"
              ></div>
              <label htmlFor="short_description">Short Description</label>
              <textarea
                value={selectedImage.description}
                onChange={(e) => {
                  setSelectedImage({
                    ...selectedImage,
                    description: e.target.value,
                  })
                }}
                id="short_description"
                name="short_description"
                cols="30"
                rows="10"
              ></textarea>
              <span>{selectedImage.description.length}/50</span>
              <hr />
              <div className="show-picture-informationbutton-group">
                <div
                  onClick={() => {
                    setSelectedImage({
                      id: null,
                      description: null,
                      image: placeHolderImage,
                    })
                    setshowPictureDetailsBox(!showPictureDetailsBox)
                  }}
                  className=""
                >
                  {' '}
                  cancel{' '}
                </div>
                <div onClick={handleUpdateImageDescription} className="">
                  <img src={doneButtonImage} alt="" srcSet="" />
                  save
                </div>
              </div>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

let PhotoDescription = (selectedImage, hideBox) => {
  const [description, setDescription] = useState(null)
  let handleUpdateImageDescription = () => {
    console.log('hello')
  }
  return (
    <div className={`show-picture-information-wrapper `}>
      <form className="show-picture-information">
        <div
          style={{ backgroundImage: `url(${baseURL2 + selectedImage.image})` }}
          className="picture"
        ></div>
        <label htmlFor="short_description">Short Description</label>
        <textarea
          id="short_description"
          name="short_description"
          cols="30"
          rows="10"
        ></textarea>
        <span>0/50</span>
        <hr />
        <div className="show-picture-informationbutton-group">
          <div
            onClick={() => {
              hideBox()
            }}
            className=""
          >
            {' '}
            cancel{' '}
          </div>
          <div onClick={handleUpdateImageDescription} className="">
            <img src={doneButtonImage} alt="" srcSet="" />
            save
          </div>
        </div>
      </form>
    </div>
  )
}
