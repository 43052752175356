import React from 'react'
import { Modal, Tag } from 'antd'
import moment from 'moment'
import { QuestionCircleFilled, TagFilled } from '@ant-design/icons'

function PaymentInformationModal(props) {
  const { payInfoModalVis, setPayInfoModalVis, data } = props
  // console.log(`PaymentInformationModal data: `)
  // console.log(data)

  const startDate = moment(`${data.start_date} ${data.start_time}`)
  const endDate = moment(`${data.end_date} ${data.end_time}`)

  const dayDifference = endDate.diff(startDate, 'days') + 1
  const hourDifference = endDate.diff(startDate, 'hours') % 24

  // console.log('day:' + dayDifference)
  // console.log('hour:' + hourDifference)
  // console.log(data.price.pricing_condition.id == 1 ? 'hourly' : 'day')

  return (
    <Modal
      open={payInfoModalVis}
      title={`Payment Information`}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setPayInfoModalVis(false)}
      onCancel={() => setPayInfoModalVis(false)}
    >
      <div
        style={{ border: '1px solid #f7f7f7', borderRadius: 20, padding: 10 }}
      >
        <p style={{ fontSize: 16, fontWeight: '500' }}>Price break down</p>
        <div>
          <div style={styles.flexSpaceBetween}>
            <p>
              ${data.price.price} <span style={{ fontWeight: '500' }}>X</span>{' '}
              {data.price.pricing_condition.id == 1 &&
                `${hourDifference} hours`}
              {data.price.pricing_condition.id == 1 && (
                <span style={{ fontWeight: '500' }}> X</span>
              )}{' '}
              {dayDifference} days{' '}
              <QuestionCircleFilled style={{ color: '#CCCCCC' }} />
            </p>
            <p>
              $
              {data.price?.price *
                (data.price?.pricing_condition?.id == 1
                  ? hourDifference * dayDifference
                  : dayDifference)}
            </p>
          </div>
          <div style={styles.flexSpaceBetween}>
            <p>
              Cleaning fee <QuestionCircleFilled style={{ color: '#CCCCCC' }} />
            </p>
            <p>${data.cleaning_fee.toFixed(2)}</p>
          </div>
          <div style={styles.flexSpaceBetween}>
            <p>
              Service fee <QuestionCircleFilled style={{ color: '#CCCCCC' }} />
            </p>
            <p>${(data.service_fee + data.transaction_fee).toFixed(2)}</p>
          </div>
          {/* <div style={styles.flexSpaceBetween}>
            <p>
              Taxs <QuestionCircleFilled style={{ color: '#CCCCCC' }} />
            </p>
            <p>$ Empty</p>
          </div> */}
          <div style={styles.flexSpaceBetween}>
            <p>
              {data.price?.minimum_hour_for_discount}+ hours discount{' '}
              <QuestionCircleFilled style={{ color: '#CCCCCC' }} />
            </p>
            <p>$ {data.discount.toFixed(2)} </p>
          </div>
        </div>
        <hr style={{ borderTop: '1px solid #f0f0f0' }} />
        <div style={{ marginTop: 20 }}>
          <div style={styles.flexSpaceBetween}>
            <p style={{ fontWeight: '500' }}>Sub total</p>
            <p>
              $
              {(
                data.price?.price *
                  (data.price?.pricing_condition?.id == 1
                    ? hourDifference * dayDifference
                    : dayDifference) +
                data.service_fee +
                data.transaction_fee
              ).toFixed(2)}
            </p>
          </div>
          <div style={styles.flexSpaceBetween}>
            <div style={{ display: 'flex' }}>
              Discount{' '}
              {data?.coupon && (
                <div style={{ display: 'flex', marginLeft: 2 }}>
                  <Tag
                    color="white"
                    icon={<TagFilled style={{ color: 'black' }} />}
                  >
                    <span style={{ color: 'black' }}>{data?.coupon?.code}</span>
                  </Tag>
                </div>
              )}
            </div>
            <p>- ${data?.coupon_discount}</p>
          </div>
        </div>
        <hr style={{ borderTop: '1px solid #f0f0f0' }} />
        <div style={{ marginTop: 20 }}>
          <div style={styles.flexSpaceBetween}>
            <p style={{ fontWeight: '500' }}>Total</p>
            <p>${data.total_amount.toFixed(2)}</p>
          </div>
        </div>
        {/* // ? Below commented section is for card info */}
        <hr style={{ borderTop: '2px solid #000' }} />
        <div style={{ marginTop: 20 }}>
          <p style={{ fontSize: 16, fontWeight: '500' }}>Payment</p>
          <div style={styles.flexSpaceBetween}>
            {/* <p>{JSON.stringify(data.payment_info.brand)}</p> */}
            <p>
              {data.payment_info?.brand?.toUpperCase()} ****{' '}
              {data.payment_info?.last4}
            </p>
            <p>${data.total_amount.toFixed(2)}</p>
          </div>
          {/* <p style={{}}>March 20,2023. 12:34:10 PM EST</p> */}
          <p style={{}}>
            {moment(data.created).format('MMMM DD,YYYY. hh:mm:ss A z')}
            {/* {moment(data.created)
              .tz('America/New_York')
              .format('MMMM DD,YYYY. hh:mm:ss A z')} */}
          </p>
        </div>
        <hr style={{ borderTop: '1px solid #f0f0f0' }} />
        <div style={{ marginTop: 20 }}>
          <div style={styles.flexSpaceBetween}>
            <p style={{ fontWeight: '500' }}>Amount Paid (USD)</p>
            <p>${data.total_amount.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const styles = {
  flexSpaceBetween: { display: 'flex', justifyContent: 'space-between' },
}

export default PaymentInformationModal
