import React, { useReducer } from 'react'
import { Button, message, Modal } from 'antd'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { TopMsg } from '../Topmsg'
import Button1 from '../buttons/Button1'

const AddCardDetails = (props) => {
  const {
    addCardModal,
    setaddCardModal,
    title,
    cardNumber,
    setcardNumber,
    expDate,
    setexpDate,
    zipCode,
    setzipCode,
    cvc,
    setCvc,
    newCardAdded,
    setnewCardAdded,
  } = props
  const handleOk = () => {
    createList()
    setaddCardModal(false)
  }
  const handleCancel = () => {
    setaddCardModal(false)
  }


  const baseURL = process.env.REACT_APP_BASE_URL

  const cookies = new Cookies()
  const authData = cookies.get('jwt')
  const createList = async () => {
    try {
      const res = await axios.post(
        `${baseURL}/guest/paymnet-method`,
        {
          number: cardNumber,
          exp_month: expDate.split('/')[0],
          exp_year: expDate.split('/')[1],
          cvc: cvc,
        },
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      console.log(res.data)
      setcardNumber('')
      setexpDate('')
      setCvc('')
      setzipCode('')
      setnewCardAdded(!newCardAdded)
      message.success(TopMsg('Card added successfully'))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Modal
        title={title ? title : ''}
        cancelButtonProps={displayNone}
        width={680}
        okText="Add"
        okButtonProps={{ style: { display: 'none' } }}
        visible={addCardModal}
        onOk={() => handleOk()}
        onCancel={handleCancel}
        style={{ top: '16%' }}
        >
        <input
          className="input-card-number top"
          type="text"
          placeholder="Card Number"
          value={cardNumber}
          onChange={(e) => {
            setcardNumber(e.target.value)
          }}
          />
        <div className="bottom">
          <input
            className="input-card-number exp"
            type="text"
            placeholder="Expiration date"
            value={expDate}
            onChange={(e) => setexpDate(e.target.value)}
            />
          <input
            className="input-card-number cvv bottom"
            type="text"
            placeholder="CVV"
            value={cvc}
            onChange={(e) => setCvc(e.target.value)}
          />
        </div>
        <input
          className="input-card-number zip"
          type="text"
          placeholder="Zip code"
          value={zipCode}
          onChange={(e) => setzipCode(e.target.value)}
        />
        <div onClick={() => handleOk()} style={{ marginLeft: '80%', marginTop: '5%' }}>
          <Button1 title="Add" width={108} height={48} />
        </div>
      </Modal>
    </>
  )
}

const displayNone = { style: { display: 'none' } }
export default AddCardDetails
