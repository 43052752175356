import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { ActionType } from '../../redux/contants/actions-type'
import { Link } from 'react-router-dom'
import { refresh } from '../../redux/actions/AuthAction'
import StatusBox from '../../components/StatusBox'
import { setBanner } from '../../redux/actions/ComponentAction'
import { setSpace } from '../../redux/actions/SpaceAction'
import { setReservation } from '../../redux/actions/PropertyAction'
import ResarvationDetailsCardV2 from './ResarvationDetailsCardV2'
import ResarvationCard from '../../components/ResarvationCard'
import CancelledRequestComponent from './AllRequestComponent/CancelledRequestComponent'
import AllReservationRequestComponent from './AllRequestComponent/AllReservationRequestComponent'

export default function AllReservations() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [resarvations, setResarvations] = useState([])
  const [selectedReservation, setSelectedReservation] = useState(null)
  const [resarvationDetailsCardType, setResarvationDetailsCardType] =
    useState('AllReservation')

  useEffect(() => {
    if (loading) {
      console.log('loading...')
      dispatch(
        setSpace({
          callBack: (response) => {
            let has_space = Object.keys(response.data).length > 0
            dispatch(
              setBanner({
                header: 'Today',
                discription: has_space
                  ? 'Review all of your reservations in this page.'
                  : 'Complete your first listing and you’ll be a host soon!',
                button: {
                  visible: !has_space,
                  link: '/space/add/new',
                  name: 'Create Space',
                },
                progress_bar: {
                  visible: false,
                  progress: 0,
                },
                pages: {
                  visible: false,
                  total_page: 0,
                  page_number: 0,
                },
                callBack: () => {
                  // dispatch( setReservation({
                  //     type:'all',
                  //     callBack:(response)=>{
                  //         setResarvations(response.data);
                  //     }
                  // }))
                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])
  const [reRenderComponent, setReRenderComponent] = useState(false)
  let handleReload = () => {
    setLoading(true)
    setReRenderComponent(!reRenderComponent)
  }
  return (
    <>
      {/* {
                resarvations.length > 0 ? resarvations.map((resarvation,index)=>{
                    return <ResarvationCard pageName={"AllReservation"} key={index} resarvation={resarvation} setSelectedReservation = {setSelectedReservation}/>
                }) : (
                    <div className="data-showing-box">
                        <p>You don’t have any guest renting your space today</p>
                    </div>
                )
            } */}
      {/* <button onClick={handleReload}> hello </button> */}
      <AllReservationRequestComponent
        setReRenderComponent={setReRenderComponent}
        reRenderComponent={reRenderComponent}
        title={'Pending'}
        type={'PENDING'}
        limit={2}
        setSelectedReservation={setSelectedReservation}
        setResarvationDetailsCardType={setResarvationDetailsCardType}
      />
      <AllReservationRequestComponent
        setReRenderComponent={setReRenderComponent}
        reRenderComponent={reRenderComponent}
        title={'Upcoming'}
        type={'CONFIRMED'}
        limit={2}
        setSelectedReservation={setSelectedReservation}
        setResarvationDetailsCardType={setResarvationDetailsCardType}
      />
      <AllReservationRequestComponent
        setReRenderComponent={setReRenderComponent}
        reRenderComponent={reRenderComponent}
        title={'Cancelled'}
        type={'CANCELED'}
        limit={2}
        setSelectedReservation={setSelectedReservation}
        setResarvationDetailsCardType={setResarvationDetailsCardType}
      />
      <AllReservationRequestComponent
        setReRenderComponent={setReRenderComponent}
        reRenderComponent={reRenderComponent}
        title={'Completed '}
        type={'PAST'}
        limit={2}
        setSelectedReservation={setSelectedReservation}
        setResarvationDetailsCardType={setResarvationDetailsCardType}
      />
      {selectedReservation != null ? (
        <div className="reservation-details">
          <ResarvationDetailsCardV2
            pageName={resarvationDetailsCardType}
            resarvation={selectedReservation}
            setSelectedReservation={setSelectedReservation}
            resetData={handleReload}
          />
        </div>
      ) : (
        ''
      )}
      {/* { 
                selectedReservation != null ? (
                    <div className="reservation-details">
                        <ResarvationDetailsCard pageName={"acceptedBooking"} resarvation={selectedReservation} setSelectedReservation={setSelectedReservation} />
                    </div>
                ) : ''
            } */}
    </>
  )
}
