import React, { useState, useEffect } from 'react'
import polygon_1 from './../img/polygon_1.png'
import { useSelector, useDispatch } from 'react-redux'
import {
  createWindowBookingRole,
  deleteWindowBookingRole,
  updateWindowBookingRole,
} from '../redux/actions/WindowBookingRoleAction'
import { setInformation } from '../redux/actions/InformationAction'
import { setSpace } from '../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import {
  createBlockedDate,
  setBlockedDate,
} from '../redux/actions/BlockedDateAction'
import SingleBlockedDate from './SingleBlockedDate'

export default function BlockedDateList() {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [allProperties, setAllProperties] = useState(null)
  const [allBlockedDate, setAllBlockedDate] = useState(null)
  useEffect(() => {
    if (loading) {
      setData()
    }

    return () => {
      setLoading(false)
    }
  }, [loading])

  let setData = () => {
    dispatch(
      setBlockedDate({
        callBack: (response) => {
          setAllProperties(response.data.all_property)
          setAllBlockedDate(response.data.blocked_dates)
        },
      }),
    )
  }

  let create_blocked_date = () => {
    dispatch(
      createBlockedDate({
        callBack: (response) => {
          setData()
        },
      }),
    )
  }

  return (
    <div className="blocked-date-section">
      <h1>Blocked Date</h1>
      <p>Add all of your date that you dont want a guest to book </p>
      {allBlockedDate &&
        allBlockedDate.map((blocked_date) => {
          return (
            <SingleBlockedDate
              setData={setData}
              properties={allProperties}
              blocked_date={blocked_date}
            />
          )
        })}
      <button type="button" onClick={create_blocked_date}>
        {' '}
        + Add blocked date{' '}
      </button>
    </div>
  )
}
