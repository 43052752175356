const get12HourFormat = (item) => {
  return new Date('1970-01-01T' + item + 'Z').toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
}

export default get12HourFormat
