import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval'
import './../style/space.css'
import StatusBox from '../components/StatusBox'
import ArrowImage from './../img/polygon_1.png'
import PlusImage from './../img/plus_symbol.png'
import SpaceListItem from '../components/SpaceListItem'

function AddPaymentLayOut() {
  const [collapsed, setCollapsed] = useState(false)
  const banner = useSelector((state) => state.component.banner)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [usrData, setUsrData] = useState({})
  let navigate = useNavigate()
  const [rotateArroButton, setRotateArroButton] = useState(false)
  const [rotateArroButton2, setRotateArroButton2] = useState(false)
  // useEffect(() => {
  //   if(auth.user.name == null){
  //       dispatch(me())
  //   }
  // }, [])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);

  return (
    <main>
      <aside className="main-content-aside">
        <div className="space-side-nevbar">
          <ul>
            <li className="space-list">
              <NavLink
                to="/payment/revenue"
                className={({ isActive }) => {
                  setRotateArroButton(isActive)
                  return isActive ? 'active' : ''
                }}
              >
                Revenue
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />
              <ul
                className={`space-list-child ${
                  !rotateArroButton ? 'hide-elements' : ''
                }`}
              >
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/revenue/income"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    Income
                  </NavLink>
                </li>
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/revenue/transaction-history"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    Transaction History
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="space-list">
              <NavLink
                to="/payment/banking"
                className={({ isActive }) => {
                  setRotateArroButton2(isActive)
                  return isActive ? 'active' : ''
                }}
              >
                Banking
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton2 ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />
              <ul
                className={`space-list-child ${
                  !rotateArroButton2 ? 'hide-elements' : ''
                }`}
              >
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/banking/payout-details"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    Payout details
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
      <section className="main-content">
        {/* <StatusBox /> */}
        <Outlet />
      </section>
    </main>
  )
}

export default AddPaymentLayOut
