import { ImageActionType } from '../contants/actions-type'
const initialState = {
  images: [],
  image: {},
}

export default function ImageReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ImageActionType.SET_IMAGE:
      return {
        ...state,
        ...payload,
      }

    case ImageActionType.SELECT_IMAGE:
      return {
        ...state,
        ...payload,
      }

    case ImageActionType.CREATE_IMAGE:
      return {
        ...state,
        ...payload,
      }

    case ImageActionType.UPDATE_IMAGE:
      return {
        ...state,
        ...payload,
      }

    case ImageActionType.REMOVE_IMAGE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
