import React from 'react'
import { Spin } from 'antd'
import '../../style/button1.css'

const Button1 = ({ title, bgColor, color, margin, width, height }) => {
  return (
    <div
      className="btn__1"
      style={{
        backgroundColor: bgColor,
        border: '1px #6567e6e6 solid',
        margin: margin ? margin : 0,
        width: width ? width : 260,
        height: height && height,
      }}
    >
      <label style={{ color: color, cursor: 'pointer' }}>{title}</label>
    </div>
  )
}

export default Button1
