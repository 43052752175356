import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import arrowDown from './../../img/arrow_down_1.png'
import poligondown from './../../img/polygon_2.png'
import poligonup from './../../img/polygon_3.png'
import ActivitieBox from '../../components/ActivitieBox'
import { getAllInputValue } from '../../redux/requests'
import { time_list, tConvert } from '../../redux/requests'
import { createPricing, updatePricing } from '../../redux/actions/PricingAction'
import { selectSpace, setSpace } from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { setInformation } from '../../redux/actions/InformationAction'

export default function ActivitiesOfSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  // const pricing = useSelector(state => state.information.form_data.pricing)
  const [pricing, setPricing] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Activities and Pricing',
          discription:
            'Set pricing and best activities that work well with this listing',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100 / 7) * 5,
          },
          pages: {
            visible: true,
            total_page: 5,
            page_number: 6,
          },
          callBack: () => {
            dispatch(
              setInformation({
                callBack: (response) => {
                  setPricing(response.data.form_data.pricing)
                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])
  const [activitiesDataFromForm, setActivitiesDataFromForm] = useState({})
  let setActivitiesFormData = (data) => {
    let formData = {}
    formData[data.Pricing_type] = data
    setActivitiesDataFromForm({ ...activitiesDataFromForm, ...formData })
  }

  let handleSubmit = async (e) => {
    e.preventDefault()
    let isAllValid = []
    for (let key in activitiesDataFromForm) {
      let is_valide = validateData(activitiesDataFromForm[key])
      isAllValid.push(is_valide)
    }

    if (!isAllValid.includes(false)) {
      for (let key in activitiesDataFromForm) {
        createOrUpdateActivities(activitiesDataFromForm[key])
      }
      navigate(`/space/add/${id}/amenities`)
    }
  }

  let validateData = (data) => {
    let pricing_type_title = pricing[data.Pricing_type].title

    if (parseInt(data.pricing_condition) == 2) {
      if (data.time_start == undefined || data.time_end == undefined) {
        NotificationManager.warning(
          `please enter start and end time ${pricing_type_title}`,
          pricing_type_title,
          3000,
        )
        return false
      }

      if (data.time_start != undefined || data.time_end != undefined) {
        var new_start_time = new Date(`1/1/2020 ${data.time_start}`)
        var new_end_time = new Date(`1/1/2020 ${data.time_end}`)
        if (new_start_time > new_end_time) {
          NotificationManager.warning(
            `start time cannot be greater then end time for ${pricing_type_title}`,
            pricing_type_title,
            3000,
          )
          return false
        }
      }
    }
    if (parseInt(data.pricing_condition) != 2) {
      console.log(data.pricing_condition)
      if (
        data.minimum_number_of_hours < 1 ||
        data.minimum_number_of_hours > 23
      ) {
        NotificationManager.warning(
          `Minimum number of hours cannot be more than 23 or less than 1 value for ${pricing_type_title}`,
          pricing_type_title,
          8000,
        )
        return false
      }
    }

    if (data.price.length != 0 && data.price < 0) {
      NotificationManager.warning(
        `price cannot be a negetive value for ${pricing_type_title}`,
        pricing_type_title,
        3000,
      )
      return false
    }

    if (data.discount.length != 0 && data.discount < 0) {
      NotificationManager.warning(
        `discount cannot be a negetive value for ${pricing_type_title}`,
        pricing_type_title,
        3000,
      )
      return false
    }

    if (data.discount.length != 0 && data.discount > 100) {
      NotificationManager.warning(
        `discount cannot be a greater then 100% for ${pricing_type_title}`,
        pricing_type_title,
        3000,
      )
      return false
    }

    if (data.price.length != 0 && data.price < 0) {
      NotificationManager.warning(
        `price cannot be a negetive value for ${pricing_type_title}`,
        pricing_type_title,
        3000,
      )
      return false
    }

    if (data.price.length == 0) {
      NotificationManager.warning(
        `please enter your space pricing for ${pricing_type_title}`,
        pricing_type_title,
        3000,
      )
      return false
    }

    if (data.cleaning_fee == 2) {
      if (data.additional_cleaning_fee == undefined) {
        NotificationManager.warning(
          `please enter your addintional cleaning fee for ${pricing_type_title}`,
          pricing_type_title,
          3000,
        )
        return false
      }
    }

    return true
  }

  let createOrUpdateActivities = (data) => {
    if (data.id != null) {
      dispatch(
        updatePricing({
          id: data.id,
          ...data,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: data['property'],
                callBack: (response) => {
                  dispatch(
                    setInformation({
                      callBack: (response) => {
                        // NotificationManager.success(`Successfully Updated`,'pricing',700)
                      },
                    }),
                  )
                },
              }),
            )
          },
        }),
      )
    }

    if (data.id == null) {
      dispatch(
        createPricing({
          ...data,
          callBack: (response) => {
            if (response.status == 400) {
              Object.keys(response.data).map((key) => {
                // NotificationManager.warning(`${response.data[key]}`,key.replaceAll('_',' ').toLowerCase(),3000)
                // navigate(-1)
              })
            } else {
              dispatch(
                setInformation({
                  callBack: (response) => {
                    dispatch(
                      selectSpace({
                        id: data['property'],
                        callBack: (response) => {
                          // NotificationManager.success(`Successfully created`,'pricing',700)
                        },
                      }),
                    )
                  },
                }),
              )
            }
          },
        }),
      )
    }
    NotificationManager.success(`Successful`, 'pricing', 700)
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <div className="space-setup-inserte-activities-form">
        <div className="radio-button-group">
          <h1 className="radio-button-group-input-lable">
            Which type of activities would you like to host in this space?
          </h1>
          <p className="radio-button-group-input-lable-description">
            Please select all event types that you would like to host for this
            space:
          </p>
          <div>
            {!pricing && (
              <Spin
                size="large"
                style={{ width: '100%', margin: '40px auto' }}
              />
            )}
          </div>
          <div className="activities-box">
            {pricing &&
              Object.keys(pricing).map((key, index) => {
                return (
                  <ActivitieBox
                    setActivitiesFormData={setActivitiesFormData}
                    pricing={pricing[key]}
                    property_id={id}
                  />
                )
              })}
          </div>
        </div>
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/availability`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="submit" onClick={handleSubmit}>
            {' '}
            Save & Continue{' '}
          </button>
        </div>
      </div>
    </div>
  )
}

export function EventForm() {
  return (
    <>
      <form action="" className="event-form">
        <hr />
        <div className="pricing-conditions-group">
          <h1 className="pricing-conditions-group-heading">
            Pricing Conditions
          </h1>
          <div className="event-pricing-form-radio-button-group">
            <div className="hourly-rate-radio-button">
              <input
                type="radio"
                id="event_hourly_rate"
                value="hourly"
                name="event_rate"
              />
              <label htmlFor="event_hourly_rate">Hourly Rate</label>
            </div>
            <div className="fixed-rate-radio-button">
              <input
                type="radio"
                id="event_fixed_rate"
                value="fixed"
                name="event_rate"
              />
              <label htmlFor="event_fixed_rate">Fixed Rate</label>
            </div>
          </div>
        </div>
        <div className="price-input-box-group">
          <span>$</span>
          <input type="number" name="event_price" />
        </div>

        <hr className="event-form-hr" />
        <div className="minimum-number-of-hours">
          <h1 className="minimum-number-of-hours-heading">
            Minimum number of hours
          </h1>
          <input type="number" name="event_minimum_number_of_hours" min="0" />
        </div>

        <hr className="event-form-hr" />
        <div className="hour-discount">
          <h1 className="8-hour-discount-heading">8+ hour discount</h1>
          <input type="number" name="event_8_hour_discount" min="0" />
        </div>

        <hr className="event-form-hr" />
        <div className="cleaning-fee-conditions-group">
          <h1 className="cleaning-fee-heading">Cleaning Fee</h1>
          <div className="event-cleaning-fee-form-radio-button-group">
            <div className="hourly-rate-radio-button">
              <input
                type="radio"
                id="event_default_rate"
                value={'event_default_rate'}
                name="Event_cleaning_rate"
              />
              <label htmlFor="event_default_rate">
                Default: Included in the pricing
              </label>
            </div>
            <div className="fixed-rate-radio-button">
              <input
                type="radio"
                id="event_additional_rate"
                value={'event_additional_rate'}
                name="Event_cleaning_rate"
              />
              <label htmlFor="event_additional_rate">Additional flat fee</label>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export function MeetingsClassesRehearsalsForm({ pricing, property_id }) {
  let formRef = createRef()
  const [timeRange, setTimeRange] = useState({
    start: '8:00 am',
    end: '10:00 pm',
  })
  const pricing_condition_option = useSelector(
    (state) => state.information.form_data.pricing_condition_option,
  )
  const cleaning_fee_option = useSelector(
    (state) => state.information.form_data.cleaning_fee_option,
  )
  const prev_pricing = useSelector(
    (state) => state.space.space.activities_and_pricing,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (prev_pricing[pricing.id]) {
      formRef.current.elements.pricing_condition.value =
        prev_pricing[pricing.id].pricing_condition.id
      formRef.current.elements.pricing_condition.value =
        prev_pricing[pricing.id].pricing_condition.id
      formRef.current.elements.price.value = prev_pricing[pricing.id].price
      formRef.current.elements.cleaning_fee.value =
        prev_pricing[pricing.id].cleaning_fee.id
      formRef.current.elements.minimum_number_of_hours.value =
        prev_pricing[pricing.id].minimum_number_of_hours
      formRef.current.elements.discount.value =
        prev_pricing[pricing.id].discount

      if (pricing.show_time_range) {
        formRef.current.elements.time_start.value =
          prev_pricing[pricing.id].time_start
        formRef.current.elements.time_end.value =
          prev_pricing[pricing.id].time_end
      }
    }
  }, [5])

  let handelSubmit = (e) => {
    e.preventDefault()
    let form = e.target.elements
    let data = {
      property: property_id,
      Pricing_type: pricing.id,
      pricing_condition: form.pricing_condition.value,
      price: form.price.value,
      cleaning_fee: form.cleaning_fee.value,
      minimum_number_of_hours: form.minimum_number_of_hours.value,
      discount: form.discount.value,
    }

    if (form.time_start && form.time_start.value)
      data.time_start = form.time_start.value
    if (form.time_end && form.time_end.value)
      data.time_end = form.time_end.value
    if (form.day_start && form.day_start.value)
      data.day_start = form.day_start.value
    if (form.day_end && form.day_end.value) data.day_end = form.day_end.value
    console.log(data)
    if (prev_pricing[pricing.id]) {
      dispatch(
        updatePricing({
          id: prev_pricing[pricing.id].id,
          ...data,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: property_id,
                callBack: (response) => {
                  NotificationManager.success(
                    `Successfully Updated`,
                    'pricing',
                    700,
                  )
                },
              }),
            )
          },
        }),
      )
      return true
    }

    dispatch(
      createPricing({
        ...data,
        callBack: (response) => {
          dispatch(
            selectSpace({
              id: property_id,
              callBack: (response) => {
                NotificationManager.success(
                  `Successfully created`,
                  'pricing',
                  700,
                )
              },
            }),
          )
          console.log(response)
        },
      }),
    )
  }

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handelSubmit}
        className="event-form"
        id={pricing.title.replaceAll(' ', '_').toLowerCase() + '_form'}
      >
        <hr />
        <div
          style={{ marginBottom: '10px' }}
          className="pricing-conditions-group"
        >
          <h1 className="pricing-conditions-group-heading">
            Pricing Conditions
          </h1>
          <div className="event-pricing-form-radio-button-group">
            {pricing_condition_option.map((option) => {
              let id =
                option.title.replaceAll(' ', '_').toLowerCase() +
                '_' +
                pricing.title.replaceAll(' ', '_').toLowerCase()
              return (
                <div className="hourly-rate-radio-button">
                  <input
                    type="radio"
                    id={id}
                    value={option.id}
                    name="pricing_condition"
                  />
                  <label htmlFor={id}>{option.title}</label>
                </div>
              )
            })}
          </div>
        </div>
        {pricing.show_time_range ? (
          <div className="meetings_classes_rehearsals_price_range_time">
            <span>Between</span>
            <div
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className="time-range-set"
            >
              <select
                className="meetings_classes_rehearsals_price_range_time_start"
                name="time_start"
                id="time_start"
              >
                {time_list.map((time) => {
                  return <option value={time}>{tConvert(time)}</option>
                })}
              </select>
              <p>to</p>
              <select
                className="meetings_classes_rehearsals_price_range_time_start"
                name="time_end"
                id="time_end"
              >
                {time_list.map((time) => {
                  return <option value={time}>{tConvert(time)}</option>
                })}
              </select>
            </div>
            <span>on</span>
            <div
              style={{ marginTop: '10px' }}
              className="meetings_classes_rehearsals_price_range_time_set"
            >
              Mon - Sun
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="price-input-box-group">
          <span style={{ padding: '9px' }}>$</span>
          <input type="number" name="price" />
        </div>

        <hr className="event-form-hr" />
        <div className="minimum-number-of-hours">
          <h1 className="minimum-number-of-hours-heading">
            Minimum number of hours
          </h1>
          <input type="number" name="minimum_number_of_hours" min="0" />
        </div>

        <hr className="event-form-hr" />
        <div className="hour-discount">
          <h1 className="8-hour-discount-heading">8+ hour discount</h1>
          <input type="number" name="discount" min="0" />
        </div>

        <hr className="event-form-hr" />
        <div className="cleaning-fee-conditions-group">
          <h1 className="cleaning-fee-heading">Cleaning Fee</h1>
          <div className="event-cleaning-fee-form-radio-button-group">
            {cleaning_fee_option.map((option) => {
              let id =
                option.title
                  .replaceAll(' ', '_')
                  .replaceAll(':', '')
                  .toLowerCase() +
                '_' +
                pricing.title.replaceAll(' ', '_').toLowerCase()
              return (
                <div className="hourly-rate-radio-button">
                  <input
                    type="radio"
                    id={id}
                    value={option.id}
                    name={'cleaning_fee'}
                  />
                  <label htmlFor={id}>{option.title}</label>
                </div>
              )
            })}
          </div>
        </div>
        <button type="submit" className="submit-form-button">
          {' '}
          submit{' '}
        </button>
      </form>
    </>
  )
}

export function MediaProductionForm() {
  return (
    <>
      <form action="" className="event-form">
        <hr />
        <div className="pricing-conditions-group">
          <h1 className="pricing-conditions-group-heading">
            Pricing Conditions
          </h1>
          <div className="event-pricing-form-radio-button-group">
            <div className="hourly-rate-radio-button">
              <input
                type="radio"
                id="media_production_hourly_rate"
                value="hourly"
                name="media_production_rate"
              />
              <label htmlFor="media_production_hourly_rate">Hourly Rate</label>
            </div>
            <div className="fixed-rate-radio-button">
              <input
                type="radio"
                id="media_production_fixed_rate"
                value="fixed"
                name="media_production_rate"
              />
              <label htmlFor="media_production_fixed_rate">Fixed Rate</label>
            </div>
          </div>
        </div>
        <div className="price-input-box-group">
          <span style={{ padding: '9px' }}>$</span>
          <input type="number" name="media_production_price" />
        </div>

        <hr className="event-form-hr" />
        <div className="minimum-number-of-hours">
          <h1 className="minimum-number-of-hours-heading">
            Minimum number of hours
          </h1>
          <input
            type="number"
            name="media_production_minimum_number_of_hours"
            min="0"
          />
        </div>

        <hr className="event-form-hr" />
        <div className="hour-discount">
          <h1 className="8-hour-discount-heading">8+ hour discount</h1>
          <input type="number" name="media_production_8_hour_discount" min="0" />
        </div>

        <hr className="event-form-hr" />
        <div className="cleaning-fee-conditions-group">
          <h1 className="cleaning-fee-heading">Cleaning Fee</h1>
          <div className="event-cleaning-fee-form-radio-button-group">
            <div className="hourly-rate-radio-button">
              <input
                type="radio"
                id="media_production_cleaning_rate"
                name="media_production_cleaning_rate"
                value={'default'}
              />
              <label htmlFor="media_production_cleaning_rate">
                Default: Included in the pricing
              </label>
            </div>
            <div className="fixed-rate-radio-button">
              <input
                type="radio"
                id="media_production_additional_cleaning_rate"
                name="media_production_cleaning_rate"
                value={'additional'}
              />
              <label htmlFor="media_production_additional_cleaning_rate">
                Additional flat fee
              </label>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
