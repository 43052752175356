import React, { useState, useEffect } from 'react'
import { Button, Modal, Typography, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { PaperClipOutlined } from '@ant-design/icons'

import {
  setHostPublicInformation,
  setInformation,
} from '../../redux/actions/InformationAction'

function ShareProfileModal({
  isModalVisible,
  setIsModalVisible,
  hostInformation,
}) {
  const dispatch = useDispatch()
  const [unique_id, setUnique_id] = useState(null)

  useEffect(() => {
    dispatch(
      setInformation({
        callBack: (response) => {
          console.log(response.data)
          setUnique_id(response.data.unique_id)
        },
      }),
    )
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const { Paragraph } = Typography

  return (
    <>
      <Modal
        title="Share"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <h2 style={{ fontWeight: 500, fontSize: 14 }}>Shareable Link</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Paragraph
            className="copy-link-host-profile"
            style={{
              border: '1px solid #c4c4c4',
              color: '#000',
              padding: '6px',
              borderRadius: '5px',
              display: 'flex',
              overflow: 'hidden',
              backgroundColor: '#e1e2f5',
              margin: 0,
            }}
          >
            {window.location.protocol}//{window.location.host}/profile/
            {unique_id}
          </Paragraph>
          <Button
            type="primary"
            style={{ borderRadius: 5, marginLeft: 4 }}
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.host}/profile/${unique_id}`,
              )
              message.success('Link copied to clipboard')
            }}
          >
            Copy Link
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ShareProfileModal
