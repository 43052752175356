import React, { useState, useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import googleIcon from '../icons/google.svg'
import googleLogo from './../../img/logo_googleg_48dp.svg'
import { useNavigate } from 'react-router-dom'
import {
  emailCheck,
  login,
  registration,
  sendResetPasswordEmail,
} from '../../redux/actions/AuthAction'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import { RefreshTokenSetup } from './RefreshTokenSetup'

export default function LoginForm({ data, setData, setStepper, closeModal }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    let values = {
      type: data.role == 'Renter' ? 'Guest' : 'Host',
      email: e.target.elements.email.value,
      password: e.target.elements.password.value,
    }

    setloading(true)
    dispatch(
      login({
        ...values,
        callBack: (response) => {
          setloading(false)
          let is_ok = response.status == 200
          if (is_ok) {
            NotificationManager.success(`sign in successfull`, 'Sign In', 700)
            if (response.data.type == 'Guest') {
              navigate('/')
              closeModal(false)
              setStepper('role')
            }

            if (response.data.type == 'Host') {
              closeModal(false)
              setStepper('role')
              navigate('/pending-request')
            }
          }
          if (!is_ok) {
            let warningDetails= ''
            if(response.data.detail){
            let firstLetter = response.data.detail.charAt(0);
            firstLetter = firstLetter.toUpperCase();
             warningDetails = firstLetter + response.data.detail.substr(1)
            }
            NotificationManager.warning('',`${warningDetails}`, 3000)
          }
        },
      }),
    )
  }

  let onGoogleLoginSuccess = (response) => {
    console.log(response.profileObj)
    RefreshTokenSetup(response)
  }
  let onGoogleLoginFailure = (response) => {
    console.log(response)
  }

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID,
        plugin_name: 'chat',
      })
    })
  }, [])

  const onSuccess = (response) => {
    console.log(response.profileObj)
    RefreshTokenSetup(response)

    dispatch(
      emailCheck({
        type: data.role,
        email: response.profileObj.email,
        callBack: (res) => {
          
          if (res.status == 200) {
            // checkAndLoginWithGmail(response)
            NotificationManager.warning(
              `We couldn't find your Venuely account.`,
              'sign up',
              7000,
            )
          } 
          if(res.status == 406) {
            loginWithGmail(response)
          }
        },
      }),
    )
    
  }

  let loginWithGmail = (response) => {
    dispatch(
      registration({
        google_login: true,
        type: data.role,
        name: response.profileObj.name,
        email: response.profileObj.email,
        callBack: (response) => {
          // setloading(false)
          let is_ok = response.status == 200
          if (is_ok) {
            // setStepper('confitmEmail')
            // console.log(data.role);
            if (data.role == 'Renter') {
              closeModal(false)
              setStepper('role')
              navigate('/')
            }
            if (data.role == 'Host') {
              closeModal(false)
              setStepper('role')
              navigate('/pending-request')
            }
            NotificationManager.success(
              response.data.details,
              'Registration',
              700,
            )
          }
        },
      }),
    )
  }

  let checkAndLoginWithGmail = (response) => {
    let agree_terms_of_service =
      document.getElementById('agree_terms_of_service') &&
      document.getElementById('agree_terms_of_service').checked

    let agree_privacy_policy =
      document.getElementById('agree_privacy_policy') &&
      document.getElementById('agree_privacy_policy').checked

    if (!agree_terms_of_service || !agree_privacy_policy) {
      NotificationManager.warning(
        `you need to agree our terms and conditions`,
        'Terms And Condition',
        3000,
      )
    }
    if (agree_terms_of_service && agree_privacy_policy) {
      loginWithGmail(response)
    }
  }

  const [googlebtnEnter, setGooglebtnEnter] = useState(false)
  const onMouseEnter = () => {
    setGooglebtnEnter(true)
  }
  const [sendingEmail, setSendingEmail] = useState(false)
  let sendForgotPasswordEmail = () => {
    setSendingEmail(true)
    let email = document.getElementById('user_signin_email').value
    if (email.length == 0) {
      setSendingEmail(false)
      NotificationManager.warning(
        `please enter you email address`,
        'Forgot password',
        3000,
      )
      return true
    }
    dispatch(
      sendResetPasswordEmail({
        email: email,
        type: data.role == 'Renter' ? 'Guest' : 'Host',
        callBack: (response) => {
          setSendingEmail(false)
          NotificationManager.success(response.data[0], 'Forgot password', 3000)
        },
      }),
    )

    return true
  }

  const onMouseLeave = () => {
    setGooglebtnEnter(false)
  }

  let handleGoogleSSOClick = (renderProps) => {
    renderProps.onClick()
  }

  return (
    <div className="take-user-email">
      <NotificationContainer />
      <h1>Welcome back to Venuely, sign in as a {data.role.toLowerCase()}</h1>
      <form onSubmit={handleSubmit}>
        <input
          id="user_signin_email"
          type="email"
          name="email"
          placeholder="Email"
          style={{ fontSize: 14 }}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          style={{ fontSize: 14 }}
        />
        <button
          name="submit"
          className="take-user-email-button"
          style={{ fontSize: 16 }}
        >
          Login
        </button>
        <b style={{ cursor: 'pointer',borderBottom:"1px black solid" }} onClick={sendForgotPasswordEmail}>
          {' '}
          {sendingEmail ? 'sending email...' : 'Forgot Password?'}{' '}
        </b>
      </form>
      <div className="devider">
        {' '}
        <hr /> <p>or</p> <hr />{' '}
      </div>
      <div style={{ textAlign: 'center' }}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_CLIENT_ID} // your Google app client ID
          buttonText={'Continue with Google'}
          render={(renderProps) => (
            <div
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                border: '1.55px solid #ccc',
                borderRadius: 3,
                height: 48,
                backgroundColor: googlebtnEnter ? '#fafafa' : '#fff',
              }}
            >
              <img
                src={googleIcon}
                style={{ objectFit: 'contain', height: 26, width: 26 }}
                alt=""
              />
              <button
                // disabled = { document.getElementById('agree_policy_sign_in') && !document.getElementById('agree_policy_sign_in').checked }
                onClick={() => handleGoogleSSOClick(renderProps)}
                style={{
                  backgroundColor: googlebtnEnter ? '#fafafa' : '#fff',
                  width: 400,
                  fontSize: 16,
                  fontWeight: '600',
                }}
              >
                Continue with Google
              </button>
              <div></div>
            </div>
          )}
          onSuccess={(response) => onSuccess(response)}
          onFailure={(response) => {
            console.log(response)
          }}
          cookiePolicy={'single_host_origin'}
        />
        {/* <div>
          <p
            className="final-notes"
            style={{
              marginTop: 14,
              color: '#555',
              textAlign: 'left',
              // display: 'flex',
              // flexDirection: 'column',
            }}
          >
            By using Venuely or, signing up with google to Venuely, I agree to{' '}
            <div
              style={{
                display: 'inline',
                position: 'relative',
                marginRight: 16,
              }}
            >
              <input
                style={{
                  position: 'absolute',
                  top: 2,
                  height: 'auto',
                  width: 'auto',
                  margin: 0,
                }}
                type="checkbox"
                name="agree_terms_of_service"
                id="agree_terms_of_service"
              />
            </div>
            Venuely{' '}
            <a
              style={{
                fontSize: 12,
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '500',
              }}
              href="/terms-of-use"
              target="_blank"
            >
              Terms of Service
            </a>,{' '}
            <div
              style={{
                display: 'inline',
                position: 'relative',
                marginRight: 16,
              }}
            >
              <input
                style={{
                  position: 'absolute',
                  top: 2,
                  height: 'auto',
                  width: 'auto',
                  margin: 0,
                }}
                type="checkbox"
                name="agree_privacy_policy"
                id="agree_privacy_policy"
              />
            </div>
            Venuely{' '}
            <a
              style={{
                fontSize: 12,
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '500',
              }}
              href="/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div> */}
      </div>
    </div>
  )
}
