import { BasicPolicyActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setPolicy = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.get('/property/basic-policy/').catch((err) => {
    return err.response
  })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BasicPolicyActionType.SET_BASIC_POLICY,
    payload: response.data,
  })
}

export const selectPolicy = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/property/basic-policy/${requiredData.id}/`)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BasicPolicyActionType.SET_BASIC_POLICY,
    payload: null,
  })
}

export const createPolicy = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/property/basic-policy/', requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BasicPolicyActionType.SET_BASIC_POLICY,
    // payload:response.data
  })
}

export const updatePolicy = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/property/basic-policy/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BasicPolicyActionType.SET_BASIC_POLICY,
    // payload:response.data
  })
}

export const searchForSuggation = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post(`/property/address`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BasicPolicyActionType.SET_BASIC_POLICY,
    // payload:response.data
  })
}
