import { BlockedDateActionType } from '../contants/actions-type'
const initialState = {}

export default function BlockedDateReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case BlockedDateActionType.SELECT_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    case BlockedDateActionType.SET_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    case BlockedDateActionType.SELECT_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    case BlockedDateActionType.CREATE_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    case BlockedDateActionType.UPDATE_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    case BlockedDateActionType.REMOVE_BLOCKED_DATE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
