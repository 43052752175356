import backbtn from '../pages/icons/backbtn.svg'
import rectangle from '../pages/icons/rectangle.svg'
import calendar from '../pages/icons/calendar.svg'
import clock from '../pages/icons/clock.svg'
import stripe from '../pages/icons/stripe.svg'
import '../style/booking.css'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderBar from './HeaderBar'
import axios from 'axios'
import CalendarMenuMain from './calendar/CalendarMenuMain'
import { ListOfTime } from './utils/ListOfTime'
import Cookies from 'universal-cookie'
import { Button, message, Modal, Spin, Tooltip, Input, Tag } from 'antd'
import { TagFilled, CloseOutlined } from '@ant-design/icons'

import ActivityList from './ActivityList'
import get12HourFormat from './utils/get12HourFormat'
import { months } from './utils/months'
import StripeModal from './popups/StripeModal'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/AuthAction'
import disableScroll from 'disable-scroll'
import { ToolTipMain, ToolTipTexts } from '../components/menus/ToolTipMenu'
import { timeListArr } from './utils/timeListArr'
import ActivityModal from './popups/ActivityModal'
import { getAllActivities } from './Topbar/TopbarHelpers'

const Booking = () => {
  const location = useLocation()
  const navigate = useNavigate()
  // const path = location.pathname
  const [creditCards, setCreditCards] = useState(false)
  const [msgToHost, setMsgToHost] = useState('')
  const [guestAmount, setguestAmount] = useState('')
  const [alcoholConsumption, setAlcoholConsumption] = useState('No')
  const [hostRule, setHostRule] = useState(false)
  const [foodBeverage, setfoodBeverage] = useState(false)
  const [startTimeMenu, setStartTimeMenu] = useState(false)
  const [endTimeMenu, setEndTimeMenu] = useState(false)
  const currentTimeHour =
    moment().minutes() > 30
      ? moment().add(30, 'minutes').format('HH')
      : moment().format('HH')
  const currentTimeMin =
    moment().minutes() > 0 ? (moment().minutes() < 15 ? '15' : '30') : '00'
  const currentTimeStart = currentTimeHour + ':' + currentTimeMin + ':' + '00'
  const currentTimeEnd =
    +currentTimeHour + 3 + ':' + currentTimeMin + ':' + '00'
  const [startTime, setStartTime] = useState(location.state.startTime || '')
  const [endTime, setEndTime] = useState(location.state.endTime || '')
  const [activityVal, setActivityVal] = useState('')
  const [activityValID, setactivityValID] = useState('')
  const [activityItemSelected, setactivityItemSelected] = useState(false)
  const [activityListView, setactivityListView] = useState(false)
  const [activityItemsArray, setactivityItemsArray] = useState([])
  const [cardNumber, setcardNumber] = useState('')
  const [cardCVC, setcardCVC] = useState('')
  const [cardExp, setcardExp] = useState('')
  const [reviewPayPage, setreviewPayPage] = useState(false)
  const [calendarVisible, setcalendarVisible] = useState(false)
  const [dateVal, setDateVal] = useState(location.state.dateVal)
  const [dateVal2, setDateVal2] = useState(location.state.dateVal2)
  const [countCalPress, setcountCalPress] = useState(0)
  const [nextBtnColor, setnextBtnColor] = useState('#ccc')
  const [isStripeModal, setIsStripeModal] = useState(false)
  const [invalidFormat, setInvalidFormat] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const [savedCreditCard, setSavedCreditCard] = useState(false)
  const [inAvailableTime, setInavailableTime] = useState(false)
  const [availableTimeRange, setAvailableTimeRange] = useState('')
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [paymentDone, setPaymentDone] = useState(false)
  // Coupons states
  const [showCouponInput, setShowCouponInput] = useState(false)
  const [enteredCoupon, setEnteredCoupon] = useState('')
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useState(false)
  const [couponResponse, setCouponResponse] = useState(null)
  const [nextLoading, setNextLoading] = useState(false)
  const [event, setEvent] = useState(false)
  const [allActivities, setallActivities] = useState([])
  const [activityModal, setActivityModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [reviewPayPage])

  const room = location.state.room
  useEffect(() => {
    const format = 'hh:mm:ss'
    room.property.availability.map((item) => {
      if (
        item.day.day.toLowerCase() ===
        moment(dateVal).format('dddd').toString().toLowerCase()
      ) {
        const startTimeItem = moment(item.start, format)
        const endTimeItem = moment(item.end, format)
        const startIsSameOrAfter = moment(startTime, format).isSameOrAfter(
          startTimeItem,
        )
        const startIsSameOrBefore = moment(startTime, format).isSameOrBefore(
          endTimeItem,
        )
        const endIsSameOrAfter = moment(endTime, format).isSameOrAfter(
          startTimeItem,
        )
        const endIsSameOrBefore = moment(endTime, format).isSameOrBefore(
          endTimeItem,
        )
        const checkerArr = [
          startIsSameOrAfter,
          startIsSameOrBefore,
          endIsSameOrAfter,
          endIsSameOrBefore,
        ]
        const checker = checkerArr.every((statement) => statement === true)
        if (checker) {
          setInavailableTime(false)
          setAvailableTimeRange('')
        } else {
          setInavailableTime(true)
          setAvailableTimeRange(
            'The available time is between ' +
              moment(item.start, format).format('hh:mm A').toString() +
              ' & ' +
              moment(item.end, format).format('hh:mm A').toString(),
          )
        }
      }
    })
  }, [startTime, endTime, setStartTime, setEndTime])
  const optOutActivities = room.opt_out_activities
  const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL?.split('/api')[0]

  const percentOfPrice = (price, percentage) => {
    return ((price / 100) * percentage).toFixed(2)
  }

  const [serviceFeePercentage, setServiceFeePercentage] = useState(0)
  useEffect(() => {
    axios.get(`${baseURL}/host/global-variables`).then((res) => {
      const fee = res.data.venuely_fee
      setServiceFeePercentage(fee)
    })
  }, [baseURL])

  // id = 1 -> hourly rating
  // id = 2 -> fixed rating
  const pricingCondition = room?.pricing_condition.id
  const rate = room?.price
  const minHourDiscount = room?.minimum_hour_for_discount
  // const minNumDiscount = room?.minimum_number_of_hours
  const start = moment(startTime, 'HH:mm:ss a')
  const end = moment(endTime, 'HH:mm:ss a')
  const durationStartEnd = moment.duration(end.diff(start))
  const hoursStartEnd = parseInt(durationStartEnd.asHours())
  const dateDiff = moment(dateVal2).diff(dateVal, 'days') + 1
  const hours = pricingCondition === 1 && !invalidFormat ? hoursStartEnd : 0
  const price =
    pricingCondition === 2 ? rate * dateDiff : rate * hours * dateDiff
  const cleaningFee =
    room?.cleaning_fee.id !== 1 ? room?.additional_cleaning_fee : 0
  const serviceFee = percentOfPrice(price, serviceFeePercentage)
  const discount =
    hours >= minHourDiscount ? percentOfPrice(price, room?.discount) : 0
  let transactionCost =
    (2.9 / 100) *
      (+price +
        +cleaningFee +
        +percentOfPrice(price, serviceFeePercentage) -
        +discount) +
    0.3
  const total =
    +price +
    +cleaningFee +
    +transactionCost +
    +percentOfPrice(price, serviceFeePercentage) -
    +discount
  const totalWithoutServiceFee = +price + +cleaningFee + -+discount

  const serviceFeeWithTransaction = +serviceFee + +transactionCost

  const getActivity = async (val) => {
    try {
      if (val.length === 0) {
        setactivityListView(false)
      } else {
        const res = await axios.post(`${baseURL}/guest/activity`, {
          name: activityVal,
          price_id: room.id,
        })
        setactivityItemsArray(
          res.data
            .filter(
              (ar) => !optOutActivities.find((rm) => rm.title === ar.title),
            )
            .map((item) => item),
        )
        setactivityListView(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const reviewViewSetter = async () => {
    setNextLoading(true)
    // Check if the backend will allow to book this space on the selected date

    const checkDate = async () => {
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const data = {
        date: dateVal === dateVal2 ? [dateVal] : [dateVal, dateVal2],
        start: startTime,
        end: endTime,
        price: room.id,
        space: room.property.id,
        alcohol_consumption: alcoholConsumption,
        message_to_host: msgToHost,
        expected_guest_amount: +guestAmount,
        activity: activityValID,
        is_inquiry: false,
      }
      console.log(data)
      axios
        .put(`${baseURL}/guest/space-reservation`, data, {
          headers: {
            Authorization: authData,
          },
        })
        .then((res) => {
          setNextLoading(false)
          console.log(res.data)
          if (
            alcoholConsumption === '' ||
            activityVal === '' ||
            guestAmount === ''
          ) {
            return message.error('Please fill up all the values')
          } else if (
            (!hostRule &&
              room?.property?.house_restriction?.rules?.length > 0) ||
            (!foodBeverage &&
              room?.property?.house_restriction?.restriction?.length)
          ) {
            return message.error('Please fill up all the values')
          } else {
            setreviewPayPage(true)
            // console.log('check passed')
          }
        })
        .catch((err) => {
          setNextLoading(false)
          console.log(err.response)
          message.error(err.response.data.detail)
          return
        })
    }

    await checkDate()
  }
  useEffect(() => {
    if (!reviewPayPage) {
      if (
        alcoholConsumption === '' ||
        activityVal === '' ||
        guestAmount === ''
      ) {
        setnextBtnColor('#ccc')
      } else if (
        (!hostRule && room?.property?.house_restriction?.rules?.length > 0) ||
        (!foodBeverage &&
          room?.property?.house_restriction?.restriction?.length)
      ) {
        setnextBtnColor('#ccc')
      } else {
        setnextBtnColor('#6568E6')
      }
    } else {
      if (
        alcoholConsumption === '' ||
        activityVal === '' ||
        guestAmount === ''
      ) {
        setnextBtnColor('#ccc')
      } else if (
        (!hostRule && room?.property?.house_restriction?.rules?.length > 0) ||
        (!foodBeverage &&
          room?.property?.house_restriction?.restriction?.length)
      ) {
        console.log('inside')
        setnextBtnColor('#ccc')
      } else {
        setnextBtnColor('#6568E6')
      }
    }
  }, [
    reviewPayPage,
    alcoholConsumption,
    activityVal,
    guestAmount,
    hostRule,
    foodBeverage,
  ])
  const errorMsg = (err) => (
    <>
      <label style={{ fontSize: 16 }}>{err.response.data.detail}</label>
    </>
  )

  const requestBooking = async () => {
    try {
      setBtnLoading(true)
      if (paymentIntentId === null || paymentIntentId === undefined) {
        message.error('Please choose a payment method first')
        document.getElementById('payment-method').scrollIntoView()
        setBtnLoading(false)
        return
      }
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const Data = {
        date: [dateVal === dateVal2 ? dateVal : dateVal, dateVal2],
        start: startTime,
        end: endTime,
        price: room.id,
        space: room.property.id,
        alcohol_consumption: alcoholConsumption === 'No' ? false : true,
        message_to_host: msgToHost,
        expected_guest_amount: +guestAmount,
        activity: activityValID,
        is_inquiry: false,
        ...(savedCreditCard && { method: paymentMethod }),
        payment_intent_id: paymentIntentId,
        ...(enteredCoupon && { coupon: enteredCoupon }),
      }
      // console.log(Data)
      const config = {
        headers: {
          Authorization: authData,
        },
      }
      await axios
        .post(`${baseURL}/guest/space-reservation`, Data, config)
        .then((res) => {
          if (res.status === 200) {
            setBtnLoading(false)
            message.success('Booking done successfully')
            navigate('/renter/mybookings')
          } else {
            setBtnLoading(false)
          }
        })
    } catch (err) {
      if (err) {
        setBtnLoading(false)
        console.log(err.response)
        // message.error(err.response.data.detail)
      }
    }
  }
  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      const classList = e.target.classList
      // console.log(e.target.id)
      if (
        classList.contains('details-parent') ||
        classList.contains('deviderDiv')
      ) {
        setcalendarVisible(false)
        setStartTimeMenu(false)
        setEndTimeMenu(false)
      }
    })
  }, [])

  const inAvailability = room?.property?.in_availability
  const blockedDate = room?.property?.blocked_date
  const windowDate = room?.property?.window_time
  const previousBookingDate = room?.property?.previous_booking
  // const windowTime = room?.property?.window_time.split(" ")[1]
  // const bufferTime = room.property?.buffer_time
  const [houseRuleLimit, setHouseRuleLimit] = useState(300)
  const [cancelLimit, setCancelLimit] = useState(300)
  const [covidLimit, setCovidLimit] = useState(300)
  const [foodLimit, setFoodLimit] = useState(300)
  const [minHourExceeded, setMinHourExceeded] = useState(false)

  useEffect(() => {
    const diff = moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'))
    const condtion =
      moment.duration(diff, 'milliseconds').asHours() <
      room.minimum_number_of_hours
    if (condtion) {
      setMinHourExceeded(true)
      setInvalidFormat(true)
    } else {
      setMinHourExceeded(false)
      setInvalidFormat(false)
    }
  })

  const showMore = (item, setItem) => {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => {
          setItem(!item)
          item <= 0 ? setItem(300) : setItem(0)
        }}
      >
        <h4
          style={{
            marginTop: 8,
            textDecoration: 'underline',
            color: '#808080',
            fontSize: 16,
          }}
        >
          {item ? 'Show more' : 'Show less'}
        </h4>
        <img
          src="/dropdown.svg"
          alt=""
          style={{
            margin: 10,
            opacity: 0.5,
            transform: item ? 'none' : 'rotate(180deg)',
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    if (startTime === '' || endTime === '') {
      setInvalidFormat(false)
    } else {
      let beginningTime = moment(startTime, 'h:mma')
      let end = moment(endTime, 'h:mma')
      if (beginningTime.isBefore(end)) {
        setInvalidFormat(false)
      } else {
        setInvalidFormat(true)
      }
    }
  }, [startTime, endTime])

  const address = room?.property?.address
  const propertyName = room?.property?.name

  const boxStyle = {
    marginTop: 20,
    borderRadius: 10,
    backgroundColor: reviewPayPage ? '#F7F7F7' : '#fff',
    border: !reviewPayPage ? '1px solid #D9D9D9' : '0px',
    width: 680,
    padding: '30px',
  }

  const horline = (
    <img src="/horline.svg" alt="" style={{ width: 680, margin: '40px 0' }} />
  )

  const boldUnderlined = {
    fontSize: 14,
    textDecoration: 'underline',
    fontWeight: 'bold',
  }

  const [prevPayment, setPrevPayment] = useState(null)
  useEffect(() => {
    const getPaymentCardList = async () => {
      const PAGE_LIMIT = 10
      const cookies = new Cookies()
      const authData = await cookies.get('jwt')
      try {
        const res = await axios.get(
          `${baseURL}/guest/paymnet-method?limit=${PAGE_LIMIT}`,
          {
            headers: {
              Authorization: authData,
            },
          },
        )
        setPrevPayment(res.data.default_method)
      } catch (err) {
        console.log(err.response)
        if (err.response.status === 406) {
          // message.error('Please Login again')
          // dispatch(logout())
          // navigate('/')
        } else {
          message.error(err.response.data.detail)
        }
      }
    }
    getPaymentCardList()
    return () => getPaymentCardList()
  }, [baseURL, dispatch, navigate])

  const getPaymentIntent = () => {
    const cookie = new Cookies()
    const authData = cookie.get('jwt')
    const data = {
      date: dateVal === dateVal2 ? [dateVal] : [dateVal, dateVal2],
      start: startTime,
      end: endTime,
      price: room.id,
      space: room.property.id,
      alcohol_consumption: alcoholConsumption,
      message_to_host: msgToHost,
      expected_guest_amount: +guestAmount,
      activity: activityValID,
      is_inquiry: false,
      ...(enteredCoupon && { coupon: enteredCoupon }),
    }
    axios
      .put(`${baseURL}/guest/space-reservation`, data, {
        headers: {
          Authorization: authData,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentIntentId(res.data.id)
          // console.log(prevPayment.id)
          setPaymentMethod(prevPayment.id)
          setSavedCreditCard(true)
          message.success('Payment intent created successfully')
        }
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  useEffect(() => {
    calendarVisible ? disableScroll.on() : disableScroll.off()
  }, [calendarVisible])

  const maxCapacityInput = (e) => {
    console.log(room.property['description'].capacity)
    console.log(room.property.number_of_guest)
    if (
      e.target.value >
      (room.property['description'].capacity || room.property.number_of_guest)
    ) {
      message.error(
        'Maximum capacity is ' +
          room?.property['description']?.capacity +
          ', number of guests exceeds the max capacity for this space.',
      )
      return
    }
    setguestAmount(e.target.value)
  }
  const timeRef = useRef(null)

  const [pausedBookingModal, setPausedBookingModal] = useState(false)

  const showPausedBookingModal = () => setPausedBookingModal(true)
  const handleOk = () => setPausedBookingModal(false)
  const handleCancel = () => setPausedBookingModal(false)

  const couponValidationReq = async () => {
    // console.log(`enteredCoupon: ${enteredCoupon}`)
    setCouponError(false)
    setCouponSuccess(false)
    setCouponResponse(null)
    if (enteredCoupon.length == 0) {
      setCouponError(true)
      return
    }

    const cookies = new Cookies()
    const authData = await cookies.get('jwt')
    try {
      const res = await axios.post(
        `${baseURL}/payment/coupon/info?page_number=1&limit=10`,
        {
          code: enteredCoupon,
        },
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      // console.log(res.data)
      if (res?.data?.is_active === false) {
        setCouponError(true)
        return
      } else {
        setCouponSuccess(true)
        setCouponResponse(res.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <HeaderBar />
      <Modal
        title="Message"
        open={pausedBookingModal}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p style={{ fontSize: 18, lineHeight: 1.1 }}>
          Sorry for the inconvenience!
        </p>
        <div>
          <p style={{ fontSize: 16, lineHeight: 1.1 }}>
            Reservation is temporarily unavailable.
          </p>
        </div>
      </Modal>
      <div className="deviderDiv">
        <div
          className="details-parent"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="details">
            <h5
              className="navTexts"
              style={{
                opacity: !reviewPayPage ? 1 : 0.7,
                cursor: 'pointer',
                fontSize: 18,
              }}
              onClick={() => setreviewPayPage(false)}
            >
              Booking details
            </h5>
            <img
              src={backbtn}
              alt=""
              style={{
                width: 8,
                opacity: 0.3,
                margin: 16,
                marginTop: 8,
                transform: 'rotate(180deg)',
              }}
            />
            <h5
              className="navTexts"
              style={{
                opacity: reviewPayPage ? 1 : 0.7,
                cursor: 'pointer',
                fontSize: 18,
              }}
              onClick={() => reviewViewSetter()}
            >
              Review & pay
            </h5>
          </div>
          {!reviewPayPage && <h1>Booking Details</h1>}
          {reviewPayPage && <h1>Review & pay</h1>}

          {reviewPayPage && (
            <div>
              <div>
                <h4 style={{ marginTop: 15, fontWeight: '500', fontSize: 20 }}>
                  Date & time
                </h4>
                <div className="cardBox-parent" style={{ display: 'flex' }}>
                  <div
                    className="cardBox"
                    style={{ width: dateVal === dateVal2 ? 240 : 280 }}
                  >
                    <img
                      src={calendar}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                    {dateVal === dateVal2 ? (
                      <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                        {moment(dateVal).format('ddd, MMM DD, YYYY')}
                      </h5>
                    ) : (
                      <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                        {moment(dateVal).format('ddd, MMM DD')}
                        {moment(dateVal2).format(' - MMM DD, YYYY')}
                      </h5>
                    )}
                  </div>
                  <div className="cardBox" style={{ width: 'max-content' }}>
                    <img src={clock} alt="" style={{ width: 24, height: 24 }} />
                    <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                      {get12HourFormat(startTime)} - {get12HourFormat(endTime)}
                    </h5>
                  </div>
                </div>
              </div>
              <div>
                <h4 style={{ marginTop: 15, fontWeight: '500', fontSize: 20 }}>
                  Activity
                </h4>
                <div
                  className="cardBox-parent"
                  style={{ display: 'flex', width: 'max-content' }}
                >
                  <div className="cardBox">
                    <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                      {activityVal}
                    </h5>
                  </div>
                </div>
              </div>
              <div>
                <h4 style={{ marginTop: 15, fontWeight: '500', fontSize: 20 }}>
                  Attendees
                </h4>
                <div
                  className="cardBox-parent"
                  style={{ display: 'flex', width: 70 }}
                >
                  <div className="cardBox">
                    <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                      {guestAmount}
                    </h5>
                  </div>
                </div>
              </div>
              <div>
                <h4 style={{ marginTop: 15, fontWeight: '500', fontSize: 20 }}>
                  Alcohol
                </h4>
                <div
                  className="cardBox-parent"
                  style={{ display: 'flex', width: 70 }}
                >
                  <div className="cardBox">
                    <h5 style={{ margin: '0 6px', fontSize: 18 }}>
                      {!alcoholConsumption ? 'No' : 'Yes'}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!reviewPayPage && (
            <>
              <h3 style={{ marginTop: 50, fontSize: 24 }}>Your events</h3>
              <h4 style={{ marginTop: 30, fontWeight: '600', fontSize: 18 }}>
                Date & time
              </h4>
              <div
                className="date-dropdown"
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: 23,
                  width: 450,
                }}
                onClick={() => {
                  setStartTimeMenu(false)
                  setEndTimeMenu(false)
                  setcalendarVisible(true)
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    padding: 5,
                  }}
                >
                  <h6 style={{ fontWeight: 'bold', marginLeft: 0, width: 200 }}>
                    Date
                  </h6>
                  {dateVal === dateVal2 ? (
                    <h5 style={{ fontSize: 16 }}>
                      {months[+dateVal?.split('-')[1] - 1]}{' '}
                      {dateVal?.slice(8, 10)}
                    </h5>
                  ) : (
                    <h5 style={{ marginLeft: 0, fontSize: 16 }}>
                      {months[+dateVal?.split('-')[1] - 1]}{' '}
                      {dateVal?.slice(8, 10)} {'-'}
                      {months[+dateVal2?.split('-')[1] - 1]}{' '}
                      {dateVal2?.slice(8, 10)}
                    </h5>
                  )}
                </div>
                <img src="/dropdown.svg" alt="" style={{ marginLeft: 146 }} />
                <div style={{ position: 'relative' }}>
                  {calendarVisible ? (
                    <div
                      className="calendar-menu-hahah"
                      style={{
                        position: 'absolute',
                        marginTop: -376,
                        marginLeft: -500,
                      }}
                    >
                      <CalendarMenuMain
                        dateVal={dateVal}
                        setDateVal={setDateVal}
                        dateVal2={dateVal2}
                        setDateVal2={setDateVal2}
                        countCalPress={countCalPress}
                        setcountCalPress={setcountCalPress}
                        previousBookingDate={previousBookingDate}
                        calendarVisible={calendarVisible}
                        setcalendarVisible={setcalendarVisible}
                        inAvailability={inAvailability}
                        blockedDate={blockedDate}
                        windowDate={windowDate}
                        // windowTime={windowTime}
                        // bufferTime={bufferTime}
                        width={430}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="start-end-date" style={{ width: 450 }}>
                <div
                  className="start-end"
                  style={{
                    borderRadius: '0px 0px 0px 10px',
                    padding: 5,
                    border:
                      invalidFormat || inAvailableTime
                        ? '1px #DF3416 solid'
                        : '1px #ccc solid',
                  }}
                  onClick={() => {
                    if (pricingCondition === 2) {
                      return
                    }
                    setcalendarVisible(false)
                    setEndTimeMenu(false)
                    setStartTimeMenu(!startTimeMenu)
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h6
                      style={{
                        fontWeight: 'bold',
                        color:
                          invalidFormat || inAvailableTime ? '#DF3416' : '#000',
                      }}
                    >
                      Start time
                    </h6>
                    <h6 style={{ fontSize: 16 }}>
                      {get12HourFormat(startTime)}
                    </h6>
                  </div>
                  <div style={{ position: 'relative' }}>
                    {startTimeMenu && (
                      <ul
                        className="date-menu2 date-menu-common"
                        style={{ marginTop: 40, marginLeft: -90 }}
                      >
                        {timeListArr.map((item, idx) => {
                          return (
                            <label
                              key={idx}
                              ref={timeRef}
                              className={
                                item === startTime
                                  ? 'time-text2-focus'
                                  : 'time-text2'
                              }
                              onClick={() => {
                                if (item === startTime) {
                                  const focus =
                                    document.querySelector('.time-text2')
                                  focus.scrollIntoView()
                                }
                                setStartTime(item)
                              }}
                            >
                              {get12HourFormat(item)}
                            </label>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                  <img src="/dropdown.svg" alt="" />
                </div>
                <div
                  className="start-end"
                  style={{
                    borderRadius: '0px 0px 10px 0px',
                    padding: 5,
                    border:
                      invalidFormat || inAvailableTime
                        ? '1px #DF3416 solid'
                        : '1px #ccc solid',
                  }}
                  onClick={() => {
                    if (pricingCondition === 2) {
                      return
                    }
                    setcalendarVisible(false)
                    setStartTimeMenu(false)
                    setEndTimeMenu(!endTimeMenu)
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h6
                      style={{
                        fontWeight: 'bold',
                        color:
                          invalidFormat || inAvailableTime ? '#DF3416' : '#000',
                      }}
                    >
                      End time
                    </h6>
                    <h6 style={{ fontSize: 16 }}>{get12HourFormat(endTime)}</h6>
                  </div>
                  <div style={{ position: 'relative' }}>
                    {endTimeMenu && (
                      <ul
                        className="date-menu2 date-menu-common"
                        style={{ marginTop: 50, marginLeft: -140 }}
                      >
                        {timeListArr.map((item, idx) => {
                          return (
                            <label
                              key={idx}
                              className={
                                item === endTime
                                  ? 'time-text2-focus'
                                  : 'time-text2'
                              }
                              onClick={() => setEndTime(item)}
                            >
                              {get12HourFormat(item)}
                            </label>
                          )
                        })}
                      </ul>
                    )}
                    <img src="/dropdown.svg" alt="" />
                  </div>
                </div>
              </div>
              {!inAvailableTime && minHourExceeded && (
                <h4 style={{ fontSize: 14, color: 'red', margin: 5 }}>
                  Minimum number of hour is {room.minimum_number_of_hours}
                </h4>
              )}
              {inAvailableTime && (
                <h4 style={{ fontSize: 14, color: 'red', margin: 5 }}>
                  {availableTimeRange}
                </h4>
              )}
              <h4 style={{ marginTop: 30, fontWeight: '600', fontSize: 18 }}>
                Activity
              </h4>
              <input
                className="booking-input"
                type="text"
                value={activityVal}
                onChange={(e) => setActivityVal(e.target.value)}
                onKeyUp={(e) => getActivity(e.target.value)}
                placeholder="e.g. Photo Shoot, Video Shoot..."
              />
              <div style={{ position: 'relative' }}>
                {activityListView && (
                  <div
                    style={{
                      marginTop: 10,
                      position: 'absolute',
                      backgroundColor: '#fff',
                      border: '#cccccc 1px solid',
                      boxShadow: 'rgba(0, 0, 0, 0.07) 2px 2px 10px',
                      borderRadius: 10,
                      width: 200,
                      maxHeight: 300,
                      overflowY: 'scroll',
                      zIndex: 3,
                    }}
                    onClick={() => setactivityListView(false)}
                  >
                    <ActivityList
                      activityItemsArray={activityItemsArray}
                      setactivityItemsArray={setactivityItemsArray}
                      activityItemSelected={activityItemSelected}
                      setactivityItemSelected={setactivityItemSelected}
                      setactivityValID={setactivityValID}
                      activityVal={activityVal}
                      setActivityVal={setActivityVal}
                      getAllActivities={() =>
                        getAllActivities(
                          {
                            setActivityModal,
                            setallActivities,
                            setEvent,
                          },
                          room.id,
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <div className="activity-modal">
                <ActivityModal
                  allActivities={allActivities}
                  setallActivities={setallActivities}
                  activityModal={activityModal}
                  setActivityModal={setActivityModal}
                  event={event}
                  setEvent={setEvent}
                />
              </div>

              <h4 style={{ marginTop: 30, fontWeight: '600', fontSize: 18 }}>
                How many people will attend?
              </h4>
              <input
                className="booking-input"
                type="text"
                value={guestAmount}
                onChange={(e) => maxCapacityInput(e)}
                placeholder={`Max capacity is ${
                  room.property['description'].capacity ||
                  room.property.number_of_guest
                }`}
              />
              <h4 style={{ marginTop: 30, fontWeight: '600', fontSize: 18 }}>
                Will alcohol be consumed?
              </h4>
              <div style={{ margin: '10px 0' }}>
                <button
                  className="btn-card"
                  style={{
                    margin: '0 8px',
                    backgroundColor: alcoholConsumption
                      ? 'rgb(101, 104, 230)'
                      : 'rgba(0,0,0,0)',
                    color: alcoholConsumption ? '#fff' : '#000',
                  }}
                  onClick={() => setAlcoholConsumption(true)}
                >
                  Yes
                </button>
                <button
                  className="btn-card"
                  style={{
                    margin: '0 8px',
                    backgroundColor: !alcoholConsumption
                      ? 'rgb(101, 104, 230)'
                      : 'rgba(0,0,0,0)',
                    color: !alcoholConsumption ? '#fff' : '#000',
                  }}
                  onClick={() => setAlcoholConsumption(false)}
                >
                  No
                </button>
              </div>
            </>
          )}
          {horline}

          {room?.property?.house_restriction?.rules?.length > 0 && (
            <div>
              <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
                Host rules
              </h2>
              <div style={boxStyle}>
                <p
                  style={{
                    color: '#000',
                    fontSize: 16,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {JSON.parse(
                    JSON.stringify(room?.property.house_restriction.rules),
                  ).slice(
                    0,
                    houseRuleLimit <= 0
                      ? room?.property.house_restriction.rules.length
                      : houseRuleLimit,
                  )}
                </p>
                {showMore(houseRuleLimit, setHouseRuleLimit)}
              </div>
              {!reviewPayPage && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 30,
                  }}
                >
                  <input
                    type="checkbox"
                    id="host-rules"
                    style={{
                      padding: 40,
                      WebkitTransform: 'scale(1.6)',
                      margin: '7.5px 10px',
                    }}
                    onChange={(e) => e.target.checked && setHostRule(true)}
                  />
                  <label
                    htmlFor="host-rules"
                    style={{ fontSize: 18, fontWeight: 400, color: '#000' }}
                  >
                    I accept and understand these host and disclosures.
                  </label>
                </div>
              )}
              {horline}
            </div>
          )}

          {room?.property.house_restriction?.insurance_need === true && (
            <>
              <div>
                <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
                  Venuely's Insurance Policy
                </h2>
                <div style={boxStyle}>
                  <p
                    style={{
                      marginTop: 20,
                      fontSize: 16,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    This host requires all guests to provide commercial
                    liability insurance in order to host events in this space.
                    You can purchase an insurance certificate and provide this
                    certificate of insurance documents via email with the host
                    before the date of the event. Insurance can be purchased via
                    our partner,{' '}
                    <a
                      href="https://www.eventsured.com/venuely/"
                      target={'_blank'}
                      style={{
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Eventsured,
                    </a>{' '}
                    at checkout or via your chosen vendor.
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 30,
                  }}
                >
                  <input
                    type="checkbox"
                    id="food-beverage"
                    style={{
                      padding: 40,
                      WebkitTransform: 'scale(1.6)',
                      margin: '7.5px 10px',
                    }}
                    // TODO: Make it functional later if needed
                    // onChange={(e) => e.target.checked && setfoodBeverage(true)}
                  />
                  <label
                    // TODO: Make it functional later if needed
                    // htmlFor="food-beverage"
                    style={{ fontSize: 18, fontWeight: 400, color: '#000' }}
                  >
                    I accept and understand these host and disclosures.
                  </label>
                </div>
              </div>
              {horline}
            </>
          )}

          {room?.property?.house_restriction?.restriction?.length > 0 && (
            <div>
              <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
                Food and beverage restrictions
              </h2>
              <div style={boxStyle}>
                <p
                  style={{
                    color: '#000',
                    fontSize: 16,
                    maxWidth: 799,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {JSON.parse(
                    JSON.stringify(
                      room?.property.house_restriction.restriction,
                    ),
                  ).slice(
                    0,
                    foodLimit <= 0
                      ? room?.property.house_restriction.restriction.length
                      : foodLimit,
                  )}
                </p>
                {showMore(foodLimit, setFoodLimit)}
              </div>
              {!reviewPayPage && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 30,
                  }}
                >
                  <input
                    type="checkbox"
                    id="food-beverage"
                    style={{
                      padding: 40,
                      WebkitTransform: 'scale(1.6)',
                      margin: '7.5px 10px',
                    }}
                    onChange={(e) => e.target.checked && setfoodBeverage(true)}
                  />
                  <label
                    htmlFor="food-beverage"
                    style={{ fontSize: 18, fontWeight: 400, color: '#000' }}
                  >
                    I accept and understand these host and disclosures.
                  </label>
                </div>
              )}
              {horline}
            </div>
          )}

          <div>
            <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
              COVID-19 Health & Safety Measures
            </h2>
            <div style={boxStyle}>
              <h5
                style={{ marginTop: 20, fontSize: 16, whiteSpace: 'pre-wrap' }}
              >
                {JSON.parse(
                  JSON.stringify(
                    room?.property.house_restriction.cleaning_process,
                  ),
                ).slice(
                  0,
                  covidLimit <= 0
                    ? room?.property.house_restriction.cleaning_process.length
                    : covidLimit,
                )}
              </h5>
              {covidLimit !== 300 && (
                <div>
                  {room?.property.house_restriction.Social_distancing.length !==
                    0 && (
                    <div>
                      <h5 style={{ fontSize: 18, marginTop: 20 }}>
                        Social distancing
                      </h5>
                      <div style={listStyle}>
                        {room?.property.house_restriction.Social_distancing.map(
                          (item, idx) => {
                            return (
                              <>
                                <li
                                  key={idx}
                                  style={{ fontSize: 16, listStyle: 'inside' }}
                                >
                                  {/* {item.title.length >= 20
                                      ? item.title.slice(0, 20)
                                      : item.title} */}
                                  {item.title}
                                </li>
                              </>
                            )
                          },
                        )}
                      </div>
                    </div>
                  )}
                  {room?.property.house_restriction.cleaning_and_hygiene
                    .length !== 0 && (
                    <>
                      <h5 style={{ fontSize: 18, marginTop: 20 }}>
                        Cleaning and Hygiene
                      </h5>
                      <div style={listStyle}>
                        {room?.property.house_restriction.cleaning_and_hygiene.map(
                          (item, idx) => {
                            return (
                              <>
                                <li
                                  key={idx}
                                  style={{ fontSize: 16, listStyle: 'inside' }}
                                >
                                  {/* {item.title.length >= 20
                                      ? item.title.slice(0, 20)
                                      : item.title} */}
                                  {item.title}
                                </li>
                              </>
                            )
                          },
                        )}
                      </div>
                    </>
                  )}
                  {room?.property.house_restriction.Protect_people_and_detect
                    .length !== 0 && (
                    <>
                      <h5 style={{ fontSize: 18, marginTop: 20 }}>
                        Protect people and detect
                      </h5>
                      <div style={listStyle}>
                        {room?.property.house_restriction.Protect_people_and_detect.map(
                          (item, idx) => {
                            return (
                              <>
                                <li
                                  style={{ fontSize: 16, listStyle: 'inside' }}
                                >
                                  {/* {item.title.length >= 20
                                      ? item.title.slice(0, 20)
                                      : item.title} */}
                                  {item.title}
                                </li>
                              </>
                            )
                          },
                        )}
                      </div>
                    </>
                  )}
                  {room?.property.house_restriction.communicating_rules
                    .length !== 0 && (
                    <>
                      <h5 style={{ fontSize: 18, marginTop: 20 }}>
                        Communicating Rules
                      </h5>
                      <div style={listStyle}>
                        {room?.property.house_restriction.communicating_rules.map(
                          (item, idx) => {
                            return (
                              <>
                                <li
                                  style={{ fontSize: 16, listStyle: 'inside' }}
                                >
                                  {/* {item.title.length >= 20
                                      ? item.title.slice(0, 20)
                                      : item.title} */}
                                  {item.title}
                                </li>
                              </>
                            )
                          },
                        )}
                      </div>
                    </>
                  )}
                  {room?.property.house_restriction.emergency_response_plans
                    .length !== 0 && (
                    <>
                      <h5 style={{ fontSize: 18, marginTop: 20 }}>
                        Emergency response plans
                      </h5>
                      <div style={listStyle}>
                        {room?.property.house_restriction.emergency_response_plans.map(
                          (item, idx) => {
                            return (
                              <>
                                <li
                                  style={{ fontSize: 16, listStyle: 'inside' }}
                                >
                                  {/* {item.title.length >= 20
                                      ? item.title.slice(0, 20)
                                      : item.title} */}
                                  {item.title}
                                </li>
                              </>
                            )
                          },
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              {showMore(covidLimit, setCovidLimit)}
            </div>
            <div
              style={{ display: 'flex', alignItems: 'flex-end', marginTop: 30 }}
            >
              {/* <input
                type="checkbox"
                id="covid19"
                style={{
                  padding: 40,
                  WebkitTransform: 'scale(1.6)',
                  margin: '7.5px 10px',
                }}
                onChange={(e) => e.target.checked && setCovid(true)}
              />
              <label
                htmlFor="covid19"
                style={{ fontSize: 18, fontWeight: 400, color: '#000' }}
              >
                I accept and understand these host and disclosures.
              </label> */}
            </div>
            {horline}
          </div>
          <div>
            <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
              Message your host
            </h2>
            <p style={{ color: '#808080', fontSize: 16 }}>
              Introduce yourself to host and describe what you’re planning.
            </p>
            <textarea
              type="text"
              value={msgToHost}
              onChange={(e) => setMsgToHost(e.target.value)}
              style={{
                borderRadius: 5,
                border: 'none',
                outline: '#ccc 1px solid',
                marginTop: 20,
                width: 680,
                height: 300,
                resize: 'none',
                fontSize: 16,
                fontWeight: 400,
                textIndent: 12,
                padding: 12,
              }}
            />
          </div>
          {horline}
          <div id="payment-method">
            {reviewPayPage && (
              <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
                Payment
              </h2>
            )}
            {reviewPayPage && (
              <div style={{ marginTop: 20, borderRadius: 10 }}>
                <p style={{ fontWeight: '500', width: 300, fontSize: 18 }}>
                  Pay with
                </p>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: 20,
                  }}
                >
                  <button
                    onClick={() => {
                      if (paymentDone) {
                        return message.success(
                          'You have already authorized for a payment',
                        )
                      } else {
                        setPaymentIntentId('')
                        setIsStripeModal(true)
                        setSelectedPayment('stripe')
                        setPaymentDone(true)
                      }
                    }}
                    className="cardBox-btn"
                    style={{
                      border:
                        selectedPayment === 'stripe'
                          ? 'rgba(101, 103, 230, 0.9) 3px solid'
                          : 'rgb(112, 112, 112) 1px solid',
                      textAlign: 'center',
                      padding: 14,
                      width: 240,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    Authorize with
                    <img
                      src={stripe}
                      alt=""
                      style={{ width: 44, marginLeft: 5 }}
                    />
                  </button>
                  {paymentIntentId !== '' && paymentDone && (
                    <h1
                      style={{
                        fontSize: 16,
                        color: '#00ab41',
                        marginTop: -20,
                        marginLeft: 8,
                      }}
                    >
                      Payment Authorized
                    </h1>
                  )}
                  {prevPayment?.last4 !== undefined && (
                    <>
                      <p
                        style={{
                          fontWeight: '500',
                          width: 300,
                          fontSize: 18,
                          marginBottom: -3,
                        }}
                      >
                        Default payment method
                      </p>
                      <button
                        onClick={() => {
                          setPaymentIntentId('')
                          getPaymentIntent()
                          setSelectedPayment('default')
                        }}
                        className="cardBox-btn"
                        style={{
                          border:
                            selectedPayment === 'default'
                              ? 'rgba(101, 103, 230, 0.9) 3px solid'
                              : 'rgb(112, 112, 112) 1px solid',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 240,
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {prevPayment?.brand}
                        <span style={{ fontSize: 16, marginInline: 5 }}>
                          {`••••${prevPayment?.last4}`}
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            backgroundColor: '#ccc',
                            color: '#000',
                            padding: 6,
                            borderRadius: 4,
                            marginInline: 5,
                          }}
                        >
                          DEFAULT
                        </span>
                      </button>
                    </>
                  )}
                  <StripeModal
                    dateVal={dateVal}
                    dateVal2={dateVal2}
                    startTime={startTime}
                    endTime={endTime}
                    price={room.id}
                    space={room.property.id}
                    alcoholConsumption={alcoholConsumption}
                    guestAmount={guestAmount}
                    activity={activityValID}
                    msgToHost={msgToHost}
                    paymentIntentId={paymentIntentId}
                    setPaymentIntentId={setPaymentIntentId}
                    setIsStripeModal={setIsStripeModal}
                    isStripeModal={isStripeModal}
                    setSavedCreditCard={setSavedCreditCard}
                  />
                  {horline}
                  {/* <div style={{ display: 'flex' }}>
                    <button
                      className="cardBox-btn"
                      onClick={() => setCreditCards(!creditCards)}
                      style={{
                        border: 'none',
                        textAlign: 'center',
                        padding: 14,
                        width: 240,
                        fontSize: 14,
                      }}
                    >
                      <img
                        src={card}
                        alt=""
                        style={{ width: 24, marginRight: 10 }}
                      />
                      Credit or debit card
                    </button>
                  </div> */}
                </div>
                {creditCards && (
                  <div
                    className="cardBox-parent"
                    style={{ marginTop: 20, marginLeft: 5 }}
                  >
                    <input
                      className="card-box-input"
                      type="text"
                      placeholder="Card number (eg. 5555555555554444)"
                      value={cardNumber}
                      onChange={(e) => setcardNumber(e.target.value)}
                      style={{ fontSize: 14, outline: 'none' }}
                    />
                    <div style={{ display: 'flex' }}>
                      <input
                        className="card-box-input"
                        type="text"
                        placeholder="Expiration (12/23)"
                        value={cardExp}
                        onChange={(e) => setcardExp(e.target.value)}
                        style={{
                          fontSize: 14,
                          outline: 'none',
                          borderRadius: '0px 0px 5px 5px',
                          width: 200,
                        }}
                      />
                      <input
                        className="card-box-input"
                        type="text"
                        placeholder="CVV (123)"
                        value={cardCVC}
                        onChange={(e) => setcardCVC(e.target.value)}
                        style={{
                          fontSize: 14,
                          outline: 'none',
                          borderRadius: '0px 0px 5px 0px',
                          width: 200,
                        }}
                      />
                    </div>
                    <input
                      className="card-box-input"
                      type="text"
                      placeholder="ZIP Code"
                      style={{
                        fontSize: 14,
                        outline: 'none',
                        borderRadius: 5,
                        marginTop: 20,
                      }}
                    />
                    {/* <div>
                      <div
                        className="card-box-input"
                        type="text"
                        placeholder="ZIP Code"
                        style={{
                          fontSize: 14,
                          outline: 'none',
                          borderRadius: 5,
                          marginTop: 20,
                          padding: 8,
                        }}
                      >
                        <h5>Country/region</h5>
                        <h4>United States</h4>
                      </div>
                    </div> */}
                    {/* <h4
                      style={{
                        fontWeight: '600',
                        marginTop: 10,
                        marginLeft: 5,
                        textDecoration: 'underline',
                      }}
                    >
                      Enter a coupon
                    </h4> */}
                    <div>
                      <input
                        className="card-box-input"
                        type="text"
                        placeholder="Coupon"
                        style={{
                          fontSize: 14,
                          outline: 'none',
                          borderRadius: 5,
                          marginTop: 20,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {reviewPayPage && (
            <div>
              <h2 style={{ fontWeight: '600', marginTop: 20, fontSize: 24 }}>
                Cancellation Policy
              </h2>
              <div
                style={{
                  marginTop: 20,
                  borderRadius: 10,
                  padding: '16px 16px 24px',
                  backgroundColor: '#F7F7F7',
                  width: 680,
                }}
              >
                <p style={{ color: '#000', fontSize: 16, maxWidth: 799 }}>
                  {room?.property.cancellation_policy.description.slice(
                    0,
                    cancelLimit <= 0
                      ? room?.property.cancellation_policy.description.length
                      : cancelLimit,
                  )}
                </p>
                {/* {showMore(cancelLimit, setCancelLimit)} */}
              </div>
              {horline}
              <p
                style={{
                  fontWeight: '500',
                  width: 680,
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                By selecting the button below, I agree to the{' '}
                <span style={boldUnderlined}>Host’s House Rules,</span>{' '}
                <span> </span>
                <span style={boldUnderlined}>House of Share’s COVID-19</span>
                <br />
                <span> </span>Safety Requirements, and the <span> </span>
                <span style={boldUnderlined}>Guest Refund Policy.</span>{' '}
                <span> </span>I agree to pay the total amount shown if the host
                accepts my booking request.
              </p>
            </div>
          )}
          {reviewPayPage ? (
            <>
              <Button
                type="primary"
                loading={btnLoading ? true : false}
                onClick={() => requestBooking()}
                // onClick={() => showPausedBookingModal()}
                style={{
                  width: 180,
                  height: 40,
                  color: '#fff',
                  border: 'none',
                  margin: '30px 0',
                  borderRadius: 4,
                  backgroundColor: nextBtnColor,
                }}
              >
                <span style={{ fontSize: 16 }}>Request to book</span>
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              loading={nextLoading}
              onClick={() => reviewViewSetter()}
              style={{
                width: 140,
                height: 40,
                color: '#fff',
                border: 'none',
                margin: '30px 0',
                borderRadius: 4,
                backgroundColor: nextBtnColor,
              }}
            >
              <span style={{ fontSize: 16 }}>Next</span>
            </Button>
          )}
        </div>

        <div
          className="rightbar"
          style={{
            marginTop: 40,
            // height: reviewPayPage ? 850 : 620,
            boxShadow: 'none',
            outline: '#ccc 1px solid',
          }}
        >
          <div className="prices" style={{ marginTop: 0 }}>
            <div style={{ display: 'flex' }}>
              <img
                src={`${imgURL}${room.property.image[0].image}` || rectangle}
                style={{
                  width: 138,
                  height: 100,
                  objectFit: 'cover',
                  borderRadius: '8%',
                }}
                alt=""
              />
              <div style={{ margin: '8px 16px' }}>
                <p style={{ width: 240, fontWeight: 600, fontSize: 15 }}>
                  {propertyName}
                </p>
                <label style={{ color: '#0D0119', fontSize: 14 }}>
                  {address}
                </label>
              </div>
            </div>
            <img src="/horline.svg" alt="" />
            <div>
              <h5 style={{ fontWeight: 'bold', marginTop: 20, fontSize: 16 }}>
                Date & time
              </h5>
              <div className="stuff-prices">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <img
                    src={calendar}
                    alt=""
                    style={{ width: 18, marginRight: 10 }}
                  />
                  {dateVal === dateVal2 ? (
                    <h5 style={{ fontSize: 15, fontWeight: 500 }}>
                      {months[+dateVal?.split('-')[1] - 1]}{' '}
                      {dateVal?.slice(8, 10)}
                    </h5>
                  ) : (
                    <h5
                      style={{ marginLeft: 0, fontSize: 15, fontWeight: 500 }}
                    >
                      {months[+dateVal?.split('-')[1] - 1]}{' '}
                      {dateVal?.slice(8, 10)} {'-'}
                      {months[+dateVal2?.split('-')[1] - 1]}{' '}
                      {dateVal2?.slice(8, 10)}
                    </h5>
                  )}
                </div>
              </div>
              <div className="stuff-prices">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <img
                    src={clock}
                    alt=""
                    style={{ width: 18, marginRight: 10 }}
                  />
                  <h5 style={{ fontSize: 15 }}>
                    {get12HourFormat(startTime)} - {get12HourFormat(endTime)}
                  </h5>
                </div>
              </div>
            </div>
            {reviewPayPage && (
              <>
                <div>
                  <h5
                    style={{ fontWeight: 'bold', marginTop: 16, fontSize: 16 }}
                  >
                    Activity
                  </h5>
                  <div className="stuff-prices">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        fontSize: 16,
                      }}
                    >
                      <h5 style={{ fontSize: 16 }}>{activityVal}</h5>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    style={{ fontWeight: 'bold', marginTop: 16, fontSize: 16 }}
                  >
                    Attendees
                  </h5>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>{guestAmount}</h5>
                    </div>
                  </div>
                </div>
                <div>
                  <h5
                    style={{ fontWeight: 'bold', marginTop: 16, fontSize: 16 }}
                  >
                    Alcohol
                  </h5>
                  <div className="stuff-prices">
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <h5 style={{ fontSize: 16 }}>
                        {alcoholConsumption ? 'Yes' : 'No'}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <img src="/horline.svg" style={{ margin: 0 }} alt="" />
          <div className="prices">
            <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>Price detail</h4>
            <div className="stuff-prices">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h5 style={{ fontSize: 16 }}>
                  ${rate.toFixed(2)} x {hours}{' '}
                  {pricingCondition === 1 && 'hours'} x {dateDiff} day(s)
                </h5>
                <Tooltip
                  title={ToolTipMain(ToolTipTexts.price)}
                  placement="bottomLeft"
                  color="#fff"
                >
                  <img
                    src="/moreinfo.svg"
                    alt=""
                    style={{ width: 22, marginLeft: 10 }}
                  />
                </Tooltip>
              </div>
              <h5 style={{ fontSize: 16 }}>${price.toFixed(2)}</h5>
            </div>
            <div className="stuff-prices">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h5 style={{ fontSize: 16 }}>Cleaning fee</h5>
                <Tooltip
                  title={ToolTipMain(ToolTipTexts.cleaningFee)}
                  placement="bottomLeft"
                  color="#fff"
                >
                  <img
                    src="/moreinfo.svg"
                    alt=""
                    style={{ width: 22, marginLeft: 10 }}
                  />
                </Tooltip>
              </div>
              <h5 style={{ fontSize: 16 }}>${cleaningFee.toFixed(2)}</h5>
            </div>
            <div className="stuff-prices">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h5 style={{ fontSize: 16 }}>Service fee</h5>
                <Tooltip
                  title={ToolTipMain(ToolTipTexts.serviceFee)}
                  placement="bottomLeft"
                  color="#fff"
                >
                  <img
                    src="/moreinfo.svg"
                    alt=""
                    style={{ width: 22, marginLeft: 10 }}
                  />
                </Tooltip>
              </div>

              <h5 style={{ fontSize: 16 }}>
                $
                {(Math.round(serviceFeeWithTransaction * 100) / 100).toFixed(2)}
              </h5>
            </div>
            <div className="stuff-prices">
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h5 style={{ fontSize: 16 }}>Discount</h5>
                <Tooltip
                  title={ToolTipMain(ToolTipTexts.discount)}
                  placement="bottomLeft"
                  color="#fff"
                >
                  <img
                    src="/moreinfo.svg"
                    alt=""
                    style={{ width: 22, marginLeft: 10 }}
                  />
                </Tooltip>
              </div>
              <h5 style={{ color: '#808080', fontSize: 16 }}>
                ${parseFloat(discount)?.toFixed(2)}
              </h5>
            </div>
            {couponSuccess && (
              <div className="stuff-prices">
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <h5 style={{ fontSize: 16 }}>Coupon Discount</h5>
                  <Tag
                    color="white"
                    icon={<TagFilled style={{ color: 'black' }} />}
                  >
                    <span style={{ color: 'black' }}>{enteredCoupon}</span>
                  </Tag>
                </div>
                <h5 style={{ color: '#808080', fontSize: 16 }}>
                  {/* ${discount.toFixed(2)} */}-${' '}
                  {(
                    totalWithoutServiceFee -
                    priceAfterCouponApplied(
                      totalWithoutServiceFee,
                      couponResponse?.discount_percentage,
                      // 5,
                    )
                  ).toFixed(2)}
                </h5>
              </div>
            )}
          </div>
          <hr />
          <p
            style={styles.addCouponCode}
            onClick={() => setShowCouponInput(!showCouponInput)}
          >
            Add Coupon Code
          </p>
          {showCouponInput && (
            <div style={styles.couponCodeInputContainer}>
              <Input
                placeholder="Coupon code goes here"
                style={{ width: '50%' }}
                allowClear
                value={enteredCoupon}
                onChange={(e) => {
                  setCouponSuccess(false)
                  setCouponError(false)
                  setCouponResponse(null)
                  setEnteredCoupon(e.target.value)
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={couponValidationReq}
              >
                Apply
              </Button>
            </div>
          )}
          {couponError && (
            <p style={{ color: 'red', marginTop: 9 }}>Invalid Code</p>
          )}
          {couponSuccess && (
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}
            >
              <p style={{ color: 'green' }}>Coupon Applied Successfully!</p>
              <p style={{ marginLeft: 20 }}>
                <Tag
                  color="#dbd9d9"
                  icon={<TagFilled style={{ color: 'black' }} />}
                >
                  <span style={{ color: 'black' }}>{enteredCoupon}</span>
                  <CloseOutlined style={{ color: 'black', marginLeft: 5 }} />
                </Tag>
              </p>
            </div>
          )}
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px 0px',
            }}
          >
            <h3 style={{ fontWeight: 'bold', fontSize: 24 }}>Total</h3>
            <h3 style={{ fontWeight: 'bold', fontSize: 20 }}>
              $
              {couponResponse
                ? (
                    priceAfterCouponApplied(
                      totalWithoutServiceFee.toFixed(2),
                      couponResponse.discount_percentage,
                      // 5,
                    ) + +serviceFeeWithTransaction.toFixed(2)
                  ).toFixed(2)
                : total.toFixed(2)}
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}

const listStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 10,
  width: '110%',
  marginTop: 20,
}

const styles = {
  addCouponCode: {
    marginTop: 20,
    color: '#6567e6',
    fontSize: 16,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  couponCodeInputContainer: {
    display: 'flex',
  },
}

export default Booking

// Calculate tip percentage with grand total to show in the tag
const priceAfterCouponApplied = (total, coupnDiscountPercentage = 0) => {
  // console.log(`priceAfterCouponApplied() called`)
  // console.log(total)
  // console.log(coupnDiscountPercentage)

  const isNumber = (value) => {
    return typeof value === 'number' && isFinite(value)
  }

  // coupnDiscountPercentage = 5 // FOR TEST ONLY

  if (isNumber(coupnDiscountPercentage)) {
    // console.log('returning: ', total - (total * (coupnDiscountPercentage / 100)).toFixed(2))
    return total - (total * (coupnDiscountPercentage / 100)).toFixed(2)
  }
  return 0
}
