import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import verifiedTextSVG from '../img/verified.svg'
import defaultProfileSVG from '../pages/icons/default_profile.svg'
import profileImg from '../img/Profile.svg'
import EmailModal from '../components/popups/EmailModal'
import PhoneNumberModal from '../components/popups/PhoneNumberModal'
import ProfilePhotoUpload from '../components/popups/ProfilePhotoUpload'
import Button1 from '../components/buttons/Button1'
import '../style/renterProfile.css'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { message, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import useInterval from '@use-it/interval'
import {
  logout,
  refresh,
  resendVerifyEmailAccountLink,
  resendVerifyPhoneCode,
} from '../redux/actions/AuthAction'
import { TopMsg } from '../components/Topmsg'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'

const RenterProfile = () => {
  const dispatch = useDispatch()
  const [emailModal, setEmailModal] = useState(false)
  const [phoneModal, setPhoneModal] = useState(false)
  const [profilePhotoModal, setProfilePhotoModal] = useState(false)
  const authRedux = useSelector((state) => state.auth)
  const navigate = useNavigate()

  const [legalName, setLegalName] = useState(false)
  const [org, setOrg] = useState(false)
  const [jobTitle, setJobTitle] = useState(false)
  const [dob, setDob] = useState(false)
  const [getProfileLoading, setGetProfileLoading] = useState(false)
  const [pass, setPass] = useState(false)
  const [firstNameVal, setFirstNameVal] = useState('')
  const [lastNameVal, setLastNameVal] = useState('')
  const [orgVal, setOrgVal] = useState('')
  const [jobTitleVal, setjobTitleVal] = useState('')
  const [dobVal, setDobVal] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [phnNumberVal, setPhnNumberVal] = useState('')
  const [currentpassVal, setcurrentpassVal] = useState('')
  const [newPassVal, setNewpassVal] = useState('')
  const [confirmPassVal, setconfirmpassVal] = useState('')
  const [dpImg, setDpImg] = useState('')
  const [phnVerified, setPhnVerified] = useState()
  const [emailVerified, setEmailVerified] = useState()
  const [showSendEmailSendLoadingBar, setShowSendEmailSendLoadingBar] =
    useState(false)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const baseURL = process.env.REACT_APP_BASE_URL
  const getProfile = async (reduxData) => {
    try {
      setGetProfileLoading(true)

      // Getting this 'reduxData' on login() and me() actions
      if (reduxData) {
        // First store from redux
        setjobTitleVal(reduxData['Job_title'])
        setOrgVal(reduxData['Organization'])
        setDobVal(reduxData['birth_date'])
        setEmailVal(reduxData['email'])
        setFirstNameVal(reduxData['first_name'])
        setLastNameVal(reduxData['last_name'])
        // If first_name and last_name is empty string and name not empty then set the name as setFirstNameVal value to render at UI properly.
        // ! It is happening mostly on google login account at Renter side. (Thats why its a tricky fix at UI)
        if (
          !reduxData['first_name'] &&
          !reduxData['last_name'] &&
          reduxData['name']
        ) {
          setFirstNameVal(reduxData['name'])
        }
        setPhnNumberVal(reduxData['phone'])
        setDpImg(reduxData.image)
        setPhnVerified(reduxData.phone_verified)
        setEmailVerified(reduxData.verified)
      }

      // Then update the data with another get request
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const res = await axios(`${baseURL}/guest`, {
        headers: {
          Authorization: authData,
        },
      })
      console.log('GET PROFILE', res.status, res.data)
      setjobTitleVal(res.data['Job_title'])
      setOrgVal(res.data['Organization'])
      setDobVal(res.data['birth_date'])
      setEmailVal(res.data['email'])
      setFirstNameVal(res.data['first_name'])
      setLastNameVal(res.data['last_name'])
      // If first_name and last_name is empty string and name not empty then set the name as setFirstNameVal value to render at UI properly.
      // ! It is happening mostly on google login account at Renter side. (Thats why its a tricky fix at UI)
      if (
        !res.data['first_name'] &&
        !res.data['last_name'] &&
        res.data['name']
      ) {
        setFirstNameVal(res.data['name'])
      }
      setPhnNumberVal(res.data['phone'])
      setDpImg(res.data.image)
      setPhnVerified(res.data.phone_verified)
      setEmailVerified(res.data.verified)
      setGetProfileLoading(false)
    } catch (err) {
      if (err.response.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        message.error(err.response.data.detail)
      }
    }
  }
  useEffect(() => {
    ;(async () => {
      console.log('103ln')
      console.log(authRedux)
      getProfile(authRedux.user.guestProfileData)
    })()
    return () => getProfile()
  }, [])

  const legalNameStyle = { fontSize: 16 }
  const inputTitle = (title) => {
    const titleStyle = {
      position: 'absolute',
      marginTop: 8,
      marginLeft: 16,
      fontSize: 12,
      fontWeight: 400,
    }
    return <h1 style={titleStyle}>{title}</h1>
  }
  const editBtnComponent = (item, setItem) => {
    const handleClick = () => {
      setItem(!item)
      if (item) getProfile() // if Cancel button is pressed the, then in order to show the accurate content again, fetching the document. Incase user can change the input field and Cancel it.
    }
    return (
      <div onClick={handleClick}>
        <h1 style={editStyle}>{item ? 'Cancel' : 'Edit'}</h1>
      </div>
    )
  }

  const cookie = new Cookies()
  const authData = cookie.get('jwt')
  const config = {
    headers: {
      Authorization: authData,
    },
  }
  const onChangeNames = async (firstNameVal, lastNameVal) => {
    try {
      const data = {
        first_name: firstNameVal,
        last_name: lastNameVal,
      }
      await axios.patch(`${baseURL}/guest/`, data, config)
      message.success(TopMsg('Updated info successfully'))
    } catch (err) {
      message.error(err.response.statusText)
    }
  }
  const onChangeOrg = async (orgVal) => {
    try {
      const data = {
        Organization: orgVal,
      }
      await axios.patch(`${baseURL}/guest/`, data, config)
      message.success(TopMsg('Updated info successfully'))
    } catch (err) {
      message.error(err.response.statusText)
    }
  }
  const onChangeJobTitle = async (jobTitleVal) => {
    try {
      const data = {
        Job_title: jobTitleVal,
      }
      await axios.patch(`${baseURL}/guest/`, data, config)
      message.success(TopMsg('Updated info successfully'))
    } catch (err) {
      message.error(err.response.statusText)
    }
  }
  const onChangeBirthDate = async (dobVal) => {
    try {
      const data = {
        birth_date: dobVal,
      }
      if (moment().diff(dobVal, 'years') < 18) {
        message.error('The user should be 18 years old')
      } else {
        await axios.patch(`${baseURL}/guest/`, data, config)
        message.success(TopMsg('Updated info successfully'))
      }
    } catch (err) {
      message.error(err.response.statusText)
    }
  }
  const onChangePass = async (currentpassVal, newPassVal, confirmPassVal) => {
    try {
      if (newPassVal !== confirmPassVal) {
        message.error('Passwords do not match')
      }
      const data = {
        prev_password: currentpassVal,
        password: newPassVal,
      }
      await axios.patch(`${baseURL}/guest/`, data, config)
      setNewpassVal()
      setcurrentpassVal()
      setPass()
      message.success(TopMsg('Updated info successfully'))
    } catch (err) {
      message.error(err.response.statusText)
    }
  }
  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)

  // For verification
  let reSendEmail = () => {
    setShowSendEmailSendLoadingBar(true)
    dispatch(
      resendVerifyEmailAccountLink({
        callBack: (response) => {
          setShowSendEmailSendLoadingBar(false)
          console.log(response)
          message.success(
            TopMsg('Verification email resend to your mailbox again.'),
          )
        },
      }),
    )
  }

  // For mobile verification
  let reSendPhoneCode = () => {
    // setShowSendEmailSendLoadingBar(true)
    const payload = {
      phone: phnNumberVal,
      email: emailVal,
      type: 'Guest',
    }
    dispatch(
      resendVerifyPhoneCode(
        {
          callBack: (response) => {
            // setShowSendEmailSendLoadingBar(false)
            console.log(response)
            message.success(TopMsg('Verification code resend to your mobile.'))
          },
        },
        payload,
      ),
    )
  }

  return (
    <div
      style={{
        width: '100vw',
        maxWidth: '100%',
        border: '1px solid white',
      }}
    >
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      <div
        style={{
          display: 'flex',
          border: '3px solid white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ maxWidth: '1380px', border: '1px solid white' }}>
          {getProfileLoading && !firstNameVal ? ( // "firstNameVal" is used to make sure if no user data is stored previously at state, if "firstNameVal" value is available then it means user data is previously stored, so don't show this Spin. Use other spinner if needed.
            <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
          ) : (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
                // padding: 5,
              }}
            >
              <div style={{ margin: 50 }}>
                <h2 style={{ marginLeft: 20, fontSize: '2rem' }}>Profile</h2>
                <div style={{ marginTop: 30 }}>
                  <div style={parentStyle}>
                    {legalName ? (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Legal Name</h5>
                        <h1
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#656369',
                            marginBottom: 25,
                          }}
                        >
                          This is the name on your travel document, which could
                          be a license or a passport.
                        </h1>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginRight: 10 }}>
                            {inputTitle('First Name')}
                            <input
                              type="text"
                              value={firstNameVal}
                              onChange={(e) => setFirstNameVal(e.target.value)}
                              onKeyDown={(e) =>
                                e.key === 'Enter' && setLegalName(!legalName)
                              }
                              style={valueInputStyle}
                            />
                          </div>
                          <div style={{ marginInline: 10 }}>
                            {inputTitle('Last Name')}
                            <input
                              type="text"
                              value={lastNameVal}
                              onChange={(e) => setLastNameVal(e.target.value)}
                              onKeyDown={(e) =>
                                e.key === 'Enter' && setLegalName(!legalName)
                              }
                              style={valueInputStyle}
                            />
                          </div>
                        </div>
                        <div
                          style={{ margin: '20px 0' }}
                          onClick={() => {
                            setLegalName(!legalName)
                          }}
                        >
                          <div
                            onClick={() =>
                              onChangeNames(firstNameVal, lastNameVal)
                            }
                          >
                            <Button1 title="Save" width={87} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Legal Name</h5>
                        <p style={{ fontSize: 16 }}>
                          {firstNameVal} {lastNameVal}
                        </p>
                      </div>
                    )}
                    {editBtnComponent(legalName, setLegalName)}
                  </div>
                  <div style={parentStyle}>
                    {org ? (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Organization</h5>
                        {/* <h1
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#656369',
                            marginBottom: 25,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </h1> */}
                        <div style={{ marginRight: 10 }}>
                          {inputTitle('Organization Name')}
                          <input
                            type="text"
                            value={orgVal}
                            onChange={(e) => setOrgVal(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && setOrg(!org)}
                            style={valueInputStyle}
                          />
                        </div>
                        <div
                          style={{ margin: '20px 0' }}
                          onClick={() => {
                            setOrg(!org)
                          }}
                        >
                          <div onClick={() => onChangeOrg(orgVal)}>
                            <Button1 title="Save" width={87} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Organization</h5>
                        <p style={{ fontSize: 16 }}>{orgVal}</p>
                      </div>
                    )}
                    {editBtnComponent(org, setOrg)}
                  </div>
                  <div style={parentStyle}>
                    {jobTitle ? (
                      <div>
                        <h5 style={legalNameStyle}>Job title</h5>
                        {/* <h1
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#656369',
                            marginBottom: 25,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </h1> */}
                        <div style={{ marginRight: 10 }}>
                          {inputTitle('Job title')}
                          <input
                            type="text"
                            value={jobTitleVal}
                            onChange={(e) => setjobTitleVal(e.target.value)}
                            onKeyDown={(e) =>
                              e.key === 'Enter' && setJobTitle(!jobTitle)
                            }
                            style={valueInputStyle}
                          />
                        </div>
                        <div
                          style={{ margin: '20px 0' }}
                          onClick={() => {
                            setJobTitle(!jobTitle)
                          }}
                        >
                          <div onClick={() => onChangeJobTitle(jobTitleVal)}>
                            <Button1 title="Save" width={87} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Job title</h5>
                        <p style={{ fontSize: 16 }}>{jobTitleVal}</p>
                      </div>
                    )}
                    {editBtnComponent(jobTitle, setJobTitle)}
                  </div>
                  <div style={parentStyle}>
                    {dob ? (
                      <div>
                        <h5 style={legalNameStyle}>Date of birth</h5>
                        {/* <h1
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#656369',
                            marginBottom: 25,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </h1> */}
                        <div style={{ marginRight: 10 }}>
                          {inputTitle('Date of birth')}
                          <input
                            type="date"
                            value={dobVal}
                            onChange={(e) => {
                              setDobVal(e.target.value)
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setDob(!dob)
                              }
                            }}
                            style={{
                              width: 373.5,
                              height: 56,
                              borderRadius: 8,
                              border: '1px solid #D9D9D9',
                              textIndent: 7,
                              fontSize: 16,
                              paddingTop: 14,
                            }}
                          />
                        </div>
                        <div
                          style={{ margin: '20px 0' }}
                          onClick={() => {
                            setDob(!dob)
                          }}
                        >
                          <div onClick={() => onChangeBirthDate(dobVal)}>
                            <Button1 title="Save" width={87} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Date of birth</h5>
                        <p style={{ fontSize: 16 }}>{dobVal}</p>
                      </div>
                    )}
                    {editBtnComponent(dob, setDob)}
                  </div>
                  <div style={parentStyle}>
                    <div>
                      <h5 style={{ fontSize: 16 }}>Email Address</h5>
                      <p style={{ fontSize: 16 }}>{emailVal}</p>
                    </div>
                    <div onClick={() => setEmailModal(!emailModal)}>
                      <h1 style={editStyle}>Edit</h1>
                    </div>
                  </div>
                  <div style={parentStyle}>
                    <div>
                      <h5 style={{ fontSize: 16 }}>Phone Number</h5>
                      {phnNumberVal !== '0' ? (
                        <p style={{ fontSize: 16 }}>+{phnNumberVal}</p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <h1
                        style={editStyle}
                        onClick={() => setPhoneModal(!phoneModal)}
                      >
                        Edit
                      </h1>
                    </div>
                  </div>
                  <div style={parentStyle}>
                    {pass ? (
                      <div>
                        <h5 style={legalNameStyle}>Password</h5>
                        <h1
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: '#656369',
                            marginBottom: 25,
                          }}
                        >
                          Edit your password
                        </h1>
                        <div style={{ marginRight: 10 }}>
                          {/* {inputTitle('Current Password')} */}
                          <input
                            type="password"
                            placeholder="Current Password"
                            value={currentpassVal}
                            onChange={(e) => setcurrentpassVal(e.target.value)}
                            style={{ ...valueInputStyle, paddingTop: 0 }}
                          />
                        </div>
                        {/* <h1
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      textDecorationLine: 'underline',
                      margin: '20px 5px',
                    }}
                  >
                    Forgot password?
                  </h1> */}
                        <div style={{ marginTop: 10 }}>
                          {/* {inputTitle('New Password')} */}
                          <input
                            type="password"
                            placeholder="New Password"
                            value={newPassVal}
                            onChange={(e) => setNewpassVal(e.target.value)}
                            style={{ ...valueInputStyle, paddingTop: 0 }}
                          />
                        </div>
                        <div style={{ marginTop: 14 }}>
                          {/* {inputTitle('Confirm Password')} */}
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassVal}
                            onChange={(e) => setconfirmpassVal(e.target.value)}
                            onKeyDown={(e) =>
                              e.key === 'Enter' && setPass(!pass)
                            }
                            style={{ ...valueInputStyle, paddingTop: 0 }}
                          />
                        </div>
                        <div
                          style={{ margin: '20px 0' }}
                          onClick={() => {
                            setPass(!pass)
                          }}
                        >
                          <div
                            onClick={() =>
                              onChangePass(
                                currentpassVal,
                                newPassVal,
                                confirmPassVal,
                              )
                            }
                          >
                            <Button1 title="Save" width={87} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5 style={{ fontSize: 16 }}>Password</h5>
                        <p style={{ fontSize: 16 }}>***********</p>
                      </div>
                    )}
                    {editBtnComponent(pass, setPass)}
                  </div>
                </div>
              </div>
              <div style={{ margin: 50 }}>
                <div
                  style={{
                    border: '#D9D9D9 1px solid',
                    borderRadius: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 443,
                    height: 418,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {dpImg === null ? (
                      <img
                        src={defaultProfileSVG}
                        alt=""
                        style={{
                          marginTop: 20,
                          objectFit: 'cover',
                          width: 120,
                          height: 120,
                          // border: '0px #ccc solid',
                          // borderRadius: '50%',
                          opacity: 0.7,
                          marginBottom: 16,
                        }}
                      />
                    ) : (
                      <img
                        src={`${baseURL.replace('/api', '')}${dpImg}`}
                        alt=""
                        style={{
                          marginTop: 20,
                          objectFit: 'cover',
                          width: 120,
                          height: 120,
                          border: '1px #ccc solid',
                          borderRadius: '50%',
                          marginBottom: 16,
                        }}
                        // key={Date.now()}
                      />
                    )}

                    <label
                      onClick={() => setProfilePhotoModal(true)}
                      style={{
                        textDecoration: 'underline',
                        fontSize: 16,
                        fontWeight: 500,
                        marginBottom: 20,
                        cursor: 'pointer',
                      }}
                    >
                      Upload a Photo
                    </label>
                    <div
                      style={{
                        borderBottom: '1px solid #D9D9D9',
                        margin: 16,
                        width: 400,
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '90%',
                        margin: 15,
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: 16 }}>Email Address</h5>
                      </div>
                      <div>
                        {emailVerified ? (
                          <img src={verifiedTextSVG} alt="" />
                        ) : (
                          <>
                            {/* <img src={notProvidedTextSVG} alt="" /> */}
                            <p
                              style={{
                                color: 'red',
                                fontSize: 14,
                                textAlign: 'right',
                              }}
                            >
                              ⚠ Not Verified
                            </p>
                            <h4
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                margin: 0,
                              }}
                              onClick={() => reSendEmail()}
                            >
                              {showSendEmailSendLoadingBar ? (
                                <Spin />
                              ) : (
                                `Resend Verification Email`
                              )}
                            </h4>
                          </>
                        )}
                      </div>
                    </div>
                    <ProfilePhotoUpload
                      title="Upload profile photo"
                      profilePhotoModal={profilePhotoModal}
                      setProfilePhotoModal={setProfilePhotoModal}
                      getProfile={getProfile}
                    />
                    <EmailModal
                      emailVal={emailVal}
                      setEmailVal={setEmailVal}
                      emailModal={emailModal}
                      setEmailModal={setEmailModal}
                      getProfile={getProfile}
                      reSendEmail={reSendEmail}
                    />
                    <PhoneNumberModal
                      title="Change Phone Number"
                      phoneModal={phoneModal}
                      setPhoneModal={setPhoneModal}
                      phnNumberVal={phnNumberVal}
                      setPhnNumberVal={setPhnNumberVal}
                      getProfile={getProfile}
                      reSendPhoneCode={reSendPhoneCode}
                      email={emailVal}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '90%',
                        margin: 15,
                      }}
                    >
                      <div>
                        <h5 style={{ fontSize: 16 }}>Phone Number</h5>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        {!phnNumberVal && (
                          <h4
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              textDecoration: 'underline',
                              cursor: 'context-menu',
                            }}
                            onClick={() => setPhoneModal(true)}
                          >
                            Add Now
                          </h4>
                        )}

                        {phnNumberVal && !phnVerified && (
                          <>
                            {/* <img src={notProvidedTextSVG} alt="" /> */}
                            <p style={{ color: 'red', fontSize: 14 }}>
                              ⚠ Not Verified
                            </p>
                            <h4
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                margin: 0,
                              }}
                              onClick={() => reSendPhoneCode()}
                            >
                              {showSendEmailSendLoadingBar ? (
                                <Spin />
                              ) : (
                                `Resend Verification Code`
                              )}
                            </h4>
                          </>
                        )}

                        {phnNumberVal && phnVerified && (
                          <img src={verifiedTextSVG} alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const parentStyle = {
  display: 'flex',
  borderBottom: '1px solid #D9D9D9',
  width: 600,
  justifyContent: 'space-between',
  margin: 20,
}
const editStyle = {
  fontSize: 16,
  fontWeight: 500,
  color: '#6568E6',
  textDecoration: 'underline',
  marginRight: 10,
  cursor: 'pointer',
}
const valueInputStyle = {
  width: 373.5,
  height: 56,
  borderRadius: 8,
  border: '1px solid #D9D9D9',
  textIndent: 14,
  fontSize: 16,
  paddingTop: 14,
}

export default RenterProfile
