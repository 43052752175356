import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import userIcon from '../../img/group.svg'
import checkIcon from '../../img/check.svg'
import Button1 from '../buttons/Button1'
// import CalendarMenu from '../calendar/CalendarMenu'
import axios from 'axios'
import { baseURL } from '../../redux/requests'
import ActivityList from '../ActivityList'
import Cookies from 'universal-cookie'
import { months } from '../utils/months'
import CalendarMenuMain from '../calendar/CalendarMenuMain'
import { useLocation } from 'react-router-dom'
import get12HourFormat from '../utils/get12HourFormat'
import moment from 'moment'
import useOnclickOutside from 'react-cool-onclickoutside'
import disableScroll from 'disable-scroll'
import { timeListArr } from '../utils/timeListArr'

const HostModal = (props) => {
  const {
    optOutActivities,
    capacity,
    hostModal,
    setHostModal,
    spaceList,
    room,
  } = props
  const location = useLocation()
  const date = new Date()
  const [startTimeMenu, setStartTimeMenu] = useState(false)
  const [endTimeMenu, setEndTimeMenu] = useState(false)
  const currentTimeHour =
    moment().minutes() > 30
      ? moment().add(30, 'minutes').format('HH')
      : moment().format('HH')
  const currentTimeMin =
    moment().minutes() > 0 ? (moment().minutes() < 15 ? '15' : '30') : '00'
  const currentTimeStart = currentTimeHour + ':' + currentTimeMin + ':' + '00'
  const currentTimeEnd =
    +currentTimeHour + 3 + ':' + currentTimeMin + ':' + '00'
  const [startTime, setStartTime] = useState(currentTimeStart)
  const [endTime, setEndTime] = useState(currentTimeEnd)
  const [successMenu, setSuccessMenu] = useState(false)
  const [activityValID, setactivityValID] = useState('')
  const [activityItemSelected, setactivityItemSelected] = useState(false)
  const [activityItemsArray, setactivityItemsArray] = useState([])
  const [activityVal, setActivityVal] = useState('')
  const [guestAmount, setguestAmount] = useState('')
  const [msgToHost, setMsgToHost] = useState('')
  const [alcoholConsumption, setAlcoholConsumption] = useState(false)
  const [countCalPress, setcountCalPress] = useState(0)
  const [activityListView, setactivityListView] = useState(false)
  const [invalidFormat, setInvalidFormat] = useState(false)
  const [calendarVisible, setcalendarVisible] = useState(false)
  const [inAvailableTime, setInavailableTime] = useState(false)
  const [availableTimeRange, setAvailableTimeRange] = useState('')
  // const valueDate = moment().format('YYYY-MM-DD')
  // const [props.dateVal, setDateVal] = useState(valueDate)
  // const [props.dateVal2, setDateVal2] = useState(valueDate)
  // const room = location.state.room

  // const optOutActivities = room.opt_out_activities
  useEffect(() => {
    const format = 'hh:mm:ss'
    const checkAvailability = () => {
      room?.property?.availability.map((item) => {
        if (
          item.day.day.toLowerCase() ===
          moment(props.dateVal).format('dddd').toString().toLowerCase()
        ) {
          const startTimeItem = moment(item.start, format)
          const endTimeItem = moment(item.end, format)
          const startIsSameOrAfter = moment(startTime, format).isSameOrAfter(
            startTimeItem,
          )
          const startIsSameOrBefore = moment(startTime, format).isSameOrBefore(
            endTimeItem,
          )
          const endIsSameOrAfter = moment(endTime, format).isSameOrAfter(
            startTimeItem,
          )
          const endIsSameOrBefore = moment(endTime, format).isSameOrBefore(
            endTimeItem,
          )
          const checkerArr = [
            startIsSameOrAfter,
            startIsSameOrBefore,
            endIsSameOrAfter,
            endIsSameOrBefore,
          ]
          const checker = checkerArr.every((statement) => statement === true)
          if (checker) {
            setInavailableTime(false)
            setAvailableTimeRange('')
            setInvalidFormat(false)
          } else {
            setInavailableTime(true)
            setInvalidFormat(true)
            setAvailableTimeRange(
              'The available time is between ' +
                moment(item.start, format).format('hh:mm A').toString() +
                ' & ' +
                moment(item.end, format).format('hh:mm A').toString(),
            )
          }
        }
      })
    }
    checkAvailability()
    return () => checkAvailability()
  })

  useEffect(() => {
    calendarVisible ? disableScroll.on() : disableScroll.off()
  }, [calendarVisible])

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      const classList = e.target.classList
      if (
        classList.contains('ant-modal-body') ||
        classList.contains('date-time') ||
        classList.contains('activity-text') ||
        classList.contains('people-attend-text') ||
        classList.contains('alcohol-text')
      ) {
        setcalendarVisible(false)
      }
    })
  }, [])

  const errorMsg = (err) => (
    <>
      <label style={{ fontSize: 16 }}>{err.response.data[0]}</label>
    </>
  )
  // const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL.split('/api')[0]
  const handleOk = async () => {
    if (!activityValID) {
      message.error(
        "We don't have the activity at the moment. Please select an activity closest to what you are planning and explain more to the host in the message below.",
      )
      return
    }
    try {
      const url = `${baseURL}/guest/space-reservation`
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      if (!authData || authData === '' || authData === undefined) {
        setHostModal(false)
      }
      const data = {
        date: [
          props.dateVal === props.dateVal2 ? props.dateVal : props.dateVal,
          props.dateVal2,
        ],
        start: startTime,
        end: endTime,
        price: spaceList.id,
        space: spaceList.property.id,
        alcohol_consumption: alcoholConsumption,
        message_to_host: msgToHost,
        expected_guest_amount: +guestAmount,
        activity: activityValID,
        is_inquiry: true,
      }
      // console.log(data)
      const config = {
        headers: {
          Authorization: authData,
        },
      }
      const res = await axios.post(url, data, config)
      if (res.status === 200) {
        setSuccessMenu(true)
      }
    } catch (err) {
      if (!err) {
        setSuccessMenu(true)
      } else {
        message.error(err.response.data.detail)
      }
    }
  }

  const handleCancel = () => {
    setcalendarVisible(false)
    setHostModal(false)
    setSuccessMenu(false)
  }

  const getActivity = async (val) => {
    try {
      if (val.length === 0) {
        setactivityListView(false)
      } else {
        const res = await axios.post(`${baseURL}/guest/activity`, {
          name: activityVal,
        })
        setactivityItemsArray(
          res.data
            .filter(
              (ar) => !optOutActivities.find((rm) => rm.title === ar.title),
            )
            .map((item) => item),
        )
        setactivityListView(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const CalendarRef = useOnclickOutside(() => {
    setcalendarVisible(false)
  })

  useEffect(() => {
    if (startTime === '' || endTime === '') {
      setInvalidFormat(false)
    } else {
      const beginningTime = moment(startTime, 'h:mma')
      const end = moment(endTime, 'h:mma')
      if (beginningTime.isBefore(end)) {
        setInvalidFormat(false)
      } else {
        setInvalidFormat(true)
      }
    }
  }, [startTime, endTime])

  const maxCapacityInput = (e) => {
    if (e.target.value > capacity) {
      message.error(
        'Maximum capacity is ' +
          capacity +
          ', number of guests exceeds the max capacity for this space.',
      )
      return
    }
    setguestAmount(e.target.value)
  }

  return (
    <>
      <Modal
        title=""
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Send"
        visible={hostModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: !successMenu ? '3%' : '15%' }}
        destroyOnClose
        okButtonProps={{ style: { display: !successMenu ? 'block' : 'none' } }}
      >
        {!successMenu ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${imgURL}${room?.property?.host?.image}`}
                style={{
                  width: 51,
                  height: 51,
                  objectFit: 'contain',
                  borderRadius: '100%',
                }}
                alt=""
              />
              <h5 style={{ fontWeight: 'bold', marginLeft: 10, fontSize: 16 }}>
                {room?.property?.host?.name}
              </h5>
            </div>
            <h5
              style={{
                color: '#777',
                marginTop: 25,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Fill all the important information below:
            </h5>
            <h4 className="date-time" style={titleStyle}>
              Date & Time
            </h4>
            <div>
              <div
                className="date-dropdown"
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingInline: 40,
                }}
                onClick={() => setcalendarVisible(!calendarVisible)}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    padding: 5,
                  }}
                >
                  <h5 style={{ fontWeight: 'bold', fontSize: 12 }}>Date</h5>
                  {props.dateVal === props.dateVal2 ? (
                    <h5 style={{ fontSize: 14 }}>
                      {months[+props.dateVal.split('-')[1] - 1]}{' '}
                      {props.dateVal.slice(8, 10)}
                    </h5>
                  ) : (
                    <h5 style={{ fontSize: 14 }}>
                      {months[+props.dateVal.split('-')[1] - 1]}{' '}
                      {props.dateVal.slice(8, 10)} {'-'}
                      {months[+props.dateVal2.split('-')[1] - 1]}{' '}
                      {props.dateVal2.slice(8, 10)}
                    </h5>
                  )}
                </div>
                <img src="/dropdown.svg" alt="" style={{ marginLeft: 0 }} />
              </div>
              <div className="start-end-date" style={{ width: 472 }}>
                <div
                  className="start-end"
                  style={{
                    borderRadius: '0px 0px 0px 10px',
                    border: invalidFormat
                      ? '1px #DF3416 solid'
                      : '1px #ccc solid',
                    padding: 5,
                  }}
                  onClick={() => {
                    if (spaceList?.pricing_condition?.id === 2) {
                      return
                    }
                    setStartTimeMenu(!startTimeMenu)
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h6
                      style={{
                        fontWeight: 'bold',
                        color: invalidFormat ? '#DF3416' : '#000',
                      }}
                    >
                      Start time
                    </h6>
                    <h6 style={{ fontSize: 16 }}>
                      {get12HourFormat(startTime)}
                    </h6>
                  </div>
                  {startTimeMenu && (
                    <ul
                      className="date-menu2 date-menu-common"
                      style={{ marginTop: 360, width: 120, marginLeft: -100 }}
                    >
                      {timeListArr.map((item, idx) => {
                        return (
                          <label
                            key={idx}
                            className={
                              item === startTime
                                ? 'time-text2-focus'
                                : 'time-text2'
                            }
                            onClick={() => setStartTime(item)}
                          >
                            {get12HourFormat(item)}
                          </label>
                        )
                      })}
                    </ul>
                  )}
                  <img src="/dropdown.svg" alt="" />
                </div>
                <div
                  className="start-end"
                  style={{
                    borderRadius: '0px 0px 10px 0px',
                    border: invalidFormat
                      ? '1px #DF3416 solid'
                      : '1px #ccc solid',
                    padding: 5,
                  }}
                  onClick={() => {
                    if (spaceList?.pricing_condition?.id === 2) {
                      return
                    }
                    setEndTimeMenu(!endTimeMenu)
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h6
                      style={{
                        fontWeight: 'bold',
                        color: invalidFormat ? '#DF3416' : '#000',
                      }}
                    >
                      End time
                    </h6>
                    <h6 style={{ fontSize: 16 }}>{get12HourFormat(endTime)}</h6>
                  </div>
                  {endTimeMenu && (
                    <ul
                      className="date-menu2 date-menu-common"
                      style={{ marginTop: 360, width: 120, marginLeft: -105 }}
                    >
                      {timeListArr.map((item, idx) => {
                        return (
                          <label
                            key={idx}
                            className={
                              item === endTime
                                ? 'time-text2-focus'
                                : 'time-text2'
                            }
                            onClick={() => setEndTime(item)}
                          >
                            {get12HourFormat(item)}
                          </label>
                        )
                      })}
                    </ul>
                  )}
                  <img src="/dropdown.svg" alt="" />
                </div>
              </div>
              {inAvailableTime && (
                <h4 style={{ fontSize: 14, color: 'red', margin: 5 }}>
                  {availableTimeRange}
                </h4>
              )}
            </div>
            {calendarVisible && (
              <div
                style={{
                  position: 'absolute',
                  marginTop: -480,
                  marginLeft: -85,
                }}
              >
                <CalendarMenuMain
                  dateVal={props.dateVal}
                  setDateVal={props.setDateVal}
                  dateVal2={props.dateVal2}
                  setDateVal2={props.setDateVal2}
                  countCalPress={countCalPress}
                  setcountCalPress={setcountCalPress}
                  calendarVisible={calendarVisible}
                  setcalendarVisible={setcalendarVisible}
                  width={430}
                />
              </div>
            )}
            <h4 className="activity-text" style={titleStyle}>
              Activity
            </h4>
            <input
              type="text"
              placeholder="Activity"
              style={inputStyle}
              value={activityVal}
              onChange={(e) => {
                setActivityVal(e.target.value)
              }}
              onKeyUp={(e) => getActivity(e.target.value)}
            />
            {activityListView && (
              <div
                style={{
                  // marginTop: -120,
                  position: 'absolute',
                  backgroundColor: '#fff',
                  border: '#cccccc 1px solid',
                  boxShadow: 'rgba(0, 0, 0, 0.07) 2px 2px 10px',
                  borderRadius: 10,
                  width: 200,
                  maxHeight: 300,
                  overflowY: 'scroll',
                  zIndex: 3,
                }}
                onClick={() => setactivityListView(false)}
              >
                <ActivityList
                  activityItemsArray={activityItemsArray}
                  setactivityItemSelected={setactivityItemSelected}
                  setactivityValID={setactivityValID}
                  setActivityVal={setActivityVal}
                />
              </div>
            )}
            <h4 className="people-attend-text" style={titleStyle}>
              How many people will attend?
            </h4>
            <input
              type="text"
              placeholder={`Max capacity is ${capacity}`}
              style={inputStyle}
              value={guestAmount}
              onChange={(e) => maxCapacityInput(e)}
            />

            <h4 className="alcohol-text" style={titleStyle}>
              Will alcohol be consumed?
            </h4>
            <div style={{ margin: '15px 0' }}>
              <button
                className="btn-card"
                style={{
                  margin: '0 8px',
                  backgroundColor: alcoholConsumption
                    ? 'rgb(101, 104, 230)'
                    : 'rgba(0,0,0,0)',
                  color: alcoholConsumption ? '#fff' : '#000',
                }}
                onClick={() => setAlcoholConsumption(true)}
              >
                Yes
              </button>
              <button
                className="btn-card"
                style={{
                  margin: '0 8px',
                  backgroundColor: !alcoholConsumption
                    ? 'rgb(101, 104, 230)'
                    : 'rgba(0,0,0,0)',
                  color: !alcoholConsumption ? '#fff' : '#000',
                }}
                onClick={() => setAlcoholConsumption(false)}
              >
                No
              </button>
            </div>
            <h3
              style={{
                fontWeight: 500,
                fontSize: 24,
                marginTop: 20,
                resize: 'none',
              }}
            >
              Message to the host
            </h3>
            <p style={{ color: '#000', fontSize: 16, fontWeight: 400 }}>
              Thank you for your interest! Please introduce yourself and tell
              the host about your activity and how you plan to use the space for
              your event. If your event is a non- profit/fundraiser and aligns
              with the values of the venue, feel free to ask the host about a
              discount code before checkout.
            </p>
            <textarea
              style={{
                width: 450,
                outline: '#ccc 1px solid',
                borderRadius: 8,
                height: 200,
                resize: 'none',
                textIndent: 8,
                padding: 8,
              }}
              onChange={(e) => setMsgToHost(e.target.value)}
            ></textarea>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <img src={checkIcon} alt="" />
            </div>
            <h4 style={{ fontWeight: 600, fontSize: 24 }}>
              The message has been sent successfully!
            </h4>
            <h5 style={{ color: '#656369', marginTop: 10 }}>
              The host will get back to you soon!
            </h5>
            <div style={{ marginTop: 20 }} onClick={handleCancel}>
              <Button1
                title="Back to the listing"
                bgColor="#fff"
                color="#6567e6e6"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

const inputStyle = {
  border: '1px solid #D9D9D9',
  borderRadius: 8,
  width: 443,
  height: 56,
  textIndent: 10,
  fontSize: 16,
  fontWeight: 400,
}

const btnStyleLeft = {
  border: '1px solid #D9D9D9',
  width: 100,
  height: 56,
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  color: '#656369',
  justifyContent: 'center',
  borderRadius: '8px 0 0 8px',
}
const btnStyleRight = {
  border: '1px solid #D9D9D9',
  width: 100,
  height: 56,
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  color: '#656369',
  justifyContent: 'center',
  borderRadius: '0 8px 8px 0',
}
const titleStyle = { fontSize: 18, fontWeight: 500, marginTop: 20 }

export default HostModal
