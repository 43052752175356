import React, { useState, useEffect } from 'react'
import arrowDown from '../../img/arrow_down_1.png'

let weekEnd = (day) => {
  return day === 'Sun'
}
let getCount = (givenMonth, givenYear, index) => {
  var lastDate = new Date(givenYear, givenMonth, 0)
  let isNextMonth = false
  let monthNumber = givenMonth
  let count = index
  let yearNumber = givenYear
  if (index > lastDate.getDate()) {
    monthNumber = parseInt(givenMonth) + 1
    if (givenMonth == 12) {
      monthNumber = 1
      yearNumber = givenYear + 1
    }
    count = index - lastDate.getDate()
    isNextMonth = true
    // console.log(monthNumber,yearNumber,count,isNextMonth);
  }

  let date = new Date(`${monthNumber} ${count}, ${yearNumber}`)
  let dayNumber = date.getDate()
  let dayName = date.toLocaleString('en-us', { weekday: 'short' })
  let presentMonth = date.toLocaleString('en-us', { month: 'long' })
  return {
    isNextMonth,
    dayNumber,
    dayName,
    presentMonth,
    monthNumber,
    yearNumber,
  }
}

function matchDate(date1, date2) {
  let d1 = `${date1.getMonth()}/${date1.getFullYear()}/${date1.getDate()}`
  let d2 = `${date2.getMonth()}/${date2.getFullYear()}/${date2.getDate()}`
  return d1 == d2
}

export function CalenderDay({ date, setDateRange, start, end }) {
  const [SelectedResarvation, setSelectedResarvation] = useState(null)
  const [StartDate, setStartDate] = useState(start)
  const [EndDate, setEndDate] = useState(end)
  // useEffect(() => {
  //     setStartDate(start)
  //     setEndDate(end)
  // }, [1])

  let givenMonth = date.getMonth() + 1
  let givenYear = date.getFullYear()
  let days = []
  let daySerial = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  // console.log(givenMonth,givenYear);
  daySerial.forEach((se) => {
    days.push(
      <div key={se} className={`weekday-filter`}>
        {se}
      </div>,
    )
  })
  let givenDate = new Date(`${givenMonth} 1, ${givenYear}`)
  let skip =
    daySerial.indexOf(givenDate.toLocaleString('en-us', { weekday: 'short' })) -
    1

  let selectData = (dayNumber, presentMonth, monthNumber, yearNumber) => {
    let date = new Date(`${monthNumber} ${dayNumber}, ${yearNumber}`)
    if (StartDate && EndDate) {
      if (!matchDate(StartDate, EndDate)) {
        if (matchDate(date, StartDate)) {
          setStartDate(EndDate)
          setDateRange({ start: EndDate, end: EndDate })
          return null
        }

        if (matchDate(date, EndDate)) {
          setEndDate(StartDate)
          setDateRange({ start: StartDate, end: StartDate })
          return null
        }
      }

      if (StartDate > date) {
        setStartDate(date)
        setDateRange({ start: date, end: EndDate })
        return null
      }

      setEndDate(date)
      setDateRange({ start: StartDate, end: date })
    }

    if (StartDate == null) {
      setStartDate(date)
      setDateRange({ start: date, end: EndDate })
      if (date > EndDate) {
        setEndDate(date)
        setStartDate(EndDate)
        setDateRange({ start: EndDate, end: date })
      }
      return null
    }
    if (EndDate == null) {
      setEndDate(date)
      setDateRange({ start: StartDate, end: date })
      return null
    }
  }

  for (let index = skip; index >= 0; index--) {
    var d = new Date(givenYear, givenMonth - 1, 0)
    days.push(
      <div key={index + 'asdas'} className={`date-filter next-month-filter`}>
        {' '}
        {d.getDate() - index}{' '}
      </div>,
    )
  }

  for (let index = 1; index < 42 - skip; index++) {
    let {
      isNextMonth,
      dayNumber,
      dayName,
      presentMonth,
      monthNumber,
      yearNumber,
    } = getCount(givenMonth, givenYear, index)
    let date = new Date(`${monthNumber} ${dayNumber}, ${yearNumber}`)

    let color = 'white'

    let inRange = false
    let start = false
    let inside = false
    let end = false

    let inRangeCss = {}

    if (!isNextMonth) {
      if (EndDate && StartDate) {
        if (date > StartDate && date < EndDate) {
          inRange = true
          inRangeCss = {
            borderTop: '0.5px dashed gray',
            borderBottom: '0.5px dashed gray',
            background: 'lightgray',
          }
        }
      }
      if (StartDate != null && matchDate(date, StartDate)) {
        inRange = true
        inRangeCss = {
          borderRight: EndDate == null ? '1px dashed gray' : 'none',
          borderLeft: '0.5px dashed gray',
          borderTop: '0.5px dashed gray',
          borderBottom: '0.5px dashed gray',
          background: '#2593fc',
          color: 'white',
        }
      }

      if (EndDate != null && matchDate(date, EndDate)) {
        inRange = true
        inRangeCss = {
          borderLeft: StartDate == null ? '1px dashed gray' : 'none',
          borderRight: '0.5px dashed gray',
          borderTop: '0.5px dashed gray',
          borderBottom: '0.5px dashed gray',
          background: '#2593fc',
          color: 'white',
        }
      }
    }

    days.push(
      <div
        style={inRange ? inRangeCss : null}
        key={index}
        onClick={() => {
          if (!isNextMonth)
            selectData(dayNumber, presentMonth, monthNumber, yearNumber)
        }}
        className={`date-filter ${weekEnd(dayName) ? 'weekEnd-filter' : ''} ${
          isNextMonth ? 'next-month-filter' : ''
        }`}
      >
        {dayNumber}
      </div>,
    )
  }

  return <div className="calender-date-filter">{days.map((day) => day)}</div>
}

export function CalenderDayRangFilter({
  setShowMonthFilter,
  showMonthFilter,
  selectedMonth,
  SelectedYear,
  setSelectedYear,
  setSelectedMonth,
  setDateRange,
}) {
  const [dateObjact, setdateObjact] = useState(null)
  const [day, setday] = useState(null)

  let startDateObject = new Date(
    `${selectedMonth.monthNumber} 1, ${SelectedYear}`,
  )
  let endDateObject = new Date(
    `${selectedMonth.monthNumber} 1, ${SelectedYear}`,
  )
  endDateObject.setDate(endDateObject.getDate() + 6)
  const [dateRangeForFilter, setDateRangeForFilter] = useState({
    start: startDateObject,
    end: endDateObject,
  })

  useEffect(() => {
    let DateObject = new Date(`${selectedMonth.monthNumber} 1, ${SelectedYear}`)
    setdateObjact(DateObject)
    setday(DateObject.toLocaleString('en-us', { weekday: 'long' }))
  }, [2])

  let showDateRange = (date) => {
    setDateRangeForFilter(date)
    setDateRange(date)
  }
  let selectMonth = (monthNumber) => {
    let DateObject = new Date(`${monthNumber} 1, ${SelectedYear}`)
    setSelectedMonth({
      monthNumber: monthNumber,
      monthName: DateObject.toLocaleString('en-us', { month: 'long' }),
    })
  }

  let goToNextMonth = () => {
    let date = selectedMonth.monthNumber + 1
    if (date <= 12) {
      selectMonth(date)
      let DateObject = new Date(`${date} 1, ${SelectedYear}`)
      setdateObjact(DateObject)
    }
  }

  let goToPrevioustMonth = () => {
    let date = selectedMonth.monthNumber - 1
    if (date > 0) {
      selectMonth(date)
      let DateObject = new Date(`${date} 1, ${SelectedYear}`)
      setdateObjact(DateObject)
    }
  }
  var lastday = function (y, m) {
    return new Date(y, m + 1, 0).getDate()
  }

  let changeSlot = (increment = true) => {
    let diffrence = null
    let startDateObject = null
    let endDateObject = null
    let start_lastdate = lastday(
      dateRangeForFilter.start.getFullYear(),
      dateRangeForFilter.start.getMonth(),
    )
    let end_lastdate = lastday(
      dateRangeForFilter.end.getFullYear(),
      dateRangeForFilter.end.getMonth(),
    )

    console.log(
      'Start :',
      dateRangeForFilter.start.getMonth(),
      dateRangeForFilter.start.getDate(),
    )
    console.log(
      'End :',
      dateRangeForFilter.end.getMonth(),
      dateRangeForFilter.end.getDate(),
    )

    diffrence =
      dateRangeForFilter.end.getDate() - dateRangeForFilter.start.getDate()
    startDateObject = new Date(
      `${
        parseInt(dateRangeForFilter.start.getMonth()) + 1
      } ${dateRangeForFilter.start.getDate()}, ${dateRangeForFilter.start.getFullYear()}`,
    )
    endDateObject = new Date(
      `${
        parseInt(dateRangeForFilter.end.getMonth()) + 1
      } ${dateRangeForFilter.end.getDate()}, ${dateRangeForFilter.end.getFullYear()}`,
    )

    if (startDateObject != null && endDateObject != null) {
      if (increment) {
        startDateObject.setDate(startDateObject.getDate() + diffrence)
        endDateObject.setDate(endDateObject.getDate() + diffrence)
      } else {
        startDateObject.setDate(startDateObject.getDate() - diffrence)
        endDateObject.setDate(endDateObject.getDate() - diffrence)
      }

      showDateRange({
        start: startDateObject,
        end: endDateObject,
      })
    }
  }

  return (
    <div className="calender-filter calender-filter-grid">
      <div className="selected-month-year">
        <img
          onClick={() => {
            changeSlot(false)
          }}
          className="arrow decriment-range-filter leftArrow"
          src={arrowDown}
          alt=""
          srcSet=""
        />
        <p
          onClick={() => {
            setShowMonthFilter(!showMonthFilter)
          }}
        >
          {day && day},{selectedMonth.monthName}{' '}
          {dateRangeForFilter.start && dateRangeForFilter.start.getDate()} -{' '}
          {dateRangeForFilter.end && dateRangeForFilter.end.getDate()}{' '}
          {SelectedYear}
        </p>
        <img
          onClick={() => {
            changeSlot(true)
          }}
          className="arrow incriment-range-filter rightArrow"
          src={arrowDown}
          alt=""
          srcSet=""
        />
      </div>
      <div
        className="calender-filter-element"
        style={{ display: showMonthFilter ? 'block' : 'none' }}
      >
        <div className="year-select">
          <div>
            <img
              className="year-decrement arrow"
              src={arrowDown}
              alt=""
              srcSet=""
              onClick={goToPrevioustMonth}
            />{' '}
          </div>
          <div> {selectedMonth.monthName}</div>
          <div>
            {' '}
            <img
              onClick={goToNextMonth}
              className="year-increment arrow"
              src={arrowDown}
              alt=""
              srcSet=""
            />{' '}
          </div>
        </div>
        <div className="month-select-filter">
          {dateObjact && (
            <CalenderDay
              date={dateObjact}
              setDateRange={showDateRange}
              start={dateRangeForFilter.start}
              end={dateRangeForFilter.end}
            />
          )}
        </div>
      </div>
    </div>
  )
}
