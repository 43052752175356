import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import poligondown from './../../img/polygon_2.png'
import poligonup from './../../img/polygon_3.png'
import { getAllInputValue, titleCase } from '../../redux/requests'
import {
  createSpace,
  updateSpace,
  setSpace,
  selectSpace,
} from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

const formRef = createRef()
export default function SetUpInformationInsert() {
  const banner = useSelector((state) => state.component.banner)
  const parentSpace = useSelector((state) => state.space.main_space)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [maxNumberOfGuest, setMaxNumberOfGuest] = useState(0)
  const [guestAgeLimit, setGuestAgeLimit] = useState(0)
  const { id } = useParams()
  const [spaceCategory, setSpaceCategory] = useState([
    'Sanctuary',
    'Classroom',
    'Chapel',
    'Fellowship Hall',
    'Community room',
    'Parlor',
    'Social Hall',
    'Conference Room',
    'Garden',
    'Parish Hall',
    'Music Room',
    'Roof',
    'Studio',
    'Office Space',
    'Gymnasium',
    'Gallery',
    'Multi purpose Room',
    'Lounge',
  ])
  const [showAgeLimitDropDownBox, setShowAgeLimitDropDownBox] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  let handleChaneShowAgeLimit = () => {
    setShowAgeLimitDropDownBox(!showAgeLimitDropDownBox)
  }
  useEffect(() => {
    if (loading) {
      fatchSpace()
      dispatch(
        setBanner({
          header: 'Your Space: Listing Setup',
          discription:
            'Provide more specific information about your space type and other options',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100 / 7) * 1,
          },
          pages: {
            visible: true,
            total_page: 1,
            page_number: 6,
          },
          callBack: () => {
            // formRef.current.elements.phone.value = '+1'
            // setLoading(false)
          },
        }),
      )
    }
  }, [id])
  let place_category = [
    'Sanctuary',
    'Classroom',
    'Chapel',
    'Fellowship Hall',
    'Community room',
    'Parlor',
    'Social Hall',
    'Conference Room',
    'Garden',
    'Parish Hall',
    'Music Room',
    'Roof',
    'Studio',
    'Office Space',
    'Gymnasium',
    'Gallery',
    'Multi purpose Room',
    'Lounge',
  ]

  let formValidation = (form) => {
    if (form.number_of_guest > 15000 || form.number_of_guest < 1) {
      NotificationManager.warning(
        `Number of Guest must be between 1 - 15,000`,
        'Oops!',
        3000,
      )
      return false
    }

    if (form.manager_name.length < 1) {
      NotificationManager.warning(
        `person’s name of contact is required`,
        'Oops !',
        3000,
      )
      return false
    }

    if (form.email.length < 1) {
      NotificationManager.warning(`email is required`, 'Oops !', 3000)
      return false
    }

    if (form.phone.length < 10) {
      NotificationManager.warning(
        `valid US telephone number is required`,
        'Oops !',
        3000,
      )
      return false
    }

    if (form.phone.startsWith("+1")) {
      NotificationManager.warning(
        `please exclude the country code`,
        'Oops !',
        3000,
      )
      return false
    }

    if (form.place_category.length < 1) {
      NotificationManager.warning(
        `place category is required`,
        'Oops !',
        3000,
      )
      return false
    }

    let phone = form.phone
      .replaceAll('+', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('{', '')
      .replaceAll('}', '')
      .replaceAll('[', '')
      .replaceAll(']', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
    let phoneno = /^\d{10}$/
    let is_validate_phone = phone.match(phoneno)

    if (!is_validate_phone) {
      NotificationManager.warning(
        `all telephone number should be valid us number`,
        'Basic Information',
        3000,
      )
      return false
    }

    return true
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let values = getAllInputValue(e, [
      "place_category",
      "number_of_guest",
      "age",
      "email",
      "phone",
      "manager_name",
      "max_consecutive_booking",
    ])

    let formDate = {
      place_category: values.place_category,
      number_of_guest: maxNumberOfGuest,
      age: guestAgeLimit,
      email: values.email,
      phone: values.phone,
      manager_name: values.manager_name,
      max_consecutive_booking: values.max_consecutive_booking,
    }
    let is_validated = formValidation(formDate)

    if (is_validated) {
      setFormLoading(true) // to show the spinner for now
      if (!id) {
        dispatch(
          createSpace({
            ...formDate,
            callBack: (response) => {
              if (response.status == 201) {
                NotificationManager.success(
                  `Successfully ${response.statusText}`,
                  'Space Information',
                  700,
                )
                navigate(`/space/add/${response.data.space.id}/about`)
              }
              setFormLoading(false)
            },
          }),
        )
      }

      if (id) {
        dispatch(
          updateSpace({
            setup: { ...formDate },
            id: id,
            callBack: (response) => {
              if (response.status == 200) {
                NotificationManager.success(
                  `Successfully updated`,
                  'Space Information',
                  700,
                )
                navigate(`/space/add/${response.data.space.id}/about`)
              }
              setFormLoading(false)
            },
          }),
        )
      }
    }
  }

  let fatchSpace = () => {
    setSpaceCategory([...place_category])
    if (id != undefined) {
      dispatch(
        selectSpace({
          id: id,
          callBack: (response) => {
            let space = response.data.setup
            if (
              !spaceCategory.includes(
                titleCase(space.place_category.replaceAll('_', ' ')),
              )
            ) {
              setSpaceCategory([
                ...place_category,
                space.place_category.replaceAll('_', ' '),
              ])
            }
            if (space.manager_name) {
              formRef.current.elements.manager_name.value = space.manager_name
            }
            if (space.email) {
              formRef.current.elements.email.value = space.email
            }

            if (space.phone) {
              formRef.current.elements.phone.value = space.phone
            }

            if (space.place_category) {
              formRef.current.elements.place_category.value =
                space.place_category
            }

            if (space.max_consecutive_booking) {
              formRef.current.elements.max_consecutive_booking.value =
                space.max_consecutive_booking
            }

            if (space.number_of_guest) {
              formRef.current.elements.number_of_guest.value =
                space.number_of_guest
              setMaxNumberOfGuest(space.number_of_guest)
            }

            if (space.age) {
              formRef.current.elements.age.value = space.age
              setGuestAgeLimit(space.age)
            }
          },
        }),
      )
    }
  }

  let handleAddCustomSpaceType = () => {
    if (formRef.current.elements['custom-space-name'].value.length > 0) {
      let word = titleCase(
        formRef.current.elements['custom-space-name'].value.trim(),
      )
      // console.log(word);
      if (!spaceCategory.includes(word)) {
        setSpaceCategory([
          ...spaceCategory,
          titleCase(formRef.current.elements['custom-space-name'].value),
        ])
      }
    }
  }

  let deleteCutomPlaceCategory = (index) => {
    let new_list = [...spaceCategory]
    new_list.splice(index, 1)
    setSpaceCategory([...new_list])
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="space-setup-inserte-form"
      >
        <div className="radio-button-group">
          <h1 className="radio-button-group-input-lable">
            What type of space are you listing?
          </h1>
          <p className="radio-button-group-input-lable-description">
            Pick the type of space that most closely represents the physical
            space being listed
          </p>
          <div className="radion-buttons">
            {spaceCategory.map((cat, index) => {
              return (
                <div className="radio-button">
                  <input
                    type="radio"
                    name={'place_category'}
                    id={cat.replace(' ', '_').toLowerCase()}
                    value={cat.replace(' ', '_').toLowerCase()}
                  />
                  {/* <span class="checkmark"></span> */}
                  <label htmlFor={cat.replace(' ', '_').toLowerCase()}>
                    {cat}
                  </label>
                  {!place_category.includes(cat) ? (
                    <span
                      onClick={() => {
                        deleteCutomPlaceCategory(index)
                      }}
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginLeft: '25px',
                        fontSize: '20px',
                      }}
                    >
                      {' '}
                      x{' '}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
          <div className="add-space-category-button-group">
            <input type="text" name="custom-space-name" />
            <button type="button" onClick={handleAddCustomSpaceType}>
              {' '}
              add{' '}
            </button>
          </div>
        </div>
        {/* max and min number of guest */}
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="number-of-guest">
          <h1>
            What’s the maximum number of guests you would like to welcome to this space?
          </h1>
          <div className="number-of-guest-input-fielt-group">
            <label htmlFor="number_of_guest">Maximum Guests</label>
            {/* <img src={poligonup} onClick={()=>{setMaxNumberOfGuest( parseInt(maxNumberOfGuest)+1)}}  className="poligon poligon-up" alt="" srcSet="" /> */}
            {/* <img src={poligondown} onClick={()=>{ if(maxNumberOfGuest > 0){setMaxNumberOfGuest(parseInt(maxNumberOfGuest)-1)}}} className="poligon poligon-down" alt="" srcSet="" /> */}
            <input
              value={maxNumberOfGuest}
              onChange={(e) => {
                setMaxNumberOfGuest(e.target.value)
              }}
              type="number"
              id="number_of_guest"
              name="number_of_guest"
            />
          </div>
        </div>
        {/* max and min age limit of guest */}
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="allowed-age-guest">
          <h1>What’s the age requirements for this space?</h1>
          <p>Typically, only spaces that serve alcohol have age requirements</p>
          <button
            type="button"
            onFocusCapture={() => {
              setShowAgeLimitDropDownBox(true)
            }}
            onBlurCapture={() => {
              setShowAgeLimitDropDownBox(false)
            }}
            className="allowed-age-input-fielt-group"
          >
            <img
              src={poligondown}
              className="allowed-age-input-fielt-group-drop-down-poligon"
              alt=""
              srcSet=""
            />
            <input
              value={guestAgeLimit == 0 ? 'All Ages' : `${guestAgeLimit}+`}
              disabled
              type="text"
              id="allowed_age"
              name="allowed_age"
            />
            <div
              className={`allowed-age-input-fielt-group-drop-down-box ${!showAgeLimitDropDownBox ? 'hide-elements' : ''
                }`}
            >
              <ul>
                <li>
                  <input
                    style={{ display: 'none' }}
                    onChange={() => {
                      setGuestAgeLimit(0)
                    }}
                    type="radio"
                    id="0"
                    name="age"
                    value="0"
                  />
                  <label htmlFor="0">
                    <div className="radio-box-dummy">
                      {' '}
                      <div
                        style={{
                          display: guestAgeLimit == 0 ? 'block' : 'none',
                        }}
                      ></div>{' '}
                    </div>
                    All Ages
                  </label>
                </li>
                <li>
                  <input
                    style={{ display: 'none' }}
                    onChange={() => {
                      setGuestAgeLimit(16)
                    }}
                    type="radio"
                    id="16"
                    name="age"
                    value="16"
                  />
                  <label htmlFor="16">
                    <div className="radio-box-dummy">
                      {' '}
                      <div
                        style={{
                          display: guestAgeLimit == 16 ? 'block' : 'none',
                        }}
                      ></div>{' '}
                    </div>
                    16+
                  </label>
                </li>
                <li>
                  <input
                    style={{ display: 'none' }}
                    onChange={() => {
                      setGuestAgeLimit(18)
                    }}
                    type="radio"
                    id="18"
                    name="age"
                    value="18"
                  />
                  <label htmlFor="18">
                    <div className="radio-box-dummy">
                      {' '}
                      <div
                        style={{
                          display: guestAgeLimit == 18 ? 'block' : 'none',
                        }}
                      ></div>{' '}
                    </div>
                    18+
                  </label>
                </li>
                <li>
                  <input
                    style={{ display: 'none' }}
                    onChange={() => {
                      setGuestAgeLimit(21)
                    }}
                    type="radio"
                    id="21"
                    name="age"
                    value="21"
                  />
                  <label htmlFor="21">
                    <div className="radio-box-dummy">
                      {' '}
                      <div
                        style={{
                          display: guestAgeLimit == 21 ? 'block' : 'none',
                        }}
                      ></div>{' '}
                    </div>
                    21+
                  </label>
                </li>
              </ul>
            </div>
          </button>
        </div>
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="allowed-age-guest">
          <h1 style={{ width: '100%' }}>
            What’s the maximum number of consecutive days a guest can book for
            this space?
          </h1>
          <p style={{ width: '85%' }}>
            Guests will not be able to book in a single reservation request more
            days than the number you have provided here.
          </p>
          <div className="max-booking-input-group">
            <label htmlFor="max_consecutive_booking">
              Maximum Days Per Booking
            </label>
            <input
              min={1}
              placeholder="Enter maximum days per booking"
              type="number"
              name="max_consecutive_booking"
              id="max_consecutive_booking"
              defaultValue={10}
            />
          </div>
        </div>
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="point-of-contact">
          <div className="point-of-contact-field-group">
            <label htmlFor="manager_name">
              Provide the point of contact’s name for this space *{' '}
            </label>
            <br />
            <input id="manager_name" name="manager_name" />
          </div>
          <div className="point-of-contact-field-group">
            <label htmlFor="phone">Point of Contact Telephone * </label>
            <div className="note">Make sure this information is correct.</div>
            <div style={{ position: 'relative' }}>
              <input
                placeholder=" XXX-XXX-XXXX"
                type="numberic"
                id="phone"
                name="phone"
                style={{ paddingLeft: 35 }}
              />
              <span style={{ position: 'absolute', left: 5, top: 28 }}>
                (+1)
              </span>
            </div>
          </div>
          <div className="point-of-contact-field-group">
            <label htmlFor="email">Point of Contact Email * </label>
            <div className="note">Make sure this information is correct.</div>
            <input type="email" id="email" name="email" />
          </div>
        </div>
        {!formLoading ? (
          <div className="basic-information-input-group">
            <button type="submit"> Save & Continue</button>
          </div>
        ) : (
          <div>
            <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
          </div>
        )}
      </form>
      <NotificationContainer />
    </div>
  )
}
