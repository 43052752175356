import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
// import { createStore } from 'redux';
import { Provider } from 'react-redux'
// import rootReducer from './redux/reducers';
import { ErrorBoundary } from 'react-error-boundary'

import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'

import Dashboard from './pages/Dashboard'
import Master from './layouts/Master'
import ErrorFallback from './components/fallback/ErrorFallBack'
import DashboardLayOut from './layouts/DashboardLayOut'
import Login from './pages/Login'
import Auth from './auth/Auth'
import store from './redux/store'
import './App.css'

import YourCalendarLayOut from './layouts/YourCalendarLayOut'
import YourSpaceLayOut from './layouts/YourSpaceLayOut'
import SpaceList from './pages/Space/SpaceList'
import AddSpace from './pages/Space/AddSpace'
import AboutSpaceInsert from './pages/Space/AboutSpaceInsert'
import ActivitiesOfSpaceInsert from './pages/Space/ActivitiesOfSpaceInsert'
import BasicInformationInsert from './pages/Space/BasicInformationInsert'
import OptOutOfSpaceInsert from './pages/Space/OptOutOfSpaceInsert'
import PhotoOfSpaceInsert from './pages/Space/PhotoOfSpaceInsert'
import PolicyAndRulsOfSpaceInsert from './pages/Space/PolicyAndRulsOfSpaceInsert'
import SetUpInformationInsert from './pages/Space/SetUpInformationInsert'

import Profile from './pages/Profile'
import Calendar from './pages/Calendar'
import BasicLocationInsert from './pages/Space/BasicLocationInsert'
import AllReservations from './pages/Resarvation/AllReservations'
import PendingRequest from './pages/Resarvation/PendingRequest'
import AcceptedBookings from './pages/Resarvation/AcceptedBookings'
import CheckingInSoon from './pages/Resarvation/CheckingInSoon'
import AvailabilityOfSpaceInsert from './pages/Space/AvailabilityOfSpaceInsert'
import HouseRulesOfSpaceInsert from './pages/Space/HouseRulesOfSpaceInsert'
import Covid19PolicyOfSpaceInsert from './pages/Space/Covid19PolicyOfSpaceInsert'
import GuestRequirementOfSpaceInsert from './pages/Space/GuestRequirementOfSpaceInsert'
import LawsAndRegulationsOfSpaceInsert from './pages/Space/LawsAndRegulationsOfSpaceInsert'
import Amenities from './pages/Space/Amenities'
import PaymentLayOut from './layouts/PaymentLayOut'
import Income from './pages/Payment/Revenue/Income'
import TransactionHistory from './pages/Payment/Revenue/TransactionHistory'
import PayoutDetails from './pages/Payment/Banking/PayoutDetails'
import DiscountCoupon from './pages/Payment/Banking/DiscountCoupon'
import AddPaymentLayOut from './layouts/AddPaymentLayOut'
import AddPaymentDetails from './pages/Payment/Banking/AddPaymentDetails'
import BecomeHost from './pages/BecomeHost'
import SearchResult from './pages/SearchResult'
import InSearchResult from './pages/InSearchResult/InSearchResult'
import BookingPages from './pages/BookingPages'
import Mybookings from './pages/Mybookings'
import MybookingsReview from './pages/MybookingsReview/MybookingsReview'
import RenterProfile from './pages/RenterProfile'
import HostProfile from './pages/HostProfile/HostProfile'
import HostProfilePublic from './pages/HostProfile/HostProfilePublic'
import EditHostProfile from './pages/HostProfile/EditHostProfile'
import AddBankAccrountDetails from './pages/Payment/Banking/AddBankAccrountDetails'
import InquiryConversation from './pages/HostInquiry/InquiryConversation'
import InquiryList from './pages/HostInquiry/InquiryList'
import VerifyEmail from './pages/EmailVerification/VerifyEmail'
import CanceledBookings from './pages/Resarvation/CanceledBookings'
import Messages from './pages/Messages'
import InboxChat from './pages/InboxChat'
import PaymentPage from './pages/PaymentPage'
import UnsubscribeEmail from './pages/UnsubscribeEmail'
import Faq from './pages/faq/Faq'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfUse from './pages/TermsOfUse'
import ResetPassword from './pages/ResetPassword'
import NotSupportedOverlay from './components/NotSupportedOverlay'
import * as Sentry from '@sentry/react'
import Hotjar from '@hotjar/browser'
import { BrowserTracing } from '@sentry/tracing'
import RenterLanding from './pages/RenterLanding'
import ReactGA from 'react-ga4'
ReactGA.initialize('G-26FETV7T1Y')

// import 'bootstrap/dist/css/bootstrap.min.css';

// const store = createStore(rootReducer);
// const auth = false

// Because if I init Sentry on local development, all logs comes from instrument.js
if (process.env.REACT_APP_LOCAL_ENV !== 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const siteId = process.env.REACT_APP_HOTJAR_SITEID
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotSupportedOverlay
        text="Venuely is currently not supported on mobile devices"
        subtext="Please try accessing venuely from your computer"
      />
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="profile/:id" element={<HostProfilePublic />} />
            <Route
              path="/reset-password/:email/:type/:token"
              element={<ResetPassword />}
            />
            <Route path="unsubscribe/:token" element={<UnsubscribeEmail />} />
            <Route path="faq" element={<Faq />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-use" element={<TermsOfUse />} />
            <Route path="verify" element={<VerifyEmail />} />
            <Route index path="/" element={<RenterLanding />} />
            <Route index path="/become-host" element={<BecomeHost />} />
            <Route path="renter">
              <Route path="search" element={<SearchResult />} />
              <Route path="messages" element={<Messages />} />
              <Route path="messages/:id" element={<InboxChat />} />
              <Route path="payment" element={<PaymentPage />} />
              <Route
                path="place/:id/:startTime/:endTime/:dateVal/:dateVal2"
                element={<InSearchResult />}
              />
              <Route path="profile" element={<RenterProfile />} />
              <Route path="booking" element={<BookingPages />} />
              <Route path="mybookings" element={<Mybookings />} />
              <Route path="review/:id" element={<MybookingsReview />} />
            </Route>
            {/* <Route path="profile/:id" element={<HostProfilePublic />} /> */}

            <Route path="/" element={<Auth children={<Master />} />}>
              <Route path="messages">
                <Route index element={<InquiryList />} />
                <Route path=":type" element={<InquiryList />} />
                <Route path="history/:id" element={<InquiryConversation />} />
              </Route>

              <Route path="profile" element={<HostProfile />} />

              {/* <Route path="profile/:id" element={<HostProfilePublic />} /> */}
              <Route path="profile-edit" element={<EditHostProfile />} />

              <Route element={<DashboardLayOut />}>
                <Route path="canceled-request" element={<CanceledBookings />} />
                <Route path="pending-request" element={<PendingRequest />} />
                <Route path="past-reservation" element={<AcceptedBookings />} />
                <Route path="upcoming-events" element={<CheckingInSoon />} />
                <Route path="all-reservations" element={<AllReservations />} />
              </Route>

              <Route path="space" element={<YourSpaceLayOut />}>
                <Route index element={<SpaceList />} />
                <Route path="add" element={<SpaceList />} />
                <Route path="add/:id/about" element={<AboutSpaceInsert />} />
                <Route
                  path="add/:id/availability"
                  element={<AvailabilityOfSpaceInsert />}
                />
                <Route
                  path="add/:id/activities"
                  element={<ActivitiesOfSpaceInsert />}
                />
                <Route path="add/:id/amenities" element={<Amenities />} />
                <Route
                  path="add/:id/optout"
                  element={<OptOutOfSpaceInsert />}
                />
                <Route path="add/:id/photos" element={<PhotoOfSpaceInsert />} />
                <Route
                  path="add/:id/setup-information"
                  element={<SetUpInformationInsert />}
                />
                <Route path="add/new" element={<SetUpInformationInsert />} />
                <Route path="add/:id" element={<SetUpInformationInsert />} />

                <Route path="policy-and-ruls">
                  <Route index element={<PolicyAndRulsOfSpaceInsert />} />
                  <Route
                    path="basic-policies"
                    element={<PolicyAndRulsOfSpaceInsert />}
                  />
                  <Route
                    path="house-rules"
                    element={<HouseRulesOfSpaceInsert />}
                  />
                  <Route
                    path="covid-19-policy"
                    element={<Covid19PolicyOfSpaceInsert />}
                  />
                  <Route
                    path="guest-requirement"
                    element={<GuestRequirementOfSpaceInsert />}
                  />
                  {/* <Route path="laws-and-regulations" element={<LawsAndRegulationsOfSpaceInsert />} /> */}
                </Route>

                <Route path="basic">
                  <Route
                    path="information"
                    element={<BasicInformationInsert />}
                  />
                  <Route path="location" element={<BasicLocationInsert />} />
                </Route>
              </Route>

              <Route path="calendar" element={<YourCalendarLayOut />}>
                <Route index element={<Calendar />} />
                <Route path="overview" element={<Calendar />} />
                <Route path="sync-settings" element={<Calendar />} />
              </Route>

              <Route path="payment" element={<PaymentLayOut />}>
                <Route path="revenue" element={<Income />} />
                <Route path="revenue/income" element={<Income />} />
                <Route
                  path="revenue/transaction-history"
                  element={<TransactionHistory />}
                />
                <Route path="banking" element={<PayoutDetails />} />
                <Route
                  path="banking/payout-details"
                  element={<PayoutDetails />}
                />
                <Route path="banking/discount" element={<DiscountCoupon />} />
              </Route>
              <Route element={<AddPaymentLayOut />}>
                <Route
                  path="payment/banking/add/bank-account-details"
                  element={<AddBankAccrountDetails />}
                />
                <Route
                  path="payment/banking/add/payout-details"
                  element={<AddPaymentDetails />}
                />
              </Route>

              <Route path="profile" element={<Profile />} />
            </Route>
            <Route> 404 Not Found </Route>
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
