import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import pentagon from './../../../img/pentagon.png'
import dots from './../../../img/dot.png'

import stripeImage from './../../../img/strip.png'
import bankImage from './../../../img/bank.png'
import { setInformation } from '../../../redux/actions/InformationAction'
import { useSearchParams } from 'react-router-dom'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function DiscountCoupon() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [haveAddedBank, sethaveAddedBank] = useState(false)
  const [bankInfo, setBankInfo] = useState(null)
  const details = useSelector((state) => state.information)
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (loading) {
      setStripeButtonIsLoading(true)
      if (searchParams.get('success')) {
        NotificationManager.success(
          `Successful`,
          'banl account added successfully',
          700,
        )
      }
      dispatch(
        setBanner({
          header: 'Discount Code',
          discription: 'Create your discount code and request to our team',
          button: {
            // visible: details.details_submitted
            //   ? !details.details_submitted
            //   : true,
            visible: false, // hide always for now
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            console.log(details)
            setStripeButtonIsLoading(false)
            // dispatch( setInformation( {
            //   callBack:(response)=>{
            //     sethaveAddedBank(response.data.payment_information.length > 0);
            //     if(response.data.payment_information.length > 0){
            //       setBankInfo({
            //         "charges_enabled":response.data.charges_enabled,
            //         "details_submitted":response.data.charges_enabled,
            //         "payouts_enabled":response.data.charges_enabled,
            //         "link":response.data.link,
            //       });
            //     }
            //   }
            // }))

            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  const [stripeButtonIsLoading, setStripeButtonIsLoading] = useState(false)
  let got_to_stripe = () => {
    setStripeButtonIsLoading(true)
    dispatch(
      setInformation({
        callBack: (response) => {
          window.open(response.data.link, '_blank')
          setStripeButtonIsLoading(false)
        },
      }),
    )
  }

  return (
    <div style={{ padding: 50, paddingRight: 200 }} className='discountCoupon'>
      <NotificationContainer />
      <p>
        In some cases, host may want to issue custom discount codes to renters
        and guests - perhaps to extend discounts for booking multiple hours
        and/or days for their events, to honor long-standing partnerships with
        special rates, or to offer discounts for small collectives of
        non-profits that could use some in-kind donation of space!
      </p>
      <p>
        Currently, this feature is managed by Venuely. In order to provide a
        discount code to a prospective guest, hosts must request a discount code
        from the Venuely administrators. Please note that percentages are the
        only current form for discounts.
      </p>
      <h1>To request a code</h1>
      <p>
        Email{' '}
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          hello@venuely.org
        </span>{' '}
        to request a discount code. We will need the following information to
        issue the code:
      </p>
      <div style={{ paddingLeft: 20 }}>
        <p style={{ fontWeight: 'bold' }}>
          1) The percentage forthe discount (1-100%)
        </p>
        <p>
          <span style={{ fontWeight: 'bold' }}>
            2) The code name to assign to the discount.
          </span>{' '}
          <i>*You may use any combination of letters, numbers, and symbols. </i>{' '}
          <br />
          We recommend keeping it short and easy to remember! Also make it
          relevant to your specific space. To minimize site errors, use
          underscores or dashes between words, and avoid using spaces.
        </p>
      </div>
      <p>
        For example, you might assign a more generic "10PERCENT" code name for a
        10% discount, or you can code it by the name of the guest, e.g.
        "DANCE-GROUP_30" or "SAMPLE-CHILDRENS-THEATER"
      </p>
      <p>Please alow 1-2 business days for turnaround on custom codes.</p>
      <h1>To issue the code</h1>
      <p>
        Simply share the code with your guest through the messaging system in
        your Venuely host portal.
      </p>
      <p>
        Guests can use the code when intially booking the reservation, after
        pressing the "Start Booking" button on the space listing page.
      </p>
      <p>
        On the right side of the checkout details, the guest will find a "Add
        Coupon Code" link underneath the price detail summary. By clicking that
        link, they will be able to enter the code you provided and apply it to
        the total.
      </p>

      <h1>Discount Code FAQ</h1>
      <p>
        <b>
          Is there a way to apply the coupon/discount after the booking has been
          requested?
        </b>
      </p>
      <p>
        Guests can only apply the cods at the initial booking stage - so guests
        must re-request the booking if a coupon code is issued for use after the
        initial request.
      </p>
      <p>
        If the booking was approved and then the discount was issued - hosts can
        issue a refund, after which point the guest can re-request the booking.
      </p>
    </div>
  )
}
