import React, { useRef, useState } from 'react'
import { Radio } from 'antd'
import styles from './styles/PriceMenu.module.css'

const PriceMenu = (props) => {
  const {
    priceVal,
    setPriceVal,
    setPricePopup,
    exactPriceVal,
    setExactPriceVal,
  } = props
  const exactInputRef = useRef(null)

  const [items] = useState(['$0-$100', '$100-$250', '$250-$500', 'Enter Range'])

  const onChange = (e) => {
    if (e.target.value === 'Enter Range') {
      exactInputRef.current.focus()
    }
    setPriceVal(e.target.value)
  }
  const selectedItem = () => {
    document.getElementById('search__btn').click()
    setPricePopup(false)
  }
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className={styles.price__parent} style={{
          visibility: props.pricePopup ? 'visible' : "hidden",
          opacity: props.pricePopup ? 1 : 0
        }}>
          <div
            className="menu"
            style={{
              padding: 20,
              width: 250,
              position: 'absolute',
              top: '-2rem',
              left: '15rem',
              height: 300
            }}
          >
            <div className={styles.price__parent}>
              <h5 style={{ fontWeight: '600', fontSize: 14 }}>Price</h5>
              <h5 className={styles.clear_text} onClick={() => setPriceVal('$0-$500')}>Clear</h5>
            </div>
            {items.map((item, idx) => {
              return (
                <>
                  <div key={idx} style={{ margin: '10px 0px' }}>
                    <Radio.Group onChange={onChange} value={priceVal}>
                      <Radio value={item}>{item}</Radio>
                    </Radio.Group>
                  </div>
                </>
              )
            })}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
              <input
                type="text"
                value={exactPriceVal}
                onChange={(e) => setExactPriceVal(e.target.value)}
                onClick={() => setPriceVal('Enter Range')}
                ref={exactInputRef}
                placeholder="e.g. 1-200"
                style={{
                  borderRadius: 4,
                  padding: 5,
                  border: '#aaa 1px solid',
                  width: 120,
                }}
              />
              {/* <input
            type="text"
            placeholder="$ Max"
            style={{
              borderRadius: 4,
              padding: 5,
              border: '#aaa 1px solid',
              width: 80,
              margin: 4,
            }}
          /> */}
            </div>
            <button className={styles.done_btn} onClick={selectedItem}>
              <span>
                Done
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceMenu
