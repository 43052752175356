import React, { useState, useEffect } from 'react'
import { Modal, Button, message } from 'antd'
import '../../style/phoneNumbers.css'
import phoneNumbers from './data/phoneNumbers.json'
import { TopMsg } from '../Topmsg'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { DownOutlined } from '@ant-design/icons'
import ReactCodeInput from 'react-verification-code-input'
import { data, privetData, handleErrorAndDispatch } from '../../redux/requests'

const btnStyle = {
  backgroundColor: '#6568E6',
  width: 616,
  height: 48,
  borderRadius: 4,
  marginTop: 14,
  color: '#fff',
  textAlign: 'center',
  marginTop: 30,
  paddingTop: 10,
  cursor: 'pointer',
}
const countryCodeStyle = {
  border: '1px solid #D9D9D9',
  borderRadius: '8px 8px 0px 0px',
  width: 616,
  height: 56,
  fontSize: 16,
  fontWeight: 400,
  textIndent: 10,
  userSelect: 'none',
}
const phnNumberStyle = {
  border: '1px solid #D9D9D9',
  borderRadius: '0px 0px 8px 8px',
  width: 616,
  height: 56,
  fontSize: 16,
  fontWeight: 400,
  textIndent: 10,
}
const displayNone = { style: { display: 'none' } }

const baseURL = process.env.REACT_APP_BASE_URL

const PhoneNumberModal = (props) => {
  const { phoneModal, setPhoneModal, title, phnNumberVal, setPhnNumberVal } =
    props
  const [conCodeList, setConCodeList] = useState(false)
  const [countryName, setCountryName] = useState('United States')
  const [conCodeVal, setConCodeVal] = useState('+1')
  const [showVerifyInfo, setShowVerifyInfo] = useState(false)
  const [verificationCode, setVerificationCode] = useState(null)
  const handleOk = () => {
    setPhoneModal(true)
  }
  const handleCancel = () => {
    setPhoneModal(false)
    props.getProfile() // To update user info at RenterProfile.js (reaquired for new phone not verified message)
  }

  const onChangePhone = async (phnNumberVal) => {
    // return // Temp
    const num = conCodeVal + phnNumberVal
    // console.log(num)
    // console.log(conCodeVal)
    // console.log(phnNumberVal)

    if (phnNumberVal.includes('+') || !phnNumberVal) return

    const cookie = new Cookies()
    const authData = cookie.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    const data = {
      phone: num.substring(1),
    }
    const res = await axios.patch(`${baseURL}/guest/`, data, config)
    // console.log(res.data)

    try {
      // After successful change at DB send the verification message
      let request = await privetData()
      const response = await request.get(
        `/user/verify-phone?type=Guest&email=${
          props.email
        }&phone=${num.substring(1)}&service=sms`,
      )
      // .catch((err) => {
      //   return err.response
      // })

      // console.log(response)

      message.success(TopMsg('Phone info updated successfully'))
      setShowVerifyInfo(true) // to show verify info

      props.getProfile() // To update user info at RenterProfile.js (reaquired for new phone not verified message)

      // setPhoneModal(false)
    } catch (err) {
      // console.log(err)
      message.error(TopMsg('Phone info updated failed! Try again later.'))
      return
    }
  }

  const verifyCode = async () => {
    // if (phnNumberVal.includes('+') || !phnNumberVal) return
    try {
      const payload = {
        type: 'Guest',
        email: props.email,
        phone: phnNumberVal,
        token: verificationCode,
      }
      // console.log(payload)

      let request = await privetData()
      const response = await request
        .post(`/user/verify-phone`, payload)
        .catch((err) => {
          return err.response
        })
      // console.log(response)

      if (response.status === 406) {
        message.error(
          TopMsg('Wrong code, pelase try again or resend the code.'),
        )
        return
      }

      message.success(TopMsg('Phone info verified.'))
      setShowVerifyInfo(false) // to show verify info
      setPhoneModal(false)

      props.getProfile() // To update user info at RenterProfile.js (reaquired for new phone not verified message)

      // setPhoneModal(false)
    } catch (err) {
      message.error(TopMsg('Wrong code, pelase try again or resend the code.'))
    }
  }

  return (
    <>
      <Modal
        title={title}
        cancelButtonProps={displayNone}
        okButtonProps={displayNone}
        width={680}
        okText="Send"
        visible={phoneModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: '16%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 25,
          }}
        >
          <h4 style={{ fontSize: 24, fontWeight: 500 }}>
            {!showVerifyInfo
              ? `Change your phone number`
              : `Please enter verification code`}
          </h4>
          <h6 style={{ fontSize: 14, fontWeight: 400, color: '#000' }}>
            {!showVerifyInfo
              ? `This is so your Host, Guests, or Venuely can reach you.`
              : `Please enter 5 digit code sent to ${phnNumberVal}`}
          </h6>
        </div>
        {!showVerifyInfo ? (
          <>
            <div
              style={{
                position: 'relative',
                // border: '1px solid red'
              }}
            >
              <input
                style={{ ...countryCodeStyle, paddingTop: 25 }}
                onClick={() => {
                  setConCodeList(!conCodeList)
                  if (conCodeVal && countryName) {
                    setConCodeVal('')
                    setCountryName('')
                  }
                }}
                // placeholder="Country Codes"
                // placeholder={
                //   conCodeVal && `${countryName + ' ' + `(${conCodeVal})`}`
                // }
                value={
                  countryName
                    ? `${countryName + ' ' + `(${conCodeVal})`}`
                    : `${conCodeVal}`
                }
                // value={conCodeVal}
                // value={conCodeVal.includes('+') ? conCodeVal : `+${conCodeVal}`})``}
                onChange={(e) => setConCodeVal(e.target.value)}
              />
              <p
                style={{
                  position: 'absolute',
                  top: 10,
                  color: '#656369',
                  paddingLeft: 10,
                }}
              >
                Country/Region
              </p>
              <DownOutlined
                className="DownOutlined_icon"
                onClick={() => setConCodeList(!conCodeList)}
                style={{
                  // fontSize: '34px', // not working here, check class name
                  color: '#656369',
                  position: 'absolute',
                  top: 22,
                  right: 30,
                }}
              />
            </div>
            {conCodeList && (
              <ul
                className="date-menu2"
                style={{ width: 616, marginLeft: 1, top: '43%' }}
              >
                {phoneNumbers.countries
                  .filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(conCodeVal.toLowerCase()) ||
                      item.code
                        .toLowerCase()
                        .includes(conCodeVal.toLowerCase()),
                  )
                  .map((item, idx) => {
                    return (
                      <div
                        className="phn-number-code-list"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '5px 15px',
                          marginLeft: -25,
                        }}
                        onClick={() => {
                          setConCodeVal(item.code)
                          setCountryName(item.name)
                          setConCodeList(false)
                        }}
                      >
                        <label style={{ fontSize: 14, padding: 10 }}>
                          {item.name}
                        </label>
                        <label key={idx} style={{ fontSize: 14 }}>
                          {item.code}
                        </label>
                      </div>
                    )
                  })}
              </ul>
            )}
            <input
              style={phnNumberStyle}
              type="number"
              placeholder="Phone Number"
              value={phnNumberVal !== '0' ? phnNumberVal : ''}
              onChange={(e) => setPhnNumberVal(e.target.value)}
            />
            <h5 style={{ fontWeight: 400, fontSize: 14, marginTop: 10 }}>
              We’ll call or text you to confirm your number. Standard message
              and data rates apply.
            </h5>
            <div style={btnStyle} onClick={() => onChangePhone(phnNumberVal)}>
              <label style={{ fontSize: 16, marginTop: 12 }}>Continue</label>
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: 18, fontWeight: 600 }}>
              Enter verification code
            </p>
            <ReactCodeInput
              fields={5}
              onChange={(val) => {
                // console.log(val)
                setVerificationCode(val)
              }}
            />
            <div
              style={{ ...btnStyle, width: '60%' }}
              onClick={() => verifyCode()}
            >
              <label style={{ fontSize: 16, marginTop: 12 }}>
                Confirm Code
              </label>
            </div>

            <p
              style={{
                marginTop: 30,
                textAlign: 'center',
                fontSize: 16,
              }}
            >
              Didn’t get a code?
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: 14,
                color: '#656369',
              }}
            >
              If you don’t receive your link in 1 minutes, <br />
              click{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  fontSize: 14,
                  color: '#656369',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  props.reSendPhoneCode()
                }}
              >
                resend a code.
              </span>
            </p>
          </div>
        )}
      </Modal>
    </>
  )
}

export default PhoneNumberModal
