import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import rectangle from '../pages/icons/rec2.svg'
import '../style/mybookings.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Button, message, Spin } from 'antd'
import get12HourFormat from '../components/utils/get12HourFormat'
import { useDispatch } from 'react-redux'
import { logout, refresh } from '../redux/actions/AuthAction'
import useInterval from '@use-it/interval'
import noUpImg from '../img/bookings.png'
import Button1 from '../components/buttons/Button1'
import { months } from '../components/utils/months'

const Mybookings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [cancelBooking, setcancelBooking] = useState(false)
  const [bookings, setBookings] = useState(null)
  const [doneFetchingApi, setdoneFetchingApi] = useState(false)

  const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL.split('/api')[0]
  const getUserBookings = async () => {
    try {
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const res = await axios.get(
        `${baseURL}/guest/space-reservation?cancel_reservation_page_number=1&cancel_reservation_limit=10&past_reservation_page_number=1&past_reservation_limit=10&pending_reservation_page_number=1&pending_reservation_limit=10&latest_reservation_page_number=1&latest_reservation_limit=10`,
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      // console.log(res.data)
      setBookings(res.data)
      setdoneFetchingApi(true)
    } catch (err) {
      if (err?.response?.status === 406) {
        message.error('Please Login again')
        dispatch(logout())
        navigate('/')
      } else {
        // message.error(err?.response.data.detail)
        console.log(err.response)
      }
    }
  }
  useEffect(() => {
    getUserBookings()
  }, [])

  const formattedDate = (item) => {
    return (
      months[item.start_date.slice(6, 7)] +
      ', ' +
      item.start_date.slice(8, 10) +
      ', ' +
      item.start_date.slice(0, 4)
    )
  }

  const [upcomingnumOfData, setupcomingnumOfData] = useState(3)
  const [upcomingShowMore, setupcomingShowMore] = useState(false)
  const [pastnumOfData, setpastnumOfData] = useState(6)
  const [pastShowMore, setpastShowMore] = useState(false)
  const [cancallednumOfData, setcancallednumOfData] = useState(6)
  const [cancalledShowMore, setcancalledShowMore] = useState(false)
  const [pendingnumOfData, setpendingnumOfData] = useState(6)
  const [pendingShowMore, setpendingShowMore] = useState(false)

  const downloadICS = async (ID) => {
    try {
      const cookie = new Cookies()
      const authData = cookie.get('jwt')
      const res = await axios.post(
        `${baseURL}/guest/space-reservation-ics-file/${ID}`,
        {},
        {
          headers: {
            Authorization: authData,
          },
        },
      )
      const link = document.createElement('a')
      const file = new File([res.data.data], 'your_upcoming_booking.ics', {
        type: 'text/plain',
      })
      const url = URL.createObjectURL(file)
      link.href = url
      link.download = file.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (err) {
      console.log(err)
    }
  }
  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)

  const EmptyState = () => {
    if (bookings.latest_reservation.data.length === 0) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px #ccc solid',
            borderRadius: 14,
            width: 1250,
            height: 320,
            margin: '40px 0',
          }}
        >
          <div style={{ margin: 40 }}>
            <h1 style={{ fontSize: 20, margin: '25px 0' }}>
              No upcoming bookings...
            </h1>
            <div
              onClick={() => {
                navigate('/renter/search')
              }}
            >
              <Button1 title="Start Searching" />
            </div>
          </div>
          <img
            src={noUpImg}
            alt=""
            style={{
              width: 600,
              height: '100%',
              objectFit: 'cover',
              margin: 10,
            }}
          />
        </div>
      )
    } else return <></>
  }

  const BookingStatus = (status) => {
    let statusText
    if (status === 'PENDING') {
      statusText = 'The Request Is Being Reviewed by the Host'
    } else {
      statusText = 'Booking Confirmed'
    }
    return (
      <h5 className="review-text" style={{ fontSize: 16 }}>
        {statusText}
      </h5>
    )
  }
  return (
    <>
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      {doneFetchingApi ? (
        <>
          <div style={{ margin: 60 }}>
            <h1 style={{ fontSize: 32, fontWeight: 500, margin: '40px 0px' }}>
              My bookings
            </h1>
            <h4 style={{ fontWeight: '500', marginTop: 35, fontSize: 24 }}>
              Upcoming bookings
            </h4>
            <EmptyState />
            <>
              <div style={{ marginTop: 40 }}>
                {bookings?.latest_reservation.data
                  ?.filter((_, idx) => idx < upcomingnumOfData)
                  ?.map((item, idx) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 20,
                            justifyContent: 'space-between',
                            border: ' #ccc 1px solid',
                            borderRadius: 8,
                            gap: 10,
                          }}
                        >
                          <img
                            src={
                              `${baseURL.replace('/api', '')}${
                                item.property.images[0].image
                              }` || rectangle
                            }
                            alt=""
                            style={{
                              width: 237,
                              height: 170,
                              objectFit: 'cover',
                              borderRadius: '8px 0 0 8px',
                            }}
                          />
                          <div>
                            <h4 style={{ fontSize: 16 }}>
                              {months[Number(item.start_date.slice(5, 7))]}{' '}
                              {item.start_date.slice(8, 10)},{' '}
                              {item.start_date.slice(0, 4)},{' '}
                              {get12HourFormat(item.start_time)} -{' '}
                              {get12HourFormat(item.end_time)}
                            </h4>
                            <h4 style={{ fontSize: 16 }}>
                              {item.activity.title}
                            </h4>
                            <h5 style={{ fontSize: 14 }}>
                              {item.property.address}
                            </h5>
                            <h5 style={{ color: '#808080', fontSize: 14 }}>
                              {months[+item.start_date.slice(5, 7) - 1]}{' '}
                              {item.start_date.slice(8, 10)},{' '}
                              {item.start_date.slice(0, 4)}, 8:49 EST
                            </h5>
                            <h5 style={{ color: '#808080' }}>
                              {JSON.stringify(bookings.date)}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                            // onClick={() => navigate('/renter/profile')}
                          >
                            <img
                              src={`${imgURL}${item?.host.image}` || rectangle}
                              alt=""
                              style={{
                                width: 80,
                                height: 80,
                                objectFit: 'cover',
                                borderRadius: '100%',
                              }}
                            />
                            <h5 style={{ marginTop: 10, fontSize: 16 }}>
                              {item.host.name}
                            </h5>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 0,
                              textAlign: 'center',
                            }}
                          >
                            {BookingStatus(item.reservation_status)}
                            <h4
                              style={{
                                fontSize: 18,
                                fontWeight: '500',
                                cursor: 'context-menu',
                              }}
                            >
                              ${' '}
                              {(
                                Math.round(item.total_amount * 100) / 100
                              ).toFixed(2)}
                            </h4>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 25,
                            }}
                          >
                            <button
                              className="see-detail-btn"
                              onClick={() =>
                                navigate(`/renter/review/${item.unique_id}`, {
                                  state: {
                                    item: item,
                                  },
                                })
                              }
                              style={{
                                padding: '10px 34px',
                                fontSize: 16,
                                backgroundColor: '#6568E6',
                                color: 'white',
                              }}
                            >
                              See detail
                            </button>
                            {item.reservation_status === 'CONFIRMED' && (
                              <button
                                className="see-detail-btn"
                                onClick={() => downloadICS(item.unique_id)}
                                style={{
                                  color: '#6568E6',
                                  border: '#6568E6 1px solid',
                                  padding: '10px 12px',
                                  marginTop: 10,
                                  fontSize: 16,
                                }}
                              >
                                Add to calendar
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  })}
              </div>
              {bookings.latest_reservation.data.length >= 4 && (
                <h5
                  className="show-more"
                  style={{ marginTop: 10, fontSize: 16 }}
                  onClick={() => {
                    setupcomingShowMore(!upcomingShowMore)
                    !upcomingShowMore
                      ? setupcomingnumOfData(
                          bookings.latest_reservation.data.length,
                        )
                      : setupcomingnumOfData(3)
                  }}
                >
                  {!upcomingShowMore ? 'Show more' : 'Show less'}
                </h5>
              )}
            </>
            {bookings?.past_reservation.data.length > 0 && (
              <h4 style={{ fontWeight: '500', marginTop: 35, fontSize: 24 }}>
                Past bookings
              </h4>
            )}
            <div
              style={{
                margin: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={gridStyle}>
                {bookings.past_reservation.data
                  .filter((item, idx) => idx < pastnumOfData)
                  .map((item, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        marginRight: 180,
                        alignItems: 'center',
                        width: 240,
                      }}
                    >
                      <div>
                        <img
                          src={
                            `${baseURL.replace('/api', '')}${
                              item.property.images[0].image
                            }` || rectangle
                          }
                          alt=""
                          style={{
                            width: 130,
                            height: 100,
                            objectFit: 'cover',
                            borderRadius: 4.25,
                          }}
                        />
                      </div>
                      <div>
                        <h5
                          style={{
                            fontWeight: '600',
                            margin: 10,
                            fontSize: 16,
                          }}
                        >
                          {item.activity.title}
                        </h5>
                        <h6 style={{ margin: 10, opacity: 0.7, fontSize: 14 }}>
                          {formattedDate(item)}
                        </h6>
                        <h6
                          style={{
                            margin: 10,
                            opacity: 0.7,
                            width: '120%',
                            fontSize: 14,
                          }}
                        >
                          {item.property.address}
                        </h6>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {bookings.past_reservation.data.length > 6 && (
              <h5
                className="show-more"
                onClick={() => {
                  setpastShowMore(!pastShowMore)
                  !pastShowMore
                    ? setpastnumOfData(bookings.past_reservation.data.length)
                    : setpastnumOfData(6)
                }}
                style={{ fontSize: 16 }}
              >
                {!pastShowMore ? 'Show more' : 'Show less'}
              </h5>
            )}
            {bookings.cancel_reservation.data.length > 0 && (
              <h4 style={{ fontWeight: '500', fontSize: 24, width: 240 }}>
                Cancelled bookings
              </h4>
            )}
            <div
              style={{
                margin: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={gridStyle}>
                {bookings.cancel_reservation.data
                  .filter((item, idx) => idx < pendingnumOfData)
                  .map((item, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        marginRight: 180,
                        alignItems: 'center',
                        width: 240,
                      }}
                    >
                      <div>
                        <img
                          src={
                            `${baseURL.replace('/api', '')}${
                              item.property.images[0].image
                            }` || rectangle
                          }
                          alt=""
                          style={{
                            width: 180,
                            height: 100,
                            objectFit: 'cover',
                            borderRadius: 4.25,
                          }}
                        />
                      </div>
                      <div>
                        <h5
                          style={{
                            fontWeight: '600',
                            margin: 10,
                            fontSize: 16,
                          }}
                        >
                          {item.activity.title}
                        </h5>
                        <h6 style={{ margin: 10, opacity: 0.7, fontSize: 14 }}>
                          {formattedDate(item)}
                        </h6>
                        <h6
                          style={{
                            margin: 10,
                            opacity: 0.7,
                            width: '120%',
                            fontSize: 14,
                          }}
                        >
                          {item.property.address}
                        </h6>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {bookings.cancel_reservation.data.length > 6 && (
              <h5
                className="show-more"
                style={{ fontSize: 16 }}
                onClick={() => {
                  setcancalledShowMore(!cancalledShowMore)
                  !cancalledShowMore
                    ? setcancallednumOfData(
                        bookings.cancel_reservation.data.length,
                      )
                    : setcancallednumOfData(6)
                }}
              >
                {!cancalledShowMore ? 'Show more' : 'Show less'}
              </h5>
            )}
          </div>
        </>
      ) : (
        <div>
          <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
        </div>
      )}
    </>
  )
}

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: 40,
  margin: '15px 0',
}

export default Mybookings
