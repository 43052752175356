import { WindowBookingRoleActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setWindowBookingRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/host/space/booking-window-rules/', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: WindowBookingRoleActionType.SET_WINDOW_BOOKING_ROLE,
    payload: {},
  })
}

export const selectWindowBookingRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/booking-window-rules/${requiredData.id}`, requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: WindowBookingRoleActionType.SELECT_WINDOW_BOOKING_ROLE,
    payload: {},
  })
}

export const createWindowBookingRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space/booking-window-rules/', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: WindowBookingRoleActionType.CREATE_WINDOW_BOOKING_ROLE,
    payload: {},
  })
}

export const updateWindowBookingRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/booking-window-rules/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: WindowBookingRoleActionType.UPDATE_WINDOW_BOOKING_ROLE,
    payload: {},
  })
}

export const deleteWindowBookingRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(
      `/host/space/booking-window-rules/${requiredData.id}/`,
      requiredData,
    )
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: WindowBookingRoleActionType.REMOVE_WINDOW_BOOKING_ROLE,
    // payload:response.data
  })
}
