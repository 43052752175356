import { BufferTimeRoleActionType } from '../contants/actions-type'
const initialState = {}

export default function BufferTimeRoleReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case BufferTimeRoleActionType.SET_BUFFER_TIME_ROLE:
      return {
        ...state,
        ...payload,
      }
    case BufferTimeRoleActionType.SELECT_BUFFER_TIME_ROLE:
      return {
        ...state,
        ...payload,
      }
    case BufferTimeRoleActionType.CREATE_BUFFER_TIME_ROLE:
      return {
        ...state,
        ...payload,
      }

    case BufferTimeRoleActionType.UPDATE_BUFFER_TIME_ROLE:
      return {
        ...state,
        ...payload,
      }

    case BufferTimeRoleActionType.REMOVE_BUFFER_TIME_ROLE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
