import { Outlet } from 'react-router'
import { useState, useEffect, useRef } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { logout, me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval'
import './../style/space.css'
import StatusBox from '../components/StatusBox'
import ArrowImage from './../img/polygon_1.png'
import PlusImage from './../img/plus_symbol.png'
import SpaceListItem from '../components/SpaceListItem'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { setInformation } from '../redux/actions/InformationAction'
import { setSpace } from '../redux/actions/SpaceAction'

function YourSpaceLayOut() {
  const sectionElementRef = useRef(null)
  const [collapsed, setCollapsed] = useState(false)
  const banner = useSelector((state) => state.component.banner)
  const information = useSelector((state) => state.information)
  const allSpace = useSelector((state) => state.space.spaces)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [usrData, setUsrData] = useState({})
  let navigate = useNavigate()
  const [rotateArroButton, setRotateArroButton] = useState(false)
  const [rotateArroButton2, setRotateArroButton2] = useState(false)
  useEffect(() => {
    if (loading) {
      dispatch(
        setInformation({
          callBack: (response) => {
            // if(response.data.property == undefined){
            //   navigate('/space/basic/information')
            // };

            // if(response.data.property != undefined){
            dispatch(
              setSpace({
                callBack: (response) => {
                  setLoading(false)
                },
              }),
            )
            // }
          },
        }),
      )
    }
  }, [loading])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);
  console.log(Object.keys(allSpace).length)
  console.log(allSpace)
  return (
    <main>
      <aside
        className="main-content-aside"
        style={{
          // border: '1px solid red',
          // height:
          //   allSpace && Object.keys(allSpace).length > 0
          //     ? 170 * Object.keys(allSpace).length +
          //       sectionElementRef.current?.clientHeight * 1.5
          //     : '100vh',
          height: 'calc(100vh - 131px)',
        }}
      >
        <div className="space-side-nevbar">
          <ul>
            {/* { information.property && information.property ?  */}
            {/* <> */}
            <li className="space-list">
              <NavLink
                to="/space/add"
                className={({ isActive }) => {
                  setRotateArroButton(isActive)
                  return isActive ? 'active' : ''
                }}
                style={styles.navLinkStyle}
              >
                Listings
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />

              <ul
                className={`space-list-child ${
                  !rotateArroButton ? 'hide-elements' : ''
                }`}
              >
                {/* <li className="space-list-child-item"> <img src={PlusImage} style={{marginRight:"5px"}} alt="" srcSet="" />
                          <NavLink to="add" className={({ isActive }) => (isActive ? 'active' : '')}>
                            Add a space
                          </NavLink>
                        </li> */}
                <li
                // className="space-list-child-item"
                // style={{ marginLeft: '17px' }}
                >
                  {/* <NavLink to={`/space/add/new`} className={  ({ isActive }) => {  return (isActive ? 'active' : '')  } }> add Space </NavLink> */}
                  {/* <br /> */}
                  {allSpace &&
                    Object.keys(allSpace).map((key, index) => {
                      return (
                        <>
                          {' '}
                          <SpaceListItem
                            id={key}
                            index={index}
                            space={allSpace[key]}
                          />{' '}
                          <br />{' '}
                        </>
                      )
                    })}
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/space/basic/information"
                className={({ isActive }) => (isActive ? 'active' : '')}
                style={styles.navLinkStyle}
              >
                Basics
              </NavLink>
            </li>
            <li className="space-list">
              <NavLink
                to="/space/policy-and-ruls"
                className={({ isActive }) => {
                  setRotateArroButton2(isActive)
                  return isActive ? 'active' : ''
                }}
                style={styles.navLinkStyle}
              >
                Policies and Rules
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton2 ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />
              <ul
                className={`space-list-child ${
                  !rotateArroButton2 ? 'hide-elements' : ''
                }`}
              >
                <li className="space-list-child-item">
                  <NavLink
                    to="/space/policy-and-ruls/basic-policies"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={styles.navLinkStyle}
                  >
                    Basic Policies
                  </NavLink>
                </li>
                <li className="space-list-child-item">
                  <NavLink
                    to="/space/policy-and-ruls/house-rules"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    House Rules
                  </NavLink>
                </li>
                <li className="space-list-child-item">
                  <NavLink
                    to="/space/policy-and-ruls/covid-19-policy"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    Covid-19 Policy
                  </NavLink>
                </li>
                <li className="space-list-child-item">
                  <NavLink
                    to="/space/policy-and-ruls/guest-requirement"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    Guest Requirement
                  </NavLink>
                </li>
                {/* <li className="space-list-child-item">
                          <NavLink to="laws-and-regulations" className={({ isActive }) => (isActive ? 'active' : '')}>
                            Laws and regulations
                          </NavLink>
                        </li> */}
              </ul>
            </li>
            {/* </> : (
                    <>
                      <li className="space-list">
                        <a className="de-activated-link">Listings</a>
                        <img src={ArrowImage} className={`arrow-image-space-list ${ !rotateArroButton ? 'rotate-right' : ''}`} alt="" srcSet="" />
                        
                      </li>
                      <li>
                        <NavLink to="/space/basic/information" className={({ isActive }) => (isActive ? 'active' : '')}>Basics</NavLink>
                      </li>
                      <li className="space-list">
                        <a className="de-activated-link">Policies and Rules</a>
                        <img src={ArrowImage} className={`arrow-image-space-list ${ !rotateArroButton2 ? 'rotate-right' : ''}`} alt="" srcSet="" />
                      </li> 
                    </>
                  ) } */}
          </ul>
        </div>
      </aside>
      <section className="main-content" ref={sectionElementRef}>
        <StatusBox />
        <Outlet />
        <NotificationContainer />
      </section>
    </main>
  )
}

const styles = {
  navLinkStyle: {
    color: '#000',
  },
}

export default YourSpaceLayOut
