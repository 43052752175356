import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { getAllInputValue } from '../../redux/requests'
import {
  createHouseRule,
  setHouseRule,
} from '../../redux/actions/HouseRuleAction'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'

const formRef = createRef()
export default function HouseRulesOfSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  const formValue = useSelector((state) => state.information.property)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      fatchFormData()
      dispatch(
        setBanner({
          header: 'Your Space: Policies & Rules',
          discription: 'Set the policies for all of your spaces',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: 25 * 2,
          },
          pages: {
            visible: true,
            total_page: 2,
            page_number: 4,
          },
          callBack: () => {
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let fatchFormData = () => {
    // console.log(formValue);
    if (formValue != undefined) {
      if (formValue.policies_and_rules.house_rules != undefined) {
        let data = formValue.policies_and_rules.house_rules

        if (data.rules != undefined)
          formRef.current.elements.rules.value = data.rules
        if (data.restriction != undefined)
          formRef.current.elements.restriction.value = data.restriction
        if (data.instructions != undefined)
          formRef.current.elements.Instructions.value = data.instructions
        if (data.wifi_name != undefined)
          formRef.current.elements.wifi_name.value = data.wifi_name
        if (data.wifi_password != undefined)
          formRef.current.elements.wifi_password.value = data.wifi_password
      }
    }
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let formData = getAllInputValue(e)

    dispatch(
      updateInformation({
        house_rules: { ...formData },
        callBack: (response) => {},
      }),
    )
    navigate(`/space/policy-and-ruls/covid-19-policy`)
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        className="policy-and-ruls-of-space-insert-space-form"
      >
        <div className="buffer-time-ruls ">
          <h1>What are your house rules?</h1>
          <p style={{ lineHeight: '41px' }}>
            Guests must agree to your house rules before they can book your
            space(s).
            <br />
            Ex) No smoking in the building, No alcohol without staff’s presence.
            <br />
            *Do not include cleaning fee or contracts here.
            <br />
          </p>
          <div style={{ marginTop: '28px' }}>
            {' '}
            <b>
              Include any requirements around safety concern, shared space
              rules, and your community regulations
            </b>{' '}
          </div>
          <textarea
            name="rules"
            id="rules"
            cols="30"
            rows="10"
            placeholder="Example: Outside catering is allowed"
          ></textarea>
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="buffer-time-ruls ">
          <h1>What are your food and beverage restriction in the space?</h1>
          <p style={{ lineHeight: '41px' }}>
            Do you have any specific dietary restrctions for your space? Please
            let the guests know.
            <br />
            Ex) Guests can not eat eggs, fish, dairy, meat and olive oil during
            the fast season.
            <br />
          </p>
          <div style={{ marginTop: '28px' }}>
            {' '}
            <b>
              Include any requirements around religious dietary restrictions
              that applies to your space(s)
            </b>{' '}
          </div>
          <textarea
            name="restriction"
            id="restriction"
            cols="30"
            rows="10"
            placeholder="Example: Only kosher food allowed"
          ></textarea>
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="buffer-time-ruls ">
          <h1>Provide Arrival Instructions</h1>
          <p style={{ lineHeight: '41px' }}>
            Try to include: directions to your space (If address alone is not
            clear), building access, door buzzers,
            <br />
            floors, point of contact, stair/elevator access, etc.
            <br />
          </p>
          <div style={{ marginTop: '28px' }}>
            {' '}
            <b>
              Help your guests and their attendees find and enter your space
            </b>{' '}
          </div>
          <textarea
            name="Instructions"
            id="Instructions"
            cols="30"
            rows="10"
            placeholder="Example: When you arrive, hit the buzzer and ask for Sarah"
          ></textarea>
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="booking-window-rules">
          <h1 style={{ marginTop: '52px' }}>
            What’s your wifi name and password? (Optional)
          </h1>
          <div className="policy-and-ruls-input-group">
            <label htmlFor="wifi_name">Wifi Name: </label>
            <input id="wifi_name" name="wifi_name" type="text" />
          </div>
          <div className="policy-and-ruls-input-group">
            <label htmlFor="wifi_password">Password: </label>
            <input id="wifi_password" name="wifi_password" type="text" />
          </div>
        </div>

        <hr
          style={{ marginTop: '145px' }}
          className="policy-and-ruls-of-space-insert-lasr-hr"
        />
        <div className="policy-and-ruls-of-space-insert-space-button-group">
          <button
            type="button"
            style={{ marginRight: '10px' }}
            onClick={() => {
              navigate(`/space/policy-and-ruls/basic-policies`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="submit"> Next </button>
        </div>
      </form>
    </div>
  )
}
