import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setBanner } from './../../../redux/actions/ComponentAction'

import BarCart from '../../../components/BarCart'
import arrowDown from './../../../img/arrow_down_1.png'
import SelectOption from '../../../components/SelectOption'
import { setPaymentHistory } from '../../../redux/actions/InformationAction'

export default function Income() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [allPaymentHistoryData, setAllPaymentHistoryData] = useState(null)
  const details = useSelector((state) => state.information)
  const [historyIsloading, setHistoryIsloading] = useState(false)
  useEffect(() => {
    if (loading) {
      setHistoryIsloading(true)
      dispatch(
        setBanner({
          header: 'Today',
          discription: 'Earnings & Payout Summary',
          button: {
            visible: details.details_submitted
              ? !details.details_submitted
              : true,
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            dispatch(
              setPaymentHistory({
                page_number: 1,
                limit: 10,
                callBack: (response) => {
                  let history = response.data
                  setAllPaymentHistoryData(history)
                  setHistoryIsloading(false)
                  // console.log(history.refunded);
                },
              }),
            )
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let setSelectOptionValue = ({ name, value }, multiSelect) => {
    console.log(name, value, multiSelect)
  }
  let spacemultiSelectOptionsList = [
    { value: 'example_space_1', name: 'Example Space #1' },
    { value: 'example_space_2', name: 'Example Space #2' },
    { value: 'example_space_3', name: 'Example Space #3' },
    { value: 'example_space_4', name: 'Example Space #4' },
  ]
  let spaceOptionsList = [
    { name: 'apply_to', value: 'Apply to' },
    { name: 'all_spaces', value: 'All spaces' },
  ]
  let monthRangeOptionsListStart = [
    { name: 'From: January', value: 'january' },
    { name: 'From: February', value: 'february' },
    { name: 'From: March', value: 'march' },
    { name: 'From: April', value: 'april' },
    { name: 'From: May', value: 'may' },
    { name: 'From: Jun', value: 'jun' },
    { name: 'From: July', value: 'july' },
    { name: 'From: August', value: 'august' },
    { name: 'From: september', value: 'september' },
    { name: 'From: Octobar', value: 'octobar' },
    { name: 'From: November', value: 'november' },
    { name: 'From: December', value: 'december' },
  ]
  let monthRangeOptionsListEnd = [
    { name: 'To: January', value: 'january' },
    { name: 'To: February', value: 'february' },
    { name: 'To: March', value: 'march' },
    { name: 'To: April', value: 'april' },
    { name: 'To: May', value: 'may' },
    { name: 'To: Jun', value: 'jun' },
    { name: 'To: July', value: 'july' },
    { name: 'To: August', value: 'august' },
    { name: 'To: september', value: 'september' },
    { name: 'To: Octobar', value: 'octobar' },
    { name: 'To: November', value: 'november' },
    { name: 'To: December', value: 'december' },
  ]
  let yearRangeOptionsList = [
    { name: '2022', value: '2022' },
    { name: '2023', value: '2023' },
    { name: '2024', value: '2024' },
    { name: '2025', value: '2025' },
    { name: '2028', value: '2028' },
    { name: '2029', value: '2029' },
  ]

  let loader_wrapper_style = {
    background: 'white',
    height: '125rem',
    width: '80%',
    position: 'absolute',
    top: '298px',
    zIndex: '1',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <>
      {historyIsloading ? (
        <div>
          <Spin size="large" style={{ width: '100%', margin: '40px 50px' }} />
        </div>
      ) : (
        <>
          <div className="cart-filter-information">
            <div className="total-earning-information">
              <h1>
                $
                {allPaymentHistoryData && allPaymentHistoryData.expected != null
                  ? allPaymentHistoryData.expected.toFixed(2)
                  : 0}
              </h1>
              <p>Total Booked earnings</p>
            </div>

            {/* <div className="cart-filter">
          <div className="cart-filter-space-group">
            <h1>Select a space</h1>
            <SelectOption multiSelect={spacemultiSelectOptionsList} preSetValue={null} optionsList={spaceOptionsList} optionName={'select_space'} className={'select_space'} sendValue={setSelectOptionValue} placeHolder={'Select a space'}/>
          </div>
          <div className="cart-filter-history-group">
            <h1>Select a month</h1>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={monthRangeOptionsListStart} optionName={'select_month_start'} className={'select_month_start'} sendValue={setSelectOptionValue} placeHolder={'From: January'}/>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={monthRangeOptionsListEnd} optionName={'select_month_end'} className={'select_month_end'} sendValue={setSelectOptionValue} placeHolder={'From: Decembar'}/>
          </div>
          <div className="cart-filter-history-second-group">
            <h1>Select a year</h1>
            <SelectOption multiSelect={null} preSetValue={null} optionsList={yearRangeOptionsList} optionName={'select_year'} className={'select_year'} sendValue={setSelectOptionValue} placeHolder={'Year'}/>
          </div>
        </div> */}

            {/* <div className="export-button">Export</div> */}
          </div>
          <div className="chart-data-information">
            <div className="payout information-box">
              <div className="amount">
                $
                {allPaymentHistoryData && allPaymentHistoryData.paid_out != null
                  ? allPaymentHistoryData.paid_out.toFixed(2)
                  : 0}
              </div>
              <div className="amount-information-box">
                <div className="payout-color information-box-color"></div>
                <div className="information-box-name">Paid out</div>
              </div>
            </div>

            <div className="expected information-box">
              <div className="amount">
                $
                {allPaymentHistoryData && allPaymentHistoryData.expected != null
                  ? allPaymentHistoryData.expected.toFixed(2)
                  : 0}
              </div>
              <div className="amount-information-box">
                <div className="expected-color information-box-color"></div>
                <div className="information-box-name">Expected</div>
              </div>
            </div>

            <div className="refunds information-box">
              <div className="amount">
                $
                {allPaymentHistoryData && allPaymentHistoryData.refunded != null
                  ? allPaymentHistoryData.refunded.toFixed(2)
                  : 0}
              </div>
              <div className="amount-information-box">
                <div className="refunds-color information-box-color"></div>
                <div className="information-box-name">Refunds</div>
              </div>
            </div>

            <div className="chargebacks information-box">
              <div className="amount">
                $
                {allPaymentHistoryData &&
                allPaymentHistoryData.charge_back != null
                  ? allPaymentHistoryData.charge_back.toFixed(2)
                  : 0}
              </div>
              <div className="amount-information-box">
                <div className="chargebacks-color information-box-color"></div>
                <div className="information-box-name">Chargebacks</div>
              </div>
            </div>
          </div>
          <div className="bar-chart">
            <BarCart
              chart_data={
                allPaymentHistoryData && allPaymentHistoryData.chart_data
              }
            />
          </div>
        </>
      )}
    </>
  )
}
