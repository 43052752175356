import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, verifyEmailAccount } from '../../redux/actions/AuthAction'
import { NavLink, useParams, useNavigate } from 'react-router-dom'

export default function VerifyEmail() {
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  let token = searchParams.get('token'),
    type = searchParams.get('user'),
    email = searchParams.get('email')

  useEffect(() => {
    dispatch(
      verifyEmailAccount({
        user: searchParams.get('user').replaceAll("'", ''),
        token: searchParams.get('token').replaceAll("'", ''),
        email: searchParams.get('email').replaceAll("'", ''),
        callBack: (response) => {
          if (isauthenticated) {
            if (searchParams.get('user').replaceAll("'", '') == 'Host') {
              navigate('/profile-edit')
            }
          }
          if (!isauthenticated) {
            dispatch(logout())
            navigate('/')
          }
          // console.log(response);
        },
      }),
    )
    console.log(searchParams.get('user').replaceAll("'", ''))
  }, [3])
  return <div>VerifyEmail</div>
}
