import React, { useState, createRef, useEffect } from 'react'
import arrowDown from './../img/arrow_down_1.png'
import { getAllInputValue } from '../redux/requests'
import { useSelector, useDispatch } from 'react-redux'
import { updatePricing } from '../redux/actions/PricingAction'
import { selectSpace } from '../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function OptOutActivitieBox({
  setOptOutActivities,
  space_id,
  header,
  lists,
  listHeading,
  listHeadingDescription,
  pricing_type_id,
  pricing_id,
}) {
  const formRef = createRef()
  const pricing = useSelector((state) => state.space.space.price)
  const [displayList, setDisplayList] = useState(
    pricing[pricing_type_id] != undefined ? true : false,
  )
  const [checkBoxList, setCheckBoxList] = useState(lists)
  const [activities, setActivities] = useState(null)
  const [makeButtonSelected, setMakeButtonSelected] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (pricing[pricing_type_id]) {
      let opt = pricing[pricing_type_id].opt_out_activities
      opt.map((option) => {
        let id = option.title.replaceAll(' ', '_').toLowerCase() + option.id
        formRef.current.elements[id].checked = true
        setMakeButtonSelected(true)
      })
    }
  }, [2])

  const handleAddActivities = (e) => {
    e.preventDefault()
    // var form = document.getElementById('addOptOutActivities');
    // var selectElement = form.querySelector('input[name="activities"]');
    // var selectedValue = selectElement.value;
    // console.log(selectedValue);
    let newList = [...checkBoxList, activities]
    setCheckBoxList(newList)
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let selectedValue = []
    for (const element in formRef.current.elements) {
      if (isNaN(parseInt(element))) {
        if (
          element != 'length' &&
          element != 'namedItem' &&
          element != 'item' &&
          element != 'list_of_activities'
        ) {
          if (formRef.current.elements[element].checked) {
            selectedValue.push(
              parseInt(formRef.current.elements[element].value),
            )
          }
        }
      }
    }

    dispatch(
      updatePricing({
        id: pricing_id,
        opt_out_activities: selectedValue,
        callBack: (response) => {
          dispatch(
            selectSpace({
              id: space_id,
              callBack: (response) => {
                NotificationManager.success(
                  `Successfully opt-out-activitie`,
                  'opt-out-activitie',
                  700,
                )
              },
            }),
          )
        },
      }),
    )
  }

  let handleFormChange = (e) => {
    let selectedValue = []
    let data = {}
    for (const element in formRef.current.elements) {
      if (formRef.current.elements[element].nodeName == 'INPUT') {
        if (
          element != 'length' &&
          element != 'namedItem' &&
          element != 'item' &&
          element != 'list_of_activities'
        ) {
          if (formRef.current.elements[element].checked) {
            selectedValue.push(
              parseInt(formRef.current.elements[element].value),
            )
          }
        }
      }
    }
    data[pricing_id] = selectedValue
    setOptOutActivities(data)
  }
  return (
    <div className="activitie">
      <h1>{header}</h1>
      <button type="button" className={'selected-button'}>
        Select
      </button>
      <hr
        style={{
          display: displayList ? 'block' : 'none',
          marginTop: 20,
          marginBottom: 20,
        }}
      />
      <h2 style={{ display: displayList ? 'block' : 'none' }}>{listHeading}</h2>
      <p style={{ display: displayList ? 'block' : 'none' }}>
        {listHeadingDescription}
      </p>
      <form onChange={handleFormChange} ref={formRef}>
        <ul style={{ display: displayList ? 'block' : 'none' }}>
          {lists.map((list) => {
            return (
              <li>
                <input
                  type="checkbox"
                  id={list.title.replaceAll(' ', '_').toLowerCase() + list.id}
                  name={listHeading.replaceAll(' ', '_').toLowerCase()}
                  value={list.id}
                />
                <label
                  style={{ marginLeft: '7px' }}
                  htmlFor={
                    list.title.replaceAll(' ', '_').toLowerCase() + list.id
                  }
                >
                  {' '}
                  {list.title}{' '}
                </label>
                <br />
              </li>
            )
          })}
        </ul>
      </form>
      {/* <p style={{display: displayList ? "block" : "none" }} >Add your own opt out activity</p>
            <form style={{display: displayList ? "block" : "none" }} className='addOptOutActivities' id='addOptOutActivities'>
                <input name='activities' type="text" onChange={ (e)=>{setActivities(e.target.value)} } />
                <button onClick={handleAddActivities}>Add</button>
            </form> */}
      <hr />
      {/* <span style={{display: !displayList ? "block" : "none" }}>Show Examples</span> */}
      {/* <img className='downarrow' style={{transform: `rotate(${ displayList ?'180deg':'0deg'})`}} onClick={ ()=>{setDisplayList(!displayList)} } src={arrowDown} alt="" srcSet="" /> */}
      {/* <span style={{display: displayList ? "block" : "none" }}>Back to the top</span> */}
    </div>
  )
}
