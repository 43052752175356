import React from 'react'

const ActivityList = (props) => {
  const {
    setActivityVal,
    setactivityItemSelected,
    setactivityValID,
    activityItemsArray,
    getAllActivities,
    setSelectedActivites,
    activityRef,
  } = props
  // console.log(props)
  return (
    <>
      {activityItemsArray?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="txt"
            style={{ color: '#000' }}
            onClick={(ev) => {
              console.log(ev.target.innerText)
              console.log(item)
              if (setSelectedActivites)
                // only from Topbar.js
                setSelectedActivites((prev) => {
                  console.log('prev', prev)
                  if (prev) {
                    return [...prev, item]
                  } else {
                    return [item]
                  }
                })
              if (activityRef) activityRef.current.value = '' // only from Topbar.js
              // console.log("RAW ACTIVITY ID", item.id)
              setActivityVal(ev.target.innerText)
              setactivityItemSelected(false)
              setactivityValID(item.id) // From Booking.js (found now as of now)
            }}
          >
            {item.title}
          </div>
        )
      })}
      <div
        className="all-act-txt"
        onClick={() => {
          getAllActivities()
        }}
        style={{
          color: '#6568E6',
          marginLeft: 11,
          marginBottom: 7,
          marginTop: 5,
        }}
      >
        See all popular activities
      </div>
    </>
  )
}

export default ActivityList
