import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'antd'

const ActivityModal = (props) => {
  const { activityModal, setActivityModal, allActivities, event, setEvent } =
    props
  const [meeting, setMeeting] = useState(false)
  const [media, setMedia] = useState(false)

  const handleOk = () => setActivityModal(false)
  const handleCancel = () => setActivityModal(false)

  const titleStyle = {
    color: '#888',
    paddingBottom: 13,
    fontSize: 16,
    borderBottom: '1px solid #D9D9D9',
    width: 260,
    textAlign: 'center',
    cursor: 'context-menu',
    transition: 'ease-out 0.2s all',
  }
  const titleActiveStyle = {
    color: '#555',
    paddingBottom: 10,
    fontSize: 16,
    fontWeight: 500,
    borderBottom: '3px solid #1B7E69',
    width: 260,
    textAlign: 'center',
    cursor: 'context-menu',
    transition: 'ease-out 0.2s all',
  }

  const setEventActive = () => {
    setEvent(true)
    setMeeting(false)
    setMedia(false)
  }
  const setMeetingActive = () => {
    setMeeting(true)
    setEvent(false)
    setMedia(false)
  }
  const setMediaActive = () => {
    setMedia(true)
    setEvent(false)
    setMeeting(false)
  }

  return (
    <>
      <Modal
        title="All Activities"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        okText="Done"
        visible={activityModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: '5%' }}
        footer={null}
        width="800px"
      >
        <div
          className="top__head"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: '20px 25px',
          }}
        >
          <h6
            onClick={setEventActive}
            style={event ? titleActiveStyle : titleStyle}
          >
            Event
          </h6>
          <h6
            onClick={setMeetingActive}
            style={meeting ? titleActiveStyle : titleStyle}
          >
            Meeting/Class/Rehearsal
          </h6>
          <h6
            onClick={setMediaActive}
            style={media ? titleActiveStyle : titleStyle}
          >
            Media Production
          </h6>
        </div>
        {event && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginLeft: 60,
              marginTop: 10,
            }}
          >
            {allActivities[0].activitie?.map((item, idx) => (
              <div key={idx} style={{ margin: 12 }}>
                <h5
                  style={{
                    fontSize: 16,
                    color: '#333',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                  }}
                >
                  {item.title}
                </h5>
              </div>
            ))}
          </div>
        )}
        {meeting && allActivities[1] && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginLeft: 60,
              marginTop: 10,
            }}
          >
            {allActivities[1].activitie?.map((item, idx) => (
              <div key={idx} style={{ margin: 12 }}>
                <h5
                  style={{
                    fontSize: 16,
                    color: '#333',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                  }}
                >
                  {item.title}
                </h5>
              </div>
            ))}
          </div>
        )}
        {media && allActivities[2] && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              marginLeft: 60,
              marginTop: 10,
            }}
          >
            {allActivities[2].activitie?.map((item, idx) => (
              <div key={idx} style={{ margin: 12 }}>
                <h5
                  style={{
                    fontSize: 16,
                    color: '#333',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                  }}
                >
                  {item.title}
                </h5>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  )
}

export default ActivityModal
