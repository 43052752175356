import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval'
import './../style/dashboard.css'
import StatusBox from '../components/StatusBox'
import { setReservation } from '../redux/actions/PropertyAction'

function DashboardLayOut() {
  const [collapsed, setCollapsed] = useState(false)
  const banner = useSelector((state) => state.component.banner)
  const reservations = useSelector((state) => state.property.reservations)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [usrData, setUsrData] = useState({})
  const [allReservation, setAllReservation] = useState(null)
  let navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    dispatch(
      setReservation({
        type: 'PENDING',
        page_number: 1,
        limit: 1,
        callBack: (response) => {},
      }),
    )
  }, [pathname])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);

  return (
    <main>
      <aside
        style={{
          height: 'calc(100vh - 131px)',
        }}
      >
        <div
          style={{
            height: '6rem',
            width: '100%',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            borderBottom: '1px solid #c4c4c4',
          }}
        >
          <div
            style={{
              // width: '50%',
              margin: '0 auto',
            }}
          >
            <a href="#" style={style.navLinkStyle} className="active-link">
              Your Reservation
            </a>
          </div>
        </div>
      </aside>
      <section className="main-content">
        <StatusBox />
        <section
          className="content"
          style={{
            height: 'calc(100vh - 331px)',
            overflow: 'auto',
            scrollbarWidth: 'none',
          }}
        >
          <h1 style={{ marginBottom: 25 }}>Your Reservations</h1>
          <div className="button-list" style={{}}>
            <NavLink to={'/pending-request'} className={'button-link'}>
              Pending Request (
              <span className="pending-request-count">
                {reservations.total_pending}
              </span>
              ){' '}
            </NavLink>
            <NavLink to={'/upcoming-events'} className={'button-link'}>
              Upcoming Events (
              <span className="checking-in-soon-count">
                {reservations.total_confirmed}
              </span>
              )
            </NavLink>
            {/* <NavLink to={'/past-reservation'} className={'button-link'}>Past Reservation (<span className="accepted-bookings-count">{reservations.total_past}</span>)</NavLink> */}
            <NavLink to={'/canceled-request'} className={'button-link'}>
              Canceled Reservation (
              <span className="accepted-bookings-count">
                {reservations.total_canceled}
              </span>
              )
            </NavLink>
            <NavLink to={'/all-reservations'} className={'button-link'}>
              All Reservations
            </NavLink>
          </div>
          <Outlet />
        </section>
      </section>
    </main>
  )
}

const style = {
  navLinkStyle: {
    color: '#000',
    textAlign: 'center',
    fontSize: 14,
  },
}

export default DashboardLayOut
