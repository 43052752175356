import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter, getAllInputValue } from '../../redux/requests'
import {
  createHouseRule,
  setHouseRule,
} from '../../redux/actions/HouseRuleAction'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'
import HorizontalLoading from '../../components/HorizontalLoading'
import { Spin } from 'antd';

const formRef = createRef()
export default function Covid19Policy() {
  const banner = useSelector((state) => state.component.banner)
  // const formData = useSelector(state => state.information.form_data.covid19_policy)
  const [formData, setFormData] = useState(null)
  // const formValue = useSelector(state => state.information.property.policies_and_rules.covid_19_policy)
  const [formValue, setFormValue] = useState(null)
  const [allOptions, setallOptions] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) {
      fatchFormData()
      dispatch(
        setBanner({
          header: 'Your Space: Covid-19 Policy',
          discription:
            'Add your cleaning protocol and additional health and safety measures to your listing page(s)',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: 25 * 3,
          },
          pages: {
            visible: true,
            total_page: 3,
            page_number: 4,
          },
          callBack: () => {
            fatchFormData()
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let fatchFormData = () => {
    dispatch(
      setInformation({
        callBack: (response) => {
          let value = response.data.property.policies_and_rules.covid_19_policy

          setFormValue(
            response.data.property.policies_and_rules.covid_19_policy,
          )
          setFormData(response.data.form_data.covid19_policy)
          value &&
            Object.keys(value).map((key, index) => {
              let name = key.replaceAll(' ', '_').toLowerCase()
              if (name != 'cleaning_process') {
                value[key].map((value) => {
                  let checkBoxName = value.title
                    .replaceAll(' ', '_')
                    .toLowerCase()
                  formRef.current.elements[checkBoxName].checked = true
                })
              }

              if (name == 'cleaning_process')
                formRef.current.elements[name].value = value[key]
            })
        },
      }),
    )
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let form = e.target.elements
    let requiredData = {}
    requiredData.cleaning_process = e.target.elements.cleaning_process.value
    console.log(requiredData)
    Object.keys(formData).map((key, index) => {
      let name = key.replaceAll(' ', '_').toLowerCase()
      requiredData[name] = []
      for (const checkbox in form[name]) {
        if (form[name][checkbox].checked) {
          requiredData[name].push(form[name][checkbox].value)
        }
      }
    })

    dispatch(
      updateInformation({
        covid_19_policy: { ...requiredData },
        callBack: (response) => {
          navigate(`/space/policy-and-ruls/guest-requirement`)
        },
      }),
    )
  }

  return (
    <div className="space-setup-inserte-section \" style={{height: 'calc(100vh - 331px)', overflow: 'scroll'}}>
      <form
        onSubmit={handleSubmit}
        ref={formRef}
        className="policy-and-ruls-of-space-insert-space-form"
      >
        <div className="booking-window-rules">
          <h1>What protective measure or controls have you put in place to:</h1>
          <p>Select all that apply</p>
          {
            formData &&
            Object.keys(formData).length > 0 ? Object.keys(formData).map((key, index) => {
              return (
                <div className="check-box-group-list">
                  <h1>
                    {capitalizeFirstLetter(
                      key.replaceAll('_', ' ').toLowerCase(),
                    )}
                  </h1>
                  <div className="check-boxes">
                    {formData[key].map((boxName) => {
                      return key.replaceAll('_', ' ').toLowerCase() !=
                        'emergency response plans' ? (
                        <div
                          className="check-box-group"
                          style={{ display: 'flex', alignItem: 'center' }}
                        >
                          <input
                            type="checkbox"
                            name={key}
                            id={boxName.title
                              .replaceAll(' ', '_')
                              .toLowerCase()}
                            value={boxName.id}
                          />
                          <label
                            htmlFor={boxName.title
                              .replaceAll(' ', '_')
                              .toLowerCase()}
                          >
                            {boxName.title}
                          </label>
                        </div>
                      ) : (
                        <div
                          className="check-box-group"
                          style={{ display: 'flex', alignItem: 'center' }}
                        >
                          <input
                            type="radio"
                            name={key}
                            id={boxName.title
                              .replaceAll(' ', '_')
                              .toLowerCase()}
                            value={boxName.id}
                          />
                          <label
                            htmlFor={boxName.title
                              .replaceAll(' ', '_')
                              .toLowerCase()}
                          >
                            {boxName.title}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }) : <Spin /> 
          }
        </div>
        <hr className="policy-and-ruls-of-space-insert-lasr-hr" />
        <div className="buffer-time-ruls ">
          <h1>Tell the guests about your cleaning process</h1>
          <textarea
            name="cleaning_process"
            id="cleaning_process"
            cols="30"
            rows="10"
            placeholder="Minimum 50 characters"
          ></textarea>
        </div>
        <hr
          style={{ marginTop: '66px' }}
          className="policy-and-ruls-of-space-insert-lasr-hr"
        />
        <div className="policy-and-ruls-of-space-insert-space-button-group">
          <button
            type="button"
            style={{ marginRight: '10px' }}
            onClick={() => {
              navigate(`/space/policy-and-ruls/house-rules`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="submit"> Next </button>
        </div>
      </form>
    </div>
  )
}
