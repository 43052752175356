import { SpaceActionType } from '../contants/actions-type'
const initialState = {
  main_space: {},
  spaces: {},
  space: {},
}

export default function SpaceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SpaceActionType.SET_SPACE:
      return {
        ...state,
        ...payload,
      }

    case SpaceActionType.SELECT_SPACE:
      return {
        ...state,
        ...payload,
      }

    case SpaceActionType.CREATE_SPACE:
      return {
        ...state,
        ...payload,
      }

    case SpaceActionType.UPDATE_SPACE:
      return {
        ...state,
        ...payload,
      }

    case SpaceActionType.REMOVE_SPACE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
