import { WindowBookingRoleActionType } from '../contants/actions-type'
const initialState = {}

export default function WindowBookingRoleReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case WindowBookingRoleActionType.SET_WINDOW_BOOKING_ROLE:
      return {
        ...state,
        ...payload,
      }
    case WindowBookingRoleActionType.SELECT_WINDOW_BOOKING_ROLE:
      return {
        ...state,
        ...payload,
      }
    case WindowBookingRoleActionType.CREATE_WINDOW_BOOKING_ROLE:
      return {
        ...state,
        ...payload,
      }

    case WindowBookingRoleActionType.UPDATE_WINDOW_BOOKING_ROLE:
      return {
        ...state,
        ...payload,
      }

    case WindowBookingRoleActionType.REMOVE_WINDOW_BOOKING_ROLE:
      return {
        ...state,
        ...payload,
      }

    default:
      return state
  }
}
