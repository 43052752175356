import React, { useEffect, useState, createRef, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import usaCities from '../../constant/usaCity'
import stateLists from '../../constant/stateLists'
import {
  setSpace,
  selectSpace,
  createSpace,
  updateSpace,
} from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import {
  setInformation,
  updateInformation,
} from '../../redux/actions/InformationAction'
const formRef = createRef()

const { Option, OptGroup } = Select

export default function BasicInformationInsert() {
  const banner = useSelector((state) => state.component.banner)
  const information = useSelector((state) => state.information)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const [mainSpaceID, setmainSpaceID] = useState(null)
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedState, setSelectedState] = useState(`New York`)
  const [listOfCities, setListOfCities] = useState([[]])
  const navigate = useNavigate()
  const selectRef = useRef()

  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Basics',
          discription: 'Set the policies for all of your spaces',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: 50,
          },
          pages: {
            visible: true,
            total_page: 1,
            page_number: 2,
          },
          callBack: () => { },
        }),
      )
      setFormData()
    }

    // set the default cities based
    setListOfCities(stateLists.nyStates)
  }, [loading])

  let city_list = [
    'New York City',
    'Albany',
    'Amsterdam',
    'Auburn',
    'Batavia',
    'Beacon',
    'Binghamton',
    'Buffalo',
    'Canandaigua',
    'Cohoes',
    'Corning',
    'Cortland',
    'Dunkirk',
    'Elmira',
    'Fulton',
    'Geneva',
    'Glen Cove',
    'Glens Falls',
    'Gloversville',
    'Hornell',
    'Hudson',
    'Ithaca',
    'Jamestown',
    'Johnstown',
    'Kingston',
    'Lackawanna',
    'Little Falls',
    'Lockport',
    'Long Beach',
    'Mechanicville',
    'Middletown',
    'Mount Vernon',
    'New Rochelle',
    'Newburgh',
    'Niagara Falls',
    'North Tonawanda',
    'Norwich',
    'Ogdensburg',
    'Olean',
    'Oneida',
    'Oneonta',
    'Oswego',
    'Peekskill',
    'Plattsburgh',
    'Port Jervis',
    'Poughkeepsie',
    'Rensselaer',
    'Rochester',
    'Rome',
    'Rye',
    'Salamanca',
    'Saratoga Springs',
    'Schenectady',
    'Sherrill',
    'Syracuse',
    'Tonawanda',
    'Troy',
    'Utica',
    'Watertown',
    'Watervliet',
    'White Plains',
    'Yonkers',
  ]

  let setFormData = () => {
    let form = formRef.current.elements
    dispatch(
      setInformation({
        callBack: (response) => {
          if (response.data.property != undefined) {
            form.name.value = response.data.property.basic_information.name
            form.address.value =
              response.data.property.basic_information.address
            //   form.venue_short_name.value = response.data.property.basic_information.venue_short_name
            form.entrance_address.value =
              response.data.property.basic_information.entrance_address
            form.type_of_worship.value = readyDenomination(
              response.data.property.basic_information.type_of_worship,
            )
            setSelectedCity(response.data.property.basic_information.city)
            // form.city.value = response.data.property.basic_information.city
            //   form.email.value = response.data.property.basic_information.email

            form.zip_code.value =
              response.data.property.basic_information.zip_code
            //   form.phone.value = response.data.property.basic_information.phone
          }
          setLoading(false)
        },
      }),
    )
  }

  let validateForm = (form) => {
    var address_reg = /[!@$#%^&*()_+]/g
    var entrance_address_reg = /[!@$%^&*()_+]/g
    // let phone_number_reg = /^[+1]{2}/g;
    // var phone_exprassion_check_reg = /[!@$#%^&*]/g;
    // let zip_code_reg = /[A-za-z!@#$%^&*()_+]/g;
    // let zip_code_reg = /^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/;
    let zip_code_reg = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/

    // if(!phone_number_reg.test(form.phone) || phone_exprassion_check_reg.test(form.phone) ){
    //     NotificationManager.warning(`all phone numbers should be valid us number`,'Basic Information',700)
    //     return false
    // };

    if (!zip_code_reg.test(form.zip_code)) {
      NotificationManager.warning(
        `all zip code should be valid`,
        'Basic Information',
        3000,
      )
      return false
    }

    // let phone = form.phone.replaceAll('+','').replaceAll('(','').replaceAll(')','').replaceAll('{','').replaceAll('}','').replaceAll('[','').replaceAll(']','').replaceAll('-','').replaceAll(' ','')
    // let phoneno = /^\d{11}$/;
    // let is_validate_phone = phone.match(phoneno)

    // if(!is_validate_phone){
    //     NotificationManager.warning(`all phone numbers should be valid us number`,'Basic Information',3000)
    //     return false
    // };

    if (address_reg.test(form.address)) {
      NotificationManager.warning(
        `street address cannot contain any special character`,
        'Basic Information',
        3000,
      )
      return false
    }

    if (entrance_address_reg.test(form.entrance_address)) {
      NotificationManager.warning(
        `entrance address cannot contain any special character but #`,
        'Basic Information',
        3000,
      )
      return false
    }

    if (form.name.length > 64) {
      NotificationManager.warning(
        `congregation name length cannot be greater then 64 character`,
        'Basic Information',
        3000,
      )
      return false
    }

    if (form.type_of_worship.length > 32) {
      NotificationManager.warning(
        `kind of house of worship name length cannot be greater then 32 character`,
        'Basic Information',
        3000,
      )
      return false
    }

    if (form.address.length > 32) {
      NotificationManager.warning(
        `street address leangth cannot be grater then 32 character`,
        'Basic Information',
        3000,
      )
      return false
    }

    return true
  }
  const handleChange = (value) => {
    console.log(`selected city: ${value}`)
    setSelectedCity(value)
  }
  const handleStateChange = (value) => {
    console.log(`selected state: ${value}`)
    setSelectedState(value)
    if (value === 'New York') {
      setListOfCities(stateLists.nyStates)
    } else if (value === `New Jersey`) {
      setListOfCities(stateLists.njStates)
    } else if (value === `Indiana`) {
      setListOfCities(stateLists.indianaStates)
    }
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    let formDate = {
      name: e.target.elements.name.value,
      address: e.target.elements.address.value,
      // venue_short_name:e.target.elements.venue_short_name.value,
      entrance_address: e.target.elements.entrance_address.value,
      type_of_worship: e.target.elements.type_of_worship.value,
      city: selectedCity,
      state: selectedState,
      // email:e.target.elements.email.value,
      zip_code: e.target.elements.zip_code.value,
      // phone:e.target.elements.phone.value
    }

    let is_form_validated = validateForm(formDate)
    if (is_form_validated) {
      dispatch(
        updateInformation({
          basic_information: formDate,
          callBack: (response) => {
            // console.log(response.data.data);
            NotificationManager.success(
              `${response.data.message}`,
              'Space Information',
              700,
            )
            navigate('/space/basic/location')
          },
        }),
      )
    }
  }

  // Custom filter function <Select> tag for Cities
  const filterOption = (input, option) => {
    // Check if the search text has more than one character
    if (input.length > 1) {
      // Use the previous search results
      return (
        option.props.children.toLowerCase().includes(input.toLowerCase()) ||
        option.props.value.toLowerCase().includes(input.toLowerCase())
      )
    } else {
      selectRef.current.scrollTo(0) // Just scroll top of the search result list
      // Check if the first character of the search text matches the first character of the option label or value
      return (
        option.props.children[0].toLowerCase() === input[0].toLowerCase() ||
        option.props.value[0].toLowerCase() === input[0].toLowerCase()
      )
    }
  }

  return (
    <div
      className="basic-information-inserte-section"
      style={{
        height: 'calc(100vh - 366px)',
        overflowY: 'scroll',
        paddingBottom: '10px',
      }}
    >
      <h1> Contact </h1>
      <p>
        {' '}
        Contact information will be shown only after the renters make their
        reservation{' '}
      </p>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="basic-information-inserte-form"
      >
        <div className="basic-information-input-group">
          <label for="name">Congregation Name *:</label>
          <br />
          <br />
          <input type="text" id="name" name="name" required />
        </div>
        <div className="basic-information-input-group">
          <label for="type_of_worship">
            What is your congregation’s faith denomination ? *:
          </label>
          <br />
          <br />
          <input
            type="text"
            id="type_of_worship"
            name="type_of_worship"
            required
          />
        </div>

        {/* <div className="basic-information-input-group">
                    <label for="venue_short_name">Short Venue Name For Mobile (Optional):</label><br/>
                    <input type="text" id="venue_short_name" name="venue_short_name" required />
                </div> */}

        <div className="basic-information-input-group">
          <label for="city">State *:</label>
          <br />
          <br />

          <div id={'basicsCitySelect'}>
            <Select
              onChange={handleStateChange}
              value={selectedState}
              // defaultValue={'New York'}
              style={{ width: '90%', backgroundColor: '#f3f3f3' }}
            >
              <Option value={`New York`}>{`New York`}</Option>
              <Option value={`New Jersey`}>{`New Jersey`}</Option>
              <Option value={`Indiana`}>{`Indiana`}</Option>
            </Select>
          </div>
        </div>

        <div className="basic-information-input-group">
          <label for="city">City *:</label>
          <br />
          <br />

          <div id={'basicsCitySelect'}>
            <Select
              ref={selectRef}
              showSearch
              onChange={handleChange}
              value={selectedCity}
              style={{ width: '90%', backgroundColor: '#f3f3f3' }}
              filterOption={filterOption}
            >
              {/* {Object.keys(usaCities).map((keyName, i) => (
                <OptGroup label={keyName}>
                  {Object.keys(usaCities[keyName]).map((city, city2) => {
                    return (
                      <Option
                        value={usaCities[keyName][city].name}
                        style={{ fontColor: 'red' }}
                      >
                        {usaCities[keyName][city].name}
                      </Option>
                    )
                  })}
                </OptGroup>
              ))} */}
              {listOfCities?.map((city, idx) => {
                return (
                  <Option value={city.name} style={{ fontColor: 'red' }}>
                    {city.name}
                  </Option>
                )
              })}
            </Select>
          </div>

          {/* <select name="city" id="city">
            {city_list.map((city) => {
              return <option value={city}>{city}</option>
            })}
          </select> */}

          {/* <input type="text" id="city" name="city" required /> */}
        </div>

        <div className="basic-information-input-group">
          <label for="address">Street Address *:</label>
          <br />
          <br />
          <input type="text" id="address" name="address" required />
        </div>

        <div className="basic-information-input-group">
          <label for="zip_code">Zip/Postal Code *:</label>
          <br />
          <br />
          <input
            type="text"
            id="zip_code"
            placeholder="XXXXX-XXXX"
            name="zip_code"
            required
          />
        </div>

        <div className="basic-information-input-group">
          <label for="entrance_address">Entrance Address (Optional):</label>
          <br />
          <br />
          <input type="text" id="entrance_address" name="entrance_address" />
        </div>

        {/* <div className="basic-information-input-group">
                    <label for="email">Point of Contact Email *:</label><br/>
                    <input type="text" id="email" name="email" required />
                </div> */}

        <div></div>
        {/* <div className="basic-information-input-group">
                    <label for="phone">Point of Contact Telephone *:</label><br/>
                    <input type="text" id="phone" placeholder='+1 XXX-XXX-XXXX' name="phone" required />
                </div> */}
        <div
          className="basic-information-input-group"
          style={{ gridColumn: `1/-1`, marginBottom: 20 }}
        >
          <button type="submit"> Next </button>
        </div>
      </form>
    </div>
  )
}
// readyDenomination()
function readyDenomination(string) {
  if (!string) return ''

  // Replace "_" to " "
  let response = string ? string.replace(/_/g, ' ') : ''
  // response = `test1_test2_three1`.replace(/_/g, ' ') // Hard coded testings with string

  // Create array of string
  response = response.split(' ')

  // console.log(capitalizeWords(response))

  // Capitalize each string in array
  const capitalizedWordsArr = capitalizeWords(response)

  // JOin array of string to a single string
  response = capitalizedWordsArr.join(' ')

  // console.log(response)
  return response
}

function capitalizeWords(arr) {
  return arr.map((element) => {
    return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase()
  })
}
