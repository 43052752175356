import { ConversationActionType } from '../contants/actions-type'
const initialState = {}

export default function ConversationReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case ConversationActionType.SET_CONVERSATION:
      return { ...state }

    case ConversationActionType.SELECT_CONVERSATION:
      return { ...state }

    case ConversationActionType.SEND_MESSAGE:
      return { ...state }

    default:
      return state
  }
}
