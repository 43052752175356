import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../redux/actions/AuthAction'
import People from '../icons/People'

const DropDownMenu = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const authUser = useSelector((state) => state.auth.user)
  let handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <div
      style={{ position: 'relative', border: '1px solid white', display: 'flex', alignItems: 'center' }}
    >
      {authUser.type === 'Guest' && (
        <li style={{ paddingLeft: '22px', paddingRight: 8 }}>
          <NavLink
            to={'/renter/messages'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ color: '#000', fontSize: 16 }}
          >
            Messages
          </NavLink>
        </li>
      )}
      {authUser.type === 'Guest' && (
        <li style={{ paddingLeft: '22px', paddingRight: 18 }}>
          <NavLink
            to={'/renter/mybookings'}
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ color: '#000', fontSize: 16 }}
          >
            My Bookings
          </NavLink>
        </li>
      )}
      <button
        type="button"
        onClick={() => {
          props.setShowDropdownMenu(!props.showDropdownMenu)
        }}
        className="profile-drop-down"
        style={{
          // minWidth: 250,
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: 'inherit',
        }}
      >
        <People />
        <ul
          className={`${props.showDropdownMenu ? '' : 'hide-elements'}`}
          style={{
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
            background: '#fff',
            borderRadius: 8,
            border: '1px solid #656369',
            marginTop: 40,
          }}
        >
          <li style={{ paddingLeft: '34px' }}>
            <NavLink
              to={authUser.type == 'Host' ? '/profile' : '/renter/profile'}
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ color: '#000', fontSize: 16 }}
            >
              Profile
            </NavLink>
          </li>
          <li style={{ paddingLeft: '34px' }}>
            <NavLink
              to={authUser.type == 'Host' ? '/profile-edit' : '/renter/payment'}
              className={({ isActive }) => (isActive ? 'active' : '')}
              style={{ color: '#000', fontSize: 16 }}
            >
              Payment
            </NavLink>
          </li>
          <li
            className={({ isActive }) => (isActive ? 'active' : '')}
            style={{ color: '#000', fontSize: 16 }}
            onClick={handleLogout}
          >
            Logout
          </li>
        </ul>
      </button>
    </div>
  )
}
export default DropDownMenu
