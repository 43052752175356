import React from 'react'
import { useNavigate } from 'react-router-dom'
import searchIcon from '../../img/search_icon2.svg'

function SearchOne() {
  const navigate = useNavigate()
  return (
    <li id="w-node-c7979273-319f-ea37-6f95-b3efced5292b-b3bf5a67">
      <div className="field-main" style={{ width: 'auto', overflow: 'hidden' }}>
        <input
          className="in-field"
          type="text"
          placeholder="Start your search for a space!"
          onClick={() => navigate('/renter/search')}
          style={{ width: '280px', textAlign: 'center' }}
        />

        <img
          src={searchIcon}
          alt=""
          style={{ height: 32, width: 32, marginRight: 10 }}
          onClick={() => navigate('/renter/search')}
        />
      </div>
    </li>
  )
}

export default SearchOne
