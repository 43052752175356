import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { logout, me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval'
import './../style/space.css'
import StatusBox from '../components/StatusBox'
import ArrowImage from './../img/polygon_1.png'
import PlusImage from './../img/plus_symbol.png'
import SpaceListItem from '../components/SpaceListItem'

function YourCalendarLayOut() {
  const [collapsed, setCollapsed] = useState(false)
  const banner = useSelector((state) => state.component.banner)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [usrData, setUsrData] = useState({})
  let navigate = useNavigate()
  const [rotateArroButton, setRotateArroButton] = useState(false)
  const [rotateArroButton2, setRotateArroButton2] = useState(false)
  // useEffect(() => {
  //   if(auth.user.name == null){
  //       dispatch(me())
  //   }
  // }, [])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);

  return (
    <main>
      <aside className="main-content-aside">
        <div className="space-side-nevbar">
          <ul>
            <li>
              <NavLink
                to="/calendar/overview"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/calendar/sync-settings"
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                Sync Settings
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <section className="main-content">
        <StatusBox />
        <Outlet />
      </section>
    </main>
  )
}

export default YourCalendarLayOut
