import React, { useEffect, useState } from 'react'
import { Modal, Button, Spin, message } from 'antd'
import uploadPhotoSVG from '../../img/uploadPhoto.svg'
import warnSVG from '../../img/warn.svg'
import { TopMsg } from '../Topmsg'
import axios from 'axios'
import Cookies from 'universal-cookie'

const ProfilePhotoUpload = (props) => {
  const { profilePhotoModal, setProfilePhotoModal, title } = props

  const [fileSizeExceed, setFileSizeExceed] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileContent, setFileContent] = useState(null)
  const [upLoading, setUpLoading] = useState(false)

  const handleOk = () => {
    setProfilePhotoModal(true)
  }

  const handleCancel = () => {
    setProfilePhotoModal(false)
  }

  const btnStyle = {
    border:
      fileContent && !fileSizeExceed
        ? '#6568E6 1px solid'
        : '#D9D9D9 1px solid',
    width: 445,
    height: 48,
    borderRadius: 4,
    color: fileContent && !fileSizeExceed ? '#6568E6' : '#D9D9D9',
    textAlign: 'center',
    paddingTop: 8,
    cursor: fileContent && !fileSizeExceed ? 'pointer' : 'auto',
  }
  const inputStyle = {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 443,
    height: 56,
    textIndent: 10,
    fontSize: 16,
    fontWeight: 400,
    marginTop: 20,
  }

  const baseURL = process.env.REACT_APP_BASE_URL

  const displayNone = { style: { display: 'none' } }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = async () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const imgSelector = (e) => {
    setFileName({ file: URL.createObjectURL(e.target.files[0]) })
    setFileContent(e)

    const fileSize = e.target.files[0].size / 1000
    if (fileSize > 10000) {
      setFileSizeExceed(true)
      setFileName('')
      setFileContent(null)
    } else {
      setFileSizeExceed(false)
    }
  }

  const uploadImgToServer = () => {
    if (!fileContent) return
    setUpLoading(true)
    const e = fileContent
    convertToBase64(e.target.files[0]).then(async (file) => {
      try {
        const cookie = new Cookies()
        const authData = cookie.get('jwt')
        const res = await axios.patch(
          `${baseURL}/guest/`,
          {
            image: file,
          },
          {
            headers: {
              Authorization: authData,
            },
          },
        )
        console.log(res.data)
        message.success(TopMsg('Photo updated successfully'))

        // Initial state
        setUpLoading(false)
        setFileContent(null)
        setFileName(null)
        setFileSizeExceed(false)

        props.getProfile() // To update user info at RenterProfile.js (reaquired for new phone not verified message)

        handleCancel() // close modal
      } catch (err) {
        setUpLoading(false)
        console.log(err)
        message.error(TopMsg('Photo failed to upload'))
      }
    })
  }

  return (
    <>
      <Modal
        title={title ? title : ''}
        cancelButtonProps={displayNone}
        okButtonProps={displayNone}
        okText="Send"
        visible={profilePhotoModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: '16%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 25,
          }}
          for="file-upload"
        >
          <h4 style={{ fontSize: 24, fontWeight: 500 }}>
            Add your profile photo
          </h4>
          <h6 style={{ fontSize: 14, fontWeight: 400 }}>
            Pick an image that shows your face. Hosts won’t be able to see your
            profile photo untile your reservation is confirmed.
          </h6>

          <label>
            <input
              type="file"
              id="file-upload"
              style={{ display: 'none' }}
              onChange={(e) => imgSelector(e)}
            />
            <img
              src={fileName ? fileName.file : uploadPhotoSVG}
              alt=""
              style={{
                borderRadius: '50%',
                width: 134,
                height: 134,
                objectFit: 'cover',
                margin: '10px 0',
                cursor: 'pointer',
              }}
            />
          </label>
          <div>
            {fileSizeExceed ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  margin: '40px 0',
                }}
              >
                <img src={warnSVG} alt="" style={{ marginRight: 7 }} />
                <h5 style={{ fontSize: 14, color: '#DF3416' }}>
                  This photo size exceeds 10 mb
                </h5>
              </div>
            ) : (
              <h5 style={{ margin: '40px 0', color: '#656369' }}>
                Maximum upload size is 10 mb
              </h5>
            )}
          </div>
          {upLoading ? (
            <Spin />
          ) : (
            <div
              style={{
                ...btnStyle,
              }}
              onClick={() => {
                uploadImgToServer()
              }}
            >
              <label
                style={{
                  fontSize: 16,
                  marginTop: 12,
                  cursor: fileContent && !fileSizeExceed ? 'pointer' : 'auto',
                }}
              >
                Upload a photo
              </label>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default ProfilePhotoUpload
