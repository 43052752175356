import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../hooks/useWindowDimensions'
import SearchOne from '../components/searchBoxes/SearchOne'
import DropDownMenu from '../components/menus/DropDownMenu'

const MAX_WIDTH = 1000
const NOT_SUPPORTED_MSG =
  'Venuely is currently not supported on mobile. Please sign up using your desktop device.'

export default function TermsOfUse() {
  const navigate = useNavigate()
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const authUser = useSelector((state) => state.auth.user)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [newsletterName, setNewsletterName] = useState(null)
  const [newsletterEmail, setNewsletterEmail] = useState(null)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [allActivities, setallActivities] = useState([])
  const [event, setEvent] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const { width } = useWindowDimensions()
  const [navbarVisibile, setNavbarVisibile] = useState(true)

  const userSignUp = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(false)
    setShowRegistrationForm(!showRegistrationForm)
  }
  const userSignIn = () => {
    navigate('/')
    return // temp solution
    if (width < MAX_WIDTH) {
      return message.error(NOT_SUPPORTED_MSG)
    }
    setShowLoginForm(!showLoginForm)
  }
  return (
    <>
      <div
        style={{
          boxShadow: 'rgba(128, 128, 128, 0.2) 0px 1px 10px',
          height: 80,
          transition: 'linear 0.2s all',
          backgroundColor: '#fff',
          top: 0,
          left: 0,
          position: 'sticky',
          zIndex: 10,
          opacity: navbarVisibile ? 1 : 0,
        }}
      >
        <div className="navbar__parent">
          <Link to={'/'}>
            <img
              src="/venuely.svg"
              alt=""
              style={{ width: 130, transition: 'ease-in-out 0.2s all' }}
              className="nav-logo"
            />
          </Link>
          <SearchOne />
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <h3 className="link_text" onClick={() => navigate('/become-host')}>
              Become a host
            </h3>
            <div className="right__side__links">
              {!isauthenticated ? (
                <>
                  <h3 className="link_text" onClick={userSignUp}>
                    Sign up
                  </h3>
                  <h3 className="link_text" onClick={userSignIn}>
                    Sign in
                  </h3>
                </>
              ) : (
                <DropDownMenu
                  showDropdownMenu={showDropdownMenu}
                  setShowDropdownMenu={setShowDropdownMenu}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // border: '1px solid blue',
          margin: 10,
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: '45%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'justify',
            // border: '1px solid red',
          }}
        >
          <h1>Terms of Use</h1>
          <p>
            Venuely Inc. is a nonprofit grassroots membership organization based
            in New York. We collaborate with individuals and organizations from
            faith-based institutions and the real-estate community to provide
            resources, connections and training in order to empower
            congregations in NYC to maximize and monetize their real property to
            support mission, benefit the community and continue their good work.
          </p>
          <p>
            These Terms of Use (the “Terms”) govern your access to and use of
            our websites (including www.bricksandmortals.org, www.venuely.org),
            and any mobile apps or other online or offline services we may offer
            (collectively, the “Services”), whether accessed via computer,
            mobile device or otherwise, including any electronic content,
            functionality, features and applications (collectively, “Materials”)
            offered on or through the Services. &nbsp;
          </p>
          <p>
            Depending on the circumstances, you may be allowed to access certain
            Services as a visitor (“Guest User”) or as a registered user
            (“Registered User”). Through the Services, we may provide a forum
            that enables Registered Users to (i) offer certain space or
            facilities as a “Host” (collectively, “Properties”), or (ii) share
            such Properties as a “GUEST”. &nbsp;&nbsp;
          </p>
          <p>
            WE OPERATE SOLELY AS A FORUM TO CONNECT AND FACILITATE INTERACTIONS
            BETWEEN HOSTS AND GUESTS, BUT ARE NOT A PARTY TO ANY RENTAL
            AGREEMENT OR OTHER TRANSACTION BETWEEN HOSTS AND GUESTS.
          </p>

          <h1>1. Agreement</h1>
          <p>
            PLEASE READ THESE TERMS AND OUR PRIVACY POLICY BEFORE YOU START TO
            USE OUR SERVICES. To the extent you are acting in your individual
            capacity, you represent that you are of legal age to form a binding
            contract with us and agree to be bound and abide by our Terms. To
            the extent you are registering an account or otherwise acting on
            behalf of an organization, you represent that you are authorized to
            accept our Terms on behalf of the organization, and accept that the
            organization shall be bound and abide by our Terms (and all
            references to “you” or “your” in our Terms are understood to apply
            to both you and your organization). If you or your organization
            entered into a separate executed agreement with us, the terms of
            that agreement will control in the event of any conflict.
          </p>
          <p>
            If you do not agree to the Terms, or if you violate them in any way,
            your right to access or use the Services is terminated.&nbsp;We
            reserve the right, in our sole discretion, to modify, alter or
            otherwise update the Terms at any time, and by using the Services
            after the posting of a modification, you accept the changes.&nbsp;We
            retain the right in our sole discretion to deny access to the
            Services to anyone at any time and for any reason, including, but
            not limited to, for violation of the Terms. PLEASE SEE SECTIONS
            12-14 BELOW REGARDING YOUR LEGAL RIGHTS IN ANY DISPUTE INVOLVING OUR
            SERVICES, INCLUDING A WAIVER TO BRING ANY CLAIM UNDER A CLASS
            ACTION.
          </p>
          <h1>2. Your Responsibilities</h1>
          <p>
            You are responsible for any activity that occurs through your
            account and you agree you will not sell, transfer, license or assign
            your account or any account rights. You may not create or use an
            account as a Registered User for anyone other than yourself except
            with their express authorization. All information you provide upon
            registration and at all other times must be true, accurate, current
            and complete and you agree to update your information as necessary
            to maintain it accordingly. You are responsible for keeping your
            password for the Services (if any) secret and secure. You are solely
            responsible for your interaction with other users of the Services,
            whether online or offline. You agree that we are not responsible or
            liable for the conduct of any user of the Services. We reserve the
            right, but have no obligation, to monitor or become involved in
            disputes between you and other users of the Services. Please
            exercise common sense and your best judgment when interacting with
            others, including when you submit or post User Content (described
            below) or any personal or other information.
          </p>
          <p>
            You must follow any other guidelines or directions we provide
            through the Services, such as in regard to postings, pricing or
            other Host/GUEST obligations.
          </p>

          <h1>3. Property Rentals</h1>
          <h2>1. Our Relationship</h2>
          <p>
            As noted near the top of these Terms, we operate solely as a forum
            to connect and facilitate interactions between Hosts and GUESTs, but
            are not a party to any rental agreement or other transaction between
            Hosts and GUESTs.&nbsp;
          </p>
          <p>
            We have no control over the accuracy or correctness of the content
            or information provided or used by Registered Users through the
            Services. Any part of an actual or potential transaction between a
            Host and GUEST, including the condition, quality, safety or legality
            of Properties advertised, the truth or accuracy of the listings
            (including the content thereof or any review related thereto), the
            ability of Hosts to share a Property to a GUEST, or the ability of
            GUESTs to pay for or use Properties are solely the responsibility of
            Hosts and GUESTs, as applicable. You acknowledge and agree to take
            all reasonable precautions in ensuring the accuracy and reliability
            of your User Content.&nbsp;
          </p>
          <p>
            We are not responsible for the condition of any Properties listed
            through the Services or the compliance with applicable laws, rules
            or regulations that may be applicable to the Properties or rentals
            thereof. &nbsp;Hosts and GUESTs are solely responsible for their
            respective compliance with any legal and/or safety requirements
            applicable to the Properties. &nbsp;Hosts are responsible for
            determining if renting a Property is permissible and, if so, for
            providing their Property in a safe and sanitary condition to GUESTs.
            GUESTs are responsible for vacating any rented Property in a safe
            and sanitary condition.
          </p>
          <p>
            As a condition of your use of the Services, you expressly represent
            and warrant that: (i) you possess the legal authority to create a
            binding legal obligation with respect to any terms you enter into
            concerning a Property offered through the Services; (ii) you will
            only use the Services to post or view, as applicable, available
            Properties or transact in Property rentals for yourself or for
            another person or entity for whom you are legally authorized to act;
            (iii) if you are offering or booking a Property rental on behalf of
            another person or entity, you are individually responsible for
            ensuring that such they are a party to the separate agreement for
            the Property rental; (iv) if you are listing a Property for rental,
            you are either the Property owner or otherwise authorized to share
            the Property.&nbsp;
          </p>
          <p>
            As a user of the Services, you acknowledge and agree that you may be
            required to enter into a separate agreement and/or waiver directly
            between any other party to a transaction you enter into (e.g., GUEST
            with Host, GUEST with Insurer), prior to renting any Property or
            purchasing a product or service, and such agreement and/or waiver
            may place additional restrictions on your rental, product or
            service. Any separate agreements and/or waivers relating to the
            rental of a Property or related products or services are under the
            sole control of the parties to such agreements and/or waivers and
            not us, but shall not contradict or conflict with these Terms.
          </p>
          <p>
            If you are a prospective or actual Host, you acknowledge and agree
            that you are solely responsible for ensuring compliance with all
            laws, rules and regulations applicable to the Properties you offer
            through the Services and any rentals thereof.
          </p>
          <h2>2. Insurance Offers</h2>
          <p>
            We are not a party to any contract for insurance facilitated through
            any link to a third party insurance provider advertising on the
            Services, and are not acting on behalf of any insurer, any broker or
            agent, any insured or any claimant in any contract for insurance
            facilitated through such link. Insurance coverage may be provided
            through a third-party independent insurance producer not affiliated
            with us. We do not act as a broker, agent or consultant in the sale
            of insurance, are not endorsing or recommending any particular
            insurer or terms of coverage, and are merely providing a link for
            you, if you wish, to transact a contract for insurance with a
            third-party provider. Any contract for insurance is solely between
            you, as the insured, and such insurer as you and the third-party
            insurance provider agree shall act as the insurer. We have no
            control over and make no representations regarding the coverage of
            any insurance you may purchase from a third-party insurance
            provider. You acknowledge and agree that we assume no responsibility
            or liability for any claims or disputes arising from, or related to,
            a contract for insurance facilitated through such link. You also
            acknowledge and agree that we shall not be included as a party to
            any such claims or disputes.
          </p>
          <h2>3. Payment Services&nbsp;</h2>
          <p>Use of the Services is free for Guest Users and GUESTs.&nbsp;</p>
          <p>
            While we are not a party to any agreement between you and other
            Registered Users or third party providers (e.g., Hosts, GUESTs,
            insurance providers or other third parties), you acknowledge and
            agree that we may act as the payment agent for rentals, insurance
            policies or other third party services made available to you through
            the Services.&nbsp;
          </p>
          <p>
            Your provision of financial information to us for purposes of
            payment is governed by these Terms including our Privacy Policy. To
            make a payment through the Services, you must register an account,
            and provide payment authorizations for purchases consistent with
            these Terms, as well as any other terms governing the services you
            are receiving. You agree to provide all requested information, such
            as a credit card or other payment information to be used for
            pre-authorization/reserve and payment/capture for purchased
            services. Payment used for a purchase will be transmitted by us
            directly to the intended party (e.g., Host or other third party
            provider). By providing your credit card or other payment
            information to us, you agree that we are authorized to charge your
            card or other payment provider with the total cost of the purchase
            (e.g., rental, taxes and any required deposit, as described below).
            We may use a service provider to provide payment processing services
            including to store your credit card information to facilitate future
            payments as described herein and you expressly authorize such
            storage.&nbsp;
          </p>
          <p>
            Unless otherwise agreed by us in writing in our discretion, we will
            deduct a service charge for facilitating a transaction through the
            Services (the “Services Charge”). For rentals, the Services Charge
            is 7.5% of the total amount charged to the GUEST (i.e., the booking
            and any additional expenses or taxes we are required to collect),
            and the remainder of the GUEST’s payment will be transmitted to the
            Host. A Services Charge may be applied to other third party services
            offered through the Services, as agreed upon between us and the
            applicable provider.
          </p>
          <p>
            <br />
          </p>
          <p>
            We reserve the right to round up amounts to the nearest whole dollar
            (for example, $202.60 will be rounded to $203). We will process all
            charges in US dollars. We may adjust the Services Charge for
            Property rentals at any time, provided that any such adjustment will
            be effective the next time you book a rental after such fee change
            goes into effect (i.e., rentals already booked will be subject to
            the Services Charge in effect at the time of booking). As a Host or
            other service provider using the Services, you agree that you will
            not try to circumvent us by any means, which includes but is not
            limited to receiving payment from GUESTs outside of the Services to
            avoid paying the Services Charge. Any attempt to circumvent the
            Services Charge will result in account suspension and removal from
            the Services. We may correct any errors or mistakes in pricing, even
            if we have already requested or received payment. We may refuse any
            transaction placed through the Services.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <h2>4. Payments to Hosts.&nbsp;</h2>
          <p>
            Payment to Hosts for accepted rentals will be made net 30 days or
            sooner from the date we receive payment. If, after the Host has been
            paid any portion of the accepted booking by us, an accepted booking
            is cancelled or terminated for any reason by either Host or GUEST
            before the reservation is completed, Host shall return to us all
            sums advanced by us for such reservation immediately (within five
            (5) days) upon receiving an email notice. Host agrees that we are
            not liable for any damages Host may incur as a result of such
            cancellation and/or having to return such funds. If Host fails to
            timely refund said sums, we are entitled to interest on said sums at
            the rate of 18% per annum, or the highest rate allowed by law,
            whichever is lower, and all of our fees incurred to recover such
            funds.
          </p>
          <p>
            If a Host does not respond to a booking inquiry, we reserve the
            right to transfer the booking and associated payment to another Host
            on a GUEST’s behalf. We reserve the right to hold payment to a Host
            if we suspect there has been fraud or other suspicious activity in
            connection with the rental or payment. Unacceptable payment methods
            that may be posted on the Services may result in the immediate
            removal of the non-conforming listing from the Services without
            notice to the Host and without refund. Unacceptable payment methods
            are those methods that may pose a risk of fraud, identify theft, or
            breach of privacy.
          </p>
          <p>
            ANY DIRECT OR INDIRECT EXCHANGE OF MONEY BETWEEN HOSTS AND GUESTS
            OTHER THAN PAYMENT THROUGH US (EXCLUDING APPLICABLE TAXES) IS A
            VIOLATION OF THESE TERMS.
          </p>
          <h2>5. Taxes.&nbsp;</h2>
          <p>
            The rental/booking fee and any additional expenses may be subject to
            applicable local, federal and state taxes. We may charge and collect
            all applicable taxes we are required to collect and which are
            imposed by a governmental authority on any transactions we process.
            Nevertheless, it is the sole responsibility of the Host or other
            party receiving funds from us to collect and remit any taxes that we
            do not charge and collect. &nbsp;To the extent applicable taxes are
            charged and collected from the GUEST by us, the Host should not
            charge and collect the same amount of such taxes from the
            GUEST.&nbsp;
          </p>
          <h2>6. No Cancellations or Refunds.&nbsp;</h2>
          <p>
            Rentals and other transactions we facilitate may not be cancelled or
            refunded except where expressly agreed upon by the parties (e.g.,
            Host and GUEST), such as in a rental agreement, or where
            cancellation and/or refund is required by applicable law.
          </p>
          <p>
            If for any reason a rental or other transaction is canceled, GUEST
            shall be refunded all sums paid to us for the rental, other than the
            Transaction Fee and any taxes remitted to a taxing authority prior
            to the cancellation.
          </p>
          <h2>7. Release and Indemnity</h2>
          <p>
            IN THE EVENT OF A DISPUTE BETWEEN A HOST AND A GUEST, THE HOST AND
            GUEST, JOINTLY AND SEVERALLY, HEREBY AGREE TO INDEMNIFY, RELEASE,
            DEFEND AND HOLD HARMLESS US&nbsp;ALONG WITH OUR&nbsp;AFFILIATES,
            DIRECTORS, OFFICERS, AGENTS, EMPLOYEES AND SERVICE PROVIDERS
            (COLLECTIVELY, THE “B&amp;M PARTIES”) FROM ANY CLAIMS, CAUSES OF
            ACTION, DEMANDS, RECOVERIES, LOSSES, DAMAGES (ACTUAL AND
            CONSEQUENTIAL), FINES, PENALTIES AND OTHER COSTS OR EXPENSES, OF ANY
            KIND OR NATURE, INCLUDING BUT NOT LIMITED TO REASONABLE LEGAL AND
            ACCOUNTING FEES (COLLECTIVELY, THE “CLAIMS”), KNOWN AND UNKNOWN,
            ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES,
            INCLUDING, WITHOUT LIMITATION, DISPUTES ARISING OVER BREACH OF THIS
            AGREEMENT, BREACH OF ANY SEPARATE AGREEMENT OR TRANSACTION, AND/OR
            THE PROPERTIES. FURTHER, HOST AND GUEST EACH HEREBY AGREE TO
            INDEMNIFY, RELEASE, DEFEND AND HOLD HARMLESS THE B&amp;M PARTIES
            FROM ANY CLAIMS BROUGHT AS A RESULT OF A HOST AND/OR GUEST (1)
            BREACH OF THE TERMS, (2) VIOLATION OF ANY LAW OR THE RIGHTS OF A
            THIRD PARTY, AND/OR (3) USE OF THIS WEBSITE AND THE SERVICES.
          </p>
          <h1>4. User Conduct and Restrictions</h1>
          <p>
            You agree not to: (a) decompile, reverse engineer, disassemble,
            modify, reduce the Services to human readable form or create
            derivative works based upon the Services or any part thereof; (b)
            disable any licensing or control features of the Services; (c)
            introduce into the Services any virus or other code or routine
            intended to disrupt or damage the Services, or alter, damage or
            delete any Materials , or retrieve or record information about the
            Services or its users; (d) merge the Services or Materials with
            another program or create derivative works based on the Services or
            Materials; (e) remove, obscure, or alter any notice of the copyright
            or other proprietary legends on the Services or Materials; (f)
            sublicense, assign, translate, rent, lease, lend, resell for profit,
            distribute or otherwise assign or transfer the Materials or access
            to the Services to others; (g) use, or allow the use of, the
            Services or the Materials in violation of any applicable laws or
            regulations; (h) otherwise act in a fraudulent, illegal, misleading,
            malicious or negligent manner when using the Services; (i) post
            violent, defamatory, indecent, sexually explicit, discriminatory,
            unlawful, infringing, hateful or other inappropriate photos or other
            content, including any posts intended for defaming, stalking,
            bullying, abusing, harassing, threatening, impersonating, harming,
            impersonating or intimidating people or entities; (j) create,
            solicit, transmit, or procure the sending of, any unwanted,
            unsolicited or harassing comments or communications, including
            advertising or promotional material, without our prior written
            consent, including any “junk mail,” “chain letter,” “spam” or any
            other similar solicitation; (k) access or use the Services by means
            of any automated program, expert system, electronic agent or “bot”;
            (l) give any other person or entity unauthorized access to the
            Services or (m) scrape, copy, republish, license, or sell the
            information or Materials on the Services. We may pursue legal action
            and/or report to law enforcement for any such violations.
          </p>
          <h1>5. Privacy</h1>
          <p>
            Personal information collected by us in connection with the Services
            will be maintained in accordance with our posted Privacy Policy,
            which is incorporated herein by reference.
          </p>
          <h1>6. User Content&nbsp;</h1>
          <p>
            The Services we provide may contain venue postings, forums and other
            features (collectively, “Interactive Services”) that allow users to
            post, submit, publish, display, or transmit to other users or other
            persons (hereinafter, “post”) information, images, links and other
            content or materials (collectively, “User Content”) on or through
            the Services. You represent and warrant that: (a) your User Content
            complies with these Terms; (b) you own or control all rights to the
            User Content posted by you through the Services or otherwise have
            the right to grant the rights and licenses set forth in these Terms;
            (c) You will pay for all royalties, fees, and any other monies owed
            by reason of User Content you post through the Services; (d) You
            understand and acknowledge that you are responsible for any User
            Content you submit or contribute, and you, not us, have fully
            responsibility for such materials, including its legality,
            reliability, accuracy, and appropriateness; and (e) we are not
            responsible or liable to any third party for the content or accuracy
            of any User Content posted by you or any other user of the Services.
            We are not a backup service and you agree that you will not rely on
            the Services for the purposes of storing User Content. We will not
            be liable to you or third-party beneficiaries for any modification,
            suspension, or discontinuation of the Services, or the loss of any
            User Content.
          </p>
          <p>
            User Content may include reviews or ratings we permit to be
            submitted through the Services. When posting a review or rating, you
            must: (1) comply with these Terms (for example, see the Prohibited
            Actions above); (2) have firsthand experience with the entity and/or
            product being reviewed; (3) not be affiliated with any competitor if
            posting negative reviews; (4) not make any conclusions as to the
            legality of any conduct; and (5) not organize a campaign encouraging
            others to post reviews, whether positive or negative. We may accept,
            reject, or remove reviews in our sole discretion.&nbsp;
          </p>
          <p>
            You hereby grant us a non-exclusive, fully paid and royalty-free,
            transferable, sub-licensable, worldwide license to use, reproduce,
            modify, perform, display, distribute, and otherwise disclose to
            third parties the User Content that you post through the Services or
            otherwise provide to us, subject to our Privacy Policy. User Content
            will be considered non-confidential and non-proprietary.
            Furthermore, our Services may be subject to a compromise of security
            and the submission of User Content or other information you provide
            may not be secure. It is in our sole discretion to share, reproduce,
            publish, or post through the Services any User Content submitted by
            you or on your behalf.&nbsp;
          </p>
          <p>
            We do not accept unsolicited materials or ideas, and take no
            responsibility for any materials or ideas so transmitted. If you
            choose to send us content, information, ideas, suggestions, or other
            materials, you agree that we are free to use any such content,
            information, ideas, suggestions or other materials, for any purposes
            whatsoever, including, without limitation, developing and marketing
            products and services, without any liability or payment of any kind
            to you.&nbsp;
          </p>
          <h1>
            7. Reporting Copyright Infringement and Other Violations With
            Respect to the Services&nbsp;
          </h1>
          <p>
            We respect the intellectual property rights of others, and we
            prohibit users of our Services from submitting, uploading, posting
            or otherwise transmitting any materials that violate another
            person’s intellectual property rights or these Terms. For
            allegations of copyright infringement, please follow the
            instructions below; otherwise, you can report any issues to us via
            the contact information provided at the end of these Terms. The
            remaining information in this section is provided exclusively for
            notifying us that your copyrighted material may have been
            infringed.&nbsp;
          </p>
          <p>
            Pursuant to 17 U.S.C. 512(c)(2), written notification must be
            submitted in writing to the Designated Agent as follows:&nbsp;
          </p>
          <p>Attn: DMCA Agent</p>
          <p>Subject: Notification of Claimed Infringement&nbsp;</p>
          <p>
            By mail to: Bricks and Mortals, 239 Thompson Street, New York, NY
            10012
          </p>
          <p>
            Or via email to:&nbsp;
            <a href="mailto:hello@venuely.org">hello@venuely.org</a>
            &nbsp; &nbsp;&nbsp;
          </p>
          <p>
            To be effective, the notification of copyright infringement must
            include the following: (1) A physical or electronic signature of a
            person authorized to act on behalf of the owner of an exclusive
            right that is allegedly infringed; (2) Identification of the
            copyrighted work claimed to have been infringed, or if multiple
            copyrighted works at a single online site are covered by a single
            notification, a representative list of such works at that site; (3)
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit the service provider to locate the material;
            (4) Information reasonably sufficient to permit the service provider
            to contact the complaining party, such as an address, telephone
            number, and if available, an electronic mail address at which the
            complaining party may be contacted; (5) A statement that the
            complaining party has a good faith belief that use of the material
            in the manner complained of is not authorized by the copyright
            owner, its agent, or the law; and (6) A statement that the
            information in the notification is accurate, and under penalty of
            perjury, that the complaining party is authorized to act on behalf
            of the owner of an exclusive right that is allegedly
            infringed.&nbsp;
          </p>
          <h1>8. Our Intellectual Property Rights</h1>
          <p>
            We grant you a limited, non-exclusive, non-sublicensable,
            non-transferable, revocable license to access and use the Services
            for lawful purposes in accordance with these Terms. The Services
            contain Materials owned or licensed by us, including name, logo,
            text, images, audio/visual works, icons and scripts and other
            materials provided on or through the Services. Except as provided
            herein, none of the Materials may be copied, distributed, displayed,
            downloaded, or transmitted in any form or by any means without prior
            written permission from us or the third party owner. Unauthorized
            use of any Materials provided by the Services may violate copyright
            laws, trademark laws, the laws of privacy and publicity, and/or
            other regulations and statutes. If you believe that any of the
            Materials infringe on any of your intellectual property rights,
            please contact us immediately via the contact information provided
            below. Except as expressly provided herein, we and our third party
            licensors reserve all rights with respect to the Services and
            Materials. &nbsp;We may pursue legal action under applicable laws
            and/or report to law enforcement for any violations, any we may
            terminate the account of any user believed to violate our Terms. We
            may also disable any social media features and any links at any time
            without notice in our sole discretion.&nbsp;
          </p>
          <p>
            Trademarks and service marks that may be referred to in the Services
            are our property or their respective owners. Nothing in the Services
            should be construed as granting, by implication, estoppel, or
            otherwise, any license or right to use any trademark without our
            written permission. You are not authorized to use our company and
            brand names and logos without express written permission, except to
            the extent otherwise permitted by law.&nbsp;
          </p>
          <h1>9. Materials&nbsp;</h1>
          <p>
            We may update the content in our Services and Materials from time to
            time, but it will not necessarily be complete or up-to-date. The
            Services may be supported by advertising revenue and may display
            advertisements and promotions, and you agree that we may place such
            advertising and promotions through the Services or on, about, or in
            conjunction with User Content. The manner, mode and extent of such
            advertising and promotions are subject to change without specific
            notice. You acknowledge that we may not always identify promoted
            services or content, or commercial communications as such. Although
            it is our intention for the Services to be available as much as
            possible, there may be occasions when the Services may be
            interrupted, including, without limitation, for scheduled
            maintenance or upgrades, for emergency repairs, or due to failure of
            telecommunications links and/or equipment. We may remove any content
            from the Services for any reason, without prior notice. Content
            removed from the Services may continue to be stored by us ,
            including, without limitation, in order to comply with certain legal
            obligations, but may not be retrievable without a valid court order
            or similar legal process.&nbsp;
          </p>
          <h1>10. Links in the Services&nbsp;</h1>
          <p>
            Although we may control some of the hyperlinks in the Services,
            other links within the Services may lead to third-party sites. We
            include these third-party links solely as a convenience to you. The
            presence of a link does not imply an endorsement of the linked site,
            its operator, or its contents, or that we are in any way affiliated
            with the linked site. The Services do not incorporate any materials
            appearing in such linked sites by reference. We reserve the right to
            terminate a link to a third party web site at any time. The third
            party sites are not controlled by us, and may have different terms
            of use and privacy policies, which we encourage you to review.
          </p>
          <h1>11. Linking to the Services</h1>
          <p>
            You may link to our website, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it, but you must not establish a link in such a way as to suggest
            any form of association, approval, or endorsement on our part
            without our express written consent. The Services may provide
            certain social media features that enable you to: link from your own
            or certain third-party websites to certain content on our Services;
            send communications with certain content, or links to certain
            content, using the Services; or cause limited portions of content on
            the Services to be displayed or appear to be displayed on your own
            or certain third-party websites. You may use these features solely
            as they are provided by us, and must not otherwise: establish a link
            from any website that is not owned by you; cause the Services or
            portions of it to be displayed on, or appear to be displayed by, any
            other site (for example, scraping, framing, deep linking, or in-line
            linking); or take any action with respect to the Services that is
            inconsistent with these Terms. We may disable any social media
            features and any links at any time without notice in our sole
            discretion.
          </p>
          <h1>12. Disclaimer of Warranties; Limitation of Liability.&nbsp;</h1>
          <p>
            YOUR USE OF THE SERVICES, MATERIALS AND USER CONTENT IS AT YOUR OWN
            RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE
            SERVICES AND ALL USER CONTENT AND MATERIALS THEREIN ARE PROVIDED “AS
            IS” WITHOUT A REPRESENTATION OR WARRANTY OF ANY KIND, EITHER
            EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF
            TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT
            CONTAINED ON THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
            DEFECTS WILL BE CORRECTED, OR THAT OUR SERVERS ARE FREE OF VIRUSES
            OR OTHER HARMFUL COMPONENTS THROUGH USE OR DOWNLOADING MATERIAL FROM
            THE SERVICES. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY
            DIRECT OR INDIRECT, ACTUAL, SPECIAL, PUNITIVE, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES THAT MAY ARISE FROM THE SERVICES. BY ACCESSING
            THE SERVICES, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH
            RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CERTAIN DAMAGES, AND IN SUCH JURISDICTIONS OUR LIABILITY IS
            LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.&nbsp;
          </p>
          <p>
            WE&nbsp;DO NOT ENDORSE, APPROVE OR VERIFY ANY POSTED USER CONTENT ON
            THE&nbsp;SERVICES&nbsp;AND&nbsp;WE SHALL NOT BE RESPONSIBLE OR
            LIABLE WHATSOEVER IN ANY MANNER FOR ANY USER CONTENT POSTED ON THE
            SERVICES (INCLUDING CLAIMS OF INFRINGEMENT RELATING
            TO&nbsp;USER&nbsp;CONTENT POSTED ON THE SERVICES, FOR YOUR USE OF
            THE SERVICES, OR FOR THE CONDUCT OF USERS (INCLUDING HOSTS OR
            GUESTS) OR THIRD PARTIES WHETHER ON THE SERVICES OR RELATING TO THE
            SERVICES.
          </p>
          <p>
            If you are a consumer, the provisions in these Terms are intended to
            be only as broad and inclusive as is permitted by the laws of your
            state of residence. We reserve all rights, defenses and permissible
            limitations under the law of your state of residence.&nbsp;
          </p>
          <h1>13. Indemnification</h1>
          <p>
            You agree to indemnify us and hold us harmless from and against any
            and all loss, expenses, damages, and costs, including without
            limitation reasonable attorneys’ fees, resulting, whether directly
            or indirectly, from your violation of these Terms. You also agree to
            indemnify us and hold us harmless from and against any and all
            claims brought by third parties arising out of your use of the
            Services and the User Content you make available via the Services by
            any means, including without limitation through a posting, a link,
            reference to other content, or otherwise.
          </p>
          <h1>14. Governing Law; Arbitration and Class Action Waiver&nbsp;</h1>
          <p>
            THE LAWS OF THE STATE OF NEW YORK WILL GOVERN THESE TERMS (INCLUDING
            THE PRIVACY POLICY) AND ANY DISPUTE RELATING TO THE SERVICES,
            WITHOUT GIVING EFFECT TO ANY PRINCIPLES OF CONFLICTS OF LAWS.
            EXCLUDING DISPUTES ADDRESSED THROUGH ARBITRATION AS PROVIDED BELOW,
            YOU AGREE TO SUBMIT TO THE EXCLUSIVE JURISDICTION OF THE COMPETENT
            STATE AND FEDERAL COURTS LOCATED IN NEW YORK, NEW YORK IN RELATION
            TO ANY CLAIM, DISPUTE OR DIFFERENCE ARISING FROM THESE TERMS, AND
            YOU AGREE TO WAIVE ANY RIGHT OF REMOVAL OR TRANSFER WHETHER DUE TO
            FORUM NON CONVENIENS OR OTHER REASON. The United Nations Convention
            on Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are expressly excluded
            from these Terms.
          </p>
          <p>
            Any dispute between you and us in connection with these Terms or our
            Services that cannot be resolved through informal negotiations will
            be finally and exclusively resolved by binding arbitration. YOU
            UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
            SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be
            commenced and conducted under the Commercial Arbitration Rules of
            the American Arbitration Association (“AAA”) and, where appropriate,
            the AAA’s Supplementary Procedures for Consumer Related Disputes
            (“AAA Consumer Rules”), both of which are available at the AAA
            website www.adr.org. Your arbitration fees and your share of
            arbitrator compensation shall be governed by the AAA Consumer Rules
            and, where appropriate, limited by the AAA Consumer Rules. The
            arbitration may be conducted in person, through the submission of
            documents, by phone, or online. The arbitrator will make a decision
            in writing, but need not provide a statement of reasons unless
            requested by either party. The arbitrator must follow applicable
            law, and any award may be challenged if the arbitrator fails to do
            so. Except where otherwise required by the applicable AAA rules or
            applicable law, the arbitration will take place in your state of
            residence or New York, New York. Except as otherwise provided
            herein, the parties may litigate in court to compel arbitration,
            stay proceedings pending arbitration, or to confirm, modify, vacate,
            or enter judgment on the award entered by the arbitrator, as well as
            to seek non-monetary injunctive or other equitable relief.
          </p>
          <p>
            EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, YOU AGREE THAT
            EACH PARTY TO A DISPUTE HEREUNDER MAY BRING CLAIMS AGAINST THE OTHER
            ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless
            both you and we agree, no arbitrator or judge may consolidate more
            than one person’s claims or otherwise preside over any form of a
            representative or class proceeding.
          </p>
          <h1>15. Services Controlled from United States&nbsp;</h1>
          <p>
            Our Services are operated from the United States. We make no
            representation that content or materials in the Services are
            appropriate or available for use in other jurisdictions. Access to
            any of the Services from jurisdictions where such access is illegal
            is strictly prohibited. If you choose to access the Services from
            other jurisdictions, you do so at your own risk. You are always
            responsible for your compliance with applicable laws.&nbsp;
          </p>
          <h1>16. Entire Agreement; Severability&nbsp;</h1>
          <p>
            You agree that these Terms (including our Privacy Policy which is
            incorporated by reference) constitute the complete and exclusive
            statement of the agreement between you and us and supersedes all
            other proposals or prior agreements oral or written, and any other
            communications relating to the subject matter of these Terms. If any
            provision of these Terms is found unenforceable, it shall not affect
            the validity of the remainder of these Terms, which shall remain
            valid and enforceable according to its terms, and the invalid or
            unenforceable provision will be deemed superseded by a valid,
            enforceable provision that most closely matches the intent of the
            original provision and the remainder of the agreement shall continue
            in effect. You agree that no joint venture, partnership, employment,
            or agency relationship exists between you and us as a result of
            these Terms or your use of the Services.&nbsp;
          </p>
          <h1>17. Contacting Us&nbsp;</h1>
          <p>
            If you have any questions, concerns or comments about these Terms or
            our Services, or if you would like us to update information or
            preferences you provided to us, please contact us by mail to: Bricks
            and Mortals, 239 Thompson Street, New York, NY 10012; via email
            to&nbsp;
            <a href="mailto:hello@venuely.org">hello@venuely.org</a> or by phone
            at (218) 389-1055‬.&nbsp;
          </p>
          <p>These Terms were last updated August 1st, 2022.</p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>{' '}
    </>
  )
}
