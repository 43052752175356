import React, { useState } from 'react'
import arrow_down_1 from './../../img/arrow_down_1.png'

export default function TakePhoneNumber({ data, setData, setStepper }) {
  const [Region, setRegion] = useState('United States (+1)')
  let handleSubmit = (e) => {
    e.preventDefault()
    setData({
      ...data,
      phone: e.target.elements.number.value,
    })
    setStepper('profilePicture')
  }
  return (
    <div classNameName="take-user-details take-user-phone-number">
      <div classNameName="take-user-phone-heading">
        <p classNameName="step-count">Step 2 of 3</p>
        <h1>Confirm your email address</h1>
        <p>Please check your email inbox, and click the following link.</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <img
            src={arrow_down_1}
            classNameName="region_option_reveal_down_errow"
            alt=""
          />
          <input
            type="text"
            id="region"
            name="region"
            value={Region}
            required
          />
          <label htmlFor="region">Region/Country</label>
        </div>
        <div className="input-field">
          <input type="text" id="number" name="number" required />
          <label htmlFor="number">Phone Number</label>
        </div>
        <p>
          We’ll call or text you to confirm your number. Standard message and
          data rates apply.
        </p>
        <button type="submit" classNameName="take-user-phone-number-submit-button">
          Continue
        </button>
        <div classNameName="confirm-email-later">I’ll do this later</div>
      </form>
    </div>
  )
}
