import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../../redux/actions/ComponentAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import pentagon from './../../../img/pentagon.png'
import dots from './../../../img/dot.png'
import {
  setInformation,
  updateOrCreatePaymentInformation,
} from '../../../redux/actions/InformationAction'
import { useNavigate } from 'react-router-dom'
export default function AddBankAccrountDetails() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [bankDetails, setBankDetails] = useState([])
  const [showHeader, setShowHeader] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Today',
          discription: 'Earnings & Payout Summary',
          button: {
            visible: true,
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            dispatch(
              setInformation({
                callBack: (response) => {
                  dispatch(
                    setInformation({
                      callBack: (response) => {
                        setPaymentMethod(response.data.payment_information)
                        let bankDetails = []
                        response.data.payment_information.map((method) => {
                          if (method.payment_method_type == 'BANK')
                            bankDetails.push(method)
                        })
                        setBankDetails(bankDetails)
                      },
                    }),
                  )
                },
              }),
            )
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let handleSubmit = (e) => {
    e.preventDefault()
    setShowLoader(true)
    let elements = Array.from(e.target.elements)
    let values = {
      payment_method_type: 'BANK',
      country: 'us',
      currency: 'usd',
    }
    for (let key in elements) {
      if (elements[key].name.length > 0) {
        values[elements[key].name] = elements[key].value
      }
    }
    dispatch(
      updateOrCreatePaymentInformation({
        ...values,
        callBack: (response) => {
          setShowLoader(false)
          if (response.status == 200) {
            NotificationManager.success(
              `${response.data[0]}`,
              'bank details',
              3000,
            )
            setLoading(true)
            navigate(-1)
          }

          if (response.status != 200) {
            let message = response.data[0].split(': ')
            NotificationManager.warning(`${message[1]}`, 'bank details', 3000)
            setLoading(true)
          }
          // console.log(response);
        },
      }),
    )
  }

  return (
    <div className="payout-details-wrapper">
      <NotificationContainer />
      {bankDetails.length > 0 ? <h1>Saved payout methods</h1> : ''}

      {paymentMethod &&
        paymentMethod.map((method, index) => {
          if (method.payment_method_type == 'BANK') {
            return (
              <div className="cash-chacking-button">
                <img src={pentagon} alt="" srcSet="" />
                <p>Chase Checking ....2410</p>
                <img src={dots} alt="" srcSet="" />
              </div>
            )
          }
        })}

      <h1>Add a bank</h1>

      <form onSubmit={handleSubmit} action="">
        <div className="payout-details-input-group">
          <label htmlFor="account_number">ACCOUNT NUMBER</label>
          <input
            required
            placeholder="xxxx - xxxx - xxxx - xxxx"
            type="text"
            id="account_number"
            name="account_number"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="routing_number">ROUTING NUMBER</label>
          <input
            required
            placeholder="xx - xxxxxxxx"
            type="text"
            id="routing_number"
            name="routing_number"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="account_holder_type">TYPE</label>
          <select
            required
            style={{
              height: '45px',
              width: '100%',
              padding: '10px',
              fontSize: '16px',
            }}
            name="account_holder_type"
            id="account_holder_type"
          >
            <option value="individual">individual</option>
            <option value="company">company</option>
          </select>
          {/* <input placeholder='checking' type="text" id='account_number'  name='account_number'/> */}
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="first_name">FIRST NAME</label>
          <input
            required
            placeholder=""
            type="text"
            id="first_name"
            name="first_name"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="last_name">LAST NAME</label>
          <input
            required
            placeholder=""
            type="text"
            id="last_name"
            name="last_name"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="billing_address">BILLING ADDRESS</label>
          <input
            placeholder=""
            type="text"
            id="billing_address"
            name="billing_address"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="state">STATE</label>
          <input placeholder="" type="text" id="state" name="state" />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="zip_code">ZIP CODE</label>
          <input placeholder="" type="text" id="zip_code" name="zip_code" />
        </div>
        <button
          disabled={showLoader}
          style={{ position: 'relative' }}
          type="submit"
        >
          {showLoader ? (
            <div
              style={{
                height: '30px',
                width: '30px',
                top: '8px',
              }}
              className="loader"
            ></div>
          ) : (
            'Save'
          )}
        </button>
      </form>
    </div>
  )
}
