import React from 'react'
// import searchImg from '../../src/img/search_error.png'
import searchImg from '../../src/img/venuely-search-logo.jpg'

const NoSpaceDescription = ({ title, para }) => {
    return (
        <div
            className="no-space-div"
            style={{
                width: 780,
                marginTop: -10,
                marginLeft: 30,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <img
                className="no-space-img"
                src={searchImg}
                alt=""
                style={{ objectFit: 'contain', width: 420, height: 'fit-content' }}
            />
            <h1
                className="no-result-text"
                style={{ textAlign: 'left', width: 500, fontSize: 18, color: "#4d4faf", lineHeight: 1.7 }}
            >
                {title}
            </h1>
            <p className="no-result-text" style={{ textAlign: 'left', fontSize: 18, fontWeight: '600', width: 600, color: "#1b7d69", marginTop: 5 }}>
                {para}
            </p>
        </div>
    )
}

export default NoSpaceDescription