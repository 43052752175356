import React, { useState, useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { registration } from '../../redux/actions/AuthAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import loader from './../../img/loading.gif'
export default function TakeUserDetails({ data, setData, setStepper }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setloading] = useState(false)

  useEffect(() => {
    // By this we are preventing the year to be more than 4 digits
    const datePickerId = document.getElementById('birth-date')
    datePickerId.max = new Date().toLocaleDateString('en-ca')
  }, [])

  let dateValidate = (input) => {
    var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/
    if (input.match(reg)) {
      return true
    } else {
      return false
    }
  }
  let underAgeValidate = (birthday) => {
    var optimizedBirthday = birthday.split('/')
    var prasentData = new Date()
    var myBirthday = new Date(
      `${optimizedBirthday[2]} ${optimizedBirthday[1]} ${optimizedBirthday[0]}`,
    )
    var Difference_In_Time = prasentData.getTime() - myBirthday.getTime()
    var Difference_In_year = Difference_In_Time / (1000 * 3600 * 24) / 365
    let is_under_aged = Difference_In_year < 18
    return is_under_aged
  }

  function validatePassword(newPassword) {
    var minNumberofChars = 6
    var maxNumberofChars = 16
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
    var passowrd_validet_regEx = /[!@#$%^&*()]/g
    if (
      newPassword.length < minNumberofChars ||
      newPassword.length > maxNumberofChars
    ) {
      NotificationManager.warning(
        `password need to be at least 6 charecter and maximum 16 charecter`,
        'Oops !',
        3000,
      )
      return false
    }
    if (passowrd_validet_regEx.test(newPassword)) {
      NotificationManager.warning(
        'password cannot have special character',
        'Oops !',
        3000,
      )
      return false
    }
    return true
  }

  let handleSubmit = async (e) => {
    e.preventDefault()
    let requestedData = {
      type: data.role == 'Renter' ? 'Guest' : 'Host',
      email: e.target.elements.email.value,
      name: e.target.elements.firstName.value + ' ' + e.target.elements.lastName.value,
      ...(e.target.elements.firstName.value &&  {first_name: e.target.elements.firstName.value}),
      ...(e.target.elements.lastName.value  &&  {last_name: e.target.elements.lastName.value}),
      password: e.target.elements.password.value,
      birth_date: e.target.elements.birth_date.value,
    }

    let password_mached =
      e.target.confirm_password.value == e.target.elements.password.value
    // let is_date_validated = dateValidate(requestedData.birth_date)
    let is_date_validated = true
    let under_aged = underAgeValidate(requestedData.birth_date)
    let is_passowrd_validated = false
    if (under_aged) {
      NotificationManager.warning(
        `you need to be at least 18`,
        'Oops !',
        3000,
      )
    }

    if (!password_mached) {
      NotificationManager.warning(`password not mached`, 'Oops !', 3000)
    }
    if (password_mached) {
      is_passowrd_validated = validatePassword(requestedData.password)
    }

    let agree_terms = e.target.agree_terms
    if (!agree_terms.checked) {
      NotificationManager.warning(
        `you need to agree out terms and conditions`,
        'Agree our terms and conditions',
        3000,
      )
    }

    if (
      password_mached &&
      is_date_validated &&
      !under_aged &&
      is_passowrd_validated &&
      agree_terms.checked
    ) {
      setloading(true)
      dispatch(
        registration({
          ...requestedData,
          callBack: (response) => {
            setloading(false)
            let is_ok = response.status == 200
            if (is_ok) {
              // setStepper('confitmEmail')
              navigate('/pending-request')
              NotificationManager.success(
                response.data.details,
                'Registration',
                700,
              )
            }
            if (!is_ok) {
              for (const [key, value] of Object.entries(response.data)) {
                if (key == 'email') {
                  NotificationManager.warning(`${value}`, 'Oops !', 3000)
                  setStepper('email')
                }
                // console.log(`${key}: ${value}`);
                // console.log(e.target.elements[key]);
              }
            }
          },
        }),
      )
    }
  }

  return (
    <div className="take-user-details">
      <div
        className={`take-user-details-loader-wrapper ${
          loading ? '' : 'hide-elements'
        }`}
      >
        <div className="loader take-user-details-loader"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <input type="text" id="firstName" required />
          <label className="input-label" htmlFor="firstName">
            First Name
          </label>
        </div>
        <div className="input-field">
          <input type="text" id="lastName" required />
          <label htmlFor="lastName">Last Name</label>
        </div>
        <p className="note-name">
          Make sure it matches the name on your government ID.
        </p>
        <div className="input-field">
          <input type="date" name="birth_date" id="birth-date" required />
          <label htmlFor="birth-date">Birth Date ( MM/DD/YYYY )</label>
        </div>
        <p className="note-birth-date">
          To sign-up, you need to be at least 18. Your birthday won’t be shared
          with other people who use House of Share.
        </p>

        <div className="input-field">
          <input
            type="email"
            id="email"
            name="email"
            value={data.email}
            required
          />
          <label htmlFor="email">Email</label>
        </div>
        <p className="note-birth-date">
          We’ll email you trip confirmations and recipts.
        </p>

        <div className="input-field">
          <input type="password" id="password" required />
          <label htmlFor="password">Password</label>
        </div>
        <p className="note-birth-date"></p>

        <div className="input-field">
          <input type="password" id="confirm_password" required />
          <label htmlFor="confirm_password">Confirm Password</label>
        </div>
        <p className="note-birth-date">
          <p>
            {' '}
            Password can not contain any special charecter and it need to be in
            between 6 and 16 charecter{' '}
          </p>
        </p>
        <p
          className="final-notes"
          style={{
            marginTop: 14,
            color: '#555',
            textAlign: 'left',
            // display: 'flex',
            // flexDirection: 'column',
            fontSize: 16,
            lineHeight: 1.5,
          }}
        >
          <div
            style={{
              display: 'inline',
              position: 'relative',
              marginRight: 20,
            }}
          >
            <input
              style={{
                position: 'absolute',
                top: -2,
                // height: 'auto',
                // width: 'auto',
                height: 16,
                width: 16,
                margin: 0,
              }}
              type="checkbox"
              name="agree_terms"
              id="agree_policy_sign_up1"
            />
          </div>
          By using Venuely or, signing up with google to Venuely, I agree to
          Venuely{' '}
          <a
            style={{
              fontSize: 14,
              color: '#000',
              textDecoration: 'underline',
              fontWeight: '500',
            }}
            href="/terms-of-use"
            target="_blank"
          >
            Terms of Service
          </a>
          , Venuely{' '}
          <a
            style={{
              fontSize: 14  ,
              color: '#000',
              textDecoration: 'underline',
              fontWeight: '500',
            }}
            href="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </p>
        <button name="submit" style={{ fontSize: 16 }}>
          continue
        </button>
      </form>
    </div>
  )
}
