import { ImageActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setImage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request.get('/host/space/image').catch((err) => {
    return err.response
  })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ImageActionType.SET_IMAGE,
    payload: {},
  })
}

export const selectImage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/image/${requiredData.id}/`)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ImageActionType.SELECT_IMAGE,
    payload: {},
  })
}

export const createImage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space/image/', requiredData)
    .catch((err) => {
      return err.response
    })
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ImageActionType.CREATE_IMAGE,
    payload: {},
  })
}

export const updateImage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/image/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ImageActionType.UPDATE_IMAGE,
    payload: {},
  })
}

export const deleteImage = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(`/host/space/image/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: ImageActionType.REMOVE_IMAGE,
    payload: {},
  })
}
