import React from 'react'
import ProfilePicture from './../../img/Profile.png'
import { useNavigate } from 'react-router-dom'

export default function TakeProfilePicture({
  data,
  setData,
  setStepper,
  closeModel,
}) {
  let navigate = useNavigate()
  let handleSubmit = (e) => {
    e.preventDefault()
    closeModel(false)
    navigate('/pending-request')
  }
  return (
    <div className="take-profile-picture">
      <p className="step-count">Step 3 of 3</p>
      <h1>Add a profile photo</h1>
      <p>
        Pick an image that shows your face. Hosts won’t be able to see your
        profile photo untile your reservation is confirmed.
      </p>
      <img src={ProfilePicture} alt="" srcSet="" />
      <div className="upload-profile-photo-button">Upload a photo</div>
      <div
        onClick={handleSubmit}
        className="confirm-email-later confirm-email-later-phto-upload"
      >
        I’ll do this later
      </div>
    </div>
  )
}
