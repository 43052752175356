import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { LoadScript,GoogleMap,Marker,InfoWindow  } from 'react-google-maps'
import {
  GeocodeResult,
  getLatLng,
  getGeocode,
  getDetails,
  getZipCode,
} from 'use-places-autocomplete'
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api'
import usePlacesAutocomplete from 'use-places-autocomplete'
// import { AutoComplete } from 'antd';
import { mapStyle, mapStyle2, WYMaps } from './style/style'
import homeIcon from './images/homeicon.png'
import { ChangeEvent } from 'react'
import pinLogo from './../../img/pin.png'
import instructionImage from './../../img/instruction.png'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import SuggestionsBoxV2 from './SuggestionsBoxV2'

const libraries = ['places']
const mapContainerStyle = {
  width: '693px',
  height: '475px',
  marginBottom: '50px',
}
const centerDefault = {
  // lat: 45.383321536272049,
  // lng: -75.3372987731628
  lat: 40.71249292662493,
  lng: -74.00650155819713,
}
const options = {
  // styles:WYMaps,
  disableDefaultUI: true,
  zoomControl: true,
}

export default function Map(props) {
  const [markers, setMarkers] = useState([])
  const [selectedMarket, setSelectedMarker] = useState(null)
  const [center, setCenter] = useState(centerDefault)
  const information = useSelector((state) => state.information)
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyDtmCQqWlHzLPURHHxMQPqs8Yp-JEl8iuw',
  //   libraries,
  // })
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })
  useEffect(() => {
    if (information.property != undefined) {
      if (
        information.property.basic_information.latitude != null &&
        information.property.basic_information.longitud != null
      ) {
        setMarkers([
          {
            lat: information.property.basic_information.latitude,
            lng: information.property.basic_information.longitud,
            time: new Date(),
          },
        ])
        setCenter({
          lat: information.property.basic_information.latitude,
          lng: information.property.basic_information.longitud,
        })
      }
    }
  }, [2])

  const pointMarkerOnMap = useCallback(
    (event) => {
      props.form.current.elements.latitude.value = event.latLng.lat()
      props.form.current.elements.longitud.value = event.latLng.lng()
      setCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
      setMarkers([
        // ...markers,
        {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          time: new Date(),
        },
      ])
    },
    [markers],
  )

  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  if (loadError) return 'error'
  if (!isLoaded) return 'map loadding...'
  const panTo = (data) => {
    mapRef.current.panTo({ lat: data.lat, lng: data.lng })
    mapRef.current.setZoom(20)

    props.form.current.elements.latitude.value = data.lat
    props.form.current.elements.longitud.value = data.lng
    props.form.current.elements.address.value = data.address
    props.form.current.elements.zip.value = data.zip

    // props.form.current.setFields([
    //     {
    //         name: `latitude`,
    //         value: data.lat
    //     },
    //     {
    //         name: `longitud`,
    //         value: data.lng
    //     },
    //     {
    //         name: `address`,
    //         value: data.address
    //     }
    // ]);

    // console.log(data);
  }

  function handleChange(lat = null, lng = null) {
    // Here, we invoke the callback with the new value
    // const newCenter = mapRef.getCenter();
    // props.onChange(newCenter);
    // console.log(centerPonit);

    let centerPonit = mapRef.current.getCenter().toJSON()
    let latitude = lat == null ? centerPonit.lat : lat
    let longitude = lng == null ? centerPonit.lng : lng
    if (latitude != null) {
      props.form.current.elements.latitude.value = latitude
    }

    if (longitude != null) {
      props.form.current.elements.longitud.value = longitude
    }

    setCenter({
      lat: latitude,
      lng: longitude,
    })

    setMarkers([
      {
        lat: latitude,
        lng: longitude,
        time: new Date(),
      },
    ])
  }

  return (
    <>
      <SuggestionsBoxV2 handleChange={handleChange} />
      {/* <SuggestionBox handleChange={handleChange} setDataMapData={panTo} /> */}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={20}
        options={options}
        onClick={pointMarkerOnMap}
        onLoad={onMapLoad}
        onDragEnd={handleChange}
      >
        {markers.map((marker) => (
          <Marker
            key={marker.time.toISOString()}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: homeIcon,
              scaledSize: new window.google.maps.Size(30, 50),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 42),
            }}
            onClick={() => {
              setSelectedMarker(marker)
            }}
          />
        ))}
        {/* {
                    selectedMarket && (
                        <InfoWindow 
                            position={{lat:selectedMarket.lat,lng:selectedMarket.lng}} 
                            onCloseClick={()=>{
                                setSelectedMarker(null)
                            }}
                        > 
                            <div>
                                <h1> hello </h1>
                            </div>
                        </InfoWindow>
                    )
                } */}
      </GoogleMap>
    </>
  )
}

function SuggestionBox(props) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete()
  const information = useSelector((state) => state.information)
  const [placeHolder, setplaceHolder] = useState('Example Address')
  useEffect(() => {
    let lat = undefined
    let lng = undefined

    if (information.property != undefined) {
      let adderss = `${information.property.basic_information.address},${information.property.basic_information.city}`

      if (information.property.basic_information != undefined) {
        lat = information.property.basic_information.latitude
        lng = information.property.basic_information.longitud
        setplaceHolder(adderss)
      }

      if (lat == undefined && lng == undefined) {
        setValue(adderss)
      }
    }
  }, [information])

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  const handleSelect = async (address) => {
    setValue(address, false)
    clearSuggestions()
    // console.log(address);
    try {
      let result = await getGeocode({ address })
      let { lat, lng } = await getLatLng(result[0])
      let zipCode = await getZipCode(result[0])
      props.handleChange(lat, lng)
      props.setDataMapData({ lat, lng, zipCode, address })
    } catch (error) {
      console.log(error)
    }
  }

  const renderSuggestions = () => {
    const suggestions = data.map(({ place_id, description }) => (
      <ComboboxOption key={place_id} value={description} />
    ))

    return (
      <>
        {suggestions}
        {/* <li className="logo">
            <img
              src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
              alt="Powered by Google"
            />
          </li> */}
      </>
    )
  }

  return (
    <>
      <Combobox
        style={{
          position: 'absolute',
          zIndex: '1000',
          top: '7rem',
          left: '15rem',
        }}
        onSelect={handleSelect}
        aria-labelledby="demo"
      >
        <div className="basic-location-search-box">
          <ComboboxInput
            id="location"
            name="location"
            placeholder={placeHolder}
            value={value}
            onChange={handleInput}
            disabled={!ready}
          />
          <img
            style={{ width: '2px', height: '4px' }}
            src={pinLogo}
            alt=""
            srcSet=""
          />
        </div>
        <img
          src={instructionImage}
          className="instructionImage"
          alt=""
          srcSet=""
        />
        <ComboboxPopover>
          <ComboboxList>{status === 'OK' && renderSuggestions()}</ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  )
}
