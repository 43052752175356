import { AuthActionType } from '../contants/actions-type'
import { data, privetData, handleErrorAndDispatch } from '../requests'
import { userUrlType } from '../contants/user-type'
import Cookies from 'universal-cookie'

export const login = (requiredData) => async (dispatch) => {
  const cookies = new Cookies()
  let request = data()
  const response = await request
    .post('/user/login/', requiredData)
    .catch((err) => {
      return err.response
    })
  if (response.status == 200)
    cookies.set('jwt', response.data.jwt, { path: '/' })
  console.log(response)

  // If its a guest, get its user profile data and store to Redux
  let guestProfileData = null
  if (response.data.type === `Guest`) {
    const responseTwo = await request
      .get('/guest', {
        headers: {
          Authorization: response.data.jwt,
        },
      })
      .catch((err) => {
        console.log(err)
      })
    guestProfileData = responseTwo.data
  }

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: AuthActionType.LOGIN,
    payload: {
      authenticated: true,
      token: response.data.jwt,
      user: {
        name: response.data.name,
        email: response.data.email,
        type: response.data.type,
        guestProfileData,
      },
    },
  })

  console.log(AuthActionType.LOGIN)
}
export const logout = () => async (dispatch) => {
  // const response = await data.get('/products').catch((err)=>{ console.log(err); })
  console.log(AuthActionType.LOGOUT)
  const cookies = new Cookies()
  cookies.remove('jwt', { path: '/' })
  dispatch({
    type: AuthActionType.LOGOUT,
    payload: {
      authenticated: false,
      token: null,
      user: {
        name: null,
        email: null,
        type: null,
      },
    },
  })
}

export const registration = (requestedData) => async (dispatch) => {
  let request = data()
  const response = await request
    .post('/user/registration/', requestedData)
    .catch((err) => {
      return err.response
    })
  const cookies = new Cookies()
  if (response.status == 200)
    await cookies.set('jwt', response.data.jwt, { path: '/' })
  console.log(AuthActionType.REGISTRATION, requestedData)
  // const cookies = new Cookies();
  // cookies.remove('jwt', { path: '/' });

  handleErrorAndDispatch(requestedData, response, dispatch, {
    type: AuthActionType.REGISTRATION,
    payload: {
      authenticated: true,
      token: response.data.jwt,
      user: {
        name: response.data.name,
        email: response.data.email,
        type: response.data.type,
      },
    },
  })
}

export const emailCheck = (requestedData) => async (dispatch) => {
  let request = data()
  const response = await request
    .put('/user/registration/', requestedData)
    .catch((err) => {
      return err.response
    })

  console.log(AuthActionType.REGISTRATION, requestedData)
  handleErrorAndDispatch(requestedData, response, dispatch, {
    type: AuthActionType.REGISTRATION,
    payload: {},
  })
}

// This function never called in "App" for getting "host" data. Used only for guest.
export const me = () => async (dispatch) => {
  let request = await privetData()
  const response = await request.get(`user/login/`).catch((err) => {
    // console.log(err.response)
  })
  // console.log(AuthActionType.ME)
  // console.log(response.data)

  // Getting guest profile data
  const responseTwo = await request.get(`/guest`).catch((err) => {
    // console.log(err.response)
  })

  handleErrorAndDispatch({}, response, dispatch, {
    type: AuthActionType.ME,
    payload: {
      user: {
        name: response.data.name,
        email: response.data.email,
        type: response.data.type,
        guestProfileData: responseTwo.data, // storing to redux, for faster use
      },
    },
  })
}

export const refresh = () => async (dispatch) => {
  const cookies = new Cookies()
  let request = await privetData()
  const response = await request.put(`user/login/`).catch((err) => {
    // console.log(err.response)
  })
  // console.log(AuthActionType.ME)

  if (response.status === 200)
    cookies.set('jwt', response.data.jwt, { path: '/' })
  handleErrorAndDispatch({}, response, dispatch, {
    type: AuthActionType.REFRESHED,
    payload: {
      authenticated: true,
      token: response.data.jwt,
      user: {
        name: response.data.name,
        email: response.data.email,
        type: response.data.type,
      },
    },
  })
}

// export const update = () => async (dispatch) => {
//     let request = await privetData()
//     const response = await request.post('/user/email/verification').catch((err)=>{ return err })
//     console.log(response);

//     // console.log(AuthActionType.UPDATE);
//     dispatch({
//         type:AuthActionType.UPDATE,
//         payload:{}
//     })
// }

export const verifyEmailAccount = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/user/email/verification', requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: AuthActionType.UPDATE,
    // payload:response.data
  })
}

export const resetPasswordEmail = (requiredData) => async (dispatch) => {
  let request = await data()
  console.log(requiredData)
  const response = await request
    .post('/user/forgot-password', requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: AuthActionType.UPDATE,
    // payload:response.data
  })
}

export const sendResetPasswordEmail = (requiredData) => async (dispatch) => {
  let request = await data()
  const response = await request
    .get(
      `/user/forgot-password?type=${requiredData.type}&email=${requiredData.email}`,
    )
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: AuthActionType.UPDATE,
    // payload:response.data
  })
}

export const resendVerifyEmailAccountLink =
  (requiredData) => async (dispatch) => {
    let request = await privetData()
    const response = await request
      .post('/user/resend/email/verification', requiredData)
      .catch((err) => {
        return err.response
      })

    handleErrorAndDispatch(requiredData, response, dispatch, {
      type: AuthActionType.UPDATE,
      // payload:response.data
    })
  }

export const resendVerifyPhoneCode =
  (requiredData, payload) => async (dispatch) => {
    let request = await privetData()
    const response = await request
      .get(
        `/user/verify-phone?type=${payload.type}&email=${payload.email}&phone=${payload.phone}&service=sms`,
      )
      .catch((err) => {
        return err.response
      })
    console.log(response)
    // handleErrorAndDispatch(requiredData, response, dispatch, {
    //   type: AuthActionType.UPDATE,
    //   // payload:response.data
    // })
  }
