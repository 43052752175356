import React from 'react'
import './../style/statusBox.css'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createSpace } from '../redux/actions/SpaceAction'
import { Progress } from 'antd';
export default function StatusBox(props) {
  const banner = useSelector((state) => state.component.banner)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let handleButtonClick = (link) => {
    if (link == '/space/add/new') {
      dispatch(
        createSpace({
          callBack: (response) => {
            navigate(`/space/add/${response.data.space.id}`)
            // console.log(response.data.space.id);
          },
        }),
      )
    }
    if (link != null) {
      navigate(link)
    }
  }
  return (
    <>
      <div className="banner">
        <div style={{ padding: 50 }}>
          <h1>{banner.header}</h1>
          <p>{banner.discription}</p>
        </div>
        {banner.button.visible ? (
          <div
            style={{
              width: '100%',
              paddingRight: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <button
              onClick={() => {
                handleButtonClick(banner.button.link)
              }}
            >
              {banner.button.name}
            </button>
          </div>
        ) : (
          ''
        )}
        {banner.pages.visible ? (
          <span className="page-number">
            {banner.pages.total_page}/{banner.pages.page_number}
          </span>
        ) : (
          ''
        )}
      </div>
      {banner.progress_bar.visible ? (
        <>
          <Progress style={{
            position: "absolute",
            marginTop: "-12px",
            width: "84.5%"
          }} percent={banner.progress_bar.progress} showInfo={false} />
          {/* <div
            className="progress"
            style={{
              height: '4px',
              borderRadius: '0px',
              border: 'black',
            }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${banner.progress_bar.progress}%`,
                background: 'black',
              }}
              aria-valuenow={`${banner.progress_bar.progress}`}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div> */}
        </>
      ) : (
        ''
      )}
    </>
  )
}
