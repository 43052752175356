import React from 'react'

const BecomeHostFAQ =
    [
        {
            header: `Who can be a Venuely Host?`,
            body: <>
                Any house of worship, of any faith, can become a Venuely host. It is not required that there be an active congregation. We are just looking for amazing structures that are looking to share their space.
            </>
        },
        {
            header: `How do I earn money as a house of faith?`,
            body: <>
                Once you have uploaded all of your rooms to Venuely's system, the prospective Guests will be able to browse your listing and see if it fits their needs. You will also connect your bank account to Venuely's secure payment processing program. Venuely processes the funds and will automatically send those funds to your bank account. So, so simple!
            </>
        },
        {
            header: `Does Venuely take a transaction fee?`,
            body: <>Venuely takes a 5% fee per transaction. While we wish we could
                provide these services 100% for free, there is a cost to
                maintain and host and update the website as well keep good
                customer service standards. This fee helps us pay for those
                services to keep Venuely working for you!</>
        },
        {
            header: `I want to be a Venuely Host, how do I set my pricing?`,
            body: <>There are many ways to answer this question. You can set your pricing based on: anticipated wear and tear on your space, the costs to run and maintain your space for any given time, the mission and vision alignment of the prospective Guest to your institution, and the local going commercial rate. You also have an opportunity to create diffeshare levels of prices for: Events, Meetings-Classes-Rehearsals, and Media/Production.
            </>
        },
        {
            header: <>As a Venuely Guest, can I book the same day of the week for the same time<br></br> of day, for example, three Tuesdays in a row from 4pm-8pm?</>,
            body: <>Cursharely, Venuely doesn't have the capacity to do repeat bookings in this manner. In the above example, the Guest would have to book each Tuesday from 4pm-8pm individually so they would have three bookings all for Tuesdays at 4pm for four hours. Our system cursharely works best when used in consecutive days for the same time each day.
                <br />
                We are working on improving these features in our next phase of the website! Please share with us your questions, ideas, and concerns!
            </>
        }
    ]

export { BecomeHostFAQ }