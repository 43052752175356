import { Outlet } from 'react-router'
import { useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { logout, me, refresh } from '../redux/actions/AuthAction'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval'
import './../style/space.css'
import StatusBox from '../components/StatusBox'
import ArrowImage from './../img/polygon_1.png'
import PlusImage from './../img/plus_symbol.png'
import SpaceListItem from '../components/SpaceListItem'
import { setInformation } from '../redux/actions/InformationAction'

function PaymentLayOut() {
  const [collapsed, setCollapsed] = useState(false)
  const banner = useSelector((state) => state.component.banner)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [usrData, setUsrData] = useState({})
  let navigate = useNavigate()
  const location = useLocation()
  const [rotateArroButton, setRotateArroButton] = useState(false)
  const [rotateArroButton2, setRotateArroButton2] = useState(false)
  const [fatchInformation, setFatchInformation] = useState(true)
  useEffect(() => {
    // if(auth.user.name == null){
    //     dispatch(me())
    // }
    if (fatchInformation) {
      dispatch(
        setInformation({
          callBack: (response) => {
            console.log(response.data)
          },
        }),
      )
    }
    return () => {
      setFatchInformation(false)
    }
  }, [])

  // useInterval(() => {
  //   // Your custom logic here
  //   dispatch(refresh({}))
  // }, 1200000);

  return (
    <main>
      <aside style={{ height: 1800 }}>
        <div className="space-side-nevbar">
          <ul>
            <li className="space-list">
              <NavLink
                to="/payment/revenue"
                className={({ isActive }) => {
                  setRotateArroButton(isActive)
                  return isActive ? 'active' : ''
                }}
                style={styles.navLinkStyle}
              >
                Revenue
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />
              <ul
                className={`space-list-child ${
                  !rotateArroButton ? 'hide-elements' : ''
                }`}
              >
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/revenue/income"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={styles.navLinkStyle}
                  >
                    Income
                  </NavLink>
                </li>
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/revenue/transaction-history"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={styles.navLinkStyle}
                  >
                    Transaction History
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="space-list">
              <NavLink
                to="/payment/banking"
                className={({ isActive }) => {
                  setRotateArroButton2(isActive)
                  setRotateArroButton2(
                    isActive &&
                      location.pathname !== '/payment/banking/discount'
                      ? true
                      : false,
                  )
                  return isActive &&
                  location.pathname !== '/payment/banking/discount' ? 'active' : ''
                }}
                style={styles.navLinkStyle}
              >
                Banking
              </NavLink>
              <img
                src={ArrowImage}
                className={`arrow-image-space-list ${
                  !rotateArroButton2 ? 'rotate-right' : ''
                }`}
                alt=""
                srcSet=""
              />
              <ul
                className={`space-list-child ${
                  !rotateArroButton2 ? 'hide-elements' : ''
                }`}
              >
                <li className="space-list-child-item">
                  <NavLink
                    to="/payment/banking/payout-details"
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={styles.navLinkStyle}
                  >
                    Payout details
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="space-list">
              <NavLink
                to="/payment/banking/discount"
                className={({ isActive }) => {
                  setRotateArroButton2(
                    location.pathname === '/payment/banking/discount'
                      ? false
                      : true,
                  )
                  return isActive ? 'active' : ''
                }}
                style={styles.navLinkStyle}
              >
                Discount Code
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <section className="main-content">
        <StatusBox />
        <Outlet />
      </section>
    </main>
  )
}

const styles = {
  navLinkStyle: {
    color: '#000',
  },
}

export default PaymentLayOut
