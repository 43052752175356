import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

const ReviewModal = (props) => {
  const { isModalVisible, setIsModalVisible, modalText, modalTitle, covidText } = props

  const listGridStyle = { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15, width: '78%', marginTop: 20 }
  const listTitle = { fontSize: 16, marginTop: 20 }

  return (
    <Modal
      title={modalTitle}
      destroyOnClose
      okText="Apply"
      visible={isModalVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      style={{ top: covidText ? '5%' : '12%' }}
    >
      <div style={{ maxHeight: 550, overflowX: 'hidden', overflowY: 'scroll' }}>
        <p style={{ fontSize: 14 }}>
          {modalText}
        </p>
        {covidText && covidText.Social_distancing.length !== 0 &&
          <>
            <h5 style={listTitle}>Social distancing</h5>
            <div style={listGridStyle}>
              {covidText.Social_distancing.map((item, idx) => {
                return (
                  <li key={idx} style={{ fontSize: 14, listStyle: 'inside' }}>{item.title.length >= 20 ? item.title.slice(0, 20) : item.title}</li>
                )
              })}
            </div>
          </>
        }
        {covidText && covidText.cleaning_and_hygiene.length !== 0 &&
          <>
            <h5 style={listTitle}>Cleaning and hygene</h5>
            <div style={listGridStyle}>
              {covidText.cleaning_and_hygiene.map((item, idx) => {
                return (
                  <li key={idx} style={{ fontSize: 14, listStyle: 'inside' }}>{item.title.length >= 20 ? item.title.slice(0, 20) : item.title}</li>
                )
              })}
            </div>
          </>
        }
        {covidText && covidText.Protect_people_and_detect.length !== 0 &&
          <>
            <h5 style={listTitle}>Protect people and detect</h5>
            <div style={listGridStyle}>
              {covidText.Protect_people_and_detect.map((item, idx) => {
                return (
                  <li key={idx} style={{ fontSize: 14, listStyle: 'inside' }}>{item.title.length >= 20 ? item.title.slice(0, 20) : item.title}</li>
                )
              })}
            </div>
          </>
        }
        {covidText && covidText.communicating_rules.length !== 0 &&
          <>
            <h5 style={listTitle}>Communicating rules</h5>
            <div style={listGridStyle}>
              {covidText.communicating_rules.map((item, idx) => {
                return (
                  <li key={idx} style={{ fontSize: 14, listStyle: 'inside' }}>{item.title.length >= 20 ? item.title.slice(0, 20) : item.title}</li>
                )
              })}
            </div>
          </>
        }
        {covidText && covidText.emergency_response_plans.length !== 0 &&
          <>
            <h5 style={listTitle}>Emergency response plan</h5>
            <div style={listGridStyle}>
              {covidText.emergency_response_plans.map((item, idx) => {
                return (
                  <li key={idx} style={{ fontSize: 14, listStyle: 'inside' }}>{item.title.length >= 20 ? item.title.slice(0, 20) : item.title}</li>
                )
              })}
            </div>
          </>
        }
      </div>
    </Modal>
  )
}

export default ReviewModal

