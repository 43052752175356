import React, { useState, useEffect } from 'react'
import { Modal, Button, message, Alert, Form, Input } from 'antd'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { TopMsg } from '../Topmsg'

const EmailModal = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL
  const { emailModal, setEmailModal, emailVal, setEmailVal } = props

  const [email, setEmail] = useState(emailVal)
  const [showVerifyInfo, setShowVerifyInfo] = useState(false)

  useEffect(() => {
    ;(async () => {
      const cookie = new Cookies()
      const authData = cookie.get('jwt')

      const res = await axios(`${process.env.REACT_APP_BASE_URL}/guest`, {
        headers: {
          Authorization: authData,
        },
      })

      setEmail(res.data['email'])
    })()
  }, [emailVal])

  const handleOk = () => {
    setEmailModal(true)
    setShowVerifyInfo(false)
    props.getProfile() // To update user info at RenterProfile.js (reaquired for new email not verified message)
  }

  const handleCancel = () => {
    setEmailModal(false)
    setShowVerifyInfo(false)
    props.getProfile() // To update user info at RenterProfile.js (reaquired for new email not verified message)
  }

  const btnStyle = {
    // backgroundColor: '#D9D9D9',
    backgroundColor: '#6568E6',
    width: 445,
    height: 48,
    borderRadius: 4,
    color: '#fff',
    textAlign: 'center',
    marginTop: 30,
    paddingTop: 10,
    cursor: 'pointer',
  }
  const inputStyle = {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 443,
    height: 56,
    textIndent: 10,
    fontSize: 16,
    fontWeight: 400,
    marginTop: 20,
  }

  return (
    <>
      <Modal
        title="Change Email Address"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        okText="Send"
        visible={emailModal}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: '16%' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 25,
          }}
        >
          <h4 style={{ fontSize: 24, fontWeight: 500 }}>
            {!showVerifyInfo
              ? `Change Email Address`
              : `Verify your email address`}
          </h4>
          <h6 style={{ fontSize: 14, fontWeight: 400 }}>
            {!showVerifyInfo
              ? `This is your primary Email for your account.`
              : `Please check your email inbox, and click the following link.`}
          </h6>
        </div>

        {!showVerifyInfo ? (
          <>
            <h5 style={{ fontSize: 18, fontWeight: 600 }}>
              Current email address
            </h5>
            <h5 style={{ fontSize: 16, fontWeight: 400 }}>{email}</h5>
            <Form>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid E-mail address',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input
                  placeholder="New email address"
                  style={inputStyle}
                  onChange={(e) => setEmailVal(e.target.value)}
                />
              </Form.Item>
            </Form>
            {/* <input
          style={inputStyle}
          // value={emailVal}
          onChange={(e) => setEmailVal(e.target.value)}
          type="email"
          placeholder="New email addresss"
        /> */}
            <div
              style={btnStyle}
              onClick={async () => {
                try {
                  const cookie = new Cookies()
                  const authData = cookie.get('jwt')
                  const config = {
                    headers: {
                      Authorization: authData,
                    },
                  }
                  const data = {
                    email: emailVal,
                  }
                  await axios.patch(`${baseURL}/guest/`, data, config)
                  message.success(TopMsg('Email info updated successfully'))
                  setEmail(emailVal) // To show above input box.
                  props.getProfile() // To update user info at RenterProfile.js (reaquired for new email not verified message)

                  setShowVerifyInfo(true)
                  // setTimeout(() => {
                  //   setShowVerifyInfo(false)
                  // }, 8000)
                } catch (err) {
                  message.error(err.response.statusText)
                }
              }}
            >
              <label style={{ fontSize: 16, marginTop: 12 }}>Continue</label>
            </div>
          </>
        ) : (
          <div>
            <p
              style={{
                textAlign: 'center',
                fontSize: 16,
              }}
            >
              Didn’t get a link?
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: 14,
                color: '#656369',
              }}
            >
              If you don’t receive your link in 1 minutes, <br />
              click{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  fontSize: 14,
                  color: '#656369',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  props.reSendEmail()
                }}
              >
                resend a link.
              </span>
            </p>
          </div>
        )}
      </Modal>
    </>
  )
}

export default EmailModal
