import React, { useEffect, useState, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate, useParams } from 'react-router-dom'
import InputButtonSwitch from '../../components/InputButtonSwitch'
import deleteImage from './../../img/delete.png'
import plusImage from './../../img/plus.png'
import {
  createAvailability,
  deleteAvailability,
  selectAvailability,
  updateAvailability,
} from '../../redux/actions/AvailabilityAction'
import { selectSpace, setSpace } from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import LoadingAnimation from '../../components/LoadingAnimation'
import HorizontalLoading from '../../components/HorizontalLoading'
import { time_list } from '../../redux/requests/index'

export default function AvailabilityOfSpaceInsert() {
  const banner = useSelector((state) => state.component.banner)
  // const availability_list = useSelector(state => state.space.space.availability)
  const [availability_list, setAvailability_list] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const [showLoading, setShowLoading] = useState(false)
  // const [availabilityList, setavailabilityList] = useState()
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Your Space: Availability',
          discription: 'Set your operating weekly hours',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100/7) * 4,
          },
          pages: {
            visible: true,
            total_page: 4,
            page_number: 6,
          },
          callBack: () => {
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  setAvailability_list(response.data.availability)
                  // console.log('render');
                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])
  const [open, setOpen] = useState(false)
  const selectRange = (
    <div className="time-range">
      <select name="start" id="">
        <option value="">8.00 am</option>
        <option value="">8.00 am</option>
        <option value="">8.00 am</option>
      </select>
      <p>to</p>
      <select name="end" id="">
        <option value="">8.00 am</option>
        <option value="">8.00 am</option>
        <option value="">8.00 am</option>
      </select>
    </div>
  )
  const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  let makeCamelCase = (givenString) => {
    let string = givenString.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  let addAvailability = (dayId) => {
    let loaderId = `availability-loader-${dayId}`,
      ulId = `availability-ul-${dayId}`,
      loader = document.getElementById(loaderId),
      list = document.getElementById(ulId)
    loader.style.display = 'block'
    list.style.display = 'none'
    dispatch(
      createAvailability({
        day_id: dayId,
        space_id: id,
        callBack: (response) => {
          if(response.status == 400){
            
            setLoading(true)
            loader.style.display = 'none'
            list.style.display = 'block'
            NotificationManager.warning(
              response.data[0],
              'availability',
              700,
            )
          }
          if(response.status == 200){
            dispatch(
              selectSpace({
                id: id,
                callBack: (response) => {
                  setLoading(true)
                  loader.style.display = 'none'
                  list.style.display = 'block'
                  NotificationManager.success(
                    `Successfully Created`,
                    'availability',
                    700,
                  )
                },
              }),
            )
          }
        },
      }),
    )
  }

  let hideTime = (id, is_open) => {
    console.log(id)
  }

  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form className="space-setup-availability-inserte-form">
        <div className="radio-button-group">
          <h1 className="radio-button-group-input-lable">
            What are your operating hours for this space?
          </h1>
          <p className="radio-button-group-input-lable-description">
            Guests will not be able to book times outside of your operating
            hours.
          </p>
        </div>

        <hr style={{ width: '100%' }} />

        <ul className="availability-inserte-list">
          {availability_list == null ? (
            <div className="time-loading">
              {' '}
              <HorizontalLoading />{' '}
            </div>
          ) : (
            Object.keys(availability_list).map((key, index) => {
              return (
                <li>
                  <div className="day">{makeCamelCase(key)}</div>
                  <InputButtonSwitch
                    loading={setLoading}
                    hideTime={() => {
                      hideTime(
                        availability_list[key].id,
                        availability_list[key].is_open,
                      )
                    }}
                    space_id={id}
                    id={availability_list[key].id}
                    value={availability_list[key].is_open}
                  />
                  <ul
                    id={`availability-loader-${availability_list[key].id}`}
                    style={{ display: 'none' }}
                  >
                    <li>
                      <HorizontalLoading />
                    </li>
                  </ul>
                  <ul
                    id={`availability-ul-${availability_list[key].id}`}
                    style={{ maxWidth: 368 }}
                  >
                    {availability_list[key].availability.map((time, index) => {
                      if (availability_list[key].is_open) {
                        return (
                          <li>
                            <TimeRange
                              loading={setLoading}
                              space_id={id}
                              data={time}
                              is_open={availability_list[key].is_open}
                              all_availAbility={availability_list}
                              day={key}
                            />
                          </li>
                        )
                      } else {
                        if (index == 0) {
                          return (
                            <li>
                              <TimeRange
                                loading={setLoading}
                                space_id={id}
                                data={time}
                                is_open={availability_list[key].is_open}
                                all_availAbility={availability_list}
                                day={key}
                              />
                            </li>
                          )
                        }
                      }
                    })}
                  </ul>
                  <img
                    src={plusImage}
                    style={
                      !availability_list[key].is_open
                        ? { opacity: 0, cursor: 'auto' }
                        : {}
                    }
                    title={`Add time Interval for ${key.toLowerCase()}`}
                    onClick={() => {
                      if (availability_list[key].is_open)
                        addAvailability(availability_list[key].id)
                    }}
                    className="plusbutton"
                    alt=""
                    srcSet=""
                  />
                </li>
              )
            })
          )}
        </ul>

        {/* <hr /> */}
        <div style={{ marginTop: 100 }} />
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/photos`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button
            type="submit"
            onClick={() => {
              navigate(`/space/add/${id}/activities`)
            }}
          >
            {' '}
            Save & Continue{' '}
          </button>
        </div>
      </form>
    </div>
  )
}

const formRef = createRef()

// let setTimeList = () => {
//     let time_list = []
//     for (let i = 1; i <= 24; i++) {
//         var time = new Date(`1/1/2020 ${i}:00:00`).toLocaleTimeString('en-US',{ hour12: false });
//         var newTime = new Date(`1/1/2020 ${i}:00:00`);
//         time_list.push(time)
//         if(i!=24){
//             for (let j = 1; j < 4; j++) {
//                 let min = 15*j
//                 let addedIntervel = newTime.getTime() + (min*60000)
//                 var conv = new Date(addedIntervel).toLocaleTimeString('en-US',{ hour12: false });
//                 time_list.push(conv)
//             }
//         }
//     }

//     return time_list;
// }
// let time_list = setTimeList()

function TimeRange({
  data,
  space_id,
  is_open,
  loading,
  all_availAbility,
  day,
}) {
  const [startTime, setStartTime] = useState(data.start)
  const [endTime, setEndTime] = useState(data.end)
  const dispatch = useDispatch()
  const [fatch, setFatch] = useState(true)
  const [showLoder, setshowLoder] = useState(false)

  useEffect(() => {
    if (fatch) {
      dispatch(
        selectAvailability({
          id: data.id,
          callBack: (response) => {
            let form = document.getElementById(`${data.id}-time-form`).elements
            form.start.value = response.data.start
            form.end.value = response.data.end
            setFatch(false)
          },
        }),
      )
    }
  }, [fatch])

  let handleChange = (e) => {
    e.preventDefault()
    let reqData = { id: data.id }
    reqData[e.target.name] = e.target.value
    setshowLoder(true)
    dispatch(
      updateAvailability({
        ...reqData,
        callBack: (response) => {
          let is_okay = response.status == 200
          if (is_okay) {
            NotificationManager.success(
              `Successfully Updated`,
              'availability',
              700,
            )
          }
          if (!is_okay) {
            NotificationManager.warning(response.data[0], 'availability', 3000)
          }
          dispatch(
            selectAvailability({
              id: data.id,
              callBack: (response) => {
                setshowLoder(false)
                e.target.value = response.data[e.target.name]
                loading(true)
              },
            }),
          )
        },
      }),
    )
  }

  let handleDelete = () => {
    if (is_open) {
      setshowLoder(true)
      dispatch(
        deleteAvailability({
          id: data.id,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: space_id,
                callBack: (response) => {
                  NotificationManager.success(
                    `Successfully Deleted`,
                    'availability',
                    700,
                  )
                  setshowLoder(false)
                  loading(true)
                },
              }),
            )
          },
        }),
      )
    }
  }

  const selectRange = (
    <form
      onChange={handleChange}
      ref={formRef}
      id={`${data.id}-time-form`}
      className="time-range"
    >
      <div
        style={{
          display: showLoder ? 'block' : 'none',
        }}
      >
        <LoadingAnimation />
        {/* <HorizontalLoading /> */}
      </div>
      <div
        style={{
          display: !showLoder ? 'block' : 'none',
        }}
      >
        {/* <input type="time" value={'06:00:00'} /> */}
        <select
          disabled={!is_open || showLoder}
          style={!is_open ? { opacity: 0, cursor: 'auto' } : {}}
          onChange={(e) => {
            setStartTime(e.target.value)
          }}
          name="start"
          id="start"
        >
          {time_list.map((time) => {
            return <option value={time}>{tConvert(time)}</option>
          })}
        </select>
      </div>

      <p style={!is_open ? {} : { marginTop: 12 }}>
        {!is_open ? 'Unavailable' : 'To'}
      </p>
      <div style={{ display: showLoder ? 'block' : 'none' }}>
        <LoadingAnimation />
      </div>
      <div style={{ display: !showLoder ? 'block' : 'none' }}>
        <select
          disabled={!is_open}
          style={!is_open ? { opacity: 0, cursor: 'auto' } : {}}
          onChange={(e) => {
            setEndTime(e.target.value)
          }}
          name="end"
          id="end"
        >
          {time_list.map((time) => {
            return <option value={time}>{tConvert(time)}</option>
          })}
        </select>
      </div>
    </form>
  )

  return (
    <>
      {selectRange}
      <img
        src={deleteImage}
        style={!is_open ? { opacity: 0, cursor: 'auto' } : {}}
        onClick={handleDelete}
        className="deletebutton"
        alt=""
        srcSet=""
      />
    </>
  )
}

function tConvert(time) {
  var time = new Date(`1/1/2020 ${time}`).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
  return time
  // time = time.substring(0, time.length-3);
  // time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  // if (time.length > 1) { // If time format correct
  //   time = time.slice (1);  // Remove full string match value
  //   time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
  //   time[0] = +time[0] % 12 || 12; // Adjust hours
  // }
  // return time.join (''); // return adjusted time or original string
}
