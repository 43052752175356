import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateAvailabilityDay } from '../redux/actions/AvailabilityAction'
import { selectSpace } from '../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function InputButtonSwitch(props) {
  const [open, setOpen] = useState(props.value)
  const dispatch = useDispatch()
  let handleChange = (e) => {
    let loaderId = `availability-loader-${props.id}`,
      ulId = `availability-ul-${props.id}`,
      loader = document.getElementById(loaderId),
      list = document.getElementById(ulId)
    loader.style.display = 'block'
    list.style.display = 'none'

    dispatch(
      updateAvailabilityDay({
        id: props.id,
        is_open: !open,
        callBack: (response) => {
          dispatch(
            selectSpace({
              id: props.space_id,
              callBack: (response) => {
                setOpen(!open)
                props.hideTime()
                props.loading(true)

                loader.style.display = 'none'
                list.style.display = 'block'

                NotificationManager.success(
                  `Successfully Updated`,
                  'availability',
                  700,
                )
              },
            }),
          )
        },
      }),
    )
  }
  return (
    <label
      className={`switch switch-left-right ${
        !props.value ? 'switch-left-right-close' : 'switch-left-right-open'
      }`}
    >
      <input
        onChange={handleChange}
        className="switch-input"
        type="checkbox"
        checked={open}
      />
      <span className="switch-handle"></span>
    </label>
  )
}
