import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom'

import { logout } from '../../redux/actions/AuthAction'
import line from '../../pages/icons/line.svg'
import { months } from '../utils/months'
import searchIcon from '../../img/search_icon.svg'
import horLine from '../../img/line.svg'
import magnifier from '../../img/magnifier_icon.svg'
import SelectUser from '../login/SelectUser'
import RegistrationForm from '../registration/RegistrationForm'
import ActivityList from '../ActivityList'
import CalendarMenuMain from '../calendar/CalendarMenuMain'
import Poeple from '../icons/People'
import axios from 'axios'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import moment from 'moment'
import { message, Tag } from 'antd'
import { AnalyticsEventTracker } from '../utils/AnalyticsEventTracker'
import {
  getAllActivities,
  getActivity,
  getAddressSuggestions,
  getSpaceListWithoutDataInput,
} from './TopbarHelpers.js'

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const getSessionToken = () =>
  new window.google.maps.places.AutocompleteSessionToken()

const Topbar = (props) => {
  const { selectedActivites, setSelectedActivites } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [dateSelected, setDateSelected] = useState(false)
  const authUser = useSelector((state) => state.auth.user)
  const [activityItemSelected, setactivityItemSelected] = useState(false)
  const [optOutActivities, setOptOutActivities] = useState([])
  const [placeSuggestions, setPlaceSuggestions] = useState([])
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  let handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }
  const [activityVal, setActivityVal] = useState('')
  const [activityValID, setactivityValID] = useState('')
  const [sessionToken, setSessionToken] = useState(getSessionToken())
  const [value, setValue] = useState('')
  const [placeID, setPlaceID] = useState('')
  const baseURL = process.env.REACT_APP_BASE_URL
  // const selectedActivity = (e) => { // ! Added by not Kamal, not is use
  //   setActivityVal(e.target.innerText)
  //   setactivityItemSelected(true)
  // }
  const onPanelChange = (value, mode) => console.log(value, mode)
  const date = new Date()
  const valueDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`
  const isauthenticated = useSelector((state) => state.auth.authenticated)

  const geoData = async () => {
    try {
      if (value === '' || props.dateVal === '' || activityVal === '') {
        props.setLoading(false)
      } else {
        props.setLoading(true)
      }
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/property/address`,
        { find_coordinates: placeID },
      )
      // props.setMarkerPos(res.data?.results[0].geometry.location)
      // const latLng = res.data?.results[0].geometry.location // when using googles places API
      const latLng = { lat: res.data.lat, lng: res.data.lng }
      props.setCenter(latLng)
      await getSpaceList(
        latLng,
        res.data.full_address?.administrative_area_level_1,
      )
      props.setactivityList(false)
      props.setLocItemSelected(true)
      props.setLocSelected(true)
      props.setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const setURLParams = () => {
    const { protocol, host, pathname } = window.location
    const href = protocol + '//' + host + pathname
    window.history.replaceState({}, '', href)
    const { pushState } = window.history
    if (pushState) {
      const newUrl =
        href +
        `?location=${value}&placeId=${placeID}&startTime=${props.startTime}&endTime=${props.endTime}&date=${props.dateVal}&date2=${props.dateVal2}`
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
  }

  useEffect(() => {
    const searchFromURLParams = () => {
      const url = new URL(window.location.href)
      const loc = url.searchParams.get('location')
      const placeId = url.searchParams.get('placeId')
      const dateValue = url.searchParams.get('date')
      const dateValue2 = url.searchParams.get('date2')
      // const activity = url.searchParams.get('activity')
      // const activityId = url.searchParams.get('activityId')
      let condition = loc === null || dateValue === null || placeID === null
      // activity === null ||
      // activityId === null
      if (condition) {
        props.setLocItemSelected(false)
        props.setTimeVal(false)
        setDateSelected(false)
      } else {
        // TODO: make this else block work (it was working before, but now working now, please re-enable this one.)
        setValue(loc)
        setPlaceID(placeId)
        // setactivityValID(+activityId)
        props.setLocItemSelected(true)
        props.setDateVal(moment(dateValue).format('YYYY-MM-DD'))
        props.setDateVal2(moment(dateValue2).format('YYYY-MM-DD'))
        props.setTimeVal(true)
        setDateSelected(true)
        // setActivityVal(activity)
        setactivityItemSelected(true)
        const fetchUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${mapsApiKey}`
        axios.get(fetchUrl).then((res) => {
          // props.setMarkerPos(res.data?.results[0].geometry.location)
          props.setCenter(res.data?.results[0].geometry.location)
          getSpaceList(res.data?.results[0].geometry.location)
          document.getElementById('search__btn').click()
        })
      }
    }
    searchFromURLParams()
    return () => searchFromURLParams()
  }, [])

  useEffect(() => {
    getSpaceListWithoutDataInput(props)
  }, [])

  const getSpaceList = async (val, optionalState) => {
    try {
      const fetchUrl = `${baseURL}/guest/property`
      const reqData = {
        lat: val.lat,
        lng: val.lng,
        activity: selectedActivites.map((el) => el.id),
        date:
          props.dateVal === props.dateVal2
            ? [props.dateVal]
            : [props.dateVal, props.dateVal2],
        start: props.startTime,
        end: props.endTime,
        ...(props.occupencyVal !== '' && {
          occupency:
            props.occupencyVal === 'Exact Count'
              ? [0, +props.exactOccupencyValue]
              : [
                  +props.occupencyVal?.split('-')[0],
                  +props.occupencyVal.split('-')[1],
                ],
        }),
        ...(props.priceVal !== '' && {
          price:
            props.priceVal === 'Enter Range'
              ? [
                  +props.exactPriceVal?.split('-')[0],
                  +props.exactPriceVal.split('-')[1],
                ]
              : [
                  +props.priceVal?.split('-')[0].split('$')[1],
                  +props.priceVal?.split('-')[1].split('$')[1],
                ],
        }),
        house_rules: props.houseRules,
        amenity: props.amenity,
        type_of_worship: props.typeOfWorship,
        place_category: props.placeCategory,
        page_number: 1,
        limit: 10,
        // distance: 8, // * currently turned off
        ...(optionalState ? { state: optionalState } : {}),
        order_by: 'distance',
      }
      // console.log(reqData)
      const res = await axios.post(fetchUrl, reqData)
      props.setSpaceList(res.data)
      props.setLocSelected(true)
      // setOptOutActivities(res.data.opt_out_activities)
      // console.log(res.data.opt_out_activities)
    } catch (err) {
      // console.log(err)
    }
  }

  // TODO: REMOVE this below portion when confirmed if its done.
  // let {
  //   ready,
  //   value,
  //   suggestions: { status, data },
  //   setValue,
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   requestOptions: {
  //     sessionToken: sessionToken,
  //   },
  //   debounce: 300,
  // })

  // TODO: remove this below portion when make sure Faisl bro is not sending any data outside Venuely states.
  // data = data.filter((data) => {
  //   console.log(`data`, data)
  //   console.log(data?.structured_formatting.secondary_text) // TODO: remove after March 21, 2023 meeting
  //   const arrayOfSearchString = ['NY, USA', 'NJ, USA', 'IL, USA']

  //   if (data?.structured_formatting?.secondary_text != null)
  //     // if (data?.structured_formatting?.secondary_text.includes('NY, USA'))
  //     //   return true
  //     return arrayOfSearchString.some((str) =>
  //       data?.structured_formatting?.secondary_text.includes(str),
  //     )
  //   return false
  // })

  const ref = useOnclickOutside(() => {
    // clearSuggestions()
  })

  const handleInput = (e) => {
    setValue(e.target.value) // search query for array
    getAddressSuggestions(e.target.value, setPlaceSuggestions) // Calling for suggestions and storing at array to render
    props.setCalendarPopup(false)
  }

  const handleSelect =
    ({ key, id }) =>
    () => {
      setValue(key)
      setPlaceID(id)
      setPlaceSuggestions([])
      props.setactivityList(false)
      props.setCalendarPopup(false)
    }

  const renderSuggestions = () => {
    return placeSuggestions.map((suggestion, idx) => {
      return (
        <div key={suggestion.id} onClick={handleSelect(suggestion)}>
          <div
            className="txt"
            onClick={(e) => {
              // props.setMarkerName(
              //   e.target.textContent.substring(
              //     0,
              //     e.target.textContent.indexOf(','),
              //   ),
              // )
              props.setLocItemVal(e.target.textContent)
              props.setLocItemSelected(true)
            }}
          >
            {suggestion.key}
          </div>
        </div>
      )
    })
  }

  const activityRef = useRef(null)

  return (
    <>
      <div className="top__header" style={{ userSelect: 'none' }}>
        <Link to={'/'}>
          <img src="/venuely.svg" alt="" style={{ cursor: 'pointer' }} />
        </Link>
        {props.doneSearch ? (
          <div className="search_">
            <div
              className="search__bar"
              onClick={() => props.setdoneSearch(!props.doneSearch)}
              style={{ visibility: props.doneSearch ? 'visible' : 'hidden' }}
            >
              <div className="search__bar__child">
                <h5 style={{ color: '#0D0119', fontSize: 15 }}>
                  {props.address}
                </h5>
                <img className="line" src={line} alt="" />
                {props.dateVal === props.dateVal2 ? (
                  <h5 style={{ color: '#0D0119', fontSize: 15 }}>
                    {months[+props.dateVal2.split('-')[1]]}{' '}
                    {props.dateVal2?.slice(8, 10)}
                  </h5>
                ) : (
                  <h5 style={{ color: '#0D0119', fontSize: 15 }}>
                    {months[+props.dateVal.split('-')[1]]}{' '}
                    {props.dateVal?.slice(8, 10)} {'-'}
                    {months[+props.dateVal.split('-')[1]]}{' '}
                    {props.dateVal2?.slice(8, 10)}
                  </h5>
                )}
                <img className="line" src={line} alt="" />
                <h5 style={{ color: '#0D0119', fontSize: 15 }}>
                  {activityVal}
                </h5>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="search__bar__in"
            style={{ visibility: props.doneSearch ? 'hidden' : 'visible' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 6,
              }}
            >
              <div>
                {props.locItemSelected && (
                  <h5 style={{ fontWeight: 'bold', marginLeft: 2 }}>
                    Location
                  </h5>
                )}
                <div>
                  <input
                    type="text"
                    value={value}
                    placeholder="Where are you going"
                    className="top-bar-input"
                    onChange={handleInput}
                    // disabled={!ready}
                    onClick={() => {
                      props.setCalendarPopup(false)
                      props.setactivityList(false)
                    }}
                  />
                </div>
                {placeSuggestions.length >= 0 && (
                  <div
                    className="location-menu"
                    style={{
                      marginLeft: -10,
                      marginTop: props.locItemSelected ? 20 : 30,
                    }}
                  >
                    {renderSuggestions()}
                    {/* 
                    // Just for tests below
                    <div>
                      <div className="txt">test</div>
                    </div>
                    <div>
                      <div className="txt">test</div>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            <img className="horline" src={horLine} alt="" />
            <div>
              {props.timeVal && (
                <h5
                  style={{
                    fontWeight: 'bold',
                    marginLeft: 42,
                    position: 'absolute',
                    marginTop: 3,
                  }}
                >
                  Date
                </h5>
              )}
              <div style={{ display: showRegistrationForm ? 'flex' : 'none' }}>
                <RegistrationForm
                  closeRegistrationFormCart={setShowRegistrationForm}
                />
              </div>
              <div style={{ display: showLoginForm ? 'flex' : 'none' }}>
                <SelectUser closeRegistrationFormCart={setShowLoginForm} />
              </div>
              <div
                id="in__btns"
                className="in__btns"
                onClick={() => {
                  if (props.dateVal === '') {
                    props.setDateVal(valueDate)
                    props.setDateVal2(valueDate)
                    props.setCalendarPopup(!props.calendarPopup)
                  } else {
                    props.setCalendarPopup(!props.calendarPopup)
                  }
                  props.setLocMenu(false)
                  props.setactivityList(false)
                }}
              >
                {props.timeVal ? (
                  <h5
                    style={{
                      color: 'rgb(0, 0, 0)',
                      fontWeight: 400,
                      marginTop: 25,
                      marginLeft: 22,
                      fontSize: 14,
                    }}
                  >
                    {props.dateVal === props.dateVal2 ? (
                      <>
                        {months[+props.dateVal.split('-')[1] - 1]}{' '}
                        {props.dateVal.slice(8, 10)}
                      </>
                    ) : (
                      <>
                        {months[+props.dateVal.split('-')[1] - 1]}{' '}
                        {props.dateVal.slice(8, 10)} {'-'}
                        {months[+props.dateVal2.split('-')[1] - 1]}{' '}
                        {props.dateVal2.slice(8, 10)}
                      </>
                    )}
                  </h5>
                ) : (
                  <h5
                    style={{
                      color: 'rgb(126, 126, 126)',
                      fontWeight: 400,
                      marginTop: 5,
                      fontSize: 15,
                    }}
                  >
                    When is your event?
                  </h5>
                )}
              </div>
            </div>
            {props.calendarPopup && (
              <CalendarMenuMain
                dateVal={props.dateVal}
                setDateVal={props.setDateVal}
                dateVal2={props.dateVal2}
                setDateVal2={props.setDateVal2}
                flexibleTime={props.flexibleTime}
                setFlexibleTime={props.setFlexibleTime}
                countCalPress={props.countCalPress}
                setcountCalPress={props.setcountCalPress}
                calendarPopup={props.calendarPopup}
                setCalendarPopup={props.setCalendarPopup}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
                setTimeVal={props.setTimeVal}
                timeVal={props.timeVal}
                onPanelChange={onPanelChange}
                startDatemenu={props.startDatemenu}
                setstartDatemenu={props.setstartDatemenu}
                startTime={props.startTime}
                endTime={props.endTime}
                setendDatemenu={props.setendDatemenu}
                setStartTime={props.setStartTime}
                endDatemenu={props.endDatemenu}
                setEndTime={props.setEndTime}
              />
            )}
            <img className="horline" src={horLine} alt="" />
            <div id="in__btns" className="in__btns">
              {activityItemSelected && (
                <div style={{ marginTop: -3 }}>
                  <h5 style={styles.acitivityText}>Activity</h5>
                </div>
              )}
              <div style={{}}>
                <input
                  ref={activityRef}
                  placeholder="What are you planning?"
                  style={styles.whatPlanning}
                  // value={activityVal}
                  onChange={(e) => setActivityVal(e.target.value)}
                  onKeyUp={(e) =>
                    getActivity(
                      e.target.value,
                      props,
                      selectedActivites,
                      activityVal,
                      optOutActivities,
                    )
                  }
                />
              </div>
              {props.activityList && (
                <div
                  style={styles.activityListContainer}
                  onClick={() => {
                    setactivityItemSelected(true)
                    props.setactivityList(false)
                  }}
                >
                  <ActivityList
                    activityItemsArray={props.activityItemsArray}
                    setactivityItemSelected={setactivityItemSelected}
                    // setactivityValID={setactivityValID}
                    setActivityVal={setActivityVal}
                    getAllActivities={() => getAllActivities(props)}
                    setSelectedActivites={setSelectedActivites} //addedKml
                    activityRef={activityRef} //addedKml
                  />
                </div>
              )}
            </div>
            {
              <div
                className="search__btnn"
                id="search__btn"
                onClick={() => {
                  if (location.pathname === '/renter/search') {
                    setURLParams()
                    geoData()
                    AnalyticsEventTracker(
                      'Search',
                      'Space Search',
                      'Space Search',
                    )
                  } else {
                    navigate('/search')
                  }
                  if (
                    value === '' ||
                    props.dateVal === ''
                    // activityVal === ''
                  ) {
                    message.error('Please fill every section of the search bar')
                  }
                  // props.setdoneSearch(true)
                }}
              >
                <img src={magnifier} alt="" />
                <h5>Search</h5>
              </div>
            }
          </div>
        )}
        {/* <button
          onClick={() => {
            console.log(selectedActivites)
          }}
        >
          temp
        </button> */}
        {!isauthenticated ? (
          <>
            <div className="right__side__links">
              <h3
                className="link_text"
                onClick={() => {
                  setShowLoginForm(false)
                  setShowRegistrationForm(!showRegistrationForm)
                }}
              >
                Sign up
              </h3>
              <h3
                className="link_text"
                onClick={() => {
                  props.setCalendarPopup(false)
                  setShowLoginForm(!showLoginForm)
                }}
              >
                Sign in
              </h3>
            </div>
          </>
        ) : (
          <li>
            <button
              type="button"
              onClick={() => {
                setShowDropdownMenu(!showDropdownMenu)
              }}
              className="profile-drop-down"
            >
              {/* <img src={dummyUser} alt="" /> */}
              <Poeple />
              <ul
                className={`${showDropdownMenu ? '' : 'hide-elements'}`}
                style={{
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
                  background: '#fff',
                  borderRadius: 8,
                  border: '1px solid #656369',
                }}
              >
                <li style={{ paddingLeft: '34px' }}>
                  <NavLink
                    to={
                      authUser.type === 'Host' ? '/profile' : '/renter/profile'
                    }
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={{ color: '#000', fontSize: 16 }}
                  >
                    {' '}
                    Profile{' '}
                  </NavLink>
                </li>
                {authUser.type === 'Guest' && (
                  <li style={{ paddingLeft: '34px' }}>
                    <NavLink
                      to={'/renter/messages'}
                      className={({ isActive }) => (isActive ? 'active' : '')}
                      style={{ color: '#000', fontSize: 16 }}
                    >
                      {' '}
                      Messages{' '}
                    </NavLink>
                  </li>
                )}
                {authUser.type === 'Guest' && (
                  <li style={{ paddingLeft: '34px' }}>
                    <NavLink
                      to={'/renter/mybookings'}
                      className={({ isActive }) => (isActive ? 'active' : '')}
                      style={{ color: '#000', fontSize: 16 }}
                    >
                      {' '}
                      My Bookings{' '}
                    </NavLink>
                  </li>
                )}
                <li style={{ paddingLeft: '34px' }}>
                  <NavLink
                    to={
                      authUser.type === 'Host'
                        ? '/profile-edit'
                        : '/renter/payment'
                    }
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={{ color: '#000', fontSize: 16 }}
                  >
                    {' '}
                    Payments{' '}
                  </NavLink>
                </li>
                <li
                  onClick={handleLogout}
                  style={{ color: '#000', fontSize: 16 }}
                >
                  {' '}
                  Log out
                </li>
              </ul>
            </button>
          </li>
        )}
      </div>
    </>
  )
}

const styles = {
  acitivityText: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.85)',
    marginLeft: 3,
  },
  whatPlanning: {
    color: '#000',
    fontWeight: 400,
    border: 'none',
    outline: 'none',
    fontSize: 15,
    marginTop: -4,
  },
  activityListContainer: {
    // marginTop: activityItemSelected ? 20 : 30,
    marginLeft: -10,
    position: 'absolute',
    backgroundColor: '#fff',
    border: '#cccccc 1px solid',
    boxShadow: 'rgba(0, 0, 0, 0.07) 2px 2px 10px',
    borderRadius: 10,
    width: 200,
    maxHeight: 300,
    overflowY: 'scroll',
    zIndex: 3,
  },
}

export default Topbar
