import { BufferTimeRoleActionType } from '../contants/actions-type'
import { privetData, handleErrorAndDispatch } from '../requests'

export const setBufferTimeRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get('/host/space/buffer-time-rules/', requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(BufferTimeRoleActionType.SET_BUFFER_TIME_ROLE)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BufferTimeRoleActionType.SET_BUFFER_TIME_ROLE,
    payload: {},
  })
}

export const getBufferTimeRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .get(`/host/space/buffer-time-rules/${requiredData.id}`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(BufferTimeRoleActionType.CREATE_BUFFER_TIME_ROLE)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BufferTimeRoleActionType.SELECT_BUFFER_TIME_ROLE,
    payload: {},
  })
}

export const createBufferTimeRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .post('/host/space/buffer-time-rules/', requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(BufferTimeRoleActionType.CREATE_BUFFER_TIME_ROLE)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BufferTimeRoleActionType.CREATE_BUFFER_TIME_ROLE,
    payload: {},
  })
}

export const updateBufferTimeRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .patch(`/host/space/buffer-time-rules/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })
  console.log(BufferTimeRoleActionType.UPDATE_BUFFER_TIME_ROLE)
  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BufferTimeRoleActionType.UPDATE_BUFFER_TIME_ROLE,
    payload: {},
  })
}

export const deleteBufferTimeRole = (requiredData) => async (dispatch) => {
  let request = await privetData()
  const response = await request
    .delete(`/host/space/buffer-time-rules/${requiredData.id}/`, requiredData)
    .catch((err) => {
      return err.response
    })

  handleErrorAndDispatch(requiredData, response, dispatch, {
    type: BufferTimeRoleActionType.REMOVE_BUFFER_TIME_ROLE,
    // payload:response.data
  })
}
