import React, { useState, useEffect } from 'react'
import arrowDown from '../../img/arrow_down_1.png'

export function CalenderMonthFilter({
  setShowMonthFilter,
  showMonthFilter,
  selectedMonth,
  SelectedYear,
  setSelectedYear,
  setSelectedMonth,
}) {
  return (
    <div className="calender-filter">
      <div
        className="selected-month-year"
        onClick={() => {
          setShowMonthFilter(!showMonthFilter)
        }}
      >
        <p>
          {' '}
          {selectedMonth.monthName} {SelectedYear}{' '}
        </p>
        <img
          className="arrow show-month-filter"
          src={arrowDown}
          alt=""
          srcSet=""
        />
      </div>
      <div
        className="calender-filter-element"
        style={{ display: showMonthFilter ? 'block' : 'none' }}
      >
        <div className="year-select">
          <div>
            <img
              className="year-decrement arrow"
              src={arrowDown}
              alt=""
              srcSet=""
              onClick={() => {
                let dicrementValue = SelectedYear - 1
                setSelectedYear(dicrementValue)
              }}
            />{' '}
          </div>
          <div> {SelectedYear}</div>
          <div>
            {' '}
            <img
              onClick={() => {
                let icrementValue = SelectedYear + 1
                setSelectedYear(icrementValue)
              }}
              className="year-increment arrow"
              src={arrowDown}
              alt=""
              srcSet=""
            />{' '}
          </div>
        </div>
        <div className="month-select">
          <div
            className={`month ${
              selectedMonth.monthNumber == 1 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 1, monthName: 'January' })
            }}
            id="January"
          >
            January
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 2 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 2, monthName: 'Fabruary' })
            }}
            id="Fabruary"
          >
            Fabruary
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 3 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 3, monthName: 'March' })
            }}
            id="March"
          >
            March
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 4 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 4, monthName: 'April' })
            }}
            id="April"
          >
            April
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 5 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 5, monthName: 'May' })
            }}
            id="May"
          >
            May
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 6 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 6, monthName: 'June' })
            }}
            id="June"
          >
            June
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 7 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 7, monthName: 'July' })
            }}
            id="July"
          >
            July
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 8 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 8, monthName: 'Augast' })
            }}
            id="Augast"
          >
            Augast
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 9 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 9, monthName: 'Septembar' })
            }}
            id="Septembar"
          >
            Septembar
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 10 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 10, monthName: 'Octobar' })
            }}
            id="Octobar"
          >
            Octobar
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 11 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 11, monthName: 'Novamber' })
            }}
            id="Novamber"
          >
            Novamber
          </div>
          <div
            className={`month ${
              selectedMonth.monthNumber == 12 ? 'selected-month' : ''
            }`}
            onClick={() => {
              setSelectedMonth({ monthNumber: 12, monthName: 'Dicembar' })
            }}
            id="Dicembar"
          >
            Dicembar
          </div>
        </div>
      </div>
    </div>
  )
}
