import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from './../../../redux/actions/ComponentAction'

import pentagon from './../../../img/pentagon.png'
import dots from './../../../img/dot.png'
import { setInformation } from '../../../redux/actions/InformationAction'

export default function AddPaymentDetails() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [methodDetails, setMethodDetails] = useState([])
  const [showHeader, setShowHeader] = useState(false)
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Today',
          discription: 'Earnings & Payout Summary',
          button: {
            visible: true,
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            dispatch(
              setInformation({
                callBack: (response) => {
                  setPaymentMethod(response.data.payment_information)
                  let bankDetails = []
                  response.data.payment_information.map((method) => {
                    if (method.payment_method_type == 'CARD')
                      bankDetails.push(method)
                  })
                  setMethodDetails(bankDetails)
                },
              }),
            )
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  return (
    <div className="payout-details-wrapper">
      {methodDetails.length > 0 ? <h1>Saved payout methods</h1> : ''}

      {paymentMethod &&
        paymentMethod.map((method) => {
          if (method.payment_method_type == 'CARD') {
            return (
              <div className="cash-chacking-button">
                <img src={pentagon} alt="" srcSet="" />
                <p>Chase Checking ....2410</p>
                <img src={dots} alt="" srcSet="" />
              </div>
            )
          }
        })}

      <h1>Add a Card</h1>
      <form action="">
        <div className="payout-details-input-group">
          <label htmlFor="account_number">ACCOUNT NUMBER</label>
          <input
            placeholder="xxxx - xxxx - xxxx - xxxx"
            type="text"
            id="account_number"
            name="account_number"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="routing_number">ROUTING NUMBER</label>
          <input
            placeholder="xx - xxxxxxxx"
            type="text"
            id="routing_number"
            name="routing_number"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="routing_number">TYPE</label>
          <input
            placeholder="checking"
            type="text"
            id="routing_number"
            name="routing_number"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="first_name">FIRST NAME</label>
          <input placeholder="" type="text" id="first_name" name="first_name" />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="last_name">LAST NAME</label>
          <input placeholder="" type="text" id="last_name" name="last_name" />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="billing_address">BILLING ADDRESS</label>
          <input
            placeholder=""
            type="text"
            id="billing_address"
            name="billing_address"
          />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="state">STATE</label>
          <input placeholder="" type="text" id="state" name="state" />
        </div>
        <div className="payout-details-input-group">
          <label htmlFor="zip_code">ZIP CODE</label>
          <input placeholder="" type="text" id="zip_code" name="zip_code" />
        </div>
        <button>Save</button>
      </form>
    </div>
  )
}
