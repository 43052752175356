import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../../redux/actions/ComponentAction'
import { useNavigate, useParams } from 'react-router-dom'
import arrowDown from './../../img/arrow_down_1.png'
import ActivitieBox from '../../components/ActivitieBox'
import { updateSpace } from '../../redux/actions/SpaceAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function Amenities() {
  const banner = useSelector((state) => state.component.banner)
  const amnities = useSelector((state) => state.information.form_data.emnities)
  const space_amnities = useSelector((state) => state.space.space.amenities)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()
  let default_amnities = []
  // default_amnities = space_amnities.length > 0 ? space_amnities.map((am)=>{ return am.id }) : []
  const [selectecAmenities, setSelectecAmenities] = useState(
    space_amnities && space_amnities.length > 0
      ? space_amnities.map((am) => {
          return am.id
        })
      : [],
  )

  useEffect(() => {
    if (loading) {
      // let selected_amnities = space_amnities.map((am)=>{ return am.id });
      // setSelectecAmenities(selected_amnities);
      dispatch(
        setBanner({
          header: 'Your Space: Amenities',
          discription:
            'Set pricing and best activities that work well with this listing',
          button: {
            visible: false,
            link: null,
            name: null,
          },
          progress_bar: {
            visible: true,
            progress: (100/7) * 6,
          },
          pages: {
            visible: true,
            total_page: 6,
            page_number: 7,
          },
          callBack: () => {
            // console.log(space_amnities);
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  let add_your_own_amenities_emnities = []
  let addAmnity = (e) => {
    e.preventDefault()
    let selected_value = parseInt(e.target.getAttribute('data-value'))
    let new_list = [...selectecAmenities]
    if (new_list.includes(selected_value)) {
      const index = new_list.indexOf(selected_value)
      if (index > -1) {
        new_list.splice(index, 1)
      }
      setLoading(true)
      setSelectecAmenities([...new_list])
      return true
    }
    setSelectecAmenities([...selectecAmenities, selected_value])
  }

  let handleSubmit = (e) => {
    e.preventDefault()
    dispatch(
      updateSpace({
        id: id,
        amenity: [...selectecAmenities],
        callBack: (response) => {
          NotificationManager.success(`Successfully added`, 'amnities', 700)
          navigate(`/space/add/${id}/optout`)
        },
      }),
    )
  }
  return (
    <div
      className="space-setup-inserte-section"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto' }}
    >
      <form className="space-setup-inserte-activities-form space-emnities-form">
        <h1>Which amenities do you provide in this space?</h1>
        <p>
          Please select all the amenities you provide for this specific space:
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#6568E6',
            padding: 4,
            borderRadius: 3,
            width: 365,
          }}
        >
          {' '}
          <img
            src="/icons8-light-64.png"
            alt="icon"
            style={{ height: 15, width: 15 }}
          />
          <p
            style={{
              margin: 0,
              marginLeft: 5,
              width: 'auto',
              color: '#fff',
              fontSize: 13,
              fontWeight: 'lighter',
            }}
          >
            Select as many as you can to appear in most searches.
          </p>
        </div>
        <div className="list-of-emnities-box">
          {Object.keys(amnities).map(function (key, index) {
            if (key != 'Add your own amenities') {
              return (
                <div
                  key={`${index}-${key
                    .replace(/\&/g, '')
                    .replace(' ', '-')
                    .replace(' ', '')}-emnity-box`}
                  className={`${index}-${key
                    .replace(/&/g, '')
                    .replace(' ', '-')
                    .replace(' ', '')}-emnity-box emnity-box`}
                >
                  <h1>{key}</h1>
                  <div className="child-emnities">
                    {amnities[key].map((emnity, i) => {
                      let active_class = selectecAmenities.includes(emnity.id)
                        ? 'emnities-selected'
                        : ''
                      return (
                        <div
                          data-value={emnity.id}
                          onClick={addAmnity}
                          key={`${i}-${key
                            .replace(/\&/g, '')
                            .replace(' ', '-')
                            .replace(' ', '')}-emnity`}
                          className={`${active_class} ${i}-${key
                            .replace(/&/g, '')
                            .replace(' ', '-')
                            .replace(' ', '')}-emnity child-emnity`}
                        >
                          {emnity.title}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
          })}
          {/* <div className={`emnity-box`}>
                        <h1>Add your own amenities</h1>
                        <form>
                            <input type="text" />
                            <button>Add</button>
                        </form>
                    </div> */}
        </div>
        <hr className="space-setup-inserte-activities-last-div" />
        <div className="about-space-insert-button-group">
          <button
            type="button"
            onClick={() => {
              navigate(`/space/add/${id}/activities`)
            }}
          >
            {' '}
            Back{' '}
          </button>
          <button type="submit" onClick={handleSubmit}>
            {' '}
            Save & Continue{' '}
          </button>
        </div>
      </form>
    </div>
  )
}
