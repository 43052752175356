import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from '../redux/actions/ComponentAction'
import { ActionType } from '../redux/actions/AuthAction'
import { Link } from 'react-router-dom'
import { refresh } from '../redux/actions/AuthAction'
import axios from 'axios'
import StatusBox from '../components/StatusBox'
import SelectMonth from '../components/SelectMonth'
import CalendarComponent from '../components/calendar/CalendarComponent'
import CalenderInformation from '../components/calendar/CalenderInformation'
import { CalenderMonthFilter } from '../components/calendar/CalenderMonthFilter'
import GridCalenderInformation from '../components/calendar/GridCalenderInformation'
import { CalenderDayRangFilter } from '../components/calendar/CalenderDayRangFilter'
import arrowDown from './../img/arrow_down_1.png'

export default function Calendar() {
  const [selectedMonth, setSelectedMonth] = useState({
    monthNumber: 1,
    monthName: 'January',
  })
  const [SelectedYear, setSelectedYear] = useState(2022)
  const [showMonthFilter, setShowMonthFilter] = useState(false)
  const [showBlockFilter, setShowBlockFilter] = useState(false)
  const [showBlockFilterSpaceList, setShowBlockFilterSpaceList] =
    useState(false)
  const [ShowAllSpaceFIlter, setShowAllSpaceFIlter] = useState(false)
  const [showMonth, setShowMonth] = useState(true)
  const [DateRange, setDateRange] = useState({
    start: null,
    end: null,
  })
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (loading) {
      dispatch(
        setBanner({
          header: 'Calendar & Bookings',
          discription:
            'See all of your space inquiries, requests, and bookings in a single place',
          button: {
            visible: false,
            link: '/space/add/new',
            name: 'Create Space',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  return (
    <div>
      <div className="calender-control">
        <div
          className={`list-view-button ${
            showMonth ? 'view-button-active' : ''
          }`}
          onClick={() => setShowMonth(true)}
        >
          Month
        </div>
        <div
          className={`grid-view-button ${
            !showMonth ? 'view-button-active' : ''
          } `}
          onClick={() => setShowMonth(false)}
        >
          Grid
        </div>
        {showMonth ? (
          <CalenderMonthFilter
            setSelectedMonth={setSelectedMonth}
            setShowMonthFilter={setShowMonthFilter}
            showMonthFilter={showMonthFilter}
            selectedMonth={selectedMonth}
            SelectedYear={SelectedYear}
            setSelectedYear={setSelectedYear}
          />
        ) : (
          <CalenderDayRangFilter
            setDateRange={setDateRange}
            setSelectedMonth={setSelectedMonth}
            setShowMonthFilter={setShowMonthFilter}
            showMonthFilter={showMonthFilter}
            selectedMonth={selectedMonth}
            SelectedYear={SelectedYear}
            setSelectedYear={setSelectedYear}
          />
        )}
        <div className="calender-block-filter">
          <p
            onClick={() => {
              setShowBlockFilter(!showBlockFilter)
            }}
          >
            {' '}
            Block Time{' '}
          </p>
          <div
            className={`calender-block-filter-filter-box ${
              !showBlockFilter ? 'hide-elements' : ''
            }`}
          >
            <span
              onClick={() => {
                setShowBlockFilter(!showBlockFilter)
              }}
              className="calender-block-filter-filter-box-close-button"
            >
              X
            </span>
            <h1>Block Time</h1>
            <p>Block Time will override your space’s availbility</p>
            <div className="calender-block-filter-filter-date-range">
              <div className="calender-block-filter-date-start">
                <p>Feb 2, 2022</p>
                <img
                  className="calender-block-filter-date-downarrow"
                  src={arrowDown}
                  alt=""
                  srcSet=""
                />
              </div>
              <span>to</span>
              <div className="calender-block-filter-date-end">
                <p>Feb 2, 2022</p>
                <img
                  className="calender-block-filter-date-downarrow"
                  src={arrowDown}
                  alt=""
                  srcSet=""
                />
              </div>
            </div>
            <div className="calender-block-filter-filter-title-input-field">
              <label htmlFor="calender-block-filter-title">Title</label>
              <input type="text" id="calender-block-filter-title" />
            </div>
            <div className="calender-block-filter-all-space-drop-down">
              <p>All spaces</p>
              <img
                onClick={() => {
                  setShowBlockFilterSpaceList(!showBlockFilterSpaceList)
                }}
                className="calender-block-filter-all-space-drop-downarrow"
                src={arrowDown}
                alt=""
                srcSet=""
              />
              <div
                className={`calender-block-filter-all-space-drop-down-space-list ${
                  !showBlockFilterSpaceList ? 'hide-elements' : ''
                }`}
              >
                <h1>Apply to</h1>
                <h1>All spaces</h1>
                <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
                  <input
                    type="checkbox"
                    id="example_space_1"
                    name="example_space_1"
                    value="Bike"
                  />
                  <label htmlFor="example_space_1"> Example space 1</label>
                </div>
                <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
                  <input
                    type="checkbox"
                    id="example_space_2"
                    name="example_space_2"
                    value="Car"
                  />
                  <label htmlFor="example_space_2"> Example Space 2</label>
                </div>
                <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
                  <input
                    type="checkbox"
                    id="example_space_3"
                    name="example_space_3"
                    value="Boat"
                  />
                  <label htmlFor="example_space_3"> Example Space 3</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calender-block-filter all-spaces-filter">
          <p> All spaces </p>
          <img
            onClick={() => {
              setShowAllSpaceFIlter(!ShowAllSpaceFIlter)
            }}
            className="calender-block-filter-date-downarrow"
            src={arrowDown}
            alt=""
            srcSet=""
          />

          <div
            style={{ zIndex: '100' }}
            className={`calender-block-filter-all-space-drop-down-space-list ${
              !ShowAllSpaceFIlter ? 'hide-elements' : ''
            }`}
          >
            <h1>Views</h1>
            <h1>All spaces</h1>
            <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
              <input
                type="checkbox"
                id="example_space_4"
                name="example_space_4"
                value="Bike"
              />
              <label htmlFor="example_space_4"> Example space 1</label>
            </div>
            <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
              <input
                type="checkbox"
                id="example_space_5"
                name="example_space_5"
                value="Car"
              />
              <label htmlFor="example_space_5"> Example Space 2</label>
            </div>
            <div className="calender-block-filter-all-space-drop-down-space-list-check-box">
              <input
                type="checkbox"
                id="example_space_6"
                name="example_space_6"
                value="Boat"
              />
              <label htmlFor="example_space_6"> Example Space 3</label>
            </div>
          </div>
        </div>
      </div>
      {showMonth ? (
        <CalenderInformation
          selectedMonth={selectedMonth}
          SelectedYear={SelectedYear}
        />
      ) : (
        <GridCalenderInformation
          dateRande={DateRange}
          selectedMonth={selectedMonth}
          SelectedYear={SelectedYear}
        />
      )}
    </div>
  )
}
