import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { ActionType } from '../redux/contants/actions-type'
import { Link } from 'react-router-dom'
import { refresh } from '../redux/actions/AuthAction'
import StatusBox from '../components/StatusBox'

export default function Profile() {
  return <div>this is space</div>
}
