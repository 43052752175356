import React from 'react'
import { Modal, Form, Button, DatePicker, Input, message } from 'antd'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'

function DeleteProfile(props) {
  const { visible, setVisible, user } = props
  const navigate = useNavigate()

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onFinish = async (values) => {
    const cookies = new Cookies()

    const jwt = await cookies.get('jwt')
    console.log(jwt)

    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/user/registration/`, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((response) => {
        console.log(response)
        navigate(`/`)
        message.success('Successfully deleted.')
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to delete.')
      })
  }

  return (
    <Modal
      title="Delete Venuely Host Account"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <div
        style={{
          height: 170,
          paddingTop: 20,
          //   display: 'flex',
          //   flexDirection: 'column',
          //   justifyContent: 'center',
        }}
      >
        <p style={{ fontSize: '1.8rem', textAlign: 'center' }}>
          Are you sure want to delete this account?
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop: 20,
          }}
        >
          <Button
            onClick={() => {
              onFinish() // delete request
            }}
            type="primary"
            style={{ width: '35%', height: 35 }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              handleCancel()
            }}
            type="primary"
            ghost
            style={{ width: '35%', height: 35 }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  //   user: state.user,
})

export default connect(mapStateToProps)(DeleteProfile)
