import React, { useState, useEffect, createRef } from 'react'
import googleLogo from './../../img/logo_googleg_48dp.svg'
import { useDispatch } from 'react-redux'
import { emailCheck, registration } from '../../redux/actions/AuthAction'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import { RefreshTokenSetup } from './../../components/login/RefreshTokenSetup'
import { useNavigate } from 'react-router-dom'
import googleIcon from '../icons/google.svg'
export default function TakeEmailAddress({
  data,
  setData,
  setStepper,
  closeModel,
}) {
  const dispatch = useDispatch()
  let handleSubmit = (e) => {
    e.preventDefault()
    let newData = {
      ...data,
      email: e.target.elements.email.value,
    }
    dispatch(
      emailCheck({
        type: newData.role,
        email: newData.email,
        callBack: (response) => {
          if (response.status == 200) {
            setData({
              ...data,
              email: e.target.elements.email.value,
            })
            setStepper('details')
          } else {
            NotificationManager.warning(
              response.data.email,
              'Sorry, you cannot use this email address.',
              3000,
            )
          }
        },
      }),
    )
  }
  const navigate = useNavigate()

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID,
        plugin_name: 'chat',
      })
    })
  }, [])

  const onSuccess = (response) => {
    // console.log(response.profileObj);
    RefreshTokenSetup(response)
    dispatch(
      registration({
        google_login: true,
        type: data.role,
        name: response.profileObj.name,
        email: response.profileObj.email,
        callBack: (response) => {
          // setloading(false)
          let is_ok = response.status == 200
          if (is_ok) {
            // setStepper('confitmEmail')
            if (data.role == 'Renter') {
              closeModel(false)
              setStepper('role')
              navigate('/')
            }
            if (data.role == 'Host') {
              closeModel(false)
              setStepper('role')
              navigate('/pending-request')
            }
          }
        },
      }),
    )
  }

  const [googlebtnEnter, setGooglebtnEnter] = useState(false)
  const onMouseEnter = () => {
    setGooglebtnEnter(true)
  }

  const onMouseLeave = () => {
    setGooglebtnEnter(false)
  }

  let handleGoogleSSOClick = (renderProps) => {
    let agree =
      document.getElementById('agree_policy_sign_up1') &&
      document.getElementById('agree_policy_sign_up1').checked

    if (!agree) {
      NotificationManager.warning(
        `you need to agree our terms and conditions`,
        'Terms And Condition',
        3000,
      )
      return
    }

    if (agree) {
      renderProps.onClick()
    }
  }

  return (
    <div className="take-user-email">
      <h1>Become a {data.role} Today!</h1>
      <p style={{ fontWeight: 'bold', fontSize: 16 }}>
        *We are currently only servicing in the state of New York, New Jersey,
        and Indiana.
      </p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          style={{ fontSize: 14 }}
        />
        <button
          name="submit"
          className="take-user-email-button"
          style={{ fontSize: 16 }}
        >
          Sign Up
        </button>
      </form>
      <div className="devider">
        {' '}
        <hr /> <p>or</p> <hr />{' '}
      </div>
      <div style={{ textAlign: 'center' }}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_CLIENT_ID} // your Google app client ID
          buttonText={'Continue with Google'}
          render={(renderProps) => (
            <div
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                border: '1.55px solid #ccc',
                borderRadius: 3,
                height: 48,
                backgroundColor: googlebtnEnter ? '#fafafa' : '#fff',
              }}
            >
              <img
                src={googleIcon}
                style={{ objectFit: 'contain', height: 26, width: 26 }}
                alt=""
              />
              <button
                onClick={() => {
                  handleGoogleSSOClick(renderProps)
                }}
                // disabled = { document.getElementById('agree_policy_sign_up') && !document.getElementById('agree_policy_sign_up').checked }
                style={{
                  backgroundColor: googlebtnEnter ? '#fafafa' : '#fff',
                  width: 400,
                  fontSize: 16,
                  fontWeight: '600',
                }}
              >
                Continue with Google
              </button>
              <div></div>
            </div>
          )}
          onSuccess={(response) => onSuccess(response)}
          onFailure={(response) => {
            console.log(response)
          }}
          cookiePolicy={'single_host_origin'}
        />
        <div>
          {/* <input
            style={{
              float: 'left',
              width: '2%',
              margin: '-20px 6px 0px 0px',
            }}
            type="checkbox"
            name="agree_policy"
            id="agree_policy_sign_up"
          /> */}

          <p
            className="final-notes"
            style={{
              marginTop: 14,
              color: '#555',
              textAlign: 'left',
              // display: 'flex',
              // flexDirection: 'column',
              fontSize: 16,
              lineHeight: 1.5,
            }}
          >
            <div
              style={{
                display: 'inline',
                position: 'relative',
                marginRight: 20,
              }}
            >
              <input
                style={{
                  position: 'absolute',
                  top: -2,
                  // height: 'auto',
                  // width: 'auto',
                  height: 16,
                  width: 16,
                  margin: 0,
                }}
                type="checkbox"
                name="agree_policy"
                id="agree_policy_sign_up1"
              />
            </div>{' '}
            By using Venuely or, signing up with google to Venuely, I agree to
            Venuely{' '}
            <a
              style={{
                fontSize: 15,
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '500',
              }}
              href="/terms-of-use"
              target="_blank"
            >
              Terms of Service
            </a>
            , Venuely{' '}
            <a
              style={{
                fontSize: 15,
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '500',
              }}
              href="/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}
