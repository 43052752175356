import styles from '../style/notSupportedOverlay.module.css'

import useWindowDimensions from '../hooks/useWindowDimensions'

const NotSupportedOverlay = ({ text, subtext }) => {
  const { width } = useWindowDimensions()
  // Landing page should should not be blocked by this overlay
  const { pathname } = window.location
  if (width < 1000 && pathname === '/' || pathname === '/become-host') {
    return <div />
  }
  return (
    <div className={styles.overlay}>
      <img src="/venuely.svg" alt="" className={styles.overlay__image}></img>
      <div className={styles.overlay__text}>
        <h1 className={styles.overlay__text__value}>{text}</h1>
        <h2 className={styles.overlay__subtext__value}>{subtext}</h2>
      </div>
    </div>
  )
}
export default NotSupportedOverlay
