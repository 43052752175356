import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'
import axios from 'axios'
import disableScroll from 'disable-scroll'
import Cookies from 'universal-cookie'
import { message, Spin, Tooltip, Tag } from 'antd'

import '../../style/InSearchResult.css'
import HostModal from '../../components/popups/HostModal'
import SelectUser from '../../components/login/SelectUser'
import RegistrationForm from '../../components/registration/RegistrationForm'
import { months } from '../../components/utils/months'
import CalendarMenuMain from '../../components/calendar/CalendarMenuMain'
import get12HourFormat from '../../components/utils/get12HourFormat'
import HeaderBar from '../../components/HeaderBar'
import groupIcon from '../../img/grp.svg'
import scaleIcon from '../../img/scale.svg'
import { ToolTipMain, ToolTipTexts } from '../../components/menus/ToolTipMenu'
import defaultProfileSVG from '../../pages/icons/default_profile.svg'
import { timeListArr } from '../../components/utils/timeListArr'
import ShareModal from '../../components/popups/ShareModal'
import PropertyCover from './PropertyCover'

const baseURL = process.env.REACT_APP_BASE_URL
const imgURL = baseURL.split('/api')[0]

const InSearchResult = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch()

  const cookie = new Cookies()
  const authData = cookie.get('jwt')

  const [calendarVisible, setcalendarVisible] = useState(false)
  const [startDatemenu, setstartDatemenu] = useState(false)
  const [endDatemenu, setendDatemenu] = useState(false)
  const startTimeState = location.state?.startTime
  const endTimeState = location.state?.endTime
  const startTimeFromHref = window.location.href.split('/')[6]
  const endTimeFromHref = window.location.href.split('/')[7]
  const [startTime, setStartTime] = useState(
    startTimeFromHref || startTimeState,
  )
  const [endTime, setEndTime] = useState(endTimeFromHref || endTimeState)
  const [hostModal, setHostModal] = useState(false)
  const [invalidFormat, setInvalidFormat] = useState(false)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [room, setRoom] = useState(null)
  const dateValProp = params?.dateVal
  const dateVal2Prop = params?.dateVal2
  const todaysDate = moment().format('YYYY-MM-DD')
  const dateValue = dateValProp || todaysDate
  const dateValue2 = dateVal2Prop || todaysDate
  const [dateVal, setDateVal] = useState(dateValue)
  const [dateVal2, setDateVal2] = useState(dateValue2)
  const [spaceList, setSpaceList] = useState(null)
  const [countCalPress, setcountCalPress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [photoModal, setPhotoModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [inAvailability, setinAvailability] = useState(null)
  const [blockedDate, setblockedDate] = useState(null)
  const [windowDate, setwindowDate] = useState(null)
  const [windowTime, setwindowTime] = useState(null)
  const [bufferTime, setbufferTime] = useState(null)
  const [previousBookingDate, setpreviousBookingDate] = useState(null)
  const [inAvailableTime, setInavailableTime] = useState(false)
  const [availableTimeRange, setAvailableTimeRange] = useState('')
  const [serviceFeePercentage, setServiceFeePercentage] = useState(0)
  const [houseRuleLimit, setHouseRuleLimit] = useState(200)
  const [covidLimit, setCovidLimit] = useState(200)
  const [foodLimit, setFoodLimit] = useState(200)

  useEffect(() => {
    calendarVisible ? disableScroll.on() : disableScroll.off()
  }, [calendarVisible])

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      const classList = e.target.classList
      if (
        classList.contains('left-right-bar') ||
        classList.contains('rightbar')
      ) {
        setcalendarVisible(false)
      }
    })
  }, [])

  useEffect(() => {
    const getSpaceList = async () => {
      try {
        const fetchUrl = `${baseURL}/guest/property/${params.id}`
        const reqData = {
          date: dateVal === dateVal2 ? [dateVal] : [dateVal, dateVal2],
        }
        const res = await axios.post(fetchUrl, reqData)
        if (res.status === 200) {
          const data = res.data
          setRoom(data)
          setSpaceList(res.data)
          setpreviousBookingDate(res.data?.property?.previous_booking)
          setinAvailability(res.data?.property?.in_availability)
          setblockedDate(res.data?.property?.blocked_date)
          setwindowDate(res.data?.property?.window_time?.split(' ')[0])
          setwindowTime(res.data?.property?.window_time?.split(' ')[1])
          setbufferTime(res.data?.property?.buffer_time)
          setLoading(false)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getSpaceList()
    return () => getSpaceList()
  }, [])

  useEffect(() => {
    const format = 'hh:mm:ss'
    const checkAvailability = () => {
      spaceList?.property?.availability.map((item) => {
        if (
          item.day.day.toLowerCase() ===
          moment(dateVal).format('dddd').toString().toLowerCase()
        ) {
          const startTimeItem = moment(item.start, format)
          const endTimeItem = moment(item.end, format)
          const startIsSameOrAfter = moment(startTime, format).isSameOrAfter(
            startTimeItem,
          )
          const startIsSameOrBefore = moment(startTime, format).isSameOrBefore(
            endTimeItem,
          )
          const endIsSameOrAfter = moment(endTime, format).isSameOrAfter(
            startTimeItem,
          )
          const endIsSameOrBefore = moment(endTime, format).isSameOrBefore(
            endTimeItem,
          )
          const checkerArr = [
            startIsSameOrAfter,
            startIsSameOrBefore,
            endIsSameOrAfter,
            endIsSameOrBefore,
          ]
          const checker = checkerArr.every((statement) => statement === true)
          if (checker) {
            setInavailableTime(false)
            setAvailableTimeRange('')
            setInvalidFormat(false)
          } else {
            setInavailableTime(true)
            setInvalidFormat(true)
            setAvailableTimeRange(
              'The available time is between ' +
                moment(item.start, format).format('hh:mm A').toString() +
                ' & ' +
                moment(item.end, format).format('hh:mm A').toString(),
            )
          }
        }
      })
    }
    checkAvailability()
    return () => checkAvailability()
  })

  useEffect(() => {
    axios.get(`${baseURL}/host/global-variables`).then((res) => {
      const fee = res.data.venuely_fee
      setServiceFeePercentage(fee)
    })
  }, [baseURL])

  const percentOfPrice = (price, percentage) => {
    return ((price / 100) * percentage).toFixed(2)
  }
  const datePrettyFormatterSameDate = (dateVal) => {
    return (
      <>
        {months[+dateVal.split('-')[1] - 1]} {dateVal.slice(8, 10)}
      </>
    )
  }
  const datePrettyFormatterDiffDate = (dateVal, dateVal2) => {
    return (
      <>
        {months[+dateVal.split('-')[1] - 1]} {dateVal.slice(8, 10)} {'-'}
        {months[+dateVal2.split('-')[1] - 1]} {dateVal2.slice(8, 10)}
      </>
    )
  }

  const showMore = (item, setItem) => {
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={() => {
          setItem(!item)
          item <= 0 ? setItem(200) : setItem(0)
        }}
      >
        <h4
          style={{
            marginTop: 8,
            textDecoration: 'underline',
            color: '#808080',
            fontSize: 16,
          }}
        >
          {item ? 'Show more' : 'Show less'}
        </h4>
        <img
          src="/dropdown.svg"
          alt=""
          style={{
            margin: 10,
            opacity: 0.5,
            transform: item ? 'none' : 'rotate(180deg)',
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    if (startTime === '' || endTime === '') {
      setInvalidFormat(false)
    } else {
      let beginningTime = moment(startTime, 'h:mma')
      let end = moment(endTime, 'h:mma')
      if (beginningTime.isBefore(end)) {
        setInvalidFormat(false)
      } else {
        setInvalidFormat(true)
      }
    }
  }, [startTime, endTime])

  // id = 1 -> hourly rating
  // id = 2 -> fixed rating
  const pricingCondition = spaceList?.pricing_condition.id
  const rate = spaceList?.price
  const minHourDiscount = spaceList?.minimum_hour_for_discount
  const minNumDiscount = spaceList?.minimum_number_of_hours
  const start = moment(startTime, 'HH:mm:ss a')
  const end = moment(endTime, 'HH:mm:ss a')
  const durationStartEnd = moment.duration(end.diff(start))
  const hoursStartEnd = parseInt(durationStartEnd.asHours())
  const dateDiff = moment(dateVal2).diff(dateVal, 'days') + 1
  const hours = pricingCondition === 1 && !invalidFormat ? hoursStartEnd : 0
  const price =
    pricingCondition === 2 ? rate * dateDiff : rate * hours * dateDiff
  const cleaningFee =
    spaceList?.cleaning_fee.id !== 1 ? spaceList?.additional_cleaning_fee : 0
  const serviceFee = percentOfPrice(price, serviceFeePercentage)
  const discount =
    hours >= minHourDiscount ? percentOfPrice(price, spaceList?.discount) : 0
  let transactionCost =
    (2.9 / 100) *
      (+price +
        +cleaningFee +
        +percentOfPrice(price, serviceFeePercentage) -
        +discount) +
    0.3
  let total =
    +price +
    +cleaningFee +
    +transactionCost +
    +percentOfPrice(price, serviceFeePercentage) -
    +discount

  const serviceFeeWithTransaction = +serviceFee + +transactionCost

  const startTimeValue = () => {
    if (startTime === '') {
      return ''
    } else {
      if (pricingCondition === 2) {
        return get12HourFormat(spaceList?.time_start)
      }
      return get12HourFormat(startTime)
    }
  }
  const endTimeValue = () => {
    if (endTime === '') {
      return ''
    } else {
      if (pricingCondition === 2) {
        return get12HourFormat(spaceList?.time_end)
      }
      return get12HourFormat(endTime)
    }
  }

  useEffect(() => {
    if (pricingCondition === 2) {
      setStartTime(spaceList?.time_start)
      setEndTime(spaceList?.time_end)
    }
  }, [spaceList?.time_start, spaceList?.time_end, pricingCondition])
  useEffect(() => {
    const photoModalEvent = (ev) => {
      const targetId = ev.target.id
      if (targetId === 'carousel-bg') setPhotoModal(true)
      if (targetId === 'photo-modal-backdrop') setPhotoModal(false)
    }
    document.addEventListener('mousedown', photoModalEvent)
    return () => document.removeEventListener('mousedown', photoModalEvent)
  }, [])

  const handleStartBooking = () => {
    if (availableTimeRange) {
      message.error('Please fix the time first')
      return
    }
    const firstTime = moment(startTime, 'HH:mm:ss')
    const secondTime = moment(endTime, 'HH:mm:ss')

    if (firstTime.isAfter(secondTime)) {
      // console.log('The first time is greater than the second time')
      message.error(
        'Start time can not be greater than end time, please fix your times.',
      )
      return
    } else {
      // console.log('The second time is greater than or equal to the first time')
      setEndTime(endTime)
    }
    navigate('/renter/booking', {
      state: {
        room: spaceList,
        dateVal,
        dateVal2,
        startTime,
        endTime,
      },
    })
  }

  return (
    <div>
      <ShareModal shareModal={shareModal} setShareModal={setShareModal} />
      <HeaderBar customStyles={{ paddingLeft: 50, width: '100%' }} />
      {loading ? (
        <div style={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div style={{ display: showRegistrationForm ? 'flex' : 'none' }}>
            <RegistrationForm
              closeRegistrationFormCart={setShowRegistrationForm}
            />
          </div>
          <div style={{ display: showLoginForm ? 'flex' : 'none' }}>
            <SelectUser closeRegistrationFormCart={setShowLoginForm} />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center', margin: 25 }}
          >
            <ShareModal />
            <PropertyCover
              photoModal={photoModal}
              setShareModal={setShareModal}
              dateVal={dateVal}
              dateVal2={dateVal2}
              loading={loading}
              spaceList={spaceList}
            />
          </div>
          <div
            className="left-right-bar"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              // border: '2px solid blue',
            }}
          >
            <div className="leftBar" style={{ width: '37vw' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <h3
                    style={{
                      margin: '10px 0px',
                      fontSize: 24,
                      fontWeight: 500,
                    }}
                  >
                    {spaceList?.property.name}
                  </h3>
                  <h4 style={{ margin: '20px 0px', fontSize: 16 }}>
                    {spaceList?.property.address}
                  </h4>
                </div>
                <Link to={`/profile/${spaceList?.property?.host?.unique_id}`}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: 10,
                    }}
                  >
                    {/* <p>{JSON.stringify(spaceList?.property?.host)}</p> */}
                    <img
                      src={
                        spaceList?.property?.host?.image === null
                          ? defaultProfileSVG
                          : `${imgURL}${spaceList?.property?.host?.image}`
                      }
                      alt=""
                      style={{
                        margin: 10,
                        objectFit: 'cover',
                        width: 80,
                        height: 80,
                        borderRadius: '100%',
                      }}
                    />
                    <label
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        width: 150,
                        textTransform: 'capitalize',
                        textAlign: 'center',
                        color: '#000',
                      }}
                    >
                      {spaceList?.property.host['name']}
                    </label>
                  </div>
                </Link>
              </div>
              <div className="grp-clk-scale">
                <div>
                  <img src={groupIcon} alt="" />
                  <h4 style={{ fontSize: 16 }}>
                    {spaceList?.property.description.capacity}
                  </h4>
                </div>
                <div>
                  <img src={scaleIcon} alt="" />
                  <h4 style={{ fontSize: 16 }}>
                    {spaceList?.property.description.dimention} sqft
                  </h4>
                </div>
              </div>
              <img
                src="/horline.svg"
                alt=""
                style={{ width: 700, marginTop: 40 }}
              />
              <div>
                <div style={{ marginTop: 20 }}>
                  <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                    About this space
                  </h3>
                  <p
                    style={{ marginTop: 20, fontSize: 16, overflow: 'hidden' }}
                  >
                    {spaceList?.property?.description.description}
                  </p>
                </div>
                <img
                  src="/horline.svg"
                  alt=""
                  style={{ width: 700, marginTop: 40 }}
                />
                <div style={{ marginTop: 20 }}>
                  <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                    What this place offers
                  </h3>
                  {spaceList?.property?.amenity.length !== 0 && (
                    <>
                      <h5
                        style={{
                          fontWeight: 700,
                          margin: '20px 0',
                          fontSize: 16,
                        }}
                      >
                        Amenities
                      </h5>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 1fr',
                          gap: 10,
                        }}
                      >
                        {spaceList?.property?.amenity.map((item, idx) => {
                          return (
                            <h5 key={idx} style={{ fontSize: 16 }}>
                              {item}
                            </h5>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
                {spaceList?.property?.house_restriction?.rules !== null && (
                  <>
                    {spaceList?.property?.house_restriction?.rules?.length !==
                      0 && (
                      <>
                        <img
                          src="/horline.svg"
                          alt=""
                          style={{ width: 700, marginTop: 40 }}
                        />
                        <div style={{ marginTop: 20 }}>
                          <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                            House rules
                          </h3>
                          <p
                            style={{
                              marginTop: 20,
                              fontSize: 16,
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {JSON.parse(
                              JSON.stringify(
                                spaceList?.property.house_restriction?.rules,
                              ),
                            ).slice(
                              0,
                              houseRuleLimit <= 0
                                ? spaceList?.property.house_restriction.rules
                                    ?.length
                                : houseRuleLimit,
                            )}
                          </p>
                          {showMore(houseRuleLimit, setHouseRuleLimit)}
                        </div>
                      </>
                    )}
                  </>
                )}
                {spaceList?.property?.house_restriction?.insurance_need ===
                  true && (
                  <>
                    <img
                      src="/horline.svg"
                      alt=""
                      style={{ width: 700, marginTop: 40 }}
                    />
                    <div style={{ marginTop: 20 }}>
                      <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                        Venuely's Insurance Policy
                      </h3>
                      <p
                        style={{
                          marginTop: 20,
                          fontSize: 16,
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        This host requires all guests to provide commercial
                        liability insurance in order to host events in this
                        space. You can purchase an insurance certificate and
                        provide this certificate of insurance documents via
                        email with the host before the date of the event.
                        Insurance can be purchased via our partner,{' '}
                        <a
                          href="https://www.eventsured.com/venuely/"
                          target={'_blank'}
                          style={{
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                          }}
                        >
                          Eventsured,
                        </a>{' '}
                        at checkout or via your chosen vendor.
                      </p>
                    </div>
                  </>
                )}
                {spaceList?.property?.house_restriction?.restriction !==
                  null && (
                  <>
                    {spaceList?.property?.house_restriction?.restriction
                      ?.length !== 0 && (
                      <>
                        <img
                          src="/horline.svg"
                          alt=""
                          style={{ width: 700, marginTop: 40 }}
                        />
                        <div style={{ marginTop: 20 }}>
                          <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                            Food and beverage restrictions
                          </h3>
                          <h5
                            style={{
                              marginTop: 20,
                              fontSize: 16,
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            {JSON.parse(
                              JSON.stringify(
                                spaceList?.property.house_restriction
                                  ?.restriction,
                              ),
                            ).slice(
                              0,
                              foodLimit <= 0
                                ? spaceList?.property.house_restriction
                                    ?.restriction.length
                                : foodLimit,
                            )}
                          </h5>
                          {showMore(foodLimit, setFoodLimit)}
                        </div>
                      </>
                    )}
                  </>
                )}
                {spaceList?.property.house_restriction?.cleaning_process
                  .length !== 0 && (
                  <img
                    src="/horline.svg"
                    alt=""
                    style={{ width: 700, marginTop: 40 }}
                  />
                )}
                {spaceList?.property.house_restriction?.cleaning_process
                  .length !== 0 && (
                  <div style={{ marginTop: 20 }}>
                    <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                      COVID-19 Health & Safety Measures
                    </h3>
                    <h5
                      style={{
                        marginTop: 20,
                        fontSize: 16,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {JSON.parse(
                        JSON.stringify(
                          spaceList?.property.house_restriction
                            ?.cleaning_process,
                        ),
                      )}
                    </h5>
                    {spaceList?.property.house_restriction?.Social_distancing
                      .length !== 0 && (
                      <div>
                        <h5 style={{ fontSize: 18, marginTop: 20 }}>
                          Social distancing
                        </h5>
                        <div style={listStyle}>
                          {spaceList?.property.house_restriction?.Social_distancing.map(
                            (item, idx) => {
                              return (
                                <>
                                  <li
                                    key={idx}
                                    style={{
                                      fontSize: 16,
                                      listStyle: 'inside',
                                    }}
                                  >
                                    {/* {item.title.length >= 20
                                          ? item.title.slice(0, 20)
                                          : item.title} */}
                                    {item.title}
                                  </li>
                                </>
                              )
                            },
                          )}
                        </div>
                      </div>
                    )}
                    {spaceList?.property.house_restriction?.cleaning_and_hygiene
                      .length !== 0 && (
                      <>
                        <h5 style={{ fontSize: 18, marginTop: 20 }}>
                          Cleaning and Hygiene
                        </h5>
                        <div style={listStyle}>
                          {spaceList?.property.house_restriction?.cleaning_and_hygiene.map(
                            (item, idx) => {
                              return (
                                <>
                                  <li
                                    key={idx}
                                    style={{
                                      fontSize: 16,
                                      listStyle: 'inside',
                                    }}
                                  >
                                    {/* {item.title.length >= 20
                                          ? item.title.slice(0, 20)
                                          : item.title} */}
                                    {item.title}
                                  </li>
                                </>
                              )
                            },
                          )}
                        </div>
                      </>
                    )}
                    {spaceList?.property.house_restriction
                      ?.Protect_people_and_detect.length !== 0 && (
                      <>
                        <h5 style={{ fontSize: 18, marginTop: 20 }}>
                          Protect people and detect
                        </h5>
                        <div style={listStyle}>
                          {spaceList?.property.house_restriction?.Protect_people_and_detect.map(
                            (item, idx) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      fontSize: 16,
                                      listStyle: 'inside',
                                    }}
                                  >
                                    {/* {item.title.length >= 20
                                          ? item.title.slice(0, 20)
                                          : item.title} */}
                                    {item.title}
                                  </li>
                                </>
                              )
                            },
                          )}
                        </div>
                      </>
                    )}
                    {spaceList?.property.house_restriction?.communicating_rules
                      .length !== 0 && (
                      <>
                        <h5 style={{ fontSize: 18, marginTop: 20 }}>
                          Communicating Rules
                        </h5>
                        <div style={listStyle}>
                          {spaceList?.property.house_restriction?.communicating_rules.map(
                            (item, idx) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      fontSize: 16,
                                      listStyle: 'inside',
                                    }}
                                  >
                                    {/* {item.title.length >= 20
                                          ? item.title.slice(0, 20)
                                          : item.title} */}
                                    {item.title}
                                  </li>
                                </>
                              )
                            },
                          )}
                        </div>
                      </>
                    )}
                    {spaceList?.property.house_restriction
                      ?.emergency_response_plans.length !== 0 && (
                      <>
                        <h5 style={{ fontSize: 18, marginTop: 20 }}>
                          Emergency response plans
                        </h5>
                        <div style={listStyle}>
                          {spaceList?.property.house_restriction?.emergency_response_plans.map(
                            (item, idx) => {
                              return (
                                <>
                                  <li
                                    style={{
                                      fontSize: 16,
                                      listStyle: 'inside',
                                    }}
                                  >
                                    {/* {item.title.length >= 20
                                          ? item.title.slice(0, 20)
                                          : item.title} */}
                                    {item.title}
                                  </li>
                                </>
                              )
                            },
                          )}
                        </div>
                      </>
                    )}
                    {/* {showMore(covidLimit, setCovidLimit)} */}
                  </div>
                )}
                <img
                  src="/horline.svg"
                  alt=""
                  style={{ width: 700, marginTop: 40 }}
                />
                <div style={{ marginTop: 20 }}>
                  <h2 style={{ marginBottom: 20, fontSize: 24 }}>Location</h2>
                  <div style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                      }}
                      defaultCenter={{ lat: 41, lng: -73 }}
                      center={{
                        lat: spaceList?.property.latitude,
                        lng: spaceList?.property.longitud,
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      draggable={false}
                      defaultZoom={11}
                      zoom={11}
                    >
                      <div
                        style={{
                          width: 150,
                          height: 150,
                          marginLeft:
                            (((Math.random() * 75) | 0) + 75 / 2) * -1,
                          marginTop: (((Math.random() * 75) | 0) + 75 / 2) * -1,
                          borderRadius: 150,
                          backgroundColor: 'rgb(101, 104, 230)',
                          opacity: 0.5,
                        }}
                      ></div>
                    </GoogleMapReact>
                  </div>
                </div>
                <img
                  src="/horline.svg"
                  alt=""
                  style={{ width: 700, marginTop: 40 }}
                />
                <div className="operation-hours">
                  <h3 style={{ marginBottom: 0, fontSize: 24 }}>
                    Operation hours
                  </h3>
                  <div style={{ marginTop: 20 }}>
                    {spaceList?.property.availability.map((item, idx) => {
                      return (
                        <>
                          <div className="operation">
                            <h5 style={{ fontSize: 16 }}>{item.day['day']}</h5>
                            <div style={{ display: 'flex' }}>
                              <h5 style={{ fontSize: 16 }}>
                                {new Date(
                                  '1970-01-01T' + item.start + 'Z',
                                ).toLocaleTimeString('en-US', {
                                  timeZone: 'UTC',
                                  hour12: true,
                                  hour: 'numeric',
                                  minute: 'numeric',
                                })}
                              </h5>
                              <h5 style={{ marginLeft: -16, fontSize: 16 }}>
                                {new Date(
                                  '1970-01-01T' + item.end + 'Z',
                                ).toLocaleTimeString('en-US', {
                                  timeZone: 'UTC',
                                  hour12: true,
                                  hour: 'numeric',
                                  minute: 'numeric',
                                })}
                              </h5>
                            </div>
                            {/* <h5>4-11pm</h5> */}
                          </div>
                        </>
                      )
                    })}
                  </div>
                  <img
                    src="/horline.svg"
                    alt=""
                    style={{ width: 700, marginTop: 40 }}
                  />
                  <div style={{ marginTop: 20, marginBottom: 80 }}>
                    <h3 style={{ fontSize: 24 }}>Cancellation policy</h3>
                    <h5 style={{ marginTop: 20, fontSize: 16 }}>
                      {spaceList?.property.cancellation_policy.description}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="rightbar" style={{ marginBottom: 20 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1>${spaceList?.price.toFixed(2)}</h1>
                <h1 style={{ marginLeft: 5, fontSize: 16 }}>
                  /{pricingCondition === 1 ? 'hr' : 'day'}
                </h1>
              </div>
              <h4 style={{ color: '#1B7E69', fontWeight: 400, fontSize: 14 }}>
                {pricingCondition === 2
                  ? 'Fixed rate'
                  : minNumDiscount + ' hr minimum'}
              </h4>

              {spaceList?.property?.house_restriction?.insurance_need ===
                true && (
                <p>
                  <Tag color="#dadbf5">
                    <span style={{ color: '#6568e6' }}>
                      * Commercial Liability Insurance required
                    </span>
                  </Tag>
                </p>
              )}

              <img src="/horline.svg" alt="" />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '10px 0px',
                  alignItems: 'center',
                }}
              >
                {pricingCondition === 1 && (
                  <h4 style={{ fontSize: 16 }}>
                    {minHourDiscount}+ hours discount
                  </h4>
                )}
                {pricingCondition === 1 && (
                  <h4
                    style={{
                      backgroundColor: '#1B7E69',
                      color: '#fff',
                      fontFamily: 'inter',
                      fontSize: 12,
                      fontWeight: 400,
                      padding: '5px 10px',
                      borderRadius: 6,
                    }}
                  >
                    {spaceList?.discount}% DISCOUNT
                  </h4>
                )}
              </div>

              <div style={{ border: '1px solid white', maxWidth: 380 }}>
                <p style={{ fontSize: 16 }}>
                  *Message the host before checkout for inquiring about
                  non-profit/fundraiser discount code{' '}
                </p>
              </div>

              {pricingCondition === 1 && <img src="/horline.svg" alt="" />}
              <div
                className="date-dropdown"
                style={{ marginTop: 20 }}
                onClick={() => setcalendarVisible(!calendarVisible)}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                  }}
                >
                  <h5 style={{ fontWeight: 'bold', fontSize: 12 }}>Date</h5>
                  {dateVal === dateVal2 ? (
                    <h4 style={{ fontSize: 14 }}>
                      {datePrettyFormatterSameDate(dateVal)}
                    </h4>
                  ) : (
                    <h4 style={{ fontSize: 14 }}>
                      {datePrettyFormatterDiffDate(dateVal, dateVal2)}
                    </h4>
                  )}
                </div>
                <img src="/dropdown.svg" alt="" style={{ marginLeft: 160 }} />
              </div>
              {calendarVisible ? (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: -410,
                    marginLeft: -88,
                  }}
                >
                  <CalendarMenuMain
                    dateVal={dateVal}
                    setDateVal={setDateVal}
                    dateVal2={dateVal2}
                    setDateVal2={setDateVal2}
                    countCalPress={countCalPress}
                    setcountCalPress={setcountCalPress}
                    previousBookingDate={previousBookingDate}
                    calendarVisible={calendarVisible}
                    setcalendarVisible={setcalendarVisible}
                    inAvailability={inAvailability}
                    blockedDate={blockedDate}
                    windowDate={windowDate}
                    windowTime={windowTime}
                    bufferTime={bufferTime}
                    width={430}
                  />
                </div>
              ) : null}
              <Tooltip
                title={
                  pricingCondition === 2
                    ? `This property is in fixed rate, you can't change the time. Message the host if you need more help.`
                    : ``
                }
                color={'#6568e6'}
              >
                <div
                  className="start-end-date"
                  style={{ margin: 0, width: '100%' }}
                >
                  <div
                    className="start-end"
                    onClick={() => {
                      if (pricingCondition === 2) {
                        return
                      }
                      setstartDatemenu(!startDatemenu)
                      setendDatemenu(false)
                    }}
                    style={{
                      borderRadius: '0px 0px 0px 7px',
                      height: 60,
                      width: 200,
                      border: invalidFormat
                        ? '1px #DF3416 solid'
                        : '1px #ccc solid',
                      borderRightWidth: '0px',
                    }}
                  >
                    <div className="date-btn">
                      <h5
                        style={{
                          fontWeight: 'bold',
                          color: invalidFormat ? '#DF3416' : '#000',
                        }}
                      >
                        Start time
                      </h5>
                      <label className="label-start-end-date2">
                        {startTimeValue()}
                      </label>
                      {
                        <ul
                          className="date-menu2 date-menu-common"
                          style={{
                            opacity: startDatemenu ? 1 : 0,
                            visibility: startDatemenu ? 'visible' : 'hidden',
                          }}
                        >
                          {timeListArr.map((item, idx) => {
                            return (
                              <label
                                key={idx}
                                className={
                                  item === startTime
                                    ? 'time-text2-focus'
                                    : 'time-text2'
                                }
                                onClick={(e) => {
                                  setStartTime(item)
                                }}
                              >
                                {get12HourFormat(item)}
                              </label>
                            )
                          })}
                        </ul>
                      }
                    </div>
                    <img
                      src="/dropdown.svg"
                      style={{ opacity: pricingCondition === 2 ? 0 : 1 }}
                      alt=""
                    />
                  </div>
                  <div
                    className="start-end"
                    onClick={() => {
                      if (pricingCondition === 2) {
                        return
                      }
                      setendDatemenu(!endDatemenu)
                      setstartDatemenu(false)
                    }}
                    style={{
                      borderRadius: '0px 0px 7px 0px',
                      height: 60,
                      padding: 5,
                      width: 200,
                      border: invalidFormat
                        ? '1px #DF3416 solid'
                        : '1px #ccc solid',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <h5
                        style={{
                          fontWeight: 'bold',
                          color: invalidFormat ? '#DF3416' : '#000',
                        }}
                      >
                        End time
                      </h5>
                      <label className="label-start-end-date2">
                        {endTimeValue()}
                      </label>
                      {endDatemenu && (
                        <ul className="date-menu2 date-menu-common">
                          {timeListArr.map((item, idx) => {
                            return (
                              <label
                                key={idx}
                                className={
                                  item === endTime
                                    ? 'time-text2-focus'
                                    : 'time-text2'
                                }
                                onClick={(e) => setEndTime(item)}
                              >
                                {get12HourFormat(item)}
                              </label>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                    <img
                      src="/dropdown.svg"
                      style={{ opacity: pricingCondition === 2 ? 0 : 1 }}
                      alt=""
                    />
                  </div>
                </div>
              </Tooltip>
              {inAvailableTime && (
                <h4 style={{ fontSize: 14, color: 'red', margin: 5 }}>
                  {availableTimeRange}
                </h4>
              )}
              <div className="prices">
                <h3 style={{ fontWeight: 'bold', fontSize: 16 }}>Price</h3>
                <div className="stuff-prices">
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5 style={{ fontSize: 16 }}>
                      ${rate.toFixed(2)}{' '}
                      {pricingCondition === 1 && 'x ' + hours}{' '}
                      {pricingCondition === 1 && 'hours'} x {dateDiff} day(s)
                    </h5>
                    <Tooltip
                      title={ToolTipMain(ToolTipTexts.price)}
                      placement="bottomLeft"
                      color="#fff"
                    >
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </Tooltip>
                  </div>
                  <h5 style={{ fontSize: 16 }}>${price.toFixed(2)}</h5>
                </div>
                <div className="stuff-prices">
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5 style={{ fontSize: 16 }}>Cleaning fee</h5>
                    <Tooltip
                      title={ToolTipMain(ToolTipTexts.cleaningFee)}
                      placement="bottomLeft"
                      color="#fff"
                    >
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </Tooltip>
                  </div>
                  <h5 style={{ fontSize: 16 }}>$ {cleaningFee.toFixed(2)}</h5>
                </div>
                <div className="stuff-prices">
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5 style={{ fontSize: 16 }}>Service fee</h5>
                    <Tooltip
                      title={ToolTipMain(ToolTipTexts.serviceFee)}
                      placement="bottomLeft"
                      color="#fff"
                    >
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </Tooltip>
                  </div>

                  <h5 style={{ fontSize: 16 }}>
                    $
                    {(
                      Math.round(serviceFeeWithTransaction * 100) / 100
                    ).toFixed(2)}
                  </h5>
                </div>
                <div className="stuff-prices">
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <h5 style={{ fontSize: 16 }}>Discount</h5>
                    <Tooltip
                      title={ToolTipMain(ToolTipTexts.discount)}
                      placement="bottomLeft"
                      color="#fff"
                    >
                      <img
                        src="/moreinfo.svg"
                        alt=""
                        style={{ width: 22, marginLeft: 10 }}
                      />
                    </Tooltip>
                  </div>
                  <h5 style={{ color: '#808080', fontSize: 16 }}>
                    ${parseFloat(discount)?.toFixed(2)}
                  </h5>
                </div>
              </div>
              <img src="/horline.svg" alt="" />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>Total</h4>
                <h4 style={{ fontWeight: 'bold', fontSize: 16 }}>
                  ${total.toFixed(2)}
                </h4>
              </div>
              <button
                className="booking-btn"
                onClick={() => handleStartBooking()}
              >
                Start Booking
              </button>
              <button
                className="booking-btn btn-outline"
                onClick={() => {
                  if (!authData || authData === undefined) {
                    setHostModal(false)
                    message.error('Please Log in first')
                  } else {
                    setcalendarVisible(false)
                    setHostModal(true)
                  }
                }}
              >
                Message the host
              </button>
              <HostModal
                hostModal={hostModal}
                setHostModal={setHostModal}
                room={room}
                dateVal={dateVal}
                dateVal2={dateVal2}
                setDateVal={setDateVal}
                setDateVal2={setDateVal2}
                capacity={spaceList?.property.description.capacity}
                optOutActivities={spaceList?.opt_out_activities}
                spaceList={spaceList}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const styles = {
  loading: {
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}

const listStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 10,
  width: '110%',
  marginTop: 20,
}

export default InSearchResult
