import React from 'react'
import { Button, Result } from 'antd'
import { NavLink, useNavigate } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'

function ErrorFallBack({ error }) {
  const navigate = useNavigate()
  console.log('ErrorFallBack', error)

  return (
    <>
      {' '}
      <div style={styles.navContainerMobile}>
        <img
          src="/venuely.svg"
          alt=""
          className="nav-logo"
          onClick={(event) => {
            if (event.ctrlKey || event.metaKey) {
              window.open(window.location.host, '_blank')
            }
          }}
        />
      </div>
      <Result
        // status="warning"
        icon={<WarningOutlined className="warning-outline-error-boundary" />}
        title="There are some problems with your operation. Please try again later."
        extra={
          // <NavLink to="/">
          <Button
            type="primary"
            key="console"
            onClick={() => {
              window.open(process.env.REACT_APP_BASE_URL2, '_self')
            }}
          >
            Go to home
          </Button>
        }
      />
    </>
  )
}

const styles = {
  navContainerMobile: {
    padding: '30px 20px',
    boxShadow: `1px 1px 5px 0 rgba(0, 0, 0, 0.25)`,
  },
}

export default ErrorFallBack
