import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBanner } from './../../../redux/actions/ComponentAction'
import { useNavigate } from 'react-router-dom'
import pentagon from './../../../img/pentagon.png'
import dots from './../../../img/dot.png'

import stripeImage from './../../../img/strip.png'
import bankImage from './../../../img/bank.png'
import { setInformation } from '../../../redux/actions/InformationAction'
import { useSearchParams } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications'

export default function PayoutDetails() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [haveAddedBank, sethaveAddedBank] = useState(false)
  const [bankInfo, setBankInfo] = useState(null)
  const details = useSelector((state) => state.information)
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (loading) {
      setStripeButtonIsLoading(true)
      if (searchParams.get("success")) {
        NotificationManager.success(
          `Successful`,
          'banl account added successfully',
          700,
        )
      };
      dispatch(
        setBanner({
          header: 'Payout Method',
          discription: 'Add your preferred methods to receive your payouts',
          button: {
            // visible: details.details_submitted
            //   ? !details.details_submitted
            //   : true,
            visible: false, // hide always for now
            link: '/payment/banking/payout-details',
            name: 'Add your bank',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            console.log(details)
            setStripeButtonIsLoading(false)
            // dispatch( setInformation( {
            //   callBack:(response)=>{
            //     sethaveAddedBank(response.data.payment_information.length > 0);
            //     if(response.data.payment_information.length > 0){
            //       setBankInfo({
            //         "charges_enabled":response.data.charges_enabled,
            //         "details_submitted":response.data.charges_enabled,
            //         "payouts_enabled":response.data.charges_enabled,
            //         "link":response.data.link,
            //       });
            //     }
            //   }
            // }))

            setLoading(false)
          },
        }),
      )
    }
  }, [loading])

  const [stripeButtonIsLoading, setStripeButtonIsLoading] = useState(false)
  let got_to_stripe = () => {
    setStripeButtonIsLoading(true)
    dispatch(
      setInformation({
        callBack: (response) => {
          window.open(response.data.link, '_blank')
          setStripeButtonIsLoading(false)
        },
      }),
    )
  }

  return (
    <div className="payout-details-wrapper">
      <NotificationContainer />
      <h1>Direct Deposit</h1>
      <p>All payouts will be paid to the following account</p>
      <div className="cash-out-methodes">
        {/* {
          haveAddedBank ? 
          <div className="cash-chacking-button">
            <img src={pentagon} alt="" srcSet="" />
            <p>{bankInfo && bankInfo.bank_name} ....{bankInfo && bankInfo.last4}</p>
            <img src={dots} alt="" srcSet="" />
          </div> : ''
        } */}
        <div className="bank-chash-out">
          <img src={bankImage} alt="" />
          {/* <a onClick={()=>{navigate(`/payment/banking/add/bank-account-details`)}} className="bank-cash-out-button"> */}
          {/* <a href={details != undefined ? details.link : ''} target="_blank" className="bank-cash-out-button">
            { details != undefined ? details.details_submitted ? 'Check your account balence' : 'Add a bank account' : ''}
          </a> */}
          <a onClick={got_to_stripe} className="bank-cash-out-button">
            {stripeButtonIsLoading ? (
              <div
                style={{
                  width: '33px',
                  height: '33px',
                  top: '7px',
                }}
                className="loader"
              ></div>
            ) : details != undefined ? (
              details.details_submitted ? (
                'Check your account balence'
              ) : (
                'Add a bank account'
              )
            ) : (
              ''
            )}
          </a>
        </div>
        {/* <div className="stripe-chash-out">
          <img src={stripeImage} alt="" />
          <div onClick={()=>{navigate(`/payment/banking/add/payout-details`)}} className="stripe-cash-out-button">
            Verify with Stripe
          </div>
        </div> */}
      </div>
      <hr className="space-setup-inserte-activities-last-div" />
      <div className="about-space-insert-button-group">
        {/* <button type="button" > Submit </button> */}
        <p>
          By clicking Add a bank account, you agree to{' '}
          <a
            target="_blank"
            href="https://docs.google.com/document/d/1JUFg98N_4kqd6FLBNj15w-dd_I9O3ZpB/edit?usp=sharing&ouid=107292513541425478408&rtpof=true&sd=true"
            className="active-link"
          >
            {' '}
            Venuely’s Service Agreement
          </a>
          , and{' '}
          <a
            target="_blank"
            href="https://stripe.com/legal"
            className="active-link"
          >
            Stripe’s Services Agreement{' '}
          </a>
          which includes the{' '}
          <a
            target="_blank"
            href="https://stripe.com/legal/connect-account"
            className="active-link"
          >
            Stripe Connected Account Agreement
          </a>
          .
        </p>
      </div>
    </div>
  )
}
