import React, { useEffect, useState, createRef } from 'react'
import arrowDown from './../img/arrow_down_1.png'
import doneButtonImage from './../img/done.png'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { updatePricing, createPricing } from '../redux/actions/PricingAction'
import { selectSpace, setSpace } from '../redux/actions/SpaceAction'
import { tConvert, time_list, titleCase } from '../redux/requests'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { setInformation } from '../redux/actions/InformationAction'

export default function ActivitieBox({
  property_id,
  pricing,
  setActivitiesFormData,
}) {
  const [displayList, setDisplayList] = useState(true)
  const prev_pricing = useSelector(
    (state) => state.space.space.activities_and_pricing,
  )
  const space = useSelector((state) => state.space.space)
  const [showformComponent, setShowformComponent] = useState(
    prev_pricing[pricing.id] != undefined ? true : false,
  )

  let formRef = createRef()
  const [timeRange, setTimeRange] = useState({
    start: '8:00 am',
    end: '10:00 pm',
  })
  const pricing_condition_option = useSelector(
    (state) => state.information.form_data.pricing_condition_option,
  )
  const cleaning_fee_option = useSelector(
    (state) => state.information.form_data.cleaning_fee_option,
  )
  const stateForValidation = useSelector((state) => state.information.control)
  const [showPriceRange, setShowPriceRange] = useState(true)
  const [showAditionalFeeInputBox, setShowAditionalFeeInputBox] = useState(true)
  const dispatch = useDispatch()
  const [dayList, setDayList] = useState([])
  let defineDayList = () => {
    let day_list = []
    for (let key in space.availability) {
      day_list.push({
        name: key,
        id: space.availability[key].id,
      })
    }
    setDayList(day_list)
  }
  useEffect(() => {
    if (prev_pricing[pricing.id]) {
      setShowformComponent(prev_pricing[pricing.id].is_activate)
    }
    defineDayList()
    if (prev_pricing[pricing.id]) {
      let day_id_list = prev_pricing[pricing.id].day.map((d) => d.id)
      setSelectedDayList(day_id_list)
      formRef.current.elements.pricing_condition.value =
        prev_pricing[pricing.id].pricing_condition.id
      formRef.current.elements.pricing_condition.value =
        prev_pricing[pricing.id].pricing_condition.id
      formRef.current.elements.price.value = prev_pricing[pricing.id].price
      formRef.current.elements.cleaning_fee.value =
        prev_pricing[pricing.id].cleaning_fee.id
      formRef.current.elements.minimum_number_of_hours.value =
        prev_pricing[pricing.id].minimum_number_of_hours
      formRef.current.elements.discount.value =
        prev_pricing[pricing.id].discount
      formRef.current.elements.additional_cleaning_fee.value =
        prev_pricing[pricing.id].additional_cleaning_fee

      formRef.current.elements.time_start.value =
        prev_pricing[pricing.id].time_start
      formRef.current.elements.time_end.value =
        prev_pricing[pricing.id].time_end
      let is_pricing_condition_fixed =
        prev_pricing[pricing.id].pricing_condition.title == 'Fixed Rate'
      let is_additional_fee =
        prev_pricing[pricing.id].cleaning_fee.title == 'Additional flat fee'
      setShowPriceRange(is_pricing_condition_fixed)
      setShowAditionalFeeInputBox(is_additional_fee)
    } else {
      // console.log(cleaning_fee_option[0].id,pricing_condition_option[0].id);
      formRef.current.elements.pricing_condition.value =
        pricing_condition_option[0].id
      formRef.current.elements.cleaning_fee.value = cleaning_fee_option[0].id
      setShowPriceRange(false)
      setShowAditionalFeeInputBox(false)
    }
  }, [5])

  let handelSubmit = (e) => {
    e.preventDefault()
    let form = e.target.elements
    let data = {
      property: property_id,
      Pricing_type: pricing.id,
      pricing_condition:
        form.pricing_condition.value == ''
          ? pricing_condition_option[0].id
          : form.pricing_condition.value,
      price: form.price.value,
      cleaning_fee:
        form.cleaning_fee.value == ''
          ? cleaning_fee_option[0].id
          : form.cleaning_fee.value,
      minimum_number_of_hours:
        form.minimum_number_of_hours.value == ''
          ? 0
          : form.minimum_number_of_hours.value,
      discount: form.discount.value == '' ? 0 : form.discount.value,
      day: [...selectedDayList],
    }

    if (form.time_start && form.time_start.value)
      data.time_start = form.time_start.value
    if (form.time_end && form.time_end.value)
      data.time_end = form.time_end.value
    if (form.day_start && form.day_start.value)
      data.day_start = form.day_start.value
    if (form.day_end && form.day_end.value) data.day_end = form.day_end.value
    if (form.additional_cleaning_fee && form.additional_cleaning_fee.value)
      data.additional_cleaning_fee = form.additional_cleaning_fee.value
    data.id = prev_pricing[pricing.id] ? prev_pricing[pricing.id].id : null
    // console.log(data);
    // setAvailabilityBoxData(data)

    if (data.id != null) {
      dispatch(
        updatePricing({
          id: data.id,
          ...data,
          callBack: (response) => {
            dispatch(
              selectSpace({
                id: data['property'],
                callBack: (response) => {
                  dispatch(
                    setInformation({
                      callBack: (response) => {
                        NotificationManager.success(
                          `Successfully Updated`,
                          'pricing',
                          700,
                        )
                      },
                    }),
                  )
                },
              }),
            )
          },
        }),
      )
    }

    if (data.id == null) {
      dispatch(
        createPricing({
          ...data,
          callBack: (response) => {
            if (response.status == 400) {
              Object.keys(response.data).map((key) => {
                NotificationManager.warning(
                  `${response.data[key]}`,
                  key.replaceAll('_', ' ').toLowerCase(),
                  3000,
                )
              })
            } else {
              dispatch(
                setInformation({
                  callBack: (response) => {
                    dispatch(
                      selectSpace({
                        id: data['property'],
                        callBack: (response) => {
                          NotificationManager.success(
                            `Successfully created`,
                            'pricing',
                            700,
                          )
                        },
                      }),
                    )
                  },
                }),
              )
            }
          },
        }),
      )
    }
  }

  let getFormatedData = () => {
    const form = formRef.current.elements
    let data = {
      property: property_id,
      Pricing_type: pricing.id,
      pricing_condition:
        form.pricing_condition.value == ''
          ? pricing_condition_option[0].id
          : form.pricing_condition.value,
      price: form.price.value,
      cleaning_fee:
        form.cleaning_fee.value == ''
          ? cleaning_fee_option[0].id
          : form.cleaning_fee.value,
      minimum_number_of_hours:
        form.minimum_number_of_hours.value == ''
          ? 0
          : form.minimum_number_of_hours.value,
      discount: form.discount.value == '' ? 0 : form.discount.value,
      day: [...selectedDayList],
    }

    if (form.time_start && form.time_start.value)
      data.time_start = form.time_start.value
    if (form.time_end && form.time_end.value)
      data.time_end = form.time_end.value
    if (form.day_start && form.day_start.value)
      data.day_start = form.day_start.value
    if (form.day_end && form.day_end.value) data.day_end = form.day_end.value
    if (form.additional_cleaning_fee && form.additional_cleaning_fee.value)
      data.additional_cleaning_fee = form.additional_cleaning_fee.value
    data.id = prev_pricing[pricing.id] ? prev_pricing[pricing.id].id : null
    return data
  }

  let handleFormChange = (e) => {
    const form = formRef.current.elements
    let formatedData = getFormatedData()

    setActivitiesFormData(formatedData)
    setShowPriceRange(form.pricing_condition[1].checked)
    setShowAditionalFeeInputBox(form.cleaning_fee[1].checked)
  }

  let handleVisibilityOfForm = async (e) => {
    e.preventDefault()
    if (prev_pricing[pricing.id]) {
      dispatch(
        updatePricing({
          id: prev_pricing[pricing.id].id,
          property: property_id,
          is_activate: !showformComponent,
          callBack: (response) => {},
        }),
      )
    }

    // console.log(prev_pricing[pricing.id].is_activate);
    // if (prev_pricing[pricing.id] == undefined) {
    setShowformComponent(!showformComponent)
    // }
  }
  const [selectedDayList, setSelectedDayList] = useState([])
  const [showDayselectOptionList, setShowDayselectOptionList] = useState(false)
  let defineSelectedDayList = (id) => {
    let checked = selectedDayList.includes(id)
    if (!checked) {
      setSelectedDayList([...selectedDayList, id])
    }

    if (checked) {
      let array = selectedDayList
      const index = array.indexOf(id)
      if (index > -1) {
        array.splice(index, 1)
      }
      setSelectedDayList([...array])
    }
    // console.log(selectedDayList);
  }

  let selectedDayListString = () => {
    if (selectedDayList.length == 0) {
      return 'select day'
    }
    let string = ''
    selectedDayList.map((d) => {
      dayList.map((dl) => {
        if (dl.id == d) {
          string = `${string}${titleCase(
            dl.name.toLowerCase().substring(0, 3),
          )}, `
        }
      })
    })
    return string
  }

  return (
    <div className="activitie">
      <h1>{pricing.title}</h1>
      <button
        onClick={handleVisibilityOfForm}
        className={`${showformComponent ? 'selected-button' : ''}`}
      >
        Select
      </button>
      <>
        <div className={showformComponent ? 'hide-elements' : ''}>
          <hr
            style={{
              display: displayList ? 'block' : 'none',
              marginTop: 15,
              marginBottom: 15,
            }}
          />
          <h2 style={{ display: displayList ? 'block' : 'none' }}>
            Activities Could Include
          </h2>
          <ul style={{ display: displayList ? 'block' : 'none' }}>
            {pricing.activitie.map((activity, index) => {
              if (index < 20) {
                return <li> {activity.title} </li>
              }
            })}
          </ul>
          <hr />
        </div>
        {/* <span style={{display: !displayList ? "block" : "none" }}>Show Examples</span> */}
        {/* <img className='downarrow' style={{transform: `rotate(${ displayList ?'180deg':'0deg'})`}} onClick={ ()=>{setDisplayList(!displayList)} } src={arrowDown} alt="" srcSet="" /> */}
        {/* <span style={{display: displayList ? "block" : "none" }}>Back to the top</span> */}

        <form
          ref={formRef}
          onChange={handleFormChange}
          onSubmit={handelSubmit}
          className={
            !showformComponent ? 'hide-elements event-form' : 'event-form'
          }
          id={pricing.title.replaceAll(' ', '_').toLowerCase() + '_form'}
        >
          <hr />
          <div
            style={{ marginBottom: '10px' }}
            className="pricing-conditions-group"
          >
            <h1 className="pricing-conditions-group-heading">
              Pricing Conditions
            </h1>
            <div className="event-pricing-form-radio-button-group">
              {pricing_condition_option.map((option, index) => {
                let id =
                  option.title.replaceAll(' ', '_').toLowerCase() +
                  '_' +
                  pricing.title.replaceAll(' ', '_').toLowerCase()
                return (
                  <div
                    className="hourly-rate-radio-button"
                    style={{ display: 'flex', padding: '5px 0px 0px 0px' }}
                  >
                    <input
                      type="radio"
                      id={id}
                      value={option.id}
                      name="pricing_condition"
                    />
                    <label htmlFor={id}>{option.title}</label>
                  </div>
                )
              })}
            </div>
          </div>
          {showPriceRange ? (
            <div className="meetings_classes_rehearsals_price_range_time">
              <p style={{ color: '#959595' }}>
                Please make sure this time is in between all of the availability
                time of each day that you have entered on previous step.
              </p>
              <span>Between</span>
              <div
                style={{ marginTop: '10px', marginBottom: '10px' }}
                className="time-range-set"
              >
                <select
                  className="meetings_classes_rehearsals_price_range_time_start"
                  name="time_start"
                  id="time_start"
                >
                  <option>------</option>
                  {time_list.map((time) => {
                    return <option value={time}>{tConvert(time)}</option>
                  })}
                </select>
                <p>to</p>
                <select
                  className="meetings_classes_rehearsals_price_range_time_start"
                  name="time_end"
                  id="time_end"
                >
                  <option>------</option>
                  {time_list.map((time) => {
                    return <option value={time}>{tConvert(time)}</option>
                  })}
                </select>
              </div>
              <span>on</span>
              <button
                onFocusCapture={() => setShowDayselectOptionList(true)}
                onBlurCapture={() => setShowDayselectOptionList(false)}
                type="button"
                style={{ marginTop: '10px' }}
                className="meetings_classes_rehearsals_price_range_time_set"
              >
                <div className="meetings_classes_rehearsals_price_range_time_set_option_place_holder">
                  {selectedDayListString()}
                </div>
                <div
                  className={`meetings_classes_rehearsals_price_range_time_set_option ${
                    showDayselectOptionList ? '' : 'hide-elements'
                  }`}
                >
                  <ul>
                    {dayList.map((d) => {
                      return (
                        <li>
                          <input
                            type="checkbox"
                            name="day"
                            value={d.id}
                            id={`${d.name}_${pricing.id}`}
                            checked={selectedDayList.includes(d.id)}
                            className="hide-elements"
                            onChange={() => {}}
                          />
                          <label
                            onClick={() => {
                              defineSelectedDayList(d.id)
                            }}
                            htmlFor={`${d.name}_${pricing.id}`}
                          >
                            <div
                              // selected-check-box
                              className={`custom-check-box ${
                                selectedDayList.includes(d.id)
                                  ? 'selected-check-box'
                                  : ''
                              }`}
                              id={`day_select_option_custom_check_box_${d.name}_${pricing.id}`}
                            >
                              ✔
                            </div>
                            {d.name}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </button>
            </div>
          ) : (
            ''
          )}
          <div className="price-input-box-group">
            <span style={{ padding: '11.5px' }}>$</span>
            <input
              type="number"
              name="price"
              onChange={(e) => {
                if (
                  e.target.value < stateForValidation.min_price ||
                  e.target.value > stateForValidation.max_price
                ) {
                  message.error('Please enter value between 0 to 10000')
                  e.target.value = null // Clearing the input field
                }
              }}
            />
          </div>

          <hr
            className={`event-form-hr ${
              !showPriceRange ? '' : 'hide-elements'
            }`}
          />
          <div
            className={`minimum-number-of-hours ${
              !showPriceRange ? '' : 'hide-elements'
            }`}
          >
            <h1 className="minimum-number-of-hours-heading">
              Minimum number of hours
            </h1>
            <input type="number" name="minimum_number_of_hours" min="0" />
          </div>

          <hr className="event-form-hr" />
          <div className="hour-discount price-input-box-group">
            <h1 className="8-hour-discount-heading">8+ hour discount</h1>
            <span style={{ padding: '11.5px' }}>%</span>
            <input type="number" name="discount" min="0" />
          </div>

          <hr className="event-form-hr" />
          <div className="cleaning-fee-conditions-group">
            <h1 className="cleaning-fee-heading">Cleaning Fee</h1>
            <div className="event-cleaning-fee-form-radio-button-group">
              {cleaning_fee_option.map((option) => {
                let id =
                  option.title
                    .replaceAll(' ', '_')
                    .replaceAll(':', '')
                    .toLowerCase() +
                  '_' +
                  pricing.title.replaceAll(' ', '_').toLowerCase()
                return (
                  <div
                    className="hourly-rate-radio-button"
                    style={{ display: 'flex', padding: '5px 0px 0px 0px' }}
                  >
                    <input
                      type="radio"
                      id={id}
                      value={option.id}
                      name={'cleaning_fee'}
                    />
                    <label htmlFor={id}>{option.title}</label>
                  </div>
                )
              })}

              {showAditionalFeeInputBox ? (
                <div className="price-input-box-group">
                  <span style={{ padding: '12px' }}>$</span>
                  <input type="number" name="additional_cleaning_fee" />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          {/* <button type='submit' style={{marginTop:"20px",padding:"5px 10px"}}  > Save </button> */}
        </form>
      </>
    </div>
  )
}
