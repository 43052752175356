import React, { useState, useEffect } from 'react'
import backbtn from '../pages/icons/backbtn.svg'
import backbtnBlue from '../pages/icons/backbtn_blue.svg'
import user from '../pages/icons/user.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { logout, me } from '../redux/actions/AuthAction'
import dummyUser from '../img/group.png'
import People from './icons/People'
import SearchOne from './searchBoxes/SearchOne'
import RegistrationForm from './registration/RegistrationForm'
import SelectUser from './login/SelectUser'
import DropDownMenu from './menus/DropDownMenu'

const HeaderBar = ({ barLevel, customStyles }) => {
  const nav = useNavigate()
  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const authUser = useSelector((state) => state.auth.user)
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)
  const [showLoginForm, setShowLoginForm] = useState(false)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  useEffect(() => {
    if (!authUser?.email) dispatch(me())
  }, [authUser])

  const pathName = location.pathname

  return (
    <>
      <div
        className={`singn-up-form ${showRegistrationForm ? '' : 'hide-elements'
          }`}
      >
        <RegistrationForm closeRegistrationFormCart={setShowRegistrationForm} />
      </div>
      <div className={`singn-in-form ${showLoginForm ? '' : 'hide-elements'}`}>
        <SelectUser closeRegistrationFormCart={setShowLoginForm} />
      </div>
      {pathName == '/renter/mybookings' ||
        (pathName == '/renter/booking' && (
          <div className="header-bar">
            <div className="header-left-widget" onClick={() => nav(-1)}>
              <img
                src={pathName === `/review/${params.id}` ? backbtn : backbtnBlue}
                alt=""
                style={{ width: 12, margin: 8, color: '#6568E6' }}
              />
              <label
                className="back-title"
                style={{
                  color: pathName === `/review/${params.id}` ? '#000' : '#6568E6',
                }}
              >
                {pathName === `/review/${params.id}` ? 'Booking details' : 'Back'}
              </label>
            </div>
            <a href="/">
              <div className="main-logo"
              // onClick={() => nav('/')}
              >
                <img
                  src="venuely.svg" alt="" style={{ width: 90, margin: 8 }}
                  onClick={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                      window.open(window.location.host, '_blank')
                    }
                  }}
                />
                <label className="title">Booking</label>
              </div>
            </a>
            {/* <People /> */}
            {!isauthenticated ? (
              <>
                <div className="right__side__links">
                  <h3 className="link_text" onClick={() => {
                    setShowLoginForm(false)
                    setShowRegistrationForm(!showRegistrationForm)
                  }}>Sign up</h3>
                  <h3 className="link_text"
                    onClick={() => setShowLoginForm(!showLoginForm)}>Sign in</h3>
                </div>
              </>
            ) : (
              <DropDownMenu showDropdownMenu={showDropdownMenu} setShowDropdownMenu={setShowDropdownMenu} />
            )}
          </div>
        ))}

      {pathName === '/renter/mybookings' ||
        pathName === '/renter/payment' ||
        pathName === `/renter/place/${params.id}/${params.startTime}/${params.endTime}/${params.dateVal}/${params.dateVal2}` ||
        pathName === `/renter/review/${params.id}` ||
        pathName === '/renter/messages' ||
        pathName === `/renter/messages/${params.id}` ||
        pathName === '/renter/bookings' ||
        pathName === `/review/${params.id}` ||
        pathName === '/renter/profile' ? (
        <div className="header-bar" style={customStyles ? customStyles : {}}>
          <a href="/">
            <div className="main-logo"
            >
              <img src="/venuely.svg" alt="" style={{ width: 90, margin: 8 }} />
            </div>
          </a>
          <SearchOne />

          {!isauthenticated ? (
            <>
              <div className="right__side__links">
                <h3 className="link_text" onClick={() => {
                  setShowLoginForm(false)
                  setShowRegistrationForm(!showRegistrationForm)
                }}>Sign up</h3>
                <h3 className="link_text"
                  onClick={() => setShowLoginForm(!showLoginForm)}>Sign in</h3>
              </div>
            </>
          ) : (
            <DropDownMenu showDropdownMenu={showDropdownMenu} setShowDropdownMenu={setShowDropdownMenu} />
          )}
        </div>
      ) : null}
    </>
  )
}

export default HeaderBar
