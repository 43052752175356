import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

const ContactInfoOfVenuelyAtRenter = (props) => {
  const { contactModalOn, setContactModalOn } = props

  const listGridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 15,
    width: '78%',
    marginTop: 20,
  }
  const listTitle = { fontSize: 16, marginTop: 20 }
  // console.log(props.item)

  return (
    <Modal
      title={'Venue Contact Information'}
      destroyOnClose
      okText="Apply"
      visible={contactModalOn}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setContactModalOn(false)}
      onCancel={() => setContactModalOn(false)}
    >
      <div style={{ maxHeight: 550, overflowX: 'hidden', overflowY: 'scroll' }}>
        <p style={{ fontSize: 14 }}>
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Venue Address:
          </span>{' '}
          {/* 1047 Amsterdam Ave, New York, NY 10025, United States */}
          {props.item.property.address
            ? props.item.property.address
            : 'Not provided'}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Entrance Address:{' '}
          </span>
          {props.item.property.entrance_address
            ? props.item.property.entrance_address
            : 'Not provided'}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Point of Contact Name:
          </span>{' '}
          {props.item.host.name}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Point of Contact’s Email:
          </span>{' '}
          {props.item.host.email}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Point of Contact’s Number:
          </span>{' '}
          +{props.item.host.phone}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>
            Arrival Instructions:
          </span>{' '}
          {props.item.house_rules.Instructions}
        </p>
      </div>
    </Modal>
  )
}

export default ContactInfoOfVenuelyAtRenter
