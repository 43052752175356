import React, { useEffect, useRef, useState } from 'react'
import { Modal, Dropdown, message, Button, Menu, Spin } from 'antd'
import uploadPhotoSVG from '../../img/uploadPhoto.svg'
import Button1 from '../buttons/Button1'
import './style/cancelBookingModal.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import moment from 'moment'

const baseURL = process.env.REACT_APP_BASE_URL
const CancelBookingModal = (props) => {
  const { cancelBooking, setcancelBooking, modalText, propertyId } = props
  const navigate = useNavigate()
  const [reasonText, setReasonText] = useState('')
  const [loading, setLoading] = useState(false)
  const [cancellationMsg, setCancellationMsg] = useState('')

  const displayNone = { style: { display: 'none' } }

  const confirmCancellation = () => {
    setLoading(!loading)
    const cookie = new Cookies()
    const authData = cookie.get('jwt')
    const config = {
      headers: {
        Authorization: authData,
      },
    }
    axios
      .patch(
        `${baseURL}/guest/space-reservation/${propertyId}`,
        {
          message_to_host: cancellationMsg,
          cancelation_reason: reasonText === 'Other' ? '' : reasonText,
        },
        config,
      )
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          setcancelBooking(false)
          message.success('Reservation cancelled successfully')
          navigate('/renter/mybookings')
        }
        setLoading(!loading)
      })
      .catch((err) => {
        if (err) {
          setcancelBooking(false)
          const errorMessage = err.response.data.detail
            .split(':')[1]
            .split('.')[0]
          message.error(errorMessage)
        }
        setLoading(!loading)
      })
  }

  const textAreaRef = useRef(null)

  const menu = (
    <Menu
      selectable
      onClick={(e) => setReasonText(e.key)}
      items={[
        {
          key: 'I no longer need accommodations',
          label: (
            <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>
              I no longer need accommodations
            </h3>
          ),
        },
        {
          key: 'I made the reservation by accident',
          label: (
            <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>
              I made the reservation by accident
            </h3>
          ),
        },
        {
          key: 'My host needs to cancel',
          label: (
            <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>
              My host needs to cancel
            </h3>
          ),
        },
        {
          key: "I'm uncomfortable with the host",
          label: (
            <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>
              I'm uncomfortable with the host
            </h3>
          ),
        },
        {
          key: 'My event dates changed',
          label: (
            <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>
              My event dates changed
            </h3>
          ),
        },
        {
          key: 'Other',
          label: <h3 style={{ fontSize: 14, fontWeight: 'normal' }}>Other</h3>,
        },
      ]}
    />
  )

  const titleStyle = { fontSize: 20, fontWeight: 500, marginBottom: 18 }

  useEffect(() => {
    reasonText === 'Other' && textAreaRef.current.focus()
  }, [reasonText])

  const todayMoment = moment()
  const tomorrowMoment = todayMoment.clone().add(1, 'days').format('MMMM DD') // TODO: make dyanmic later
  const refundMoment = todayMoment.clone().add(3, 'days').format('MMMM DD') // TODO: make dyanmic later

  return (
    <>
      <Modal
        destroyOnClose
        title="Cancel Booking"
        cancelButtonProps={displayNone}
        okButtonProps={displayNone}
        okText="Send"
        onCancel={() => setcancelBooking(false)}
        visible={cancelBooking}
        style={{ top: '7%' }}
      >
        <div>
          <h3 style={titleStyle}>Cancellation policy</h3>
          {/* <p style={{ fontSize: 14, color: '#000' }}>{modalText}</p> */}
          <p style={{ fontSize: 14, color: '#000' }}>
            Free cancellation untill 9:00 AM on {tomorrowMoment}
          </p>
          <p style={{ fontSize: 14, color: '#000' }}>
            Cancel before 9:00 AM on {refundMoment}, get a 50% refund. After
            that, refund of cleaning fee only.
          </p>
          <h3 style={titleStyle}>Why do you need to cancel?</h3>
          <Dropdown overlay={menu} placement="bottom" trigger={'click'}>
            <h5
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#000',
                border: '#ccc 1px solid',
                borderRadius: 4,
                padding: 8,
                cursor: 'pointer',
              }}
            >
              {reasonText === '' ? 'Please select a reason' : reasonText}
            </h5>
          </Dropdown>
          <h5
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: '#000',
              marginTop: 25,
            }}
          >
            Cancellation message to the host
          </h5>
          <textarea
            ref={textAreaRef}
            value={cancellationMsg}
            onChange={(e) => setCancellationMsg(e.target.value)}
            name=""
            id=""
            cols="30"
            rows="10"
            className="text-area"
          ></textarea>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            {/* <div onClick={() => setcancelBooking(false)}>
              <Button1
                title="Cancel"
                bgColor="#fff"
                width={200}
                color="#6568E6"
                margin={5}
              />
            </div> */}
            <div
              onClick={() => confirmCancellation()}
              style={{ cursor: 'pointer' }}
            >
              {loading && (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                    marginBottom: 14,
                  }}
                >
                  <Spin style={{ color: 'white' }} />
                </div>
              )}
              <Button1
                title="Confirm  cancellation"
                bgColor="#6568E6"
                color="#fff"
                margin={5}
                // loading={loading}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
             
export default CancelBookingModal
