import { combineReducers } from 'redux'
import AuthReducer from './AuthReducer'
import PropertyReducer from './PropertyReducer'
import ComponentReducer from './ComponentReducer'
import SpaceReducer from './SpaceReducer'
import BasicPolicyReducer from './BasicPolicyReducer'
import HouseRuleReducer from './HouseRuleReducer'
import InformationReducer from './InformationReducer'
import AvailabilityReducer from './AvailabilityReducer'
import ImageReducer from './ImageReducer'
import PricingReducer from './PricingReducer'
import BufferTimeRoleReducer from './BufferTimeRulesReducer'
import WindowBookingRoleReducer from './WindowTimeRulesReducer'
import ConversationReducer from './ConversationReducer'
import BlockedDateReducer from './BlockedDateReducer'

export default combineReducers({
  auth: AuthReducer,
  property: PropertyReducer,
  component: ComponentReducer,
  space: SpaceReducer,
  policy: BasicPolicyReducer,
  house_rules: HouseRuleReducer,
  information: InformationReducer,
  availability: AvailabilityReducer,
  image: ImageReducer,
  prices: PricingReducer,
  buffer_time_roles: BufferTimeRoleReducer,
  window_time_role: WindowBookingRoleReducer,
  convesation: ConversationReducer,
  blocked_date: BlockedDateReducer,
})
